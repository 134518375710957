// @ts-nocheck

import type { Dictionary } from '@af/types';

import qs from 'qs';
import { getCorrelationId } from '@af/utils';

export type ApiRequest = {
	url: string;
	params?: {
		[key: string]: unknown;
	};
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	headers?: {
		[key: string]: string;
	};
	body?:
		| string
		| {
				[key in string | number]: any;
		  }
		| FormData;
};

export type BatchErrorItem = {
	error: boolean;
	httpCode: number;
	statusDescription: string;
};

export type BatchResponse = Array<BatchErrorItem | any>;

export type EndpointType = {
	url: string;
	params: Dictionary | undefined | null;
	method: ApiRequest['method'];
	withOrgId: boolean;
};

const initialQueryParams = qs.parse(window.location.search.substring(1));

// prettier-ignore
const inferedQueryParams = (initialQueryParams as any as {
  customServerUrl: null
});

// yarn start for staging
// yarn start:mock for mock server
// http://localhost:3000/?customServerUrl=https://...

const serverUrl =
	inferedQueryParams.customServerUrl || process.env.REACT_APP_SERVER_URL;

if (inferedQueryParams.customServerUrl) {
	console.warn('Using custom server url', serverUrl);
}

let apiUrlCache;
export function getApiUrl(): string {
	// Prevent recalculation
	if (apiUrlCache) return apiUrlCache;

	if (serverUrl) {
		apiUrlCache = serverUrl;
	} else {
		apiUrlCache = '/api';
	}

	return apiUrlCache;
}

export async function apiRequest<T>({
	url,
	params = {},
	method = 'GET',
	headers: headerParams = {},
	body: bodyRaw,
}: ApiRequest): Promise<string | T | Error> {
	if (url === undefined) {
		throw new Error('url is undefined');
	}

	const query: string = qs.stringify(params);
	const path: string = `${getApiUrl()}/${url}`;
	let requestUrl: string = path;

	// Add query strings if they are provided
	if (query.length > 0) {
		requestUrl += `?${query}`;
	}

	const { correlationId } = getCorrelationId();

	headerParams['x-correlation-id'] = correlationId;

	const headers = new Headers(headerParams);

	// Add some sensible default headers so we don't have to repeat them
	headers.append('Accept', 'application/json');

	let body: BodyInit | undefined | null = undefined;

	if (bodyRaw) {
		// If body is a json convertible object
		if (typeof bodyRaw === 'object' && !(bodyRaw instanceof FormData)) {
			body = JSON.stringify(bodyRaw);

			// Add json header because body is json
			headers.append('Content-Type', 'application/json');
		} else {
			body = bodyRaw;
		}
	}

	const options: RequestOptions = {
		headers,
		method,
		body,
	};

	try {
		const response = await fetch(requestUrl, options);
		const parsedResponse = await onFetchSuccess<T>(
			response,
			requestUrl,
			options,
		);

		return parsedResponse;
	} catch (error) {
		// Re-throw same error
		throw error;
	}
}

async function onFetchSuccess<T>(
	response: Response,
	requestUrl: string,
	requestOptions: RequestOptions,
): Promise<Error | string | T> {
	const headerContentType = response.headers.get('Content-Type');
	const isJSON =
		headerContentType && headerContentType.includes('application/json');

	// Parse response as json if content type is json otherwise as text
	const body = await (isJSON ? response.json() : response.text());

	if (response.ok) {
		return body;
	}

	const responseMeta = {
		status: response.status,
		statusText: response.statusText,
		url: requestUrl,
		method: requestOptions.method,
		responseBody: body,
		requestBody: requestOptions.body,
	};

	const error = new Error(response.statusText);

	// $FlowFixMe: I know this is a problem but it's okay for now
	error.responseMeta = responseMeta;

	throw error;
}
