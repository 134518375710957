// @ts-nocheck

import type { ComponentProps } from 'react';

import React from 'react';
import styled from 'styled-components';

import {
	IconButton as IconButtonCore,
	PrimaryButton as PrimaryButtonCore,
	SecondaryButton as SecondaryButtonCore,
} from 'ds/components/core/Button';
import { useTheme } from 'hooks/useTheme';

import { Box } from './Box';
import { Text } from './Text';

type PrimaryButtonProps = {
	children: ComponentProps<typeof Text>['children'];
} & ComponentProps<typeof Box> &
	ComponentProps<typeof PrimaryButtonCore>;

export const PrimaryButton = ({ children, ...props }: PrimaryButtonProps) => (
	<Box {...props} as={PrimaryButtonCore}>
		<Text>{children}</Text>
	</Box>
);

type SecondaryButtonProps = {
	children: ComponentProps<typeof Text>['children'];
} & ComponentProps<typeof Box> &
	ComponentProps<typeof SecondaryButtonCore>;

export const SecondaryButton = ({
	children,
	...props
}: SecondaryButtonProps) => {
	const theme = useTheme();

	return (
		<Box
			{...props}
			as={SecondaryButtonCore}
			{...(!props.isActive && { background: theme.color.baseLum + 4 })}
		>
			<Text>{children}</Text>
		</Box>
	);
};

type IconButtonProps = {} & ComponentProps<typeof Box> &
	ComponentProps<typeof IconButtonCore>;

export const IconButton = styled(Box).attrs(props => ({
	forwardedAs: IconButtonCore,
	background: props.background || props.theme.color.baseLum + 4,
}))<IconButtonProps>`
	background: ${props => props.transparent && 'transparent'};
`;
