/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import {
	getUserNotificationsQuery,
	NotificationResponse,
	QueryResponse,
	QueryVariables,
} from 'App/hooks/useGetUserNotificationsQuery';

import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { GQLNotificationEventType } from '@af/api';
import { useHistory } from 'react-router';
import { useVirtual } from 'react-virtual';

import { useDismissNotificationMutation } from 'App/hooks/useDismissNotificationMutation';
import { useGetUserNotificationsQuery } from 'App/hooks/useGetUserNotificationsQuery';
import { UserContext } from 'contexts/UserContext';
import { Theme } from 'ds/components/core/Theme';
import { ErrorMessage } from 'ds/components/ErrorMessage';
import { useReadNotificationMutation } from 'ds/components/UserPanel/components/Notification/useReadNotificationMutation';
import {
	getActionCenterTempExpressionUrl,
	getRiskDetailTempExpressionUrl,
} from 'utils/tempExpression';

import { Notification } from './Notification';
import {
	ListContent,
	Message,
	VirtualRow,
	Wrapper,
	NotificationsLoader,
} from './styles';
import { useNotificationCountContext } from 'contexts/NotificationCountContext';

export type Props = {
	onClose: () => void;
	onError: (a: string) => void;
};

type FetchMoreProps = {
	offset: QueryVariables['offset'];
};

type ToggleReadProps = {
	id: string;
	isRead: boolean;
};

const DISMISS_ERROR = 'There was a problem dismissing the notification';
const TOGGLE_ERROR_BASE = 'There was a problem marking the notification';
const READ_NOTIFICATION_QUERY_CONNECTION_KEY = 'notificationPanel';

const NotificationPanel = ({ onClose, onError }: Props) => {
	const [notificationsCountRecord, setNotificationsCountRecord] = useState<
		number | null
	>(null);
	const [isRefetching, setIsRefetching] = useState<boolean>(false);
	const { orgId } = useContext(UserContext);
	const history = useHistory();
	const parentRef = useRef<HTMLDivElement | null>(null);
	const [dismissNotification, { loading: isDismissing, error: dismissError }] =
		useDismissNotificationMutation();
	const [
		readNotification,
		{ loading: isReadUpdating, error: readUpdateError },
	] = useReadNotificationMutation();

	const { notifications, count, fetchMore, refetch, error, loading } =
		useGetUserNotificationsQuery({
			connectionKey: READ_NOTIFICATION_QUERY_CONNECTION_KEY,
			isRead: false,
		});

	const { notificationsCount } = useNotificationCountContext();

	const handleRefetch = async () => {
		setIsRefetching(true);
		await refetch();
		setIsRefetching(false);
	};
	useEffect(() => {
		if (
			notificationsCountRecord &&
			notificationsCount !== notificationsCountRecord &&
			notificationsCount !== count
		) {
			handleRefetch();
		}
		setNotificationsCountRecord(notificationsCount);
	}, [notificationsCount]);

	const hasMore = useMemo(() => {
		return count && notifications.length < count;
	}, [notifications, count]);

	const isNotificationsListEmpty = !loading && !count;

	const notificationVirtualizer = useVirtual({
		size: notifications.length,
		parentRef,
		estimateSize: useCallback(() => 120, []),
	});

	// The `loading` property doesn't give us enough control over when new rows
	// are being fetched. We only want new requests to happen after they have been
	// rendered. So we're hooking into `useVirtual`'s totalSize property - when
	// that changes, we can load more rows.
	const [isFetchingMore, setIsFetchingMore] = useState(false);
	useEffect(() => {
		setIsFetchingMore(false);
	}, [notificationVirtualizer.totalSize]);

	const loadMore = useCallback(() => {
		fetchMore<FetchMoreProps, NotificationResponse>({
			variables: { offset: notifications.length },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const previousNotifications =
					previousResult.userMe.notifications.notifications;
				const resultNotifications =
					fetchMoreResult?.userMe?.notifications?.notifications || [];

				return {
					...previousResult,
					userMe: {
						...previousResult.userMe,
						notifications: {
							...previousResult.userMe.notifications,
							notifications: [...previousNotifications, ...resultNotifications],
						},
					},
				};
			},
		});
	}, [fetchMore, notifications.length]);

	useEffect(() => {
		const lastItemIndex =
			notificationVirtualizer.virtualItems[
				notificationVirtualizer.virtualItems.length - 1
			]?.index;

		if (lastItemIndex == null) {
			return;
		}

		if (
			lastItemIndex === notifications.length - 1 &&
			hasMore &&
			!loading &&
			!isFetchingMore
		) {
			setIsFetchingMore(true);
			loadMore();
		}
	}, [
		notifications,
		notifications.length,
		notificationVirtualizer.virtualItems,
		hasMore,
		loading,
		isFetchingMore,
		loadMore,
	]);

	const handleDismissed = async ({ id }) => {
		await dismissNotification({
			variables: {
				contextOrgId: orgId,
				notificationId: id,
			},
		}).catch(() => {
			onError(DISMISS_ERROR);
			return;
		});
		if (dismissError) {
			onError(DISMISS_ERROR);
			return;
		}
		refetch();
	};

	const handleToggleRead = async ({ id, isRead }: ToggleReadProps) => {
		const errorMsg = `${TOGGLE_ERROR_BASE} ${isRead ? 'unread' : 'read'}`;
		await readNotification({
			variables: {
				contextOrgId: orgId,
				notificationId: id,
				isRead,
			},
		}).catch(() => {
			onError(errorMsg);
			return;
		});
		if (readUpdateError) {
			onError(errorMsg);
			return;
		}
		refetch();
	};

	const handleToggleReadSilentResponse = ({ id, isRead }: ToggleReadProps) => {
		// No await so we don't expect any response
		// before next code
		readNotification({
			variables: {
				contextOrgId: orgId,
				notificationId: id,
				isRead,
			},
			update: (cache, response) => {
				const defaultVariables = {
					contextOrgId: orgId,
					offset: 0,
					limit: 30,
					isDismissed: false,
					isRead: false,
				};

				const data = cache.readQuery<QueryResponse, QueryVariables>({
					query: getUserNotificationsQuery(
						READ_NOTIFICATION_QUERY_CONNECTION_KEY,
					),
					variables: defaultVariables,
				});

				if (!data?.userMe || !response?.data?.setReadNotification) {
					return;
				}

				const readItems = response.data.setReadNotification.reduce(
					(acc, curr) => ({
						...acc,
						[curr.notificationId]: curr.isRead,
					}),
					{},
				);

				const updatedNotifications =
					data.userMe.notifications.notifications.filter(
						item => !readItems.hasOwnProperty(item.id),
					);

				const updatedTotalItemsCount = updatedNotifications.length;

				const updatedData = {
					...data,
					userMe: {
						...data.userMe,
						notifications: {
							...data.userMe.notifications,
							totalItemsCount: updatedTotalItemsCount,
							notifications: updatedNotifications,
						},
					},
				};

				cache.writeQuery({
					query: getUserNotificationsQuery(
						READ_NOTIFICATION_QUERY_CONNECTION_KEY,
					),
					variables: defaultVariables,
					data: updatedData,
				});
			},
		});
	};

	const isLoading = loading || isReadUpdating || isDismissing || isRefetching;

	const handleNotificationClicked = notification => {
		handleToggleReadSilentResponse({
			id: notification.id,
			isRead: !notification.isRead,
		});

		switch (notification.eventType) {
			case GQLNotificationEventType.EntitlementCreated:
				// Flow fix
				if (!notification.viewId) return;
				history.push(`/view/${notification.viewId}/risks`);
				break;
			case GQLNotificationEventType.RiskComment:
			case GQLNotificationEventType.RiskAssigned:
				// Flow fix
				if (!notification.temporaryFilterExpressionId) return;
				history.push(
					getRiskDetailTempExpressionUrl(
						notification.temporaryFilterExpressionId,
					),
				);
				break;
			case GQLNotificationEventType.InstructionCommentReceivedEvent:
				// Flow fix
				if (!notification.temporaryFilterExpressionId) return;
				history.push(
					getActionCenterTempExpressionUrl(
						notification.temporaryFilterExpressionId,
					),
				);
				break;
			case GQLNotificationEventType.ContentExportDone:
				// go no where, but explicitly so. the next line made necessary by the tests
				return;
			default:
				return;
		}
		onClose();
	};

	return (
		<Wrapper ref={parentRef}>
			{error ? (
				<ErrorMessage error={error} />
			) : isNotificationsListEmpty ? (
				<Message>No notifications</Message>
			) : null}
			{!isNotificationsListEmpty && (
				<>
					<ListContent
						style={{ height: `${notificationVirtualizer.totalSize}px` }}
					>
						{notificationVirtualizer.virtualItems.map(virtualRow => {
							const notification = notifications[virtualRow.index];
							if (!notification) return null;
							return (
								<VirtualRow key={virtualRow.index} virtualRow={virtualRow}>
									<Theme baseLum={5}>
										<Notification
											notification={notification}
											onDismissed={handleDismissed}
											onToggleRead={handleToggleRead}
											onClick={() => handleNotificationClicked(notification)}
										/>
									</Theme>
								</VirtualRow>
							);
						})}
					</ListContent>
				</>
			)}
			{isLoading ? <NotificationsLoader /> : null}
		</Wrapper>
	);
};

export { NotificationPanel };
