// @ts-nocheck

import React from 'react';
import * as PropTypes from 'prop-types';

import Svg from '../Svg';
import { defaultProps, propTypes } from './props';

const variants = {
	default: {
		viewBox: '0 0 21 27',
	},
	circled: {
		viewBox: '0 0 21 21',
	},
};

export default function AvatarIcon({
	color,
	bgColor,
	width,
	height,
	variant,
	...props
}) {
	return (
		<Svg
			width={width}
			height={height}
			viewBox={variants[variant].viewBox}
			{...props}
		>
			{variant === 'default' && (
				<path
					fill={color}
					d="M5 5.7C5 2.7 7.5 0 10.5 0c1.7 0 3.2.8 4.2 2l.6 1c.5.7.7 1.7.7 2.7 0 1.8-.8 3.4-2 4.4-.3.4-.6.6-1 .8-.8.4-1.6.6-2.5.6-3 0-5.5-2.6-5.5-5.7zM20 20v1.8c0 2 0 4-.6 4.6-.4.4-1 .6-2 .6H3.6c-1 0-1.6-.2-2-.6C1 25.6 1 24 1 21.8v-2c0-3.8 3.2-7 7-7h6.2c1 .3 2 .7 2.7 1.2l1 1c1.3 1.3 2 3 2 5z"
				/>
			)}

			{variant === 'circled' && (
				<g>
					<circle fill={bgColor} cx="10.5" cy="10.5" r="10.5" />
					<path
						fill={color}
						d="M14,13 C14,11.8321161 13.0596946,10.9 11.8997692,10.9 L9.10023081,10.9 C7.94405115,10.9 7,11.840202 7,13 L7,15.1 L14,15.1 L14,13 Z M10.5,10.2 C9.34020203,10.2 8.4,9.25979797 8.4,8.1 C8.4,6.94020203 9.34020203,6 10.5,6 C11.659798,6 12.6,6.94020203 12.6,8.1 C12.6,9.25979797 11.659798,10.2 10.5,10.2 Z"
					/>
				</g>
			)}
		</Svg>
	);
}

AvatarIcon.propTypes = {
	variant: PropTypes.oneOf(Object.keys(variants)),
	bgColor: PropTypes.string,
	...propTypes,
};
AvatarIcon.defaultProps = {
	variant: 'default',
	bgColor: '#CACACA',
	...defaultProps,
};
