type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const ActionCentreOnIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M19 5h-6v7h6V5zM19 14h-6v5h6v-5zM11 5H5v5h6V5zM11 12H5v7h6v-7z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default ActionCentreOnIcon;
