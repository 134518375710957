type Props = {
	className?: string;
};

export const DeselectIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M10 14H5.5c-.294 0-.5-.406-.5-.7V4.5c0-.294.206-.5.5-.5h8c.294 0 .5.206.5.5V8h-1V5.004H5.997v7.992H10V14zm1-3h4v1h-4v-1z"
	/>
);
