// @ts-nocheck

import React, { useMemo } from 'react';
import { GQLNavigationMenuPermission } from '@af/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Protected } from 'components/Protected';
import { Theme } from 'ds/components/core/Theme';
import Icon, { ICONS } from 'ds/components/Icon';
import { Typography } from 'ds/components/Typography';
import { useAllViewId } from 'hooks/useAllViewId';
import { fadeIn } from 'styles/animation';
import { NAVBAR_TITLES } from 'utils/constants';

type Props = {
	label: string;
	onRouteClick: () => void;
};

export function Nav({ label, onRouteClick, ...props }: Props) {
	const { data } = useAllViewId();

	const contentCenterUrl = useMemo(() => {
		const allViewId = data?.allView?.id;

		if (!allViewId) return null;

		return `/view/${allViewId}/contents`;
	}, [data]);

	const flags = useFlags();
	const isSummaryV2Enabled = flags['summary-v2'];

	return (
		<Wrapper {...props}>
			{label && (
				<Typography as={Label} size="tiny">
					{label}
				</Typography>
			)}

			<Theme baseLum={2}>
				<NavItem to="/summary" iconConfig={ICONS.risk} onClick={onRouteClick}>
					{isSummaryV2Enabled
						? NAVBAR_TITLES.summary
						: NAVBAR_TITLES.riskSummary}
				</NavItem>

				<Protected permissionId={GQLNavigationMenuPermission.JourneyCenter}>
					<NavItem
						to="/journey-center"
						iconConfig={ICONS.journey}
						onClick={onRouteClick}
					>
						{NAVBAR_TITLES.journeyCenter}
					</NavItem>
				</Protected>

				<Protected
					permissionId={GQLNavigationMenuPermission.ConfigurationCenter}
				>
					<NavItem
						data-test="configuration-center-nav-item"
						to="/configuration-center"
						iconConfig={ICONS.settings}
						onClick={onRouteClick}
					>
						{NAVBAR_TITLES.configurationCenter}
					</NavItem>
				</Protected>

				<Protected permissionId={GQLNavigationMenuPermission.Onboarding}>
					<NavItem
						to="/setup"
						iconConfig={ICONS.preferences}
						onClick={onRouteClick}
					>
						{NAVBAR_TITLES.onboarding}
					</NavItem>
				</Protected>

				<Protected permissionId={GQLNavigationMenuPermission.Analytics}>
					<NavItem
						to="/dashboard"
						iconConfig={ICONS.dashboard}
						onClick={onRouteClick}
					>
						{NAVBAR_TITLES.analytics}
					</NavItem>
				</Protected>

				<Protected permissionId={GQLNavigationMenuPermission.FieldConfig}>
					<NavItem
						to="/field-configuration"
						iconConfig={ICONS.onboarding}
						onClick={onRouteClick}
					>
						{NAVBAR_TITLES.fieldConfig}
					</NavItem>
				</Protected>

				{contentCenterUrl && (
					<Protected permissionId={GQLNavigationMenuPermission.ContentDetails}>
						<NavItem
							to={contentCenterUrl}
							iconConfig={ICONS.rules}
							onClick={onRouteClick}
						>
							{NAVBAR_TITLES.contents}
						</NavItem>
					</Protected>
				)}

				<Protected permissionId={GQLNavigationMenuPermission.VideoHelp}>
					<NavItem
						to="/videos"
						iconConfig={ICONS.videoHelp}
						onClick={onRouteClick}
					>
						{NAVBAR_TITLES.videoHelp}
					</NavItem>
				</Protected>

				<Protected
					permissionId={GQLNavigationMenuPermission.BusinessIntelligence}
				>
					<NavItemExternal
						href="https://analytics.accessfintech.com/"
						target="_blank"
						iconConfig={ICONS.businessIntelligence}
					>
						{NAVBAR_TITLES.businessIntelligence}
					</NavItemExternal>
				</Protected>
			</Theme>
		</Wrapper>
	);
}

function NavItem({ to, iconConfig, children, ...props }) {
	return (
		<RouteLink to={to} {...props}>
			<RouteIcon config={iconConfig} size="medium" />
			<Typography as={RouteName} size="medium">
				{children}
			</Typography>
		</RouteLink>
	);
}

function NavItemExternal({ iconConfig, children, ...props }) {
	return (
		<RouteLinkExternal {...props}>
			<RouteIcon config={iconConfig} size="medium" />
			<Typography as={RouteName} size="medium">
				{children}
			</Typography>
			<ExternalIcon config={ICONS.goto} size="small" />
		</RouteLinkExternal>
	);
}

const Wrapper = styled.nav`
	animation: ${fadeIn} 200ms ease-out;
	flex-shrink: 0;
	position: absolute;
	left: 0;
	top: 100%;
	background-color: ${props => props.theme.color.bg.lum.active};
	box-shadow: 0px 6px 6px
		${props => props.theme.color.palette.black.alpha.custom(0.4)};
`;

const Label = styled.span`
	display: block;
	padding: ${props => props.theme.spacing.small}px;
	text-transform: uppercase;
`;

const RouteLinkExternal = styled.a`
	display: flex;
	padding: ${props => props.theme.spacing.small}px;
	align-items: center;
	white-space: nowrap;
	min-width: 200px;

	&:hover,
	&:focus {
		background-color: ${props => props.theme.color.bg.lum.rollover};
		color: ${props => props.theme.color.fg.base};
	}

	&.is-active {
		background-color: ${props => props.theme.color.primary.base};
	}
`;

const RouteLink = styled(RouteLinkExternal).attrs(() => ({ as: NavLink }))``;

const RouteIcon = styled(Icon)`
	margin-right: ${props => props.theme.spacing.small}px;
`;

const RouteName = styled.span`
	color: inherit;
`;

const ExternalIcon = styled(Icon)`
	margin-left: ${props => props.theme.spacing.small}px;
`;
