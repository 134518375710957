type Props = {
	className?: string;
};

export const FavoriteIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M11.425 8.04l-.232-.471L10 5.152 8.575 8.04l-.52.076-2.668.387 2.306 2.249-.089.518-.455 2.656L10 12.428l.465.244 2.386 1.255-.544-3.175.376-.367 1.93-1.881-3.188-.464zm5.064-.146a.229.229 0 0 1-.058.234l-3.05 2.973.72 4.198a.228.228 0 0 1-.33.24L10 13.557 6.23 15.54a.228.228 0 0 1-.331-.24l.72-4.198-3.05-2.973a.228.228 0 0 1 .126-.39l4.215-.612 1.885-3.82c.077-.155.333-.155.41 0l1.885 3.82 4.215.613a.228.228 0 0 1 .184.155z"
	/>
);
