type Props = {
	className?: string;
};

export const SaveIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(4, 4)"
		d="M8.5.5C8 0 8 0 7.5 0H7v3c0 .6-.4 1-1 1H3c-.6 0-1-.4-1-1V0H1C.4 0 0 .4 0 1v10c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V4.5c0-.5 0-.5-.5-1l-3-3zM10 11H2V9c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v2zM3 2v.5c0 .509 0 .5.5.5h2C6 3 6 3 6 2.5V2H3z"
	/>
);
