import { timingFunctions } from 'polished';
import { css } from 'styled-components';

/**
 * 1. Cannot use `display: flex` as it causes a height issue in IE11
 */

export default function reactModal() {
	return css`
		.ReactModal__Overlay {
			display: block; /* 1. */

			opacity: 0;
			transition: opacity 200ms ${timingFunctions('easeOutExpo')};

			&--after-open {
				opacity: 1;
			}

			&--before-close {
				opacity: 0;
			}
		}

		.ReactModal__Content {
			transform: scale(0.5);
			transition: transform 200ms ${timingFunctions('easeOutExpo')};

			&--after-open {
				transform: scale(1);
			}

			&--before-close {
				transform: scale(0.5);
			}
		}
	`;
}
