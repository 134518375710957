// @ts-nocheck

import styled from 'styled-components';
import { css } from 'styled-components';

import { IconButton } from 'ds/components/core/Button';
import { Typography } from 'ds/components/Typography';
import { resetButton } from 'styles/helper';

export const Wrapper = styled.article`
	display: flex;
	position: relative;
	height: 100%;
	margin: ${props => props.theme.spacing.tiny}px
		${props => props.theme.spacing.tiny}px 0;
	outline: none;
	transition: background 0.3s ease-out;

	${({ hasDestination, isRead, theme: { color, spacing } }) => {
		return css`
			background-color: ${color.bg.lum.custom(6)};
			padding: ${spacing.small}px;
			cursor: ${hasDestination && 'pointer'};

			h3 {
				color: ${color.fg.typeAlpha.medium};
			}

			${Date} {
				color: ${color.fg.typeAlpha.custom(0.6)};
			}

			${isRead &&
			css`
				background-color: ${color.bg.lum.custom(3)};
			`}

			&:focus,
			&:active {
				background-color: ${color.bg.lum.active};
				h3 {
					color: ${color.fg.typeAlpha.high};
				}
				${Date} {
					color: ${color.fg.typeAlpha.custom(0.7)};
				}
			}

			&:hover {
				background-color: ${color.bg.lum.rollover};
				h3 {
					color: ${color.fg.base};
				}
				${Date} {
					color: ${color.fg.typeAlpha.high};
				}
			}
		`;
	}};
`;

export const StatusButtonWrapper = styled.div`
	position: absolute;
	top: ${props => props.theme.spacing.small}px;
	left: ${props => props.theme.spacing.tiny}px;
	border-top: 2px solid transparent;
	display: flex;
	align-items: center;
`;

export const StatusButton = styled.button`
	${resetButton};
	cursor: pointer;

	${({ isRead, theme: { color, spacing } }) => {
		return css`
			margin: ${spacing.tiny}px;

			&::before {
				content: '';
				display: inline-block;
				width: ${props => props.theme.spacing.small}px;
				height: ${props => props.theme.spacing.small}px;
				border-radius: 50%;
				border: 1px solid transparent;
				border-color: ${isRead && color.fg.alpha.custom(0.2)};
				background-color: ${!isRead && color.palette.brandBlue.base};
				transition: opacity 0.3s ease-out;
			}

			${Wrapper}:hover &:enabled::before {
				border-color: ${isRead && color.fg.alpha.custom(0.4)};
				opacity: ${isRead && 1};
			}
		`;
	}}
`;

export const FooterButton = styled(IconButton)`
	opacity: 0.8;
	transition: opacity 0.3s ease-out;

	${Wrapper}:hover &:enabled {
		opacity: 1;
	}
`;

export const Content = styled.div`
	margin-top: ${props => props.theme.spacing.tiny}px;
	margin-left: ${props => props.theme.spacing.medium}px;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const Message = styled.div`
	display: flex;
	flex: 1 1 auto;
	margin-right: ${props => props.theme.spacing.small}px;
	height: 60px;
`;

export const Footer = styled.footer`
	margin-top: ${props => props.theme.spacing.tiny}px;
	display: flex;
	align-items: center;
`;

export const Date = styled(Typography).attrs(() => ({
	forwardedAs: 'time',
	size: 'tiny',
}))`
	display: flex;
	flex: 1 1 auto;
`;

export const DownloadButton = styled.a`
	flex: 1 0 auto;
	margin-top: -${props => props.theme.spacing.tiny}px;
	margin-left: ${props => props.theme.spacing.small}px;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: ${props => props.theme.color.palette.white.typeAlpha.base};
	background-color: ${props => props.theme.color.bg.lum.active};
	padding: 0 ${props => props.theme.spacing.tiny}px 0
		${props => props.theme.spacing.small}px;
	height: 24px;

	&:hover {
		color: ${props => props.theme.color.palette.white.typeAlpha.high};
		background-color: ${props => props.theme.color.bg.lum.default};
	}
`;
