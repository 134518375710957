// @ts-nocheck

import type { AnyKindOfExpression } from 'types/Expression';
import type { Field, FieldDictionary } from 'types/Field';
import type { Id } from 'types/Id';

import { GQLFieldType } from '@af/api';
import { warn } from '@af/utils';

import { nowISO } from 'utils/dates';

import {
	BOOLEAN_OPERATORS,
	DATETIME_OPERATORS,
	NUMBER_OPERATORS,
	STRING_OPERATORS,
} from './constants';

export const NUMERIC_FIELD_TYPES = [GQLFieldType.Integer, GQLFieldType.Double];
export const DATE_FIELD_TYPES = [GQLFieldType.Date, GQLFieldType.DateTime];

export const DEFAULT_FIELD: Field = {
	fieldId: 'default',
	id: 'default',
	contentTypeId: null,
	productLineId: null,
	organizationId: null,
	genericFieldId: null,
	fieldFriendlyName: 'Unknown',
	type: GQLFieldType.String,
	formatType: GQLFieldType.String,
	fieldValues: [],
	isTimeBased: false,
	titleRank: null,
	resourceType: 'clientField',
	values: [],
	contentType: null,
	productLine: null,
};

export function getFieldWithKey(
	key: Id,
	fieldDictionary: FieldDictionary,
): Field | undefined | null {
	if (!key) {
		warn('Missing key', key);
		return;
	}

	const field = fieldDictionary[key];

	return field;
}

// This type is safe to be inexact because we may not always have full field data
type FieldPartialName = {
	fieldFriendlyName?: string | null;
	fieldId: Id;
};

export function getFieldFriendlyName(partialField: FieldPartialName): string {
	return (
		partialField.fieldFriendlyName ||
		(partialField.fieldId ? partialField.fieldId.toString() : 'Unknown')
	);
}

export function getDefaultValueForField(field: {
	type: GQLFieldType;
}): string | number | boolean {
	switch (field.type) {
		case GQLFieldType.String:
			return '';
		case GQLFieldType.Integer:
		case GQLFieldType.Double:
			return 0;
		case GQLFieldType.Date:
		case GQLFieldType.DateTime:
			return nowISO();
		case GQLFieldType.Boolean:
			return true;
		default:
			return '';
	}
}

// All possible operators, usually used for journey edit
export const getOperators = (field: Field) => {
	const { type } = field;

	switch (type) {
		case GQLFieldType.String:
			return STRING_OPERATORS;
		case GQLFieldType.Integer:
		case GQLFieldType.Double:
			return NUMBER_OPERATORS;
		case GQLFieldType.Date:
		case GQLFieldType.DateTime:
			return DATETIME_OPERATORS;
		case GQLFieldType.Boolean:
			return BOOLEAN_OPERATORS;
		default:
			return [];
	}
};

// Pull the fieldId from any "value" type expression
export const getFieldIdFromExpression = (
	expression: AnyKindOfExpression,
): string | undefined | null => {
	return (
		(expression?.left &&
			expression.left.fieldId &&
			String(expression.left.fieldId)) ||
		(expression?.value &&
			expression.value.fieldId &&
			String(expression.value.fieldId)) ||
		undefined
	);
};
