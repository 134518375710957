// @ts-nocheck

import 'react-app-polyfill/ie11';
import 'core-js/features/array/fill';
import 'core-js/features/array/includes';
import 'core-js/features/array/find-index';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/string/starts-with';
import 'core-js/features/set';
import 'resize-observer-polyfill';
import 'requestidlecallback';
import 'react-day-picker/lib/style.css';
import 'focus-visible';

import React, { useState } from 'react';
import { AuthProvider, useMount } from '@af/hooks';
import { ApolloProvider } from '@apollo/react-hooks';
import * as FullStory from '@fullstory/browser';
import { init as initSentry } from '@sentry/browser';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { ActionDispatchProvider } from 'contexts/ActionsDispatchContext';
import { RiskSummaryContextProvider } from 'contexts/RiskSummaryContext';
import { Theme } from 'ds/components/core/Theme';
import { GlobalStyle } from 'ds/styles/GlobalStyle';
import { getApolloClient } from 'utils/apollo-client';
import { getAppVersion, getBusinessEnvironmentName } from 'utils/environment';
import { getLDProvider } from 'utils/launchDarkly';

import { App } from './App';
import store from './store';

const appVersion = getAppVersion();
const envName = getBusinessEnvironmentName();

// We don't want to enable error reporting on development mode
// because error notifications will turn into spam while coding
// Feel free to enable it if you want to track something on your local machine
if (process.env.REACT_APP_IS_SENTRY_ENABLED === 'true') {
	// Set up error tracking
	const sentryConfig = {
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: envName,
		release: appVersion,
		ignoreErrors: [
			'Frequency and Grouping features are limited to a maximum of', // This has been added to ignore these types of errors: https://sentry.io/organizations/access-fintech-qa/issues/2946406934/?environment=staging&environment=production&query=is%3Aunresolved&statsPeriod=24h
		],
	};

	initSentry(sentryConfig);
}

global.Headers =
	global.Headers ||
	import(/* webpackChunkName: "fetch-headers" */ 'fetch-headers');

Modal.setAppElement('body');

// Frame busting script
if (process.env.REACT_APP_IS_E2E !== 'true' && window.top !== window.self) {
	window.top.onbeforeunload = function () {};
	window.top.location.replace(window.self.location.href);
}

function Root() {
	const [apolloClient, setApolloClient] = useState();

	useMount(() => {
		getApolloClient().then(setApolloClient);
	});

	return (
		<AuthProvider>
			{apolloClient ? (
				<ApolloProvider client={apolloClient}>
					<ApolloHooksProvider client={apolloClient}>
						<Provider store={store}>
							<Theme name="v2dark">
								<GlobalStyle />
								<HelmetProvider>
									<Router>
										<ErrorBoundary>
											<ActionDispatchProvider>
												<RiskSummaryContextProvider>
													<App />
												</RiskSummaryContextProvider>
											</ActionDispatchProvider>
										</ErrorBoundary>
									</Router>
								</HelmetProvider>
							</Theme>
						</Provider>
					</ApolloHooksProvider>
				</ApolloProvider>
			) : null}
		</AuthProvider>
	);
}

FullStory.init({ orgId: '188CGJ' });

// Launch Darkly setup
(async () => {
	const LDProvider = await getLDProvider();

	const rootElement = document.getElementById('root');

	if (rootElement) {
		ReactDOM.render(
			<LDProvider>
				<Root />
			</LDProvider>,
			rootElement,
		);
	} else {
		console.warn('#root element could not be found.');
	}
})();
