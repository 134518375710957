type Props = {
	className?: string;
};

export const LinkIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M11.875 5c.813 0 1.625.313 2.188.938.624.562.937 1.375.937 2.187 0 .813-.313 1.625-.875 2.188l-.75.75a.675.675 0 0 1-.438.187.675.675 0 0 1-.437-.188.604.604 0 0 1 0-.874l.688-.75c.374-.313.562-.813.562-1.313s-.188-1-.563-1.375c-.687-.688-1.937-.688-2.624 0l-.75.75a.604.604 0 0 1-.876 0 .604.604 0 0 1 0-.875l.75-.688C10.25 5.313 11.063 5 11.876 5zm-1.688 7.5c.25-.25.626-.313.876-.063s.25.626 0 .876l-.75.75c-.563.624-1.376.937-2.188.937-.813 0-1.625-.313-2.188-.938C5.313 13.5 5 12.688 5 11.876c0-.813.313-1.625.938-2.188l.687-.75c.25-.25.625-.25.875 0s.25.626 0 .876l-.688.75c-.375.312-.562.812-.562 1.312s.188 1 .563 1.375c.687.688 1.937.688 2.625 0l.75-.75zm.688-4.25c.25-.25.625-.25.875 0s.25.625 0 .875L9.125 11.75a.675.675 0 0 1-.438.188.675.675 0 0 1-.437-.188.604.604 0 0 1 0-.875l2.625-2.625z"
	/>
);
