import type { ThemeName } from 'ds/styles/themes';
import type { TypeSizeName } from 'ds/styles/typography';
import type { ReactNode } from 'react';

import React from 'react';
import { ThemeProvider } from 'styled-components';

import { BASE_THEMES } from 'ds/styles/themes';
import {
	convertBaseColorToColorV2,
	convertBaseThemeToTheme,
} from 'ds/styles/utils';
import { useTheme } from 'hooks/useTheme';

export function Theme({
	name,
	baseLum,
	typographySize,
	children,
}: {
	name?: ThemeName;
	baseLum?: number;
	typographySize?: TypeSizeName;
	children: ReactNode;
}) {
	const themeContext = useTheme();
	const themeId = name || (themeContext ? themeContext.id : 'v2dark');
	const baseTheme = BASE_THEMES[themeId];
	const baseThemeLum =
		baseLum != null ? baseLum : themeContext && themeContext.color.baseLum;

	const typography =
		typographySize != null
			? typographySize
			: themeContext && themeContext.typography.size.name;

	const theme = convertBaseThemeToTheme(
		{
			...baseTheme,
			color: {
				...baseTheme.color,
				maxLum: 12,
				lum: {
					disabled: -2,
					default: 0,
					active: 2,
					rollover: 4,
				},
			},
		},
		baseThemeLum,
		typography,
		convertBaseColorToColorV2,
		'v2',
	);

	return (
		<ThemeProvider theme={theme}>
			<React.Fragment>{children}</React.Fragment>
		</ThemeProvider>
	);
}
