// @ts-nocheck

import type { ApolloError } from '@apollo/react-hooks';

import React, { useEffect, useState } from 'react';
import { FavouriteIcon, FavouriteOffIcon } from '@af/icons';
import { Portal } from 'react-portal';
import { useLocation } from 'react-router-dom';

import { IconButton } from 'ds/components/core/Button';
import { Theme } from 'ds/components/core/Theme';
import { ErrorMessage } from 'ds/components/ErrorMessage';
import {
	Modal,
	ModalActionPrimary,
	ModalActions,
	ModalActionSecondary,
	ModalContent,
	ModalTitle,
} from 'ds/components/Modal';
import { APP_NOTIFICATION_PORTAL_ID } from 'utils/constants';
import { Toast, ToastMessage } from 'v2/components/Toast';

import { useUserPreferencesBookmarkMutation } from './useUserPreferenceBookmarkMutation';
import { useUserPreferencesBookmarkQuery } from './useUserPreferenceBookmarkQuery';

export const KEY = 'defaultPath';
const DEFAULT_PATH = '/summary';

function Bookmark() {
	const location = useLocation();
	const { pathname } = location;

	const [savePreference, { loading: saving, error: saveError }] =
		useUserPreferencesBookmarkMutation();
	const {
		bookmark,
		loading,
		error: loadError,
	} = useUserPreferencesBookmarkQuery();

	const [errorMessage, setErrorMessage] = useState<
		ApolloError | undefined | null | void
	>();
	const [bookmarkPathname, setBookmarkPathname] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const isBookmark = Boolean(bookmarkPathname === pathname);
	const isLoading = Boolean(loading || saving);

	const handleOnClick = () => {
		if (isBookmark) savePreference(DEFAULT_PATH);
		if (bookmarkPathname === DEFAULT_PATH) savePreference(pathname);
		if (!isBookmark && bookmarkPathname !== DEFAULT_PATH) setIsModalOpen(true);
	};

	const handleOnConfirm = () => {
		savePreference(pathname);
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (loadError || saveError) {
			setErrorMessage(loadError || saveError);
		}
	}, [loadError, saveError]);

	useEffect(() => {
		if (!isLoading) setBookmarkPathname(bookmark || DEFAULT_PATH);
	}, [bookmark, isLoading]);

	return (
		<>
			<IconButton
				title={isBookmark ? 'Reset landing page' : 'Set as landing page'}
				onClick={handleOnClick}
			>
				{isBookmark ? <FavouriteIcon /> : <FavouriteOffIcon />}
			</IconButton>

			<Modal theme="v2light" isOpen={isModalOpen}>
				<ModalTitle>Confirm change</ModalTitle>

				<ModalContent>
					Another location is already set. Please confirm if you would like to
					update this.
				</ModalContent>

				<ModalActions>
					<ModalActionSecondary onClick={() => setIsModalOpen(false)}>
						Cancel
					</ModalActionSecondary>
					<ModalActionPrimary onClick={handleOnConfirm}>Ok</ModalActionPrimary>
				</ModalActions>
			</Modal>

			{errorMessage ? (
				<Portal
					node={document && document.getElementById(APP_NOTIFICATION_PORTAL_ID)}
				>
					<Theme name="v2dark" baseLum={2}>
						<Toast autoClose={5000} onDismiss={() => setErrorMessage()}>
							<ToastMessage>
								<ErrorMessage error={errorMessage} />
							</ToastMessage>
						</Toast>
					</Theme>
				</Portal>
			) : null}
		</>
	);
}

export { Bookmark };
