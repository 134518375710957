type Props = {
	className?: string;
};

export const ClaimIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M8,10 L8,14.0427038 L7,14.0427038 L7,9 L7,6 L14,6 L11.375,8.00387553 L14,10 L8,10 Z"
	/>
);
