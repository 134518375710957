type Props = {
	className?: string;
};

export const ChevronLeft = ({ className }: Props) => (
	<path
		className={className}
		transform="matrix(0 1 -1 0 20 0)"
		d="M14.54 6.616l.92.768L10 13.937 4.54 7.384l.92-.768L10 12.063z"
	/>
);
