type Props = {
	className?: string;
};

export const AllocationIcon = ({ className }: Props) => (
	<g className={className}>
		<path d="M5.5 6H10.5V6.83333H7.5V13.1667H10.5V14H5.5V6Z" />
		<path d="M13.6667 6.83333H11.5V6H14.5V7.94444H13.6667V6.83333Z" />
		<path d="M13.6667 13.1667V12.0556H14.5V14H11.5V13.1667H13.6667Z" />
		<path d="M10.5 8.07445V9.375H9V10.625H10.5V11.9255L12.5 10L10.5 8.07445Z" />
		<path d="M13.6667 11V9H14.5V11H13.6667Z" />
	</g>
);
