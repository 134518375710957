type Props = {
	className?: string;
};

export const ActionIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M5,5 L9,5 L9,9 L5,9 L5,5 Z M6,6 L6,8 L8,8 L8,6 L6,6 Z M5,11 L9,11 L9,15 L5,15 L5,11 Z M6,12 L6,14 L8,14 L8,12 L6,12 Z M11,11 L15,11 L15,15 L11,15 L11,11 Z M12,12 L12,14 L14,14 L14,12 L12,12 Z M11,5 L15,5 L15,9 L11,9 L11,5 Z"
	/>
);
