type Props = {
	className?: string;
};

export const RemoveIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M14 8.5V14a1 1 0 01-1 1H7a1 1 0 01-1-1V8.5h8zM15 7v1H5V7h10zM7 6h1v1H7V6zm0 0v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5V7h-1V6H7z"
		fillRule="evenodd"
	/>
);
