import type { ApiRequest } from '@af/utils';

import { merge } from 'lodash-es';

export const getMergedApiConfigs = (
	mainConfig: any,
	configOverride: any = {},
): ApiRequest => {
	const mergedConfig = merge(mainConfig, configOverride);

	// Only take the parameters that we need on 'fetch'
	const { url, params, method = 'GET', headers, body } = mergedConfig;

	return { url, params, method, headers, body };
};
