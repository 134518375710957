// @ts-nocheck

import type { Id } from 'types/Id';
import type { Role, User } from 'types/User';

import { difference } from 'lodash-es';

export const USER_ROLES = {
	RiskSummaryUI: 'RiskSummaryUI',
	RiskSummaryEditUI: 'RiskSummaryEditUI',
	RiskDetailUI: 'RiskDetailUI',
	FactsheetUI: 'FactsheetUI',
	JourneyViewUI: 'JourneyViewUI',
	JourneyEditUI: 'JourneyEditUI',
	ParticipantsUI: 'ParticipantsUI',
	FieldConfigUI: 'FieldConfigUI',
	AnalyticsUI: 'AnalyticsUI',
	RuleBuilderUI: 'RuleBuilderUI',
	ChatRoomCreate: 'ChatRoomCreate',
	ContentDetailUI: 'ContentDetailUI',
};

// Return a list of all user roles for a given orgId
// In: "{ roles": [{ "name": "Admin", "orgIds": [3]}, ...]}, orgId: 3
// Out: ["Admin"]
const getRolesByOrgId = (user: User, orgId: Id) => {
	if (!user.roles) return [];

	const orgRoles = user.roles.reduce((allRoles, { name, orgIds }) => {
		// Check if user's orgId is in allowed organisation list
		// Also check if role is global(null)
		if (orgIds.includes(orgId) || orgIds.includes(null)) {
			return [...allRoles, name];
		}

		return allRoles;
	}, []);

	return orgRoles;
};

export const isRolesInUserOrgRoleList = (
	roles: Array<Role>,
	user: User,
	orgId: Id,
): boolean => {
	const allOrgRoles = getRolesByOrgId(user, orgId);
	const result = !difference(roles, allOrgRoles).length;

	return result;
};
