/* eslint-disable */
// TODO: Typescript migration

import type { GQLUserPreference } from '@af/api';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { KEY } from './';

const UPDATE_USER_PREFERENCE_BOOKMARK = gql`
	mutation UpdateUserPreferenceBookmark($key: ID!, $value: String!) {
		upsertUserPreference(userPreferenceInput: { key: $key, value: $value }) {
			key
			value
		}
	}
`;

type QueryVariables = {
	key: GQLUserPreference['key'];
	value: GQLUserPreference['value'];
};

export type QueryData = {
	upsertUserPreference: {
		key: GQLUserPreference['key'];
		value: GQLUserPreference['value'];
		__typename: 'UserPreference';
	};
};

function useUserPreferencesBookmarkMutation() {
	const [save, response] = useMutation<QueryData, QueryVariables>(
		UPDATE_USER_PREFERENCE_BOOKMARK,
	);

	const savePreference = (pathname: string) => {
		return save({
			variables: {
				key: KEY,
				value: pathname,
			},
		});
	};

	return [savePreference, response];
}

export { useUserPreferencesBookmarkMutation };
