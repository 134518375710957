type Props = {
	className?: string;
};

export const ReshapeIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6 6)"
		d="M6.854 1.854l-1.5 1.5-.708-.708 1.5-1.5L5 0h3v3L6.854 1.854zm-5-.708L4.5 3.793v4.244h-1v-3.83L1.146 1.854 0 3V0h3L1.854 1.146z"
	/>
);
