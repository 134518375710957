type Props = {
	className?: string;
};

export const SelectQuickIcon = ({ className }: Props) => (
	<g className={className}>
		<path d="M7 11h6l-3 4z" />
		<path d="M7 9h6l-3-4z" />
	</g>
);
