type Props = {
	className?: string;
};

export const CloneIcon = ({ className }: Props) => (
	<g className={className}>
		<path
			d="M15 13v2h-2v-1h1v-1h1zm-4 1h1v1h-2v-2h1v1zm0-8v1h-1V5h2v1h-1zm4-1v2h-1V6h-1V5h2zm-1 4h1v2h-1V9zM9.3 5v10H4.5V5h4.8zM11 9v2h-1V9h1z"
			fillRule="evenodd"
		/>
	</g>
);
