// @ts-nocheck

// Tiny Bubbles
// ~ for Roy
export function tinyBubbles() {
	const pattern = '38384040373937396665';
	let input = '';

	document.onkeydown = e => {
		input += e.keyCode;

		if (input.length > pattern.length) {
			input = input.substr(input.length - pattern.length);
		}

		if (input === pattern) {
			window.location = 'https://www.youtube.com/embed/mlCiDEXuxxA?autoplay=1';

			e.preventDefault();
			return false;
		}
	};
}
