type Props = {
	className?: string;
};

export const ClearNavigationIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6, 6)"
		d="M4.707 4l2.829 2.828-.708.708L4 4.707 1.172 7.536l-.708-.708L3.293 4 .464 1.172l.708-.708L4 3.293 6.828.464l.708.708z"
	/>
);
