type Props = {
	className?: string;
};

export const UserExternalIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M11 12H9c-1.004 0-2.091.691-2.746 1.734C7.384 14.565 8.661 15 10 15s2.616-.435 3.746-1.266C13.09 12.691 12.004 12 11 12zm-1 4c-1.854 0-3.575-.716-5-2 .709-1.76 2.38-3 4-3h2c1.62 0 3.291 1.24 4 3-1.425 1.284-3.146 2-5 2zm0-7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"
	/>
);
