// @ts-nocheck

import { captureException } from '@sentry/browser';

// Setup an object on the window to suppress logs
window.logAccumulator = window.logAccumulator || {};
// Used to log a simple "how to use" warning on the first function call
let isWarnInfoShown = false;

export function warn(...args: Array<any>) {
	// Get all warning arguments and convert to a dictionary key
	const logKey = [...args].join('-');

	captureException(new Error(logKey));

	// Get previous log count or set up new count at zero
	const logCount = window.logAccumulator[logKey] || 0;

	if (logCount === 0) {
		// Log the warning if it hasn't been logged before
		console.warn(...args);
	}

	// Increase the warn count
	window.logAccumulator[logKey] = logCount + 1;

	if (!isWarnInfoShown) {
		// Show "how to" message on first function call
		isWarnInfoShown = true;

		console.warn(
			'You are using warn utility function. run "window.logAccumulator" on console to see how many times a warning has come up',
		);
	}
}
