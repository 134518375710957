// @ts-nocheck

import type { BatchErrorItem, BatchResponse } from '@af/utils';

import { apiRequest, csrfToken } from '@af/utils';
import { cloneDeep } from 'lodash-es';

import { getStoreOrgId } from 'utils/orgId';

import { navigateToLogout } from '../urls';
import { batch } from './batch';
import { getMergedApiConfigs } from './utils';

export async function apiCall(
	endpoint: any,
	overwriteConfig: any = {},
): Promise<any> {
	const isArray = endpoint instanceof Array;
	// Convert everything to arrays for easy control
	const endpoints = isArray ? endpoint : [endpoint];

	// Add CSRF token
	overwriteConfig.headers = overwriteConfig.headers || {};
	overwriteConfig.headers['x-csrf-token'] = csrfToken.get();

	// Cloning to prevent mutation of endpoint configs
	let overwrittenEndpoints = cloneDeep(endpoints);

	// Pull in orgId param for endpoints that require it
	const orgId = overwriteConfig.orgId || getStoreOrgId();

	if (!!orgId) {
		overwrittenEndpoints = overwrittenEndpoints.map(endpoint => {
			// If overwrite is explicitly "true or false" use that value
			// Otherwise default to true
			const withOrgId =
				typeof endpoint.withOrgId === 'undefined' ? true : endpoint.withOrgId;

			if (withOrgId) {
				return {
					...endpoint,
					params: {
						...endpoint.params,
						// Add orgId to request query string params
						orgId,
						...(overwriteConfig.vendorOrganizationId && {
							vendorOrgId: overwriteConfig.vendorOrganizationId,
						}),
					},
				};
			}

			return endpoint;
		});
	}

	if (overwrittenEndpoints.length === 1) {
		const endpoint = overwrittenEndpoints[0];
		const config = getMergedApiConfigs(endpoint, overwriteConfig);
		// Pre-format the config before sending to the API
		const formattedConfig = endpoint.preFormat
			? endpoint.preFormat(config)
			: config;

		return apiRequest(formattedConfig)
			.then(data => {
				// Don't format errored data
				if (
					(data &&
						typeof data !== 'string' &&
						!(data instanceof Error) &&
						data.error) ||
					!endpoint.format
				) {
					return data;
				}

				return endpoint.format(data);
			})
			.then(data => {
				// Some apiCalls expects arrays as return objects
				// even though they made a single apiCall
				// eg: apiCall(items.map(...)) //items count can be 1 or more
				return isArray ? [data] : data;
			})
			.catch(error => {
				// Log the user out when their auth fails
				if (error.responseMeta.status === 401) {
					navigateToLogout();

					return;
				}

				throw error;
			});
	}

	return batch(overwrittenEndpoints, overwriteConfig);
}

function isBatchItemErrored(batchResponseItem: BatchErrorItem | any): boolean {
	return !batchResponseItem || !!batchResponseItem.error;
}

export function isBatchErrored(response: BatchResponse): boolean {
	return response.some(isBatchItemErrored);
}
