// @ts-nocheck

// TODO: replace any with Props once the API is updated
// import type { ActiveExportResponse } from 'v2/hooks/useGetActiveExportStatus';

import React from 'react';
import { DownloadPendingIcon } from '@af/icons';

import { ProgressBar } from 'ds/components/ProgressBar';
import { Typography } from 'ds/components/Typography';

import {
	BadgeAndTitleWrapper,
	FileNameWrapper,
	LeftIconButton,
	ListRow,
	ProgressBarWrapper,
} from './styles';

// TODO: replace any with Props once the API is updated
// type Props = {|
// 	activeExport: ActiveExportResponse,
// |};

export function InProgress({ activeExport }: any) {
	return (
		<ListRow>
			<BadgeAndTitleWrapper>
				<LeftIconButton>
					<DownloadPendingIcon />
				</LeftIconButton>
				<FileNameWrapper>
					<Typography numberOfLines={2} align="center">
						{activeExport.fileName}
					</Typography>
				</FileNameWrapper>
			</BadgeAndTitleWrapper>
			<ProgressBarWrapper>
				<ProgressBar
					value={activeExport.uploadedItemsCount}
					max={activeExport.totalItemsCount}
				/>
			</ProgressBarWrapper>
		</ListRow>
	);
}
