import type { Id } from 'types/Id';
import type { User } from 'types/User';

import { getName } from 'utils/users';

export const uploadAvatarEndpoint = (userId: Id, formData: FormData) => ({
	url: `users/${userId}/image`,
	method: 'POST',
	body: formData,
});

export const userAllEndpoint = (withOrgId: boolean = true) => ({
	url: 'users',
	// Add name property to every user
	format: (users: Array<User>) =>
		// $FlowFixMe I don't understand the problem??
		users.map((user: User) => ({
			...user,
			name: getName(user),
		})),
	withOrgId,
});
