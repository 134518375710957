// @ts-nocheck

/**
 * 1. TODO: This needs to be re-reviewed once we reimplement the DS.
 *    The custom value of the typeAlpha needs to come from a variable
 */
import type { ChangeEvent } from 'react';

import React, { useState } from 'react';
import styled from 'styled-components';

const HANDLE_SIZE = 12;

const Container = styled.div`
	position: relative;
	display: inline-flex;
	padding: 2px;
	width: 32px;
	border-radius: ${HANDLE_SIZE}px;
	background-color: ${({ isChecked, isDisabled, theme }) =>
		isDisabled
			? isChecked
				? theme.color.primary.typeAlpha.custom(0.36)
				: theme.color.fg.typeAlpha.custom(0.36)
			: isChecked
			? theme.color.primary.base
			: theme.color.fg.typeAlpha.custom(0.56)};

	transition: background-color 133ms ease-in-out;
`;

const Input = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
`;

const Handle = styled.div`
	width: ${HANDLE_SIZE}px;
	height: ${HANDLE_SIZE}px;
	border-radius: 50%;
	background-color: ${props => props.theme.color.bg.base};
	transition: margin-left 133ms ease-in-out;

	${Input}:checked + & {
		margin-left: 16px;
	}

	${Input}:disabled + & {
		background-color: ${props => props.theme.color.fg.typeAlpha.custom(0.36)};
		cursor: not-allowed;
	}
`;

type UncontrolledProps = {
	defaultIsChecked: boolean;
};

type ControlledProps = {
	id?: string;
	isDisabled?: boolean;
	className?: string;
	isChecked: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

type Props = UncontrolledProps | ControlledProps;

export function Switch(props: Props) {
	const { defaultIsChecked } = props as any as UncontrolledProps;
	const {
		isChecked,
		onChange = event => {},
		isDisabled,
		className,
		...inputProps
	} = props as any as ControlledProps;

	const [isLocalChecked, setIsLocalChecked] = useState<boolean>(
		Boolean(defaultIsChecked),
	);

	const isControlled = isChecked != null;

	function handleChange(event) {
		if (isControlled) {
			onChange(event);
		} else {
			setIsLocalChecked(checked => !checked);
		}
	}

	const isCheckedValue = isControlled ? isChecked : isLocalChecked;

	return (
		<Container
			isChecked={isCheckedValue}
			isDisabled={isDisabled}
			className={className}
		>
			<Input
				{...inputProps}
				type="checkbox"
				role="switch"
				checked={isCheckedValue}
				disabled={isDisabled}
				onChange={handleChange}
			/>

			<Handle />
		</Container>
	);
}
