import styled from 'styled-components';

import { AppVersion } from 'ds/components/AppVersion';
import { fadeIn } from 'styles/animation';
import { IconButton } from 'v2/components/Button';

export const Panel = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 300px;
	height: 100%;
	animation: ${fadeIn} 200ms ease-out;
	box-shadow: 1px 5px 5px 0px
		${props => props.theme.color.palette.black.alpha.custom(0.3)};
	transition: all 100ms ease-in-out;
	z-index: 100;
`;

type WrapperProps = {
	isNotificationSettingsVisible: boolean;
};

export const Wrapper = styled.aside<WrapperProps>`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${props =>
		props.isNotificationSettingsVisible
			? props.theme.color.bg.lum.disabled
			: props.theme.color.bg.lum.default};
	position: relative;
`;

// eslint-disable-next-line import/no-unused-modules
export const StyledAppVersion = styled(AppVersion)`
	position: absolute;
	right: ${props => props.theme.spacing.tiny}px;
	top: ${props => props.theme.spacing.tiny}px;
`;

export const NotificationSettingsButton = styled(IconButton)`
	position: absolute;
	z-index: 1;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	right: ${props => props.theme.spacing.tiny}px;
	top: ${props => props.theme.spacing.large}px;
`;
