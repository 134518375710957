// @ts-nocheck

import type {
	DoubleValueKeyExpression,
	DoubleValueOperatorType,
	MultipleValueKeyExpression,
	MultipleValueOperatorType,
	NoValueKeyExpression,
	NoValueOperatorType,
	SingleValueKeyExpression,
	SingleValueOperatorType,
} from 'types/Expression';

import { GQLSystemFieldKey } from '@af/api';

// IMPORTANT
// List of different custom value pickers per entity type
// Make sure to add display name logic into utils/display.js when adding a new renderer
export const CUSTOM_RENDERER = {
	organization: 'organization',
	severity: 'severity',
	user: 'user',
	usersAndGroups: 'usersAndGroups',
	instructionAction: 'instructionAction',
};

// Dictionary of fields and their custom renderers renderers and their allowed fields
export const CUSTOM_VALUE_PICKER_FIELDKEY_DICT = {
	[GQLSystemFieldKey.SystemFieldApiDataRiskInstructionsDestinationOrgIds]:
		CUSTOM_RENDERER.organization,
	[GQLSystemFieldKey.SystemFieldApiDataRiskInstructionsOrganizationId]:
		CUSTOM_RENDERER.organization,
	[GQLSystemFieldKey.SystemFieldApiDataRiskOrgId]: CUSTOM_RENDERER.organization,
	[GQLSystemFieldKey.SystemFieldApiDataRiskSeverity]: CUSTOM_RENDERER.severity,
	[GQLSystemFieldKey.SystemFieldApiDataRiskAssignedBy]: CUSTOM_RENDERER.user,
	[GQLSystemFieldKey.SystemFieldApiDataRiskAssignedToComposite]:
		CUSTOM_RENDERER.usersAndGroups,
	[GQLSystemFieldKey.SystemFieldApiDataRiskInstructionsActionId]:
		CUSTOM_RENDERER.instructionAction,
};

export function createNoValueExpression({
	key,
	operator,
}: {
	key: string;
	operator: NoValueOperatorType;
}): NoValueKeyExpression {
	return {
		value: {
			key,
		},
		operator,
	};
}

export function createSingleValueExpression({
	key,
	operator,
	value,
}: {
	key: string;
	operator: SingleValueOperatorType;
	value: string | number | boolean;
}): SingleValueKeyExpression {
	return {
		left: {
			key,
		},
		operator,
		right: {
			value,
			valueType: 'constant',
		},
	};
}

export function createMultipleValueExpression({
	key,
	operator,
	values,
}: {
	key: string;
	operator: MultipleValueOperatorType;
	values: Array<string> | Array<number>;
}): MultipleValueKeyExpression {
	return {
		left: {
			key,
		},
		operator,
		right: values.map(value => ({
			value,
			valueType: 'constant',
		})),
	};
}

type NumericRange = {
	lower: number;
	upper: number;
};

export type DateStringRange = {
	lower: string;
	upper: string;
};

export function createDoubleValueExpression({
	key,
	operator,
	range,
}: {
	key: string;
	operator: DoubleValueOperatorType;
	range: NumericRange | DateStringRange;
}): DoubleValueKeyExpression {
	return {
		left: {
			key,
		},
		operator,
		lowerBound: {
			value: range.lower,
			valueType: 'constant',
		},
		upperBound: {
			value: range.upper,
			valueType: 'constant',
		},
	};
}
