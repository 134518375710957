/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type { ChangeEvent, ReactNode } from 'react';
import type { IconConfigType, IconName } from './constants';

import React from 'react';
import styled from 'styled-components';

import { ICON_TYPE, iconConfigMap } from './constants';

export const SIZES = {
	tiny: 16,
	small: 20,
	medium: 30,
	large: 40,
	placeholder: 80,
	mediumV2: 24, // The new sizes should be implemented
};

export type IconSize = keyof typeof SIZES;

export const STATES = ['disabled', 'active', 'rollover', 'selected'];

const BADGE_VARIANTS = {
	outlined: {
		badgeOuter: 'white',
		badgeInner: 'black',
		badgeSymbol: 'white',
	},
	filled: {
		badgeOuter: 'black',
		badgeInner: 'white',
		badgeSymbol: 'black',
	},
};

// Missing svg attributes type definition https://github.com/facebook/flow/issues/2332
export type Props = {
	title?: string;
	config: IconConfigType;
	size: IconSize;
	badgeVariant: keyof typeof BADGE_VARIANTS;
	children?: ReactNode;
	className?: string;
	style?: any;
	onClick?: (event: ChangeEvent<any>) => void;
};

const IconWrapper = styled.svg`
	vertical-align: middle;

	.badgeOuter {
		fill: ${props => BADGE_VARIANTS[props.badgeVariant].badgeOuter};
	}
	.badgeInner {
		fill: ${props => BADGE_VARIANTS[props.badgeVariant].badgeInner};
	}
	.badgeSymbol {
		fill: ${props => BADGE_VARIANTS[props.badgeVariant].badgeSymbol};
	}
`;

const ICON_VIEWBOX = '0 0 20 20';

const Icon = (props: Props) => {
	// If there is children, don't assume anything
	if (props.children) return <svg className="icon" {...props} />;

	const { title, config, size, badgeVariant, ...otherProps } = props;
	const { type, name, children: IconContent, isBadge } = config;
	const className = `icon icon-${name}`;
	const iconSize = type === ICON_TYPE.mini ? 12 : SIZES[size];
	const maskId = `${name}-${badgeVariant}-mask`;

	return (
		<IconWrapper
			aria-hidden
			className={className}
			viewBox={ICON_VIEWBOX}
			width={iconSize}
			height={iconSize}
			badgeVariant={badgeVariant}
			fill="currentColor"
			{...otherProps}
		>
			{Boolean(title) && <title>{title}</title>}
			{Boolean(isBadge) ? (
				<React.Fragment>
					<defs>
						<mask id={maskId}>
							<circle className="badgeOuter" r="10" cx="10" cy="10" />
							<circle className="badgeInner" r="9" cx="10" cy="10" />
							<IconContent className="badgeSymbol" />
						</mask>
					</defs>

					<rect width="100%" height="100%" mask={`url(#${maskId})`} />
				</React.Fragment>
			) : (
				<IconContent />
			)}
		</IconWrapper>
	);
};

Icon.defaultProps = {
	size: 'small',
	badgeVariant: 'outlined',
};

export default Icon;

export type { IconConfigType, IconName };
export { iconConfigMap as ICONS, IconWrapper };
