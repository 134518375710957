// @ts-nocheck

import type { SyntheticEvent, ReactNode } from 'react';

import React, { useRef } from 'react';
import { isEqual } from 'lodash-es';
import { useMount } from '@af/hooks';
import { warn } from '@af/utils';

type Data = {
	[key: string]: string | Array<string>;
};

type Meta = {
	event: SyntheticEvent<HTMLFormElement>;
	isDirty: boolean;
};

type Props = {
	children: ReactNode;
	onSubmit?: (data: Data, meta: Meta) => unknown;
	onChange?: (data: Data, meta: Meta) => unknown;
	onReset?: (a: SyntheticEvent<HTMLFormElement>) => unknown;
};

const Form = (
	{ children, onSubmit = () => {}, onChange = () => {}, ...props }: Props,
	externalRef,
) => {
	const formRef = useRef<HTMLFormElement | undefined | null>();
	const defaultDataRef = useRef();

	function handleSubmit(event) {
		// Forms that open in modals will trigger parent form events.
		// This ensures the event only fires on the targets nearest form.
		if (event.target === event.currentTarget) {
			const data = getFormData(event.target);
			onSubmit(data, { event, isDirty: isDirty(data) });
		}

		event.preventDefault();
	}

	function handleChange(event) {
		// Forms that open in modals will trigger parent form events.
		// This ensures the event only fires on the targets nearest form.
		if (event.target.form === event.currentTarget) {
			const data = getFormData(event.nativeEvent.target.form);
			onChange(data, { event, isDirty: isDirty(data) });
		}
	}

	function isDirty(data) {
		return !isEqual(data, defaultDataRef.current);
	}

	useMount(() => {
		if (formRef.current) {
			defaultDataRef.current = getFormData(formRef.current);
		}
	});

	return (
		<form
			{...props}
			onSubmit={handleSubmit}
			onChange={handleChange}
			ref={ref => {
				formRef.current = ref;

				if (typeof externalRef === 'function') {
					externalRef(ref);
				} else if (externalRef) {
					externalRef.current = ref;
				}
			}}
		>
			{children}
		</form>
	);
};

function getFormData(form: HTMLFormElement) {
	const formData = new FormData(form);

	const data = {};

	for (var pair of formData.entries()) {
		// Iterate over each input in the form
		const [key, value] = pair;

		// Check if developer tagged input as an array
		const isArray = key.includes('[]');
		const cleanKey = key.replace('[]', '');
		const existingValue = data[cleanKey];

		if (Boolean(existingValue)) {
			// If a value is found
			if (isArray) {
				// If tagged as array, append to array
				data[cleanKey].push(value);
			} else {
				warn(
					`"${key}" is used as a list but doesn't have array indicator "[]", did you mean to use name="${key}[]"?`,
				);
			}
		} else {
			// Add value to data accumulator as array or primitive
			data[cleanKey] = isArray ? [value] : value;
		}
	}

	return data;
}

const FormWithRef = React.forwardRef<Props, HTMLFormElement>(Form);

export { FormWithRef as Form };
