// Color names: http://chir.ag/projects/name-that-color/
export const white = '#fff';
export const black = '#000';
export const bostonBlue = 'hsla(210, 100%, 37%, 1)';
export const shark = '#292c31';
export const silverTree = '#66bc94';
export const transparent = 'transparent';

// Shared
export const textLight = white;
export const textDark = shark;
export const brandBlue = bostonBlue;
export const brandGreen = silverTree;

// TODO: Replaced this with hex values, as it's too big a job to refactor right now
export const severity = {
	low: '#71767F',
	medium: '#5555AF',
	high: '#B81941',
};

export const redRibbon = '#DB154D';
export const crimson = '#d41a4b';
export const lividBrown = '#572c37';
export const outerSpace = '#2c3d3a';
export const viridian = '#42936E';
export const puertoRico = '#40c1b0';
export const jungleGreen = '#29BA9F';
export const aquaForest = '#5BA985';
export const curiousBlue = '#189be7';
export const lightningYellow = '#f8c81c';
export const amber = '#F2C101';
export const corn = '#e8c105';

// 36 shades of grey ( ͡° ͜ʖ ͡°)
export const woodsmoke = '#08090A';
export const woodsmoke1 = '#171a1d';
export const shark11 = '#191B1E';
export const shark9 = '#1d1f23';
export const shark10 = '#1D2023';
export const shark7 = '#1F2124';
export const shark8 = '#202226';
export const shark5 = '#25272b';
export const shark6 = '#2a2b2f';
export const shark1 = '#2d3035';
export const shark3 = '#2f3136';
export const shark2 = '#303338';
export const shark4 = '#25272A';
export const mineShaft = '#333';
export const tuna = '#383b40';
export const tuna2 = '#313439';
export const mako = '#3c3f43';
export const tundora = '#444444';
export const abbey = '#44474b';
export const emperor = '#545454';
export const midGray = '#5e6064';
export const shuttleGray = '#5f656d';
export const doveGray = '#666';
export const nevada = '#696B6F';
export const rollingStone = '#7e8083';
export const waterloo = '#7E8294';
export const gray = '#888';
export const dustyGray = '#999';
export const bombay = '#b0b1b3';
export const silverChalice = '#b2b2b2';
export const frenchGray = '#c9cacd';
export const ghost = '#cbced7';
export const silver = '#ccc';
export const alto = '#d4d4d4';
export const iron = '#e2e2e3';
export const mercury = '#e5e5e5';
export const gallery = '#eaeaea';
export const gallery3 = '#ececec';
export const gallery2 = '#f0f0f0';
export const concrete = '#f2f2f2';
export const wildSand = '#f6f6f6';
export const alabaster = '#f7f7f7';
export const mischka = '#D9DCE8';
