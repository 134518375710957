type Props = {
	className?: string;
};

export const SnoozeIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M5.61755 4.54161C6.86028 3.54384 8.40629 3 10 3V3.88587C8.60798 3.88587 7.25762 4.36088 6.17216 5.23239C5.0867 6.10389 4.33112 7.3197 4.03033 8.67884C3.72953 10.038 3.90153 11.4591 4.51789 12.7072C5.13426 13.9553 6.15808 14.9558 7.42011 15.5432C8.68215 16.1305 10.1068 16.2697 11.4587 15.9376C12.8105 15.6055 14.0085 14.822 14.8547 13.7167C15.7009 12.6114 16.1446 11.2505 16.1125 9.85882C16.0804 8.46716 15.5743 7.12813 14.678 6.06308L15.3557 5.49267C16.3819 6.71203 16.9613 8.24507 16.9981 9.83836C17.0349 11.4317 16.527 12.9898 15.5581 14.2552C14.5893 15.5207 13.2177 16.4177 11.67 16.7979C10.1223 17.1781 8.4912 17.0188 7.04631 16.3463C5.60142 15.6738 4.42926 14.5284 3.72359 13.0995C3.01793 11.6705 2.82101 10.0435 3.16538 8.48742C3.50976 6.93135 4.37481 5.53938 5.61755 4.54161ZM12.2722 13.014L12.9098 12.1213L10.3969 10.3264V6H9.29981V10.8909L12.2722 13.014Z"
	/>
);
