type Props = {
	className?: string;
};

export const VideoHelpIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		d="M3.5 3H3v10h14V3H3.5zm.5 9V4h12v8H4zm2.5 3h7v-1h-7v1zm10 2h-13v-1h13v1zM12 8l-3 2V6l3 2z"
	/>
);
