type Props = {
	className?: string;
};

export const DismissIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M16.54 2.399l1.061 1.06L3.46 17.601l-1.06-1.06z"
	/>
);
