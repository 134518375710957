type Props = {
	className?: string;
};

export const JourneyDetailIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 5)"
		d="M7.75 7.25V4h1.5v3.25a2.5 2.5 0 1 1-5 0v-4.5a1 1 0 1 0-2 0V6H.75V2.75a2.5 2.5 0 0 1 5 0v4.5a1 1 0 0 0 2 0zM1.5 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm7-7.75a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
	/>
);
