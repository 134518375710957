// @ts-nocheck

import type { ContextRouter, Location } from 'react-router-dom';
import type { Id } from 'types/Id';
import type { User } from 'types/User';
import type { NotificationResponse } from './hooks/useGetUserNotificationsQuery';

import React, { Suspense } from 'react';
import { GQLNavigationMenuPermission } from '@af/api';
import { AuthContext } from '@af/hooks';
import { LDFlagSet, useFlags } from 'launchdarkly-react-client-sdk';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import ClickOutHandler from 'react-onclickout';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { Logout } from 'auth/Logout';
import { Loading } from 'components/Loading';
import { OrganizationIdleTimer } from 'components/OrganizationIdleTimer';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { UserContextProvider } from 'contexts/UserContext';
import { BreadcrumbProvider } from 'ds/components/core/Breadcrumb';
import { GridOverlay } from 'ds/components/core/GridOverlay';
import { Theme } from 'ds/components/core/Theme';
import { NavButtonsProvider } from 'ds/components/Global/NavBar/NavButtons';
import { TOAST_DURATION } from 'ds/components/Toast';
import { hackUserRoles } from 'hacks/users';
import { missingUser, receiveUser } from 'store/actions';
import {
	getPermission as getBrowserNotificationPermission,
	sendNotification,
} from 'utils/browser-notifications';
import { JOURNEY_CENTER_PATH } from 'utils/constants';
import { APP_NOTIFICATION_PORTAL_ID } from 'utils/constants';
import { formatDate, MOMENT_DATETIME_FORMAT } from 'utils/dates';
import { Toast, ToastMessage } from 'v2/components/Toast';

import { NotificationCountContextProvider } from '../contexts/NotificationCountContext';
import { UserPanel } from '../ds/components/UserPanel';
import { tinyBubbles } from '../hacks/tinyBubbles';
import { navigateToLogout } from '../utils/urls';
import { AppNavBar } from './containers/AppNavBar';
import { useGetWatchlistQuery } from './hooks/useGetWatchlistQuery';
import { GlobalNotificationContainer, Main, StyledApp } from './styles';

tinyBubbles();

const LoginAsync = React.lazy(() =>
	import(/* webpackChunkName: "auth-login" */ 'auth/Login').then(module => ({
		default: module.Login,
	})),
);

const FieldConfigurationAsync = React.lazy(() =>
	import(/* webpackChunkName: "fieldConfig" */ 'v2/fieldConfig/index').then(
		module => ({ default: module.FieldConfig }),
	),
);
const DashboardAsync = React.lazy(
	() => import(/* webpackChunkName: "dashboard" */ 'dashboard'),
);
const SetupContainerAsync = React.lazy(
	() => import(/* webpackChunkName: "setup" */ 'setup'),
);
const ViewFakeFactsheetAsync = React.lazy(
	() => import(/* webpackChunkName: "fake-factsheet" */ 'fakeFactsheet/View'),
);
const ViewFactsheetAsync = React.lazy(() =>
	import(/* webpackChunkName: "factsheet" */ 'factsheet').then(module => ({
		default: module.ViewFactsheet,
	})),
);
const JourneyCenterAsync = React.lazy(
	() => import(/* webpackChunkName: "journey-center" */ 'journeyCenter'),
);

const V2Async = React.lazy(() =>
	import(/* webpackChunkName: "v2" */ 'v2').then(module => ({
		default: module.V2,
	})),
);

function AppWrapper({ children }) {
	return (
		<StyledApp>
			<Helmet
				titleTemplate="%s | Access Fintech"
				defaultTitle="Access Fintech"
			/>
			{children}
		</StyledApp>
	);
}

type Props = {
	flags: LDFlagSet;
	user: User;
	orgId: Id;
	isAuthCheckComplete: boolean;
	notifications: Array<NotificationResponse>;
	authGetUser: () => Promise<User>;
	onReceiveUser: (user: User) => void;
	onMissingUser: () => void;
} & ContextRouter;

type State = {
	isDisplayToast: boolean;
	lastUser: User | undefined | null;
	lastLocation: Location;
	isUserPanelOpen: boolean;
	browserNotifications: Array<window.Notification>;
};

class RawApp extends React.Component<Props, State> {
	state = {
		isDisplayToast: true,
		lastUser: this.props.user,
		lastLocation: {
			hash: '',
			pathname: '',
			search: '',
		},
		isUserPanelOpen: false,
		browserNotifications: [],
	};

	static getDerivedStateFromProps(props, state) {
		const { user, location } = props;
		const { lastUser } = state;

		// If new user is not defined
		// And past user was defined
		// Log the user out
		if (!user && !!lastUser) {
			// Reset state on user change, user might log out or session can timeout etc.
			return {
				lastUser: user,
				lastLocation: location,
			};
		}

		return null;
	}

	async componentDidMount() {
		const { authGetUser, onReceiveUser, onMissingUser } = this.props;

		try {
			const user = await authGetUser();
			const hackedUser = hackUserRoles(user);

			// User is already logged in, bootstrap the app
			onReceiveUser(hackedUser);
		} catch {
			onMissingUser();
			navigateToLogout();
		}

		// Notification event listener
		window.addEventListener('beforeunload', this.clearBrowserNotifications);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.clearBrowserNotifications);
	}

	render() {
		const { location, user, orgId, isAuthCheckComplete } = this.props;
		const { isUserPanelOpen, isDisplayToast } = this.state;

		const showNav = user && !location.pathname.includes('/login');

		const hasLastLoginTime = user?.lastLoginTime;
		const hasSingleOrganization = user?.organizationIds?.length === 1;

		return (
			<UserContextProvider user={user} orgId={orgId}>
				{Boolean(user) && (
					<>
						{/* Log the user out after a period of inactivity in the UI */}
						<OrganizationIdleTimer
							onIdle={e => {
								this.closeUserPanel();
								navigateToLogout();
							}}
						/>

						{isDisplayToast && hasLastLoginTime && hasSingleOrganization && (
							<Portal
								node={
									document &&
									document.getElementById(APP_NOTIFICATION_PORTAL_ID)
								}
							>
								<Theme name="v2dark" baseLum={2}>
									<Toast
										autoClose={TOAST_DURATION}
										onDismiss={() => this.setState({ isDisplayToast: false })}
									>
										<ToastMessage>
											Last sign in on{' '}
											{formatDate(user?.lastLoginTime, {
												format: MOMENT_DATETIME_FORMAT,
											})}
										</ToastMessage>
									</Toast>
								</Theme>
							</Portal>
						)}
					</>
				)}
				<NavButtonsProvider>
					<BreadcrumbProvider>
						<GridOverlay />

						<AppWrapper>
							{showNav && (
								<NotificationCountContextProvider>
									<AppNavBar
										isAvatarActive={isUserPanelOpen}
										avatarSrc={user.imageUrl || ''}
										onAvatarClick={this.handleAvatarClick}
										onNewNotifications={this.handleNewNotifications}
									/>
								</NotificationCountContextProvider>
							)}

							<Main>
								<GlobalNotificationContainer id={APP_NOTIFICATION_PORTAL_ID} />
								{showNav && isUserPanelOpen && (
									<ClickOutHandler
										onClickOut={this.handleUserPanelClickOutside}
									>
										<AppUserPanel
											onLogout={() => {
												this.closeUserPanel();
											}}
											onClose={this.handleUserPanelClose}
										/>
									</ClickOutHandler>
								)}
								{isAuthCheckComplete ? (
									<Suspense fallback={<Loading />}>
										<Switch>
											<Route path="/login" component={LoginAsync} />

											<Route path="/logout" component={Logout} />

											<ProtectedRoute
												permissionId={GQLNavigationMenuPermission.Onboarding}
												path={'/setup'}
												component={SetupContainerAsync}
											/>

											<ProtectedRoute
												permissionId={GQLNavigationMenuPermission.Analytics}
												path={'/dashboard'}
												component={DashboardAsync}
											/>

											<ProtectedRoute
												permissionId={GQLNavigationMenuPermission.FieldConfig}
												path={'/field-configuration'}
												component={FieldConfigurationAsync}
											/>

											<ProtectedRoute
												permissionId={GQLNavigationMenuPermission.JourneyCenter}
												path={JOURNEY_CENTER_PATH}
												component={JourneyCenterAsync}
											/>

											<ProtectedRoute
												path={'/factsheet/:contentId'}
												component={ViewFactsheetAsync}
											/>

											<ProtectedRoute
												path={'/factsheet'}
												component={ViewFakeFactsheetAsync}
											/>

											{/* An empty route that redirects to the url in the state */}
											{/* Used to reset UI in cases where we can't control unmounting and mounting of pages with "key" property. */}
											{/* For example global search results redirects user to content details and adjusts query strings but adjusting query strings doesn't happen if user is already on content details page because our "Params" architecture is one way. We can't put search query as a key to the page because it would update on all search filter changes. */}
											<Route
												path={'/redirect'}
												render={props => (
													<Redirect
														// $FlowFixMe: Old flow types doesn't have "state" types
														to={props.location?.state?.nextUrl || '/'}
													/>
												)}
											/>

											<Route path={'/v2'} component={V2Async} />
											<Route path={'/'} component={V2Async} />
										</Switch>
									</Suspense>
								) : (
									<Loading />
								)}
							</Main>
						</AppWrapper>
					</BreadcrumbProvider>
				</NavButtonsProvider>
			</UserContextProvider>
		);
	}

	handleAvatarClick = () => {
		this.openUserPanel();
	};

	handleUserPanelClose = () => {
		this.closeUserPanel();
	};

	handleUserPanelClickOutside = event => {
		if (event?.target?.className?.includes?.('ToastAction')) {
			return;
		}

		event.preventDefault();

		this.closeUserPanel();
	};

	openUserPanel = () => {
		this.setState({ isUserPanelOpen: true });

		// Opening the notifications panel is a natural place to ask for permissions
		// This function is safe to call here
		getBrowserNotificationPermission().catch(e =>
			console.warn('Notification permission error', e),
		);
	};

	closeUserPanel = () => {
		this.setState({ isUserPanelOpen: false });
	};

	handleNewNotifications = async (notificationsCount: number) => {
		if (!notificationsCount) {
			return;
		}
		await sendNotification({
			body: `You have ${notificationsCount} new notifications`,
			onClick: this.openUserPanel,
		});
	};
}

type AppUserPanelProps = {
	onLogout: () => void;
	onClose: () => void;
};

function AppUserPanel({ onLogout, onClose }: AppUserPanelProps) {
	const flags = useFlags();

	const watchlistPollInterval = flags['watchlist-poll-time'];

	const { count: subscriptionsCount } = useGetWatchlistQuery({
		pollInterval: watchlistPollInterval,
	});

	return (
		<NotificationCountContextProvider>
			<UserPanel
				watchlistCount={subscriptionsCount}
				onLogout={onLogout}
				onClose={onClose}
			/>
		</NotificationCountContextProvider>
	);
}

const mapStateToProps = ({ app: { user, orgId, isAuthCheckComplete } }) => ({
	user,
	orgId,
	isAuthCheckComplete,
});

const mapDispatchToProps = {
	onReceiveUser: receiveUser,
	onMissingUser: missingUser,
};

// Order of these HOC are important because of a bug(limitation?) in react-router
// https://github.com/ReactTraining/react-router/issues/4671
const AppWithData = connect(mapStateToProps, mapDispatchToProps)(RawApp);

const AppWithRouterAndData = withRouter(AppWithData);

const AppWithAuthConsumer = (props: {}) => (
	<AuthContext.Consumer>
		{({ getUser }) => <AppWithRouterAndData authGetUser={getUser} {...props} />}
	</AuthContext.Consumer>
);

const AppWithFlags = withLDConsumer()(AppWithAuthConsumer);

export { AppWithFlags as App };
