// @ts-nocheck

import React from 'react';

import { Typography } from 'ds/components/Typography';
import { getAppVersion } from 'utils/environment';

const APP_VERSION = getAppVersion();

type Props = {
	className?: string;
};

export const AppVersion = ({ className }: Props) => (
	<Typography size="tiny" className={className}>
		{APP_VERSION}
	</Typography>
);
