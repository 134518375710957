type Props = {
	className?: string;
};

export const HoldReleaseIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(-2, -2)"
		fillRule="evenodd"
		clipRule="evenodd"
		d="M8 8H16V16H8V8ZM9 9V15H15V9H9Z"
	/>
);
