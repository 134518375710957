/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type { NotificationStatus } from 'types/Notification';

export const STATUS: {
	[x: string]: NotificationStatus;
} = {
	READ: 'read',
	UNREAD: 'unread',
	DISMISSED: 'dismissed',
};
const NOTIFICATION_STATUSES: Array<NotificationStatus> = [
	STATUS.UNREAD,
	STATUS.READ,
	STATUS.DISMISSED,
];

export const ALLOWED_NOTIFICATION_STATUSES: Array<NotificationStatus> =
	NOTIFICATION_STATUSES.filter(status => status !== STATUS.DISMISSED);

export const TYPE = {
	ASSIGNMENT: 'assignment',
	COMMENT: 'comment',
	MENTION: 'mention',
	GROUP_ASSIGNMENT: 'groupassignment',
	EXPORT_FILE_READY: 'exportfileready',
};

export const ALLOWED_NOTIFICATION_TYPES: Array<string> = Object.keys(TYPE).map(
	type => TYPE[type],
);
