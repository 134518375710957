type Props = {
	className?: string;
};

export const FrequencyIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M6.05 7a2.5 2.5 0 100-1H4v1h2.05zM16 7h-4V6h4v1zm-7 6.5a2.5 2.5 0 004.95.5H16v-1h-2.05a2.5 2.5 0 00-4.95.5zM8 14v-1H4v1h4z"
	/>
);
