// @ts-nocheck

import type { ReactNode } from 'react';
import type { QueryResult } from '@apollo/react-hooks';
import type { GQLUser, GQLOrganization } from '@af/api';

import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const USER_ME_QUERY = gql`
	query GetUser {
		userMe {
			id
			imageUrl
			firstName
			name
			userName
			jobTitle
			roleIds
			organizations {
				id
				name
			}
		}
	}
`;

type LocalUserMe = {
	id: GQLUser['id'];
	firstName: GQLUser['firstName'];
	name: GQLUser['name'];
	userName: GQLUser['userName'];
	roleIds: GQLUser['roleIds'];
	organizations: Array<{
		id: GQLOrganization['id'];
		name: GQLOrganization['name'];
	}>;
};

type QueryData = {
	userMe: LocalUserMe;
};

type QueryVariables = {};

function useUserMeQuery(): QueryResult<QueryData, QueryVariables> & {
	user: LocalUserMe | undefined | null;
} {
	const queryProps = useQuery<QueryData, QueryVariables>(USER_ME_QUERY);
	const { data } = queryProps;

	const user = data && data.userMe;

	return { ...queryProps, user };
}

const UserMeContext = createContext<LocalUserMe | undefined | null>(null);

type Props = {
	children: ReactNode;
};

const UserMeProvider = ({ children }: Props) => {
	const { user } = useUserMeQuery();

	return (
		<UserMeContext.Provider value={user}>
			{user && children}
		</UserMeContext.Provider>
	);
};

const useUserMe = (): LocalUserMe => {
	const user = useContext(UserMeContext);

	if (!user) {
		throw new Error('Missing provider `UserMeProvider`');
	}

	return user;
};

export { UserMeProvider, useUserMe };
