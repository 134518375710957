import type { ThemeName } from 'ds/styles/themes';
import type { TypeSizeName } from 'ds/styles/typography';
import type { ReactNode } from 'react';

import React from 'react';
import { ThemeProvider } from 'styled-components';

import { BASE_THEMES } from 'ds/styles/themes';
import { convertBaseThemeToTheme } from 'ds/styles/utils';
import { useTheme } from 'hooks/useTheme';

export function Theme({
	name,
	baseLum,
	typographySize,
	children,
}: {
	name?: ThemeName;
	baseLum?: number;
	typographySize?: TypeSizeName;
	children: ReactNode;
}) {
	const themeContext = useTheme();
	const themeId = name || (themeContext ? themeContext.id : 'v2dark');

	const theme = convertBaseThemeToTheme(
		BASE_THEMES[themeId],
		baseLum != null ? baseLum : themeContext && themeContext.color.baseLum,
		typographySize != null
			? typographySize
			: themeContext && themeContext.typography.size.name,
	);

	return (
		<ThemeProvider theme={theme}>
			<React.Fragment>{children}</React.Fragment>
		</ThemeProvider>
	);
}
