type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const SettingsIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 12a2 2 0 11-4 0 2 2 0 014 0zm-1 0a1 1 0 11-2 0 1 1 0 012 0z"
			fill={color || 'currentColor'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.895 5.5l-.635 1.824c-.109.035-.236.08-.362.136l-1.667-.873L6.587 8.23l.873 1.667c-.057.126-.101.253-.136.362l-1.824.635v2.21l1.825.635c.03.078.06.148.086.209l.004.01c.027.064.05.118.07.169l-.898 1.716 1.57 1.57 1.667-.874c.147.065.3.114.442.152l.629 1.808h2.21l.635-1.825c.078-.03.148-.06.209-.086l.01-.004c.064-.027.118-.05.169-.07l1.716.898 1.57-1.57-.874-1.667c.065-.148.114-.3.152-.442l1.808-.629v-2.21l-1.824-.635a3.612 3.612 0 00-.136-.362l.873-1.667-1.57-1.57-1.667.874a3.216 3.216 0 00-.438-.151L13.11 5.5h-2.215zm.71 1h.785l.572 1.719.267.066c.323.08.53.139.669.231l.246.164 1.512-.792.53.53-.791 1.513.164.246c.078.117.123.26.183.449l.059.182.077.233 1.622.564v.79l-1.645.572-.065.262c-.08.323-.139.53-.231.669l-.164.246.791 1.512-.53.53-1.418-.742-.184.046c-.19.047-.352.117-.478.17l-.01.005a2.2 2.2 0 01-.337.125l-.262.066-.572 1.644h-.79l-.572-1.645-.262-.065c-.323-.08-.53-.139-.669-.231l-.246-.164-1.512.791-.53-.53.742-1.418-.046-.184c-.047-.19-.117-.352-.17-.478l-.005-.01a2.211 2.211 0 01-.125-.337l-.065-.262-1.645-.572v-.79l1.622-.564.077-.233.06-.182c.059-.19.104-.332.182-.449l.164-.246-.792-1.512.606-.606 1.512.792.246-.164c.117-.078.26-.123.449-.183l.182-.059.233-.077.564-1.622z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default SettingsIcon;
