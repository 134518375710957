type Props = {
	className?: string;
};

export const OpenCloseIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(7 6)"
		d="M2 4.792a2.5 2.5 0 1 1 2 0V8H2V4.792zM3 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-.25 0v3.25h.5V4h-.5z"
	/>
);
