type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const FavouriteIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.49 10.427a.193.193 0 00-.155-.13l-3.567-.519-1.595-3.232c-.065-.132-.281-.132-.346 0l-1.595 3.232-3.567.518a.193.193 0 00-.107.33L9.14 13.14l-.61 3.552a.193.193 0 00.281.204L12 15.22l3.19 1.677a.193.193 0 00.28-.204l-.609-3.552 2.58-2.516a.193.193 0 00.05-.198z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default FavouriteIcon;
