type Props = {
	className?: string;
};

export const BuyInIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M11.517 5.244L7.174 9.587l-.663 2.652 2.652-.663 4.343-4.343-1.99-1.989zm2.65 8.09v.833H5.833v-.834h8.334zm-2.65-6.911l.81.81-3.59 3.59-1.081.27.27-1.081 3.59-3.59z"
	/>
);
