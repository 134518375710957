import type { ReactNode } from 'react';

import { createContext, useContext, useMemo, useState } from 'react';

type RiskSummaryContextType = {
	isInvalidateCache: boolean;
	setIsInvalidateCache: (newValue: boolean) => void;
};

const DEFAULT_CONTEXT: RiskSummaryContextType = {
	isInvalidateCache: false,
	setIsInvalidateCache: () => undefined,
};

const RiskSummaryContext =
	createContext<RiskSummaryContextType>(DEFAULT_CONTEXT);

function RiskSummaryContextProvider({ children }: { children: ReactNode }) {
	const [isInvalidateCache, setIsInvalidateCache] = useState(false);
	const context = useMemo(
		() => ({ isInvalidateCache, setIsInvalidateCache }),
		[isInvalidateCache, setIsInvalidateCache],
	);

	return (
		<RiskSummaryContext.Provider value={context}>
			{children}
		</RiskSummaryContext.Provider>
	);
}

function useRiskSummaryContext() {
	const context = useContext(RiskSummaryContext);

	if (!context) {
		throw new Error(`Missing 'RiskSummaryContextProvider'`);
	}

	return context;
}

// eslint-disable-next-line import/no-unused-modules
export { RiskSummaryContext };
export { RiskSummaryContextProvider };
export { useRiskSummaryContext };
