type Props = {
	className?: string;
};

export const DontKnow = ({ className }: Props) => (
	<path
		className={className}
		d="M15 5.083l-5 9-5-9h10zm-1.5 1h-7l3.5 6 3.5-6z"
		transform="matrix(1 0 0 -1 0 19.167)"
	/>
);
