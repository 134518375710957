// @ts-nocheck

import type { Expression } from 'types/Expression';
import type { Operand } from 'types/Expression';
import type { FieldOverrideConfig, FieldResourceType } from 'types/Field';
import type { FieldDictionary } from 'types/Field';
import type { Id } from 'types/Id';

import { warn } from '@af/utils';

import store from 'store';
import { getFieldWithKey } from 'utils/expressionsv2/field';
import { RESOURCE_TYPE } from 'utils/field';

// Create a composite key for each field to provide uniqueness
export const constructCompositeKey = (
	resourceType: FieldResourceType,
	name: Id,
	contentTypeId?: Id | null,
	orgId?: Id | null,
	productLineId?: Id | null,
): string => {
	let compositeKey = `rt:${resourceType}|name:${name}`;
	let parentId;

	if (
		resourceType === RESOURCE_TYPE.clientField &&
		typeof contentTypeId !== 'undefined' &&
		contentTypeId !== null
	) {
		parentId = `ct:${contentTypeId}`;

		if (orgId) {
			parentId = `${parentId}|org:${orgId}`;
		}
	}
	if (
		resourceType === RESOURCE_TYPE.genericField &&
		typeof contentTypeId !== 'undefined' &&
		contentTypeId !== null
	) {
		parentId = `ct:${contentTypeId}`;
	}

	if (
		resourceType === RESOURCE_TYPE.productLineField &&
		typeof productLineId !== 'undefined' &&
		productLineId !== null
	) {
		parentId = `pl:${productLineId}`;
	}

	if (parentId) {
		compositeKey += `|${parentId}`;
	}

	return compositeKey;
};

export function getCompositeKey(
	resourceType: FieldResourceType,
	name: Id,
	contentTypeId?: Id | null,
	orgId?: Id | null,
	productLineId?: Id | null,
): string | undefined | null {
	const fieldDictionary: FieldDictionary = store.getState().app.fieldDictionary;

	if (resourceType === RESOURCE_TYPE.clientField) {
		const specificKey = constructCompositeKey(
			RESOURCE_TYPE.clientField,
			name,
			contentTypeId,
			orgId,
		);

		const field = getFieldWithKey(specificKey, fieldDictionary);

		if (field) {
			return specificKey;
		} else {
			const globalCompositeKey = constructCompositeKey(
				RESOURCE_TYPE.clientField,
				name,
				contentTypeId,
			);

			const field = getFieldWithKey(globalCompositeKey, fieldDictionary);

			if (field) {
				return globalCompositeKey;
			}
		}
	} else if (resourceType === RESOURCE_TYPE.productLineField) {
		const specificKey = constructCompositeKey(
			RESOURCE_TYPE.productLineField,
			name,
			null,
			null,
			productLineId,
		);

		const field = getFieldWithKey(specificKey, fieldDictionary);

		if (field) {
			return specificKey;
		} else {
			const globalCompositeKey = constructCompositeKey(
				RESOURCE_TYPE.productLineField,
				name,
				null,
				null,
				null,
			);

			const field = getFieldWithKey(globalCompositeKey, fieldDictionary);

			if (field) {
				return globalCompositeKey;
			}
		}
	}

	warn('Could not determine field using parameters:', ...arguments);

	return;
}

const addMissingFieldProps = (
	operand: Operand,
	config: FieldOverrideConfig,
): Operand => {
	if (operand.valueType === RESOURCE_TYPE.constant) {
		return operand;
	}

	if (
		operand.valueType === RESOURCE_TYPE.productLineField &&
		(typeof operand.productLineId === 'undefined' ||
			operand.productLineId === null) &&
		config.productLineId
	) {
		return { ...operand, productLineId: config.productLineId };
	}

	if (
		operand.valueType === RESOURCE_TYPE.clientField &&
		(typeof operand.contentTypeId === 'undefined' ||
			operand.contentTypeId === null) &&
		config.contentTypeId
	) {
		return { ...operand, contentTypeId: config.contentTypeId };
	}

	return operand;
};

export const addMissingFieldPropsToExpression = (
	expression: Expression,
	config: FieldOverrideConfig,
): Expression => {
	// Join
	if (expression.expressions) {
		return {
			...expression,
			expressions: expression.expressions.map(e =>
				addMissingFieldPropsToExpression(e, config),
			),
		};
	}

	if (expression.left && expression.right) {
		return {
			...expression,
			left: addMissingFieldProps(expression.left, config),
			right: Array.isArray(expression.right)
				? expression.right.map(r => addMissingFieldProps(r, config))
				: addMissingFieldProps(expression.right, config),
		};
	} else if (expression.value) {
		return {
			...expression,
			value: addMissingFieldProps(expression.value, config),
		};
	} else if (expression.lowerBound && expression.upperBound) {
		return {
			...expression,
			lowerBound: addMissingFieldProps(expression.lowerBound, config),
			upperBound: addMissingFieldProps(expression.upperBound, config),
		};
	} else {
		console.warn(
			'Could not add missing field props to expression:',
			expression,
		);
	}

	return expression;
};
