import type { Theme, ThemeName } from 'ds/styles/themes';

import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { BASE_THEMES } from 'ds/styles/themes';
import { convertBaseThemeToTheme } from 'ds/styles/utils';

export const useTheme = (name?: ThemeName): Theme => {
	const theme = useContext(ThemeContext);
	return name ? convertBaseThemeToTheme(BASE_THEMES[name]) : theme;
};
