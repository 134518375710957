// @ts-nocheck

import React from 'react';

import Icon, { ICONS } from 'ds/components/Icon';
import { useCurrentUser } from 'hooks/useCurrentUser';

// Soon we will replace the AF logo with org logos which have come through the design team
// Until then, we're using this hack to get the Citi logo in temporarily
// I'm sorry.

export function HackLogo() {
	const { user } = useCurrentUser();

	if (user && user.userName.match(/@(citi|citibank).com$/gi)) {
		return (
			<img
				src={'/citi-logo.png'}
				alt={'Access Fintech'}
				style={{ width: 50, height: 50, marginLeft: -10 }}
			/>
		);
	}

	return <Icon config={ICONS.logo} size="medium" />;
}
