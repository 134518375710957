// @ts-nocheck

import type { ReactNode } from 'react';
import type { ComponentType } from 'types/Component';

import styled from 'styled-components';

type Props = {
	size?: number;
	color?: string;
	children?: ReactNode;
};

const Dot: ComponentType<Props> = styled.span`
	display: inline-block;
	vertical-align: middle;
	min-width: ${props => props.size && `${props.size}px`};
	min-height: ${props => props.size && `${props.size}px`};
	border-radius: ${props => props.size && `${props.size}px`};
	background-color: ${({ theme, color }) =>
		color || theme.color.palette.brandBlue.base};
`;

Dot.defaultProps = {
	size: 8,
};

export { Dot };
