// @ts-nocheck

import React from 'react';
import { DownloadIcon, TickedSolidIcon } from '@af/icons';

import { Typography } from 'ds/components/Typography';

import {
	BadgeAndTitleWrapper,
	FileNameWrapper,
	LeftIconButton,
	ListRow,
	ProgressBarWrapper,
	RightSideButton,
} from './styles';

type Props = {
	fileUrl: string;
	fileName: string;
};

export function Complete({ fileUrl, fileName }: Props) {
	return (
		<ListRow>
			<BadgeAndTitleWrapper>
				<LeftIconButton>
					<TickedSolidIcon />
				</LeftIconButton>
				<FileNameWrapper>
					<Typography align="center">{fileName}</Typography>
				</FileNameWrapper>
				<RightSideButton
					href={fileUrl}
					target="_blank"
					rel="noopener noreferrer"
				>
					<DownloadIcon />
				</RightSideButton>
			</BadgeAndTitleWrapper>
			<ProgressBarWrapper>
				<Typography>Your download is ready</Typography>
			</ProgressBarWrapper>
		</ListRow>
	);
}
