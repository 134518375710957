type Props = {
	className?: string;
};

export const AssignIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M10 15c-1.669 0-3.218-.65-4.5-1.818.638-1.6 2.142-2.727 3.6-2.727h1.8c1.458 0 2.962 1.127 3.6 2.727C13.218 14.349 11.669 15 10 15zm0-5.455a2.261 2.261 0 0 1-2.25-2.272A2.261 2.261 0 0 1 10 5a2.261 2.261 0 0 1 2.25 2.273A2.261 2.261 0 0 1 10 9.545z"
	/>
);
