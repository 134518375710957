/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type {
	GQLExportStatus,
	GQLQueryOrganizationArgs,
	GQLUser,
} from '@af/api';

import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useCurrentUser } from 'hooks/useCurrentUser';

const GET_ACTIVE_EXPORT_STATUS = gql`
	query GetActiveExportStatus($orgId: ID!) {
		userMe {
			id
			activeExports(contextOrgId: $orgId) {
				docId
				fileName
				status
				totalItemsCount
				uploadedItemsCount
			}
		}
	}
`;

export type ActiveExportResponse = {
	docId: GQLExportStatus['docId'];
	status: GQLExportStatus['status'];
	fileName: GQLExportStatus['fileName'];
	totalItemsCount: GQLExportStatus['totalItemsCount'];
	uploadedItemsCount: GQLExportStatus['uploadedItemsCount'];
	__typename: GQLExportStatus['__typename'];
};

export type QueryResponse = {
	userMe: {
		id: GQLUser['id'];
		activeExports: Array<ActiveExportResponse>;
		__typename: GQLUser['__typename'];
	};
};

type QueryVariables = {
	orgId: GQLQueryOrganizationArgs['id'];
};

export function useGetActiveExportStatus({
	pollInterval,
}: {
	pollInterval: number;
}) {
	const { orgId } = useCurrentUser();
	const response = useQuery<QueryResponse, QueryVariables>(
		GET_ACTIVE_EXPORT_STATUS,
		{
			variables: { orgId },
			pollInterval,
		},
	);

	const activeExports = useMemo(() => {
		return response.data?.userMe?.activeExports.filter(Boolean) || [];
	}, [response]);

	return {
		...response,
		activeExports,
	};
}
