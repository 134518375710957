type Props = {
	className?: string;
};

export const UndoMergeIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(-2, -2)"
		d="M10 6H18V14H15V13H17V7H11V9H10V6ZM6 10H9V11H7V17H13V15H14V18H6V10ZM12 10H14V12H13V11H12V10ZM10 12H11V13H12V14H10V12Z"
		fillRule="evenodd"
		clipRule="evenodd"
	/>
);
