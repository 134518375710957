type Props = {
	className?: string;
};

export const CompareContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M10,14 L10,14.999252 L9,14.999252 L9,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,7 C5,6.44771525 5.44771525,6 6,6 L9,6 L9,4.99925199 L10,4.99925199 L10,6 L10,14 Z M11,14 L11,9 L11.0006649,9 L13.9980053,12.9960106 L13.9980053,9 L14,9 L14,7 L11,7 L11,6 L14,6 C14.5522847,6 15,6.44771525 15,7 L15,13 C15,13.5522847 14.5522847,14 14,14 L11,14 Z M6.00066489,12.9960106 L8.99800532,12.9960106 L8.99800532,9 L6.00066489,12.9960106 Z"
	/>
);
