import type { IconName } from 'ds/components/Icon';
import type { ReactNode } from 'react';

import styled from 'styled-components';

import { ICONS } from 'ds/components/Icon';

import { StyledIcon, Wrapper } from './styles';

export const variants = {
	small: { maxWidth: '64px', minWidth: '64px', marginBottom: '16px' },
	medium: { maxWidth: '96px', minWidth: '96px', marginBottom: '16px' },
	large: { maxWidth: '128px', minWidth: '128px', marginBottom: '24px' },
};

export type FullStatusVariant = keyof typeof variants;

type Props = {
	icon: IconName;
	badgeVariant?: string;
	variant?: FullStatusVariant;
	children: ReactNode | undefined | null;
};

const RawFullStatus = ({
	icon,
	variant,
	children,
	badgeVariant,
	...props
}: Props) => {
	return (
		<Wrapper {...props}>
			<StyledIcon
				config={ICONS[icon]}
				size={variant}
				badgeVariant={badgeVariant}
			/>
			{children}
		</Wrapper>
	);
};

const FullStatus = styled(RawFullStatus)``;

FullStatus.defaultProps = {
	variant: 'medium',
	icon: 'tick',
};

export default FullStatus;
