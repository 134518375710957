type Props = {
	className?: string;
};

export const PauseIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(8, 7)"
		d="M3 0h1v6H3V0zM0 0h1v6H0V0z"
	/>
);
