// @ts-nocheck

import type { Dispatch } from 'redux';
import type { ContentType as OldContentType } from 'types/ContentType';
import type { Field as OldField } from 'types/Field';
import type { ProductLine as OldProductLine } from 'types/ProductLine';

import { keyBy, orderBy } from 'lodash-es';

import { constructCompositeKey } from 'hacks/fieldValues';
import { requestFields } from 'store/actions';
import {
	RECEIVE_CONTENT_TYPES,
	RECEIVE_FIELDS,
	RECEIVE_PRODUCT_LINES,
} from 'store/actionTypes';
import { getFieldFriendlyName } from 'utils/expressionsv2/field';
import { RESOURCE_TYPE } from 'utils/field';

import { loadInitialData } from './query';

export const processInitialData =
	(orgId: string) =>
	async (
		dispatch: Dispatch<any>, //Not sure what to put here
	) => {
		dispatch(requestFields);

		const response = await loadInitialData({ orgId });

		const {
			clientFields,
			productFields,
			systemFields,
			genericFields,
			contentTypeList,
			productLineList,
		} = response;

		const hackedContentTypes: Array<OldContentType> = contentTypeList.map(
			contentType => ({
				id: contentType.id,
				name: contentType.name,
				isEnabled: contentType.isEnabled,
				displayName: contentType.displayName,
				description: contentType.description,
				permissions: contentType.permitted ? ['read', 'use'] : ['read'],
			}),
		);

		const hackedProductLines: Array<OldProductLine> = productLineList.map(
			productLine => ({
				id: productLine.id,
				name: productLine.name || 'Unknown',
				description: productLine.description,
				url: productLine.url,
				vendorOrganizationId: productLine.vendorOrganizationId,
				contentTypeId: productLine.contentTypeId,
				canSendAlleged: false,
				connectionIds: productLine.connectionIds.filter(Boolean),
				permissions: productLine.permitted ? ['read', 'use'] : ['read'],
			}),
		);

		dispatch(
			receiveFields({
				clientFields,
				productFields,
				systemFields,
				genericFields,
				hackedContentTypes,
				hackedProductLines,
			}),
		);
		dispatch(receiveContentTypes(hackedContentTypes));
		dispatch(receiveProductLines(hackedProductLines));
	};

const receiveFields = ({
	clientFields,
	productFields,
	systemFields,
	genericFields,
	hackedContentTypes,
	hackedProductLines,
}) => {
	const hackedClientFields: Array<OldField> = clientFields.map(field => ({
		name: field.name,
		contentTypeId: field.contentTypeId,
		organizationId: field.organizationId,
		genericFieldId: field.genericFieldId,
		fieldFriendlyName: field.fieldFriendlyName,
		type: field.type,
		isTimeBased: field.isTimeBased,
		titleRank: field.titleRank,
		frequencyRank: field.frequencyRank,
		isRiskFactor: field.isRiskFactor,

		// Additional stuff
		fieldId: field.name,
		contentType: hackedContentTypes.find(c => c.id === field.contentTypeId),
		resourceType: RESOURCE_TYPE.clientField,
		fieldValues: [],
		values: [],
		productLineId: null,
		productLine: null,
		id: constructCompositeKey(
			RESOURCE_TYPE.clientField,
			field.name,
			field.contentTypeId,
			field.organizationId,
			null,
		),
	}));

	const hackedProductFields: Array<OldField> = productFields.map(field => ({
		name: field.name,
		fieldFriendlyName: field.fieldFriendlyName,
		type: field.type,
		productLineId: field.productLineId,

		// Additional stuff
		fieldId: field.name,
		organizationId: null,
		contentTypeId: null,
		genericFieldId: null,
		isTimeBased: false,
		titleRank: null,
		frequencyRank: null,
		isRiskFactor: false,
		productLine: hackedProductLines.find(p => p.id === field.productLineId),
		resourceType: RESOURCE_TYPE.productLineField,
		/**
		 * $FlowFixMe - can't figure this out
		 *  Cannot call await with loadInitialData(...) bound to p because:
 			• Either property value is missing in null or undefined [1].
 			• Or property clientFields is missing in Promise [2].
		 */
		values: field.values ? field.values.map<string>(({ value }) => value) : [],
		fieldValues: field.values || [],
		id: constructCompositeKey(
			RESOURCE_TYPE.productLineField,
			field.name,
			null,
			null,
			field.productLineId,
		),
	}));

	const hackedSystemFields: Array<OldField> = systemFields.map(field => ({
		name: field.name,
		type: field.type,

		// Additional stuff
		fieldId: field.name,
		contentTypeId: null,
		productLineId: null,
		contentType: null,
		productLine: null,
		organizationId: null,
		genericFieldId: null,
		fieldFriendlyName: field.friendlyName,
		values: [],
		fieldValues: [],
		resourceType: RESOURCE_TYPE.systemField,
		isTimeBased: false,
		titleRank: null,
		frequencyRank: null,
		isRiskFactor: false,
		id: constructCompositeKey(
			RESOURCE_TYPE.systemField,
			field.name,
			null,
			null,
			null,
		),
	}));

	const hackedGenericFields = genericFields.map(field => ({
		name: field.name,
		fieldId: field.name,
		type: field.type,
		contentTypeId: field.contentTypeId,

		fieldFriendlyName: field.name,
		resourceType: RESOURCE_TYPE.genericField,
		fieldValues: [],
		values: [],

		// id: field.key
		id: constructCompositeKey(
			RESOURCE_TYPE.genericField,
			field.name,
			field.contentTypeId,
			null,
			null,
		),
	}));

	const fieldList = [
		...hackedClientFields,
		...hackedProductFields,
		...hackedSystemFields,
		...hackedGenericFields,
	];

	const fieldDictionary = keyBy(fieldList, 'id');

	// $FlowFixMe: It is difficult to type
	const orderedClientFields = orderBy(
		hackedClientFields,
		[field => getFieldFriendlyName(field)],
		['asc'],
	);

	// $FlowFixMe: It is difficult to type
	const orderedProductFields = orderBy(
		hackedProductFields,
		[field => getFieldFriendlyName(field)],
		['asc'],
	);

	// $FlowFixMe: It is difficult to type
	const orderedGenericFields = orderBy(
		hackedGenericFields,
		[field => getFieldFriendlyName(field)],
		['asc'],
	);

	return {
		type: RECEIVE_FIELDS,
		fieldList,
		fieldDictionary,
		clientFieldList: orderedClientFields,
		productFieldList: orderedProductFields,
		genericFieldList: orderedGenericFields,
	};
};

const receiveContentTypes = hackedContentTypes => {
	const orderedContentTypes = orderBy(hackedContentTypes, 'name', 'asc');

	return {
		type: RECEIVE_CONTENT_TYPES,
		contentTypeList: orderedContentTypes,
	};
};

const receiveProductLines = hackedProductLines => {
	const orderedProductLines = orderBy(hackedProductLines, 'name', 'asc');

	return {
		type: RECEIVE_PRODUCT_LINES,
		productLineList: orderedProductLines,
	};
};
