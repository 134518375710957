import { useTheme } from '@af/ds';
type Props = {
	color?: string;
	secondaryColor?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const TickedSolidIcon = ({
	color,
	secondaryColor,
	size = 24,
	title,
	titleId,
	...props
}: Props) => {
	const theme = useTheme();
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			aria-labelledby={titleId}
			{...props}
		>
			{title ? <title id={titleId}>{title}</title> : null}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
				fill={color || 'currentColor'}
			/>
			<path
				d="M7.5 12.5l3.071 3.5L16.5 7.5"
				stroke={secondaryColor || theme.color.bg.base}
				strokeWidth={1.2}
			/>
		</svg>
	);
};

export default TickedSolidIcon;
