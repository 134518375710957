import { timingFunctions } from 'polished';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import { Loading } from 'components/Loading';
import Icon from 'ds/components/Icon';
import { Typography } from 'ds/components/Typography';
import { absoluteCenter } from 'styles/helper';

export const Header = styled.header`
	flex: 0 0 165px;
	padding: ${props => props.theme.spacing.small}px;
	padding-top: ${props => props.theme.spacing.large}px;
`;

export const AvatarWrapper = styled.div`
	position: relative;
`;

export const StyledLoading = styled(Loading)`
	${absoluteCenter};
`;

export const AddIcon = styled(Icon)`
	${absoluteCenter};
	opacity: 0;
	transition: 210ms ${timingFunctions('easeOutQuad')};
	transition-property: opacity;

	${AvatarWrapper}:hover & {
		opacity: 1;
	}
`;

export const StyledAvatar = styled(Avatar)`
	margin: 0 auto ${props => props.theme.spacing.small}px;
	transition: opacity 210ms ${timingFunctions('easeOutQuad')};

	${AvatarWrapper}:hover & {
		opacity: 0.7;
	}
`;

export const LogoutWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: ${props => props.theme.spacing.small}px;
`;

export const Name = styled(Typography).attrs(() => ({
	forwardedAs: 'h2',
	size: 'large',
	align: 'center',
}))``;

export const JobTitle = styled(Typography).attrs(() => ({
	forwardedAs: 'h3',
	size: 'small',
	align: 'center',
}))`
	position: relative;
	top: -2px;
`;
