import type {
	GQLMutationUpdateNotificationSettingsArgs,
	GQLNotificationSettingsEventData,
} from '@af/api';
import type {
	QueryResponse,
	QueryVariables,
} from './useGetUserNotificationSettingsQuery';

import { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { UserContext } from 'contexts/UserContext';

import { GET_USER_NOTIFICATION_SETTINGS_QUERY } from './useGetUserNotificationSettingsQuery';

const UPDATE_NOTIFICATION_SETTINGS_MUTATION = gql`
	mutation updateNotificationSettings(
		$contextOrgId: ID!
		$updateNotificationSettings: [NotificationSettingsEventDataInput!]!
	) {
		updateNotificationSettings(
			contextOrgId: $contextOrgId
			updateNotificationSettings: $updateNotificationSettings
		) {
			eventType
			isEnabled
		}
	}
`;

type MutationVariables = {
	contextOrgId: GQLMutationUpdateNotificationSettingsArgs['contextOrgId'];
	updateNotificationSettings: GQLMutationUpdateNotificationSettingsArgs['updateNotificationSettings'];
};

type MutationResponse = {
	updateNotificationSettings: Array<{
		eventType: GQLNotificationSettingsEventData['eventType'];
		isEnabled: GQLNotificationSettingsEventData['isEnabled'];
	}>;
};

export function useUpdateNotificationSettingsMutation() {
	const { orgId } = useContext(UserContext);

	return useMutation<MutationResponse, MutationVariables>(
		UPDATE_NOTIFICATION_SETTINGS_MUTATION,
		{
			update: (cache, response) => {
				const data = cache.readQuery<QueryResponse, QueryVariables>({
					query: GET_USER_NOTIFICATION_SETTINGS_QUERY,
					variables: {
						contextOrgId: orgId,
					},
				});

				if (!data?.userMe || !response?.data) {
					return;
				}

				const updatedItems = response?.data?.updateNotificationSettings;

				const updatedQuery = data?.userMe.notificationSettings.map(
					item =>
						updatedItems.find(
							changedItem => changedItem.eventType === item.eventType,
						) || item,
				);

				cache.writeQuery({
					query: GET_USER_NOTIFICATION_SETTINGS_QUERY,
					variables: {
						contextOrgId: orgId,
					},
					data: {
						...data,
						userMe: {
							...data.userMe,
							notificationSettings: updatedQuery,
						},
					},
				});
			},
		},
	);
}
