// @ts-nocheck

import type {
	GQLCappedContentRecords,
	GQLContentRecordsSearchQueryParams,
	GQLQuerySearchContentRecordsArgs,
} from '@af/api';
import type { GenericField } from './';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
	createMultipleValueExpression,
	createSingleValueExpression,
} from 'ds/components/FieldValuePicker/utils';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { OPERATORS } from 'utils/expressionsv2/operator';

import { SEARCH_FIELDS_DICT } from '.';

const GLOBAL_SEARCH_CONTENT_RECORDS = gql`
	query GlobalSearchContentRecords($orgId: ID!, $filterExpression: Json!) {
		searchContentRecords(
			orgId: $orgId
			filter: { filterType: ALL, filterExpression: $filterExpression }
			limit: 0
		) {
			totalItemsCount
		}
	}
`;

type QueryResponse = {
	searchContentRecords: {
		totalItemsCount: GQLCappedContentRecords['totalItemsCount'];
	};
};

type QueryVariables = {
	orgId: GQLQuerySearchContentRecordsArgs['orgId'];
	filterExpression: GQLContentRecordsSearchQueryParams['filterExpression'];
};

type HookParams = {
	searchTexts: Array<string>;
	searchFields: Array<GenericField>;
};

type SearchResult = {
	expression: any;
	label: string;
	count: number;
};

// Fixes race condition by setting `true` on all queries
// https://github.com/apollographql/react-apollo/issues/3270#issuecomment-571659025
const notifyOnNetworkStatusChange = true;

export function useGlobalSearchQuery({
	searchTexts,
	searchFields,
}: HookParams) {
	const { orgId } = useCurrentUser();
	const skip = !Boolean(searchTexts.length);

	const getKeyByName = name =>
		searchFields.find(field => field.name === name)?.key;

	const counterpartyKey = getKeyByName(SEARCH_FIELDS_DICT.CP) || '';
	const isinKey = getKeyByName(SEARCH_FIELDS_DICT.ISIN) || '';
	const referenceIdKey = getKeyByName(SEARCH_FIELDS_DICT.REF) || '';
	const manualLinkKey = getKeyByName(SEARCH_FIELDS_DICT.LINK) || '';

	const counterpartyExpression =
		searchTexts.length > 1
			? createMultipleValueExpression({
					key: counterpartyKey,
					operator: OPERATORS.in,
					values: searchTexts,
			  })
			: createSingleValueExpression({
					key: counterpartyKey,
					operator: OPERATORS.contains,
					value: searchTexts[0],
			  });

	const counterpartyResponse = useQuery<QueryResponse, QueryVariables>(
		GLOBAL_SEARCH_CONTENT_RECORDS,
		{
			skip: skip || !counterpartyKey,
			variables: {
				orgId,
				filterExpression: counterpartyExpression,
			},
			notifyOnNetworkStatusChange,
		},
	);

	const isinExpression = createMultipleValueExpression({
		key: isinKey,
		operator: OPERATORS.in,
		values: searchTexts,
	});

	const isinResponse = useQuery<QueryResponse, QueryVariables>(
		GLOBAL_SEARCH_CONTENT_RECORDS,
		{
			skip: skip || !isinKey,
			variables: {
				orgId,
				filterExpression: isinExpression,
			},
			notifyOnNetworkStatusChange,
		},
	);

	const refIdExpression = createMultipleValueExpression({
		key: referenceIdKey,
		operator: OPERATORS.in,
		values: searchTexts,
	});

	const referenceIdResponse = useQuery<QueryResponse, QueryVariables>(
		GLOBAL_SEARCH_CONTENT_RECORDS,
		{
			skip: skip || !referenceIdKey,
			variables: {
				orgId,
				filterExpression: refIdExpression,
			},
			notifyOnNetworkStatusChange,
		},
	);

	const linkIdExpression = createMultipleValueExpression({
		key: manualLinkKey,
		operator: OPERATORS.in,
		values: searchTexts,
	});

	const manualLinkResponse = useQuery<QueryResponse, QueryVariables>(
		GLOBAL_SEARCH_CONTENT_RECORDS,
		{
			skip: skip || !manualLinkKey,
			variables: {
				orgId,
				filterExpression: linkIdExpression,
			},
			notifyOnNetworkStatusChange,
		},
	);

	const isLoading =
		counterpartyResponse.loading ||
		isinResponse.loading ||
		referenceIdResponse.loading ||
		manualLinkResponse.loading;

	const error =
		counterpartyResponse.error ||
		isinResponse.error ||
		referenceIdResponse.error ||
		manualLinkResponse.error;

	const searchResults: Array<SearchResult> = [
		{
			expression: counterpartyExpression,
			label: 'Counter Party',
			count:
				counterpartyResponse?.data?.searchContentRecords?.totalItemsCount || 0,
		},
		{
			expression: isinExpression,
			label: 'ISIN',
			count: isinResponse?.data?.searchContentRecords?.totalItemsCount || 0,
		},
		{
			expression: refIdExpression,
			label: 'Reference ID',
			count:
				referenceIdResponse?.data?.searchContentRecords?.totalItemsCount || 0,
		},
		{
			expression: linkIdExpression,
			label: 'Link ID',
			count:
				manualLinkResponse?.data?.searchContentRecords?.totalItemsCount || 0,
		},
	].filter(item => item.count > 0);

	return { searchResults, isLoading, error };
}
