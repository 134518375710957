// @ts-nocheck

import defaultIcon from '../assets/notification-icon.png';

const supportsNotifications = 'Notification' in window;

export async function sendNotification(config) {
	// Check if the browser supports notifications
	if (!supportsNotifications) return;

	const isGranted = await getPermission();

	if (!isGranted) return null;

	return onGrantNotification(config);
}

export function getPermission() {
	if (!supportsNotifications) return Promise.reject('Not supported');

	return new Promise(resolve => {
		// Let's check whether notification permissions have already been granted
		if (Notification.permission === 'granted') return resolve(true);

		// Don't ask again if it is already denied
		if (Notification.permission === 'denied') resolve(false);

		// Ask for permision
		Notification.requestPermission(function (permission) {
			// If the user accepts, let's create a notification
			if (permission === 'granted') return resolve(true);
			// Denied or something
			else return resolve(false);
		});
	});
}

function onGrantNotification({
	title = 'Access Fintech',
	onClick,
	...options
}) {
	// Set accessfintech logo as default icon
	options.icon = options.icon || defaultIcon;

	var notification = new Notification(title, options);

	notification.onclick = e => {
		// Open the tab
		window.focus();

		// Close the notification
		if (Boolean(e) && Boolean(e.target)) {
			e.target.close();
		}

		// Notify caller
		onClick && onClick(e);
	};

	return notification;
}
