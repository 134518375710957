// @ts-nocheck

import type {
	AndExpressionType,
	AnyKindOfExpression,
	Constant,
	DoubleValueOperatorType,
	MultipleValueOperatorType,
	NoValueOperatorType,
	SingleValueExpression,
	SingleValueOperatorType,
} from 'types/Expression';
import type { Field } from 'types/Field';
import type { DateTypes } from 'utils/expressionsv2/constants';

import { GQLFieldType } from '@af/api';
import { warn } from '@af/utils';
import numeral from 'numeral';

import {
	createDoubleValueExpression,
	createMultipleValueExpression,
	createSingleValueExpression,
} from 'ds/components/FieldValuePicker/utils';
import { has } from 'utils/checks';
import {
	NUMERAL_EXPRESSION_DOUBLE_VALUE_FORMAT,
	NUMERAL_EXPRESSION_INTEGER_VALUE_FORMAT,
} from 'utils/constants';
import { formatDate, formatDateTime, formatSeconds } from 'utils/dates';
import { getDefaultValueForField } from 'utils/expressionsv2/field';

import {
	BOOLEAN_VALUES,
	DATE_TYPES,
	DOUBLE_VALUE_OPERATORS,
	MULTIPLE_VALUE_OPERATORS,
	NO_VALUE_OPERATORS,
	SINGLE_VALUE_OPERATORS,
} from './constants';
import { OPERATORS } from './operator';

export function getFormattedFieldValue(
	value: string | number | boolean | undefined | null,
	formatType: GQLFieldType,
	options?: {
		decimals?: number;
	},
): string {
	if (value === null || typeof value === 'undefined' || value === '') {
		return '—';
	}

	if (typeof value === 'boolean') {
		// BOOLEAN type has been handled here to keep flow happy
		return BOOLEAN_VALUES[value.toString()].label;
	}

	switch (formatType) {
		case GQLFieldType.Double:
			const hasDecimals = has(options?.decimals);

			const format = hasDecimals
				? getFormatNumberWithDecimals(options?.decimals)
				: NUMERAL_EXPRESSION_DOUBLE_VALUE_FORMAT;

			return numeral(value).format(format);
		case GQLFieldType.Integer:
			return numeral(value).format(NUMERAL_EXPRESSION_INTEGER_VALUE_FORMAT);
		case GQLFieldType.Date:
			return formatDate(String(value), {
				ignoreTimeZone: true,
				ignoreTime: true,
			});
		case GQLFieldType.DateTime:
			// Treat both Date and DateTime types as equal
			const isNumber = !isNaN(Number(value));

			if (isNumber) {
				return formatSeconds(Number(value));
			}

			return formatDateTime(String(value));
		case GQLFieldType.String:
			return String(value);
		case GQLFieldType.Boolean:
			return String(value).toLowerCase() === 'true' ? 'Yes' : 'No';
		default:
			warn('Field type is not found', { formatType, value });

			return String(value);
	}
}

function getFormatNumberWithDecimals(amountOfDigits) {
	return amountOfDigits === 0
		? '0,0.0'
		: `0,0.${new Array(amountOfDigits).fill(0).join('')}`;
}

type DefaultValueOptions = {
	field: Field;
	operator: keyof typeof OPERATORS;
	dateTypeId: DateTypes;
};

export function getDefaultValueForOperatorAndField({
	field,
	operator,
	dateTypeId,
}: DefaultValueOptions) {
	const defaultValue =
		dateTypeId === DATE_TYPES.relative.id ? 0 : getDefaultValueForField(field);
	let value;

	if (NO_VALUE_OPERATORS.includes(operator)) {
		value = undefined;
	} else if (SINGLE_VALUE_OPERATORS.includes(operator)) {
		value = defaultValue;
		// Make flow happy
	} else if (
		DOUBLE_VALUE_OPERATORS.includes(operator) &&
		typeof defaultValue !== 'boolean'
	) {
		value = { lowerValue: defaultValue, upperValue: defaultValue };
	} else if (MULTIPLE_VALUE_OPERATORS.includes(operator)) {
		value = [];
	} else {
		warn(`Couldn't determine operator type`, operator);
		return undefined;
	}

	return value;
}

type Operator =
	| NoValueOperatorType
	| SingleValueOperatorType
	| DoubleValueOperatorType
	| MultipleValueOperatorType;

export type FieldValue = {
	fieldId: string;
	operator: Operator;
	value: Array<Constant['value']> | void;
};

export function convertExpressionToFieldValue(
	expression: AnyKindOfExpression,
): FieldValue {
	const { fieldId } = expression.left || expression.value;

	let value;

	/**
	 * Remove expression so we're not tightly coupled to expressions.
	 */
	if (expression.right) {
		if (Array.isArray(expression.right)) {
			// MultipleValueExpression
			value = expression.right.map(value => value.value);
		} else if (expression.right.value != null) {
			// SingleValueExpression
			value = [expression.right.value];
		}
	} else if (expression.lowerBound != null && expression.upperBound != null) {
		// DoubleValueExpression
		value = [expression.lowerBound.value, expression.upperBound.value];
	}

	return {
		fieldId: String(fieldId),
		operator: expression.operator,
		value,
	};
}

export function convertExpressionToFieldValues(
	expression?: AndExpressionType | SingleValueExpression | null,
): Array<FieldValue> {
	if (!expression) {
		// `null` expressions are converted to empty list of expressions
		return [];
	}

	if (expression.operator && expression.operator === OPERATORS.and) {
		// Expression has already been converted to multiple
		return expression.expressions.map<FieldValue>(
			convertExpressionToFieldValue,
		);
	}

	return [convertExpressionToFieldValue(expression)];
}

export function convertFieldValuesToExpression(
	fieldValue: FieldValue,
): AnyKindOfExpression | null {
	const { operator } = fieldValue;

	if (SINGLE_VALUE_OPERATORS.includes(operator)) {
		return createSingleValueExpression({
			key: fieldValue.fieldId,
			operator: fieldValue.operator,
			value: fieldValue.value,
		});
	}

	if (MULTIPLE_VALUE_OPERATORS.includes(operator)) {
		return createMultipleValueExpression({
			key: fieldValue.fieldId,
			operator: fieldValue.operator,
			values: fieldValue.value,
		});
	}

	if (DOUBLE_VALUE_OPERATORS.includes(operator)) {
		return createDoubleValueExpression({
			key: fieldValue.fieldId,
			operator: fieldValue.operator,
			range: {
				lower: fieldValue.value[0],
				upper: fieldValue.value[1],
			},
		});
	}

	return null;
}
