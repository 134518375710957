// @ts-nocheck

import type {
	GQLMutationCreateTemporaryExpressionArgs,
	GQLTemporaryExpression,
} from '@af/api';

import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useCurrentUser } from 'hooks/useCurrentUser';

const TIME_TO_LIVE_IN_DAYS = 60;

const CREATE_TEMPORARY_EXPRESSION = gql`
	mutation CreateTemporaryExpression(
		$contextOrgId: ID!
		$timeToLiveInDays: Int!
		$whereSystemField: SystemFieldFilter!
	) {
		createTemporaryExpression(
			contextOrgId: $contextOrgId
			timeToLiveInDays: $timeToLiveInDays
			whereSystemField: $whereSystemField
		) {
			id
		}
	}
`;

export type CreateTemporaryExpressionMutationResponse = {
	createTemporaryExpression: {
		id: GQLTemporaryExpression['id'];
		__typename: GQLTemporaryExpression['__typename'];
	};
};

type MutationVariables = {
	contextOrgId: GQLMutationCreateTemporaryExpressionArgs['contextOrgId'];
	timeToLiveInDays: GQLMutationCreateTemporaryExpressionArgs['timeToLiveInDays'];
	whereSystemField: GQLMutationCreateTemporaryExpressionArgs['whereSystemField'];
};

export function useCreateTemporaryExpressionMutation() {
	const { orgId } = useCurrentUser();

	const [getExpression, response] = useMutation<
		CreateTemporaryExpressionMutationResponse,
		MutationVariables
	>(CREATE_TEMPORARY_EXPRESSION);

	const getTemporaryExpression = useCallback(
		(
			whereSystemField: GQLMutationCreateTemporaryExpressionArgs['whereSystemField'],
			timeToLiveInDays: GQLMutationCreateTemporaryExpressionArgs['timeToLiveInDays'],
		) => {
			return getExpression({
				variables: {
					contextOrgId: orgId,
					timeToLiveInDays: timeToLiveInDays || TIME_TO_LIVE_IN_DAYS,
					whereSystemField,
				},
			});
		},
		[getExpression, orgId],
	);

	return [getTemporaryExpression, response];
}
