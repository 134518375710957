type Props = {
	className?: string;
};

export const UndoIcon = ({ className }: Props) => (
	<path
		className={className}
		clipRule="evenodd"
		d="M9 8L7.8 6.8l-.443-.443A4.5 4.5 0 115.5 10h-1a5.491 5.491 0 001.572 3.85 5.5 5.5 0 10.57-8.207L6.201 5.2 5 4v4h4z"
	/>
);
