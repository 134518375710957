type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const WatchMeIcon = ({ color, size = 24, title, titleId, ...props }: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 16.857c2.934 0 5.216-2.508 6.194-3.964a1.568 1.568 0 000-1.86C17.216 9.658 14.934 7.15 12 7.15s-5.216 2.508-6.194 3.963c-.408.567-.408 1.295 0 1.78.978 1.456 3.26 3.964 6.194 3.964zm0-7.28c1.386 0 2.445 1.051 2.445 2.426 0 1.375-1.06 2.427-2.445 2.427-1.386 0-2.445-1.052-2.445-2.427S10.615 9.577 12 9.577z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default WatchMeIcon;
