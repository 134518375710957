type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const WatchUnwatchIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.264 17.956a.582.582 0 00.836 0l10.636-10.92a.618.618 0 000-.858.58.58 0 00-.836 0l-1.898 1.95c-.867-.537-1.875-.915-3.002-.915-3.864 0-6.32 4.368-6.423 4.554a.62.62 0 000 .6c.062.11.945 1.665 2.443 2.928l-1.756 1.803a.617.617 0 000 .858zm3.705-4.663l3.226-3.31A2.273 2.273 0 0012 9.64c-1.304 0-2.364 1.088-2.364 2.427 0 .45.124.867.334 1.226z"
			fill={color || 'currentColor'}
		/>
		<path
			d="M16.846 9.665l-2.498 2.565c-.08 1.205-1.014 2.164-2.189 2.246l-2.032 2.087c.58.22 1.205.357 1.873.357 3.825 0 6.317-4.365 6.42-4.55a.618.618 0 00.002-.604c-.048-.086-.61-1.07-1.576-2.101z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default WatchUnwatchIcon;
