type Props = {
	className?: string;
};

export const GenericInstructionIcon = ({ className }: Props) => (
	<g className={className} fillRule="evenodd" clipRule="evenodd">
		<circle cx="10.5" cy="10.5" r="10" fill="none" stroke="currentColor" />
		<circle cx="10.5" cy="10.5" r="4" fill="none" stroke="currentColor" />
	</g>
);
