// @ts-nocheck

/**
 * 1. Adding this property in so we can hide the outside dates for the range datepicker on the left column
 */
import 'react-datepicker/dist/react-datepicker.css';

import { forwardRef } from 'react';
import DatePickerComponent, { ReactDatePickerProps } from 'react-datepicker';
import styled, { css } from 'styled-components';

import { Background } from './Background';
import { Input } from './Input';

function DatePicker(props: ReactDatePickerProps) {
	return (
		<Wrapper>
			<DatePickerComponent
				customInput={<CustomInput />}
				showPopperArrow={false}
				{...props}
			/>
		</Wrapper>
	);
}

const CustomInput = forwardRef((props, ref) => (
	<Background ref={ref}>
		<StyledInput {...props} />
	</Background>
));

const StyledInput = styled(Input)`
	width: 100%;
`;

export const getDatePickerStyles = () => css`
	/* Update background-color */
	.react-datepicker,
	.react-datepicker__header {
		background-color: ${props => props.theme.color.bg.lum.default};
		border: none;
	}

	/* Update the font-size */
	.react-datepicker__day,
	.react-datepicker__day-name {
		${props => props.theme.typography.size.state.default};
		color: ${props => props.theme.color.fg.base};
	}

	.react-datepicker__day:hover {
		background-color: ${props =>
			props.theme.color.palette.brandBlue.typeAlpha.custom(0.5)};
		color: ${props => props.theme.color.palette.white.base};
	}

	/* Set the current month text to uppercase */
	.react-datepicker__current-month {
		text-transform: uppercase;
	}

	/* Set the current month text size */
	.react-datepicker__current-month {
		font-size: 10px; /* Look into this later */
		font-weight: normal;
		color: ${props => props.theme.color.fg.base};
		padding: ${props => props.theme.spacing.small}px;
	}

	/* Update navigation top position due to current month padding */
	.react-datepicker__navigation {
		top: 8px;
	}

	/* Set height/width of days */
	.react-datepicker__day,
	.react-datepicker__day-name {
		margin: 0;
		padding: ${props => props.theme.spacing.small}px;
		width: 32px;
		height: 32px;
		border-radius: 0;
	}

	/* Update the range background color */
	.react-datepicker__day--in-range {
		background-color: ${props =>
			props.theme.color.palette.brandBlue.typeAlpha.custom(0.5)};
		color: ${props => props.theme.color.palette.white.base};
	}

	/* Set selected state */
	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--range-start,
	.react-datepicker__day--range-end {
		background-color: ${props =>
			props.theme.color.palette.brandBlue.lum.active};
		color: ${props => props.theme.color.palette.white.base};
	}

	/* Update the triangle background-color */
	/* I've noticed that there are 2 borders and they look kinda weird so I am disabling one */
	.react-datepicker-popper[data-placement^='bottom']
		.react-datepicker__triangle::before {
		border: none;
	}

	/* And update the other one */
	.react-datepicker-popper[data-placement^='bottom']
		.react-datepicker__triangle::after {
		border-bottom-color: red;
	}

	/* Update the navigation border */
	.react-datepicker__navigation-icon::after,
	.react-datepicker__navigation-icon::before {
		border-width: 1px 1px 0 0;
	}

	/* Disable the outside month */
	.react-datepicker__day--outside-month {
		background-color: transparent !important; /* This is needed to remove highlist on outside months when selecting range */
		color: transparent; /* 1 */
		pointer-events: none;
		height: 0;
	}

	/* Update today */
	.react-datepicker__day--today {
		color: ${props => props.theme.color.palette.white.base};
		font-weight: 600;
	}

	.react-datepicker__day--in-range.react-datepicker__day--today {
		background-color: ${props =>
			props.theme.color.palette.brandBlue.typeAlpha.custom(0.5)};
		color: ${props => props.theme.color.palette.white.base};
	}

	/* Update selected day state  */
	.react-datepicker__day--selected {
		color: ${props => props.theme.color.palette.white.base};
	}

	/* Update disabled state */
	.react-datepicker__day--disabled {
		cursor: not-allowed;
		color: ${props => props.theme.color.palette.brandBlack.typeAlpha.low};
	}
`;

const Wrapper = styled.div`
	${getDatePickerStyles}
`;

export { DatePicker };
