type Props = {
	className?: string;
};

export const InstructRejectIcon = ({ className }: Props) => (
	<g>
		<path d="M8.00001 7.01001H4.5V8.01001H8.12862C8.04548 7.69046 8.00086 7.35538 8.00001 7.01001Z" />
		<path d="M7.5 13.01V15.31L3.5 12.51L7.5 9.81001V12.01H13.5V13.01H7.5Z" />
		<path
			className={className}
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 3C10.7909 3 9 4.79086 9 7C9 9.20914 10.7909 11 13 11C15.2091 11 17 9.20914 17 7C17 4.79086 15.2091 3 13 3ZM15.3536 8.64645L13.7071 7L15.3536 5.35355L14.6464 4.64645L13 6.29289L11.3536 4.64645L10.6464 5.35355L12.2929 7L10.6464 8.64645L11.3536 9.35355L13 7.70711L14.6464 9.35355L15.3536 8.64645Z"
		/>
	</g>
);
