type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const DeleteIcon = ({ color, size = 24, title, titleId, ...props }: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.707 12l4.146 4.146-.707.708L12 12.707l-4.146 4.147-.708-.707L11.293 12 7.146 7.854l.708-.708L12 11.293l4.146-4.147.707.708L12.707 12z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default DeleteIcon;
