import { GQLNotificationEventType } from '@af/api';

type NotificationSettingsType = Array<{
	label: string;
	eventType: GQLNotificationEventType[];
	isEditable: boolean;
}>;

const NOTIFICATION_SETTINGS_LIST: NotificationSettingsType = [
	{
		label: 'Instruction',
		eventType: [GQLNotificationEventType.InstructionCreatedOnContentRecords],
		isEditable: true,
	},
	{
		label: 'Instruction status',
		eventType: [GQLNotificationEventType.InstructionStatusOnContentRecords],
		isEditable: true,
	},
	{
		label: 'Comment',
		eventType: [GQLNotificationEventType.ContentRecordComment],
		isEditable: true,
	},
	{
		label: 'Manual assign',
		eventType: [
			GQLNotificationEventType.RiskAssigned,
			GQLNotificationEventType.RiskComment,
		],
		isEditable: true,
	},
	{
		label: 'Download completion',
		eventType: [GQLNotificationEventType.ContentExportDone],
		isEditable: false,
	},
	{
		label: 'Entitlement created',
		eventType: [GQLNotificationEventType.EntitlementCreated],
		isEditable: false,
	},
	{
		label: 'Email response',
		eventType: [GQLNotificationEventType.InstructionCommentReceivedEvent],
		isEditable: true,
	},
];

const ICON_SIZE = 20;

export { ICON_SIZE, NOTIFICATION_SETTINGS_LIST };
