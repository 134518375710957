// @ts-nocheck

import type { Dictionary } from '@af/types';

import { warn } from '@af/utils';
import { mapKeys } from 'lodash-es';

/**
 * Swaps the keys and values of the passed object
 */
export function swapKeyValue(obj: Dictionary) {
	return Object.keys(obj).reduce(
		(acc, val) => ({ ...acc, [obj[val]]: val }),
		{},
	);
}

/**
 * Calls mapKeysDeep on all members of an array
 */
function mapKeysArray(arr, fn) {
	return arr.map(obj => mapKeysDeep(obj, fn));
}

/**
 * Recursively calls lodash-es's mapKeys method on a complex object
 */
export function mapKeysDeep(
	obj: Dictionary,
	fn: (value: string, key: string) => unknown,
): Dictionary {
	if (typeof obj === 'undefined') {
		console.warn('Expected an object or an array, received undefined.');
		return obj;
	}

	if (Array.isArray(obj)) return mapKeysArray(obj, fn);
	if (typeof obj !== 'object') return obj;

	obj = mapKeys(obj, fn);

	const res = {};

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const val = obj[key];
			if (typeof val === 'object') {
				res[key] = mapKeysDeep(val, fn);
			} else {
				res[key] = val;
			}
		}
	}

	return res;
}

/**
 * Recursively rename object keys using corresponding entries in
 * a dictionary
 */
export function renameKeysDeep(
	obj: Dictionary,
	dictionary: Dictionary,
): Dictionary {
	return mapKeysDeep(obj, (value, key) => {
		const shortKey = dictionary[key];

		if (shortKey) return shortKey;

		warn('Short key not found on dictionary', key);

		return key;
	});
}
