type Props = {
	className?: string;
};

export const ClaimContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(-2, -1)"
		d="M10.1,12.0042704 L10.1,16.0469742 L9.1,16.0469742 L9.1,11.0042704 L9.1,8.00427038 L16.1,8.00427038 L13.475,10.0081459 L16.1,12.0042704 L10.1,12.0042704 Z"
	/>
);
