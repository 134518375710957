type Props = {
	className?: string;
};

export const CategoryIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M5 11l1.501 1.499L8.004 11H9v1l-1.498 1.499L9 14.996V16H7.995L6.5 14.502 5.004 16H4v-1.005L5.499 13.5 4 11.996V11h1zm11 0v5h-5v-5h5zm0-7v5h-5V4h5zm-1 1h-3v3h3V5zM6.5 9a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
	/>
);
