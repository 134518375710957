import type { Theme as ThemeType } from 'ds/styles/themes';

import { css, FlattenSimpleInterpolation } from 'styled-components';

type Props = {
	theme: ThemeType;
};

export const BASE_TYPOGRAPHY = (props: Props) => css`
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	font-size: 0.625rem;
	color: ${props.theme.color.fg.base};
`;

const TypeSize = {
	large: 'large',
	medium: 'medium',
	normal: 'normal',
	normalSecondary: 'normalSecondary',
	normalTertiary: 'normalTertiary',
	small: 'small',
	tiny: 'tiny',
	minuscule: 'minuscule',
};

export type TypeSizeName = keyof typeof TypeSize;

export type TypeAlphas = {
	low: number;
	medium: number;
	high: number;
};

export type TypeAlignmentName = 'left' | 'center' | 'right';
type TypeAlignments = {
	[k in TypeAlignmentName]: string;
};
export type TypeTextTransform = 'uppercase' | 'lowercase' | 'capitalize';
export type Typography = {
	size: {
		name: TypeSizeName;
		state: {
			default: FlattenSimpleInterpolation;
			rollover: FlattenSimpleInterpolation;
			selected: FlattenSimpleInterpolation;
			disabled: FlattenSimpleInterpolation;
		};
	};
	alignment: TypeAlignments;
};

type SizeType = Typography['size'];
export type TypographyState = keyof SizeType['state'];

export type BaseTypography = {
	size: {
		large: SizeType['state'];
		medium: SizeType['state'];
		normal: SizeType['state'];
		normalSecondary: SizeType['state'];
		normalTertiary: SizeType['state'];
		small: SizeType['state'];
		tiny: SizeType['state'];
		minuscule: SizeType['state'];
	};
	alignment: TypeAlignments;
};
