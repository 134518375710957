// @ts-nocheck

import type {
	GQLMutationCreateNotificationSubscriptionsArgs,
	GQLNotificationSubscription,
	GQLNotificationSubscriptionIdentity,
	GQLNotificationSubscriptionInput,
} from '@af/api';

import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useCurrentUser } from 'hooks/useCurrentUser';

const CREATE_NOTIFICATION_SUBSCRIPTION = gql`
	mutation CreateNotificationSubscription(
		$contextOrgId: ID!
		$notificationSubscriptions: [NotificationSubscriptionInput!]!
	) {
		createNotificationSubscriptions(
			contextOrgId: $contextOrgId
			notificationSubscriptions: $notificationSubscriptions
		) {
			__typename
			id
			identity {
				... on User {
					id
					name
				}
				... on UsersGroup {
					id
					name
				}
			}
		}
	}
`;

type MutationResponse = {
	createNotificationSubscriptions: Array<{
		id: GQLNotificationSubscription['id'];
		identity: GQLNotificationSubscriptionIdentity;
	}>;
};

type MutationProps = {
	notificationSubscriptions: Array<GQLNotificationSubscriptionInput>;
};

type MutationVariables = {
	contextOrgId: GQLMutationCreateNotificationSubscriptionsArgs['contextOrgId'];
} & MutationProps;

export function useCreateNotificationSubscriptionMutation() {
	const { orgId: contextOrgId } = useCurrentUser();

	const [mutation, response] = useMutation<MutationResponse, MutationVariables>(
		CREATE_NOTIFICATION_SUBSCRIPTION,
	);

	const createNotificationSubscription = useCallback(
		(props: MutationProps) =>
			mutation({ variables: { ...props, contextOrgId } }),
		[mutation, contextOrgId],
	);

	return [createNotificationSubscription, response];
}
