type Props = {
	className?: string;
};

export const ShareContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M8.341 9.668a1.654 1.654 0 0 1 0 .664l2.897 1.716a1.693 1.693 0 0 1 1.075-.381c.931 0 1.687.746 1.687 1.666 0 .92-.756 1.667-1.688 1.667a1.677 1.677 0 0 1-1.653-1.999l-2.897-1.716a1.693 1.693 0 0 1-1.074.382A1.677 1.677 0 0 1 5 10c0-.92.756-1.667 1.688-1.667.408 0 .782.144 1.074.382L10.66 7A1.677 1.677 0 0 1 12.313 5C13.244 5 14 5.746 14 6.667c0 .92-.756 1.666-1.688 1.666-.408 0-.782-.143-1.074-.381L8.34 9.668z"
	/>
);
