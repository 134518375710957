// @ts-nocheck

import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
	from {
		opacity: 0;
	}
`;

export const scaleIn = keyframes`
	from {
		opacity: 0;
		transform: scale(0.8);
	}
`;

export const slideInRight = keyframes`
	from {
		transform: translateX(100%);
	}
`;

export const slideInLeft = keyframes`
	from {
		transform: translateX(-100%);
	}
`;

export const slideInBottom = keyframes`
	from {
		transform: translateY(100%);
	}
`;

export const customRepeating = ({ from, to }) => {
	return keyframes`
		0%, 100% {
			${from};
		}
		50% {
			${to};
		}
`;
};
