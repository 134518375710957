type Props = {
	className?: string;
};

export const BusinessIntelligenceIcon = ({ className }: Props) => (
	<g className={className}>
		<path d="M12.679 3.533A7 7 0 0010 3v7h7a7.002 7.002 0 00-4.321-6.467z" />
		<path d="M14.019 14.27a5.864 5.864 0 001.586-2.548l1.087.333-.001.003a6.999 6.999 0 11-8.578-8.799l.307 1.094a5.864 5.864 0 105.599 9.917z" />
	</g>
);
