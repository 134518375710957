// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { color as col } from 'styles';

import { Spinner, Wrapper } from './styles';

function Loading({ size, color, delay, thickness, spinnerProps, ...props }) {
	const timer = useRef();
	const [isVisible, setIsVisible] = useState<boolean>(!Boolean(delay));

	useEffect(() => {
		if (delay) {
			setIsVisible(false);
			timer.current = setTimeout(() => {
				setIsVisible(true);
			}, delay);
		} else {
			setIsVisible(true);
		}

		return () => {
			clearTimeout(timer.current);
		};
	}, [delay, setIsVisible]);

	return isVisible ? (
		<Wrapper {...props}>
			<Spinner
				size={size}
				color={color}
				thickness={thickness}
				{...spinnerProps}
			/>
		</Wrapper>
	) : null;
}

Loading.propTypes = {
	color: PropTypes.string,
	delay: PropTypes.number,
	size: PropTypes.number,
	thickness: PropTypes.number,
};

Loading.defaultProps = {
	color: col.brandBlue,
	delay: 250,
	size: 32,
};

const StyledLoading = styled(Loading)``;

export { StyledLoading as Loading };
