type Props = {
	className?: string;
};

export const TypeIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M6.5 5a.5.5 0 00-.5.5V12h8V5.5a.5.5 0 00-.5-.5h-7zM6 14.5V13h8v1.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm-1-9A1.5 1.5 0 016.5 4h7A1.5 1.5 0 0115 5.5v9a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 015 14.5v-9zM9 8h2V7H9v1z"
	/>
);
