type Props = {
	className?: string;
};

export const LockNavigationIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6, 6)"
		d="M0 6.35l3.15-3.1C3.05 3 3 2.75 3 2.5 3 1.1 4.1 0 5.5 0S8 1.1 8 2.5 6.9 5 5.5 5c-.25 0-.55-.05-.8-.15L4 5.5H3v1H2v1l-.5.5H0V6.35zM6.5 2.5c0-.533-.467-1-1-1-.533 0-1 .467-1 1 0 .533.467 1 1 1 .533 0 1-.467 1-1z"
	/>
);
