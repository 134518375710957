/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type {
	GQLClient,
	GQLOrganization,
	GQLQueryOrganizationArgs,
} from '@af/api';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useCurrentUser } from 'hooks/useCurrentUser';

const GET_ORGANIZATION_IDLE_TIME = gql`
	query GetOrganizationIdleTime($orgId: ID!) {
		organization(id: $orgId) {
			id
			client {
				idleLogoutDelay
			}
		}
	}
`;

export type QueryResponse = {
	organization: {
		id: GQLOrganization['id'];
		client?: {
			idleLogoutDelay: GQLClient['idleLogoutDelay'];
			__typename: GQLClient['__typename'];
		};
		__typename: GQLOrganization['__typename'];
	};
};

type QueryVariables = {
	orgId: GQLQueryOrganizationArgs['id'];
};

export function useOrganizationIdleTime() {
	const { orgId } = useCurrentUser();

	const response = useQuery<QueryResponse, QueryVariables>(
		GET_ORGANIZATION_IDLE_TIME,
		{
			variables: { orgId },
			skip: !orgId,
		},
	);

	const apiIdleTime = response?.data?.organization?.client?.idleLogoutDelay;

	let idleMinutes;

	if (apiIdleTime != null) {
		idleMinutes = apiIdleTime;
	} else if (orgId == null) {
		// If no current org id is present use a shorter idle time
		idleMinutes = 30;
	} else {
		// if api doesn't have a setting and there is an org id, use 2 hours
		idleMinutes = 120;
	}

	return { ...response, idleMinutes };
}
