// @ts-nocheck

import { timingFunctions } from 'polished';
import styled, { css } from 'styled-components';

export const variants = {
	dark: {
		background: `rgba(0,0,0,0)`,
		onCurtainActiveBackground: `rgba(0, 0, 0, 0.7)`,
	},
	light: {
		background: `rgba(246, 246, 246, 0)`,
		onCurtainActiveBackground: `rgba(246, 246, 246, 0.85)`,
	},
};

export const Curtain = styled.div<{ active?: boolean }>`
	display: flex;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	background-color: ${props =>
		props.variant && variants[props.variant].background};
	transition: background-color 666ms ${timingFunctions('easeOutExpo')};
	pointer-events: none;

	${props => css`
		${props.active &&
		css`
			background-color: ${props.variant &&
			variants[props.variant].onCurtainActiveBackground};
			pointer-events: initial;
			z-index: 4;
		`};

		${props.delay &&
		css`
			transition-delay: ${props.delay}ms;
		`};
	`};
`;
