type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const AttachmentAddIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M16.5 4v2.5m0 0V9m0-2.5H19m-2.5 0H14M9.5 10v5.5a1 1 0 102 0v-8a2 2 0 10-4 0v8a3 3 0 106 0V10"
			stroke={color || 'currentColor'}
			strokeOpacity={0.65}
		/>
	</svg>
);

export default AttachmentAddIcon;
