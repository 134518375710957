// @ts-nocheck

import React, { useEffect, useRef } from 'react';

import { Loading } from 'components/Loading';
import { ErrorMessage } from 'ds/components/ErrorMessage';
import { Typography } from 'ds/components/Typography';
import { useGetActiveExportStatus } from 'v2/hooks/useGetActiveExportStatusQuery';

import { Message } from '../styles';
import { Complete } from './Complete';
import { InProgress } from './InProgress';
import { ListContent, Wrapper } from './styles';

export function Downloads() {
	const ref = useRef();

	const { activeExports, loading, error, stopPolling } =
		useGetActiveExportStatus({ pollInterval: 3000 });

	const activeExportsLength = activeExports.length;

	useEffect(() => {
		if (!activeExports) {
			stopPolling();
		}
	}, [activeExports, stopPolling]);

	// Ref to track the outcome where an export has been displayed but no longer exists in activeExports
	if (activeExportsLength > 0) {
		ref.current = activeExports;
	}

	const requiredData = ref.current || activeExports;

	const isDownloadReady = activeExportsLength === 0 && Boolean(ref.current);

	// TODO: Add this when API updated
	const fileUrl = 'activeExport.fileURL';

	return (
		<>
			<Wrapper>
				<Typography as="h3" size="medium">
					Downloads
				</Typography>
				{loading ? (
					<Loading />
				) : error ? (
					<Message>
						<ErrorMessage error={error} />
					</Message>
				) : !requiredData.length ? (
					<Message>No Downloads</Message>
				) : (
					<ListContent>
						{activeExports.map(activeExport => (
							<InProgress activeExport={activeExport} />
						))}
						{isDownloadReady
							? requiredData.map(activeExport => (
									<Complete
										fileName={activeExport.fileName}
										fileUrl={fileUrl}
									/>
							  ))
							: null}
					</ListContent>
				)}
			</Wrapper>
		</>
	);
}
