type Props = {
	className?: string;
};

export const OnboardingIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3, 2)"
		d="M3.5 10a2.5 2.5 0 1 0 0 5h7a2.5 2.5 0 1 0 0-5h-7zm0-1h7a3.5 3.5 0 0 1 0 7h-7a3.5 3.5 0 0 1 0-7zm7 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-7-13a2.5 2.5 0 0 0 0 5h7a2.5 2.5 0 1 0 0-5h-7zm0-1h7a3.5 3.5 0 0 1 0 7h-7a3.5 3.5 0 0 1 0-7zm0 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
	/>
);
