type Props = {
	className?: string;
};

export const ChatIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M5,6.5 L5,12.5 C5,12.7761424 5.22385763,13 5.5,13 L8,13 L8,16.0451613 L10.8531135,13.1490977 C10.9470873,13.0537092 11.0753952,13 11.2092982,13 L14.5,13 C14.7761424,13 15,12.7761424 15,12.5 L15,6.5 C15,6.22385763 14.7761424,6 14.5,6 L5.5,6 C5.22385763,6 5,6.22385763 5,6.5 Z"
	/>
);
