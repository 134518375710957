import { css, keyframes } from 'styled-components';

const fadeInFrames = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

export const fadeIn = css`
	animation: ${fadeInFrames} 166ms ease-in-out;
`;
