import {
	GQLContentRecord,
	GQLNotificationSubscriptionEntityTypeInput,
	GQLNotificationSubscriptionIdentityTypeInput,
} from '@af/api';

import { User } from 'types/User';

export function generateNotificationSubscriptionPayload(
	userId: User['id'],
	serializedCompositeKey: GQLContentRecord['serializedCompositeKey'],
) {
	return {
		notificationSubscriptions: [
			{
				identity: {
					id: userId,
					type: GQLNotificationSubscriptionIdentityTypeInput.User,
				},
				entity: {
					serializedCompositeKey,
					type: GQLNotificationSubscriptionEntityTypeInput.ContentRecord,
				},
			},
		],
	};
}
