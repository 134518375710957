import type { User } from 'types/User';

import { createContext, useContext, useMemo } from 'react';

interface ContextProps {
	user: User | null;
	orgId: string;
}

const UserContext = createContext<ContextProps>({ user: null, orgId: '' });

type Props = ContextProps & {
	children: React.ReactNode;
};

const UserContextProvider = ({ children, user, orgId }: Props) => {
	const context = useMemo(() => ({ user, orgId }), [user, orgId]);

	return (
		<UserContext.Provider value={context}>{children}</UserContext.Provider>
	);
};

const useUserContext = () => useContext(UserContext);

export { UserContext, UserContextProvider, useUserContext };
