type Props = {
	className?: string;
};

export const MessageIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M6 8.667V4h8v4.667l1.455.97L16 9.5V16H4V9.5l.545.136L6 8.666zm1 1.583l3 .75 3-.75V5H7v5.25zM8 9V8h4v1H8zm0-2V6h4v1H8z"
	/>
);
