type Props = {
	className?: string;
};

export const RiskIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3.5, 1.5)"
		d="M7 15c0-1.133.867-2 2-2s2 .867 2 2-.867 2-2 2-2-.867-2-2zm1 0c0 .567.433 1 1 1s1-.433 1-1-.433-1-1-1-1 .433-1 1zm-5.5-3A2.522 2.522 0 0 1 0 9.5C0 8.136 1.136 7 2.5 7S5 8.136 5 9.5 3.864 12 2.5 12zm0-1c.818 0 1.5-.682 1.5-1.5S3.318 8 2.5 8 1 8.682 1 9.5 1.682 11 2.5 11zM6 3.5C6 1.556 7.556 0 9.5 0S13 1.556 13 3.5 11.444 7 9.5 7A3.485 3.485 0 0 1 6 3.5zm1 0A2.49 2.49 0 0 0 9.5 6 2.49 2.49 0 0 0 12 3.5 2.49 2.49 0 0 0 9.5 1 2.49 2.49 0 0 0 7 3.5z"
	/>
);
