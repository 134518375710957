// @ts-nocheck

import { useEffect, useRef, useState } from 'react';

export function useDebounce<T>(value: T, delay: number): T {
	const timerRef = useRef();
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		timerRef.current = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [delay, value]);

	return debouncedValue as T;
}
