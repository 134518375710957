type Props = {
	className?: string;
};

export const DashboardIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(2, 3)"
		d="M4.934 7.248l-.868-.496 2.28-3.992 4.07 3.052L14.614.683l.774.634-4.804 5.87-3.93-2.947-1.719 3.008zM1.121 8.485l-.242-.97 2.188-.547 6.43 3.462 4.26-2.367.486.874-4.74 2.633-6.57-3.538-1.812.453zm1.326 3.739l-.894-.448 1.5-3 .894.448-1.5 3z"
	/>
);
