type Props = {
	className?: string;
};

export const ChevronDown = ({ className }: Props) => (
	<path
		className={className}
		d="M14.54 6.616l.92.768L10 13.937 4.54 7.384l.92-.768L10 12.063z"
	/>
);
