export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Primitive` scalar type represents the following primitive built in scalars: `Int`, `Boolean`, `Float`, `Long`, `String`, `DateTime` */
  Primitive: any;
  CustomObjectGraphType: any;
  UInt: any;
  /** The Json scalar type represents JSON (JavaScript Object Notation) */
  Json: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** A single expression with an operator */
  Expression: any;
  /** The `Minutes` scalar type represents a period of time represented as the total number of minutes. */
  Minutes: any;
  Long: any;
  Decimal: any;
  /** Date custom scalar type */
  Date: any;
  /** File serialized as base64 UTF-8 string */
  Base64File: any;
  /** A scalar to combine all primitives */
  StringOrIntOrFloatOrBoolean: any;
};


export enum GQLAction {
  New = 'NEW',
  Published = 'PUBLISHED',
  Rollback = 'ROLLBACK',
  Unknown = 'UNKNOWN',
  Update = 'UPDATE'
}

export type GQLActionAuditOrderBy = {
  direction?: GQLOrderDirection;
  field: GQLActionAuditOrderByField;
};

export enum GQLActionAuditOrderByField {
  CreationDate = 'CREATION_DATE'
}

export type GQLActionBarActionDisabledByLimitInfo = GQLActionBarActionDisableInfo & {
  __typename?: 'ActionBarActionDisabledByLimitInfo';
  friendlyMessage: Scalars['String'];
  maxRecords?: Maybe<Scalars['Int']>;
  minRecords: Scalars['Int'];
};

export type GQLActionBarActionDisabledByPermissionInfo = GQLActionBarActionDisableInfo & {
  __typename?: 'ActionBarActionDisabledByPermissionInfo';
  friendlyMessage: Scalars['String'];
  missingPermissions: Array<Scalars['String']>;
};

export type GQLActionBarActionDisableInfo = {
  friendlyMessage: Scalars['String'];
};

export type GQLActionBarActionItem = GQLActionBarItemInterface & {
  __typename?: 'ActionBarActionItem';
  action: GQLActionInterface;
  actionBarActionId: Scalars['ID'];
  actionBarConfigurationId: Scalars['ID'];
  disableInfo?: Maybe<GQLActionBarActionDisableInfo>;
  id: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
};


export type GQLActionBarActionItemDisableInfoArgs = {
  contentRecordCompositeKey?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
};


export type GQLActionBarActionItemIsDisabledArgs = {
  contentRecordCompositeKey?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
};

export type GQLActionBarConfiguration = {
  __typename?: 'ActionBarConfiguration';
  actionBarItems: Array<GQLActionBarItemInterface>;
  contentTypeId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
};

export type GQLActionBarGroup = GQLActionBarItemInterface & {
  __typename?: 'ActionBarGroup';
  actionBarConfigurationId: Scalars['ID'];
  friendlyName?: Maybe<Scalars['String']>;
  groupItems: Array<GQLActionBarGroupActionItem>;
  id: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};


export type GQLActionBarGroupIsDisabledArgs = {
  contentRecordCompositeKey?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
};

export type GQLActionBarGroupActionItem = {
  __typename?: 'ActionBarGroupActionItem';
  action: GQLActionInterface;
  actionBarActionId: Scalars['ID'];
  actionBarConfigurationId: Scalars['ID'];
  disableInfo?: Maybe<GQLActionBarActionDisableInfo>;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  sequenceOrder?: Maybe<Scalars['Int']>;
};


export type GQLActionBarGroupActionItemDisableInfoArgs = {
  contentRecordCompositeKey?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
};


export type GQLActionBarGroupActionItemIsDisabledArgs = {
  contentRecordCompositeKey?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
};

export type GQLActionBarItemInterface = {
  actionBarConfigurationId: Scalars['ID'];
  id: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
};


export type GQLActionBarItemInterfaceIsDisabledArgs = {
  contentRecordCompositeKey?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
};

export type GQLActionInterface = {
  /**
   * action's name
   * @deprecated use ActionName instead
   */
  action?: Maybe<Scalars['String']>;
  actionName: Scalars['String'];
  friendlyName: Scalars['String'];
  id: Scalars['ID'];
};

export enum GQLActionType {
  InvalidateByRecordId = 'INVALIDATE_BY_RECORD_ID',
  Replay = 'REPLAY',
  ReplayByRecordId = 'REPLAY_BY_RECORD_ID',
  Resend = 'RESEND',
  ResendByRecordId = 'RESEND_BY_RECORD_ID',
  Unknown = 'UNKNOWN'
}

export type GQLActivity = {
  __typename?: 'Activity';
  dispatchFields: Array<GQLActivityDispatchField>;
  id: Scalars['ID'];
  isContentTypeOptional: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  routingFields: Array<GQLActivityRoutingField>;
  service: Scalars['String'];
};

export type GQLActivityDispatchField = {
  __typename?: 'ActivityDispatchField';
  activityId: Scalars['ID'];
  entityName: Scalars['String'];
  entityType: Scalars['String'];
  field: GQLBaseField;
  fieldType: Scalars['String'];
  friendlyName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  values: Array<GQLActivityDispatchFieldValue>;
};

export type GQLActivityDispatchFieldValue = {
  __typename?: 'ActivityDispatchFieldValue';
  contentType?: Maybe<GQLContentType>;
  contentTypeId?: Maybe<Scalars['ID']>;
  friendlyName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Primitive']>;
};

export type GQLActivityRoutingField = {
  __typename?: 'ActivityRoutingField';
  activityId: Scalars['ID'];
  activityName: Scalars['String'];
  contentType?: Maybe<GQLContentType>;
  contentTypeId?: Maybe<Scalars['ID']>;
  field: GQLBaseField;
  fieldType: Scalars['String'];
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  possibleValues: Array<GQLActivityRoutingFieldValue>;
};

export type GQLActivityRoutingFieldValue = {
  __typename?: 'ActivityRoutingFieldValue';
  contentType?: Maybe<GQLContentType>;
  contentTypeId?: Maybe<Scalars['ID']>;
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  value?: Maybe<Scalars['Primitive']>;
};

export type GQLActivitySubscription = {
  __typename?: 'ActivitySubscription';
  activity: GQLActivity;
  activityId: Scalars['ID'];
  contentType?: Maybe<GQLContentType>;
  contentTypeId?: Maybe<Scalars['ID']>;
  dispatchingDetails?: Maybe<Array<GQLDispatchingDetails>>;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  id: Scalars['ID'];
  lastUpdateTime: Scalars['DateTime'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
  routingEventFilterExpression?: Maybe<Scalars['Json']>;
  routingExpressions: Array<GQLExpressionMeta>;
};

export type GQLActivitySubscriptionInput = {
  activityId: Scalars['ID'];
  contentTypeId?: Maybe<Scalars['ID']>;
  dispatchingDetails?: Maybe<Array<GQLDispatchingDetailsInput>>;
  orgId: Scalars['ID'];
  routingEventFilterExpression?: Maybe<Scalars['Json']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLActivitySubscriptionUniverse = {
  __typename?: 'ActivitySubscriptionUniverse';
  activitySubscription: GQLActivitySubscription;
  activitySubscriptions: Array<GQLActivitySubscription>;
};


export type GQLActivitySubscriptionUniverseActivitySubscriptionArgs = {
  id: Scalars['ID'];
};

export type GQLAggregatedInstructionRisks = {
  __typename?: 'AggregatedInstructionRisks';
  assignees: Array<GQLAssignee>;
  severities: Array<GQLRiskSeverity>;
};

export type GQLAggregatedRisk = {
  __typename?: 'AggregatedRisk';
  count: Scalars['Int'];
  severity?: Maybe<GQLRiskSeverity>;
  value: Scalars['Float'];
};

export type GQLAggregatedRisks = {
  __typename?: 'AggregatedRisks';
  loading: Scalars['Boolean'];
  results: Array<Maybe<GQLAggregatedRisk>>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type GQLAggregationCounter = {
  __typename?: 'AggregationCounter';
  count: Scalars['Int'];
  /** null in flat aggregations */
  field?: Maybe<GQLBaseField>;
  /** null in flat aggregations */
  type?: Maybe<GQLAggregationType>;
  value: Scalars['Float'];
};

export type GQLAggregationExpressionInterface = {
  expression?: Maybe<GQLBinaryEquationExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export type GQLAggregationFieldConfigInput = {
  fieldKey: Scalars['ID'];
  index: Scalars['UInt'];
  type: GQLAggregationType;
};

export type GQLAggregationFieldValue = {
  __typename?: 'AggregationFieldValue';
  baseField?: Maybe<GQLBaseField>;
  fieldKey?: Maybe<Scalars['ID']>;
  function: GQLAggregationFunction;
  operator: GQLAggregationOperator;
  threshold: Scalars['Float'];
  value: Scalars['Float'];
};

export enum GQLAggregationFunction {
  Avg = 'AVG',
  Count = 'COUNT',
  Sum = 'SUM'
}

export type GQLAggregationGrouping = {
  __typename?: 'AggregationGrouping';
  field: GQLAggregationGroupingField;
  fieldKey: Scalars['ID'];
  rank: Scalars['ID'];
  value: Scalars['CustomObjectGraphType'];
};

export type GQLAggregationGroupingField = {
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  key: Scalars['ID'];
  name: Scalars['String'];
  type: GQLFieldType;
};

export enum GQLAggregationModeInput {
  Sum = 'SUM'
}

export enum GQLAggregationOperation {
  Date = 'DATE',
  FreeText = 'FREE_TEXT',
  MultiLine = 'MULTI_LINE',
  OptionSelect = 'OPTION_SELECT',
  Sum = 'SUM'
}

export enum GQLAggregationOperator {
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEquals = 'GREATER_THAN_OR_EQUALS',
  LessThan = 'LESS_THAN',
  LessThanOrEquals = 'LESS_THAN_OR_EQUALS'
}

export type GQLAggregationParticipant = {
  __typename?: 'AggregationParticipant';
  aggregatedRecordId: Scalars['ID'];
  contentRecord?: Maybe<GQLContentRecord>;
  contentType?: Maybe<GQLContentType>;
  contentTypeId: Scalars['ID'];
  organization?: Maybe<GQLOrganization>;
  orgId: Scalars['ID'];
  participationTime: Scalars['DateTime'];
};

export type GQLAggregationSummary = {
  __typename?: 'AggregationSummary';
  aggregationCounter: GQLAggregationCounter;
  valueKey: Scalars['Primitive'];
};

export enum GQLAggregationType {
  Average = 'AVERAGE',
  Group = 'GROUP',
  Max = 'MAX',
  Min = 'MIN',
  Sum = 'SUM'
}

export type GQLAggregationUserPreferencesFieldConfig = {
  __typename?: 'AggregationUserPreferencesFieldConfig';
  field: GQLBaseField;
  index: Scalars['UInt'];
  type: GQLAggregationType;
};

export type GQLAggregator = {
  __typename?: 'Aggregator';
  recordIds?: Maybe<Array<Maybe<GQLMessageRecordId>>>;
};

export type GQLAllExpression = GQLArrayExpressionInterface & GQLExpressionInterface & {
  __typename?: 'AllExpression';
  arrayType?: Maybe<GQLObjectArrayOperandInterface>;
  expression?: Maybe<GQLExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export type GQLAmac = {
  __typename?: 'Amac';
  flow: GQLAmacFlowType;
  cusip?: Maybe<Scalars['String']>;
  firmCode?: Maybe<Scalars['String']>;
  poolNumber?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['String']>;
  requiredAllocationAmount: Scalars['Float'];
  /** Only flow=SELL records can use inventory item query */
  inventoryItems: Array<GQLInventoryItem>;
  securityDetails?: Maybe<GQLSecurityDetails>;
  allocationView: Array<GQLCombo>;
};


export type GQLAmacInventoryItemsArgs = {
  searchQuery?: Maybe<Scalars['String']>;
  orderBy?: Maybe<GQLInventorySortModel>;
};


export type GQLAmacSecurityDetailsArgs = {
  poolNumber?: Maybe<Scalars['String']>;
  cusip?: Maybe<Scalars['String']>;
};

export type GQLAmacActionResponse = {
  __typename?: 'AmacActionResponse';
  successful: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
};

export enum GQLAmacFlowType {
  Buy = 'BUY',
  Sell = 'SELL'
}

export type GQLAmendCombo = {
  comboId?: Maybe<Scalars['ID']>;
  nonStdAmount?: Maybe<Scalars['Float']>;
  override: Scalars['Boolean'];
  deliveryType: GQLDeliveryType;
  items: Array<Maybe<GQLComboItems>>;
  /** 'A' | 'M' | 'D' */
  action: Scalars['String'];
};

export type GQLAmqpConnectorBothDirection = GQLBaseConnector & {
  __typename?: 'AmqpConnectorBothDirection';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  session?: Maybe<GQLSession>;
  setupStatus?: Maybe<GQLSetupStatus>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  tlsSecret?: Maybe<GQLTlsSecret>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export enum GQLAnalyticsCounterTimeFrame {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  SinceTheBeginningOfTime = 'SINCE_THE_BEGINNING_OF_TIME'
}

export type GQLAnalyticsQueryParams = {
  maxTime: Scalars['DateTime'];
  minTime: Scalars['DateTime'];
};

export type GQLAndLogicalExpression = GQLBinaryLogicalExpressionInterface & GQLExpressionInterface & {
  __typename?: 'AndLogicalExpression';
  expressions?: Maybe<Array<Maybe<GQLExpressionInterface>>>;
  isFlat: Scalars['Boolean'];
};

export type GQLAnyExpression = GQLArrayExpressionInterface & GQLExpressionInterface & {
  __typename?: 'AnyExpression';
  arrayType?: Maybe<GQLObjectArrayOperandInterface>;
  expression?: Maybe<GQLExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export enum GQLArchivePolicyEnum {
  Delete = 'DELETE',
  IgnoreOpenRisksAndDelete = 'IGNORE_OPEN_RISKS_AND_DELETE',
  IgnoreOpenRisksAndUpload = 'IGNORE_OPEN_RISKS_AND_UPLOAD',
  Skip = 'SKIP',
  Upload = 'UPLOAD'
}

export enum GQLArchivePolicyEnumInput {
  Skip = 'SKIP',
  Upload = 'UPLOAD'
}

export type GQLArrayExpressionInterface = {
  arrayType?: Maybe<GQLObjectArrayOperandInterface>;
  expression?: Maybe<GQLExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export type GQLAssignee = GQLUser | GQLUsersGroup;

export type GQLAssigneeInputParams = {
  assigneeId: Scalars['ID'];
  assigneeType: GQLAssigneeType;
};

export enum GQLAssigneeType {
  Group = 'GROUP',
  User = 'USER'
}

export type GQLAttachmentContentRecordEvent = {
  __typename?: 'AttachmentContentRecordEvent';
  attachmentUrl: Scalars['String'];
  comment?: Maybe<GQLComment>;
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  eventTime: Scalars['DateTime'];
  eventType: GQLContentRecordEventType;
  fileName: Scalars['String'];
  user?: Maybe<GQLUser>;
  userId?: Maybe<Scalars['ID']>;
};

export type GQLAttachmentNotification = GQLNotification & {
  __typename?: 'AttachmentNotification';
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  creationTime: Scalars['DateTime'];
  eventType: GQLNotificationEventType;
  fileName: Scalars['String'];
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  subscription: GQLNotificationSubscription;
  temporaryFilterExpressionId: Scalars['ID'];
  user: GQLUser;
  userId: Scalars['ID'];
};

export type GQLAuditEntity = {
  __typename?: 'AuditEntity';
  entityId: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type GQLAuditEntityConfiguration = {
  __typename?: 'AuditEntityConfiguration';
  friendlyName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLAuditEntityIds = {
  __typename?: 'AuditEntityIds';
  auditEntityConfiguration: GQLAuditEntityConfiguration;
  ids: Array<Scalars['ID']>;
};

export type GQLAuditEntityInput = {
  entityId: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type GQLAuditQueryParams = {
  actionType?: Maybe<GQLRiskItemEventActions>;
  contentRecordIds?: Maybe<Array<Scalars['ID']>>;
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  endDate?: Maybe<Scalars['DateTime']>;
  journeyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  riskIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shareOrderDetails?: Maybe<GQLActionAuditOrderBy>;
  startDate?: Maybe<Scalars['DateTime']>;
  timeFrameId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export type GQLAutoAllocationInventory = {
  poolNumber?: Maybe<Scalars['String']>;
  poolCusip?: Maybe<Scalars['String']>;
  firmAccount?: Maybe<Scalars['String']>;
};

export type GQLAutoAssignRule = GQLRuleInterface & {
  __typename?: 'AutoAssignRule';
  assignee: GQLAssignee;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  expression?: Maybe<Scalars['Json']>;
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
  priority?: Maybe<Scalars['Float']>;
};

export type GQLAutoAssignRuleInput = {
  assigneeInputParams: GQLAssigneeInputParams;
  expression: Scalars['Json'];
  friendlyName?: Maybe<Scalars['String']>;
  id?: Scalars['ID'];
  organizationId: Scalars['ID'];
  priority?: Maybe<Scalars['Float']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLAutoAssignRulePriorityUpdateInput = {
  id: Scalars['ID'];
  priority: Scalars['Float'];
};

export type GQLAutoAssignRuleSet = GQLRuleSetInterface & {
  __typename?: 'AutoAssignRuleSet';
  autoAssignRules: Array<Maybe<GQLAutoAssignRule>>;
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  id: Scalars['ID'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
};

export type GQLAutoAssignRuleSetInput = {
  contentTypeId?: Scalars['ID'];
  id?: Scalars['ID'];
  /** max length is 45 characters */
  name: Scalars['String'];
  orgId?: Scalars['ID'];
  rules?: Maybe<Array<GQLAutoAssignRuleInput>>;
  /** please don't use, not implemented yet */
  rulesIds?: Maybe<Array<Scalars['ID']>>;
};

export type GQLAverageAggregationExpression = GQLAggregationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'AverageAggregationExpression';
  expression?: Maybe<GQLBinaryEquationExpressionInterface>;
  isFlat: Scalars['Boolean'];
};


export type GQLBaseClientFieldInterface = {
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  type: GQLFieldType;
};

export type GQLBaseConnector = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  setupStatus?: Maybe<GQLSetupStatus>;
  statusNote?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLBaseField = {
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  type: GQLFieldType;
};

export type GQLBaseSystemFieldInterface = {
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSideBar: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  systemFieldType: GQLSystemFieldType;
  type: GQLFieldType;
};

export type GQLBetweenExpression = GQLEquationExpressionInterface & GQLExpressionInterface & GQLLowerAndUpperBoundsExpressionInterface & {
  __typename?: 'BetweenExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  lowerBound?: Maybe<GQLDataOperandInterface>;
  upperBound?: Maybe<GQLDataOperandInterface>;
};

export type GQLBinaryEquationExpressionInterface = {
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLBinaryLogicalExpressionInterface = {
  expressions?: Maybe<Array<Maybe<GQLExpressionInterface>>>;
  isFlat: Scalars['Boolean'];
};

export type GQLBucketInfo = {
  __typename?: 'BucketInfo';
  data?: Maybe<Scalars['String']>;
  flowStatus?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['Json']>;
  routingKey?: Maybe<Scalars['String']>;
};

export type GQLCaasFields = {
  __typename?: 'CaasFields';
  fields?: Maybe<Array<Maybe<GQLFieldAttribute>>>;
};

export type GQLCaasUniverse = {
  __typename?: 'CaasUniverse';
  connectivityAddresses: Array<GQLConnectivityAddress>;
};

export type GQLCappedChangeEntries = {
  __typename?: 'CappedChangeEntries';
  changeEntries: Array<GQLChangeEntry>;
  /** The token to get the next page */
  nextPageToken: Scalars['String'];
  totalItemsCount: Scalars['Long'];
};

export type GQLCappedContentRecords = {
  __typename?: 'CappedContentRecords';
  contentRecords: Array<GQLContentRecord>;
  totalItemsCount: Scalars['Long'];
};

export type GQLCappedInstructionEmails = {
  __typename?: 'CappedInstructionEmails';
  emails: Array<GQLInstructionEmail>;
  totalItemsCount: Scalars['Long'];
};

export type GQLCappedInstructions = {
  __typename?: 'CappedInstructions';
  instructions: Array<GQLInstructionDetails>;
  /** The token to get the next page */
  nextPageToken: Scalars['String'];
  totalItemsCount: Scalars['Long'];
};

export type GQLCappedNotification = {
  __typename?: 'CappedNotification';
  notifications: Array<GQLNotification>;
  totalItemsCount: Scalars['Long'];
};

export type GQLCappedNotificationSubscriptions = {
  __typename?: 'CappedNotificationSubscriptions';
  notificationSubscriptions: Array<GQLNotificationSubscription>;
  totalItemsCount: Scalars['Long'];
};

export type GQLCappedRisks = {
  __typename?: 'CappedRisks';
  /** The number of unique Content Records on all pages */
  contentRecordsCount: Scalars['Long'];
  /** The token to get the next page */
  nextPageToken: Scalars['String'];
  orderByFieldsMetaData: Array<GQLBaseField>;
  risks: Array<GQLRiskItem>;
  totalItemsCount: Scalars['Long'];
  /** The sum of RiskFactor fields of all matching Risks */
  totalRiskFactorSum: Scalars['Float'];
};

export type GQLCertificate = {
  __typename?: 'Certificate';
  /** The file should be serialized as base64 string */
  certificateContent?: Maybe<Scalars['Base64File']>;
  certificateFileName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type GQLCertificateChainGraph = {
  __typename?: 'CertificateChainGraph';
  caCertificateAlias: Scalars['String'];
  serverCertificateAlias: Scalars['String'];
};

export type GQLChangeEntry = {
  __typename?: 'ChangeEntry';
  auditEntitiesIds: Array<GQLAuditEntityIds>;
  auditEntity?: Maybe<GQLAuditEntity>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  lastEditingUser?: Maybe<GQLUser>;
  lastEditingUserId?: Maybe<Scalars['ID']>;
  operation: GQLOperationEnum;
  organization?: Maybe<GQLOrganization>;
  organizationId?: Maybe<Scalars['ID']>;
  properties: Array<GQLPropertyChangeDescriptor>;
  schema: Scalars['String'];
  table: Scalars['String'];
};

export enum GQLChatDataType {
  ContentRecord = 'CONTENT_RECORD',
  Risk = 'RISK'
}

export type GQLClient = {
  __typename?: 'Client';
  archivePeriod?: Maybe<Scalars['Int']>;
  archivePolicy: GQLArchivePolicyEnum;
  contentTypeIds: Array<Maybe<Scalars['ID']>>;
  contentTypes?: Maybe<Array<Maybe<GQLContentType>>>;
  idleLogoutDelay: Scalars['Minutes'];
  idpId?: Maybe<Scalars['ID']>;
  organization: GQLOrganization;
  orgId: Scalars['ID'];
  productLineIds: Array<Maybe<Scalars['ID']>>;
  productLines?: Maybe<Array<Maybe<GQLProductLine>>>;
  receiverOnly: Scalars['Boolean'];
  shouldPerformDedupOnSummeries: Scalars['Boolean'];
  userIds: Array<Maybe<Scalars['ID']>>;
  users?: Maybe<Array<Maybe<GQLUser>>>;
};

export type GQLClientField = GQLAggregationGroupingField & GQLBaseClientFieldInterface & GQLBaseField & GQLInstructionActionField & {
  __typename?: 'ClientField';
  aggregationMode?: Maybe<GQLFieldUniverseAggregationMode>;
  archiveMetadataIndex?: Maybe<Scalars['Int']>;
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  /** @deprecated Use 'FriendlyName' instead */
  fieldFriendlyName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  frequencyRank?: Maybe<Scalars['Int']>;
  friendlyName: Scalars['String'];
  /** @deprecated Please use GenericFieldUniverseConfig for the connected GenericField config */
  genericField?: Maybe<GQLClientField>;
  genericFieldId?: Maybe<Scalars['Int']>;
  genericFieldUniverseConfig?: Maybe<GQLGenericField>;
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isAge: Scalars['Boolean'];
  isComment: Scalars['Boolean'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isEntitlement: Scalars['Boolean'];
  isExpiration: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isGrouping: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isMandatory: Scalars['Boolean'];
  isPartOfRiskItem: Scalars['Boolean'];
  isRepeatable: Scalars['Boolean'];
  isRiskFactor: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isShareable: Scalars['Boolean'];
  isShareRuleField: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  /** @deprecated Deprecated. */
  isTimeBased: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  organization?: Maybe<GQLOrganization>;
  organizationId: Scalars['ID'];
  sampleValue?: Maybe<Scalars['String']>;
  shouldCollectValues: Scalars['Boolean'];
  status?: Maybe<GQLFieldUniverseConfigStatus>;
  titleRank?: Maybe<Scalars['Int']>;
  type: GQLFieldType;
  values: Array<Maybe<GQLClientFieldValue>>;
  comboId: Scalars['String'];
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  label: Scalars['String'];
};

export type GQLClientFieldInput = {
  aggregationMode?: Maybe<GQLAggregationModeInput>;
  archiveMetadataIndex?: Maybe<Scalars['UInt']>;
  clientFieldMappingSetup?: Maybe<GQLClientFieldMappingSetupInput>;
  contentTypeId: Scalars['ID'];
  formatType?: Maybe<GQLFieldType>;
  frequencyRank?: Maybe<Scalars['Int']>;
  friendlyName?: Maybe<Scalars['String']>;
  genericFieldId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  isAge?: Maybe<Scalars['Boolean']>;
  isComment?: Maybe<Scalars['Boolean']>;
  isContentRecordGrid?: Maybe<Scalars['Boolean']>;
  isEntitlement?: Maybe<Scalars['Boolean']>;
  isExpiration?: Maybe<Scalars['Boolean']>;
  isGrouping?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isInstructionGrid?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  isPartOfRiskItem?: Maybe<Scalars['Boolean']>;
  isRiskFactor?: Maybe<Scalars['Boolean']>;
  isRiskGrid?: Maybe<Scalars['Boolean']>;
  isShareable?: Maybe<Scalars['Boolean']>;
  isShareRuleField?: Maybe<Scalars['Boolean']>;
  isTimeFilter?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['UInt']>;
  organizationId: Scalars['ID'];
  parentChildAggregationMode?: Maybe<GQLAggregationModeInput>;
  sampleValue?: Maybe<Scalars['String']>;
  status?: Maybe<GQLFieldStatus>;
  titleRank?: Maybe<Scalars['UInt']>;
  type: GQLFieldType;
};

export type GQLClientFieldMappingSetup = {
  __typename?: 'ClientFieldMappingSetup';
  enrichmentField: GQLEnrichmentField;
  enrichmentFieldId: Scalars['ID'];
  mappingFunction: GQLMappingFunction;
  mapType: GQLMapType;
  mapTypeId: Scalars['ID'];
  overrideClientField: Scalars['Boolean'];
};

export type GQLClientFieldMappingSetupInput = {
  enrichmentFieldId: Scalars['ID'];
  mappingFunction: GQLMappingFunction;
  mapTypeId: Scalars['ID'];
  overrideClientField?: Scalars['Boolean'];
};

export type GQLClientFieldOperand = GQLFieldOperandInterface & GQLGenericFieldOperandInterface & GQLValueOperandInterface & {
  __typename?: 'ClientFieldOperand';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export type GQLClientFieldRevision = {
  __typename?: 'ClientFieldRevision';
  field: GQLBaseClientFieldInterface;
  fieldKey: Scalars['ID'];
  /** Built from ClientFieldKey-UpdatedFieldState-OldValue-NewValue */
  id: Scalars['ID'];
  newValue?: Maybe<Scalars['CustomObjectGraphType']>;
  oldValue?: Maybe<Scalars['CustomObjectGraphType']>;
  updatedFieldState: GQLUpdatedFieldState;
};

export type GQLClientFieldValue = {
  __typename?: 'ClientFieldValue';
  baseClientField?: Maybe<GQLBaseClientFieldInterface>;
  clientOrganization?: Maybe<GQLOrganization>;
  clientOrganizationId: Scalars['ID'];
  /** @deprecated Please use `BaseClientField` */
  field?: Maybe<GQLClientField>;
  fieldId: Scalars['ID'];
  sign?: Maybe<GQLClientFieldValueSign>;
  value?: Maybe<Scalars['String']>;
  valueFriendlyName?: Maybe<Scalars['String']>;
  label: Scalars['String'];
};

export enum GQLClientFieldValueSign {
  Minus = 'MINUS',
  Plus = 'PLUS'
}

export type GQLClientInput = {
  archivePeriod?: Maybe<Scalars['Int']>;
  archivePolicy?: Maybe<GQLArchivePolicyEnumInput>;
  connectionIds?: Maybe<Array<Scalars['ID']>>;
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
  legalEntityIdentifiers?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  productLineIds?: Maybe<Array<Scalars['ID']>>;
  receiverOnly?: Maybe<Scalars['Boolean']>;
  shouldPerformDedupOnSummeries?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Scalars['ID']>>;
  website?: Maybe<Scalars['String']>;
};

export type GQLClientRule = {
  __typename?: 'ClientRule';
  expression?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  timeBasedExpression?: Maybe<Scalars['Json']>;
};

export type GQLClientRuleInput = {
  expression: Scalars['Json'];
  id?: Scalars['ID'];
  timeBasedExpression?: Maybe<Scalars['Json']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
  whereTimeBasedSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLCombo = {
  __typename?: 'Combo';
  comboNum?: Maybe<Scalars['Float']>;
  sellComboNum?: Maybe<Scalars['Float']>;
  comboDetails: Array<GQLComboDetail>;
};

export type GQLComboDetail = {
  __typename?: 'ComboDetail';
  tradeFacts: Array<GQLTradeFact>;
  comboVers?: Maybe<Scalars['Float']>;
  entryComboNum?: Maybe<Scalars['Float']>;
  flipF?: Maybe<Scalars['String']>;
  epnMsgId?: Maybe<Scalars['String']>;
  nonStdAmt?: Maybe<Scalars['Float']>;
  goodMM?: Maybe<Scalars['Float']>;
  comboRPB?: Maybe<Scalars['Float']>;
  comboTs?: Maybe<Scalars['String']>;
  epnNotified?: Maybe<Scalars['String']>;
  allocComboStatus?: Maybe<Scalars['String']>;
  varStatus?: Maybe<Scalars['String']>;
  comboInvldC?: Maybe<Scalars['String']>;
  comboOverrideFlag?: Maybe<Scalars['Boolean']>;
  activeIndicator?: Maybe<Scalars['String']>;
};

export type GQLComboItems = {
  poolNumber: Scalars['String'];
  origFace: Scalars['Float'];
  deliveryDate?: Maybe<Scalars['String']>;
  poolRPB?: Maybe<Scalars['String']>;
  asOfDate?: Maybe<Scalars['String']>;
  nonStdAmount?: Maybe<Scalars['Float']>;
};

export type GQLComment = {
  __typename?: 'Comment';
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  /** @deprecated Use Time field instead */
  eventTime: Scalars['DateTime'];
  isInternal: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  user?: Maybe<GQLUser>;
  userId?: Maybe<Scalars['ID']>;
};

export type GQLCommentContentRecordEvent = {
  __typename?: 'CommentContentRecordEvent';
  comment?: Maybe<GQLComment>;
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  eventParameters: Array<Maybe<Scalars['Json']>>;
  eventTime: Scalars['DateTime'];
  eventType: GQLContentRecordEventType;
  text: Scalars['String'];
  user?: Maybe<GQLUser>;
  userId?: Maybe<Scalars['ID']>;
};

export type GQLCommentInput = {
  contextOrgId?: Maybe<Scalars['ID']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

export type GQLCompletionSuggestion = {
  __typename?: 'CompletionSuggestion';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  field: GQLBaseField;
  sourceOrganization: GQLOrganization;
  sourceOrganizationId: Scalars['ID'];
  value: Scalars['String'];
};

export type GQLComputableClientField = GQLBaseField & {
  __typename?: 'ComputableClientField';
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isIdentifier: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  type: GQLFieldType;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  comboId: Scalars['String'];
};

export type GQLComputableSystemField = GQLBaseField & GQLBaseSystemFieldInterface & {
  __typename?: 'ComputableSystemField';
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isIdentifier: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSideBar: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  systemFieldType: GQLSystemFieldType;
  type: GQLFieldType;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  comboId: Scalars['String'];
  label: Scalars['String'];
};

export enum GQLConditionType {
  Eq = 'EQ',
  Gt = 'GT',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  Lt = 'LT',
  Ne = 'NE'
}

export type GQLConnectivityAddress = {
  __typename?: 'ConnectivityAddress';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization: GQLOrganization;
  orgId: Scalars['ID'];
};

export enum GQLConnectorDirectionType {
  Both = 'BOTH',
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
  Unknown = 'UNKNOWN'
}

export type GQLConnectorLog = {
  __typename?: 'ConnectorLog';
  logs?: Maybe<Array<Maybe<GQLConnectorLogName>>>;
  page?: Maybe<Scalars['String']>;
};

export type GQLConnectorLogName = {
  __typename?: 'ConnectorLogName';
  entries?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
};

export type GQLConnectorStatus = {
  __typename?: 'ConnectorStatus';
  active: Scalars['Boolean'];
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorLastUpdate?: Maybe<Scalars['DateTime']>;
  contextUserName: Scalars['String'];
  direction: Scalars['String'];
  id: Scalars['String'];
  inactivityDays?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  setupStatus?: Maybe<GQLSetupStatus>;
  taskStatus?: Maybe<Scalars['String']>;
  taskStatusReason?: Maybe<Scalars['String']>;
};

export type GQLConstantOperand = GQLDataOperandInterface & GQLValueOperandInterface & {
  __typename?: 'ConstantOperand';
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
  value?: Maybe<Scalars['Primitive']>;
};

export type GQLConsumer = GQLDbConsumer | GQLHttpConsumer | GQLKafkaConsumer | GQLRabbitConsumer;

export type GQLContact = {
  __typename?: 'Contact';
  email: Scalars['String'];
  team?: Maybe<Scalars['String']>;
  narrative?: Maybe<Scalars['String']>;
  /** Org Id */
  aftParentId: Scalars['ID'];
  isDefault: Scalars['Boolean'];
};

export type GQLContainsExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'ContainsExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export enum GQLContentExportFormat {
  Csv = 'CSV',
  Json = 'JSON'
}

export enum GQLContentExportSource {
  Manual = 'MANUAL',
  Scheduled = 'SCHEDULED'
}

export enum GQLContentExportSourceInput {
  Manual = 'MANUAL',
  Scheduled = 'SCHEDULED'
}

export enum GQLContentExportStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  InQueue = 'IN_QUEUE',
  MultipleExportsRefuse = 'MULTIPLE_EXPORTS_REFUSE'
}

export type GQLContentRecord = {
  __typename?: 'ContentRecord';
  aggregatedParticipantRecords: Array<GQLAggregationParticipant>;
  aggregatedRecords: GQLCappedContentRecords;
  aggregationFieldValues: Array<GQLAggregationFieldValue>;
  aggregationGrouping: Array<GQLAggregationGrouping>;
  chatRoomInfo?: Maybe<GQLRoomInfo>;
  clientOrganizationId: Scalars['ID'];
  comments: Array<Maybe<GQLComment>>;
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  enrichmentFields: Array<GQLEnrichmentField>;
  events: Array<Maybe<GQLContentRecordEventUnionType>>;
  /** ClientFields */
  fields: Array<Maybe<GQLClientFieldValue>>;
  /** The set of filtered fields sorted by requested keys order. Missing field will be returned as null */
  filterFields: Array<Maybe<GQLBaseField>>;
  /** @deprecated Use RevisionsCount instead */
  hasRevisions: Scalars['Boolean'];
  id: Scalars['ID'];
  insertionTime: Scalars['DateTime'];
  isAggregated: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  isFinalized: Scalars['Boolean'];
  journeyResponses: Array<Maybe<GQLContentRecordResponse>>;
  journeyWatermarks: Array<Maybe<GQLContentRecordWatermark>>;
  lastReceivedTime?: Maybe<Scalars['DateTime']>;
  lastUpdateTime: Scalars['DateTime'];
  linkedGroups: Array<GQLLinkedGroup>;
  /**
   * The list of the notifications subscriptions for the current content record
   * @deprecated Use 'PaginatedNotificationSubscriptions' instead
   */
  notificationSubscriptions: Array<GQLNotificationSubscription>;
  organization: GQLOrganization;
  /** The list of the notifications subscriptions for the current content record paginated */
  paginatedNotificationSubscriptions: GQLCappedNotificationSubscriptions;
  permissions: Array<Maybe<GQLContentRecordPermission>>;
  revisions: Array<GQLContentRecordRevision>;
  revisionsCount: Scalars['Long'];
  riskItems: Array<Maybe<GQLRiskItem>>;
  serializedCompositeKey: Scalars['String'];
  systemFields: Array<GQLBaseSystemFieldInterface>;
  titleV2: Scalars['String'];
  /**
   * Gives access to record's inventory data. 'null' value means content record
   * doesn't have either Inventory content type or ISIN client field value. Check
   * 'status' property for data availability
   */
  inventory?: Maybe<GQLInventory>;
  commentCount: Scalars['Int'];
  attachmentsCount: Scalars['Int'];
  hasInstructionBeenRejected: Scalars['Boolean'];
  fieldValues: Array<Maybe<Scalars['String']>>;
  /** Gives access to AMAC abilities, 'null' means this content record can't use AMAC */
  amac?: Maybe<GQLAmac>;
  instructionCount: Scalars['Int'];
};


export type GQLContentRecordAggregatedRecordsArgs = {
  contextOrgId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
};


export type GQLContentRecordEnrichmentFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};


export type GQLContentRecordFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};


export type GQLContentRecordFilterFieldsArgs = {
  keys: Array<Scalars['ID']>;
};


export type GQLContentRecordNotificationSubscriptionsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLContentRecordPaginatedNotificationSubscriptionsArgs = {
  contextOrgId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type GQLContentRecordRevisionsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLContentRecordRiskItemsArgs = {
  filterExpression?: Maybe<Scalars['Json']>;
};


export type GQLContentRecordSystemFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};

export type GQLContentRecordActionAuditAttachment = GQLContentRecordActionAuditInterface & {
  __typename?: 'ContentRecordActionAuditAttachment';
  actionTime: Scalars['DateTime'];
  actionType: GQLContentRecordEventActionTypeInput;
  comment?: Maybe<Scalars['String']>;
  contentRecord: GQLContentRecord;
  contentRecordId: Scalars['ID'];
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  fileKey: Scalars['ID'];
  fileName: Scalars['ID'];
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  shortFileKey: Scalars['ID'];
};

export type GQLContentRecordActionAuditComment = GQLContentRecordActionAuditInterface & {
  __typename?: 'ContentRecordActionAuditComment';
  actionTime: Scalars['DateTime'];
  actionType: GQLContentRecordEventActionTypeInput;
  comment: Scalars['String'];
  contentRecord?: Maybe<GQLContentRecord>;
  contentRecordId: Scalars['ID'];
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type GQLContentRecordActionAuditInterface = {
  actionTime: Scalars['DateTime'];
  actionType: GQLContentRecordEventActionTypeInput;
  comment?: Maybe<Scalars['String']>;
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type GQLContentRecordActionAuditManualLinking = GQLContentRecordActionAuditInterface & {
  __typename?: 'ContentRecordActionAuditManualLinking';
  actionTime: Scalars['DateTime'];
  actionType: GQLContentRecordEventActionTypeInput;
  comment?: Maybe<Scalars['String']>;
  contentRecords: Array<GQLContentRecord>;
  contentRecordsIds: Array<Scalars['ID']>;
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  linkedGroup: GQLLinkedGroup;
  linkedGroupId: Scalars['ID'];
  orgId: Scalars['ID'];
  reason?: Maybe<GQLLinkedGroupReason>;
};

export type GQLContentRecordAttachment = {
  __typename?: 'ContentRecordAttachment';
  contentRecordId: Scalars['ID'];
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  /** @deprecated Use Time field instead */
  eventTime: Scalars['DateTime'];
  fileKey: Scalars['String'];
  fileName: Scalars['String'];
  isInternal: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  user?: Maybe<GQLUser>;
  userId?: Maybe<Scalars['ID']>;
};

export type GQLContentRecordAttachmentUrl = {
  __typename?: 'ContentRecordAttachmentUrl';
  fileContentType: Scalars['String'];
  fileKey: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export enum GQLContentRecordAuditEventType {
  Attachment = 'ATTACHMENT',
  Comment = 'COMMENT',
  ManualLinking = 'MANUAL_LINKING'
}

export type GQLContentRecordClientFieldsInput = {
  fieldName: Scalars['String'];
  value?: Maybe<Scalars['Primitive']>;
};

export type GQLContentRecordCompositeKey = {
  __typename?: 'ContentRecordCompositeKey';
  contentRecord?: Maybe<GQLContentRecord>;
  contentRecordId: Scalars['ID'];
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
};

export type GQLContentRecordCompositeKeyInput = {
  contentRecordId: Scalars['ID'];
  contentTypeId: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type GQLContentRecordEvent = {
  __typename?: 'ContentRecordEvent';
  comment?: Maybe<GQLComment>;
  eventParameters: Array<Maybe<Scalars['Json']>>;
  eventTime: Scalars['DateTime'];
  eventType: GQLContentRecordEventType;
};

export enum GQLContentRecordEventActionTypeInput {
  Attachment = 'ATTACHMENT',
  Comment = 'COMMENT',
  ManualLinking = 'MANUAL_LINKING'
}

export enum GQLContentRecordEventType {
  Attachment = 'ATTACHMENT',
  Cancelled = 'CANCELLED',
  Comment = 'COMMENT',
  ExternalMatch = 'EXTERNAL_MATCH',
  ExternalMatchBreak = 'EXTERNAL_MATCH_BREAK',
  Instructed = 'INSTRUCTED',
  ManualLink = 'MANUAL_LINK',
  MatchBreak = 'MATCH_BREAK',
  Matched = 'MATCHED',
  Unknown = 'UNKNOWN'
}

export type GQLContentRecordEventUnionType = GQLAttachmentContentRecordEvent | GQLCommentContentRecordEvent | GQLContentRecordEvent | GQLInstructionContentRecordEvent;

export enum GQLContentRecordPermission {
  Comment = 'COMMENT',
  ContentRecordReevaluate = 'CONTENT_RECORD_REEVALUATE',
  Instructions = 'INSTRUCTIONS'
}

export type GQLContentRecordResponse = {
  __typename?: 'ContentRecordResponse';
  field: GQLProductLineField;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  productLine?: Maybe<GQLProductLine>;
  productLineId: Scalars['ID'];
  receivedTime: Scalars['DateTime'];
  responseText?: Maybe<Scalars['String']>;
};

export type GQLContentRecordRevision = {
  __typename?: 'ContentRecordRevision';
  clientFieldRevisions: Array<GQLClientFieldRevision>;
  index: Scalars['Int'];
  updateTime: Scalars['DateTime'];
};

export type GQLContentRecordsSearchAdvancedQueryParams = {
  contentTypeId?: Maybe<Scalars['ID']>;
  isPaired?: Maybe<Scalars['Boolean']>;
};

export enum GQLContentRecordsSearchFilterType {
  All = 'ALL',
  EntitlementView = 'ENTITLEMENT_VIEW',
  Journey = 'JOURNEY',
  PairingCenter = 'PAIRING_CENTER',
  Tag = 'TAG',
  View = 'VIEW'
}

export type GQLContentRecordsSearchQueryParams = {
  filterExpression?: Maybe<Scalars['Json']>;
  filterIds?: Maybe<Array<Scalars['String']>>;
  filterType: GQLContentRecordsSearchFilterType;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLContentRecordSummaryBucket = {
  __typename?: 'ContentRecordSummaryBucket';
  groupings?: Maybe<Array<GQLAggregationSummary>>;
  instructedTotalCount?: Maybe<Scalars['Int']>;
  pairedTotalCount?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
};

export type GQLContentRecordSystemField = GQLBaseField & GQLBaseSystemFieldInterface & GQLInstructionActionField & {
  __typename?: 'ContentRecordSystemField';
  dereferenceValueType?: Maybe<GQLDereferencedValueType>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  friendlyValue?: Maybe<Scalars['String']>;
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSideBar: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  systemFieldType: GQLSystemFieldType;
  type: GQLFieldType;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  label: Scalars['String'];
  comboId: Scalars['String'];
};

export type GQLContentRecordUniverse = {
  __typename?: 'ContentRecordUniverse';
  actionsAudits: Array<GQLContentRecordActionAuditInterface>;
  /** @deprecated Please use `contentRecordsAggregations` */
  contentRecordFrequencies: Array<GQLFrequencyFieldSummary>;
  contentRecordsAggregations: Array<GQLFrequencyFieldSummary>;
};


export type GQLContentRecordUniverseActionsAuditsArgs = {
  actionType: GQLContentRecordAuditEventType;
  contentRecordIds?: Maybe<Array<Scalars['ID']>>;
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  endTime?: Maybe<Scalars['Date']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: Maybe<GQLActionAuditOrderBy>;
  startTime?: Maybe<Scalars['Date']>;
  timeFrameId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};


export type GQLContentRecordUniverseContentRecordFrequenciesArgs = {
  advancedFilter?: Maybe<GQLContentRecordsSearchAdvancedQueryParams>;
  aggregateBy: Array<GQLAggregationFieldConfigInput>;
  filter: GQLContentRecordsSearchQueryParams;
  include: Array<GQLFilterFieldConfigInput>;
};


export type GQLContentRecordUniverseContentRecordsAggregationsArgs = {
  advancedFilter?: Maybe<GQLContentRecordsSearchAdvancedQueryParams>;
  aggregateBy: Array<GQLAggregationFieldConfigInput>;
  filter: GQLContentRecordsSearchQueryParams;
  include: Array<GQLFilterFieldConfigInput>;
};

export type GQLContentRecordViewUniverse = {
  __typename?: 'ContentRecordViewUniverse';
  /** @deprecated Please use `contentRecordsAggregations` */
  contentRecordFrequencies: Array<GQLFrequencyFieldSummary>;
  contentRecords: GQLCappedContentRecords;
  contentRecordsAggregations: Array<GQLFrequencyFieldSummary>;
  /** returns flat aggregation fields according to the user preferences order if exists */
  contentRecordsFlatAggregations: Array<GQLFrequencyFieldSummary>;
  fields: Array<GQLBaseField>;
  preferencedFields: Array<GQLFieldDisplayConfiguration>;
};


export type GQLContentRecordViewUniverseContentRecordFrequenciesArgs = {
  aggregateBy: Array<GQLAggregationFieldConfigInput>;
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  include: Array<GQLFilterFieldConfigInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLContentRecordViewUniverseContentRecordsArgs = {
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  whereFilterExpression?: Maybe<GQLWhereFilterExpressionInput>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLContentRecordViewUniverseContentRecordsAggregationsArgs = {
  aggregateBy: Array<GQLAggregationFieldConfigInput>;
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  include: Array<GQLFilterFieldConfigInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLContentRecordViewUniverseContentRecordsFlatAggregationsArgs = {
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  include?: Maybe<Array<GQLFilterFieldConfigInput>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLContentRecordViewUniverseFieldsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLContentRecordViewUniversePreferencedFieldsArgs = {
  contextOrgId: Scalars['ID'];
};

export type GQLContentRecordWatermark = {
  __typename?: 'ContentRecordWatermark';
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  sentTime: Scalars['DateTime'];
  sourceCriteriaVersion: Scalars['Int'];
};

export type GQLContentType = {
  __typename?: 'ContentType';
  allowContentBusRead: Scalars['Boolean'];
  archivePeriod?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  permitted: Scalars['Boolean'];
};

export enum GQLContext {
  DataFiltering = 'DATA_FILTERING',
  Enrichment = 'ENRICHMENT',
  FieldMapping = 'FIELD_MAPPING',
  FileUpload = 'FILE_UPLOAD',
  GeneralInfo = 'GENERAL_INFO',
  Grouping = 'GROUPING',
  MetaData = 'META_DATA',
  OnBehalfOrganization = 'ON_BEHALF_ORGANIZATION',
  Review = 'REVIEW',
  Unknown = 'UNKNOWN'
}

export type GQLCountAggregationExpression = GQLAggregationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'CountAggregationExpression';
  expression?: Maybe<GQLBinaryEquationExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export type GQLCounter = {
  __typename?: 'Counter';
  count: Scalars['Int'];
  value: Scalars['Float'];
};

export type GQLCreateContentRecordInput = {
  clientFieldsValues: Array<GQLContentRecordClientFieldsInput>;
  contentRecordCompositeKey: GQLContentRecordCompositeKeyInput;
};

export enum GQLCustomMappingFormat {
  Csv = 'CSV',
  Fix = 'FIX',
  Json = 'JSON',
  KeyValue = 'KEY_VALUE',
  NotSet = 'NOT_SET',
  Swift = 'SWIFT',
  Xml = 'XML'
}


export type GQLDataFilter = {
  __typename?: 'DataFilter';
  delimiter?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<GQLDataFilterField>>>;
  keyValueSplitter?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GQLDataFilterField = {
  __typename?: 'DataFilterField';
  condition?: Maybe<GQLFieldCondition>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
};

export type GQLDataMapping = {
  __typename?: 'DataMapping';
  records?: Maybe<Array<Maybe<GQLDataMappingRecord>>>;
};

export type GQLDataMappingFile = {
  __typename?: 'DataMappingFile';
  file?: Maybe<Scalars['Base64File']>;
};

export type GQLDataMappingRecord = {
  __typename?: 'DataMappingRecord';
  hashcodeId?: Maybe<Scalars['Int']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  mapKey?: Maybe<Scalars['String']>;
  mapType?: Maybe<Scalars['String']>;
  mapValue?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
};

export type GQLDataMappingTypes = {
  __typename?: 'DataMappingTypes';
  mappingTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GQLDataOperandInterface = {
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export enum GQLDataSource {
  All = 'ALL',
  ContextAndEntitlementsOrgs = 'CONTEXT_AND_ENTITLEMENTS_ORGS',
  ContextOrg = 'CONTEXT_ORG',
  EntitlementsFromOrg = 'ENTITLEMENTS_FROM_ORG'
}



export type GQLDatum = {
  __typename?: 'Datum';
  primaryVal: Scalars['Float'];
  secondaryVal: Scalars['Float'];
};

export type GQLDbConnector = GQLBaseConnector & {
  __typename?: 'DbConnector';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  batchOutput?: Maybe<Scalars['Boolean']>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  direction: GQLConnectorDirectionType;
  flowId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  insertSql?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  outputOnEveryPoll?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  prefetchCount?: Maybe<Scalars['Int']>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  selectSql?: Maybe<Scalars['String']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  statusNote?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  updateBatchSize?: Maybe<Scalars['Int']>;
  updateSql?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLDbConsumer = {
  __typename?: 'DbConsumer';
  batchOutput?: Maybe<Scalars['Boolean']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  headerCount?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  outputOnEveryPoll?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  pollInterval?: Maybe<Scalars['Int']>;
  prefetchCount?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  selectSql?: Maybe<Scalars['String']>;
  updateBatchSize?: Maybe<Scalars['Int']>;
  updateSql?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  user: Scalars['String'];
};

export type GQLDbPublisher = {
  __typename?: 'DbPublisher';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  insertSql?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  reconnectInterval?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
  user: Scalars['String'];
};


export type GQLDefinition = {
  __typename?: 'Definition';
  clientRule?: Maybe<GQLClientRule>;
  /** @deprecated Use PaginatedRisksDefinition connection instead */
  conditions: Array<Maybe<GQLRiskProfileItem>>;
  id: Scalars['ID'];
  paginatedRisksDefinition?: Maybe<GQLRiskProfileItemConnection>;
};


export type GQLDefinitionPaginatedRisksDefinitionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GQLDefinitionInput = {
  clientRule?: Maybe<GQLClientRuleInput>;
  conditions?: Maybe<Array<Maybe<GQLRiskProfileItemInput>>>;
};

export enum GQLDeliveryType {
  GoodDelivery = 'GOOD_DELIVERY',
  BadDelivery = 'BAD_DELIVERY'
}

export enum GQLDereferencedValueType {
  DateTime = 'DATE_TIME',
  InstructionAction = 'INSTRUCTION_ACTION',
  None = 'NONE',
  Organization = 'ORGANIZATION',
  String = 'STRING',
  User = 'USER',
  UserGroup = 'USER_GROUP'
}

export type GQLDereferenceValue = GQLInstructionAction | GQLOrganization | GQLScalarValue | GQLUser | GQLUsersGroup;

export enum GQLDirectionType {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export type GQLDispatchingDetails = {
  __typename?: 'DispatchingDetails';
  destinationSystemAddress?: Maybe<GQLConnectivityAddress>;
  destinationSystemAddressId?: Maybe<Scalars['ID']>;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  filterExpression?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  lastUpdateTime: Scalars['DateTime'];
  /** This field is only relevant to Pairing 2.0 */
  pairingGroup?: Maybe<GQLPairingGroup>;
  /** This field is only relevant to Pairing 2.0 */
  pairingGroupId?: Maybe<Scalars['ID']>;
  /** This field is only relevant to Pairing 2.0 */
  pairingOrder?: Maybe<Scalars['Int']>;
  productLine?: Maybe<GQLProductLine>;
  productLineId?: Maybe<Scalars['ID']>;
  expressions: Array<GQLExpressionMeta>;
};


export type GQLDispatchingDetailsPairingGroupArgs = {
  contextOrgId: Scalars['ID'];
};

export type GQLDispatchingDetailsInput = {
  destinationSystemAddressId?: Maybe<Scalars['ID']>;
  filterExpression?: Maybe<Scalars['Json']>;
  /** This field is only relevant to Pairing 2.0 and should be sent together with PairingOrder */
  pairingGroupId?: Maybe<Scalars['ID']>;
  /** This field is only relevant to Pairing 2.0 and should be sent together with PairingGroupId */
  pairingOrder?: Maybe<Scalars['Int']>;
  productLineId?: Maybe<Scalars['ID']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLDoubleValueExpressionMeta = GQLExpressionMeta & {
  __typename?: 'DoubleValueExpressionMeta';
  id: Scalars['ID'];
  type: GQLExpressionType;
  field?: Maybe<GQLBaseField>;
  fullLabel: Scalars['String'];
  label: Scalars['String'];
  fieldLabel: Scalars['String'];
  operatorLabel: Scalars['String'];
  operatorSymbol: Scalars['String'];
  operator: GQLOperator;
  lowerValueLabel: Scalars['String'];
  upperValueLabel: Scalars['String'];
  /** Combined values */
  valueLabel: Scalars['String'];
  lowerValue: Scalars['StringOrIntOrFloatOrBoolean'];
  upperValue: Scalars['StringOrIntOrFloatOrBoolean'];
  expression: Scalars['Expression'];
};

export type GQLDownloadNotification = GQLNotification & {
  __typename?: 'DownloadNotification';
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  creationTime: Scalars['DateTime'];
  eventType: GQLNotificationEventType;
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  user: GQLUser;
  userId: Scalars['ID'];
};

export type GQLDynamicOrderBy = {
  direction: GQLOrderDirection;
  fieldId?: Maybe<Scalars['ID']>;
  fieldKey?: Maybe<Scalars['ID']>;
};

export enum GQLElasticActionType {
  DbUpload = 'DB_UPLOAD',
  Replay = 'REPLAY',
  Resend = 'RESEND',
  ResendByIds = 'RESEND_BY_IDS'
}

export enum GQLElasticProtocolType {
  Fix = 'FIX',
  Http = 'HTTP',
  Ibmmq = 'IBMMQ'
}

export type GQLEmailAddressMapping = {
  __typename?: 'EmailAddressMapping';
  destinationId?: Maybe<Scalars['String']>;
  dispatchType?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  enable?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type GQLEmailDestinationTemplate = {
  __typename?: 'EmailDestinationTemplate';
  destinationId?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  subjectTemplate?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

export type GQLEmailIdentity = {
  __typename?: 'EmailIdentity';
  emailAddress: Scalars['String'];
  name: Scalars['String'];
  user?: Maybe<GQLUser>;
};

export enum GQLEmailStatus {
  Received = 'RECEIVED',
  Sent = 'SENT'
}

export type GQLEmailTemplate = {
  __typename?: 'EmailTemplate';
  id?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};

export type GQLEmailTemplateTestResponse = {
  __typename?: 'EmailTemplateTestResponse';
  generatedMessage?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type GQLEmptyExpression = GQLExpressionInterface & {
  __typename?: 'EmptyExpression';
  isFlat: Scalars['Boolean'];
};

export type GQLEndsWithExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'EndsWithExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLEnrichedFilterExpression = {
  __typename?: 'EnrichedFilterExpression';
  expression: Scalars['Json'];
  fields: Array<Maybe<GQLBaseField>>;
};

export type GQLEnrichedJourney = {
  __typename?: 'EnrichedJourney';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  definition: GQLDefinition;
  definitions: Array<Maybe<GQLDefinition>>;
  dispatchEvaluationStatus?: Maybe<Scalars['String']>;
  displayData?: Maybe<Scalars['Json']>;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  expressionParseVersion: Scalars['Int'];
  id: Scalars['ID'];
  journeyTypeId: Scalars['ID'];
  name: Scalars['String'];
  organization: GQLOrganization;
  organizationId: Scalars['ID'];
  productLine?: Maybe<GQLProductLine>;
  productLineId: Scalars['ID'];
  riskDefinition?: Maybe<GQLRiskProfileItem>;
  riskEvaluationStatus?: Maybe<Scalars['String']>;
  riskProfile?: Maybe<GQLRiskProfile>;
  shouldDispatch: Scalars['Boolean'];
  shouldEvaluate: Scalars['Boolean'];
  sourceCriteria?: Maybe<Scalars['Json']>;
  sourceCriteriaVersion: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
  tags: Array<GQLTag>;
  timeBasedSourceCriteria?: Maybe<Scalars['Json']>;
  version: Scalars['Int'];
};


export type GQLEnrichedJourneyDefinitionArgs = {
  id: Scalars['ID'];
};


export type GQLEnrichedJourneyRiskDefinitionArgs = {
  id: Scalars['ID'];
};

export type GQLEnrichmentActivity = {
  __typename?: 'EnrichmentActivity';
  activityFieldsCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLEnrichmentActivityInput = {
  name: Scalars['String'];
};

export type GQLEnrichmentField = GQLBaseField & GQLInstructionActionField & {
  __typename?: 'EnrichmentField';
  /** @deprecated Use 'FriendlyName' instead */
  fieldFriendlyName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSideBar: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  section: Scalars['String'];
  type: GQLFieldType;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  comboId: Scalars['String'];
  label: Scalars['String'];
};

export type GQLEnrichmentFieldInput = {
  fieldType: GQLFieldType;
  friendlyName?: Maybe<Scalars['String']>;
  isContentRecordGrid?: Maybe<Scalars['Boolean']>;
  isFrequency?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isInstructionGrid?: Maybe<Scalars['Boolean']>;
  isRiskGrid?: Maybe<Scalars['Boolean']>;
  isSideBar?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type GQLEnrichmentRuleDefinitions = {
  __typename?: 'EnrichmentRuleDefinitions';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  enrichmentExpression: Scalars['Json'];
  fieldName: Scalars['String'];
  fieldType: GQLFieldType;
  filterExpression?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  organization?: Maybe<GQLOrganization>;
  orgId?: Maybe<Scalars['ID']>;
  timeBasedFilterExpression?: Maybe<Scalars['Json']>;
};

export type GQLEnrichmentRuleDefinitionsInput = {
  activityId: Scalars['ID'];
  contentTypeId: Scalars['ID'];
  enrichmentExpression: Scalars['Json'];
  enrichmentFieldName: Scalars['String'];
  filterExpression?: Maybe<Scalars['Json']>;
  orgId?: Maybe<Scalars['ID']>;
  timeBasedExpression?: Maybe<Scalars['Json']>;
};

export type GQLEntitlement = {
  creationTime?: Maybe<Scalars['DateTime']>;
  entitlementRule: GQLEntitlementRule;
  entitlementRuleId: Scalars['ID'];
  instructionActionsPermissions: Array<Scalars['String']>;
  key: Scalars['ID'];
  lastEditingUser?: Maybe<GQLUser>;
  lastEditingUserId?: Maybe<Scalars['ID']>;
};

export type GQLEntitlementFieldsSchema = {
  __typename?: 'EntitlementFieldsSchema';
  creationTime?: Maybe<Scalars['DateTime']>;
  entitlementKeys: Array<Scalars['ID']>;
  entitlements: Array<GQLEntitlement>;
  fields?: Maybe<Array<GQLBaseField>>;
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<GQLOrganization>;
  /** Org id of the schema. Will be null if the schema is generic. */
  orgId?: Maybe<Scalars['ID']>;
};

export type GQLEntitlementFieldsSchemaInput = {
  /**
   * Generic or client field keys of the schema. Generic fields are allowed only if
   * OrgId is null, else only client fields of that organization are allowed.
   */
  fieldsKeys: Array<Scalars['ID']>;
  friendlyName: Scalars['String'];
  name: Scalars['String'];
  /**
   * Organization ID of the schema. Cannot be modified after creation. Generic
   * fields schemas should be created with null org ID, and can only be created by global admin.
   */
  orgId?: Maybe<Scalars['ID']>;
};

export type GQLEntitlementRule = GQLRuleInterface & {
  __typename?: 'EntitlementRule';
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  expression?: Maybe<Scalars['Json']>;
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isGlobal: Scalars['Boolean'];
  /** A null OrgId indicates that this expression is generic and accessible for any organization */
  orgId?: Maybe<Scalars['ID']>;
  referringEntitlementKeys: Array<Scalars['ID']>;
  /** The entitlements referring this entitlement rule */
  referringEntitlements: Array<GQLEntitlement>;
};

/** A connection from an object to a list of objects of type `EntitlementRule`. */
export type GQLEntitlementRuleConnection = {
  __typename?: 'EntitlementRuleConnection';
  /** A list of all of the edges returned in the connection. */
  edges?: Maybe<Array<Maybe<GQLEntitlementRuleEdge>>>;
  /**
   * A list of all of the objects returned in the connection. This is a convenience
   * field provided for quickly exploring the API; rather than querying for "{
   * edges { node } }" when no edge data is needed, this field can be used instead.
   * Note that when clients like Relay need to fetch the "cursor" field on the edge
   * to enable efficient pagination, this shortcut cannot be used, and the full "{
   * edges { node } } " version should be used instead.
   */
  items?: Maybe<Array<GQLEntitlementRule>>;
  /** Information to aid in pagination. */
  pageInfo: GQLPageInfo;
  /**
   * A count of the total number of objects in this connection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to `first`, then fetch the total count so it could display
   * "5 of 83", for example. In cases where we employ infinite scrolling or don't
   * have an exact count of entries, this field will return `null`.
   */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `EntitlementRule`. */
export type GQLEntitlementRuleEdge = {
  __typename?: 'EntitlementRuleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: GQLEntitlementRule;
};

export type GQLEntitlementRuleInput = {
  expression: Scalars['Json'];
  friendlyName?: Maybe<Scalars['String']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['ID']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLEntitlementUniverse = {
  __typename?: 'EntitlementUniverse';
  /** Get schema by ID */
  entitlementFieldsSchema: GQLEntitlementFieldsSchema;
  /** Get all available schemas for this organization, including generic schemas and organization specific schema */
  entitlementFieldsSchemas: Array<GQLEntitlementFieldsSchema>;
  entitlementsFromOrganization: Array<GQLOrganizationEntitlement>;
  entitlementsToOrganization: Array<GQLOrganizationEntitlement>;
  entitlementViews: Array<GQLView>;
  /** Get generic entitlement fields schemas */
  genericEntitlementFieldsSchemas: Array<GQLEntitlementFieldsSchema>;
  /** Get schemas of this organization (exclude generic) */
  organizationEntitlementFieldsSchemas: Array<GQLEntitlementFieldsSchema>;
};


export type GQLEntitlementUniverseEntitlementFieldsSchemaArgs = {
  schemaId: Scalars['Int'];
};

export type GQLEntityAudit = {
  __typename?: 'EntityAudit';
  action?: Maybe<GQLAction>;
  contextUser?: Maybe<Scalars['String']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  rollbackSourceVersion?: Maybe<Scalars['String']>;
  sourceVersion?: Maybe<Scalars['String']>;
  sourceVersionDb?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  targetVersion?: Maybe<Scalars['String']>;
  targetVersionDb?: Maybe<Scalars['String']>;
};

export type GQLEntityDiff = {
  __typename?: 'EntityDiff';
  children?: Maybe<Array<GQLEntityDiff>>;
  fieldName?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type GQLEntityDiffResult = {
  __typename?: 'EntityDiffResult';
  diff?: Maybe<GQLEntityDiff>;
  sourceVersion?: Maybe<Scalars['String']>;
  sourceVersionDb?: Maybe<Scalars['String']>;
  targetVersion?: Maybe<Scalars['String']>;
  targetVersionDb?: Maybe<Scalars['String']>;
};

export type GQLEntitySummary = {
  __typename?: 'EntitySummary';
  entitySummaryBucket: GQLEntitySummaryBucket;
  isAlerting: Scalars['Boolean'];
  view: GQLView;
};

export type GQLEntitySummaryBucket = GQLContentRecordSummaryBucket | GQLRiskSummaryBucket;

export type GQLEqualsExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'EqualsExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLEquationExpressionInterface = {
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
};

export enum GQLEquationOperator {
  Between = 'BETWEEN',
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  OppositeValues = 'OPPOSITE_VALUES'
}

export enum GQLEquationOperatorInput {
  Between = 'BETWEEN',
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  OppositeValues = 'OPPOSITE_VALUES'
}

export type GQLExportStatus = {
  __typename?: 'ExportStatus';
  currentFileSize: Scalars['Int'];
  docId: Scalars['ID'];
  fileName: Scalars['String'];
  lastStatusUpdateTime: Scalars['DateTime'];
  organization: GQLOrganization;
  organizationId: Scalars['ID'];
  source: GQLContentExportSource;
  status: GQLContentExportStatus;
  totalItemsCount: Scalars['Long'];
  uploadedItemsCount: Scalars['Long'];
  user: GQLUser;
  userId: Scalars['ID'];
};


export type GQLExpressionInterface = {
  isFlat: Scalars['Boolean'];
};

export type GQLExpressionMeta = {
  /** Although there isn't an id for expressions on the API, Apollo provides a composite id for better UI caching */
  id: Scalars['ID'];
  /** Type of expression inferred by the operator */
  type: GQLExpressionType;
  /** Field may be null due to permissions */
  field?: Maybe<GQLBaseField>;
  /** Human readable version of the entire expression */
  fullLabel: Scalars['String'];
  valueLabel: Scalars['String'];
  label: Scalars['String'];
  fieldLabel: Scalars['String'];
  operatorLabel: Scalars['String'];
  /** Unicode symbols of operators */
  operatorSymbol: Scalars['String'];
  operator: GQLOperator;
  expression: Scalars['Expression'];
};

export enum GQLExpressionType {
  NoValue = 'NO_VALUE',
  SingleValue = 'SINGLE_VALUE',
  DoubleValue = 'DOUBLE_VALUE',
  MultipleValue = 'MULTIPLE_VALUE'
}

export type GQLExpressionValueBinaryInput = {
  lowerBound: Scalars['Primitive'];
  upperBound: Scalars['Primitive'];
};

export type GQLExpressionValueListInput = {
  values: Array<Scalars['Primitive']>;
};

export type GQLExpressionValueUnaryInput = {
  value: Scalars['Primitive'];
};

export type GQLExtendedClientField = GQLAggregationGroupingField & GQLBaseClientFieldInterface & GQLBaseField & GQLInstructionActionField & {
  __typename?: 'ExtendedClientField';
  aggregationMode?: Maybe<GQLFieldUniverseAggregationMode>;
  archiveMetadataIndex?: Maybe<Scalars['Int']>;
  clientFieldMappingSetup?: Maybe<GQLClientFieldMappingSetup>;
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  /** @deprecated Use 'FriendlyName' instead */
  fieldFriendlyName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  frequencyRank?: Maybe<Scalars['Int']>;
  friendlyName: Scalars['String'];
  /** @deprecated Please use GenericFieldUniverseConfig for the connected GenericField config */
  genericField?: Maybe<GQLClientField>;
  genericFieldId?: Maybe<Scalars['Int']>;
  genericFieldUniverseConfig?: Maybe<GQLGenericField>;
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isAge: Scalars['Boolean'];
  isComment: Scalars['Boolean'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isEntitlement: Scalars['Boolean'];
  isExpiration: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isGrouping: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isMandatory: Scalars['Boolean'];
  isPartOfRiskItem: Scalars['Boolean'];
  isRepeatable: Scalars['Boolean'];
  isRiskFactor: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isShareable: Scalars['Boolean'];
  isShareRuleField: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  /** @deprecated Deprecated. */
  isTimeBased: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  organization?: Maybe<GQLOrganization>;
  organizationId: Scalars['ID'];
  sampleValue?: Maybe<Scalars['String']>;
  shouldCollectValues: Scalars['Boolean'];
  status?: Maybe<GQLFieldUniverseConfigStatus>;
  titleRank?: Maybe<Scalars['Int']>;
  type: GQLFieldType;
  values: Array<Maybe<GQLClientFieldValue>>;
};

export type GQLFieldAttribute = {
  __typename?: 'FieldAttribute';
  category?: Maybe<GQLFieldCategoryType>;
  conditions?: Maybe<Array<Maybe<GQLFieldCondition>>>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum GQLFieldCategoryType {
  Admin = 'ADMIN',
  Advanced = 'ADVANCED',
  General = 'GENERAL',
  Header = 'HEADER',
  Protocol = 'PROTOCOL'
}

export enum GQLFieldCondition {
  Contains = 'CONTAINS',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL'
}

export type GQLFieldDisplayConfiguration = {
  __typename?: 'FieldDisplayConfiguration';
  columnWidth?: Maybe<Scalars['UInt']>;
  field: GQLBaseField;
  fieldKey: Scalars['ID'];
  index: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  orderDirection?: Maybe<GQLOrderDirection>;
  orderIndex?: Maybe<Scalars['UInt']>;
  pinnedColumnPosition?: Maybe<GQLHorizontalPosition>;
  /** @deprecated use columnWidth */
  pinnedColumnWidth?: Maybe<Scalars['UInt']>;
};

export type GQLFieldDisplayConfigurationInput = {
  columnWidth?: Maybe<Scalars['UInt']>;
  fieldKey: Scalars['ID'];
  index: Scalars['UInt'];
  isVisible: Scalars['Boolean'];
  orderDirection?: Maybe<GQLOrderDirection>;
  orderIndex?: Maybe<Scalars['UInt']>;
  pinnedColumnPosition?: Maybe<GQLHorizontalPositionFilterType>;
  /** deprecated - use columnWidth */
  pinnedColumnWidth?: Maybe<Scalars['UInt']>;
};

export type GQLFieldOperandInterface = {
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export enum GQLFieldStatus {
  Ignored = 'IGNORED',
  Live = 'LIVE',
  Pending = 'PENDING'
}

export enum GQLFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Double = 'DOUBLE',
  Integer = 'INTEGER',
  String = 'STRING'
}

export type GQLFieldUniverse = {
  __typename?: 'FieldUniverse';
  clientFields: Array<GQLClientField>;
  /** @deprecated use ExtendedClientFieldsConfig */
  clientFieldsConfig: Array<GQLClientField>;
  extendedClientFieldsConfig: Array<GQLExtendedClientField>;
  instructionParameterFields: Array<GQLInstructionParameterField>;
  productLineFields: Array<GQLProductLineField>;
  /** @deprecated Please use SuggestCompletionV2 */
  suggestCompletion: Array<GQLCompletionSuggestion>;
  suggestCompletionV2: Array<GQLFieldValueCompletionSuggestion>;
};


export type GQLFieldUniverseClientFieldsArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  contextOrgId: Scalars['ID'];
  isContentRecordGrid?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isRiskGrid?: Maybe<Scalars['Boolean']>;
  isTimeFilter?: Maybe<Scalars['Boolean']>;
};


export type GQLFieldUniverseClientFieldsConfigArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  fieldUniverseConfigStatus?: Maybe<GQLFieldUniverseConfigStatus>;
  isHidden?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type GQLFieldUniverseExtendedClientFieldsConfigArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  contextOrgId: Scalars['ID'];
  fieldUniverseConfigStatus?: Maybe<GQLFieldUniverseConfigStatus>;
  isHidden?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type GQLFieldUniverseInstructionParameterFieldsArgs = {
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  relatedToActionIds?: Maybe<Array<Scalars['ID']>>;
};


export type GQLFieldUniverseProductLineFieldsArgs = {
  productLineIds?: Maybe<Array<Scalars['ID']>>;
};


export type GQLFieldUniverseSuggestCompletionArgs = {
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  limit?: Maybe<Scalars['Int']>;
  searchPattern: Scalars['String'];
};


export type GQLFieldUniverseSuggestCompletionV2Args = {
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  limit?: Maybe<Scalars['Int']>;
  searchPattern: Scalars['String'];
};

export enum GQLFieldUniverseAggregationMode {
  Sum = 'SUM'
}

export enum GQLFieldUniverseConfigStatus {
  Ignored = 'IGNORED',
  Live = 'LIVE',
  Pending = 'PENDING'
}

export type GQLFieldValueCompletionSuggestion = {
  __typename?: 'FieldValueCompletionSuggestion';
  field: GQLBaseField;
  isManual: Scalars['Boolean'];
  suggestion: Scalars['String'];
};

export type GQLFileConnector = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'FileConnector';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  defaultMessageType?: Maybe<Scalars['String']>;
  doneDirectory?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  errorDirectory?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ignoreHiddenFiles?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLFileUploadDetail = {
  __typename?: 'FileUploadDetail';
  customFormat?: Maybe<GQLCustomMappingFormat>;
  delimiter?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  format?: Maybe<GQLInboundSourceType>;
  genericFormat?: Maybe<GQLGenericMappingFormat>;
  headerRowCount?: Maybe<Scalars['Int']>;
  jsonPath?: Maybe<Scalars['String']>;
  mappingType?: Maybe<GQLMappingDataType>;
  minColumnCount?: Maybe<Scalars['Int']>;
  minDataCount?: Maybe<Scalars['Int']>;
  noMapping?: Maybe<GQLNoneMappingFormat>;
  outboundFormat?: Maybe<GQLOutboundSourceType>;
  sheetNumber?: Maybe<Scalars['Int']>;
};

export type GQLFilterExpressionEquationBinaryInput = {
  entity?: Maybe<Scalars['String']>;
  expressionValue: GQLExpressionValueBinaryInput;
  key: Scalars['ID'];
};

export type GQLFilterExpressionEquationListInput = {
  entity?: Maybe<Scalars['String']>;
  expressionValue: GQLExpressionValueListInput;
  key: Scalars['ID'];
};

export type GQLFilterExpressionEquationNullaryInput = {
  entity?: Maybe<Scalars['String']>;
  key: Scalars['ID'];
};

export type GQLFilterExpressionEquationUnaryInput = {
  entity?: Maybe<Scalars['String']>;
  expressionValue: GQLExpressionValueUnaryInput;
  key: Scalars['ID'];
};

export type GQLFilterExpressionLogicalListInput = {
  expressions: Array<GQLWhereFilterExpressionInput>;
};

export type GQLFilterExpressionLogicalUnaryInput = {
  expression: GQLWhereFilterExpressionInput;
};

export type GQLFilterFieldConfigInput = {
  fieldKey: Scalars['ID'];
  fieldValue?: Maybe<Scalars['CustomObjectGraphType']>;
};

export enum GQLFixConnectionType {
  Acceptor = 'ACCEPTOR',
  Initiator = 'INITIATOR',
  Unknown_1 = 'UNKNOWN_1'
}

export type GQLFixConnector = GQLBaseConnector & {
  __typename?: 'FixConnector';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkCompID?: Maybe<Scalars['Boolean']>;
  checkLatency?: Maybe<Scalars['Boolean']>;
  clientIp?: Maybe<Scalars['String']>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  enableDynamicAcceptorSessions?: Maybe<Scalars['Boolean']>;
  endDay?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  extractFixN?: Maybe<Scalars['Boolean']>;
  flowId?: Maybe<Scalars['String']>;
  generateFixN?: Maybe<Scalars['Boolean']>;
  handleMsgSeqNumTooLow?: Maybe<Scalars['Boolean']>;
  header?: Maybe<GQLFixHeader>;
  heartBeatInterval: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoutOnMissingMessages?: Maybe<Scalars['Boolean']>;
  maxLatency?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  missingMessagesTimeout?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nonFixPayload?: Maybe<Scalars['Boolean']>;
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  persistMessages?: Maybe<Scalars['Boolean']>;
  port: Scalars['Int'];
  protocolType?: Maybe<GQLProtocolType>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  refreshOnLogon?: Maybe<Scalars['Boolean']>;
  rejectInvalidMessage?: Maybe<Scalars['Boolean']>;
  resendRequestChunkSize?: Maybe<Scalars['Int']>;
  resetOnLogon?: Maybe<Scalars['Boolean']>;
  resetOnLogout?: Maybe<Scalars['Boolean']>;
  senderComp: Scalars['String'];
  sendResetSeqNumFlag?: Maybe<Scalars['Boolean']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  socketHost?: Maybe<Scalars['String']>;
  socketInsecureSkipVerify?: Maybe<Scalars['Boolean']>;
  socketPort?: Maybe<Scalars['Int']>;
  splitter?: Maybe<GQLSplitter>;
  ssl: Scalars['Boolean'];
  startDay?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  targetComp: Scalars['String'];
  taskStatus?: Maybe<Scalars['String']>;
  timeStampPrecision?: Maybe<GQLFixTimeStampPrecision>;
  timeZone: Scalars['String'];
  tlsSecret?: Maybe<GQLTlsSecret>;
  type: GQLFixConnectionType;
  url?: Maybe<Scalars['String']>;
  useAsyncPublisher?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['String']>;
  validateFieldsHaveValues?: Maybe<Scalars['Boolean']>;
  validateFieldsOutOfOrder?: Maybe<Scalars['Boolean']>;
  validateLengthAndChecksum?: Maybe<Scalars['Boolean']>;
  validateUserDefinedFields?: Maybe<Scalars['Boolean']>;
  version: GQLFixVersions;
};

export type GQLFixHeader = {
  __typename?: 'FixHeader';
  deliverToCompID?: Maybe<Scalars['String']>;
  deliverToLocationID?: Maybe<Scalars['String']>;
  deliverToSubID?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['Json']>;
  lastMsgSeqNumProcessed?: Maybe<Scalars['Int']>;
  messageEncoding?: Maybe<Scalars['String']>;
  noHops?: Maybe<Array<Maybe<GQLFixNoHop>>>;
  onBehalfOfCompID?: Maybe<Scalars['String']>;
  onBehalfOfLocationID?: Maybe<Scalars['String']>;
  onBehalfOfSubID?: Maybe<Scalars['String']>;
  origSendingTime?: Maybe<Scalars['DateTime']>;
  possDupFlag?: Maybe<Scalars['Boolean']>;
  possResend?: Maybe<Scalars['Boolean']>;
  secureData?: Maybe<Scalars['String']>;
  secureDataLen?: Maybe<Scalars['Int']>;
  senderLocationID?: Maybe<Scalars['String']>;
  senderSubID?: Maybe<Scalars['String']>;
  targetLocationID?: Maybe<Scalars['String']>;
  targetSubID?: Maybe<Scalars['String']>;
};

export enum GQLFixMessageTypeEnum {
  N = 'N'
}

export type GQLFixNoHop = {
  __typename?: 'FixNoHop';
  hopCompID: Scalars['String'];
  hopRefID?: Maybe<Scalars['Int']>;
  hopSendingTime?: Maybe<Scalars['DateTime']>;
};

export type GQLFixSequence = {
  __typename?: 'FixSequence';
  incomingSequenceNumber: Scalars['Int'];
  outgoingSequenceNumber: Scalars['Int'];
};

export enum GQLFixTimeStampPrecision {
  Micros = 'MICROS',
  Millis = 'MILLIS',
  Nanos = 'NANOS',
  Seconds = 'SECONDS'
}

export enum GQLFixVersions {
  Fix_40 = 'FIX_40',
  Fix_41 = 'FIX_41',
  Fix_42 = 'FIX_42',
  Fix_43 = 'FIX_43',
  Fix_44 = 'FIX_44',
  Fixt_11 = 'FIXT_11',
  Unknown = 'UNKNOWN'
}

export type GQLFlag = {
  __typename?: 'Flag';
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type GQLFrequencyFieldSummary = {
  __typename?: 'FrequencyFieldSummary';
  field?: Maybe<GQLBaseField>;
  fieldSummaries: Array<Maybe<GQLFrequencyValueSummary>>;
  hasMoreResults: Scalars['Boolean'];
  key: Scalars['ID'];
  totalCount: Scalars['Int'];
  /** @deprecated Use 'totalValues' */
  totalSum: Scalars['Float'];
  totalValues: Array<GQLAggregationCounter>;
};

export enum GQLFrequencyFieldType {
  All = 'ALL',
  ClientField = 'CLIENT_FIELD',
  ProductLineField = 'PRODUCT_LINE_FIELD',
  SystemField = 'SYSTEM_FIELD'
}

export type GQLFrequencyValueSummary = {
  __typename?: 'FrequencyValueSummary';
  count: Scalars['Int'];
  dereferenceValue?: Maybe<GQLDereferenceValue>;
  friendlyValue?: Maybe<Scalars['String']>;
  isAccurate: Scalars['Boolean'];
  summaries: Array<GQLAggregationCounter>;
  /** @deprecated Use 'summaries' */
  summary: GQLCounter;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
};

export type GQLGeneralAction = GQLActionInterface & {
  __typename?: 'GeneralAction';
  /**
   * action's name
   * @deprecated use ActionName instead
   */
  action?: Maybe<Scalars['String']>;
  actionName: Scalars['String'];
  friendlyName: Scalars['String'];
  id: Scalars['ID'];
};

export type GQLGenericField = GQLBaseClientFieldInterface & GQLBaseField & GQLInstructionActionField & {
  __typename?: 'GenericField';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  suggestedValues: Array<Scalars['String']>;
  type: GQLFieldType;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  label: Scalars['String'];
  comboId: Scalars['String'];
};


export type GQLGenericFieldSuggestedValuesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GQLGenericFieldOperand = GQLFieldOperandInterface & GQLGenericFieldOperandInterface & GQLValueOperandInterface & {
  __typename?: 'GenericFieldOperand';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export type GQLGenericFieldOperandInterface = {
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export enum GQLGenericMappingFormat {
  GenericCsv = 'GENERIC_CSV',
  GenericJson = 'GENERIC_JSON',
  GenericXml = 'GENERIC_XML'
}

export type GQLGreaterThanExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'GreaterThanExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLGreaterThanOrEqualsExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'GreaterThanOrEqualsExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLGroupingFieldConfig = {
  __typename?: 'GroupingFieldConfig';
  field: GQLBaseField;
  index: Scalars['UInt'];
};

export type GQLGroupingFieldConfigInput = {
  fieldKey: Scalars['ID'];
  index: Scalars['UInt'];
};

export type GQLHeaderProperties = {
  __typename?: 'HeaderProperties';
  delimiter?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<GQLFieldAttribute>>>;
  keyValueSplitter?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum GQLHeaderType {
  DataFilter = 'DATA_FILTER',
  HeaderFilter = 'HEADER_FILTER'
}

export enum GQLHorizontalPosition {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export enum GQLHorizontalPositionFilterType {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type GQLHttpConnector = GQLHttpConnectorClient | GQLHttpConnectorServer;

export type GQLHttpConnectorClient = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'HttpConnectorClient';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  cAFile?: Maybe<GQLCertificate>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLConnectorDirectionType>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  fromDate?: Maybe<GQLHttpPersistentFields>;
  generateJwt?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  lastPollTime?: Maybe<GQLHttpPersistentFields>;
  maxPages?: Maybe<Scalars['Int']>;
  maxRecords?: Maybe<Scalars['Int']>;
  maxRequestSize?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  minReplySize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  oauthClientId?: Maybe<Scalars['String']>;
  oauthClientSecret?: Maybe<Scalars['String']>;
  oauthGrantType?: Maybe<Scalars['String']>;
  oauthScope?: Maybe<Scalars['String']>;
  oauthType?: Maybe<GQLOauthType>;
  oauthUrl?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  privateKey?: Maybe<GQLCertificate>;
  protocolType?: Maybe<GQLProtocolType>;
  publicCertificate?: Maybe<GQLCertificate>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  requestBody?: Maybe<Scalars['String']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Int']>;
  tlsSecret?: Maybe<GQLTlsSecret>;
  toDate?: Maybe<GQLHttpPersistentFields>;
  url?: Maybe<Scalars['String']>;
  useBasicAuth?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLHttpConnectorServer = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'HttpConnectorServer';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  cAFile?: Maybe<GQLCertificate>;
  clientIp: Scalars['String'];
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLConnectorDirectionType>;
  flowId?: Maybe<Scalars['String']>;
  generateJwt?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  maxPages?: Maybe<Scalars['Int']>;
  maxRecords?: Maybe<Scalars['Int']>;
  maxRequestSize?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  minReplySize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  oauthClientId?: Maybe<Scalars['String']>;
  oauthClientSecret?: Maybe<Scalars['String']>;
  oauthGrantType?: Maybe<Scalars['String']>;
  oauthScope?: Maybe<Scalars['String']>;
  oauthUrl?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  port: Scalars['Int'];
  privateKey?: Maybe<GQLCertificate>;
  protocolType?: Maybe<GQLProtocolType>;
  publicCertificate?: Maybe<GQLCertificate>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  requestBody?: Maybe<Scalars['String']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Int']>;
  tlsSecret?: Maybe<GQLTlsSecret>;
  url?: Maybe<Scalars['String']>;
  useBasicAuth?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLHttpConsumer = {
  __typename?: 'HttpConsumer';
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isServer?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
};

export type GQLHttpPersistentFields = {
  __typename?: 'HttpPersistentFields';
  days?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
};

export enum GQLIbmmqConnectionType {
  AfClientToServer = 'AF_CLIENT_TO_SERVER',
  ClientToAfServer = 'CLIENT_TO_AF_SERVER',
  ServerServer = 'SERVER_SERVER'
}

export type GQLIbmMqConnector = GQLIbmMqConnectorIn | GQLIbmMqConnectorOut;

export type GQLIbmMqConnectorIn = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'IbmMqConnectorIn';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLAggregator>;
  connectionType?: Maybe<GQLIbmmqConnectionType>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  highVolume?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  maxMessageLength?: Maybe<Scalars['Int']>;
  messageFormat?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  queue?: Maybe<Array<Maybe<GQLIbmQueueGraphType>>>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLIbmMqConnectorOut = GQLBaseConnector & GQLOutboundConnector & {
  __typename?: 'IbmMqConnectorOut';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectionType?: Maybe<GQLIbmmqConnectionType>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  messageFormat?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  queue?: Maybe<Array<Maybe<GQLIbmQueueGraphType>>>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLIbmQueueGraphType = {
  __typename?: 'IbmQueueGraphType';
  name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type GQLIdentity = GQLUser | GQLUsersGroup;

export enum GQLIdentityType {
  Group = 'GROUP',
  User = 'USER'
}

export enum GQLImageType {
  Connector = 'CONNECTOR',
  ConnectorEurex = 'CONNECTOR_EUREX',
  ConnectorIbmmq = 'CONNECTOR_IBMMQ',
  Unknown = 'UNKNOWN'
}

export type GQLImageVersion = {
  __typename?: 'ImageVersion';
  type: GQLImageType;
  version: Scalars['String'];
};

export type GQLImapConnector = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'ImapConnector';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLAggregator>;
  attachmentContentType?: Maybe<Scalars['String']>;
  attachmentFilename?: Maybe<Scalars['String']>;
  bodyContentType?: Maybe<Scalars['String']>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  doneDirectory?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  errorDirectory?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  maxHeaderSize?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  processAttachments?: Maybe<Scalars['Boolean']>;
  processBody?: Maybe<Scalars['Boolean']>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  useSsl?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundAddSystemAddressCriteria = {
  contextOrgId: Scalars['String'];
  type: GQLType;
};

export type GQLInboundAggregator = {
  recordIds?: Maybe<Array<Maybe<GQLInboundRecordIdGraphType>>>;
};

export type GQLInboundAmqpConnectorBothDirection = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  session?: Maybe<GQLInboundSession>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  tlsSecret?: Maybe<GQLInboundTlsSecret>;
};

export type GQLInboundAuditCriteria = {
  id?: Maybe<Scalars['String']>;
  sourceVersion?: Maybe<Scalars['String']>;
  sourceVersionDb?: Maybe<Scalars['String']>;
  targetVersion?: Maybe<Scalars['String']>;
  targetVersionDb?: Maybe<Scalars['String']>;
};

export type GQLInboundBucketPrimaryKey = {
  direction: GQLDirectionType;
  messageId: Scalars['String'];
  orgId: Scalars['String'];
  transmissionType: GQLTransmissionType;
};

export type GQLInboundCaasQueryCriteria = {
  contextOrgId?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLConnectorDirectionType>;
  id?: Maybe<Scalars['String']>;
  isEmptyTemplate?: Maybe<Scalars['Boolean']>;
  protocolType?: Maybe<GQLProtocolType>;
};

export type GQLInboundCaasSystemQueryCriteria = {
  id?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
};

export type GQLInboundCertificate = {
  /** The file should be serialized as base64 string */
  certificateContent?: Maybe<Scalars['Base64File']>;
  certificateFileName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type GQLInboundCertificateChain = {
  caCertificateAlias: Scalars['String'];
  serverCertificateAlias: Scalars['String'];
};

export type GQLInboundChannel = {
  password?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  useSasl?: Maybe<Scalars['Boolean']>;
  useTls?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundChatData = {
  contentRecordId?: Maybe<Scalars['ID']>;
  contentTypeId?: Maybe<Scalars['ID']>;
};

export type GQLInboundChatRoom = {
  identities: Array<GQLInputIdentity>;
  subjectChatData: GQLSubjectChatData;
};

export type GQLInboundConnector = {
  pollInterval?: Maybe<Scalars['Int']>;
  splitter?: Maybe<GQLSplitter>;
};

export type GQLInboundDataFilter = {
  delimiter?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<GQLInboundDataFilterField>>>;
  keyValueSplitter?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GQLInboundDataFilterField = {
  condition?: Maybe<GQLFieldCondition>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
};

export type GQLInboundDbConnector = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  batchOutput?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<GQLInboundChannel>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  direction: GQLConnectorDirectionType;
  flowId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  insertSql?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  outputOnEveryPoll?: Maybe<Scalars['Boolean']>;
  pollInterval?: Maybe<Scalars['Int']>;
  prefetchCount?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  selectSql?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  updateBatchSize?: Maybe<Scalars['Int']>;
  updateSql?: Maybe<Scalars['String']>;
};

export type GQLInboundDbConsumer = {
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  fields: GQLInboundDbConsumerFields;
  id?: Maybe<Scalars['String']>;
};

export type GQLInboundDbConsumerFields = {
  batchOutput?: Maybe<Scalars['Boolean']>;
  headerCount?: Maybe<Scalars['Boolean']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  outputOnEveryPoll?: Maybe<Scalars['Boolean']>;
  pollInterval?: Maybe<Scalars['Int']>;
  prefetchCount?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  selectSql?: Maybe<Scalars['String']>;
  updateBatchSize?: Maybe<Scalars['Int']>;
  updateSql?: Maybe<Scalars['String']>;
};

export type GQLInboundDbDetails = {
  dbQueueName?: Maybe<Scalars['String']>;
};

export type GQLInboundDbPublisher = {
  channel: GQLInboundChannel;
  fields: GQLInboundDbPublisherFields;
  id?: Maybe<Scalars['String']>;
};

export type GQLInboundDbPublisherFields = {
  active?: Maybe<Scalars['Boolean']>;
  insertSql?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  reconnectInterval?: Maybe<Scalars['Int']>;
};

export type GQLInboundElasticAction = {
  actionType: GQLElasticActionType;
  fileLogPath: Scalars['String'];
  fromDate: Scalars['DateTime'];
  messageStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType?: Maybe<GQLFixMessageTypeEnum>;
  orgId: Scalars['String'];
  prefixId?: Maybe<Scalars['String']>;
  protocolType: GQLElasticProtocolType;
  queueName: Scalars['String'];
  receiver?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  templateName: Scalars['String'];
  toDate: Scalars['DateTime'];
  userName: Scalars['String'];
};

export type GQLInboundElasticDetails = {
  connectorId?: Maybe<Scalars['String']>;
  jsonPath?: Maybe<Scalars['String']>;
  legacyParameters?: Maybe<GQLInboundLegacyParameters>;
  messageType?: Maybe<GQLFixMessageTypeEnum>;
  protocolType: GQLProtocolType;
  queueName?: Maybe<Scalars['String']>;
};

export type GQLInboundEmailAddressCriteriaMapping = {
  destinationId?: Maybe<Scalars['String']>;
  dispatchType?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type GQLInboundEmailAddressMapping = {
  destinationId?: Maybe<Scalars['String']>;
  dispatchType: Scalars['String'];
  emails: Array<Maybe<Scalars['String']>>;
  enable?: Maybe<Scalars['Boolean']>;
  orgId: Scalars['String'];
  timeZone: Scalars['String'];
};

export type GQLInboundEmailDestinationTemplate = {
  destinationId?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  subjectTemplate?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};

export type GQLInboundEmailTemplateGraphType = {
  id?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};

export type GQLInboundEmailTemplateTest = {
  expression?: Maybe<Scalars['String']>;
  jsonData?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['Base64File']>;
};

export type GQLInboundFieldCriteria = {
  left: GQLInboundFieldExpression;
  right?: Maybe<GQLInboundFieldExpression>;
};

export type GQLInboundFieldExpression = {
  condition: GQLConditionType;
  value?: Maybe<Scalars['String']>;
};

export type GQLInboundFileConnector = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  defaultMessageType?: Maybe<Scalars['String']>;
  doneDirectory?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  errorDirectory?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ignoreHiddenFiles?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  splitter?: Maybe<GQLInboundSplitter>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundFileUpload = {
  customFormat?: Maybe<GQLCustomMappingFormat>;
  delimiter?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLTemplateDirectionType>;
  /** The file should be serialized as base64 string */
  file?: Maybe<Scalars['Base64File']>;
  fileName?: Maybe<Scalars['String']>;
  format?: Maybe<GQLInboundSourceType>;
  genericFormat?: Maybe<GQLGenericMappingFormat>;
  headerRowCount?: Maybe<Scalars['Int']>;
  jsonPath?: Maybe<Scalars['String']>;
  mappingType?: Maybe<GQLMappingDataType>;
  messageId: Scalars['String'];
  minColumnCount?: Maybe<Scalars['Int']>;
  minDataCount?: Maybe<Scalars['Int']>;
  noMapping?: Maybe<GQLNoneMappingFormat>;
  outboundFormat?: Maybe<GQLOutboundSourceType>;
  sheetNumber?: Maybe<Scalars['Int']>;
  skinnyUpdate?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundFixConnectorBoth = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkCompID?: Maybe<Scalars['Boolean']>;
  checkLatency?: Maybe<Scalars['Boolean']>;
  clientIp?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  enableDynamicAcceptorSessions?: Maybe<Scalars['Boolean']>;
  endDay?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  extractFixN?: Maybe<Scalars['Boolean']>;
  flowId?: Maybe<Scalars['String']>;
  generateFixN?: Maybe<Scalars['Boolean']>;
  handleMsgSeqNumTooLow?: Maybe<Scalars['Boolean']>;
  header?: Maybe<GQLInboundFixHeader>;
  heartBeatInterval?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logoutOnMissingMessages?: Maybe<Scalars['Boolean']>;
  maxLatency?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  missingMessagesTimeout?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nonFixPayload?: Maybe<Scalars['Boolean']>;
  partition?: Maybe<GQLInboundPartition>;
  persistMessages?: Maybe<Scalars['Boolean']>;
  port?: Maybe<Scalars['Int']>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  refreshOnLogon?: Maybe<Scalars['Boolean']>;
  rejectInvalidMessage?: Maybe<Scalars['Boolean']>;
  resendRequestChunkSize?: Maybe<Scalars['Int']>;
  resetOnLogon?: Maybe<Scalars['Boolean']>;
  resetOnLogout?: Maybe<Scalars['Boolean']>;
  senderComp: Scalars['String'];
  sendResetSeqNumFlag?: Maybe<Scalars['Boolean']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  socketHost?: Maybe<Scalars['String']>;
  socketInsecureSkipVerify?: Maybe<Scalars['Boolean']>;
  socketPort?: Maybe<Scalars['Int']>;
  splitter?: Maybe<GQLInboundSplitter>;
  sqlStoreDataSourceName?: Maybe<Scalars['String']>;
  ssl?: Maybe<Scalars['Boolean']>;
  startDay?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  targetComp: Scalars['String'];
  timeStampPrecision?: Maybe<GQLFixTimeStampPrecision>;
  timeZone?: Maybe<Scalars['String']>;
  tlsSecret?: Maybe<GQLInboundTlsSecret>;
  type: GQLFixConnectionType;
  useAsyncPublisher?: Maybe<Scalars['Boolean']>;
  validateFieldsHaveValues?: Maybe<Scalars['Boolean']>;
  validateFieldsOutOfOrder?: Maybe<Scalars['Boolean']>;
  validateLengthAndChecksum?: Maybe<Scalars['Boolean']>;
  validateUserDefinedFields?: Maybe<Scalars['Boolean']>;
  version: GQLFixVersions;
};

export type GQLInboundFixHeader = {
  deliverToCompID?: Maybe<Scalars['String']>;
  deliverToLocationID?: Maybe<Scalars['String']>;
  deliverToSubID?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['Json']>;
  lastMsgSeqNumProcessed?: Maybe<Scalars['Int']>;
  messageEncoding?: Maybe<Scalars['String']>;
  noHops?: Maybe<Array<Maybe<GQLInboundFixNoHop>>>;
  onBehalfOfCompID?: Maybe<Scalars['String']>;
  onBehalfOfLocationID?: Maybe<Scalars['String']>;
  onBehalfOfSubID?: Maybe<Scalars['String']>;
  origSendingTime?: Maybe<Scalars['DateTime']>;
  possDupFlag?: Maybe<Scalars['Boolean']>;
  possResend?: Maybe<Scalars['Boolean']>;
  secureData?: Maybe<Scalars['String']>;
  secureDataLen?: Maybe<Scalars['Int']>;
  senderLocationID?: Maybe<Scalars['String']>;
  senderSubID?: Maybe<Scalars['String']>;
  targetLocationID?: Maybe<Scalars['String']>;
  targetSubID?: Maybe<Scalars['String']>;
};

export type GQLInboundFixNoHop = {
  hopCompID: Scalars['String'];
  hopRefID?: Maybe<Scalars['Int']>;
  hopSendingTime?: Maybe<Scalars['DateTime']>;
};

export type GQLInboundFixSequence = {
  incomingSequenceNumber: Scalars['Int'];
  outgoingSequenceNumber: Scalars['Int'];
};

export type GQLInboundHeaderParams = {
  headerType: GQLHeaderType;
  messageId?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
};

export type GQLInboundHostedKafkaConnectorIn = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channelBufferSize?: Maybe<Scalars['Int']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  groupSize?: Maybe<Scalars['Int']>;
  heartbeatInterval?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  maxProcessingTime?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  rebalanceTimeout?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  sessionTimeout?: Maybe<Scalars['Int']>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  topic: Scalars['String'];
};

export type GQLInboundHostedKafkaConnectorOut = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  flushFrequency?: Maybe<Scalars['Int']>;
  flushMessages?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  retryBackoff?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  topic: Scalars['String'];
};

export type GQLInboundHttpConnectorClient = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The file should be serialized as base64 string */
  cAFile?: Maybe<GQLInboundCertificate>;
  /** The file should be serialized as base64 string */
  certificateFile?: Maybe<GQLInboundCertificate>;
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLConnectorDirectionType>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  fromDate?: Maybe<GQLInboundHttpPersistentFields>;
  generateJwt?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  lastPollTime?: Maybe<GQLInboundHttpPersistentFields>;
  maxPages?: Maybe<Scalars['Int']>;
  maxRecords?: Maybe<Scalars['Int']>;
  maxRequestSize?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  minReplySize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  oauthClientId?: Maybe<Scalars['String']>;
  oauthClientSecret?: Maybe<Scalars['String']>;
  oauthGrantType?: Maybe<Scalars['String']>;
  oauthScope?: Maybe<Scalars['String']>;
  oauthType?: Maybe<GQLOauthType>;
  oauthUrl?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  /** The file should be serialized as base64 string */
  privateKeyFile?: Maybe<GQLInboundCertificate>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  requestBody?: Maybe<Scalars['String']>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  timeout?: Maybe<Scalars['Int']>;
  tlsSecret?: Maybe<GQLInboundTlsSecret>;
  toDate?: Maybe<GQLInboundHttpPersistentFields>;
  useAFDefaultServerKeys?: Maybe<Scalars['Boolean']>;
  useBasicAuth?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundHttpConnectorServer = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The file should be serialized as base64 string */
  cAFile?: Maybe<GQLInboundCertificate>;
  /** The file should be serialized as base64 string */
  certificateFile?: Maybe<GQLInboundCertificate>;
  clientIp: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLConnectorDirectionType>;
  flowId?: Maybe<Scalars['String']>;
  generateJwt?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  maxPages?: Maybe<Scalars['Int']>;
  maxRecords?: Maybe<Scalars['Int']>;
  maxRequestSize?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  minReplySize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  oauthClientId?: Maybe<Scalars['String']>;
  oauthClientSecret?: Maybe<Scalars['String']>;
  oauthGrantType?: Maybe<Scalars['String']>;
  oauthScope?: Maybe<Scalars['String']>;
  oauthUrl?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  /** The file should be serialized as base64 string */
  privateKeyFile?: Maybe<GQLInboundCertificate>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  requestBody?: Maybe<Scalars['String']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  timeout?: Maybe<Scalars['Int']>;
  tlsSecret?: Maybe<GQLInboundTlsSecret>;
  useAFDefaultServerKeys?: Maybe<Scalars['Boolean']>;
  useBasicAuth?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundHttpConsumer = {
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isServer?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundHttpPersistentFields = {
  days?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
};

export type GQLInboundIbmMqConnectorIn = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLInboundAggregator>;
  channel?: Maybe<GQLInboundChannel>;
  connectionType?: Maybe<GQLIbmmqConnectionType>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  highVolume?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  maxMessageLength?: Maybe<Scalars['Int']>;
  messageFormat?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  queue?: Maybe<Array<Maybe<GQLInboundIbmQueueGraphType>>>;
  secret?: Maybe<GQLInboundSecret>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundIbmMqConnectorOut = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channel?: Maybe<GQLInboundChannel>;
  connectionType?: Maybe<GQLIbmmqConnectionType>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  messageFormat?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  queue?: Maybe<Array<Maybe<GQLInboundIbmQueueGraphType>>>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundIbmQueueGraphType = {
  name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundIbmQueuesRequest = {
  connectionType: GQLIbmmqConnectionType;
  direction: GQLConnectorDirectionType;
  orgId: Scalars['String'];
};

export type GQLInboundImageVersion = {
  type: GQLImageType;
  version: Scalars['String'];
};

export type GQLInboundImapConnector = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLInboundAggregator>;
  attachmentContentType?: Maybe<Scalars['String']>;
  attachmentFilename?: Maybe<Scalars['String']>;
  bodyContentType?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  doneDirectory?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  errorDirectory?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  maxHeaderSize?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  processAttachments?: Maybe<Scalars['Boolean']>;
  processBody?: Maybe<Scalars['Boolean']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  useSsl?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundKafkaConnectorIn = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channel: GQLInboundChannel;
  channelBufferSize?: Maybe<Scalars['Int']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  groupSize?: Maybe<Scalars['Int']>;
  heartbeatInterval?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  maxProcessingTime?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  rebalanceTimeout?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  sessionTimeout?: Maybe<Scalars['Int']>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  topic: Scalars['String'];
};

export type GQLInboundKafkaConsumer = {
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  fields: GQLInboundKafkaConsumerFields;
  id?: Maybe<Scalars['String']>;
};

export type GQLInboundKafkaConsumerFields = {
  active?: Maybe<Scalars['Boolean']>;
  channelBufferSize?: Maybe<Scalars['Int']>;
  groupSize?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
};

export type GQLInboundKafkaPublisher = {
  channel: GQLInboundChannel;
  fields: GQLInboundKafkaPublisherFields;
  id?: Maybe<Scalars['String']>;
};

export type GQLInboundKafkaPublisherFields = {
  active?: Maybe<Scalars['Boolean']>;
  flushFrequency?: Maybe<Scalars['Int']>;
  flushMessages?: Maybe<Scalars['Int']>;
  retryBackoff?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
};

export type GQLInboundKeyStore = {
  certificateChain?: Maybe<GQLInboundCertificateChain>;
  /** The file should be serialized as base64 string */
  javaKeyStore?: Maybe<GQLInboundCertificate>;
  /** The file should be serialized as base64 string */
  p12KeyStore?: Maybe<GQLInboundCertificate>;
};

export type GQLInboundKubernetesConfiguration = {
  cpuLimit?: Maybe<Scalars['String']>;
  cpuRequests?: Maybe<Scalars['String']>;
  memoryLimit?: Maybe<Scalars['String']>;
  memoryRequests?: Maybe<Scalars['String']>;
  nodeSelector?: Maybe<Scalars['String']>;
};

export type GQLInboundLegacyParameters = {
  fileLogPath?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type GQLInboundMessageBulkDetailsCriteria = {
  id: Scalars['String'];
  latestRecordId?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundMessageBulkOrgId = {
  orgId: Scalars['String'];
};

export type GQLInboundMessageBulkUpload = {
  dbDetails?: Maybe<GQLInboundDbDetails>;
  direction: GQLDirectionType;
  elasticDetails?: Maybe<GQLInboundElasticDetails>;
  fileContent: Scalars['Base64File'];
  fileName: Scalars['String'];
  fromDate: Scalars['DateTime'];
  orgId: Scalars['String'];
  sourceType: GQLSourceType;
  toDate: Scalars['DateTime'];
};

export type GQLInboundMessageProperty = {
  dataFilterFields?: Maybe<Array<Maybe<GQLInboundDataFilter>>>;
  fields?: Maybe<Scalars['Json']>;
};

export type GQLInboundMetaByCriteria = {
  action?: Maybe<GQLActionType>;
  criteria: GQLInboundMetaMessage;
};

export type GQLInboundMetaByDetail = {
  action?: Maybe<GQLActionType>;
  metaFields?: Maybe<Array<Maybe<GQLInboundMetaDetail>>>;
};

export type GQLInboundMetaDetail = {
  direction: GQLDirectionType;
  messageId: Scalars['String'];
  orgId: Scalars['String'];
  receiveTime: Scalars['DateTime'];
  sequenceNumber: Scalars['Long'];
  transmissionType: GQLTransmissionType;
};

export type GQLInboundMetaMessage = {
  ackStatus?: Maybe<Scalars['Boolean']>;
  connectivityId?: Maybe<Scalars['String']>;
  connectorName?: Maybe<Scalars['String']>;
  direction: GQLDirectionType;
  exchangeName?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  fromCreationDate?: Maybe<Scalars['DateTime']>;
  fromDate: Scalars['DateTime'];
  latestRecordId?: Maybe<Scalars['Boolean']>;
  messageId?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  queueName?: Maybe<Scalars['String']>;
  recordIdCriteria?: Maybe<GQLInboundFieldCriteria>;
  replay?: Maybe<GQLInboundFieldCriteria>;
  resend?: Maybe<GQLInboundFieldCriteria>;
  sender?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['String']>;
  systemAddressId?: Maybe<Scalars['String']>;
  toCreationDate?: Maybe<Scalars['DateTime']>;
  toDate: Scalars['DateTime'];
  transmissionType: GQLTransmissionType;
};

export type GQLInboundMetaQuery = {
  criteria: GQLInboundMetaMessage;
  pageNumber?: Maybe<Scalars['Int']>;
};

export type GQLInboundOnBehalfOrg = {
  onBehalfExpression?: Maybe<GQLInboundTemplateExpression>;
  organizations?: Maybe<Array<Maybe<GQLInboundOnBehalfOrganization>>>;
};

export type GQLInboundOnBehalfOrganization = {
  mapping?: Maybe<Array<Maybe<Scalars['String']>>>;
  orgId?: Maybe<Scalars['String']>;
};

export type GQLInboundOrgDataMapping = {
  /** The file should be serialized as base64 string */
  file: Scalars['Base64File'];
};

export type GQLInboundPartition = {
  active?: Maybe<Scalars['Boolean']>;
  groupSize?: Maybe<Scalars['Int']>;
  partitionSize?: Maybe<Scalars['Int']>;
  recordIds?: Maybe<Array<Maybe<GQLInboundRecordIdGraphType>>>;
  recordPayloadField?: Maybe<Scalars['String']>;
};

export type GQLInboundQueryCriteria = {
  contextOrgId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GQLInboundRabbitConsumer = {
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  fields: GQLInboundRabbitConsumerFields;
  id?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
};

export type GQLInboundRabbitConsumerFields = {
  active?: Maybe<Scalars['Boolean']>;
  pollingMode?: Maybe<Scalars['Boolean']>;
  prefetchCount?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  queue?: Maybe<Scalars['String']>;
  txCommitTimeout?: Maybe<Scalars['Int']>;
};

export type GQLInboundRabbitMqConnectorIn = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  fields: GQLInboundRabbitConsumerFields;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundRabbitMqConnectorOut = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channel: GQLInboundChannel;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  fields: GQLInboundRabbitPublisherFields;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundRabbitPublisher = {
  channel: GQLInboundChannel;
  fields: GQLInboundRabbitPublisherFields;
  id?: Maybe<Scalars['String']>;
};

export type GQLInboundRabbitPublisherFields = {
  active?: Maybe<Scalars['Boolean']>;
  exchange?: Maybe<Scalars['String']>;
  exchangeType?: Maybe<Scalars['String']>;
  queue?: Maybe<Scalars['String']>;
  routingKey?: Maybe<Scalars['String']>;
  txCommitTimeout?: Maybe<Scalars['Int']>;
  usePublisherConfirms?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundRecordIdGraphType = {
  name?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundRegressionAction = {
  id: Scalars['String'];
  initializationMode?: Maybe<Scalars['Boolean']>;
  orgId: Scalars['String'];
  testMode?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundRegressionTestQueryModel = {
  connectorName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  executionOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  s3FilePath?: Maybe<Scalars['String']>;
  testName?: Maybe<Scalars['String']>;
};

export type GQLInboundRegressionTestRequestModel = {
  connectorName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  executionOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  s3FilePath?: Maybe<Scalars['String']>;
  testName: Scalars['String'];
};

export type GQLInboundRollback = {
  id: Scalars['String'];
  targetVersion: Scalars['String'];
  targetVersionDb: Scalars['String'];
};

export type GQLInboundSecret = {
  armor?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  /** The file should be serialized as base64 string */
  privateKey?: Maybe<Scalars['Base64File']>;
  privateKeyFileName?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  type?: Maybe<GQLSecretTypeEnum>;
};

export type GQLInboundSeparateTlsFiles = {
  /** The file should be serialized as base64 string */
  cAFile?: Maybe<GQLInboundCertificate>;
  /** The file should be serialized as base64 string */
  certificateFile?: Maybe<GQLInboundCertificate>;
  /** The file should be serialized as base64 string */
  privateKeyFile?: Maybe<GQLInboundCertificate>;
  useAFDefaultServerKeys?: Maybe<Scalars['Boolean']>;
};

export type GQLInboundSession = {
  consumerQueue?: Maybe<Scalars['String']>;
  /** The file should be serialized as base64 string */
  keyStore?: Maybe<GQLInboundCertificate>;
  password?: Maybe<Scalars['String']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  publisherQueue?: Maybe<Scalars['String']>;
  sasl?: Maybe<Scalars['Boolean']>;
  sessionType?: Maybe<GQLSessionType>;
  ssl?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
};

export type GQLInboundSftpConnectorIn = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLInboundAggregator>;
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  defaultMessageType?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  fileNameFilterExpression?: Maybe<Scalars['String']>;
  fileProcessHistory?: Maybe<Scalars['Boolean']>;
  fileUploadTimeout?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  generateSshKeyPair?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  ignoreHiddenFiles?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  marketData?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  messageTypePrefix?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  privateSshKey?: Maybe<GQLInboundCertificate>;
  publicSshKey?: Maybe<Scalars['String']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  sftpNative?: Maybe<Scalars['Boolean']>;
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundSftpConnectorOut = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  batchOutput?: Maybe<Scalars['Boolean']>;
  channel: GQLInboundChannel;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileNameInBatch?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  generateSshKeyPair?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  privateSshKey?: Maybe<GQLInboundCertificate>;
  publicSshKey?: Maybe<Scalars['String']>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  sftpLogFile?: Maybe<Scalars['String']>;
  sftpLogLevel?: Maybe<Scalars['String']>;
  sftpNative?: Maybe<Scalars['Boolean']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundSftpHostedConnectorIn = {
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLInboundAggregator>;
  channel?: Maybe<GQLInboundChannel>;
  clientIp: Scalars['String'];
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  defaultMessageType?: Maybe<Scalars['String']>;
  doneDirectory?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  errorDirectory?: Maybe<Scalars['String']>;
  fileNameFilterExpression?: Maybe<Scalars['String']>;
  fileProcessHistory?: Maybe<Scalars['Boolean']>;
  fileUploadTimeout?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ignoreHiddenFiles?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  legacyAccount?: Maybe<Scalars['Boolean']>;
  marketData?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  messageTypePrefix?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  sftpPublicKey: Scalars['String'];
  splitter?: Maybe<GQLInboundSplitter>;
  storeData?: Maybe<Scalars['Boolean']>;
  subfolderPath?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundSftpHostedConnectorOut = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  batchOutput?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<GQLInboundChannel>;
  clientIp: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileNameInBatch?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  legacyAccount?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  sftpLogFile?: Maybe<Scalars['String']>;
  sftpLogLevel?: Maybe<Scalars['String']>;
  sftpPublicKey: Scalars['String'];
  storeData?: Maybe<Scalars['Boolean']>;
  subfolderPath?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundSmtpConnector = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channel: GQLInboundChannel;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLInboundPartition>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLInboundSecret>;
  smtpFields?: Maybe<GQLInboundSmtpPublisherFields>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
};

export type GQLInboundSmtpPublisherFields = {
  attachmentContentType?: Maybe<Scalars['String']>;
  attachmentFileName?: Maybe<Scalars['String']>;
  bccAddress?: Maybe<Scalars['String']>;
  ccAddress?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  fromAddress?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  textHtml?: Maybe<Scalars['String']>;
  toAddress?: Maybe<Scalars['String']>;
};

export enum GQLInboundSourceType {
  Csv = 'CSV',
  Excel = 'EXCEL',
  Fix = 'FIX',
  Html = 'HTML',
  Json = 'JSON',
  Swift = 'SWIFT',
  Xml = 'XML'
}

export type GQLInboundSplitter = {
  columnDelimiter?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  headerRowCount?: Maybe<Scalars['Int']>;
  includeFullMessage?: Maybe<Scalars['Boolean']>;
  minColumnCount?: Maybe<Scalars['Int']>;
  parameters?: Maybe<Scalars['String']>;
  remove?: Maybe<Scalars['String']>;
  skipHeader?: Maybe<Scalars['Boolean']>;
  suppressOnNoMatch?: Maybe<Scalars['Boolean']>;
  type: GQLSplitterType;
};

export type GQLInboundSystemAddress = {
  cloudStorage?: Maybe<Scalars['Boolean']>;
  connectors: Array<Maybe<GQLInboundSystemAddressConnector>>;
  contextOrgId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameMapping?: Maybe<Scalars['String']>;
  templates: Array<Maybe<GQLInboundSystemAddressTemplate>>;
  type?: Maybe<GQLType>;
};

export type GQLInboundSystemAddressConnector = {
  connectorId: Scalars['String'];
  connectorName: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type GQLInboundSystemAddressCriteria = {
  contextOrgId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<GQLType>;
};

export type GQLInboundSystemAddressTemplate = {
  selected: Scalars['Boolean'];
  template: GQLInboundTemplateDefinition;
};

export type GQLInboundSystemConfiguration = {
  kubernetesConfiguration?: Maybe<GQLInboundKubernetesConfiguration>;
};

export type GQLInboundTemplateDefinition = {
  contextOrgId: Scalars['String'];
  direction?: Maybe<GQLTemplateDirectionType>;
  draft?: Maybe<Scalars['Base64File']>;
  id?: Maybe<Scalars['String']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type GQLInboundTemplateExpression = {
  isCustom?: Maybe<Scalars['Boolean']>;
  jsonConfig?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GQLInboundTemplateField = {
  active?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Maybe<GQLInboundTemplateField>>>;
  custom?: Maybe<Scalars['Boolean']>;
  customValue?: Maybe<Scalars['String']>;
  dateMask?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jsonExpression?: Maybe<Scalars['Base64File']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  transformValue?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GQLInboundTemplateMessage = {
  advanced?: Maybe<Scalars['Boolean']>;
  cData?: Maybe<Scalars['String']>;
  customFormat?: Maybe<GQLCustomMappingFormat>;
  direction?: Maybe<GQLTemplateDirectionType>;
  enrichmentTexts?: Maybe<Array<Maybe<Scalars['String']>>>;
  executionOrder?: Maybe<Scalars['Int']>;
  fields?: Maybe<Array<Maybe<GQLInboundTemplateField>>>;
  id?: Maybe<Scalars['String']>;
  messageGroup?: Maybe<GQLInboundTemplateMessageGroup>;
  meta?: Maybe<Array<Maybe<GQLInboundTemplateMetaMessage>>>;
  name: Scalars['String'];
  onBehalfOrg?: Maybe<GQLInboundOnBehalfOrg>;
  /** The file should be serialized as base64 string */
  originalData?: Maybe<Scalars['Base64File']>;
  pageContext: GQLContext;
  properties?: Maybe<GQLInboundMessageProperty>;
  /** The file should be serialized as base64 string */
  sourceData?: Maybe<Scalars['Base64File']>;
  /** The file should be serialized as base64 string */
  targetData?: Maybe<Scalars['Base64File']>;
  templateId: Scalars['String'];
};

export type GQLInboundTemplateMessageGroup = {
  groupId?: Maybe<GQLInboundTemplateExpression>;
  parentId?: Maybe<GQLInboundTemplateExpression>;
  parentIdIdentifier?: Maybe<GQLInboundTemplateExpression>;
};

export type GQLInboundTemplateMetaMessage = {
  jsonConfig?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type GQLInboundTlsSecret = {
  generateKeys?: Maybe<Scalars['Boolean']>;
  keyStore?: Maybe<GQLInboundKeyStore>;
  removeFiles?: Maybe<Scalars['Boolean']>;
  separateTlsFiles?: Maybe<GQLInboundSeparateTlsFiles>;
};

export type GQLInboundWebConnector = {
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channel: GQLInboundChannel;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parties?: Maybe<Scalars['String']>;
  partition?: Maybe<GQLInboundPartition>;
  pollInterval?: Maybe<Scalars['Int']>;
  protocol?: Maybe<Scalars['String']>;
  splitter?: Maybe<GQLInboundSplitter>;
  sslProxyHost?: Maybe<Scalars['String']>;
  sslProxyPort?: Maybe<Scalars['Int']>;
  systemConfiguration?: Maybe<GQLInboundSystemConfiguration>;
  webHost?: Maybe<Scalars['String']>;
  webPassword?: Maybe<Scalars['String']>;
  webPort?: Maybe<Scalars['Int']>;
  webUserName?: Maybe<Scalars['String']>;
};

export type GQLInExpression = GQLEquationExpressionInterface & GQLExpressionInterface & GQLListEquationExpressionInterface & {
  __typename?: 'InExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right: Array<GQLDataOperandInterface>;
};

export type GQLInputIdentity = {
  id: Scalars['ID'];
  inputIdentityType: GQLIdentityType;
};

export type GQLInstructionAction = GQLActionInterface & {
  __typename?: 'InstructionAction';
  /**
   * action's name
   * @deprecated use ActionName instead
   */
  action?: Maybe<Scalars['String']>;
  actionName: Scalars['String'];
  canHaveAttachment: Scalars['Boolean'];
  contentType?: Maybe<GQLContentType>;
  contentTypeId?: Maybe<Scalars['ID']>;
  friendlyName: Scalars['String'];
  iconName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionActionCategory?: Maybe<GQLInstructionActionCategoryGraphType>;
  instructionActionCategoryId?: Maybe<Scalars['ID']>;
  instructionActionConfiguration: Array<GQLInstructionActionConfiguration>;
  isEntitled: Scalars['Boolean'];
  maxRecords?: Maybe<Scalars['Int']>;
  minRecords: Scalars['Int'];
  organizationIds: Array<Scalars['ID']>;
  organizations: Array<GQLOrganization>;
  orgId?: Maybe<Scalars['ID']>;
  values: Array<Maybe<GQLInstructionActionValue>>;
};


export type GQLInstructionActionInstructionActionConfigurationArgs = {
  orgId: Scalars['ID'];
};


export type GQLInstructionActionIsEntitledArgs = {
  contentRecordCompositeKeys: Array<GQLContentRecordCompositeKeyInput>;
  contextOrgId: Scalars['Int'];
};

export type GQLInstructionActionAuditInterface = {
  actionTime: Scalars['DateTime'];
  actionType: GQLInstructionItemEventActionType;
  contentRecordIds: Array<Maybe<Scalars['ID']>>;
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  instruction?: Maybe<GQLInstructionDetails>;
  instructionId: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type GQLInstructionActionCategoryGraphType = {
  __typename?: 'InstructionActionCategoryGraphType';
  friendlyName: Scalars['String'];
  id: Scalars['ID'];
  instructionActions: Array<GQLInstructionAction>;
  name: Scalars['String'];
};


export type GQLInstructionActionCategoryGraphTypeInstructionActionsArgs = {
  orgId?: Maybe<Scalars['ID']>;
};

export type GQLInstructionActionConfiguration = {
  __typename?: 'InstructionActionConfiguration';
  aggregationOperation?: Maybe<GQLAggregationOperation>;
  field: GQLInstructionActionField;
  fieldKey: Scalars['ID'];
  id: Scalars['ID'];
  instructionAction: GQLInstructionAction;
  instructionActionId: Scalars['ID'];
  instructionAggregationGroup?: Maybe<GQLInstructionAggregationGroup>;
  instructionAggregationGroupId?: Maybe<Scalars['ID']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isNetField?: Maybe<Scalars['Boolean']>;
  /** Mark field as dependent on 'IsRatioField' */
  isRatioDependentField: Scalars['Boolean'];
  /** Only one field can be configured as 'true' per instruction */
  isRatioField: Scalars['Boolean'];
  order: Scalars['UInt'];
};


export type GQLInstructionActionConfigurationInstructionAggregationGroupArgs = {
  contextOrgId?: Maybe<Scalars['ID']>;
};

export type GQLInstructionActionCreatedAudit = GQLInstructionActionAuditInterface & {
  __typename?: 'InstructionActionCreatedAudit';
  actionTime: Scalars['DateTime'];
  actionType: GQLInstructionItemEventActionType;
  comment?: Maybe<Scalars['String']>;
  contentRecordIds: Array<Maybe<Scalars['ID']>>;
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  instruction?: Maybe<GQLInstructionDetails>;
  instructionId: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type GQLInstructionActionEmailAudit = GQLInstructionActionAuditInterface & {
  __typename?: 'InstructionActionEmailAudit';
  actionTime: Scalars['DateTime'];
  actionType: GQLInstructionItemEventActionType;
  contentRecordIds: Array<Maybe<Scalars['ID']>>;
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  emails: Array<GQLInstructionEmail>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  instruction?: Maybe<GQLInstructionDetails>;
  instructionId: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type GQLInstructionActionField = {
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  key: Scalars['ID'];
  name: Scalars['String'];
  type: GQLFieldType;
};

export type GQLInstructionActionStatusAudit = GQLInstructionActionAuditInterface & {
  __typename?: 'InstructionActionStatusAudit';
  actionTime: Scalars['DateTime'];
  actionType: GQLInstructionItemEventActionType;
  comment?: Maybe<Scalars['String']>;
  contentRecordIds: Array<Maybe<Scalars['ID']>>;
  contextOrganization?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  instruction?: Maybe<GQLInstructionDetails>;
  instructionId: Scalars['ID'];
  orgId: Scalars['ID'];
  status: GQLInstructionStatusEnumV3;
};

export type GQLInstructionActionValue = {
  __typename?: 'InstructionActionValue';
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionAction: GQLInstructionAction;
  instructionActionId: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  valueType: GQLInstructionActionValueType;
};

export enum GQLInstructionActionValueType {
  Boolean = 'BOOLEAN',
  Char = 'CHAR',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  String = 'STRING'
}

export type GQLInstructionAggregationGroup = {
  __typename?: 'InstructionAggregationGroup';
  friendlyName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum GQLInstructionAuditEventType {
  EmailReceived = 'EMAIL_RECEIVED',
  InstructionCreated = 'INSTRUCTION_CREATED',
  StatusReceived = 'STATUS_RECEIVED'
}

export type GQLInstructionContentRecordEvent = {
  __typename?: 'InstructionContentRecordEvent';
  actionId: Scalars['ID'];
  comment?: Maybe<GQLComment>;
  eventParameters: Array<Maybe<Scalars['Json']>>;
  eventTime: Scalars['DateTime'];
  eventType: GQLContentRecordEventType;
  instruction?: Maybe<GQLInstructionDetails>;
  instructionAction?: Maybe<GQLInstructionAction>;
  instructionId: Scalars['ID'];
  user?: Maybe<GQLUser>;
  userId: Scalars['ID'];
};

export type GQLInstructionCustomClientFieldGraphType = {
  __typename?: 'InstructionCustomClientFieldGraphType';
  aggregationOperation?: Maybe<GQLAggregationOperation>;
  customClientField: GQLClientField;
  customClientFieldId: Scalars['ID'];
  id: Scalars['ID'];
  instructionAction: GQLInstructionAction;
  instructionActionId: Scalars['ID'];
  isEditable?: Maybe<Scalars['Boolean']>;
  isNetField?: Maybe<Scalars['Boolean']>;
};

export type GQLInstructionDetails = {
  __typename?: 'InstructionDetails';
  actionId: Scalars['ID'];
  actionOwnerOrganization?: Maybe<GQLOrganization>;
  actionOwnerOrgId?: Maybe<Scalars['ID']>;
  actionOwnerStatus?: Maybe<GQLInstructionStatus>;
  additionalInformation?: Maybe<Scalars['String']>;
  aggregatedRisks: GQLAggregatedInstructionRisks;
  cappedEmails: GQLCappedInstructionEmails;
  comment?: Maybe<GQLComment>;
  /** @deprecated Please use ContentRecordsCompositeKeys */
  contentRecordIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contentRecords?: Maybe<Array<Maybe<GQLContentRecord>>>;
  contentRecordsCompositeKeys?: Maybe<Array<Maybe<GQLContentRecordCompositeKey>>>;
  /** @deprecated Please use ContentRecordsCompositeKeys */
  contentType?: Maybe<GQLContentType>;
  contentTypeId?: Maybe<Scalars['ID']>;
  creationTime: Scalars['DateTime'];
  destinationOrganizations?: Maybe<Array<Maybe<GQLOrganization>>>;
  destinationOrgIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** @deprecated Use CappedEmails endpoint instead */
  emails: Array<GQLInstructionEmail>;
  enrichmentFields: Array<GQLEnrichmentField>;
  /** The set of filtered fields sorted by requested keys order. Missing field will be returned as null */
  filterFields: Array<Maybe<GQLBaseField>>;
  id: Scalars['ID'];
  instructionAction: GQLInstructionAction;
  /** @deprecated Please use Parameters */
  instructionParameters: Array<Maybe<Scalars['Json']>>;
  isUserReadEmails: Scalars['Boolean'];
  parameters: Array<Array<Maybe<GQLInstructionParameter>>>;
  statuses: Array<GQLInstructionStatus>;
  systemFields: Array<GQLBaseSystemFieldInterface>;
  /** @deprecated Please use `CreationTime` */
  timestamp: Scalars['DateTime'];
  titleV2: Scalars['String'];
  user?: Maybe<GQLUser>;
  userId: Scalars['ID'];
};


export type GQLInstructionDetailsEnrichmentFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};


export type GQLInstructionDetailsFilterFieldsArgs = {
  keys: Array<Scalars['ID']>;
};


export type GQLInstructionDetailsSystemFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};

export type GQLInstructionEmail = {
  __typename?: 'InstructionEmail';
  attachmentsUrls: Array<Scalars['String']>;
  body: Scalars['String'];
  cc: Array<GQLEmailIdentity>;
  date: Scalars['DateTime'];
  from: GQLEmailIdentity;
  status?: Maybe<GQLEmailStatus>;
  subject: Scalars['String'];
  to: Array<GQLEmailIdentity>;
};

export type GQLInstructionEmailInput = {
  body: Scalars['String'];
};

export type GQLInstructionEmailResponse = {
  __typename?: 'InstructionEmailResponse';
  body: Scalars['String'];
  contextOrgId: Scalars['ID'];
  instruction: GQLInstructionDetails;
  instructionId: Scalars['ID'];
  organization: GQLOrganization;
};

export type GQLInstructionInputGraphType = {
  actionOwnerOrgId?: Maybe<Scalars['ID']>;
  comment?: Maybe<GQLCommentInput>;
  contentRecordIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contentRecordsCompositeKeys?: Maybe<Array<Maybe<GQLContentRecordCompositeKeyInput>>>;
  contentTypeId?: Maybe<Scalars['ID']>;
  destinationOrgIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id?: Maybe<Scalars['ID']>;
  instructionActionId: Scalars['ID'];
  instructionParameters?: Maybe<Array<Maybe<Scalars['Json']>>>;
  parameters?: Maybe<Array<Array<GQLInstructionParameterInput>>>;
};

export enum GQLInstructionItemEventActionType {
  Created = 'CREATED',
  Email = 'EMAIL',
  Status = 'STATUS'
}

export type GQLInstructionParameter = {
  __typename?: 'InstructionParameter';
  field?: Maybe<GQLBaseField>;
  fieldKey: Scalars['ID'];
  value?: Maybe<Scalars['Primitive']>;
};

export type GQLInstructionParameterField = GQLBaseField & GQLInstructionActionField & {
  __typename?: 'InstructionParameterField';
  contentTypeId?: Maybe<Scalars['ID']>;
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<GQLOrganization>;
  organizationId: Scalars['ID'];
  type: GQLFieldType;
  values: Array<GQLInstructionParameterValue>;
};


export type GQLInstructionParameterFieldValuesArgs = {
  actionIds?: Maybe<Array<Scalars['ID']>>;
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
};

export type GQLInstructionParameterFieldOperand = GQLFieldOperandInterface & GQLValueOperandInterface & {
  __typename?: 'InstructionParameterFieldOperand';
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export type GQLInstructionParameterInput = {
  fieldKey: Scalars['ID'];
  value?: Maybe<Scalars['Primitive']>;
};

export type GQLInstructionParameterValue = {
  __typename?: 'InstructionParameterValue';
  actionId?: Maybe<Scalars['ID']>;
  friendlyName: Scalars['String'];
  id: Scalars['ID'];
  orderIndex: Scalars['UInt'];
  value: Scalars['CustomObjectGraphType'];
};

export enum GQLInstructionsSearchFilterTypeInput {
  View = 'VIEW'
}

export type GQLInstructionsSearchQueryParams = {
  filterExpression?: Maybe<Scalars['Json']>;
  filterIds: Array<Scalars['ID']>;
  filterType: GQLInstructionsSearchFilterTypeInput;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLInstructionStatus = {
  __typename?: 'InstructionStatus';
  comment?: Maybe<GQLComment>;
  eta?: Maybe<Scalars['DateTime']>;
  organization: GQLOrganization;
  organizationId: Scalars['ID'];
  receivedTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated please use StatusV3 */
  status: Scalars['String'];
  /** @deprecated please use StatusV3 */
  statusV2: GQLInstructionStatusEnum;
  statusV3: GQLInstructionStatusEnumV3;
  text?: Maybe<Scalars['String']>;
  user?: Maybe<GQLUser>;
  userId?: Maybe<Scalars['ID']>;
};

export enum GQLInstructionStatusEnum {
  Accept = 'ACCEPT',
  Cancel = 'CANCEL',
  Completed = 'COMPLETED',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Reject = 'REJECT',
  UnderReview = 'UNDER_REVIEW'
}

export enum GQLInstructionStatusEnumV3 {
  Accepted = 'Accepted',
  Cancel = 'Cancel',
  Completed = 'Completed',
  OnHold = 'OnHold',
  Pending = 'Pending',
  Rejected = 'Rejected',
  UnderReview = 'UnderReview'
}

export type GQLInstructionStatusFieldOperand = GQLFieldOperandInterface & GQLObjectArrayOperandInterface & GQLValueOperandInterface & {
  __typename?: 'InstructionStatusFieldOperand';
  actionId: Scalars['ID'];
  arrayName?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  instructionAction: GQLInstructionAction;
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export enum GQLInstructionStatusInput {
  Accept = 'ACCEPT',
  Cancel = 'CANCEL',
  Claim = 'CLAIM',
  Completed = 'COMPLETED',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Reject = 'REJECT',
  UnderReview = 'UNDER_REVIEW'
}

export type GQLInstructionSystemField = GQLBaseField & GQLBaseSystemFieldInterface & {
  __typename?: 'InstructionSystemField';
  dereferenceValueType?: Maybe<GQLDereferencedValueType>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  friendlyValue?: Maybe<Scalars['String']>;
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSideBar: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  systemFieldType: GQLSystemFieldType;
  type: GQLFieldType;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  label: Scalars['String'];
};

export type GQLInstructionUniverse = {
  __typename?: 'InstructionUniverse';
  actionsAudits: Array<GQLInstructionActionAuditInterface>;
  actionsAuditsWithComments: Array<GQLInstructionActionAuditInterface>;
  destinationOrganizations: Array<GQLOrganization>;
  instructionActionCategories: Array<GQLInstructionActionCategoryGraphType>;
  instructionActionConfiguration: Array<GQLInstructionActionConfiguration>;
  instructionActions: Array<GQLInstructionAction>;
};


export type GQLInstructionUniverseActionsAuditsArgs = {
  actionType: GQLInstructionAuditEventType;
  contentRecordIds?: Maybe<Array<Scalars['ID']>>;
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  endTime?: Maybe<Scalars['Date']>;
  instructionIds?: Maybe<Array<Scalars['ID']>>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: Maybe<GQLActionAuditOrderBy>;
  startTime?: Maybe<Scalars['Date']>;
  timeFrameId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};


export type GQLInstructionUniverseActionsAuditsWithCommentsArgs = {
  actionType: GQLInstructionAuditEventType;
  contentRecordIds?: Maybe<Array<Scalars['ID']>>;
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  endTime?: Maybe<Scalars['Date']>;
  instructionIds?: Maybe<Array<Scalars['ID']>>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: Maybe<GQLActionAuditOrderBy>;
  startTime?: Maybe<Scalars['Date']>;
  timeFrameId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};


export type GQLInstructionUniverseDestinationOrganizationsArgs = {
  compositeKeys?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  serializedCompositeKeys?: Maybe<Array<Scalars['String']>>;
};


export type GQLInstructionUniverseInstructionActionCategoriesArgs = {
  instructionActionCategoryIds?: Maybe<Array<Scalars['ID']>>;
};


export type GQLInstructionUniverseInstructionActionConfigurationArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  instructionActionId: Scalars['ID'];
};


export type GQLInstructionUniverseInstructionActionsArgs = {
  contentTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  instructionActionCategoryIds?: Maybe<Array<Scalars['ID']>>;
  instructionActionIds?: Maybe<Array<Scalars['ID']>>;
};

export type GQLInstructionViewUniverse = {
  __typename?: 'InstructionViewUniverse';
  fields: Array<GQLBaseField>;
  instructions: GQLCappedInstructions;
  instructionsAggregations: Array<GQLFrequencyFieldSummary>;
  /** returns flat aggregation fields according to the user preferences order if exists */
  instructionsFlatAggregations: Array<GQLFrequencyFieldSummary>;
  preferencedFields: Array<GQLFieldDisplayConfiguration>;
};


export type GQLInstructionViewUniverseFieldsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLInstructionViewUniverseInstructionsArgs = {
  after?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  whereFilterExpression?: Maybe<GQLWhereFilterExpressionInput>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLInstructionViewUniverseInstructionsAggregationsArgs = {
  aggregateBy: Array<GQLAggregationFieldConfigInput>;
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  include: Array<GQLFilterFieldConfigInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  whereFilterExpression?: Maybe<GQLWhereFilterExpressionInput>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLInstructionViewUniverseInstructionsFlatAggregationsArgs = {
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  include?: Maybe<Array<GQLFilterFieldConfigInput>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  whereFilterExpression?: Maybe<GQLWhereFilterExpressionInput>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLInstructionViewUniversePreferencedFieldsArgs = {
  contextOrgId: Scalars['ID'];
};

export type GQLInventory = {
  __typename?: 'Inventory';
  /** Check details for 'InventoryStatus' */
  status: GQLInventoryStatus;
  /**
   * Returns vertical and horizontal information to create a table for aggregated
   * positions data. 'columns' and 'rows.data' indexes are synchronized. 'filter' is optional.
   */
  positionSummary: GQLPositionSummary;
  /** Returns list of string transaction dates. Format is not ISO string because type is Date and not DateTime */
  transactionDates: Array<Scalars['Date']>;
  /**
   * Returns list of trades. This query is made specifically to be used with
   * ag-grid server-side model. Although you can use it without it too. Check
   * https://www.ag-grid.com/graphql-server-side-operations/ and
   * https://github.com/ag-grid/ag-grid-server-side-graphql-example
   */
  trades: Array<GQLTrade>;
  /**
   * Returns list of positions. This query is made specifically to be used with
   * ag-grid server-side model. Although you can use it without it too. Check
   * https://www.ag-grid.com/graphql-server-side-operations/ and
   * https://github.com/ag-grid/ag-grid-server-side-graphql-example
   */
  positions: Array<GQLPositionAggregation>;
};


export type GQLInventoryPositionSummaryArgs = {
  filter?: Maybe<GQLPositionSummaryFilter>;
};


export type GQLInventoryTradesArgs = {
  filter?: Maybe<GQLTradesFilter>;
  startRow?: Maybe<Scalars['Int']>;
  endRow: Scalars['Int'];
  sortModel?: Maybe<Array<GQLSortModel>>;
  rowGroupCols?: Maybe<Array<GQLRowGroup>>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
};


export type GQLInventoryPositionsArgs = {
  filter: GQLPositionsFilter;
};

export type GQLInventoryItem = {
  __typename?: 'InventoryItem';
  firmCode?: Maybe<Scalars['ID']>;
  firmAcct?: Maybe<Scalars['ID']>;
  originalFace?: Maybe<Scalars['Float']>;
  rpb?: Maybe<Scalars['Float']>;
  boxPos?: Maybe<Scalars['Float']>;
  settlePos?: Maybe<Scalars['Float']>;
  restrictTimeStamp?: Maybe<Scalars['Boolean']>;
  restrictRPB?: Maybe<Scalars['Boolean']>;
  restrictOrigFace?: Maybe<Scalars['Boolean']>;
  userName?: Maybe<Scalars['String']>;
  restrictComment?: Maybe<Scalars['Boolean']>;
  wam?: Maybe<Scalars['Float']>;
  walaCage?: Maybe<Scalars['Float']>;
  remainingTerm?: Maybe<Scalars['Float']>;
  origTerm?: Maybe<Scalars['Float']>;
  coupon?: Maybe<Scalars['Float']>;
  fctr?: Maybe<Scalars['Float']>;
  wac?: Maybe<Scalars['Float']>;
  isA?: Maybe<Scalars['Float']>;
  lvldAvgCprm1?: Maybe<Scalars['Float']>;
  lvldAvgCprm3?: Maybe<Scalars['Float']>;
  lvldAvgCprm6?: Maybe<Scalars['Float']>;
  lvldAvgCprm12?: Maybe<Scalars['Float']>;
  lvldAvgCprmLife?: Maybe<Scalars['Float']>;
  loanSize?: Maybe<Scalars['Float']>;
  ltvRatio?: Maybe<Scalars['Float']>;
  servicerName?: Maybe<Scalars['String']>;
  cusip?: Maybe<Scalars['String']>;
  isrN?: Maybe<Scalars['String']>;
  poolNum?: Maybe<Scalars['String']>;
  poolC?: Maybe<Scalars['String']>;
  prdMnem?: Maybe<Scalars['String']>;
  agyC?: Maybe<Scalars['String']>;
  gnmaType?: Maybe<Scalars['String']>;
  eligibilityFlag?: Maybe<Scalars['String']>;
  matD?: Maybe<Scalars['String']>;
  isD?: Maybe<Scalars['String']>;
  maximumLoanSize?: Maybe<Scalars['Float']>;
  delinquencyStatus?: Maybe<Scalars['String']>;
  geographyOfLoans?: Maybe<Scalars['String']>;
};

export type GQLInventorySortModel = {
  propertyName: Scalars['String'];
  direction: GQLOrderDirection;
};

export enum GQLInventoryStatus {
  /** Inventory data has not been requested from 3rd party */
  None = 'NONE',
  /** Inventory data has been requested from 3rd party but our system doesn't have the data yet */
  Requested = 'REQUESTED',
  /** Inventory data is ready to be used */
  Ready = 'READY'
}

export type GQLIsNotNullExpression = GQLExpressionInterface & GQLUnaryEquationExpressionInterface & {
  __typename?: 'IsNotNullExpression';
  isFlat: Scalars['Boolean'];
  value?: Maybe<GQLFieldOperandInterface>;
};

export type GQLIsNullExpression = GQLExpressionInterface & GQLUnaryEquationExpressionInterface & {
  __typename?: 'IsNullExpression';
  isFlat: Scalars['Boolean'];
  value?: Maybe<GQLFieldOperandInterface>;
};

export type GQLJourney = {
  __typename?: 'Journey';
  clientOrganizationId: Scalars['ID'];
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  displayData?: Maybe<Scalars['Json']>;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  expressionParseVersion: Scalars['Int'];
  id: Scalars['ID'];
  journeyTypeId: Scalars['ID'];
  name: Scalars['String'];
  organization: GQLOrganization;
  productLine?: Maybe<GQLProductLine>;
  shouldDispatch: Scalars['Boolean'];
  shouldEvaluate: Scalars['Boolean'];
  sourceCriteria?: Maybe<Scalars['String']>;
  sourceCriteriaVersion: Scalars['Int'];
  timeBasedSourceCriteria?: Maybe<Scalars['String']>;
};

export type GQLJourneyAnalyticsQueryParams = {
  journeyId: Scalars['ID'];
  maxTime: Scalars['DateTime'];
  minTime: Scalars['DateTime'];
};

export type GQLJourneyFactSheet = {
  __typename?: 'JourneyFactSheet';
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  riskSummaries: Array<Maybe<GQLRiskSummaryBucket>>;
  timeFrameRecords: Array<Maybe<GQLTimeFrameRecordsReceived>>;
  totalRecordsReceived?: Maybe<Scalars['Int']>;
  totalRisks?: Maybe<Scalars['Int']>;
  totalRisksDismissed?: Maybe<Scalars['Int']>;
};

export type GQLJourneyGroup = {
  __typename?: 'JourneyGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  orderedJourneys: Array<GQLOrderedJourney>;
  organization: GQLOrganization;
  organizationId: Scalars['ID'];
};

export type GQLJourneyGroupInput = {
  name: Scalars['String'];
  orderedJourneys: Array<GQLOrderedJourneyInput>;
};

export type GQLJourneyGroupUniverse = {
  __typename?: 'JourneyGroupUniverse';
  journeyGroups: Array<GQLJourneyGroup>;
};

export type GQLJourneyInput = {
  contentTypeId: Scalars['ID'];
  definitions?: Maybe<Array<Maybe<GQLDefinitionInput>>>;
  expressionParseVersion: Scalars['Int'];
  id?: Scalars['ID'];
  journeyTypeId: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  productLineId: Scalars['ID'];
  riskProfile?: Maybe<GQLRiskProfileInput>;
  shouldDispatch: Scalars['Boolean'];
  shouldEvaluate: Scalars['Boolean'];
  sourceCriteria?: Maybe<Scalars['Json']>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
  timeBasedSourceCriteria?: Maybe<Scalars['Json']>;
  version: Scalars['Int'];
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
  whereTimeBasedSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLJourneyRecordsFactSheet = {
  __typename?: 'JourneyRecordsFactSheet';
  journeyId: Scalars['ID'];
  timeFrameRecords: Array<Maybe<GQLTimeFrameRecordsReceived>>;
};

export type GQLJourneyRisksSummariesFactSheet = {
  __typename?: 'JourneyRisksSummariesFactSheet';
  journeyId: Scalars['ID'];
  riskProfileItemId?: Maybe<Scalars['ID']>;
  riskSummaries: Array<Maybe<GQLRiskSummaryBucket>>;
  timeFrame?: Maybe<GQLAnalyticsCounterTimeFrame>;
  totalRisksDismissed?: Maybe<Scalars['Int']>;
};

export type GQLJourneyRisksSummariesQueryParams = {
  journeyId: Scalars['ID'];
  maxTime: Scalars['DateTime'];
  minTime: Scalars['DateTime'];
  riskProfileItemId?: Maybe<Scalars['ID']>;
  timeFrame?: Maybe<GQLAnalyticsCounterTimeFrame>;
};

export enum GQLJourneyStatusInput {
  Start = 'START',
  Stop = 'STOP'
}

export type GQLJourneyType = {
  __typename?: 'JourneyType';
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type GQLKafkaConnector = GQLKafkaConnectorIn | GQLKafkaConnectorOut;

export type GQLKafkaConnectorIn = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'KafkaConnectorIn';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  channelBufferSize?: Maybe<Scalars['Int']>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  groupSize?: Maybe<Scalars['Int']>;
  heartbeatInterval?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  maxProcessingTime?: Maybe<Scalars['Int']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  rebalanceTimeout?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  sessionTimeout?: Maybe<Scalars['Int']>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  topic: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLKafkaConnectorOut = GQLBaseConnector & GQLOutboundConnector & {
  __typename?: 'KafkaConnectorOut';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  flushFrequency?: Maybe<Scalars['Int']>;
  flushMessages?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  retryBackoff?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  topic: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLKafkaConsumer = {
  __typename?: 'KafkaConsumer';
  active?: Maybe<Scalars['Boolean']>;
  channelBufferSize?: Maybe<Scalars['Int']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  groupSize?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  messageBroker?: Maybe<Scalars['Boolean']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type GQLKafkaPublisher = {
  __typename?: 'KafkaPublisher';
  active?: Maybe<Scalars['Boolean']>;
  flushFrequency?: Maybe<Scalars['Boolean']>;
  flushMessages?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  messageBroker?: Maybe<Scalars['Boolean']>;
  retryBackoff?: Maybe<Scalars['Int']>;
  retryMax?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type GQLKeyStore = {
  __typename?: 'KeyStore';
  certificateChain?: Maybe<GQLCertificateChainGraph>;
  /** The file should be serialized as base64 string */
  javaKeyStore?: Maybe<GQLCertificate>;
  /** The file should be serialized as base64 string */
  p12KeyStore?: Maybe<GQLCertificate>;
};

export type GQLKubernetesConfiguration = {
  __typename?: 'KubernetesConfiguration';
  cpuLimit?: Maybe<Scalars['String']>;
  cpuRequests?: Maybe<Scalars['String']>;
  memoryLimit?: Maybe<Scalars['String']>;
  memoryRequests?: Maybe<Scalars['String']>;
  nodeSelector?: Maybe<Scalars['String']>;
};

export type GQLLastExpression = GQLArrayExpressionInterface & GQLExpressionInterface & {
  __typename?: 'LastExpression';
  arrayType?: Maybe<GQLObjectArrayOperandInterface>;
  expression?: Maybe<GQLExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export type GQLLayoutItemInput = {
  /** View ID */
  id: Scalars['ID'];
  /** The order of the view */
  order: Scalars['Int'];
};

export enum GQLLayoutItemType {
  ContentRecord = 'CONTENT_RECORD',
  Risk = 'RISK'
}

export enum GQLLayoutItemTypeInput {
  ContentRecord = 'CONTENT_RECORD',
  Risk = 'RISK'
}

export type GQLLayoutItemV2Input = {
  /** View ID */
  id: Scalars['ID'];
  layoutItemType: GQLLayoutItemTypeInput;
  /** The order of the view */
  order: Scalars['Int'];
};

export type GQLLayoutRiskSummary = {
  __typename?: 'LayoutRiskSummary';
  isAlerting: Scalars['Boolean'];
  summaryBuckets: Array<Maybe<GQLRiskSummaryBucket>>;
  view?: Maybe<GQLView>;
};

export type GQLLessThanExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'LessThanExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLLessThanOrEqualsExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'LessThanOrEqualsExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLLinkedGroup = {
  __typename?: 'LinkedGroup';
  /** @deprecated please use CommentV2 */
  comment?: Maybe<Scalars['String']>;
  commentV2?: Maybe<GQLComment>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  linkedRecords: Array<Maybe<GQLLinkedRecord>>;
  linkingInvestigationDetails?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  overrideAmount?: Maybe<Scalars['Decimal']>;
  overrideDate?: Maybe<Scalars['Date']>;
  overrideFlag?: Maybe<Scalars['Boolean']>;
  pairingGroupId?: Maybe<Scalars['Int']>;
  pairingGroupName?: Maybe<Scalars['String']>;
  pairingSchemaId?: Maybe<Scalars['Int']>;
  pairingSchemaName?: Maybe<Scalars['String']>;
  pairingStatusFieldId?: Maybe<Scalars['Int']>;
  pairingStatusFieldName?: Maybe<Scalars['String']>;
  pairingStatusFieldValue?: Maybe<Scalars['String']>;
  pairingTime: Scalars['DateTime'];
  reason?: Maybe<GQLLinkedGroupReason>;
  reasonId?: Maybe<Scalars['ID']>;
};

export type GQLLinkedGroupInput = {
  /** please use CommentV2 field */
  comment?: Maybe<Scalars['String']>;
  commentV2?: Maybe<GQLCommentInput>;
  id?: Scalars['ID'];
  linkedRecords: Array<GQLLinkedRecordInput>;
  linkingInvestigationDetails?: Maybe<Scalars['String']>;
  overrideAmount?: Maybe<Scalars['Decimal']>;
  overrideDate?: Maybe<Scalars['Date']>;
  overrideFlag?: Maybe<Scalars['Boolean']>;
  reasonId?: Maybe<Scalars['ID']>;
};

export type GQLLinkedGroupReason = {
  __typename?: 'LinkedGroupReason';
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type GQLLinkedRecord = {
  __typename?: 'LinkedRecord';
  /** Gets the content record entity, will error on permission errors, use V2 to get null on permission errors */
  contentRecord?: Maybe<GQLContentRecord>;
  /** Gets the content record entity, will return null instead of permission errors */
  contentRecordV2?: Maybe<GQLContentRecord>;
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
  recordId: Scalars['ID'];
};

export type GQLLinkedRecordInput = {
  contentTypeId: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  orgId: Scalars['ID'];
  recordId: Scalars['ID'];
};

export type GQLListEquationExpressionInterface = {
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right: Array<GQLDataOperandInterface>;
};


export type GQLLowerAndUpperBoundsExpressionInterface = {
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  lowerBound?: Maybe<GQLDataOperandInterface>;
  upperBound?: Maybe<GQLDataOperandInterface>;
};

export enum GQLMappingDataType {
  CustomMapping = 'CUSTOM_MAPPING',
  GenericMapping = 'GENERIC_MAPPING',
  None = 'NONE'
}

export enum GQLMappingFunction {
  Exists = 'Exists',
  Map = 'Map'
}

export type GQLMapType = {
  __typename?: 'MapType';
  enrichmentFieldIds: Array<Scalars['ID']>;
  enrichmentFields: Array<GQLEnrichmentField>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLMessageBulk = {
  __typename?: 'MessageBulk';
  connectorId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  dbQueueName?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLDirectionType>;
  fileContent?: Maybe<Scalars['Base64File']>;
  fileLogPath?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  jsonPath?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['DateTime']>;
  messageType?: Maybe<GQLFixMessageTypeEnum>;
  orgId?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  queueName?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  sourceType?: Maybe<GQLSourceType>;
  status?: Maybe<GQLStatus>;
  tableName?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
  userName?: Maybe<Scalars['String']>;
};

export type GQLMessageGroupGraph = {
  __typename?: 'MessageGroupGraph';
  groupId?: Maybe<GQLTemplateExpression>;
  parentId?: Maybe<GQLTemplateExpression>;
  parentIdIdentifier?: Maybe<GQLTemplateExpression>;
};

export type GQLMessageProperty = {
  __typename?: 'MessageProperty';
  dataFilterFields?: Maybe<Array<Maybe<GQLDataFilter>>>;
  fields?: Maybe<Scalars['Json']>;
};

export type GQLMessageRecordId = {
  __typename?: 'MessageRecordId';
  name?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type GQLMetadataFieldOperand = GQLFieldOperandInterface & GQLValueOperandInterface & {
  __typename?: 'MetadataFieldOperand';
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export type GQLMetaMessage = {
  __typename?: 'MetaMessage';
  ackStatus?: Maybe<Scalars['Boolean']>;
  connectivityId?: Maybe<Scalars['String']>;
  connectorName?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  direction?: Maybe<GQLDirectionType>;
  exchangeName?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  linkRecordId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  numOfReplay?: Maybe<Scalars['Int']>;
  numOfResend?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['String']>;
  receiveTime?: Maybe<Scalars['DateTime']>;
  recordId?: Maybe<Scalars['String']>;
  s3Link?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  sequenceNumber?: Maybe<Scalars['Long']>;
  system?: Maybe<Scalars['String']>;
  systemAddressId?: Maybe<Scalars['String']>;
  transformerTime?: Maybe<Scalars['DateTime']>;
  transmissionType?: Maybe<GQLTransmissionType>;
};

export type GQLMetaMessagePage = {
  __typename?: 'MetaMessagePage';
  pageNumber?: Maybe<Scalars['Int']>;
  totalElements?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type GQLMetaMessageQuery = {
  __typename?: 'MetaMessageQuery';
  messages?: Maybe<Array<Maybe<GQLMetaMessage>>>;
  page?: Maybe<GQLMetaMessagePage>;
};


export type GQLMultipleValueExpressionMeta = GQLExpressionMeta & {
  __typename?: 'MultipleValueExpressionMeta';
  id: Scalars['ID'];
  type: GQLExpressionType;
  field?: Maybe<GQLBaseField>;
  fullLabel: Scalars['String'];
  label: Scalars['String'];
  fieldLabel: Scalars['String'];
  operatorLabel: Scalars['String'];
  operatorSymbol: Scalars['String'];
  operator: GQLOperator;
  /** Individual values in a list */
  valuesLabels: Array<Scalars['String']>;
  /** Combined values */
  valueLabel: Scalars['String'];
  values: Array<Scalars['StringOrIntOrFloatOrBoolean']>;
  expression: Scalars['Expression'];
};

export type GQLMutation = {
  __typename?: 'Mutation';
  addContentRecordAttachment: GQLContentRecordAttachment;
  addContentRecordsAttachment: Array<GQLContentRecordAttachment>;
  /** @deprecated Use addLayoutItemsV2 */
  addLayoutItems: Array<GQLUserLayoutItem>;
  addLayoutItemsV2: Array<GQLUserLayoutItem>;
  appendRuleToAutoAssignRuleSet: GQLAutoAssignRuleSet;
  attachAssignRuleSetIdToRiskProfileItems: Array<GQLRiskProfileItem>;
  cancelContentRecord: GQLContentRecordCompositeKey;
  changeInstructionEmailsUserReadState: Array<GQLInstructionDetails>;
  contentRecordInvalidate: Array<Scalars['ID']>;
  contentRecordReevaluate: Array<GQLContentRecordCompositeKey>;
  contentRecordsExport: Scalars['ID'];
  createActivitySubscription: GQLActivitySubscription;
  /** @deprecated Invalid order of events, Use CreateAttachmentUrlV2 instead */
  createAttachmentUrl: Scalars['String'];
  createAttachmentUrlV2: GQLContentRecordAttachmentUrl;
  /** Create attachment URL for multiple content records */
  createAttachmentUrlV3: GQLContentRecordAttachmentUrl;
  createAutoAssignRuleSet: GQLAutoAssignRuleSet;
  createChatRoom?: Maybe<GQLRoomInfo>;
  createClient: GQLClient;
  /** @deprecated use CreateExtendedClientFields instead */
  createClientFields: Array<GQLClientField>;
  createContentRecord: GQLContentRecordCompositeKey;
  createContentRecordsComment?: Maybe<Scalars['Boolean']>;
  createEnrichmentActivity: GQLEnrichmentActivity;
  createEnrichmentActivityRuleDefinition: GQLEnrichmentRuleDefinitions;
  createEnrichmentField: GQLEnrichmentField;
  /**
   * Create an entitlements field schema, that will limit visible fields on
   * collaboration. The schema can later be set on
   * organizationEntitlement.entitlementFieldsSchemaId.
   */
  createEntitlementFieldsSchema: GQLEntitlementFieldsSchema;
  createEntitlementRule: GQLEntitlementRule;
  createExtendedClientFields: Array<GQLExtendedClientField>;
  createInstruction?: Maybe<GQLInstructionDetails>;
  createInstructions: Array<GQLInstructionDetails>;
  createJourney?: Maybe<GQLEnrichedJourney>;
  createJourneyGroup?: Maybe<GQLJourneyGroup>;
  createLinkedGroup: GQLLinkedGroup;
  createNotificationSubscriptions: Array<GQLNotificationSubscription>;
  createOrganization: GQLOrganization;
  createOrganizationEntitlement: GQLOrganizationEntitlement;
  createPairingGroup: GQLPairingGroup;
  createPairingStatusField: GQLPairingStatusField;
  createQuickFilter: GQLQuickFilter;
  createRiskResolution: GQLRiskResolution;
  createRule: GQLRuleInterface;
  createSourceSystem?: Maybe<GQLSourceSystem>;
  createTag: GQLTag;
  createTemporaryExpression: GQLTemporaryExpression;
  createUser: GQLUser;
  createUserEntitlement: GQLUserEntitlement;
  createUsersGroup: GQLUsersGroup;
  createUsersGroupEntitlement: GQLUsersGroupEntitlement;
  createVendor: GQLVendor;
  createView: GQLView;
  deleteActivitySubscription: Scalars['ID'];
  deleteAutoAssignRule: GQLAutoAssignRule;
  deleteChatRoom?: Maybe<GQLRoomInfo>;
  deleteClientData: Scalars['ID'];
  deleteEnrichmentActivity: GQLEnrichmentActivity;
  deleteEnrichmentActivityRuleDefinition: GQLEnrichmentRuleDefinitions;
  deleteEntitlement: GQLEntitlement;
  /**
   * Delete entitlement fields schemas. To apply the deletion there should be no
   * entitlement referring this schema. Generic schema can only be deleted by global admins.
   */
  deleteEntitlementFieldsSchemas: Array<GQLEntitlementFieldsSchema>;
  deleteEntitlementRule: GQLEntitlementRule;
  deleteJourney?: Maybe<GQLEnrichedJourney>;
  deleteJourneyGroup?: Maybe<GQLJourneyGroup>;
  deleteNotificationSubscriptions: Array<GQLNotificationSubscription>;
  deleteOrganization: Scalars['ID'];
  deleteOrganizationData: Scalars['ID'];
  deletePairingGroup: GQLPairingGroup;
  deletePairingStatusField: GQLPairingStatusField;
  deleteQuickFilter: GQLQuickFilter;
  deleteRule: GQLRuleInterface;
  deleteSourceSystem?: Maybe<GQLSourceSystem>;
  deleteTag: GQLTag;
  deleteUser: GQLUser;
  deleteUserPreference: GQLUserPreference;
  deleteUsersGroup: GQLUsersGroup;
  deleteView: GQLView;
  instructionsExport: Scalars['ID'];
  pairingManualRecalculation: Array<GQLContentRecordCompositeKey>;
  performActionOnRisks?: Maybe<Array<Maybe<GQLRiskActionResult>>>;
  performActionOnRisksByQuery?: Maybe<Array<Maybe<GQLRiskActionResult>>>;
  /** @deprecated Use removeLayoutItemsV2 */
  removeLayoutItems: Array<GQLUserLayoutItem>;
  removeLayoutItemsV2: Array<GQLUserLayoutItem>;
  risksExport: Scalars['ID'];
  sendInstructionEmail: GQLInstructionEmailResponse;
  sendInstructionsStatus: Array<Scalars['ID']>;
  /** @deprecated Please use SendInstructionStatusV2 */
  sendInstructionStatus: Scalars['ID'];
  sendInstructionStatusV2: Scalars['ID'];
  setDismissNotification: Array<GQLSetDismissNotification>;
  setReadNotification: Array<GQLSetReadNotification>;
  toggleJourney: Scalars['Boolean'];
  updateActivitySubscription: GQLActivitySubscription;
  updateAutoAssignRule: GQLAutoAssignRule;
  updateAutoAssignRulesPriorities: GQLAutoAssignRuleSet;
  updateChatRoom?: Maybe<GQLRoomInfo>;
  updateClient: GQLClient;
  /** @deprecated use UpdateExtendedClientFields instead */
  updateClientFields: Array<GQLClientField>;
  updateContentRecord: GQLContentRecordCompositeKey;
  updateEnrichmentActivity: GQLEnrichmentActivity;
  updateEnrichmentActivityRuleDefinition: GQLEnrichmentRuleDefinitions;
  /**
   * Update an entitlement fields schema. This will affect every linked
   * organization entitlement, and will allow exposure of the fields in the schema
   * for linked entitlements. Generic fields schema can only be modified by global admins.
   */
  updateEntitlementFieldsSchema: GQLEntitlementFieldsSchema;
  updateEntitlementRule: GQLEntitlementRule;
  updateExtendedClientFields: Array<GQLExtendedClientField>;
  updateJourney?: Maybe<GQLEnrichedJourney>;
  updateJourneyGroup?: Maybe<GQLJourneyGroup>;
  updateNotificationSettings: Array<GQLNotificationSettingsEventData>;
  updateOrganizationEntitlement: GQLOrganizationEntitlement;
  updatePairingGroup: GQLPairingGroup;
  updatePairingStatusField: GQLPairingStatusField;
  updateQuickFilter: GQLQuickFilter;
  updateRule: GQLRuleInterface;
  updateSourceSystem?: Maybe<GQLSourceSystem>;
  updateUser: GQLUser;
  updateUserEntitlement: GQLUserEntitlement;
  /** @deprecated Use updateUserLayoutV2 */
  updateUserLayout: Array<GQLUserLayoutItem>;
  updateUserLayoutV2: Array<GQLUserLayoutItem>;
  updateUsersGroup: GQLUsersGroup;
  updateUsersGroupEntitlement: GQLUsersGroupEntitlement;
  updateVendor: GQLVendor;
  /** @deprecated Use UpdateViewV2 instead */
  updateView: GQLView;
  updateViewDisplayConfiguration: GQLViewDisplayConfiguration;
  updateViewV2: GQLView;
  /** Creating or updating all entities given as input and deleting all other entities that are related to the rule id */
  upsertEntitlementRuleReferringEntities: GQLEntitlementRule;
  upsertUserPreference: GQLUserPreference;
  busRecover?: Maybe<Scalars['Boolean']>;
  deleteConnectorById?: Maybe<Scalars['Boolean']>;
  deleteConsumer?: Maybe<Scalars['Boolean']>;
  deleteDataMappingByIds?: Maybe<Scalars['Boolean']>;
  deleteEmailDestinationAddress?: Maybe<Scalars['Boolean']>;
  deleteEmailDestinationTemplate?: Maybe<Scalars['Boolean']>;
  deleteEmailTemplate?: Maybe<Scalars['Boolean']>;
  deleteFileConnector?: Maybe<Scalars['Boolean']>;
  deleteFixConnector?: Maybe<Scalars['Boolean']>;
  deleteHttpConnector?: Maybe<Scalars['Boolean']>;
  deleteIbmMqConnector?: Maybe<Scalars['Boolean']>;
  deleteImageVersion?: Maybe<Scalars['Boolean']>;
  deleteImapConnector?: Maybe<Scalars['Boolean']>;
  deletePublisher?: Maybe<Scalars['Boolean']>;
  deleteRabbitMqConnector?: Maybe<Scalars['Boolean']>;
  deleteRegressionTest?: Maybe<GQLRegressionTestResponse>;
  deleteSmtpConnector?: Maybe<Scalars['Boolean']>;
  deleteSystemAddress?: Maybe<Scalars['Boolean']>;
  deleteTemplate?: Maybe<Scalars['Boolean']>;
  deleteTemplateMessage?: Maybe<Scalars['Boolean']>;
  deleteWebConnector?: Maybe<Scalars['Boolean']>;
  deployTemplateSchema?: Maybe<Scalars['Boolean']>;
  dockerRecover?: Maybe<Scalars['Boolean']>;
  downloadDataMappingFile?: Maybe<GQLDataMappingFile>;
  elasticMessageActionByCriteria?: Maybe<Scalars['Boolean']>;
  executeRegressionAction?: Maybe<GQLRegressionAction>;
  importTemplateSchema?: Maybe<GQLTemplateDefinition>;
  messageBulkDelete?: Maybe<GQLMessageBulk>;
  messageBulkDownloadCsv?: Maybe<GQLMessageBulk>;
  messageBulkDownloadTemplate?: Maybe<GQLMessageBulk>;
  messageBulkResendReplay?: Maybe<GQLMessageBulk>;
  messageBulkUploadTemplate?: Maybe<GQLMessageBulk>;
  metaMessageActionByCriteria?: Maybe<Scalars['Boolean']>;
  metaMessageActionByDetail?: Maybe<Scalars['Boolean']>;
  refreshConnectorById?: Maybe<Scalars['Boolean']>;
  resetFixSequenceById?: Maybe<Scalars['Boolean']>;
  rollbackTemplateMessage?: Maybe<GQLTemplateMessage>;
  saveAmqpConnectorBothDirection?: Maybe<GQLAmqpConnectorBothDirection>;
  saveConsumer?: Maybe<GQLConsumer>;
  saveDbConnector?: Maybe<GQLDbConnector>;
  saveEmailDestinationAddress?: Maybe<GQLEmailAddressMapping>;
  saveEmailDestinationTemplate?: Maybe<GQLEmailDestinationTemplate>;
  saveEmailTemplate?: Maybe<GQLEmailTemplate>;
  saveFileConnector?: Maybe<GQLFileConnector>;
  saveFixConnector?: Maybe<GQLFixConnector>;
  saveHostedKafkaConnector?: Maybe<GQLKafkaConnector>;
  saveHostedSftpConnector?: Maybe<GQLSftpHostedConnector>;
  saveHttpConnector?: Maybe<GQLHttpConnector>;
  saveIbmMqConnector?: Maybe<GQLIbmMqConnector>;
  saveImageVersion?: Maybe<GQLImageVersion>;
  saveImapConnector?: Maybe<GQLImapConnector>;
  saveKafkaConnector?: Maybe<GQLKafkaConnector>;
  savePublisher?: Maybe<GQLPublisher>;
  saveRabbitMqConnector?: Maybe<GQLRabbitMqConnector>;
  saveRegressionTest?: Maybe<GQLRegressionTestResponse>;
  saveSftpConnector?: Maybe<GQLSftpConnector>;
  saveSmtpConnector?: Maybe<GQLSmtpConnector>;
  saveSystemAddress?: Maybe<GQLSystemAddress>;
  saveTemplate?: Maybe<GQLTemplateDefinition>;
  saveTemplateMessage?: Maybe<GQLTemplateMessage>;
  saveWebConnector?: Maybe<GQLWebConnector>;
  startConnector?: Maybe<GQLConnectorStatus>;
  stopConnector?: Maybe<GQLConnectorStatus>;
  testEmailExpression?: Maybe<Scalars['Boolean']>;
  testEmailTemplate?: Maybe<GQLEmailTemplateTestResponse>;
  testTemplateMessage?: Maybe<GQLTemplateMessage>;
  updateAll?: Maybe<Scalars['Boolean']>;
  updateFixSequenceById?: Maybe<GQLFixSequence>;
  uploadDataMappingFile?: Maybe<Scalars['Boolean']>;
  uploadFileTemplate?: Maybe<GQLTemplateMessage>;
  /**
   * Requests inventory data from 3rd party. This will mark
   * contentRecord.inventory.status as 'REQUESTED'. It makes an inventory
   * instruction behind the scenes. Will throw an error if organisation doesn't
   * have Inventory content type or content record doesn't have ISIN global field
   * and it's client value
   */
  requestInventory?: Maybe<GQLContentRecord>;
  validateAllocation: Array<GQLAmacActionResponse>;
  submitAllocation: GQLAmacActionResponse;
  amendAllocation: GQLAmacActionResponse;
  autoAllocation: GQLAmacActionResponse;
};


export type GQLMutationAddContentRecordAttachmentArgs = {
  comment?: Maybe<Scalars['String']>;
  compositeKey?: Maybe<GQLContentRecordCompositeKeyInput>;
  contextOrgId: Scalars['ID'];
  fileKey: Scalars['String'];
  fileName: Scalars['String'];
  isInternal?: Maybe<Scalars['Boolean']>;
  serializedCompositeKey?: Maybe<Scalars['String']>;
};


export type GQLMutationAddContentRecordsAttachmentArgs = {
  comment?: Maybe<Scalars['String']>;
  compositeKeys?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
  fileKey: Scalars['String'];
  fileName: Scalars['String'];
  isInternal?: Maybe<Scalars['Boolean']>;
  serializedCompositeKeys?: Maybe<Array<Scalars['String']>>;
};


export type GQLMutationAddLayoutItemsArgs = {
  orgId: Scalars['ID'];
  viewIds: Array<Scalars['ID']>;
};


export type GQLMutationAddLayoutItemsV2Args = {
  orgId: Scalars['ID'];
  userLayoutItems: Array<GQLLayoutItemV2Input>;
};


export type GQLMutationAppendRuleToAutoAssignRuleSetArgs = {
  autoAssignRuleInput: GQLAutoAssignRuleInput;
  autoAssignRuleSetId: Scalars['ID'];
};


export type GQLMutationAttachAssignRuleSetIdToRiskProfileItemsArgs = {
  autoAssignRuleSetId: Scalars['ID'];
  riskProfileItemIds: Array<Scalars['ID']>;
};


export type GQLMutationCancelContentRecordArgs = {
  compositeKey: GQLContentRecordCompositeKeyInput;
  contextOrgId: Scalars['ID'];
};


export type GQLMutationChangeInstructionEmailsUserReadStateArgs = {
  contentTypeId: Scalars['ID'];
  contextOrgId: Scalars['ID'];
  emailsReadState: Scalars['Boolean'];
  instructionIds: Array<Scalars['ID']>;
};


export type GQLMutationContentRecordInvalidateArgs = {
  contentRecordIds: Array<Scalars['ID']>;
  contentTypeId: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLMutationContentRecordReevaluateArgs = {
  compositeKeys: Array<GQLContentRecordCompositeKeyInput>;
  contextOrgId?: Maybe<Scalars['ID']>;
};


export type GQLMutationContentRecordsExportArgs = {
  advancedFilter?: Maybe<GQLContentRecordsSearchAdvancedQueryParams>;
  filter: GQLContentRecordsSearchQueryParams;
  format: GQLContentExportFormat;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  orgId: Scalars['ID'];
  source?: Maybe<GQLContentExportSourceInput>;
};


export type GQLMutationCreateActivitySubscriptionArgs = {
  contextOrgId: Scalars['ID'];
  createActivitySubscriptionInput: GQLActivitySubscriptionInput;
};


export type GQLMutationCreateAttachmentUrlArgs = {
  comment?: Maybe<Scalars['String']>;
  compositeKey?: Maybe<GQLContentRecordCompositeKeyInput>;
  contextOrgId: Scalars['ID'];
  fileName: Scalars['String'];
  isInternal?: Maybe<Scalars['Boolean']>;
  serializedCompositeKey?: Maybe<Scalars['String']>;
};


export type GQLMutationCreateAttachmentUrlV2Args = {
  comment?: Maybe<Scalars['String']>;
  compositeKey?: Maybe<GQLContentRecordCompositeKeyInput>;
  contextOrgId: Scalars['ID'];
  fileName: Scalars['String'];
  serializedCompositeKey?: Maybe<Scalars['String']>;
};


export type GQLMutationCreateAttachmentUrlV3Args = {
  comment?: Maybe<Scalars['String']>;
  compositeKeys?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  contextOrgId: Scalars['ID'];
  fileName: Scalars['String'];
  serializedCompositeKeys?: Maybe<Array<Scalars['String']>>;
};


export type GQLMutationCreateAutoAssignRuleSetArgs = {
  autoAssignRuleSet: GQLAutoAssignRuleSetInput;
  riskProfileItemIds?: Maybe<Array<Scalars['ID']>>;
};


export type GQLMutationCreateChatRoomArgs = {
  inboundChatRoom: GQLInboundChatRoom;
  orgId: Scalars['ID'];
};


export type GQLMutationCreateClientArgs = {
  clientInput: GQLClientInput;
};


export type GQLMutationCreateClientFieldsArgs = {
  clientFieldsInput: Array<GQLClientFieldInput>;
};


export type GQLMutationCreateContentRecordArgs = {
  contextOrgId: Scalars['ID'];
  createContentRecordInput: GQLCreateContentRecordInput;
};


export type GQLMutationCreateContentRecordsCommentArgs = {
  comment: Scalars['String'];
  compositeKeys?: Maybe<Array<Maybe<GQLContentRecordCompositeKeyInput>>>;
  isInternal?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['ID']>;
  serializedCompositeKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GQLMutationCreateEnrichmentActivityArgs = {
  contextOrgId: Scalars['ID'];
  createActivityInput: GQLEnrichmentActivityInput;
};


export type GQLMutationCreateEnrichmentActivityRuleDefinitionArgs = {
  contextOrgId: Scalars['ID'];
  ruleDefinitions: GQLEnrichmentRuleDefinitionsInput;
};


export type GQLMutationCreateEnrichmentFieldArgs = {
  contextOrgId: Scalars['ID'];
  enrichmentFieldsInput: GQLEnrichmentFieldInput;
};


export type GQLMutationCreateEntitlementFieldsSchemaArgs = {
  entitlementFieldsSchemaInput: GQLEntitlementFieldsSchemaInput;
};


export type GQLMutationCreateEntitlementRuleArgs = {
  inputEntitlementRule: GQLEntitlementRuleInput;
};


export type GQLMutationCreateExtendedClientFieldsArgs = {
  clientFieldsInput: Array<GQLClientFieldInput>;
  contextOrgId: Scalars['ID'];
};


export type GQLMutationCreateInstructionArgs = {
  contextOrgId?: Maybe<Scalars['ID']>;
  instructionInput: GQLInstructionInputGraphType;
  orgId: Scalars['ID'];
};


export type GQLMutationCreateInstructionsArgs = {
  contextOrgId?: Maybe<Scalars['ID']>;
  instructionInput: Array<GQLInstructionInputGraphType>;
  orgId: Scalars['ID'];
};


export type GQLMutationCreateJourneyArgs = {
  journeyInput: GQLJourneyInput;
  orgId: Scalars['ID'];
};


export type GQLMutationCreateJourneyGroupArgs = {
  journeyGroupInput: GQLJourneyGroupInput;
  orgId: Scalars['ID'];
};


export type GQLMutationCreateLinkedGroupArgs = {
  contextOrgId?: Maybe<Scalars['ID']>;
  linkedGroupInput: GQLLinkedGroupInput;
};


export type GQLMutationCreateNotificationSubscriptionsArgs = {
  contextOrgId: Scalars['ID'];
  notificationSubscriptions: Array<GQLNotificationSubscriptionInput>;
};


export type GQLMutationCreateOrganizationArgs = {
  organizationInput: GQLOrganizationInput;
};


export type GQLMutationCreateOrganizationEntitlementArgs = {
  inputEntitlement: GQLOrganizationEntitlementInput;
};


export type GQLMutationCreatePairingGroupArgs = {
  contextOrgId: Scalars['ID'];
  pairingGroupCreateInput: GQLPairingGroupCreateInput;
};


export type GQLMutationCreatePairingStatusFieldArgs = {
  contextOrgId: Scalars['ID'];
  pairingStatusFieldCreateInput: GQLPairingStatusFieldCreateInput;
};


export type GQLMutationCreateQuickFilterArgs = {
  quickFilterInput: GQLQuickFilterInput;
};


export type GQLMutationCreateRiskResolutionArgs = {
  riskResolutionInput: GQLRiskResolutionInput;
};


export type GQLMutationCreateRuleArgs = {
  expression: Scalars['Json'];
  friendlyName?: Maybe<Scalars['String']>;
  orgId: Scalars['ID'];
  ruleType: GQLRuleType;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLMutationCreateSourceSystemArgs = {
  orgId: Scalars['ID'];
  sourceSystem: GQLSourceSystemInput;
};


export type GQLMutationCreateTagArgs = {
  contextOrgId: Scalars['ID'];
  tagInput: GQLTagInput;
};


export type GQLMutationCreateTemporaryExpressionArgs = {
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  timeToLiveInDays: Scalars['Int'];
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLMutationCreateUserArgs = {
  contextOrgId: Scalars['ID'];
  userInput: GQLUserInput;
};


export type GQLMutationCreateUserEntitlementArgs = {
  inputEntitlement: GQLUserEntitlementInput;
};


export type GQLMutationCreateUsersGroupArgs = {
  usersGroupInput: GQLUsersGroupInput;
};


export type GQLMutationCreateUsersGroupEntitlementArgs = {
  inputEntitlement: GQLUsersGroupEntitlementInput;
};


export type GQLMutationCreateVendorArgs = {
  contextOrgId: Scalars['ID'];
  vendorInput: GQLVendorInput;
};


export type GQLMutationCreateViewArgs = {
  copyExpressionFromViewId?: Maybe<Scalars['ID']>;
  copyPreferencesFromViewId?: Maybe<Scalars['ID']>;
  viewInput: GQLViewInput;
};


export type GQLMutationDeleteActivitySubscriptionArgs = {
  contextOrgId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLMutationDeleteAutoAssignRuleArgs = {
  ruleId: Scalars['ID'];
};


export type GQLMutationDeleteChatRoomArgs = {
  id: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteClientDataArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteEnrichmentActivityArgs = {
  activityId: Scalars['ID'];
  contextOrgId: Scalars['ID'];
};


export type GQLMutationDeleteEnrichmentActivityRuleDefinitionArgs = {
  contextOrgId: Scalars['ID'];
  ruleDefinitionId: Scalars['ID'];
  ruleDefinitionOrgId?: Maybe<Scalars['ID']>;
};


export type GQLMutationDeleteEntitlementArgs = {
  entitlementKey: Scalars['ID'];
};


export type GQLMutationDeleteEntitlementFieldsSchemasArgs = {
  entitlementFieldsSchemaIds: Array<Scalars['ID']>;
};


export type GQLMutationDeleteEntitlementRuleArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteJourneyArgs = {
  journeyId: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteJourneyGroupArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteNotificationSubscriptionsArgs = {
  contextOrgId: Scalars['ID'];
  notificationSubscriptionIds: Array<Scalars['ID']>;
};


export type GQLMutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteOrganizationDataArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeletePairingGroupArgs = {
  contextOrgId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLMutationDeletePairingStatusFieldArgs = {
  contextOrgId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLMutationDeleteQuickFilterArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteRuleArgs = {
  ruleId: Scalars['ID'];
};


export type GQLMutationDeleteSourceSystemArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteTagArgs = {
  contextOrgId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLMutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteUserPreferenceArgs = {
  key: Scalars['ID'];
};


export type GQLMutationDeleteUsersGroupArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteViewArgs = {
  id: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLMutationInstructionsExportArgs = {
  contextOrgId: Scalars['ID'];
  filter: GQLInstructionsSearchQueryParams;
  format: GQLContentExportFormat;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  source?: Maybe<GQLContentExportSourceInput>;
};


export type GQLMutationPairingManualRecalculationArgs = {
  compositeKeys: Array<GQLContentRecordCompositeKeyInput>;
  contextOrgId: Scalars['ID'];
};


export type GQLMutationPerformActionOnRisksArgs = {
  compositeKeys?: Maybe<Array<GQLRiskItemCompositeKey>>;
  contextOrgId?: Maybe<Scalars['ID']>;
  riskAction: GQLRiskAction;
  riskActionParameters: GQLRiskItemEventInputParameters;
  serializedCompositeKeys?: Maybe<Array<Scalars['String']>>;
};


export type GQLMutationPerformActionOnRisksByQueryArgs = {
  advancedFilter?: Maybe<GQLRisksSearchAdvancedQueryParams>;
  filter: GQLRisksSearchQueryParams;
  orgId: Scalars['ID'];
  riskAction: GQLRiskAction;
  riskActionParameters: GQLRiskItemEventInputParameters;
};


export type GQLMutationRemoveLayoutItemsArgs = {
  orgId: Scalars['ID'];
  viewIds: Array<Scalars['ID']>;
};


export type GQLMutationRemoveLayoutItemsV2Args = {
  orgId: Scalars['ID'];
  removeLayoutItemInput: Array<GQLRemoveLayoutItemInput>;
};


export type GQLMutationRisksExportArgs = {
  contextOrgId: Scalars['ID'];
  filter: GQLRisksSearchQueryParams;
  format: GQLContentExportFormat;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  source?: Maybe<GQLContentExportSourceInput>;
};


export type GQLMutationSendInstructionEmailArgs = {
  contextOrgId: Scalars['ID'];
  instructionEmailParameters: GQLInstructionEmailInput;
  instructionId: Scalars['ID'];
};


export type GQLMutationSendInstructionsStatusArgs = {
  contextOrgId: Scalars['ID'];
  instructionIds: Array<Scalars['ID']>;
  instructionStatusInput: GQLInstructionStatusEnumV3;
  isInternal?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
};


export type GQLMutationSendInstructionStatusArgs = {
  instructionId: Scalars['ID'];
  instructionStatusEta?: Maybe<Scalars['DateTime']>;
  instructionStatusInput: GQLInstructionStatusInput;
  isInternal?: Maybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};


export type GQLMutationSendInstructionStatusV2Args = {
  instructionId: Scalars['ID'];
  instructionStatusEta?: Maybe<Scalars['DateTime']>;
  instructionStatusInput: GQLInstructionStatusEnumV3;
  isInternal?: Maybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};


export type GQLMutationSetDismissNotificationArgs = {
  contextOrgId: Scalars['ID'];
  setDismissNotificationParameters: Array<GQLSetDismissNotificationInput>;
};


export type GQLMutationSetReadNotificationArgs = {
  contextOrgId: Scalars['ID'];
  setReadNotificationParameters: Array<GQLSetReadNotificationInput>;
};


export type GQLMutationToggleJourneyArgs = {
  journeyId: Scalars['ID'];
  journeyStatusInput: GQLJourneyStatusInput;
  orgId: Scalars['ID'];
};


export type GQLMutationUpdateActivitySubscriptionArgs = {
  contextOrgId: Scalars['ID'];
  updateActivitySubscriptionInput: GQLUpdateActivitySubscriptionDataInput;
};


export type GQLMutationUpdateAutoAssignRuleArgs = {
  autoAssignRuleInput: GQLAutoAssignRuleInput;
};


export type GQLMutationUpdateAutoAssignRulesPrioritiesArgs = {
  autoAssignRuleSetId: Scalars['ID'];
  autoAssignRuleUpdatePriorityInput: Array<GQLAutoAssignRulePriorityUpdateInput>;
};


export type GQLMutationUpdateChatRoomArgs = {
  id: Scalars['ID'];
  inboundChatRoom: GQLInboundChatRoom;
  orgId: Scalars['ID'];
};


export type GQLMutationUpdateClientArgs = {
  clientInput: GQLClientInput;
  contextOrgId: Scalars['ID'];
};


export type GQLMutationUpdateClientFieldsArgs = {
  clientFieldsInput: Array<GQLClientFieldInput>;
};


export type GQLMutationUpdateContentRecordArgs = {
  contextOrgId: Scalars['ID'];
  updateContentRecordInput: GQLUpdateContentRecordInput;
};


export type GQLMutationUpdateEnrichmentActivityArgs = {
  contextOrgId: Scalars['ID'];
  updateActivityInput: GQLUpdateEnrichmentActivityInput;
};


export type GQLMutationUpdateEnrichmentActivityRuleDefinitionArgs = {
  contextOrgId: Scalars['ID'];
  ruleDefinitions: GQLUpdateEnrichmentRuleDefinitionsInput;
};


export type GQLMutationUpdateEntitlementFieldsSchemaArgs = {
  entitlementFieldsSchemaId: Scalars['ID'];
  entitlementFieldsSchemaInput: GQLEntitlementFieldsSchemaInput;
};


export type GQLMutationUpdateEntitlementRuleArgs = {
  id: Scalars['ID'];
  inputEntitlementRule: GQLEntitlementRuleInput;
};


export type GQLMutationUpdateExtendedClientFieldsArgs = {
  clientFieldsInput: Array<GQLClientFieldInput>;
  contextOrgId: Scalars['ID'];
};


export type GQLMutationUpdateJourneyArgs = {
  journeyInput: GQLJourneyInput;
  orgId: Scalars['ID'];
};


export type GQLMutationUpdateJourneyGroupArgs = {
  id: Scalars['ID'];
  journeyGroupInput: GQLJourneyGroupInput;
  orgId: Scalars['ID'];
};


export type GQLMutationUpdateNotificationSettingsArgs = {
  contextOrgId: Scalars['ID'];
  updateNotificationSettings: Array<GQLNotificationSettingsEventDataInput>;
};


export type GQLMutationUpdateOrganizationEntitlementArgs = {
  inputEntitlement: GQLOrganizationEntitlementInput;
};


export type GQLMutationUpdatePairingGroupArgs = {
  contextOrgId: Scalars['ID'];
  pairingGroupUpdateInput: GQLPairingGroupUpdateInput;
};


export type GQLMutationUpdatePairingStatusFieldArgs = {
  contextOrgId: Scalars['ID'];
  pairingStatusFieldUpdateInput: GQLPairingStatusFieldUpdateInput;
};


export type GQLMutationUpdateQuickFilterArgs = {
  quickFilterInput: GQLQuickFilterInput;
};


export type GQLMutationUpdateRuleArgs = {
  expression: Scalars['Json'];
  ruleId: Scalars['ID'];
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLMutationUpdateSourceSystemArgs = {
  orgId: Scalars['ID'];
  sourceSystem: GQLSourceSystemInput;
};


export type GQLMutationUpdateUserArgs = {
  contextOrgId: Scalars['ID'];
  userInput: GQLUserInput;
};


export type GQLMutationUpdateUserEntitlementArgs = {
  inputEntitlement: GQLUserEntitlementInput;
};


export type GQLMutationUpdateUserLayoutArgs = {
  orgId: Scalars['ID'];
  userLayoutItems: Array<GQLLayoutItemInput>;
};


export type GQLMutationUpdateUserLayoutV2Args = {
  orgId: Scalars['ID'];
  userLayoutItems: Array<GQLLayoutItemV2Input>;
};


export type GQLMutationUpdateUsersGroupArgs = {
  usersGroupInput: GQLUsersGroupInput;
};


export type GQLMutationUpdateUsersGroupEntitlementArgs = {
  inputEntitlement: GQLUsersGroupEntitlementInput;
};


export type GQLMutationUpdateVendorArgs = {
  contextOrgId: Scalars['ID'];
  vendorInput: GQLVendorInput;
};


export type GQLMutationUpdateViewArgs = {
  copyExpressionFromViewId?: Maybe<Scalars['ID']>;
  viewInput: GQLViewInput;
};


export type GQLMutationUpdateViewDisplayConfigurationArgs = {
  orgId: Scalars['ID'];
  viewDisplayConfigurationInput: GQLViewDisplayConfigurationInput;
};


export type GQLMutationUpdateViewV2Args = {
  contextOrgId: Scalars['ID'];
  copyExpressionFromViewId?: Maybe<Scalars['ID']>;
  updateViewInput: GQLUpdateViewInput;
};


export type GQLMutationUpsertEntitlementRuleReferringEntitiesArgs = {
  id: Scalars['ID'];
  organizationEntitlements?: Maybe<Array<GQLOrganizationEntitlementInput>>;
  userEntitlements?: Maybe<Array<GQLUserEntitlementInput>>;
  usersGroupEntitlements?: Maybe<Array<GQLUsersGroupEntitlementInput>>;
};


export type GQLMutationUpsertUserPreferenceArgs = {
  userPreferenceInput: GQLUserPreferenceInput;
};


export type GQLMutationDeleteConnectorByIdArgs = {
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLMutationDeleteConsumerArgs = {
  dbConsumer?: Maybe<GQLInboundDbConsumer>;
  kafkaMessageBrokerConsumer?: Maybe<GQLInboundKafkaConsumer>;
  orgId: Scalars['ID'];
  rabbitMessageBrokerConsumer?: Maybe<GQLInboundRabbitConsumer>;
};


export type GQLMutationDeleteDataMappingByIdsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type GQLMutationDeleteEmailDestinationAddressArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteEmailDestinationTemplateArgs = {
  destinationId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLMutationDeleteEmailTemplateArgs = {
  templateName: Scalars['ID'];
};


export type GQLMutationDeleteFileConnectorArgs = {
  inboundFileConnector: GQLInboundFileConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteFixConnectorArgs = {
  inboundFixConnector: GQLInboundFixConnectorBoth;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteHttpConnectorArgs = {
  httpConnectorClient?: Maybe<GQLInboundHttpConnectorClient>;
  httpConnectorServer?: Maybe<GQLInboundHttpConnectorServer>;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteIbmMqConnectorArgs = {
  ibmMqConnectorInbound?: Maybe<GQLInboundIbmMqConnectorIn>;
  ibmMqConnectorOutbound?: Maybe<GQLInboundIbmMqConnectorOut>;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteImageVersionArgs = {
  inboundOperation: GQLInboundImageVersion;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteImapConnectorArgs = {
  inboundImapConnector: GQLInboundImapConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationDeletePublisherArgs = {
  dbPublisher?: Maybe<GQLInboundDbPublisher>;
  kafkaMessageBrokerPublisher?: Maybe<GQLInboundKafkaPublisher>;
  orgId: Scalars['ID'];
  rabbitMessageBrokerPublisher?: Maybe<GQLInboundRabbitPublisher>;
};


export type GQLMutationDeleteRabbitMqConnectorArgs = {
  orgId: Scalars['ID'];
  rabbitMqConnectorInbound?: Maybe<GQLInboundRabbitMqConnectorIn>;
  rabbitMqConnectorOutbound?: Maybe<GQLInboundRabbitMqConnectorOut>;
};


export type GQLMutationDeleteRegressionTestArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteSmtpConnectorArgs = {
  inboundSmtpConnector: GQLInboundSmtpConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteSystemAddressArgs = {
  inboundSystemAddress: GQLInboundSystemAddress;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteTemplateArgs = {
  inboundTemplateDefinition: GQLInboundTemplateDefinition;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteTemplateMessageArgs = {
  inboundTemplateMessage: GQLInboundTemplateMessage;
  orgId: Scalars['ID'];
};


export type GQLMutationDeleteWebConnectorArgs = {
  inboundWebConnector: GQLInboundWebConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationDeployTemplateSchemaArgs = {
  id: Scalars['String'];
  orgId: Scalars['ID'];
};


export type GQLMutationDownloadDataMappingFileArgs = {
  orgId: Scalars['ID'];
};


export type GQLMutationElasticMessageActionByCriteriaArgs = {
  inboundElasticMessageCriteria: GQLInboundElasticAction;
};


export type GQLMutationExecuteRegressionActionArgs = {
  inboundRegressionAction: GQLInboundRegressionAction;
};


export type GQLMutationImportTemplateSchemaArgs = {
  id: Scalars['String'];
  orgId: Scalars['ID'];
};


export type GQLMutationMessageBulkDeleteArgs = {
  inboundMessageBulkDelete: GQLInboundMessageBulkDetailsCriteria;
};


export type GQLMutationMessageBulkDownloadCsvArgs = {
  inboundMessageBulkDeleteDownloadCsv: GQLInboundMessageBulkDetailsCriteria;
};


export type GQLMutationMessageBulkResendReplayArgs = {
  inboundMessageBulkResendReplay: GQLInboundMessageBulkDetailsCriteria;
};


export type GQLMutationMessageBulkUploadTemplateArgs = {
  inboundMessageBulkUploadTemplate: GQLInboundMessageBulkUpload;
};


export type GQLMutationMetaMessageActionByCriteriaArgs = {
  inboundMetaMessageCriteria: GQLInboundMetaByCriteria;
  orgId: Scalars['ID'];
};


export type GQLMutationMetaMessageActionByDetailArgs = {
  inboundMetaMessageDetail: GQLInboundMetaByDetail;
  orgId: Scalars['ID'];
};


export type GQLMutationRefreshConnectorByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type GQLMutationResetFixSequenceByIdArgs = {
  id: Scalars['String'];
  orgId: Scalars['ID'];
};


export type GQLMutationRollbackTemplateMessageArgs = {
  inboundRollback: GQLInboundRollback;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveAmqpConnectorBothDirectionArgs = {
  inboundAmqpConnector: GQLInboundAmqpConnectorBothDirection;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveConsumerArgs = {
  dbConsumer?: Maybe<GQLInboundDbConsumer>;
  httpConsumer?: Maybe<GQLInboundHttpConsumer>;
  kafkaMessageBrokerConsumer?: Maybe<GQLInboundKafkaConsumer>;
  orgId: Scalars['ID'];
  rabbitMessageBrokerConsumer?: Maybe<GQLInboundRabbitConsumer>;
};


export type GQLMutationSaveDbConnectorArgs = {
  inboundDbConnector?: Maybe<GQLInboundDbConnector>;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveEmailDestinationAddressArgs = {
  inboundEmailService: GQLInboundEmailAddressMapping;
};


export type GQLMutationSaveEmailDestinationTemplateArgs = {
  inboundEmailTemplateService: GQLInboundEmailDestinationTemplate;
};


export type GQLMutationSaveEmailTemplateArgs = {
  inboundEmailTemplateDestinationTestService: GQLInboundEmailTemplateGraphType;
};


export type GQLMutationSaveFileConnectorArgs = {
  inboundFileConnector: GQLInboundFileConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveFixConnectorArgs = {
  inboundFixConnector: GQLInboundFixConnectorBoth;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveHostedKafkaConnectorArgs = {
  kafkaConnectorInbound?: Maybe<GQLInboundHostedKafkaConnectorIn>;
  kafkaConnectorOutbound?: Maybe<GQLInboundHostedKafkaConnectorOut>;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveHostedSftpConnectorArgs = {
  orgId: Scalars['ID'];
  sftpConnectorInbound?: Maybe<GQLInboundSftpHostedConnectorIn>;
  sftpConnectorOutbound?: Maybe<GQLInboundSftpHostedConnectorOut>;
};


export type GQLMutationSaveHttpConnectorArgs = {
  httpConnectorClient?: Maybe<GQLInboundHttpConnectorClient>;
  httpConnectorServer?: Maybe<GQLInboundHttpConnectorServer>;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveIbmMqConnectorArgs = {
  ibmMqConnectorInbound?: Maybe<GQLInboundIbmMqConnectorIn>;
  ibmMqConnectorOutbound?: Maybe<GQLInboundIbmMqConnectorOut>;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveImageVersionArgs = {
  inboundOperation: GQLInboundImageVersion;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveImapConnectorArgs = {
  inboundImapConnector: GQLInboundImapConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveKafkaConnectorArgs = {
  kafkaConnectorInbound?: Maybe<GQLInboundKafkaConnectorIn>;
  kafkaConnectorOutbound?: Maybe<GQLInboundHostedKafkaConnectorOut>;
  orgId: Scalars['ID'];
};


export type GQLMutationSavePublisherArgs = {
  dbPublisher?: Maybe<GQLInboundDbPublisher>;
  kafkaMessageBrokerPublisher?: Maybe<GQLInboundKafkaPublisher>;
  orgId: Scalars['ID'];
  rabbitMessageBrokerPublisher?: Maybe<GQLInboundRabbitPublisher>;
};


export type GQLMutationSaveRabbitMqConnectorArgs = {
  orgId: Scalars['ID'];
  rabbitMqConnectorInbound?: Maybe<GQLInboundRabbitMqConnectorIn>;
  rabbitMqConnectorOutbound?: Maybe<GQLInboundRabbitMqConnectorOut>;
};


export type GQLMutationSaveRegressionTestArgs = {
  inboundRegressionTestRequest: GQLInboundRegressionTestRequestModel;
};


export type GQLMutationSaveSftpConnectorArgs = {
  orgId: Scalars['ID'];
  sftpConnectorInbound?: Maybe<GQLInboundSftpConnectorIn>;
  sftpConnectorOutbound?: Maybe<GQLInboundSftpConnectorOut>;
};


export type GQLMutationSaveSmtpConnectorArgs = {
  inboundSmtpConnector: GQLInboundSmtpConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveSystemAddressArgs = {
  inboundSystemAddress: GQLInboundSystemAddress;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveTemplateArgs = {
  inboundTemplateDefinition: GQLInboundTemplateDefinition;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveTemplateMessageArgs = {
  inboundTemplateMessage: GQLInboundTemplateMessage;
  orgId: Scalars['ID'];
};


export type GQLMutationSaveWebConnectorArgs = {
  inboundWebConnector: GQLInboundWebConnector;
  orgId: Scalars['ID'];
};


export type GQLMutationStartConnectorArgs = {
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLMutationStopConnectorArgs = {
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLMutationTestEmailExpressionArgs = {
  inboundEmailTemplateTestService: GQLInboundEmailTemplateTest;
};


export type GQLMutationTestEmailTemplateArgs = {
  inboundEmailTemplateTestService: GQLInboundEmailTemplateTest;
};


export type GQLMutationTestTemplateMessageArgs = {
  inboundTemplateMessage: GQLInboundTemplateMessage;
  orgId: Scalars['ID'];
};


export type GQLMutationUpdateFixSequenceByIdArgs = {
  id: Scalars['String'];
  inboundFixSequence: GQLInboundFixSequence;
  orgId: Scalars['ID'];
};


export type GQLMutationUploadDataMappingFileArgs = {
  inboundDataMapping: GQLInboundOrgDataMapping;
  orgId: Scalars['ID'];
};


export type GQLMutationUploadFileTemplateArgs = {
  inboundUploadFile: GQLInboundFileUpload;
  orgId: Scalars['ID'];
};


export type GQLMutationRequestInventoryArgs = {
  contextOrgId: Scalars['ID'];
  orgId: Scalars['ID'];
  serialisedId: Scalars['ID'];
};


export type GQLMutationValidateAllocationArgs = {
  orgId: Scalars['ID'];
  contentRecordSerializedKey: Scalars['ID'];
  combos: Array<GQLValidationCombo>;
};


export type GQLMutationSubmitAllocationArgs = {
  orgId: Scalars['ID'];
  contentRecordSerializedKey: Scalars['ID'];
  combos: Array<GQLSubmissionCombo>;
  takeDownQty?: Maybe<Scalars['Float']>;
};


export type GQLMutationAmendAllocationArgs = {
  orgId: Scalars['ID'];
  contentRecordSerializedKey: Scalars['ID'];
  combos: Array<GQLAmendCombo>;
  takeDownQty?: Maybe<Scalars['Float']>;
};


export type GQLMutationAutoAllocationArgs = {
  orgId: Scalars['ID'];
  contentRecordSerializedKeys: Array<Scalars['ID']>;
  maxPoolsPerCombo: Scalars['Int'];
  highVariance: Scalars['String'];
  inventories: Array<GQLAutoAllocationInventory>;
};

export enum GQLNavigationMenuPermission {
  Analytics = 'ANALYTICS',
  BusinessIntelligence = 'BUSINESS_INTELLIGENCE',
  ConfigurationCenter = 'CONFIGURATION_CENTER',
  ContentDetails = 'CONTENT_DETAILS',
  FieldConfig = 'FIELD_CONFIG',
  JourneyCenter = 'JOURNEY_CENTER',
  Onboarding = 'ONBOARDING',
  VideoHelp = 'VIDEO_HELP'
}

export enum GQLNoneMappingFormat {
  None = 'NONE'
}

export type GQLNotBetweenExpression = GQLEquationExpressionInterface & GQLExpressionInterface & GQLLowerAndUpperBoundsExpressionInterface & {
  __typename?: 'NotBetweenExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  lowerBound?: Maybe<GQLDataOperandInterface>;
  upperBound?: Maybe<GQLDataOperandInterface>;
};

export type GQLNotContainsExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'NotContainsExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLNotEndsWithExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'NotEndsWithExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLNotEqualsExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'NotEqualsExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLNotification = {
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  creationTime: Scalars['DateTime'];
  eventType: GQLNotificationEventType;
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  user: GQLUser;
  userId: Scalars['ID'];
};

export enum GQLNotificationEventType {
  AdminNotification = 'ADMIN_NOTIFICATION',
  ContentExportDone = 'CONTENT_EXPORT_DONE',
  ContentRecordAttachment = 'CONTENT_RECORD_ATTACHMENT',
  ContentRecordCancelled = 'CONTENT_RECORD_CANCELLED',
  ContentRecordComment = 'CONTENT_RECORD_COMMENT',
  ContentRecordInvalidated = 'CONTENT_RECORD_INVALIDATED',
  ContentRecordNotificationSubscriptionCreated = 'CONTENT_RECORD_NOTIFICATION_SUBSCRIPTION_CREATED',
  EntitlementCreated = 'ENTITLEMENT_CREATED',
  InstructionCommentReceived = 'INSTRUCTION_COMMENT_RECEIVED',
  InstructionCommentReceivedEvent = 'INSTRUCTION_COMMENT_RECEIVED_EVENT',
  InstructionCreatedOnContentRecords = 'INSTRUCTION_CREATED_ON_CONTENT_RECORDS',
  InstructionStatusOnContentRecords = 'INSTRUCTION_STATUS_ON_CONTENT_RECORDS',
  RiskAssigned = 'RISK_ASSIGNED',
  RiskComment = 'RISK_COMMENT'
}

export enum GQLNotificationEventTypeInput {
  ContentRecordAttachment = 'CONTENT_RECORD_ATTACHMENT',
  ContentRecordComment = 'CONTENT_RECORD_COMMENT',
  ContentRecordNotificationSubscriptionCreated = 'CONTENT_RECORD_NOTIFICATION_SUBSCRIPTION_CREATED',
  InstructionCommentReceived = 'INSTRUCTION_COMMENT_RECEIVED',
  InstructionCommentReceivedEvent = 'INSTRUCTION_COMMENT_RECEIVED_EVENT',
  InstructionCreatedOnContentRecords = 'INSTRUCTION_CREATED_ON_CONTENT_RECORDS',
  InstructionStatusOnContentRecords = 'INSTRUCTION_STATUS_ON_CONTENT_RECORDS',
  RiskAssigned = 'RISK_ASSIGNED',
  RiskComment = 'RISK_COMMENT'
}

export type GQLNotificationSettingsEventData = {
  __typename?: 'NotificationSettingsEventData';
  eventType: GQLNotificationEventType;
  isEnabled: Scalars['Boolean'];
};

export type GQLNotificationSettingsEventDataInput = {
  eventType: GQLNotificationEventTypeInput;
  isEnabled: Scalars['Boolean'];
};

export type GQLNotificationSubscription = {
  __typename?: 'NotificationSubscription';
  creationTime: Scalars['DateTime'];
  entity?: Maybe<GQLNotificationSubscriptionEntity>;
  id: Scalars['ID'];
  identity: GQLNotificationSubscriptionIdentity;
};

export type GQLNotificationSubscriptionEntity = GQLContentRecord | GQLRiskItem;

export type GQLNotificationSubscriptionEntityInput = {
  serializedCompositeKey: Scalars['ID'];
  type: GQLNotificationSubscriptionEntityTypeInput;
};

export enum GQLNotificationSubscriptionEntityTypeInput {
  ContentRecord = 'CONTENT_RECORD',
  Risk = 'RISK'
}

export type GQLNotificationSubscriptionIdentity = GQLUser | GQLUsersGroup;

export type GQLNotificationSubscriptionIdentityInput = {
  id: Scalars['ID'];
  type: GQLNotificationSubscriptionIdentityTypeInput;
};

export enum GQLNotificationSubscriptionIdentityTypeInput {
  Group = 'GROUP',
  User = 'USER'
}

export type GQLNotificationSubscriptionInput = {
  entity: GQLNotificationSubscriptionEntityInput;
  identity: GQLNotificationSubscriptionIdentityInput;
};

export type GQLNotInExpression = GQLEquationExpressionInterface & GQLExpressionInterface & GQLListEquationExpressionInterface & {
  __typename?: 'NotInExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right: Array<GQLDataOperandInterface>;
};

export type GQLNotLogicalExpression = GQLExpressionInterface & GQLUnaryLogicalExpressionInterface & {
  __typename?: 'NotLogicalExpression';
  expression?: Maybe<GQLExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export type GQLNotStartsWithExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'NotStartsWithExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export type GQLNoValueExpressionMeta = GQLExpressionMeta & {
  __typename?: 'NoValueExpressionMeta';
  id: Scalars['ID'];
  type: GQLExpressionType;
  field?: Maybe<GQLBaseField>;
  fullLabel: Scalars['String'];
  valueLabel: Scalars['String'];
  label: Scalars['String'];
  fieldLabel: Scalars['String'];
  operatorLabel: Scalars['String'];
  operatorSymbol: Scalars['String'];
  operator: GQLOperator;
  expression: Scalars['Expression'];
};

export enum GQLOauthType {
  Basic = 'BASIC',
  None = 'NONE',
  Oauth_2 = 'OAUTH_2'
}

export type GQLObjectArrayOperand = GQLFieldOperandInterface & GQLObjectArrayOperandInterface & GQLValueOperandInterface & {
  __typename?: 'ObjectArrayOperand';
  arrayName?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export type GQLObjectArrayOperandInterface = {
  arrayName?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export type GQLOnBehalfOrganization = {
  __typename?: 'OnBehalfOrganization';
  onBehalfExpression?: Maybe<GQLTemplateExpression>;
  organizations?: Maybe<Array<Maybe<GQLOnBehalfOrgMapping>>>;
};

export type GQLOnBehalfOrgMapping = {
  __typename?: 'OnBehalfOrgMapping';
  mapping?: Maybe<Array<Maybe<Scalars['String']>>>;
  orgId?: Maybe<Scalars['String']>;
};

export enum GQLOperationEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export enum GQLOperator {
  And = 'and',
  Or = 'or',
  Eq = 'eq',
  Ne = 'ne',
  IsNotNull = 'isNotNull',
  IsNull = 'isNull',
  Gt = 'gt',
  Ge = 'ge',
  Lt = 'lt',
  Le = 'le',
  Contains = 'contains',
  Starts = 'starts',
  Ends = 'ends',
  In = 'in',
  NotIn = 'notIn',
  Between = 'between',
  NotContains = 'notContains'
}

export type GQLOppositeValue = {
  __typename?: 'OppositeValue';
  counterValue: Scalars['String'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type GQLOppositeValueInput = {
  counterValue: Scalars['String'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export enum GQLOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GQLOrderedJourney = {
  __typename?: 'OrderedJourney';
  index: Scalars['Int'];
  journey: GQLJourney;
  journeyId: Scalars['ID'];
};

export type GQLOrderedJourneyInput = {
  index: Scalars['Int'];
  journeyId: Scalars['ID'];
};

export type GQLOrganization = {
  __typename?: 'Organization';
  activitySubscriptionUniverse: GQLActivitySubscriptionUniverse;
  caasUniverse: GQLCaasUniverse;
  client?: Maybe<GQLClient>;
  contentRecordUniverse: GQLContentRecordUniverse;
  description?: Maybe<Scalars['String']>;
  entitlementUniverse: GQLEntitlementUniverse;
  fieldUniverse: GQLFieldUniverse;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  instructionUniverse: GQLInstructionUniverse;
  journeyGroupUniverse: GQLJourneyGroupUniverse;
  legalEntityIdentifiers: Array<Maybe<Scalars['String']>>;
  linkedGroups: Array<GQLLinkedGroup>;
  name: Scalars['String'];
  productLineUniverse: GQLProductLineUniverse;
  /** @deprecated Please use the nullable version QuickFilterV2 */
  quickFilters: Array<GQLQuickFilter>;
  quickFiltersV2?: Maybe<Array<GQLQuickFilter>>;
  ruleUniverse: GQLRuleUniverse;
  transactionAuditUniverse: GQLTransactionAuditUniverse;
  vendor?: Maybe<GQLVendor>;
  viewUniverse: GQLViewUniverse;
  website?: Maybe<Scalars['String']>;
  contacts: Array<GQLContact>;
};


export type GQLOrganizationContactsArgs = {
  contextOrgId: Scalars['ID'];
  orgIds?: Maybe<Array<Scalars['ID']>>;
};

export type GQLOrganizationEntitlement = GQLEntitlement & {
  __typename?: 'OrganizationEntitlement';
  creationTime?: Maybe<Scalars['DateTime']>;
  entitlementFieldsSchema?: Maybe<GQLEntitlementFieldsSchema>;
  entitlementFieldsSchemaId?: Maybe<Scalars['ID']>;
  entitlementRule: GQLEntitlementRule;
  entitlementRuleId: Scalars['ID'];
  entitlementView: GQLView;
  fromOrg: GQLOrganization;
  fromOrgId: Scalars['ID'];
  instructionActionsPermissions: Array<Scalars['String']>;
  key: Scalars['ID'];
  lastEditingUser?: Maybe<GQLUser>;
  lastEditingUserId?: Maybe<Scalars['ID']>;
  toOrg: GQLOrganization;
  toOrgId: Scalars['ID'];
};

export type GQLOrganizationEntitlementInput = {
  entitlementFieldsSchemaId?: Maybe<Scalars['ID']>;
  entitlementRuleId: Scalars['ID'];
  fromOrgId: Scalars['ID'];
  instructionActionsPermissions?: Maybe<Array<Scalars['String']>>;
  key?: Maybe<Scalars['ID']>;
  toOrgId: Scalars['ID'];
};

export type GQLOrganizationInput = {
  client?: Maybe<GQLClientInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  legalEntityIdentifiers?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  vendor?: Maybe<GQLVendorInput>;
  website?: Maybe<Scalars['String']>;
};

export type GQLOrLogicalExpression = GQLBinaryLogicalExpressionInterface & GQLExpressionInterface & {
  __typename?: 'OrLogicalExpression';
  expressions?: Maybe<Array<Maybe<GQLExpressionInterface>>>;
  isFlat: Scalars['Boolean'];
};

export type GQLOutboundConnector = {
  keepAlive?: Maybe<Scalars['Boolean']>;
  reconnectInterval?: Maybe<Scalars['Int']>;
};

export enum GQLOutboundSourceType {
  Json = 'JSON',
  KeyValue = 'KEY_VALUE'
}

/** Information about pagination in a connection. */
export type GQLPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type GQLPairingAnalyticsBucketGraphType = {
  __typename?: 'PairingAnalyticsBucketGraphType';
  counterParty?: Maybe<GQLOrganization>;
  counterPartyName?: Maybe<Scalars['String']>;
  totalPerCounterParty?: Maybe<Scalars['Int']>;
};

export type GQLPairingFactSheet = {
  __typename?: 'PairingFactSheet';
  analyticsBuckets: Array<Maybe<GQLPairingAnalyticsBucketGraphType>>;
  counterPartiesAmount?: Maybe<Scalars['Int']>;
  isMatched: Scalars['Boolean'];
  organization?: Maybe<GQLOrganization>;
  organizationId: Scalars['ID'];
  totalBucketsValue?: Maybe<Scalars['Int']>;
};

export type GQLPairingGroup = {
  __typename?: 'PairingGroup';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  counterPartyField?: Maybe<GQLBaseField>;
  counterPartyFieldId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  pairingSchemas: Array<GQLPairingSchema>;
  pairingStatusField: GQLPairingStatusField;
  pairingStatusFieldId: Scalars['ID'];
};


export type GQLPairingGroupCounterPartyFieldArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLPairingGroupPairingStatusFieldArgs = {
  contextOrgId: Scalars['ID'];
};

export type GQLPairingGroupCreateInput = {
  contentTypeId: Scalars['ID'];
  counterPartyFieldId: Scalars['ID'];
  name: Scalars['String'];
  pairingSchemas: Array<GQLPairingSchemaCreateInput>;
  pairingStatusFieldId: Scalars['ID'];
};

export type GQLPairingGroupUpdateInput = {
  contentTypeId: Scalars['ID'];
  counterPartyFieldId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  pairingSchemas: Array<GQLPairingSchemaUpdateInput>;
  pairingStatusFieldId: Scalars['ID'];
};

export type GQLPairingInvestigationComparison = GQLPairingInvestigationItemInterface & {
  __typename?: 'PairingInvestigationComparison';
  comparisonItems: Array<GQLPairingInvestigationComparisonItem>;
  contentRecord: GQLContentRecord;
  contentRecordId: Scalars['String'];
  counterContentRecord: GQLContentRecord;
  counterContentRecordId: Scalars['String'];
  counterOrganization: GQLOrganization;
  counterOrgId: Scalars['ID'];
  description: Scalars['String'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
  resultType: GQLPairingInvestigationResultType;
  title: Scalars['String'];
};

export type GQLPairingInvestigationComparisonItem = {
  __typename?: 'PairingInvestigationComparisonItem';
  counterValue: Scalars['Primitive'];
  difference: Scalars['Float'];
  existingTolerance: Scalars['Float'];
  fieldName: Scalars['String'];
  value: Scalars['Primitive'];
};

export type GQLPairingInvestigationItem = GQLPairingInvestigationItemInterface & {
  __typename?: 'PairingInvestigationItem';
  contentRecord: GQLContentRecord;
  contentRecordId: Scalars['String'];
  counterContentRecord: GQLContentRecord;
  counterContentRecordId: Scalars['String'];
  counterOrganization: GQLOrganization;
  counterOrgId: Scalars['ID'];
  description: Scalars['String'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
  resultType: GQLPairingInvestigationResultType;
  title: Scalars['String'];
};

export type GQLPairingInvestigationItemInterface = {
  contentRecord: GQLContentRecord;
  contentRecordId: Scalars['String'];
  counterContentRecord: GQLContentRecord;
  counterContentRecordId: Scalars['String'];
  counterOrganization: GQLOrganization;
  counterOrgId: Scalars['ID'];
  description: Scalars['String'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
  resultType: GQLPairingInvestigationResultType;
  title: Scalars['String'];
};

export enum GQLPairingInvestigationResultType {
  BetterCandidateFound = 'BETTER_CANDIDATE_FOUND',
  ClientNotSubscribed = 'CLIENT_NOT_SUBSCRIBED',
  ClientNotSubscribedToContentType = 'CLIENT_NOT_SUBSCRIBED_TO_CONTENT_TYPE',
  CounterPartiesUnmatched = 'COUNTER_PARTIES_UNMATCHED',
  CounterPartyMissing = 'COUNTER_PARTY_MISSING',
  DifferentContentTypes = 'DIFFERENT_CONTENT_TYPES',
  MissingSchemaFields = 'MISSING_SCHEMA_FIELDS',
  NaRecord = 'NA_RECORD',
  NoMutualGroupsAfterSubscriptionsFilterExpression = 'NO_MUTUAL_GROUPS_AFTER_SUBSCRIPTIONS_FILTER_EXPRESSION',
  NoMutualStatusFieldSubscriptions = 'NO_MUTUAL_STATUS_FIELD_SUBSCRIPTIONS',
  RecalcRequired = 'RECALC_REQUIRED',
  RecordsAlreadyBestMatch = 'RECORDS_ALREADY_BEST_MATCH',
  SchemaFieldsValuesDiff = 'SCHEMA_FIELDS_VALUES_DIFF',
  SubscriptionsFilterExpressionNotPassed = 'SUBSCRIPTIONS_FILTER_EXPRESSION_NOT_PASSED'
}

export type GQLPairingSchema = {
  __typename?: 'PairingSchema';
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  pairingSchemaFields: Array<GQLPairingSchemaField>;
  pairingStatus: GQLPairingStatus;
  pairingStatusId: Scalars['ID'];
};

export type GQLPairingSchemaCreateInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  pairingSchemaFields: Array<GQLPairingSchemaFieldCreateInput>;
  pairingStatusId: Scalars['ID'];
};

export type GQLPairingSchemaField = {
  __typename?: 'PairingSchemaField';
  equationOperator?: Maybe<GQLEquationOperator>;
  globalFieldId: Scalars['ID'];
  id: Scalars['ID'];
  oppositeValues?: Maybe<Array<GQLOppositeValue>>;
  /**
   * For numeric field types - optional to add numeric tolerance, can be any
   * number; for datetime fields - optional to add tolerance in days units
   */
  tolerance?: Maybe<Scalars['Float']>;
};

export type GQLPairingSchemaFieldCreateInput = {
  equationOperator?: Maybe<GQLEquationOperatorInput>;
  globalFieldId: Scalars['ID'];
  /** This field has meaning only if in 'EquationOperator' field, 'OppositeValues' is chosen */
  oppositeValues?: Maybe<Array<GQLOppositeValueInput>>;
  /**
   * For numeric field types - optional to add numeric tolerance, can be any
   * number; for datetime fields - optional to add tolerance in days units
   */
  tolerance?: Maybe<Scalars['Float']>;
};

export type GQLPairingSchemaFieldUpdateInput = {
  equationOperator?: Maybe<GQLEquationOperatorInput>;
  globalFieldId: Scalars['ID'];
  /** For new pairing schema field, Id should not be set (null); to update existing, Id should be set */
  id?: Maybe<Scalars['ID']>;
  /** This field has meaning only if in 'EquationOperator' field, 'OppositeValues' is chosen */
  oppositeValues?: Maybe<Array<GQLOppositeValueInput>>;
  /**
   * For numeric field types - optional to add numeric tolerance, can be any
   * number; for datetime fields - optional to add tolerance in days units
   */
  tolerance?: Maybe<Scalars['Float']>;
};

export type GQLPairingSchemaUpdateInput = {
  /** For new pairing schema, Id should not be set (null); to update existing, Id should be set */
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  pairingSchemaFields: Array<GQLPairingSchemaFieldUpdateInput>;
  pairingStatusId: Scalars['ID'];
};

export type GQLPairingStatus = {
  __typename?: 'PairingStatus';
  defaultValue: Scalars['String'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type GQLPairingStatusCreateInput = {
  defaultValue: Scalars['String'];
  value: Scalars['String'];
};

export type GQLPairingStatusField = {
  __typename?: 'PairingStatusField';
  id: Scalars['ID'];
  name: Scalars['String'];
  pairingStatuses: Array<GQLPairingStatus>;
};

export type GQLPairingStatusFieldCreateInput = {
  name: Scalars['String'];
  pairingStatuses: Array<GQLPairingStatusCreateInput>;
};

export type GQLPairingStatusFieldUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  pairingStatuses: Array<GQLPairingStatusUpdateInput>;
};

export type GQLPairingStatusUpdateInput = {
  defaultValue: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  value: Scalars['String'];
};

export type GQLPartition = {
  __typename?: 'Partition';
  active?: Maybe<Scalars['Boolean']>;
  groupSize?: Maybe<Scalars['Int']>;
  partitionSize?: Maybe<Scalars['Int']>;
  recordIds?: Maybe<Array<Maybe<GQLMessageRecordId>>>;
  recordPayloadField?: Maybe<Scalars['String']>;
};

export type GQLPositionAggregation = {
  __typename?: 'PositionAggregation';
  id: Scalars['String'];
  groupLabel: Scalars['String'];
  groupProperties: Array<Scalars['String']>;
  opening: Scalars['Float'];
  debit: Scalars['Float'];
  credit: Scalars['Float'];
  current: Scalars['Float'];
  lastActivityDate: Scalars['Date'];
};

export type GQLPositionColumn = {
  __typename?: 'PositionColumn';
  /** Maps to boxID but it is guaranteed */
  id: Scalars['String'];
  /** Maps to boxName but it is guaranteed  */
  name: Scalars['String'];
  /** Sum of all items in 'rows.data' */
  total: Scalars['Float'];
  /** Copy of raw settlementLadder data */
  settlement: GQLSettlementItem;
};

export type GQLPositionRow = {
  __typename?: 'PositionRow';
  id: Scalars['String'];
  label: Scalars['String'];
  total: Scalars['Float'];
  /** Copy of primary and secondary values for each column. Indexes are synced with 'columns'. */
  data: Array<GQLDatum>;
  /** True if this row has breakdown of underlying data. Use to determine if you ran run a subquery */
  hasPositionsData: Scalars['Boolean'];
};

export type GQLPositionsFilter = {
  positionId: Scalars['String'];
};

export type GQLPositionSummary = {
  __typename?: 'PositionSummary';
  /** Information to display a column. Indexes are synced with 'rows.data'. */
  columns: Array<GQLPositionColumn>;
  /** Information to display a row */
  rows: Array<GQLPositionRow>;
  total: Scalars['Float'];
};

export type GQLPositionSummaryFilter = {
  transactionDates: Array<Scalars['Date']>;
};


export type GQLProductLine = {
  __typename?: 'ProductLine';
  /** @deprecated Deprecated. */
  connectionIds: Array<Maybe<Scalars['Int']>>;
  contentType?: Maybe<GQLContentType>;
  contentTypeId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExternalMatching: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  permitted: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  vendorOrganization?: Maybe<GQLOrganization>;
  vendorOrganizationId: Scalars['ID'];
};

export type GQLProductLineCustomField = GQLBaseField & {
  __typename?: 'ProductLineCustomField';
  /** @deprecated Use 'FriendlyName' instead */
  fieldFriendlyName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  productLineId?: Maybe<Scalars['ID']>;
  type: GQLFieldType;
};

export type GQLProductLineField = GQLBaseField & {
  __typename?: 'ProductLineField';
  /** @deprecated Use 'FriendlyName' instead */
  fieldFriendlyName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  productLineId?: Maybe<Scalars['ID']>;
  type: GQLFieldType;
  values: Array<Maybe<GQLProductLineFieldValue>>;
  comboId: Scalars['String'];
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  label: Scalars['String'];
};

export type GQLProductLineFieldOperand = GQLFieldOperandInterface & GQLValueOperandInterface & {
  __typename?: 'ProductLineFieldOperand';
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
  productLine: GQLProductLine;
  productLineId: Scalars['ID'];
};

export type GQLProductLineFieldValue = {
  __typename?: 'ProductLineFieldValue';
  value?: Maybe<Scalars['String']>;
  valueFriendlyName?: Maybe<Scalars['String']>;
};

export type GQLProductLineUniverse = {
  __typename?: 'ProductLineUniverse';
  productLines: Array<GQLProductLine>;
};


export type GQLProductLineUniverseProductLinesArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  isExternalMatching?: Maybe<Scalars['Boolean']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type GQLPropertyChangeDescriptor = {
  __typename?: 'PropertyChangeDescriptor';
  after?: Maybe<Scalars['Primitive']>;
  before?: Maybe<Scalars['Primitive']>;
  isChanged: Scalars['Boolean'];
  propertyName: Scalars['String'];
  propertyType?: Maybe<Scalars['String']>;
};

export enum GQLProtocolType {
  Amqp = 'AMQP',
  Db = 'DB',
  File = 'FILE',
  Fix = 'FIX',
  HttpClient = 'HTTP_CLIENT',
  HttpServer = 'HTTP_SERVER',
  Ibmmq = 'IBMMQ',
  Imap = 'IMAP',
  Kafka = 'KAFKA',
  KafkaHosted = 'KAFKA_HOSTED',
  Rabbit = 'RABBIT',
  Sftp = 'SFTP',
  SftpHosted = 'SFTP_HOSTED',
  Smtp = 'SMTP',
  Unknown = 'UNKNOWN',
  Web = 'WEB'
}

export type GQLPublisher = GQLDbPublisher | GQLKafkaPublisher | GQLRabbitPublisher;

export type GQLQuery = {
  __typename?: 'Query';
  activities: Array<GQLActivity>;
  activity: GQLActivity;
  activityEnrichmentFields: Array<GQLEnrichmentField>;
  allSystemFields: Array<GQLBaseSystemFieldInterface>;
  allView: GQLView;
  basicJourney?: Maybe<GQLJourney>;
  calculatedFields: Array<GQLContentRecordSystemField>;
  computableSystemFields: Array<GQLComputableSystemField>;
  contentExportStatus: GQLExportStatus;
  contentRecord: GQLContentRecord;
  contentRecordActionBarConfiguration: GQLActionBarConfiguration;
  contentRecordsByIds?: Maybe<Array<Maybe<GQLContentRecord>>>;
  contentRecordsSearchAvailableFields: Array<GQLBaseField>;
  contentRecordsSystemFields: Array<GQLContentRecordSystemField>;
  contentTypes?: Maybe<Array<Maybe<GQLContentType>>>;
  dailyJourneyFactSheet?: Maybe<GQLJourneyFactSheet>;
  dataMappingMapTypes: Array<GQLMapType>;
  enrichmentActivities: Array<GQLEnrichmentActivity>;
  enrichmentActivityRuleDefinitions: Array<GQLEnrichmentRuleDefinitions>;
  enrichmentFields: Array<GQLEnrichmentField>;
  entitlement: GQLEntitlement;
  entitlementRule: GQLEntitlementRule;
  entitlementRules: Array<Maybe<GQLEntitlementRule>>;
  entitySummaries: Array<GQLEntitySummary>;
  fields: Array<GQLBaseField>;
  /** @deprecated Use 'organization { fieldUniverse }' instead */
  fieldUniverse: Array<GQLBaseField>;
  genericFields: Array<GQLGenericField>;
  getRoomInfo?: Maybe<GQLRoomInfo>;
  instruction?: Maybe<GQLInstructionDetails>;
  instructionActionCategories: Array<GQLInstructionActionCategoryGraphType>;
  instructionActions: Array<GQLInstructionAction>;
  /** @deprecated Use organization.instructionUniverse.instructionActionConfiguration or instructionAction.instructionActionConfiguration */
  instructionCustomClientFields: Array<GQLInstructionCustomClientFieldGraphType>;
  /** @deprecated Please use `InstructionUniverse.destinationOrganizations` */
  instructionDestinationOrganizations: Array<GQLOrganization>;
  instructionSystemFields: Array<GQLInstructionSystemField>;
  journey?: Maybe<GQLEnrichedJourney>;
  journeyGroup: GQLJourneyGroup;
  journeyRecordsFactSheet?: Maybe<GQLJourneyRecordsFactSheet>;
  journeyRisksSummariesFactSheet?: Maybe<GQLJourneyRisksSummariesFactSheet>;
  journeys: Array<GQLEnrichedJourney>;
  journeyTypes: Array<GQLJourneyType>;
  linkedGroup: GQLLinkedGroup;
  linkedGroupReason?: Maybe<Array<Maybe<GQLLinkedGroupReason>>>;
  organization?: Maybe<GQLOrganization>;
  organizations?: Maybe<Array<Maybe<GQLOrganization>>>;
  paginatedEntitlementRules?: Maybe<GQLEntitlementRuleConnection>;
  pairingCenterView: GQLView;
  pairingGroup: GQLPairingGroup;
  pairingGroups: Array<GQLPairingGroup>;
  pairingInvestigation: Array<GQLPairingInvestigationItemInterface>;
  pairingMatchCreatedFactSheet?: Maybe<GQLPairingFactSheet>;
  pairingStatusField: GQLPairingStatusField;
  pairingStatusFields: Array<GQLPairingStatusField>;
  pairingUnpairedCandidatesFactSheet?: Maybe<GQLPairingFactSheet>;
  parseExpression: Scalars['Json'];
  quickFilter: GQLQuickFilter;
  /** Will be removed on the next API version */
  risk?: Maybe<GQLRiskItem>;
  riskActionAudit?: Maybe<Array<Maybe<GQLRiskActionAuditInterface>>>;
  riskActionBarConfiguration: GQLActionBarConfiguration;
  riskAssigneeFactSheet?: Maybe<GQLRiskAssigneeFactSheet>;
  /** @deprecated Please use riskViewUniverseGraphType.frequencies */
  riskFrequencies: Array<GQLFrequencyFieldSummary>;
  riskResolutions?: Maybe<Array<Maybe<GQLRiskResolution>>>;
  /** Risk riskSearchFilterType will be removed on the next API version */
  risks?: Maybe<GQLCappedRisks>;
  risksByIds?: Maybe<Array<Maybe<GQLRiskItem>>>;
  riskSearchAvailableFields: Array<GQLBaseField>;
  /** @deprecated Use entitySummaries endpoint instead */
  riskSummaries?: Maybe<Array<Maybe<GQLLayoutRiskSummary>>>;
  riskSystemFields: Array<GQLRiskSystemField>;
  riskTimeFrames?: Maybe<Array<Maybe<GQLTimeFrameConfiguration>>>;
  rule: GQLRuleInterface;
  searchContentRecords: GQLCappedContentRecords;
  sourceSystemById?: Maybe<GQLSourceSystem>;
  sourceSystems?: Maybe<Array<Maybe<GQLSourceSystem>>>;
  /** @deprecated Use 'riskSystemFields' instead */
  systemFields: Array<Maybe<GQLRiskSystemField>>;
  tag?: Maybe<GQLTag>;
  tags?: Maybe<Array<Maybe<GQLTag>>>;
  tatBucketFactSheet?: Maybe<GQLTatBucketFactSheet>;
  user?: Maybe<GQLUser>;
  userGroup?: Maybe<GQLUsersGroup>;
  userGroups?: Maybe<Array<Maybe<GQLUsersGroup>>>;
  userMe?: Maybe<GQLUser>;
  users: Array<GQLUser>;
  version?: Maybe<Scalars['String']>;
  video: GQLVideo;
  videos: Array<GQLVideo>;
  /** @deprecated Please use Organization.ViewUniverse */
  views: Array<GQLView>;
  addSystemAddressByCriteria?: Maybe<GQLSystemAddress>;
  downloadTemplateMessageDatafile?: Maybe<GQLTemplateMessageDataFile>;
  getAllImageVersions?: Maybe<Array<Maybe<GQLImageVersion>>>;
  getAvailableSystemAddressTemplateByCriteria?: Maybe<Array<Maybe<GQLTemplateDefinition>>>;
  getBucketDataById?: Maybe<Array<Maybe<GQLBucketInfo>>>;
  getConnectorByCriteria?: Maybe<Array<Maybe<GQLBaseConnector>>>;
  getConnectorLog?: Maybe<GQLConnectorLog>;
  getConnectorOptionalParamByType?: Maybe<GQLCaasFields>;
  getConnectorStatus?: Maybe<Array<Maybe<GQLConnectorStatus>>>;
  getConsumerByCriteria?: Maybe<Array<Maybe<GQLConsumer>>>;
  getContainerLog?: Maybe<GQLConnectorLog>;
  getDataMappingRecords?: Maybe<GQLDataMapping>;
  getDataMappingTypes?: Maybe<GQLDataMappingTypes>;
  getDateMaskList?: Maybe<Array<Maybe<Scalars['String']>>>;
  getEmailDestinationByCriteria?: Maybe<Array<Maybe<GQLEmailAddressMapping>>>;
  getEmailTemplateByName?: Maybe<GQLEmailTemplate>;
  getEmailTemplatesByDestinationId?: Maybe<Array<Maybe<GQLEmailDestinationTemplate>>>;
  getFixSequenceById?: Maybe<GQLFixSequence>;
  getIbmMqQueues?: Maybe<Array<Maybe<GQLIbmQueueGraphType>>>;
  getMetaMessageByCriteria?: Maybe<GQLMetaMessageQuery>;
  getPublisherByCriteria?: Maybe<Array<Maybe<GQLPublisher>>>;
  getSplitterParamsByType?: Maybe<GQLCaasFields>;
  getSystemAddressByCriteria?: Maybe<Array<Maybe<GQLSystemAddress>>>;
  getTemplateByCriteria?: Maybe<Array<Maybe<GQLTemplateDefinition>>>;
  getTemplateHeaderParams?: Maybe<GQLHeaderProperties>;
  getTemplateMessageAudit?: Maybe<Array<Maybe<GQLEntityAudit>>>;
  getTemplateMessageByCriteria?: Maybe<Array<Maybe<GQLTemplateMessage>>>;
  getTemplateMessageDiff?: Maybe<GQLEntityDiffResult>;
  getTemplateMetaParams?: Maybe<GQLCaasFields>;
  getTemplateNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  getValueByExpression?: Maybe<Scalars['String']>;
  isUsedByApplication?: Maybe<Scalars['Boolean']>;
  messageBulkQuery?: Maybe<Array<Maybe<GQLMessageBulk>>>;
  regressionTestQuery?: Maybe<GQLRegressionTestQuery>;
  flags: Array<GQLFlag>;
  /**
   * Provides human readable text and metada information per expression. Return
   * order respects input order. OR operators are ignored, nested expressions are flattened.
   */
  expressionDetails: Array<GQLExpressionMeta>;
  paymentsSpiLookup: Array<GQLSpiItem>;
};


export type GQLQueryActivitiesArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLQueryActivityArgs = {
  contextOrgId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLQueryActivityEnrichmentFieldsArgs = {
  activityId: Scalars['ID'];
  contextOrgId: Scalars['ID'];
};


export type GQLQueryAllSystemFieldsArgs = {
  isTimeFilter?: Maybe<Scalars['Boolean']>;
  systemFieldTypesFilter?: Maybe<Array<GQLSystemFieldInputType>>;
};


export type GQLQueryBasicJourneyArgs = {
  journeyId: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLQueryContentExportStatusArgs = {
  docId: Scalars['ID'];
  docOrgId: Scalars['ID'];
};


export type GQLQueryContentRecordArgs = {
  compositeKey?: Maybe<GQLContentRecordCompositeKeyInput>;
  orgId: Scalars['ID'];
  serializedCompositeKey?: Maybe<Scalars['String']>;
};


export type GQLQueryContentRecordActionBarConfigurationArgs = {
  contentRecordCompositeKey: Array<GQLContentRecordCompositeKeyInput>;
  contextOrgId: Scalars['ID'];
};


export type GQLQueryContentRecordsByIdsArgs = {
  compositeKeys?: Maybe<Array<Maybe<GQLContentRecordCompositeKeyInput>>>;
  contentRecordIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentTypeId?: Maybe<Scalars['ID']>;
  orgId: Scalars['ID'];
  serializedCompositeKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  validateExistence?: Maybe<Scalars['Boolean']>;
};


export type GQLQueryContentRecordsSearchAvailableFieldsArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  filterIds?: Maybe<Array<Scalars['String']>>;
  filterType: GQLContentRecordsSearchFilterType;
  orgId: Scalars['ID'];
};


export type GQLQueryContentRecordsSystemFieldsArgs = {
  isTimeFilter?: Maybe<Scalars['Boolean']>;
  systemFieldTypesFilter?: Maybe<Array<GQLSystemFieldInputType>>;
};


export type GQLQueryContentTypesArgs = {
  orgId?: Maybe<Scalars['ID']>;
  sharedWithMe?: Maybe<Scalars['Boolean']>;
};


export type GQLQueryDailyJourneyFactSheetArgs = {
  journeyId: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLQueryDataMappingMapTypesArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLQueryEnrichmentActivitiesArgs = {
  activityId?: Maybe<Scalars['ID']>;
  activityName?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['ID'];
};


export type GQLQueryEnrichmentActivityRuleDefinitionsArgs = {
  activityId: Scalars['ID'];
  contextOrgId: Scalars['ID'];
};


export type GQLQueryEntitlementArgs = {
  entitlementKey: Scalars['ID'];
};


export type GQLQueryEntitlementRuleArgs = {
  id: Scalars['ID'];
};


export type GQLQueryEntitlementRulesArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryEntitySummariesArgs = {
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  groupingContentRecordFieldKey?: Maybe<Scalars['String']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLQueryFieldsArgs = {
  keys: Array<Scalars['ID']>;
};


export type GQLQueryFieldUniverseArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  includeShared?: Scalars['Boolean'];
  isTimeFilter?: Scalars['Boolean'];
  orgId: Scalars['ID'];
};


export type GQLQueryGenericFieldsArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
};


export type GQLQueryGetRoomInfoArgs = {
  contentRecordId?: Maybe<Scalars['String']>;
  contentTypeId?: Maybe<Scalars['ID']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryInstructionArgs = {
  id?: Maybe<Scalars['ID']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryInstructionActionCategoriesArgs = {
  instructionActionCategoryIds?: Maybe<Array<Scalars['Int']>>;
};


export type GQLQueryInstructionActionsArgs = {
  contentTypeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  instructionActionCategoryIds?: Maybe<Array<Scalars['Int']>>;
  instructionActionIds?: Maybe<Array<Scalars['Int']>>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryInstructionCustomClientFieldsArgs = {
  contentTypeId?: Maybe<Scalars['ID']>;
  instructionActionId?: Maybe<Scalars['ID']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryInstructionDestinationOrganizationsArgs = {
  compositeKeys?: Maybe<Array<GQLContentRecordCompositeKeyInput>>;
  orgId: Scalars['ID'];
  serializedCompositeKeys?: Maybe<Array<Scalars['String']>>;
};


export type GQLQueryInstructionSystemFieldsArgs = {
  isTimeFilter?: Maybe<Scalars['Boolean']>;
  systemFieldTypesFilter?: Maybe<Array<GQLSystemFieldInputType>>;
};


export type GQLQueryJourneyArgs = {
  journeyId: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLQueryJourneyGroupArgs = {
  id: Scalars['ID'];
};


export type GQLQueryJourneyRecordsFactSheetArgs = {
  analyticsQueryParams: GQLJourneyAnalyticsQueryParams;
  orgId: Scalars['ID'];
};


export type GQLQueryJourneyRisksSummariesFactSheetArgs = {
  analyticsQueryParams: GQLJourneyRisksSummariesQueryParams;
  orgId: Scalars['ID'];
};


export type GQLQueryJourneysArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryJourneyTypesArgs = {
  orgId: Scalars['ID'];
};


export type GQLQueryLinkedGroupArgs = {
  id: Scalars['ID'];
};


export type GQLQueryLinkedGroupReasonArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type GQLQueryOrganizationsArgs = {
  includeClients?: Maybe<Scalars['Boolean']>;
  includeReceivers?: Maybe<Scalars['Boolean']>;
  includeShareOrgs?: Maybe<Scalars['Boolean']>;
  includeUserOnlyOrgs?: Maybe<Scalars['Boolean']>;
  includeVendors?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryPaginatedEntitlementRulesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orgId: Scalars['ID'];
};


export type GQLQueryPairingGroupArgs = {
  contextOrgId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLQueryPairingGroupsArgs = {
  contextOrgId: Scalars['ID'];
  statusFieldId?: Maybe<Scalars['ID']>;
};


export type GQLQueryPairingInvestigationArgs = {
  contextOrgId: Scalars['ID'];
  counterRecordKey?: Maybe<GQLContentRecordCompositeKeyInput>;
  recordKey?: Maybe<GQLContentRecordCompositeKeyInput>;
  serializedCounterRecordKey?: Maybe<Scalars['String']>;
  serializedRecordKey?: Maybe<Scalars['String']>;
};


export type GQLQueryPairingMatchCreatedFactSheetArgs = {
  analyticsQueryParams: GQLAnalyticsQueryParams;
  orgId: Scalars['ID'];
};


export type GQLQueryPairingStatusFieldArgs = {
  contextOrgId: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLQueryPairingStatusFieldsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLQueryPairingUnpairedCandidatesFactSheetArgs = {
  analyticsQueryParams: GQLAnalyticsQueryParams;
  orgId: Scalars['ID'];
};


export type GQLQueryParseExpressionArgs = {
  contentTypeId: Scalars['ID'];
  expression: Scalars['String'];
  isCustomized?: Maybe<Scalars['Boolean']>;
};


export type GQLQueryQuickFilterArgs = {
  id: Scalars['ID'];
};


export type GQLQueryRiskArgs = {
  id: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLQueryRiskActionAuditArgs = {
  auditQueryParams: GQLAuditQueryParams;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryRiskActionBarConfigurationArgs = {
  contextOrgId: Scalars['ID'];
  riskItemCompositeKey: Array<GQLRiskItemCompositeKey>;
};


export type GQLQueryRiskAssigneeFactSheetArgs = {
  analyticsQueryParams: GQLJourneyAnalyticsQueryParams;
  orgId: Scalars['ID'];
};


export type GQLQueryRiskFrequenciesArgs = {
  advancedFilter?: Maybe<GQLRisksSearchAdvancedQueryParams>;
  filter: GQLRisksSearchQueryParams;
  frequencyFieldType?: Maybe<GQLFrequencyFieldType>;
  orgId: Scalars['ID'];
};


export type GQLQueryRiskResolutionsArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryRisksArgs = {
  advancedFilter?: Maybe<GQLRisksSearchAdvancedQueryParams>;
  after?: Maybe<Scalars['String']>;
  filter: GQLRisksSearchQueryParams;
  first?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  orgId: Scalars['ID'];
};


export type GQLQueryRisksByIdsArgs = {
  compositeKeys?: Maybe<Array<GQLRiskItemCompositeKey>>;
  orgId: Scalars['ID'];
  serializedCompositeKeys?: Maybe<Array<Scalars['String']>>;
};


export type GQLQueryRiskSearchAvailableFieldsArgs = {
  contentTypeIds?: Maybe<Array<Scalars['ID']>>;
  filterIds?: Maybe<Array<Scalars['String']>>;
  filterType: GQLRisksSearchFilterType;
  orgId: Scalars['ID'];
};


export type GQLQueryRiskSummariesArgs = {
  advancedFilter?: Maybe<GQLRisksSearchAdvancedQueryParams>;
  filterExpression?: Maybe<Scalars['Json']>;
  orgId?: Maybe<Scalars['ID']>;
  summaryType?: Maybe<GQLSummaryType>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLQueryRiskSystemFieldsArgs = {
  isTimeFilter?: Maybe<Scalars['Boolean']>;
  systemFieldTypesFilter?: Maybe<Array<GQLSystemFieldInputType>>;
};


export type GQLQueryRiskTimeFramesArgs = {
  timeFrameType: GQLSummaryType;
};


export type GQLQueryRuleArgs = {
  ruleId: Scalars['ID'];
};


export type GQLQuerySearchContentRecordsArgs = {
  advancedFilter?: Maybe<GQLContentRecordsSearchAdvancedQueryParams>;
  filter: GQLContentRecordsSearchQueryParams;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  orgId: Scalars['ID'];
};


export type GQLQuerySourceSystemByIdArgs = {
  id: Scalars['ID'];
};


export type GQLQuerySourceSystemsArgs = {
  orgId?: Maybe<Scalars['ID']>;
  sharedWithMe?: Maybe<Scalars['Boolean']>;
};


export type GQLQueryTagArgs = {
  id: Scalars['Int'];
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryTagsArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryTatBucketFactSheetArgs = {
  analyticsQueryParams: GQLJourneyAnalyticsQueryParams;
  orgId: Scalars['ID'];
};


export type GQLQueryUserArgs = {
  id: Scalars['ID'];
};


export type GQLQueryUserGroupArgs = {
  id: Scalars['Int'];
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryUserGroupsArgs = {
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryUsersArgs = {
  includePermissionByShare?: Maybe<Scalars['Boolean']>;
  onlyChatUsers?: Maybe<Scalars['Boolean']>;
  orgId?: Maybe<Scalars['ID']>;
};


export type GQLQueryVideoArgs = {
  byId?: Maybe<Scalars['String']>;
  bySlug?: Maybe<Scalars['String']>;
};


export type GQLQueryViewsArgs = {
  orgId: Scalars['ID'];
};


export type GQLQueryAddSystemAddressByCriteriaArgs = {
  inboundQueryCriteria: GQLInboundAddSystemAddressCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryDownloadTemplateMessageDatafileArgs = {
  inboundTemplateMessageQueryCriteria: GQLInboundQueryCriteria;
};


export type GQLQueryGetAvailableSystemAddressTemplateByCriteriaArgs = {
  inboundQueryCriteria: GQLInboundQueryCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryGetBucketDataByIdArgs = {
  inboundBucketPrimaryKeyQuery: GQLInboundBucketPrimaryKey;
};


export type GQLQueryGetConnectorByCriteriaArgs = {
  connectorQueryCriteria: GQLInboundCaasQueryCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryGetConnectorLogArgs = {
  id: Scalars['String'];
  orgId: Scalars['ID'];
  pageNumber?: Maybe<Scalars['String']>;
};


export type GQLQueryGetConnectorOptionalParamByTypeArgs = {
  connectorQueryCriteria: GQLInboundCaasQueryCriteria;
};


export type GQLQueryGetConnectorStatusArgs = {
  connectorQueryCriteria: GQLInboundCaasQueryCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryGetConsumerByCriteriaArgs = {
  consumerQueryCriteria: GQLInboundCaasSystemQueryCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryGetContainerLogArgs = {
  id: Scalars['String'];
  orgId: Scalars['ID'];
  pageNumber?: Maybe<Scalars['String']>;
};


export type GQLQueryGetDataMappingRecordsArgs = {
  orgId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};


export type GQLQueryGetDataMappingTypesArgs = {
  orgId: Scalars['ID'];
};


export type GQLQueryGetEmailDestinationByCriteriaArgs = {
  inboundEmailServiceCriteria: GQLInboundEmailAddressCriteriaMapping;
};


export type GQLQueryGetEmailTemplateByNameArgs = {
  templateName?: Maybe<Scalars['String']>;
};


export type GQLQueryGetEmailTemplatesByDestinationIdArgs = {
  inboundEmailServiceTemplate: GQLInboundEmailDestinationTemplate;
};


export type GQLQueryGetFixSequenceByIdArgs = {
  id: Scalars['String'];
  orgId: Scalars['ID'];
};


export type GQLQueryGetIbmMqQueuesArgs = {
  inboundIbmQueuesQuery: GQLInboundIbmQueuesRequest;
};


export type GQLQueryGetMetaMessageByCriteriaArgs = {
  inboundMetaMessageQuery: GQLInboundMetaQuery;
  orgId: Scalars['ID'];
};


export type GQLQueryGetPublisherByCriteriaArgs = {
  orgId: Scalars['ID'];
  publisherQueryCriteria: GQLInboundCaasSystemQueryCriteria;
};


export type GQLQueryGetSplitterParamsByTypeArgs = {
  splitterType: GQLInboundSplitter;
};


export type GQLQueryGetSystemAddressByCriteriaArgs = {
  inboundQueryCriteria: GQLInboundSystemAddressCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryGetTemplateByCriteriaArgs = {
  inboundQueryCriteria: GQLInboundQueryCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryGetTemplateHeaderParamsArgs = {
  templateHeaderParam: GQLInboundHeaderParams;
};


export type GQLQueryGetTemplateMessageAuditArgs = {
  inboundAuditCriteria: GQLInboundAuditCriteria;
};


export type GQLQueryGetTemplateMessageByCriteriaArgs = {
  inboundTemplateMessageQueryCriteria: GQLInboundQueryCriteria;
  orgId: Scalars['ID'];
};


export type GQLQueryGetTemplateMessageDiffArgs = {
  inboundAuditCriteria: GQLInboundAuditCriteria;
};


export type GQLQueryGetValueByExpressionArgs = {
  expression: Scalars['ID'];
  messageId: Scalars['ID'];
};


export type GQLQueryIsUsedByApplicationArgs = {
  id: Scalars['ID'];
  orgId: Scalars['ID'];
};


export type GQLQueryMessageBulkQueryArgs = {
  inboundMessageBulkQuery: GQLInboundMessageBulkOrgId;
};


export type GQLQueryRegressionTestQueryArgs = {
  inboundRegressionTestQuery: GQLInboundRegressionTestQueryModel;
};


export type GQLQueryExpressionDetailsArgs = {
  orgId: Scalars['ID'];
  expressions: Array<Scalars['Json']>;
};


export type GQLQueryPaymentsSpiLookupArgs = {
  contextOrgId: Scalars['ID'];
  contentSerializedKeys: Array<Scalars['ID']>;
};

export type GQLQuickFilter = {
  __typename?: 'QuickFilter';
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  expression: Scalars['String'];
  expressionResolved: GQLExpressionInterface;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: GQLOrganization;
  organizationId: Scalars['ID'];
};

export type GQLQuickFilterInput = {
  contentTypeId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  expression: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type GQLRabbitConsumer = {
  __typename?: 'RabbitConsumer';
  active?: Maybe<Scalars['Boolean']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  messageBroker?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  pollingMode?: Maybe<Scalars['Boolean']>;
  pollInterval?: Maybe<Scalars['Int']>;
  prefetchCount?: Maybe<Scalars['Int']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  queue?: Maybe<Scalars['String']>;
  txCommitTimeout?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  user?: Maybe<Scalars['String']>;
};

export type GQLRabbitMqConnector = GQLRabbitMqConnectorIn | GQLRabbitMqConnectorOut;

export type GQLRabbitMqConnectorIn = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'RabbitMqConnectorIn';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollingMode?: Maybe<Scalars['Boolean']>;
  pollInterval?: Maybe<Scalars['Int']>;
  prefetchCount?: Maybe<Scalars['Int']>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  queue?: Maybe<Scalars['String']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  txCommitTimeout?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  useSasl?: Maybe<Scalars['Boolean']>;
  useTls?: Maybe<Scalars['Boolean']>;
};

export type GQLRabbitMqConnectorOut = GQLBaseConnector & GQLOutboundConnector & {
  __typename?: 'RabbitMqConnectorOut';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['String']>;
  exchangeType?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  queue?: Maybe<Scalars['String']>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  routingKey?: Maybe<Scalars['String']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  statusNote?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  txCommitTimeout?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  usePublisherConfirms?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['String']>;
  useSasl?: Maybe<Scalars['Boolean']>;
  useTls?: Maybe<Scalars['Boolean']>;
};

export type GQLRabbitPublisher = {
  __typename?: 'RabbitPublisher';
  active?: Maybe<Scalars['Boolean']>;
  exchange?: Maybe<Scalars['String']>;
  exchangeType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  messageBroker?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  queue?: Maybe<Scalars['String']>;
  routingKey?: Maybe<Scalars['String']>;
  txCommitTimeout?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
  usePublisherConfirms?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLRegressionAction = {
  __typename?: 'RegressionAction';
  error?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

export type GQLRegressionTestModel = {
  __typename?: 'RegressionTestModel';
  connectorName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  executionOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  s3FilePath?: Maybe<Scalars['String']>;
  testName?: Maybe<Scalars['String']>;
};

export type GQLRegressionTestQuery = {
  __typename?: 'RegressionTestQuery';
  errorMessage?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
  resultList?: Maybe<Array<Maybe<GQLRegressionTestModel>>>;
};

export type GQLRegressionTestResponse = {
  __typename?: 'RegressionTestResponse';
  errorMessage?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
  singleResult?: Maybe<GQLRegressionTestModel>;
};

export type GQLRemoveLayoutItemInput = {
  /** View ID */
  id: Scalars['ID'];
  layoutItemType: GQLLayoutItemTypeInput;
};

export enum GQLRiskAction {
  Accept = 'ACCEPT',
  Assign = 'ASSIGN',
  Claim = 'CLAIM',
  Comment = 'COMMENT',
  Dismiss = 'DISMISS',
  Reject = 'REJECT',
  Snooze = 'SNOOZE',
  Undismiss = 'UNDISMISS'
}

export type GQLRiskActionAuditAssignee = GQLRiskActionAuditInterface & {
  __typename?: 'RiskActionAuditAssignee';
  actionTime: Scalars['DateTime'];
  actionType: GQLRiskItemEventActionType;
  comment?: Maybe<Scalars['String']>;
  contentRecordId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  risk?: Maybe<GQLRiskItem>;
  riskId?: Maybe<Scalars['ID']>;
  toIdentity?: Maybe<GQLIdentity>;
};

export type GQLRiskActionAuditComment = GQLRiskActionAuditInterface & {
  __typename?: 'RiskActionAuditComment';
  actionTime: Scalars['DateTime'];
  actionType: GQLRiskItemEventActionType;
  comment?: Maybe<Scalars['String']>;
  contentRecordId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  risk?: Maybe<GQLRiskItem>;
  riskId?: Maybe<Scalars['ID']>;
};

export type GQLRiskActionAuditDismissed = GQLRiskActionAuditInterface & {
  __typename?: 'RiskActionAuditDismissed';
  actionTime: Scalars['DateTime'];
  actionType: GQLRiskItemEventActionType;
  comment?: Maybe<Scalars['String']>;
  contentRecordId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  risk?: Maybe<GQLRiskItem>;
  riskId?: Maybe<Scalars['ID']>;
};

export type GQLRiskActionAuditInterface = {
  actionTime: Scalars['DateTime'];
  actionType: GQLRiskItemEventActionType;
  comment?: Maybe<Scalars['String']>;
  contentRecordId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  risk?: Maybe<GQLRiskItem>;
  riskId?: Maybe<Scalars['ID']>;
};

export type GQLRiskActionAuditSnooze = GQLRiskActionAuditInterface & {
  __typename?: 'RiskActionAuditSnooze';
  actionTime: Scalars['DateTime'];
  actionType: GQLRiskItemEventActionType;
  comment?: Maybe<Scalars['String']>;
  contentRecordId: Scalars['ID'];
  contextOrganization?: Maybe<GQLOrganization>;
  fromUser?: Maybe<GQLUser>;
  fromUserId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  journey?: Maybe<GQLEnrichedJourney>;
  journeyId: Scalars['ID'];
  risk?: Maybe<GQLRiskItem>;
  riskId?: Maybe<Scalars['ID']>;
};

export type GQLRiskActionResult = {
  __typename?: 'RiskActionResult';
  errorMessage?: Maybe<Scalars['String']>;
  organization?: Maybe<GQLOrganization>;
  orgId: Scalars['ID'];
  riskId: Scalars['ID'];
  /** @deprecated It is the old risk item, the actions are async, fetching the updated one is impossible as an action result */
  updatedRiskItem?: Maybe<GQLRiskItem>;
};

export type GQLRiskAssigneeBucket = {
  __typename?: 'RiskAssigneeBucket';
  assignee?: Maybe<GQLAssignee>;
  assigneeId?: Maybe<Scalars['ID']>;
  assigneeType?: Maybe<GQLAssigneeType>;
  totalRisksAssigned?: Maybe<Scalars['Int']>;
  totalRisksDismissed?: Maybe<Scalars['Int']>;
};

export type GQLRiskAssigneeFactSheet = {
  __typename?: 'RiskAssigneeFactSheet';
  journeyId: Scalars['ID'];
  riskAssigneeBuckets?: Maybe<Array<Maybe<GQLRiskAssigneeBucket>>>;
  totalRisks?: Maybe<Scalars['Int']>;
};

export type GQLRiskEventOrderBy = {
  direction?: GQLOrderDirection;
  field: GQLRiskEventOrderByField;
};

export enum GQLRiskEventOrderByField {
  Action = 'ACTION',
  Timestamp = 'TIMESTAMP',
  UserId = 'USER_ID'
}

export type GQLRiskItem = {
  __typename?: 'RiskItem';
  aggregationFieldValues: Array<GQLAggregationFieldValue>;
  aggregationGrouping: Array<GQLAggregationGrouping>;
  assignee?: Maybe<GQLAssignee>;
  assigneeId?: Maybe<Scalars['ID']>;
  assigneeType?: Maybe<GQLAssigneeType>;
  clientOrganization?: Maybe<GQLOrganization>;
  clientOrganizationId: Scalars['ID'];
  contentDispatchTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use 'EnrichedContentFields' instead */
  contentFields: Scalars['Json'];
  contentId: Scalars['ID'];
  contentRecord?: Maybe<GQLContentRecord>;
  contentRecordSerializedCompositeKey: Scalars['String'];
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  createdTime: Scalars['DateTime'];
  dismissReason?: Maybe<Scalars['String']>;
  enrichedContentFields: Array<Maybe<GQLClientFieldValue>>;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  enrichedTimeBasedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  enrichmentFields: Array<GQLEnrichmentField>;
  events: Array<Maybe<GQLRiskItemEvent>>;
  filterExpression?: Maybe<Scalars['Json']>;
  /** The set of filtered fields sorted by requested keys order. Missing field will be returned as null */
  filterFields: Array<Maybe<GQLBaseField>>;
  id: Scalars['ID'];
  instructionCount: Scalars['Int'];
  isAggregated: Scalars['Boolean'];
  isAlerting: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  isDismissed: Scalars['Boolean'];
  isFinalized: Scalars['Boolean'];
  isSnoozed: Scalars['Boolean'];
  journey: GQLEnrichedJourney;
  journeyId: Scalars['ID'];
  lastStatusUpdateTime: Scalars['DateTime'];
  linkedGroups: Array<GQLLinkedGroup>;
  /**
   * The list of the notifications subscriptions for the current risk
   * @deprecated Use 'PaginatedNotificationSubscriptions' instead
   */
  notificationSubscriptions: Array<GQLNotificationSubscription>;
  notionalValue: Scalars['Float'];
  /** The list of the notifications subscriptions for the current risk paginated */
  paginatedNotificationSubscriptions: GQLCappedNotificationSubscriptions;
  permissions: Array<Maybe<Scalars['String']>>;
  productLineId: Scalars['ID'];
  rank: Scalars['Long'];
  resolution?: Maybe<GQLRiskResolution>;
  resolutionId?: Maybe<Scalars['ID']>;
  riskFactor: Scalars['Float'];
  riskProfileId: Scalars['ID'];
  riskProfileItemId: Scalars['ID'];
  riskProfileVersion: Scalars['Int'];
  serializedCompositeKey: Scalars['String'];
  severity: GQLRiskSeverity;
  shareIds: Array<Maybe<Scalars['ID']>>;
  snoozeEnd?: Maybe<Scalars['DateTime']>;
  snoozeStart?: Maybe<Scalars['DateTime']>;
  status: GQLRiskItemStatus;
  systemFields: Array<GQLBaseSystemFieldInterface>;
  timeBasedFilterExpression?: Maybe<Scalars['Json']>;
  titleV2: Scalars['String'];
  vendorText?: Maybe<Scalars['String']>;
  assignCount: Scalars['Int'];
  commentCount: Scalars['Int'];
  comments: Array<GQLComment>;
  expressions: Array<GQLExpressionMeta>;
};


export type GQLRiskItemContentFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};


export type GQLRiskItemEnrichedContentFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};


export type GQLRiskItemEnrichmentFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};


export type GQLRiskItemEventsArgs = {
  after?: Maybe<Scalars['Int']>;
  filter?: Maybe<GQLRiskItemEventActions>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<GQLRiskEventOrderBy>;
};


export type GQLRiskItemFilterFieldsArgs = {
  keys: Array<Scalars['ID']>;
};


export type GQLRiskItemNotificationSubscriptionsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLRiskItemPaginatedNotificationSubscriptionsArgs = {
  contextOrgId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type GQLRiskItemSystemFieldsArgs = {
  isSideBar?: Maybe<Scalars['Boolean']>;
};

export type GQLRiskItemCompositeKey = {
  orgId: Scalars['ID'];
  riskId: Scalars['ID'];
};

export type GQLRiskItemEvent = {
  __typename?: 'RiskItemEvent';
  action?: Maybe<GQLRiskItemEventActions>;
  eventParams?: Maybe<GQLRiskItemEventParameters>;
  timeStamp: Scalars['DateTime'];
  user?: Maybe<GQLUser>;
  userId?: Maybe<Scalars['ID']>;
};

export enum GQLRiskItemEventActions {
  Accept = 'ACCEPT',
  Assign = 'ASSIGN',
  CancelDismiss = 'CANCEL_DISMISS',
  Claim = 'CLAIM',
  CloseoutDismiss = 'CLOSEOUT_DISMISS',
  Comment = 'COMMENT',
  Dismiss = 'DISMISS',
  Instruct = 'INSTRUCT',
  Invalidate = 'INVALIDATE',
  Reject = 'REJECT',
  Share = 'SHARE',
  ShareRiskAdded = 'SHARE_RISK_ADDED',
  ShareTask = 'SHARE_TASK',
  Snooze = 'SNOOZE',
  SystemDismiss = 'SYSTEM_DISMISS',
  Undismiss = 'UNDISMISS',
  VersionDismiss = 'VERSION_DISMISS'
}

export enum GQLRiskItemEventActionType {
  Accept = 'ACCEPT',
  Assign = 'ASSIGN',
  CancelDismiss = 'CANCEL_DISMISS',
  Claim = 'CLAIM',
  Comment = 'COMMENT',
  Dismiss = 'DISMISS',
  Instruct = 'INSTRUCT',
  Invalidate = 'INVALIDATE',
  Reject = 'REJECT',
  Snooze = 'SNOOZE',
  SystemDismiss = 'SYSTEM_DISMISS',
  SystemStaleDismiss = 'SYSTEM_STALE_DISMISS',
  Undismiss = 'UNDISMISS',
  VersionDismiss = 'VERSION_DISMISS'
}

export type GQLRiskItemEventInputParameters = {
  assigneeId?: Maybe<Scalars['ID']>;
  assigneeType?: Maybe<Scalars['String']>;
  comment?: Maybe<GQLCommentInput>;
  dismissResolutionId?: Maybe<Scalars['ID']>;
  snoozePeriodMinutes?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type GQLRiskItemEventParameters = {
  __typename?: 'RiskItemEventParameters';
  assignee?: Maybe<GQLAssignee>;
  assigneeId?: Maybe<Scalars['ID']>;
  assigneeType?: Maybe<GQLAssigneeType>;
  comment?: Maybe<GQLComment>;
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  dismissResolution?: Maybe<GQLRiskResolution>;
  dismissResolutionId?: Maybe<Scalars['ID']>;
  emailAssociated?: Maybe<Scalars['String']>;
  snoozePeriodMinutes?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export enum GQLRiskItemStatus {
  CancelDismissed = 'CANCEL_DISMISSED',
  CloseoutDismissed = 'CLOSEOUT_DISMISSED',
  ManualDismissed = 'MANUAL_DISMISSED',
  Open = 'OPEN',
  SystemDismissed = 'SYSTEM_DISMISSED',
  Untouched = 'UNTOUCHED',
  VersionDismissed = 'VERSION_DISMISSED'
}

export type GQLRiskProfile = {
  __typename?: 'RiskProfile';
  id: Scalars['ID'];
  lastEvaluationStatusTime?: Maybe<Scalars['DateTime']>;
  paginatedRisksDefinition?: Maybe<GQLRiskProfileItemConnection>;
  riskEvaluationStatus?: Maybe<Scalars['String']>;
  /** @deprecated Use PaginatedRisksDefinition connection instead */
  risksDefinition: Array<Maybe<GQLRiskProfileItem>>;
  version: Scalars['Int'];
};


export type GQLRiskProfilePaginatedRisksDefinitionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GQLRiskProfileInput = {
  id?: Scalars['ID'];
  risksDefinition?: Maybe<Array<Maybe<GQLRiskProfileItemInput>>>;
  version?: Scalars['Int'];
};

export type GQLRiskProfileItem = {
  __typename?: 'RiskProfileItem';
  /** @deprecated Deprecated. */
  assignee?: Maybe<GQLAssignee>;
  /** @deprecated Deprecated. */
  assigneeId?: Maybe<Scalars['ID']>;
  /** @deprecated Deprecated. */
  assigneeType?: Maybe<GQLAssigneeType>;
  autoAssignRuleSet?: Maybe<GQLAutoAssignRuleSet>;
  autoAssignRuleSetId?: Maybe<Scalars['ID']>;
  clientExpression?: Maybe<Scalars['Json']>;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  enrichedTimeBasedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  expression?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  /** @deprecated Deprecated. */
  isHandshake?: Maybe<Scalars['Boolean']>;
  metadataExpression?: Maybe<Scalars['Json']>;
  productExpression?: Maybe<Scalars['Json']>;
  riskEvaluatorId: Scalars['ID'];
  riskFactorExpression?: Maybe<Scalars['Json']>;
  riskProfileItemId: Scalars['ID'];
  severity?: Maybe<GQLRiskSeverity>;
  /** @deprecated Deprecated. */
  shareExpression?: Maybe<Scalars['Json']>;
  systemExpression?: Maybe<Scalars['Json']>;
  timeBasedExpression?: Maybe<Scalars['Json']>;
};

/** A connection from an object to a list of objects of type `RiskProfileItem`. */
export type GQLRiskProfileItemConnection = {
  __typename?: 'RiskProfileItemConnection';
  /** A list of all of the edges returned in the connection. */
  edges?: Maybe<Array<Maybe<GQLRiskProfileItemEdge>>>;
  /**
   * A list of all of the objects returned in the connection. This is a convenience
   * field provided for quickly exploring the API; rather than querying for "{
   * edges { node } }" when no edge data is needed, this field can be used instead.
   * Note that when clients like Relay need to fetch the "cursor" field on the edge
   * to enable efficient pagination, this shortcut cannot be used, and the full "{
   * edges { node } } " version should be used instead.
   */
  items?: Maybe<Array<GQLRiskProfileItem>>;
  /** Information to aid in pagination. */
  pageInfo: GQLPageInfo;
  /**
   * A count of the total number of objects in this connection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to `first`, then fetch the total count so it could display
   * "5 of 83", for example. In cases where we employ infinite scrolling or don't
   * have an exact count of entries, this field will return `null`.
   */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `RiskProfileItem`. */
export type GQLRiskProfileItemEdge = {
  __typename?: 'RiskProfileItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: GQLRiskProfileItem;
};

export type GQLRiskProfileItemInput = {
  assigneeId?: Maybe<Scalars['ID']>;
  assigneeType?: Maybe<Scalars['String']>;
  autoAssignRuleSetId?: Maybe<Scalars['ID']>;
  calendarIndicators?: Maybe<Scalars['String']>;
  clientExpression?: Maybe<Scalars['Json']>;
  expression?: Maybe<Scalars['Json']>;
  isHandshake?: Maybe<Scalars['Boolean']>;
  isStale?: Maybe<Scalars['Boolean']>;
  metadataExpression?: Maybe<Scalars['Json']>;
  productExpression?: Maybe<Scalars['Json']>;
  riskFactorExpression?: Maybe<Scalars['Json']>;
  riskProfileItemId?: Scalars['ID'];
  severity?: Maybe<Scalars['String']>;
  systemExpression?: Maybe<Scalars['Json']>;
  timeBasedExpression?: Maybe<Scalars['Json']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
  whereTimeBasedSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLRiskResolution = {
  __typename?: 'RiskResolution';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  organization?: Maybe<GQLOrganization>;
  organizationId?: Maybe<Scalars['ID']>;
};

export type GQLRiskResolutionInput = {
  displayName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
};

export enum GQLRiskSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NoRisk = 'NO_RISK'
}

export type GQLRisksSearchAdvancedQueryParams = {
  assigneeInputParams?: Maybe<GQLAssigneeInputParams>;
  contentTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isAssigned?: Maybe<Scalars['Boolean']>;
  isDismissed?: Maybe<Scalars['Boolean']>;
  isPaired?: Maybe<Scalars['Boolean']>;
  isSnoozed?: Maybe<Scalars['Boolean']>;
};

export enum GQLRisksSearchFilterType {
  All = 'ALL',
  AssignedToMe = 'ASSIGNED_TO_ME',
  Content = 'CONTENT',
  EntitlementView = 'ENTITLEMENT_VIEW',
  Journey = 'JOURNEY',
  PairingCenter = 'PAIRING_CENTER',
  Risk = 'RISK',
  Tag = 'TAG',
  View = 'VIEW'
}

export type GQLRisksSearchQueryParams = {
  filterExpression?: Maybe<Scalars['Json']>;
  filterIds?: Maybe<Array<Scalars['String']>>;
  filterType: GQLRisksSearchFilterType;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLRiskSummaryBucket = {
  __typename?: 'RiskSummaryBucket';
  /** The count of unique content records the risks in given bucket belong to. */
  contentRecordsCount: Scalars['Int'];
  summaries?: Maybe<GQLAggregatedRisks>;
  timeFrame?: Maybe<GQLTimeFrameBucketInterface>;
  totalCount: Scalars['Int'];
  totalUnassignedCount?: Maybe<Scalars['Int']>;
  totalUnassignedValue?: Maybe<Scalars['Float']>;
  totalValue: Scalars['Float'];
  /** The count of unique content records the unassigned risks in given bucket belong to. */
  unassignedContentRecordsCount?: Maybe<Scalars['Int']>;
  unassignedSummaries?: Maybe<GQLAggregatedRisks>;
};

export type GQLRiskSystemField = GQLBaseField & GQLBaseSystemFieldInterface & {
  __typename?: 'RiskSystemField';
  dereferenceValueType?: Maybe<GQLDereferencedValueType>;
  /** @deprecated Use 'Type' instead */
  fieldType: Scalars['String'];
  formatType: GQLFieldType;
  friendlyName: Scalars['String'];
  friendlyValue?: Maybe<Scalars['String']>;
  /** @deprecated Use 'key' instead */
  id: Scalars['ID'];
  isComputed: Scalars['Boolean'];
  isContentRecordGrid: Scalars['Boolean'];
  isFrequency: Scalars['Boolean'];
  isInstructionGrid: Scalars['Boolean'];
  isRiskGrid: Scalars['Boolean'];
  isSideBar: Scalars['Boolean'];
  isSortable: Scalars['Boolean'];
  isTimeFilter: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  systemFieldType: GQLSystemFieldType;
  type: GQLFieldType;
  value?: Maybe<Scalars['CustomObjectGraphType']>;
  label: Scalars['String'];
  comboId: Scalars['String'];
};

export type GQLRiskViewUniverse = {
  __typename?: 'RiskViewUniverse';
  fields: Array<GQLBaseField>;
  /** @deprecated Please use `risksFlatAggregations` */
  frequencies: Array<GQLFrequencyFieldSummary>;
  preferencedFields: Array<GQLFieldDisplayConfiguration>;
  risks: GQLCappedRisks;
  risksAggregations: Array<GQLFrequencyFieldSummary>;
  /** returns flat aggregation fields according to the user preferences order if exists */
  risksFlatAggregations: Array<GQLFrequencyFieldSummary>;
};


export type GQLRiskViewUniverseFieldsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLRiskViewUniverseFrequenciesArgs = {
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLRiskViewUniversePreferencedFieldsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLRiskViewUniverseRisksArgs = {
  after?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLRiskViewUniverseRisksAggregationsArgs = {
  aggregateBy: Array<GQLAggregationFieldConfigInput>;
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  include: Array<GQLFilterFieldConfigInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};


export type GQLRiskViewUniverseRisksFlatAggregationsArgs = {
  contextOrgId: Scalars['ID'];
  filterExpression?: Maybe<Scalars['Json']>;
  include?: Maybe<Array<GQLFilterFieldConfigInput>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLRole = {
  __typename?: 'Role';
  childRoles: Array<Maybe<GQLRole>>;
  childRolesIds: Array<Maybe<Scalars['ID']>>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isReadOnly: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  permissionsIds: Array<Maybe<Scalars['ID']>>;
};

export type GQLRoomData = {
  __typename?: 'RoomData';
  contentRecordId: Scalars['ID'];
  contentTypeId: Scalars['ID'];
};

export type GQLRoomInfo = {
  __typename?: 'RoomInfo';
  creatorUser: GQLUser;
  creatorUserName: Scalars['String'];
  display: Scalars['String'];
  fields: Scalars['Json'];
  lastUpdatedTime: Scalars['DateTime'];
  members: Array<Scalars['String']>;
  roomData: GQLRoomData;
  roomId: Scalars['ID'];
  url: Scalars['String'];
  users: Array<Maybe<GQLUser>>;
};

export type GQLRowGroup = {
  field?: Maybe<Scalars['String']>;
};

export type GQLRuleInterface = {
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  expression?: Maybe<Scalars['Json']>;
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
};

export type GQLRuleSetInterface = {
  contentType: GQLContentType;
  contentTypeId: Scalars['ID'];
  id: Scalars['ID'];
  organization: GQLOrganization;
  orgId: Scalars['ID'];
};

export enum GQLRuleType {
  AutoAssignment = 'AUTO_ASSIGNMENT'
}

export type GQLRuleUniverse = {
  __typename?: 'RuleUniverse';
  rules: Array<GQLRuleInterface>;
};


export type GQLRuleUniverseRulesArgs = {
  ruleType: GQLRuleType;
};

export type GQLScalarValue = {
  __typename?: 'ScalarValue';
  value: Scalars['String'];
};

export type GQLSearchParameters = {
  auditEntities?: Maybe<Array<GQLAuditEntityInput>>;
  changeType?: Maybe<GQLOperationEnum>;
  entityNames?: Maybe<Array<Scalars['String']>>;
  mainEntityIds?: Maybe<Array<Scalars['String']>>;
  mainEntityNames?: Maybe<Array<Scalars['String']>>;
  maxChangeTime?: Maybe<Scalars['Primitive']>;
  minChangeTime?: Maybe<Scalars['Primitive']>;
};

export type GQLSecret = {
  __typename?: 'Secret';
  armor?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  /** The file should be serialized as base64 string */
  privateKey?: Maybe<Scalars['Base64File']>;
  privateKeyFileName: Scalars['String'];
  publicKey?: Maybe<Scalars['String']>;
  type?: Maybe<GQLSecretTypeEnum>;
};

export enum GQLSecretTypeEnum {
  Msoffice = 'MSOFFICE',
  MsofficeXls = 'MSOFFICE_XLS',
  Openpgp = 'OPENPGP',
  Unknown = 'UNKNOWN',
  Zip = 'ZIP'
}

export type GQLSecurityDetails = {
  __typename?: 'SecurityDetails';
  wam?: Maybe<Scalars['Float']>;
  wala?: Maybe<Scalars['Float']>;
  remainingTerm?: Maybe<Scalars['Float']>;
  originalTerm?: Maybe<Scalars['Float']>;
  coupon?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  wac?: Maybe<Scalars['Float']>;
  issueAmount?: Maybe<Scalars['Float']>;
  avgCprm1?: Maybe<Scalars['Float']>;
  avgCprm3?: Maybe<Scalars['Float']>;
  avgCprm6?: Maybe<Scalars['Float']>;
  avgCprm12?: Maybe<Scalars['Float']>;
  avgCprmLife?: Maybe<Scalars['Float']>;
  loanSize?: Maybe<Scalars['Float']>;
  ltvRatio?: Maybe<Scalars['Float']>;
  servicerName?: Maybe<Scalars['String']>;
  cusip?: Maybe<Scalars['String']>;
  issuerName?: Maybe<Scalars['String']>;
  poolNum?: Maybe<Scalars['String']>;
  poolCode?: Maybe<Scalars['String']>;
  productMnemonic?: Maybe<Scalars['String']>;
  agency?: Maybe<Scalars['String']>;
  gnmaType?: Maybe<Scalars['String']>;
  eligibilityFlag?: Maybe<Scalars['String']>;
  maturityDate?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['String']>;
  maximumLoanSize?: Maybe<Scalars['Float']>;
  delinquencyStatus?: Maybe<Scalars['String']>;
  geographyOfLoans?: Maybe<Scalars['String']>;
};

export type GQLSeparateTlsFiles = {
  __typename?: 'SeparateTlsFiles';
  /** The file should be serialized as base64 string */
  cAFile?: Maybe<GQLCertificate>;
  /** The file should be serialized as base64 string */
  certificateFile?: Maybe<GQLCertificate>;
  /** The file should be serialized as base64 string */
  privateKeyFile?: Maybe<GQLCertificate>;
};

export type GQLSession = {
  __typename?: 'Session';
  certificateFileName?: Maybe<Scalars['String']>;
  consumerQueue?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  publisherQueue?: Maybe<Scalars['String']>;
  sasl?: Maybe<Scalars['Boolean']>;
  sessionType?: Maybe<GQLSessionType>;
  ssl?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export enum GQLSessionType {
  Default = 'DEFAULT',
  Eurex = 'EUREX'
}

export type GQLSetDismissNotification = {
  __typename?: 'SetDismissNotification';
  isDismissed: Scalars['Boolean'];
  notificationId: Scalars['ID'];
};

export type GQLSetDismissNotificationInput = {
  isDismissed: Scalars['Boolean'];
  notificationId: Scalars['ID'];
};

export type GQLSetReadNotification = {
  __typename?: 'SetReadNotification';
  isRead: Scalars['Boolean'];
  notificationId: Scalars['ID'];
};

export type GQLSetReadNotificationInput = {
  isRead: Scalars['Boolean'];
  notificationId: Scalars['ID'];
};

export type GQLSettlementItem = {
  __typename?: 'SettlementItem';
  fii: Scalars['String'];
  smcp: Scalars['String'];
  cins?: Maybe<Scalars['String']>;
  isin: Scalars['String'];
  cusip: Scalars['String'];
  mainSecurityId?: Maybe<Scalars['String']>;
  mainSecurityIdTypeCode?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  boxName?: Maybe<Scalars['String']>;
  boxID?: Maybe<Scalars['String']>;
  firmCode?: Maybe<Scalars['String']>;
  currentDate: Scalars['String'];
  boxTotal: Scalars['Float'];
  proprietaryTotal: Scalars['Float'];
  pledgeInTotal: Scalars['Float'];
  pledgeOutTotal: Scalars['Float'];
  euroclearABLTotal: Scalars['Float'];
  dtcBorrowTotal: Scalars['Float'];
  dtcLoanTotal: Scalars['Float'];
  dtcOmnibusTotal: Scalars['Float'];
  tempSegTotal: Scalars['Float'];
  permSegTotal: Scalars['Float'];
  receiveTotal: Scalars['Float'];
  failToReceiveTotal: Scalars['Float'];
  deliverTotal: Scalars['Float'];
  failToDeliverTotal: Scalars['Float'];
  currentDateTotal: Scalars['Float'];
  t0Date?: Maybe<Scalars['String']>;
  t1Date?: Maybe<Scalars['String']>;
  t2Date?: Maybe<Scalars['String']>;
  t3Date?: Maybe<Scalars['String']>;
  t4Date?: Maybe<Scalars['String']>;
  t5Date?: Maybe<Scalars['String']>;
  t1ReceiveTotal: Scalars['Float'];
  t1DeliverTotal: Scalars['Float'];
  t1DateTotal: Scalars['Float'];
  t2ReceiveTotal: Scalars['Float'];
  t2DeliverTotal: Scalars['Float'];
  t2DateTotal: Scalars['Float'];
  t3ReceiveTotal: Scalars['Float'];
  t3DeliverTotal: Scalars['Float'];
  t3DateTotal: Scalars['Float'];
  t4ReceiveTotal: Scalars['Float'];
  t4DeliverTotal: Scalars['Float'];
  t4DateTotal: Scalars['Float'];
  t5ReceiveTotal: Scalars['Float'];
  t5DeliverTotal: Scalars['Float'];
  t5DateTotal: Scalars['Float'];
};

export enum GQLSetupStatus {
  Initial = 'INITIAL',
  Pending = 'PENDING',
  PendingPartition = 'PENDING_PARTITION',
  Ready = 'READY',
  Rejected = 'REJECTED'
}

export type GQLSftpConnector = GQLSftpConnectorIn | GQLSftpConnectorOut;

export type GQLSftpConnectorIn = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'SftpConnectorIn';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLAggregator>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  defaultMessageType?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  fileNameFilterExpression?: Maybe<Scalars['String']>;
  fileProcessHistory?: Maybe<Scalars['Boolean']>;
  fileUploadTimeout?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ignoreHiddenFiles?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  marketData?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  messageTypePrefix?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  privateSshKey?: Maybe<GQLCertificate>;
  protocolType?: Maybe<GQLProtocolType>;
  publicSshKey?: Maybe<Scalars['String']>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  sftpNative?: Maybe<Scalars['Boolean']>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  subfolderPath?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLSftpConnectorOut = GQLBaseConnector & GQLOutboundConnector & {
  __typename?: 'SftpConnectorOut';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  batchOutput?: Maybe<Scalars['Boolean']>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileNameInBatch?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  privateSshKey?: Maybe<GQLCertificate>;
  protocolType?: Maybe<GQLProtocolType>;
  publicSshKey?: Maybe<Scalars['String']>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  sftpLogFile?: Maybe<Scalars['String']>;
  sftpLogLevel?: Maybe<Scalars['String']>;
  sftpNative?: Maybe<Scalars['Boolean']>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLSftpHostedConnector = GQLSftpHostedConnectorIn | GQLSftpHostedConnectorOut;

export type GQLSftpHostedConnectorIn = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'SftpHostedConnectorIn';
  ackDispatch?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregator?: Maybe<GQLAggregator>;
  clientIp: Scalars['String'];
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  defaultMessageType?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLConnectorDirectionType>;
  doneDirectory?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  errorDirectory?: Maybe<Scalars['String']>;
  fileNameFilterExpression?: Maybe<Scalars['String']>;
  fileProcessHistory?: Maybe<Scalars['Boolean']>;
  fileUploadTimeout?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ignoreHiddenFiles?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  legacyAccount?: Maybe<Scalars['Boolean']>;
  marketData?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  messageTypePrefix?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  protocolType?: Maybe<GQLProtocolType>;
  publishBatchSize?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  sftpPublicKey?: Maybe<Scalars['String']>;
  splitter?: Maybe<GQLSplitter>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  subfolderPath?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLSftpHostedConnectorOut = GQLBaseConnector & GQLOutboundConnector & {
  __typename?: 'SftpHostedConnectorOut';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  batchOutput?: Maybe<Scalars['Boolean']>;
  clientIp: Scalars['String'];
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLConnectorDirectionType>;
  fileName?: Maybe<Scalars['String']>;
  fileNameInBatch?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  largeFileMode?: Maybe<Scalars['Boolean']>;
  legacyAccount?: Maybe<Scalars['Boolean']>;
  maxPacketSize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  sftpLogFile?: Maybe<Scalars['String']>;
  sftpLogLevel?: Maybe<Scalars['String']>;
  sftpPublicKey?: Maybe<Scalars['String']>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  subfolderPath?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLSingleValueExpressionMeta = GQLExpressionMeta & {
  __typename?: 'SingleValueExpressionMeta';
  id: Scalars['ID'];
  type: GQLExpressionType;
  field?: Maybe<GQLBaseField>;
  fullLabel: Scalars['String'];
  label: Scalars['String'];
  fieldLabel: Scalars['String'];
  operatorLabel: Scalars['String'];
  operatorSymbol: Scalars['String'];
  operator: GQLOperator;
  valueLabel: Scalars['String'];
  value: Scalars['StringOrIntOrFloatOrBoolean'];
  expression: Scalars['Expression'];
};

export type GQLSmtpConnector = GQLBaseConnector & GQLOutboundConnector & {
  __typename?: 'SmtpConnector';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  attachmentContentType?: Maybe<Scalars['String']>;
  attachmentFileName?: Maybe<Scalars['String']>;
  bccAddress?: Maybe<Scalars['String']>;
  ccAddress?: Maybe<Scalars['String']>;
  connectivityStatus?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  fromAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keepAlive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  reconnectInterval?: Maybe<Scalars['Int']>;
  secret?: Maybe<GQLSecret>;
  setupStatus?: Maybe<GQLSetupStatus>;
  statusNote?: Maybe<Scalars['String']>;
  storeData?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  textHtml?: Maybe<Scalars['String']>;
  toAddress?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GQLSortModel = {
  colId?: Maybe<Scalars['String']>;
  sort?: Maybe<GQLOrderDirection>;
};

export type GQLSourceSystem = {
  __typename?: 'SourceSystem';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
};

export type GQLSourceSystemInput = {
  description: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export enum GQLSourceType {
  Database = 'DATABASE',
  Logs = 'LOGS'
}

export type GQLSpiItem = {
  __typename?: 'SpiItem';
  properties: Array<GQLSpiItemDetails>;
};

export type GQLSpiItemDetails = {
  __typename?: 'SpiItemDetails';
  id: Scalars['String'];
  label: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type GQLSplitter = {
  __typename?: 'Splitter';
  columnDelimiter?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  headerRowCount?: Maybe<Scalars['Int']>;
  includeFullMessage?: Maybe<Scalars['Boolean']>;
  minColumnCount?: Maybe<Scalars['Int']>;
  parameters?: Maybe<Scalars['String']>;
  remove?: Maybe<Scalars['String']>;
  skipHeader?: Maybe<Scalars['Boolean']>;
  suppressOnNoMatch?: Maybe<Scalars['Boolean']>;
  type: GQLSplitterType;
};

export enum GQLSplitterType {
  Csv = 'CSV',
  Flatfile = 'FLATFILE',
  Html = 'HTML',
  Json = 'JSON',
  Liquidnet = 'LIQUIDNET',
  Noop = 'NOOP',
  Swift = 'SWIFT',
  Unknown = 'UNKNOWN',
  Xls = 'XLS',
  Xlsx = 'XLSX',
  Xml = 'XML'
}

export type GQLStartsWithExpression = GQLBinaryEquationExpressionInterface & GQLEquationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'StartsWithExpression';
  isFlat: Scalars['Boolean'];
  left?: Maybe<GQLFieldOperandInterface>;
  right?: Maybe<GQLDataOperandInterface>;
};

export enum GQLState {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED'
}

export enum GQLStatus {
  Completed = 'COMPLETED',
  Replayed = 'REPLAYED',
  Resent = 'RESENT',
  Uploaded = 'UPLOADED'
}


export type GQLSubjectChatData = {
  chatDataType: GQLChatDataType;
  inboundChatData: GQLInboundChatData;
};

export type GQLSubmissionCombo = {
  comboId?: Maybe<Scalars['ID']>;
  nonStdAmount?: Maybe<Scalars['Float']>;
  override: Scalars['Boolean'];
  deliveryType: GQLDeliveryType;
  items: Array<Maybe<GQLComboItems>>;
};

export type GQLSubscriptionNotification = GQLNotification & {
  __typename?: 'SubscriptionNotification';
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  creationTime: Scalars['DateTime'];
  eventType: GQLNotificationEventType;
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  subscription: GQLNotificationSubscription;
  temporaryFilterExpressionId: Scalars['ID'];
  user: GQLUser;
  userId: Scalars['ID'];
};

export type GQLSumAggregationExpression = GQLAggregationExpressionInterface & GQLExpressionInterface & {
  __typename?: 'SumAggregationExpression';
  expression?: Maybe<GQLBinaryEquationExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export enum GQLSummaryType {
  Audit = 'AUDIT',
  Ladder = 'LADDER',
  Layout = 'LAYOUT',
  Riskdetails = 'RISKDETAILS'
}

export type GQLSystemAddress = {
  __typename?: 'SystemAddress';
  cloudStorage?: Maybe<Scalars['Boolean']>;
  connectors?: Maybe<Array<Maybe<GQLSystemAddressConnector>>>;
  contextOrgId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameMapping?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<Maybe<GQLSystemAddressTemplate>>>;
  type?: Maybe<GQLType>;
};

export type GQLSystemAddressConnector = {
  __typename?: 'SystemAddressConnector';
  connectorId?: Maybe<Scalars['String']>;
  connectorName?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type GQLSystemAddressTemplate = {
  __typename?: 'SystemAddressTemplate';
  selected?: Maybe<Scalars['Boolean']>;
  template?: Maybe<GQLTemplateDefinition>;
};

export type GQLSystemConfiguration = {
  __typename?: 'SystemConfiguration';
  kubernetesConfiguration?: Maybe<GQLKubernetesConfiguration>;
};

export type GQLSystemFieldFilter = {
  actionId_eq?: Maybe<Scalars['ID']>;
  actionId_in?: Maybe<Array<Scalars['ID']>>;
  actionId_isNotNull?: Maybe<Scalars['Boolean']>;
  actionId_isNull?: Maybe<Scalars['Boolean']>;
  actionId_ne?: Maybe<Scalars['ID']>;
  actionId_notIn?: Maybe<Array<Scalars['ID']>>;
  actionOwnerOrgId_eq?: Maybe<Scalars['ID']>;
  actionOwnerOrgId_in?: Maybe<Array<Scalars['ID']>>;
  actionOwnerOrgId_isNotNull?: Maybe<Scalars['Boolean']>;
  actionOwnerOrgId_isNull?: Maybe<Scalars['Boolean']>;
  actionOwnerOrgId_ne?: Maybe<Scalars['ID']>;
  actionOwnerOrgId_notIn?: Maybe<Array<Scalars['ID']>>;
  actionOwnerStatus_contains?: Maybe<Scalars['String']>;
  actionOwnerStatus_ends?: Maybe<Scalars['String']>;
  actionOwnerStatus_eq?: Maybe<Scalars['String']>;
  actionOwnerStatus_in?: Maybe<Array<Scalars['String']>>;
  actionOwnerStatus_isNotNull?: Maybe<Scalars['Boolean']>;
  actionOwnerStatus_isNull?: Maybe<Scalars['Boolean']>;
  actionOwnerStatus_ne?: Maybe<Scalars['String']>;
  actionOwnerStatus_notContains?: Maybe<Scalars['String']>;
  actionOwnerStatus_notEnds?: Maybe<Scalars['String']>;
  actionOwnerStatus_notIn?: Maybe<Array<Scalars['String']>>;
  actionOwnerStatus_notStarts?: Maybe<Scalars['String']>;
  actionOwnerStatus_starts?: Maybe<Scalars['String']>;
  additionalInformation_contains?: Maybe<Scalars['String']>;
  additionalInformation_ends?: Maybe<Scalars['String']>;
  additionalInformation_eq?: Maybe<Scalars['String']>;
  additionalInformation_in?: Maybe<Array<Scalars['String']>>;
  additionalInformation_isNotNull?: Maybe<Scalars['Boolean']>;
  additionalInformation_isNull?: Maybe<Scalars['Boolean']>;
  additionalInformation_ne?: Maybe<Scalars['String']>;
  additionalInformation_notContains?: Maybe<Scalars['String']>;
  additionalInformation_notEnds?: Maybe<Scalars['String']>;
  additionalInformation_notIn?: Maybe<Array<Scalars['String']>>;
  additionalInformation_notStarts?: Maybe<Scalars['String']>;
  additionalInformation_starts?: Maybe<Scalars['String']>;
  aggregatedRecordId_eq?: Maybe<Scalars['ID']>;
  aggregatedRecordId_in?: Maybe<Array<Scalars['ID']>>;
  aggregatedRecordId_isNotNull?: Maybe<Scalars['Boolean']>;
  aggregatedRecordId_isNull?: Maybe<Scalars['Boolean']>;
  aggregatedRecordId_ne?: Maybe<Scalars['ID']>;
  aggregatedRecordId_notIn?: Maybe<Array<Scalars['ID']>>;
  aggregatedRecordsCount_eq?: Maybe<Scalars['Int']>;
  aggregatedRecordsCount_ge?: Maybe<Scalars['Int']>;
  aggregatedRecordsCount_gt?: Maybe<Scalars['Int']>;
  aggregatedRecordsCount_in?: Maybe<Array<Scalars['Int']>>;
  aggregatedRecordsCount_isNotNull?: Maybe<Scalars['Boolean']>;
  aggregatedRecordsCount_isNull?: Maybe<Scalars['Boolean']>;
  aggregatedRecordsCount_le?: Maybe<Scalars['Int']>;
  aggregatedRecordsCount_lt?: Maybe<Scalars['Int']>;
  aggregatedRecordsCount_ne?: Maybe<Scalars['Int']>;
  aggregatedRecordsCount_notIn?: Maybe<Array<Scalars['Int']>>;
  assignedBy_eq?: Maybe<Scalars['ID']>;
  assignedBy_in?: Maybe<Array<Scalars['ID']>>;
  assignedBy_isNotNull?: Maybe<Scalars['Boolean']>;
  assignedBy_isNull?: Maybe<Scalars['Boolean']>;
  assignedBy_ne?: Maybe<Scalars['ID']>;
  assignedBy_notIn?: Maybe<Array<Scalars['ID']>>;
  assignedToComposite_eq?: Maybe<Scalars['ID']>;
  assignedToComposite_in?: Maybe<Array<Scalars['ID']>>;
  assignedToComposite_isNotNull?: Maybe<Scalars['Boolean']>;
  assignedToComposite_isNull?: Maybe<Scalars['Boolean']>;
  assignedToComposite_ne?: Maybe<Scalars['ID']>;
  assignedToComposite_notIn?: Maybe<Array<Scalars['ID']>>;
  assignmentTime_eq?: Maybe<Scalars['DateTime']>;
  assignmentTime_ge?: Maybe<Scalars['DateTime']>;
  assignmentTime_gt?: Maybe<Scalars['DateTime']>;
  assignmentTime_isNotNull?: Maybe<Scalars['Boolean']>;
  assignmentTime_isNull?: Maybe<Scalars['Boolean']>;
  assignmentTime_le?: Maybe<Scalars['DateTime']>;
  assignmentTime_lt?: Maybe<Scalars['DateTime']>;
  assignmentTime_ne?: Maybe<Scalars['DateTime']>;
  commentMessages_contains?: Maybe<Scalars['String']>;
  commentMessages_ends?: Maybe<Scalars['String']>;
  commentMessages_eq?: Maybe<Scalars['String']>;
  commentMessages_in?: Maybe<Array<Scalars['String']>>;
  commentMessages_isNotNull?: Maybe<Scalars['Boolean']>;
  commentMessages_isNull?: Maybe<Scalars['Boolean']>;
  commentMessages_ne?: Maybe<Scalars['String']>;
  commentMessages_notContains?: Maybe<Scalars['String']>;
  commentMessages_notEnds?: Maybe<Scalars['String']>;
  commentMessages_notIn?: Maybe<Array<Scalars['String']>>;
  commentMessages_notStarts?: Maybe<Scalars['String']>;
  commentMessages_starts?: Maybe<Scalars['String']>;
  contentDispatchTime_eq?: Maybe<Scalars['DateTime']>;
  contentDispatchTime_ge?: Maybe<Scalars['DateTime']>;
  contentDispatchTime_gt?: Maybe<Scalars['DateTime']>;
  contentDispatchTime_isNotNull?: Maybe<Scalars['Boolean']>;
  contentDispatchTime_isNull?: Maybe<Scalars['Boolean']>;
  contentDispatchTime_le?: Maybe<Scalars['DateTime']>;
  contentDispatchTime_lt?: Maybe<Scalars['DateTime']>;
  contentDispatchTime_ne?: Maybe<Scalars['DateTime']>;
  contentId_eq?: Maybe<Scalars['ID']>;
  contentId_in?: Maybe<Array<Scalars['ID']>>;
  contentId_isNotNull?: Maybe<Scalars['Boolean']>;
  contentId_isNull?: Maybe<Scalars['Boolean']>;
  contentId_ne?: Maybe<Scalars['ID']>;
  contentId_notIn?: Maybe<Array<Scalars['ID']>>;
  contentRecordAge_eq?: Maybe<Scalars['Int']>;
  contentRecordAge_ge?: Maybe<Scalars['Int']>;
  contentRecordAge_gt?: Maybe<Scalars['Int']>;
  contentRecordAge_in?: Maybe<Array<Scalars['Int']>>;
  contentRecordAge_isNotNull?: Maybe<Scalars['Boolean']>;
  contentRecordAge_isNull?: Maybe<Scalars['Boolean']>;
  contentRecordAge_le?: Maybe<Scalars['Int']>;
  contentRecordAge_lt?: Maybe<Scalars['Int']>;
  contentRecordAge_ne?: Maybe<Scalars['Int']>;
  contentRecordAge_notIn?: Maybe<Array<Scalars['Int']>>;
  contentRecordCommentsCount_eq?: Maybe<Scalars['Int']>;
  contentRecordCommentsCount_ge?: Maybe<Scalars['Int']>;
  contentRecordCommentsCount_gt?: Maybe<Scalars['Int']>;
  contentRecordCommentsCount_in?: Maybe<Array<Scalars['Int']>>;
  contentRecordCommentsCount_isNotNull?: Maybe<Scalars['Boolean']>;
  contentRecordCommentsCount_isNull?: Maybe<Scalars['Boolean']>;
  contentRecordCommentsCount_le?: Maybe<Scalars['Int']>;
  contentRecordCommentsCount_lt?: Maybe<Scalars['Int']>;
  contentRecordCommentsCount_ne?: Maybe<Scalars['Int']>;
  contentRecordCommentsCount_notIn?: Maybe<Array<Scalars['Int']>>;
  contentRecordId_eq?: Maybe<Scalars['ID']>;
  contentRecordId_in?: Maybe<Array<Scalars['ID']>>;
  contentRecordId_isNotNull?: Maybe<Scalars['Boolean']>;
  contentRecordId_isNull?: Maybe<Scalars['Boolean']>;
  contentRecordId_ne?: Maybe<Scalars['ID']>;
  contentRecordId_notIn?: Maybe<Array<Scalars['ID']>>;
  contentTypeId_eq?: Maybe<Scalars['ID']>;
  contentTypeId_in?: Maybe<Array<Scalars['ID']>>;
  contentTypeId_isNotNull?: Maybe<Scalars['Boolean']>;
  contentTypeId_isNull?: Maybe<Scalars['Boolean']>;
  contentTypeId_ne?: Maybe<Scalars['ID']>;
  contentTypeId_notIn?: Maybe<Array<Scalars['ID']>>;
  contentTypeName_contains?: Maybe<Scalars['String']>;
  contentTypeName_ends?: Maybe<Scalars['String']>;
  contentTypeName_eq?: Maybe<Scalars['String']>;
  contentTypeName_in?: Maybe<Array<Scalars['String']>>;
  contentTypeName_isNotNull?: Maybe<Scalars['Boolean']>;
  contentTypeName_isNull?: Maybe<Scalars['Boolean']>;
  contentTypeName_ne?: Maybe<Scalars['String']>;
  contentTypeName_notContains?: Maybe<Scalars['String']>;
  contentTypeName_notEnds?: Maybe<Scalars['String']>;
  contentTypeName_notIn?: Maybe<Array<Scalars['String']>>;
  contentTypeName_notStarts?: Maybe<Scalars['String']>;
  contentTypeName_starts?: Maybe<Scalars['String']>;
  contextOrgId_eq?: Maybe<Scalars['Int']>;
  contextOrgId_ge?: Maybe<Scalars['Int']>;
  contextOrgId_gt?: Maybe<Scalars['Int']>;
  contextOrgId_in?: Maybe<Array<Scalars['Int']>>;
  contextOrgId_isNotNull?: Maybe<Scalars['Boolean']>;
  contextOrgId_isNull?: Maybe<Scalars['Boolean']>;
  contextOrgId_le?: Maybe<Scalars['Int']>;
  contextOrgId_lt?: Maybe<Scalars['Int']>;
  contextOrgId_ne?: Maybe<Scalars['Int']>;
  contextOrgId_notIn?: Maybe<Array<Scalars['Int']>>;
  createdTime_eq?: Maybe<Scalars['DateTime']>;
  createdTime_ge?: Maybe<Scalars['DateTime']>;
  createdTime_gt?: Maybe<Scalars['DateTime']>;
  createdTime_isNotNull?: Maybe<Scalars['Boolean']>;
  createdTime_isNull?: Maybe<Scalars['Boolean']>;
  createdTime_le?: Maybe<Scalars['DateTime']>;
  createdTime_lt?: Maybe<Scalars['DateTime']>;
  createdTime_ne?: Maybe<Scalars['DateTime']>;
  destinationOrgIds_eq?: Maybe<Scalars['ID']>;
  destinationOrgIds_in?: Maybe<Array<Scalars['ID']>>;
  destinationOrgIds_isNotNull?: Maybe<Scalars['Boolean']>;
  destinationOrgIds_isNull?: Maybe<Scalars['Boolean']>;
  destinationOrgIds_ne?: Maybe<Scalars['ID']>;
  destinationOrgIds_notIn?: Maybe<Array<Scalars['ID']>>;
  dismissReason_contains?: Maybe<Scalars['String']>;
  dismissReason_ends?: Maybe<Scalars['String']>;
  dismissReason_eq?: Maybe<Scalars['String']>;
  dismissReason_in?: Maybe<Array<Scalars['String']>>;
  dismissReason_isNotNull?: Maybe<Scalars['Boolean']>;
  dismissReason_isNull?: Maybe<Scalars['Boolean']>;
  dismissReason_ne?: Maybe<Scalars['String']>;
  dismissReason_notContains?: Maybe<Scalars['String']>;
  dismissReason_notEnds?: Maybe<Scalars['String']>;
  dismissReason_notIn?: Maybe<Array<Scalars['String']>>;
  dismissReason_notStarts?: Maybe<Scalars['String']>;
  dismissReason_starts?: Maybe<Scalars['String']>;
  dispatch_ActionId_eq?: Maybe<Scalars['ID']>;
  dispatch_ActionId_in?: Maybe<Array<Scalars['ID']>>;
  dispatch_ActionId_isNotNull?: Maybe<Scalars['Boolean']>;
  dispatch_ActionId_isNull?: Maybe<Scalars['Boolean']>;
  dispatch_ActionId_ne?: Maybe<Scalars['ID']>;
  dispatch_ActionId_notIn?: Maybe<Array<Scalars['ID']>>;
  dispatch_InstructionActionId_eq?: Maybe<Scalars['ID']>;
  dispatch_InstructionActionId_in?: Maybe<Array<Scalars['ID']>>;
  dispatch_InstructionActionId_isNotNull?: Maybe<Scalars['Boolean']>;
  dispatch_InstructionActionId_isNull?: Maybe<Scalars['Boolean']>;
  dispatch_InstructionActionId_ne?: Maybe<Scalars['ID']>;
  dispatch_InstructionActionId_notIn?: Maybe<Array<Scalars['ID']>>;
  dispatch_Status_contains?: Maybe<Scalars['String']>;
  dispatch_Status_ends?: Maybe<Scalars['String']>;
  dispatch_Status_eq?: Maybe<Scalars['String']>;
  dispatch_Status_in?: Maybe<Array<Scalars['String']>>;
  dispatch_Status_isNotNull?: Maybe<Scalars['Boolean']>;
  dispatch_Status_isNull?: Maybe<Scalars['Boolean']>;
  dispatch_Status_ne?: Maybe<Scalars['String']>;
  dispatch_Status_notContains?: Maybe<Scalars['String']>;
  dispatch_Status_notEnds?: Maybe<Scalars['String']>;
  dispatch_Status_notIn?: Maybe<Array<Scalars['String']>>;
  dispatch_Status_notStarts?: Maybe<Scalars['String']>;
  dispatch_Status_starts?: Maybe<Scalars['String']>;
  failedDuration_eq?: Maybe<Scalars['Int']>;
  failedDuration_ge?: Maybe<Scalars['Int']>;
  failedDuration_gt?: Maybe<Scalars['Int']>;
  failedDuration_in?: Maybe<Array<Scalars['Int']>>;
  failedDuration_isNotNull?: Maybe<Scalars['Boolean']>;
  failedDuration_isNull?: Maybe<Scalars['Boolean']>;
  failedDuration_le?: Maybe<Scalars['Int']>;
  failedDuration_lt?: Maybe<Scalars['Int']>;
  failedDuration_ne?: Maybe<Scalars['Int']>;
  failedDuration_notIn?: Maybe<Array<Scalars['Int']>>;
  id_eq?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_isNotNull?: Maybe<Scalars['Boolean']>;
  id_isNull?: Maybe<Scalars['Boolean']>;
  id_ne?: Maybe<Scalars['ID']>;
  id_notIn?: Maybe<Array<Scalars['ID']>>;
  insertionTime_eq?: Maybe<Scalars['DateTime']>;
  insertionTime_ge?: Maybe<Scalars['DateTime']>;
  insertionTime_gt?: Maybe<Scalars['DateTime']>;
  insertionTime_isNotNull?: Maybe<Scalars['Boolean']>;
  insertionTime_isNull?: Maybe<Scalars['Boolean']>;
  insertionTime_le?: Maybe<Scalars['DateTime']>;
  insertionTime_lt?: Maybe<Scalars['DateTime']>;
  insertionTime_ne?: Maybe<Scalars['DateTime']>;
  instructionCount_eq?: Maybe<Scalars['Int']>;
  instructionCount_ge?: Maybe<Scalars['Int']>;
  instructionCount_gt?: Maybe<Scalars['Int']>;
  instructionCount_in?: Maybe<Array<Scalars['Int']>>;
  instructionCount_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionCount_isNull?: Maybe<Scalars['Boolean']>;
  instructionCount_le?: Maybe<Scalars['Int']>;
  instructionCount_lt?: Maybe<Scalars['Int']>;
  instructionCount_ne?: Maybe<Scalars['Int']>;
  instructionCount_notIn?: Maybe<Array<Scalars['Int']>>;
  instructionEta_eq?: Maybe<Scalars['DateTime']>;
  instructionEta_ge?: Maybe<Scalars['DateTime']>;
  instructionEta_gt?: Maybe<Scalars['DateTime']>;
  instructionEta_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionEta_isNull?: Maybe<Scalars['Boolean']>;
  instructionEta_le?: Maybe<Scalars['DateTime']>;
  instructionEta_lt?: Maybe<Scalars['DateTime']>;
  instructionEta_ne?: Maybe<Scalars['DateTime']>;
  instructionHasEmails_eq?: Maybe<Scalars['Boolean']>;
  instructionHasEmails_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionHasEmails_isNull?: Maybe<Scalars['Boolean']>;
  instructionHasEmails_ne?: Maybe<Scalars['Boolean']>;
  instructionId_eq?: Maybe<Scalars['ID']>;
  instructionId_in?: Maybe<Array<Scalars['ID']>>;
  instructionId_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionId_isNull?: Maybe<Scalars['Boolean']>;
  instructionId_ne?: Maybe<Scalars['ID']>;
  instructionId_notIn?: Maybe<Array<Scalars['ID']>>;
  instructionRejected_eq?: Maybe<Scalars['Boolean']>;
  instructionRejected_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionRejected_isNull?: Maybe<Scalars['Boolean']>;
  instructionRejected_ne?: Maybe<Scalars['Boolean']>;
  instructionsActionId_eq?: Maybe<Scalars['ID']>;
  instructionsActionId_in?: Maybe<Array<Scalars['ID']>>;
  instructionsActionId_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionsActionId_isNull?: Maybe<Scalars['Boolean']>;
  instructionsActionId_ne?: Maybe<Scalars['ID']>;
  instructionsActionId_notIn?: Maybe<Array<Scalars['ID']>>;
  instructionsAdditionalInformation_contains?: Maybe<Scalars['String']>;
  instructionsAdditionalInformation_ends?: Maybe<Scalars['String']>;
  instructionsAdditionalInformation_eq?: Maybe<Scalars['String']>;
  instructionsAdditionalInformation_in?: Maybe<Array<Scalars['String']>>;
  instructionsAdditionalInformation_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionsAdditionalInformation_isNull?: Maybe<Scalars['Boolean']>;
  instructionsAdditionalInformation_ne?: Maybe<Scalars['String']>;
  instructionsAdditionalInformation_notContains?: Maybe<Scalars['String']>;
  instructionsAdditionalInformation_notEnds?: Maybe<Scalars['String']>;
  instructionsAdditionalInformation_notIn?: Maybe<Array<Scalars['String']>>;
  instructionsAdditionalInformation_notStarts?: Maybe<Scalars['String']>;
  instructionsAdditionalInformation_starts?: Maybe<Scalars['String']>;
  instructionsDestinationOrgIds_eq?: Maybe<Scalars['Int']>;
  instructionsDestinationOrgIds_ge?: Maybe<Scalars['Int']>;
  instructionsDestinationOrgIds_gt?: Maybe<Scalars['Int']>;
  instructionsDestinationOrgIds_in?: Maybe<Array<Scalars['Int']>>;
  instructionsDestinationOrgIds_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionsDestinationOrgIds_isNull?: Maybe<Scalars['Boolean']>;
  instructionsDestinationOrgIds_le?: Maybe<Scalars['Int']>;
  instructionsDestinationOrgIds_lt?: Maybe<Scalars['Int']>;
  instructionsDestinationOrgIds_ne?: Maybe<Scalars['Int']>;
  instructionsDestinationOrgIds_notIn?: Maybe<Array<Scalars['Int']>>;
  instructionsOrganizationId_eq?: Maybe<Scalars['ID']>;
  instructionsOrganizationId_in?: Maybe<Array<Scalars['ID']>>;
  instructionsOrganizationId_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionsOrganizationId_isNull?: Maybe<Scalars['Boolean']>;
  instructionsOrganizationId_ne?: Maybe<Scalars['ID']>;
  instructionsOrganizationId_notIn?: Maybe<Array<Scalars['ID']>>;
  instructionStatuses_contains?: Maybe<Scalars['String']>;
  instructionStatuses_ends?: Maybe<Scalars['String']>;
  instructionStatuses_eq?: Maybe<Scalars['String']>;
  instructionStatuses_in?: Maybe<Array<Scalars['String']>>;
  instructionStatuses_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionStatuses_isNull?: Maybe<Scalars['Boolean']>;
  instructionStatuses_ne?: Maybe<Scalars['String']>;
  instructionStatuses_notContains?: Maybe<Scalars['String']>;
  instructionStatuses_notEnds?: Maybe<Scalars['String']>;
  instructionStatuses_notIn?: Maybe<Array<Scalars['String']>>;
  instructionStatuses_notStarts?: Maybe<Scalars['String']>;
  instructionStatuses_starts?: Maybe<Scalars['String']>;
  instructionsTimestamp_eq?: Maybe<Scalars['DateTime']>;
  instructionsTimestamp_ge?: Maybe<Scalars['DateTime']>;
  instructionsTimestamp_gt?: Maybe<Scalars['DateTime']>;
  instructionsTimestamp_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionsTimestamp_isNull?: Maybe<Scalars['Boolean']>;
  instructionsTimestamp_le?: Maybe<Scalars['DateTime']>;
  instructionsTimestamp_lt?: Maybe<Scalars['DateTime']>;
  instructionsTimestamp_ne?: Maybe<Scalars['DateTime']>;
  instructionTitle_contains?: Maybe<Scalars['String']>;
  instructionTitle_ends?: Maybe<Scalars['String']>;
  instructionTitle_eq?: Maybe<Scalars['String']>;
  instructionTitle_in?: Maybe<Array<Scalars['String']>>;
  instructionTitle_isNotNull?: Maybe<Scalars['Boolean']>;
  instructionTitle_isNull?: Maybe<Scalars['Boolean']>;
  instructionTitle_ne?: Maybe<Scalars['String']>;
  instructionTitle_notContains?: Maybe<Scalars['String']>;
  instructionTitle_notEnds?: Maybe<Scalars['String']>;
  instructionTitle_notIn?: Maybe<Array<Scalars['String']>>;
  instructionTitle_notStarts?: Maybe<Scalars['String']>;
  instructionTitle_starts?: Maybe<Scalars['String']>;
  isAggregated_eq?: Maybe<Scalars['Boolean']>;
  isAggregated_isNotNull?: Maybe<Scalars['Boolean']>;
  isAggregated_isNull?: Maybe<Scalars['Boolean']>;
  isAggregated_ne?: Maybe<Scalars['Boolean']>;
  isAssigned_eq?: Maybe<Scalars['Boolean']>;
  isAssigned_isNotNull?: Maybe<Scalars['Boolean']>;
  isAssigned_isNull?: Maybe<Scalars['Boolean']>;
  isAssigned_ne?: Maybe<Scalars['Boolean']>;
  isCancelled_eq?: Maybe<Scalars['Boolean']>;
  isCancelled_isNotNull?: Maybe<Scalars['Boolean']>;
  isCancelled_isNull?: Maybe<Scalars['Boolean']>;
  isCancelled_ne?: Maybe<Scalars['Boolean']>;
  isCommented_eq?: Maybe<Scalars['Boolean']>;
  isCommented_isNotNull?: Maybe<Scalars['Boolean']>;
  isCommented_isNull?: Maybe<Scalars['Boolean']>;
  isCommented_ne?: Maybe<Scalars['Boolean']>;
  isDismissed_eq?: Maybe<Scalars['Boolean']>;
  isDismissed_isNotNull?: Maybe<Scalars['Boolean']>;
  isDismissed_isNull?: Maybe<Scalars['Boolean']>;
  isDismissed_ne?: Maybe<Scalars['Boolean']>;
  isInstructed_eq?: Maybe<Scalars['Boolean']>;
  isInstructed_isNotNull?: Maybe<Scalars['Boolean']>;
  isInstructed_isNull?: Maybe<Scalars['Boolean']>;
  isInstructed_ne?: Maybe<Scalars['Boolean']>;
  isPaired_eq?: Maybe<Scalars['Boolean']>;
  isPaired_isNotNull?: Maybe<Scalars['Boolean']>;
  isPaired_isNull?: Maybe<Scalars['Boolean']>;
  isPaired_ne?: Maybe<Scalars['Boolean']>;
  isSnoozed_eq?: Maybe<Scalars['Boolean']>;
  isSnoozed_isNotNull?: Maybe<Scalars['Boolean']>;
  isSnoozed_isNull?: Maybe<Scalars['Boolean']>;
  isSnoozed_ne?: Maybe<Scalars['Boolean']>;
  isTerminated_eq?: Maybe<Scalars['Boolean']>;
  isTerminated_isNotNull?: Maybe<Scalars['Boolean']>;
  isTerminated_isNull?: Maybe<Scalars['Boolean']>;
  isTerminated_ne?: Maybe<Scalars['Boolean']>;
  journeyId_eq?: Maybe<Scalars['ID']>;
  journeyId_in?: Maybe<Array<Scalars['ID']>>;
  journeyId_isNotNull?: Maybe<Scalars['Boolean']>;
  journeyId_isNull?: Maybe<Scalars['Boolean']>;
  journeyId_ne?: Maybe<Scalars['ID']>;
  journeyId_notIn?: Maybe<Array<Scalars['ID']>>;
  lastActionOwnerName_contains?: Maybe<Scalars['String']>;
  lastActionOwnerName_ends?: Maybe<Scalars['String']>;
  lastActionOwnerName_eq?: Maybe<Scalars['String']>;
  lastActionOwnerName_in?: Maybe<Array<Scalars['String']>>;
  lastActionOwnerName_isNotNull?: Maybe<Scalars['Boolean']>;
  lastActionOwnerName_isNull?: Maybe<Scalars['Boolean']>;
  lastActionOwnerName_ne?: Maybe<Scalars['String']>;
  lastActionOwnerName_notContains?: Maybe<Scalars['String']>;
  lastActionOwnerName_notEnds?: Maybe<Scalars['String']>;
  lastActionOwnerName_notIn?: Maybe<Array<Scalars['String']>>;
  lastActionOwnerName_notStarts?: Maybe<Scalars['String']>;
  lastActionOwnerName_starts?: Maybe<Scalars['String']>;
  lastCommentedTime_eq?: Maybe<Scalars['DateTime']>;
  lastCommentedTime_ge?: Maybe<Scalars['DateTime']>;
  lastCommentedTime_gt?: Maybe<Scalars['DateTime']>;
  lastCommentedTime_isNotNull?: Maybe<Scalars['Boolean']>;
  lastCommentedTime_isNull?: Maybe<Scalars['Boolean']>;
  lastCommentedTime_le?: Maybe<Scalars['DateTime']>;
  lastCommentedTime_lt?: Maybe<Scalars['DateTime']>;
  lastCommentedTime_ne?: Maybe<Scalars['DateTime']>;
  lastContentRecordComment_contains?: Maybe<Scalars['String']>;
  lastContentRecordComment_ends?: Maybe<Scalars['String']>;
  lastContentRecordComment_eq?: Maybe<Scalars['String']>;
  lastContentRecordComment_in?: Maybe<Array<Scalars['String']>>;
  lastContentRecordComment_isNotNull?: Maybe<Scalars['Boolean']>;
  lastContentRecordComment_isNull?: Maybe<Scalars['Boolean']>;
  lastContentRecordComment_ne?: Maybe<Scalars['String']>;
  lastContentRecordComment_notContains?: Maybe<Scalars['String']>;
  lastContentRecordComment_notEnds?: Maybe<Scalars['String']>;
  lastContentRecordComment_notIn?: Maybe<Array<Scalars['String']>>;
  lastContentRecordComment_notStarts?: Maybe<Scalars['String']>;
  lastContentRecordComment_starts?: Maybe<Scalars['String']>;
  lastInstructedTime_eq?: Maybe<Scalars['DateTime']>;
  lastInstructedTime_ge?: Maybe<Scalars['DateTime']>;
  lastInstructedTime_gt?: Maybe<Scalars['DateTime']>;
  lastInstructedTime_isNotNull?: Maybe<Scalars['Boolean']>;
  lastInstructedTime_isNull?: Maybe<Scalars['Boolean']>;
  lastInstructedTime_le?: Maybe<Scalars['DateTime']>;
  lastInstructedTime_lt?: Maybe<Scalars['DateTime']>;
  lastInstructedTime_ne?: Maybe<Scalars['DateTime']>;
  lastInstructionActionId_eq?: Maybe<Scalars['ID']>;
  lastInstructionActionId_in?: Maybe<Array<Scalars['ID']>>;
  lastInstructionActionId_isNotNull?: Maybe<Scalars['Boolean']>;
  lastInstructionActionId_isNull?: Maybe<Scalars['Boolean']>;
  lastInstructionActionId_ne?: Maybe<Scalars['ID']>;
  lastInstructionActionId_notIn?: Maybe<Array<Scalars['ID']>>;
  lastInstructionActionName_contains?: Maybe<Scalars['String']>;
  lastInstructionActionName_ends?: Maybe<Scalars['String']>;
  lastInstructionActionName_eq?: Maybe<Scalars['String']>;
  lastInstructionActionName_in?: Maybe<Array<Scalars['String']>>;
  lastInstructionActionName_isNotNull?: Maybe<Scalars['Boolean']>;
  lastInstructionActionName_isNull?: Maybe<Scalars['Boolean']>;
  lastInstructionActionName_ne?: Maybe<Scalars['String']>;
  lastInstructionActionName_notContains?: Maybe<Scalars['String']>;
  lastInstructionActionName_notEnds?: Maybe<Scalars['String']>;
  lastInstructionActionName_notIn?: Maybe<Array<Scalars['String']>>;
  lastInstructionActionName_notStarts?: Maybe<Scalars['String']>;
  lastInstructionActionName_starts?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_contains?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_ends?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_eq?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_in?: Maybe<Array<Scalars['String']>>;
  lastInstructionDestinationOrgIdNames_isNotNull?: Maybe<Scalars['Boolean']>;
  lastInstructionDestinationOrgIdNames_isNull?: Maybe<Scalars['Boolean']>;
  lastInstructionDestinationOrgIdNames_ne?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_notContains?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_notEnds?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_notIn?: Maybe<Array<Scalars['String']>>;
  lastInstructionDestinationOrgIdNames_notStarts?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIdNames_starts?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_contains?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_ends?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_eq?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_in?: Maybe<Array<Scalars['String']>>;
  lastInstructionDestinationOrgIds_isNotNull?: Maybe<Scalars['Boolean']>;
  lastInstructionDestinationOrgIds_isNull?: Maybe<Scalars['Boolean']>;
  lastInstructionDestinationOrgIds_ne?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_notContains?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_notEnds?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_notIn?: Maybe<Array<Scalars['String']>>;
  lastInstructionDestinationOrgIds_notStarts?: Maybe<Scalars['String']>;
  lastInstructionDestinationOrgIds_starts?: Maybe<Scalars['String']>;
  lastInstructionStatus_contains?: Maybe<Scalars['String']>;
  lastInstructionStatus_ends?: Maybe<Scalars['String']>;
  lastInstructionStatus_eq?: Maybe<Scalars['String']>;
  lastInstructionStatus_in?: Maybe<Array<Scalars['String']>>;
  lastInstructionStatus_isNotNull?: Maybe<Scalars['Boolean']>;
  lastInstructionStatus_isNull?: Maybe<Scalars['Boolean']>;
  lastInstructionStatus_ne?: Maybe<Scalars['String']>;
  lastInstructionStatus_notContains?: Maybe<Scalars['String']>;
  lastInstructionStatus_notEnds?: Maybe<Scalars['String']>;
  lastInstructionStatus_notIn?: Maybe<Array<Scalars['String']>>;
  lastInstructionStatus_notStarts?: Maybe<Scalars['String']>;
  lastInstructionStatus_starts?: Maybe<Scalars['String']>;
  lastPairedTime_eq?: Maybe<Scalars['DateTime']>;
  lastPairedTime_ge?: Maybe<Scalars['DateTime']>;
  lastPairedTime_gt?: Maybe<Scalars['DateTime']>;
  lastPairedTime_isNotNull?: Maybe<Scalars['Boolean']>;
  lastPairedTime_isNull?: Maybe<Scalars['Boolean']>;
  lastPairedTime_le?: Maybe<Scalars['DateTime']>;
  lastPairedTime_lt?: Maybe<Scalars['DateTime']>;
  lastPairedTime_ne?: Maybe<Scalars['DateTime']>;
  lastReceivedTime_eq?: Maybe<Scalars['DateTime']>;
  lastReceivedTime_ge?: Maybe<Scalars['DateTime']>;
  lastReceivedTime_gt?: Maybe<Scalars['DateTime']>;
  lastReceivedTime_isNotNull?: Maybe<Scalars['Boolean']>;
  lastReceivedTime_isNull?: Maybe<Scalars['Boolean']>;
  lastReceivedTime_le?: Maybe<Scalars['DateTime']>;
  lastReceivedTime_lt?: Maybe<Scalars['DateTime']>;
  lastReceivedTime_ne?: Maybe<Scalars['DateTime']>;
  lastRiskComment_contains?: Maybe<Scalars['String']>;
  lastRiskComment_ends?: Maybe<Scalars['String']>;
  lastRiskComment_eq?: Maybe<Scalars['String']>;
  lastRiskComment_in?: Maybe<Array<Scalars['String']>>;
  lastRiskComment_isNotNull?: Maybe<Scalars['Boolean']>;
  lastRiskComment_isNull?: Maybe<Scalars['Boolean']>;
  lastRiskComment_ne?: Maybe<Scalars['String']>;
  lastRiskComment_notContains?: Maybe<Scalars['String']>;
  lastRiskComment_notEnds?: Maybe<Scalars['String']>;
  lastRiskComment_notIn?: Maybe<Array<Scalars['String']>>;
  lastRiskComment_notStarts?: Maybe<Scalars['String']>;
  lastRiskComment_starts?: Maybe<Scalars['String']>;
  lastStatusUpdateTime_eq?: Maybe<Scalars['DateTime']>;
  lastStatusUpdateTime_ge?: Maybe<Scalars['DateTime']>;
  lastStatusUpdateTime_gt?: Maybe<Scalars['DateTime']>;
  lastStatusUpdateTime_isNotNull?: Maybe<Scalars['Boolean']>;
  lastStatusUpdateTime_isNull?: Maybe<Scalars['Boolean']>;
  lastStatusUpdateTime_le?: Maybe<Scalars['DateTime']>;
  lastStatusUpdateTime_lt?: Maybe<Scalars['DateTime']>;
  lastStatusUpdateTime_ne?: Maybe<Scalars['DateTime']>;
  metadata_ContentLastReceivedTime_eq?: Maybe<Scalars['DateTime']>;
  metadata_ContentLastReceivedTime_ge?: Maybe<Scalars['DateTime']>;
  metadata_ContentLastReceivedTime_gt?: Maybe<Scalars['DateTime']>;
  metadata_ContentLastReceivedTime_isNotNull?: Maybe<Scalars['Boolean']>;
  metadata_ContentLastReceivedTime_isNull?: Maybe<Scalars['Boolean']>;
  metadata_ContentLastReceivedTime_le?: Maybe<Scalars['DateTime']>;
  metadata_ContentLastReceivedTime_lt?: Maybe<Scalars['DateTime']>;
  metadata_ContentLastReceivedTime_ne?: Maybe<Scalars['DateTime']>;
  metadata_InsertionTime_eq?: Maybe<Scalars['DateTime']>;
  metadata_InsertionTime_ge?: Maybe<Scalars['DateTime']>;
  metadata_InsertionTime_gt?: Maybe<Scalars['DateTime']>;
  metadata_InsertionTime_isNotNull?: Maybe<Scalars['Boolean']>;
  metadata_InsertionTime_isNull?: Maybe<Scalars['Boolean']>;
  metadata_InsertionTime_le?: Maybe<Scalars['DateTime']>;
  metadata_InsertionTime_lt?: Maybe<Scalars['DateTime']>;
  metadata_InsertionTime_ne?: Maybe<Scalars['DateTime']>;
  organizationName_contains?: Maybe<Scalars['String']>;
  organizationName_ends?: Maybe<Scalars['String']>;
  organizationName_eq?: Maybe<Scalars['String']>;
  organizationName_in?: Maybe<Array<Scalars['String']>>;
  organizationName_isNotNull?: Maybe<Scalars['Boolean']>;
  organizationName_isNull?: Maybe<Scalars['Boolean']>;
  organizationName_ne?: Maybe<Scalars['String']>;
  organizationName_notContains?: Maybe<Scalars['String']>;
  organizationName_notEnds?: Maybe<Scalars['String']>;
  organizationName_notIn?: Maybe<Array<Scalars['String']>>;
  organizationName_notStarts?: Maybe<Scalars['String']>;
  organizationName_starts?: Maybe<Scalars['String']>;
  orgId_eq?: Maybe<Scalars['ID']>;
  orgId_in?: Maybe<Array<Scalars['ID']>>;
  orgId_isNotNull?: Maybe<Scalars['Boolean']>;
  orgId_isNull?: Maybe<Scalars['Boolean']>;
  orgId_ne?: Maybe<Scalars['ID']>;
  orgId_notIn?: Maybe<Array<Scalars['ID']>>;
  pairingInfoId_eq?: Maybe<Scalars['ID']>;
  pairingInfoId_in?: Maybe<Array<Scalars['ID']>>;
  pairingInfoId_isNotNull?: Maybe<Scalars['Boolean']>;
  pairingInfoId_isNull?: Maybe<Scalars['Boolean']>;
  pairingInfoId_ne?: Maybe<Scalars['ID']>;
  pairingInfoId_notIn?: Maybe<Array<Scalars['ID']>>;
  pairingInfoLinkedGroupId_eq?: Maybe<Scalars['ID']>;
  pairingInfoLinkedGroupId_in?: Maybe<Array<Scalars['ID']>>;
  pairingInfoLinkedGroupId_isNotNull?: Maybe<Scalars['Boolean']>;
  pairingInfoLinkedGroupId_isNull?: Maybe<Scalars['Boolean']>;
  pairingInfoLinkedGroupId_ne?: Maybe<Scalars['ID']>;
  pairingInfoLinkedGroupId_notIn?: Maybe<Array<Scalars['ID']>>;
  pairingInfoPairedTime_eq?: Maybe<Scalars['DateTime']>;
  pairingInfoPairedTime_ge?: Maybe<Scalars['DateTime']>;
  pairingInfoPairedTime_gt?: Maybe<Scalars['DateTime']>;
  pairingInfoPairedTime_isNotNull?: Maybe<Scalars['Boolean']>;
  pairingInfoPairedTime_isNull?: Maybe<Scalars['Boolean']>;
  pairingInfoPairedTime_le?: Maybe<Scalars['DateTime']>;
  pairingInfoPairedTime_lt?: Maybe<Scalars['DateTime']>;
  pairingInfoPairedTime_ne?: Maybe<Scalars['DateTime']>;
  quickFilterId_eq?: Maybe<Scalars['ID']>;
  quickFilterId_in?: Maybe<Array<Scalars['ID']>>;
  quickFilterId_isNotNull?: Maybe<Scalars['Boolean']>;
  quickFilterId_isNull?: Maybe<Scalars['Boolean']>;
  quickFilterId_ne?: Maybe<Scalars['ID']>;
  quickFilterId_notIn?: Maybe<Array<Scalars['ID']>>;
  recordInstructionId_eq?: Maybe<Scalars['ID']>;
  recordInstructionId_in?: Maybe<Array<Scalars['ID']>>;
  recordInstructionId_isNotNull?: Maybe<Scalars['Boolean']>;
  recordInstructionId_isNull?: Maybe<Scalars['Boolean']>;
  recordInstructionId_ne?: Maybe<Scalars['ID']>;
  recordInstructionId_notIn?: Maybe<Array<Scalars['ID']>>;
  recordTitle_contains?: Maybe<Scalars['String']>;
  recordTitle_ends?: Maybe<Scalars['String']>;
  recordTitle_eq?: Maybe<Scalars['String']>;
  recordTitle_in?: Maybe<Array<Scalars['String']>>;
  recordTitle_isNotNull?: Maybe<Scalars['Boolean']>;
  recordTitle_isNull?: Maybe<Scalars['Boolean']>;
  recordTitle_ne?: Maybe<Scalars['String']>;
  recordTitle_notContains?: Maybe<Scalars['String']>;
  recordTitle_notEnds?: Maybe<Scalars['String']>;
  recordTitle_notIn?: Maybe<Array<Scalars['String']>>;
  recordTitle_notStarts?: Maybe<Scalars['String']>;
  recordTitle_starts?: Maybe<Scalars['String']>;
  responseJourneyId_eq?: Maybe<Scalars['ID']>;
  responseJourneyId_in?: Maybe<Array<Scalars['ID']>>;
  responseJourneyId_isNotNull?: Maybe<Scalars['Boolean']>;
  responseJourneyId_isNull?: Maybe<Scalars['Boolean']>;
  responseJourneyId_ne?: Maybe<Scalars['ID']>;
  responseJourneyId_notIn?: Maybe<Array<Scalars['ID']>>;
  riskAge_eq?: Maybe<Scalars['Int']>;
  riskAge_ge?: Maybe<Scalars['Int']>;
  riskAge_gt?: Maybe<Scalars['Int']>;
  riskAge_in?: Maybe<Array<Scalars['Int']>>;
  riskAge_isNotNull?: Maybe<Scalars['Boolean']>;
  riskAge_isNull?: Maybe<Scalars['Boolean']>;
  riskAge_le?: Maybe<Scalars['Int']>;
  riskAge_lt?: Maybe<Scalars['Int']>;
  riskAge_ne?: Maybe<Scalars['Int']>;
  riskAge_notIn?: Maybe<Array<Scalars['Int']>>;
  riskCommentsCount_eq?: Maybe<Scalars['Int']>;
  riskCommentsCount_ge?: Maybe<Scalars['Int']>;
  riskCommentsCount_gt?: Maybe<Scalars['Int']>;
  riskCommentsCount_in?: Maybe<Array<Scalars['Int']>>;
  riskCommentsCount_isNotNull?: Maybe<Scalars['Boolean']>;
  riskCommentsCount_isNull?: Maybe<Scalars['Boolean']>;
  riskCommentsCount_le?: Maybe<Scalars['Int']>;
  riskCommentsCount_lt?: Maybe<Scalars['Int']>;
  riskCommentsCount_ne?: Maybe<Scalars['Int']>;
  riskCommentsCount_notIn?: Maybe<Array<Scalars['Int']>>;
  riskFactor_eq?: Maybe<Scalars['Float']>;
  riskFactor_ge?: Maybe<Scalars['Float']>;
  riskFactor_gt?: Maybe<Scalars['Float']>;
  riskFactor_in?: Maybe<Array<Scalars['Float']>>;
  riskFactor_isNotNull?: Maybe<Scalars['Boolean']>;
  riskFactor_isNull?: Maybe<Scalars['Boolean']>;
  riskFactor_le?: Maybe<Scalars['Float']>;
  riskFactor_lt?: Maybe<Scalars['Float']>;
  riskFactor_ne?: Maybe<Scalars['Float']>;
  riskFactor_notIn?: Maybe<Array<Scalars['Float']>>;
  riskId_eq?: Maybe<Scalars['ID']>;
  riskId_in?: Maybe<Array<Scalars['ID']>>;
  riskId_isNotNull?: Maybe<Scalars['Boolean']>;
  riskId_isNull?: Maybe<Scalars['Boolean']>;
  riskId_ne?: Maybe<Scalars['ID']>;
  riskId_notIn?: Maybe<Array<Scalars['ID']>>;
  routing_InstructionActionId_eq?: Maybe<Scalars['ID']>;
  routing_InstructionActionId_in?: Maybe<Array<Scalars['ID']>>;
  routing_InstructionActionId_isNotNull?: Maybe<Scalars['Boolean']>;
  routing_InstructionActionId_isNull?: Maybe<Scalars['Boolean']>;
  routing_InstructionActionId_ne?: Maybe<Scalars['ID']>;
  routing_InstructionActionId_notIn?: Maybe<Array<Scalars['ID']>>;
  severity_eq?: Maybe<Scalars['Int']>;
  severity_ge?: Maybe<Scalars['Int']>;
  severity_gt?: Maybe<Scalars['Int']>;
  severity_in?: Maybe<Array<Scalars['Int']>>;
  severity_isNotNull?: Maybe<Scalars['Boolean']>;
  severity_isNull?: Maybe<Scalars['Boolean']>;
  severity_le?: Maybe<Scalars['Int']>;
  severity_lt?: Maybe<Scalars['Int']>;
  severity_ne?: Maybe<Scalars['Int']>;
  severity_notIn?: Maybe<Array<Scalars['Int']>>;
  snoozeEnd_eq?: Maybe<Scalars['DateTime']>;
  snoozeEnd_ge?: Maybe<Scalars['DateTime']>;
  snoozeEnd_gt?: Maybe<Scalars['DateTime']>;
  snoozeEnd_isNotNull?: Maybe<Scalars['Boolean']>;
  snoozeEnd_isNull?: Maybe<Scalars['Boolean']>;
  snoozeEnd_le?: Maybe<Scalars['DateTime']>;
  snoozeEnd_lt?: Maybe<Scalars['DateTime']>;
  snoozeEnd_ne?: Maybe<Scalars['DateTime']>;
  snoozeStart_eq?: Maybe<Scalars['DateTime']>;
  snoozeStart_ge?: Maybe<Scalars['DateTime']>;
  snoozeStart_gt?: Maybe<Scalars['DateTime']>;
  snoozeStart_isNotNull?: Maybe<Scalars['Boolean']>;
  snoozeStart_isNull?: Maybe<Scalars['Boolean']>;
  snoozeStart_le?: Maybe<Scalars['DateTime']>;
  snoozeStart_lt?: Maybe<Scalars['DateTime']>;
  snoozeStart_ne?: Maybe<Scalars['DateTime']>;
  status_eq?: Maybe<Scalars['Int']>;
  status_ge?: Maybe<Scalars['Int']>;
  status_gt?: Maybe<Scalars['Int']>;
  status_in?: Maybe<Array<Scalars['Int']>>;
  status_isNotNull?: Maybe<Scalars['Boolean']>;
  status_isNull?: Maybe<Scalars['Boolean']>;
  status_le?: Maybe<Scalars['Int']>;
  status_lt?: Maybe<Scalars['Int']>;
  status_ne?: Maybe<Scalars['Int']>;
  status_notIn?: Maybe<Array<Scalars['Int']>>;
  statuses_contains?: Maybe<Scalars['String']>;
  statuses_ends?: Maybe<Scalars['String']>;
  statuses_eq?: Maybe<Scalars['String']>;
  statuses_in?: Maybe<Array<Scalars['String']>>;
  statuses_isNotNull?: Maybe<Scalars['Boolean']>;
  statuses_isNull?: Maybe<Scalars['Boolean']>;
  statuses_ne?: Maybe<Scalars['String']>;
  statuses_notContains?: Maybe<Scalars['String']>;
  statuses_notEnds?: Maybe<Scalars['String']>;
  statuses_notIn?: Maybe<Array<Scalars['String']>>;
  statuses_notStarts?: Maybe<Scalars['String']>;
  statuses_starts?: Maybe<Scalars['String']>;
  tagId_eq?: Maybe<Scalars['ID']>;
  tagId_in?: Maybe<Array<Scalars['ID']>>;
  tagId_isNotNull?: Maybe<Scalars['Boolean']>;
  tagId_isNull?: Maybe<Scalars['Boolean']>;
  tagId_ne?: Maybe<Scalars['ID']>;
  tagId_notIn?: Maybe<Array<Scalars['ID']>>;
  temporaryExpressionId_eq?: Maybe<Scalars['ID']>;
  temporaryExpressionId_in?: Maybe<Array<Scalars['ID']>>;
  temporaryExpressionId_isNotNull?: Maybe<Scalars['Boolean']>;
  temporaryExpressionId_isNull?: Maybe<Scalars['Boolean']>;
  temporaryExpressionId_ne?: Maybe<Scalars['ID']>;
  temporaryExpressionId_notIn?: Maybe<Array<Scalars['ID']>>;
  title_contains?: Maybe<Scalars['String']>;
  title_ends?: Maybe<Scalars['String']>;
  title_eq?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_isNotNull?: Maybe<Scalars['Boolean']>;
  title_isNull?: Maybe<Scalars['Boolean']>;
  title_ne?: Maybe<Scalars['String']>;
  title_notContains?: Maybe<Scalars['String']>;
  title_notEnds?: Maybe<Scalars['String']>;
  title_notIn?: Maybe<Array<Scalars['String']>>;
  title_notStarts?: Maybe<Scalars['String']>;
  title_starts?: Maybe<Scalars['String']>;
  totalPenaltyEstimate_eq?: Maybe<Scalars['Float']>;
  totalPenaltyEstimate_ge?: Maybe<Scalars['Float']>;
  totalPenaltyEstimate_gt?: Maybe<Scalars['Float']>;
  totalPenaltyEstimate_in?: Maybe<Array<Scalars['Float']>>;
  totalPenaltyEstimate_isNotNull?: Maybe<Scalars['Boolean']>;
  totalPenaltyEstimate_isNull?: Maybe<Scalars['Boolean']>;
  totalPenaltyEstimate_le?: Maybe<Scalars['Float']>;
  totalPenaltyEstimate_lt?: Maybe<Scalars['Float']>;
  totalPenaltyEstimate_ne?: Maybe<Scalars['Float']>;
  totalPenaltyEstimate_notIn?: Maybe<Array<Scalars['Float']>>;
  updateTime_eq?: Maybe<Scalars['DateTime']>;
  updateTime_ge?: Maybe<Scalars['DateTime']>;
  updateTime_gt?: Maybe<Scalars['DateTime']>;
  updateTime_isNotNull?: Maybe<Scalars['Boolean']>;
  updateTime_isNull?: Maybe<Scalars['Boolean']>;
  updateTime_le?: Maybe<Scalars['DateTime']>;
  updateTime_lt?: Maybe<Scalars['DateTime']>;
  updateTime_ne?: Maybe<Scalars['DateTime']>;
  usersEmailsRead_eq?: Maybe<Scalars['Boolean']>;
  usersEmailsRead_isNotNull?: Maybe<Scalars['Boolean']>;
  usersEmailsRead_isNull?: Maybe<Scalars['Boolean']>;
  usersEmailsRead_ne?: Maybe<Scalars['Boolean']>;
  watermarkJourneyId_eq?: Maybe<Scalars['ID']>;
  watermarkJourneyId_in?: Maybe<Array<Scalars['ID']>>;
  watermarkJourneyId_isNotNull?: Maybe<Scalars['Boolean']>;
  watermarkJourneyId_isNull?: Maybe<Scalars['Boolean']>;
  watermarkJourneyId_ne?: Maybe<Scalars['ID']>;
  watermarkJourneyId_notIn?: Maybe<Array<Scalars['ID']>>;
};

export enum GQLSystemFieldId {
  ActionId = 'ActionId',
  ActionOwnerOrgId = 'ActionOwnerOrgId',
  ActionOwnerStatus = 'ActionOwnerStatus',
  AdditionalInformation = 'AdditionalInformation',
  AggregatedRecordId = 'AggregatedRecordId',
  AggregatedRecordsCount = 'AggregatedRecordsCount',
  AssignedBy = 'AssignedBy',
  AssignedToComposite = 'AssignedToComposite',
  AssignmentTime = 'AssignmentTime',
  CommentMessages = 'CommentMessages',
  ContentDispatchTime = 'ContentDispatchTime',
  ContentId = 'ContentId',
  ContentRecordAge = 'ContentRecordAge',
  ContentRecordCommentsCount = 'ContentRecordCommentsCount',
  ContentRecordId = 'ContentRecordId',
  ContentTypeId = 'ContentTypeId',
  ContentTypeName = 'ContentTypeName',
  ContextOrgId = 'ContextOrgId',
  CreatedTime = 'CreatedTime',
  DestinationOrgIds = 'DestinationOrgIds',
  DismissReason = 'DismissReason',
  DispatchActionId = 'Dispatch_ActionId',
  DispatchInstructionActionId = 'Dispatch_InstructionActionId',
  DispatchStatus = 'Dispatch_Status',
  FailedDuration = 'FailedDuration',
  Id = 'Id',
  InsertionTime = 'InsertionTime',
  InstructionCount = 'InstructionCount',
  InstructionEta = 'InstructionEta',
  InstructionHasEmails = 'InstructionHasEmails',
  InstructionId = 'InstructionId',
  InstructionRejected = 'InstructionRejected',
  InstructionsActionId = 'InstructionsActionId',
  InstructionsAdditionalInformation = 'InstructionsAdditionalInformation',
  InstructionsDestinationOrgIds = 'InstructionsDestinationOrgIds',
  InstructionsOrganizationId = 'InstructionsOrganizationId',
  InstructionStatuses = 'InstructionStatuses',
  InstructionsTimestamp = 'InstructionsTimestamp',
  InstructionTitle = 'InstructionTitle',
  IsAggregated = 'IsAggregated',
  IsAssigned = 'IsAssigned',
  IsCancelled = 'IsCancelled',
  IsCommented = 'IsCommented',
  IsDismissed = 'IsDismissed',
  IsInstructed = 'IsInstructed',
  IsPaired = 'IsPaired',
  IsSnoozed = 'IsSnoozed',
  IsTerminated = 'IsTerminated',
  JourneyId = 'JourneyId',
  LastActionOwnerName = 'LastActionOwnerName',
  LastCommentedTime = 'LastCommentedTime',
  LastContentRecordComment = 'LastContentRecordComment',
  LastInstructedTime = 'LastInstructedTime',
  LastInstructionActionId = 'LastInstructionActionId',
  LastInstructionActionName = 'LastInstructionActionName',
  LastInstructionDestinationOrgIdNames = 'LastInstructionDestinationOrgIdNames',
  LastInstructionDestinationOrgIds = 'LastInstructionDestinationOrgIds',
  LastInstructionStatus = 'LastInstructionStatus',
  LastPairedTime = 'LastPairedTime',
  LastReceivedTime = 'LastReceivedTime',
  LastRiskComment = 'LastRiskComment',
  LastStatusUpdateTime = 'LastStatusUpdateTime',
  MetadataContentLastReceivedTime = 'Metadata_ContentLastReceivedTime',
  MetadataInsertionTime = 'Metadata_InsertionTime',
  OrganizationName = 'OrganizationName',
  OrgId = 'OrgId',
  PairingInfoId = 'PairingInfoId',
  PairingInfoLinkedGroupId = 'PairingInfoLinkedGroupId',
  PairingInfoPairedTime = 'PairingInfoPairedTime',
  QuickFilterId = 'QuickFilterId',
  RecordInstructionId = 'RecordInstructionId',
  RecordTitle = 'RecordTitle',
  ResponseJourneyId = 'ResponseJourneyId',
  RiskAge = 'RiskAge',
  RiskCommentsCount = 'RiskCommentsCount',
  RiskFactor = 'RiskFactor',
  RiskId = 'RiskId',
  RoutingInstructionActionId = 'Routing_InstructionActionId',
  Severity = 'Severity',
  SnoozeEnd = 'SnoozeEnd',
  SnoozeStart = 'SnoozeStart',
  Status = 'Status',
  Statuses = 'Statuses',
  TagId = 'TagId',
  TemporaryExpressionId = 'TemporaryExpressionId',
  Title = 'Title',
  TotalPenaltyEstimate = 'TotalPenaltyEstimate',
  UpdateTime = 'UpdateTime',
  UsersEmailsRead = 'UsersEmailsRead',
  WatermarkJourneyId = 'WatermarkJourneyId'
}

export enum GQLSystemFieldInputType {
  Dispatch = 'DISPATCH',
  Metadata = 'METADATA',
  Regular = 'REGULAR',
  Routing = 'ROUTING'
}

export enum GQLSystemFieldKey {
  ComputableSystemFieldCommentMessages = 'ComputableSystemField_CommentMessages',
  ComputableSystemFieldContentRecordAge = 'ComputableSystemField_ContentRecordAge',
  ComputableSystemFieldContentRecordCommentsCount = 'ComputableSystemField_ContentRecordCommentsCount',
  ComputableSystemFieldFailedDuration = 'ComputableSystemField_FailedDuration',
  ComputableSystemFieldInstructionHasEmails = 'ComputableSystemField_InstructionHasEmails',
  ComputableSystemFieldInstructionRejected = 'ComputableSystemField_InstructionRejected',
  ComputableSystemFieldIsCommented = 'ComputableSystemField_IsCommented',
  ComputableSystemFieldIsSnoozed = 'ComputableSystemField_IsSnoozed',
  ComputableSystemFieldJourneyId = 'ComputableSystemField_JourneyId',
  ComputableSystemFieldLastContentRecordComment = 'ComputableSystemField_LastContentRecordComment',
  ComputableSystemFieldLastRiskComment = 'ComputableSystemField_LastRiskComment',
  ComputableSystemFieldQuickFilterId = 'ComputableSystemField_QuickFilterId',
  ComputableSystemFieldRiskAge = 'ComputableSystemField_RiskAge',
  ComputableSystemFieldRiskCommentsCount = 'ComputableSystemField_RiskCommentsCount',
  ComputableSystemFieldTagId = 'ComputableSystemField_TagId',
  ComputableSystemFieldTemporaryExpressionId = 'ComputableSystemField_TemporaryExpressionId',
  ComputableSystemFieldTotalPenaltyEstimate = 'ComputableSystemField_TotalPenaltyEstimate',
  SystemFieldApiDataContentRecordAdditionalInformation = 'SystemField_ApiDataContentRecord_AdditionalInformation',
  SystemFieldApiDataContentRecordAggregatedRecordId = 'SystemField_ApiDataContentRecord_AggregatedRecordId',
  SystemFieldApiDataContentRecordAggregatedRecordsCount = 'SystemField_ApiDataContentRecord_AggregatedRecordsCount',
  SystemFieldApiDataContentRecordContentRecordId = 'SystemField_ApiDataContentRecord_ContentRecordId',
  SystemFieldApiDataContentRecordContentTypeId = 'SystemField_ApiDataContentRecord_ContentTypeId',
  SystemFieldApiDataContentRecordContentTypeName = 'SystemField_ApiDataContentRecord_ContentTypeName',
  SystemFieldApiDataContentRecordId = 'SystemField_ApiDataContentRecord_Id',
  SystemFieldApiDataContentRecordInsertionTime = 'SystemField_ApiDataContentRecord_InsertionTime',
  SystemFieldApiDataContentRecordInstructionCount = 'SystemField_ApiDataContentRecord_InstructionCount',
  SystemFieldApiDataContentRecordInstructionEta = 'SystemField_ApiDataContentRecord_InstructionEta',
  SystemFieldApiDataContentRecordInstructionsActionId = 'SystemField_ApiDataContentRecord_InstructionsActionId',
  SystemFieldApiDataContentRecordInstructionsAdditionalInformation = 'SystemField_ApiDataContentRecord_InstructionsAdditionalInformation',
  SystemFieldApiDataContentRecordInstructionsDestinationOrgIds = 'SystemField_ApiDataContentRecord_InstructionsDestinationOrgIds',
  SystemFieldApiDataContentRecordInstructionsOrganizationId = 'SystemField_ApiDataContentRecord_InstructionsOrganizationId',
  SystemFieldApiDataContentRecordInstructionStatuses = 'SystemField_ApiDataContentRecord_InstructionStatuses',
  SystemFieldApiDataContentRecordIsAggregated = 'SystemField_ApiDataContentRecord_IsAggregated',
  SystemFieldApiDataContentRecordIsCancelled = 'SystemField_ApiDataContentRecord_IsCancelled',
  SystemFieldApiDataContentRecordIsInstructed = 'SystemField_ApiDataContentRecord_IsInstructed',
  SystemFieldApiDataContentRecordIsPaired = 'SystemField_ApiDataContentRecord_IsPaired',
  SystemFieldApiDataContentRecordIsTerminated = 'SystemField_ApiDataContentRecord_IsTerminated',
  SystemFieldApiDataContentRecordJourneyId = 'SystemField_ApiDataContentRecord_JourneyId',
  SystemFieldApiDataContentRecordLastActionOwnerName = 'SystemField_ApiDataContentRecord_LastActionOwnerName',
  SystemFieldApiDataContentRecordLastCommentedTime = 'SystemField_ApiDataContentRecord_LastCommentedTime',
  SystemFieldApiDataContentRecordLastInstructedTime = 'SystemField_ApiDataContentRecord_LastInstructedTime',
  SystemFieldApiDataContentRecordLastInstructionActionId = 'SystemField_ApiDataContentRecord_LastInstructionActionId',
  SystemFieldApiDataContentRecordLastInstructionActionName = 'SystemField_ApiDataContentRecord_LastInstructionActionName',
  SystemFieldApiDataContentRecordLastInstructionDestinationOrgIdNames = 'SystemField_ApiDataContentRecord_LastInstructionDestinationOrgIdNames',
  SystemFieldApiDataContentRecordLastInstructionDestinationOrgIds = 'SystemField_ApiDataContentRecord_LastInstructionDestinationOrgIds',
  SystemFieldApiDataContentRecordLastInstructionStatus = 'SystemField_ApiDataContentRecord_LastInstructionStatus',
  SystemFieldApiDataContentRecordLastPairedTime = 'SystemField_ApiDataContentRecord_LastPairedTime',
  SystemFieldApiDataContentRecordLastReceivedTime = 'SystemField_ApiDataContentRecord_LastReceivedTime',
  SystemFieldApiDataContentRecordMetadataContentLastReceivedTime = 'SystemField_ApiDataContentRecord_Metadata_ContentLastReceivedTime',
  SystemFieldApiDataContentRecordMetadataInsertionTime = 'SystemField_ApiDataContentRecord_Metadata_InsertionTime',
  SystemFieldApiDataContentRecordOrganizationName = 'SystemField_ApiDataContentRecord_OrganizationName',
  SystemFieldApiDataContentRecordOrgId = 'SystemField_ApiDataContentRecord_OrgId',
  SystemFieldApiDataContentRecordPairingInfoId = 'SystemField_ApiDataContentRecord_PairingInfoId',
  SystemFieldApiDataContentRecordPairingInfoPairedTime = 'SystemField_ApiDataContentRecord_PairingInfoPairedTime',
  SystemFieldApiDataContentRecordRecordInstructionId = 'SystemField_ApiDataContentRecord_RecordInstructionId',
  SystemFieldApiDataContentRecordRecordTitle = 'SystemField_ApiDataContentRecord_RecordTitle',
  SystemFieldApiDataContentRecordResponseJourneyId = 'SystemField_ApiDataContentRecord_ResponseJourneyId',
  SystemFieldApiDataContentRecordUpdateTime = 'SystemField_ApiDataContentRecord_UpdateTime',
  SystemFieldApiDataContentRecordWatermarkJourneyId = 'SystemField_ApiDataContentRecord_WatermarkJourneyId',
  SystemFieldApiDataInstructionActionId = 'SystemField_ApiDataInstruction_ActionId',
  SystemFieldApiDataInstructionActionOwnerOrgId = 'SystemField_ApiDataInstruction_ActionOwnerOrgId',
  SystemFieldApiDataInstructionActionOwnerStatus = 'SystemField_ApiDataInstruction_ActionOwnerStatus',
  SystemFieldApiDataInstructionAdditionalInformation = 'SystemField_ApiDataInstruction_AdditionalInformation',
  SystemFieldApiDataInstructionAggregatedRecordId = 'SystemField_ApiDataInstruction_AggregatedRecordId',
  SystemFieldApiDataInstructionAssignedToComposite = 'SystemField_ApiDataInstruction_AssignedToComposite',
  SystemFieldApiDataInstructionContentTypeId = 'SystemField_ApiDataInstruction_ContentTypeId',
  SystemFieldApiDataInstructionContentTypeName = 'SystemField_ApiDataInstruction_ContentTypeName',
  SystemFieldApiDataInstructionContextOrgId = 'SystemField_ApiDataInstruction_ContextOrgId',
  SystemFieldApiDataInstructionDestinationOrgIds = 'SystemField_ApiDataInstruction_DestinationOrgIds',
  SystemFieldApiDataInstructionDispatchActionId = 'SystemField_ApiDataInstruction_Dispatch_ActionId',
  SystemFieldApiDataInstructionDispatchInstructionActionId = 'SystemField_ApiDataInstruction_Dispatch_InstructionActionId',
  SystemFieldApiDataInstructionDispatchStatus = 'SystemField_ApiDataInstruction_Dispatch_Status',
  SystemFieldApiDataInstructionId = 'SystemField_ApiDataInstruction_Id',
  SystemFieldApiDataInstructionInsertionTime = 'SystemField_ApiDataInstruction_InsertionTime',
  SystemFieldApiDataInstructionInstructionEta = 'SystemField_ApiDataInstruction_InstructionEta',
  SystemFieldApiDataInstructionInstructionId = 'SystemField_ApiDataInstruction_InstructionId',
  SystemFieldApiDataInstructionInstructionsActionId = 'SystemField_ApiDataInstruction_InstructionsActionId',
  SystemFieldApiDataInstructionInstructionsAdditionalInformation = 'SystemField_ApiDataInstruction_InstructionsAdditionalInformation',
  SystemFieldApiDataInstructionInstructionsDestinationOrgIds = 'SystemField_ApiDataInstruction_InstructionsDestinationOrgIds',
  SystemFieldApiDataInstructionInstructionsOrganizationId = 'SystemField_ApiDataInstruction_InstructionsOrganizationId',
  SystemFieldApiDataInstructionInstructionStatuses = 'SystemField_ApiDataInstruction_InstructionStatuses',
  SystemFieldApiDataInstructionInstructionTitle = 'SystemField_ApiDataInstruction_InstructionTitle',
  SystemFieldApiDataInstructionJourneyId = 'SystemField_ApiDataInstruction_JourneyId',
  SystemFieldApiDataInstructionLastCommentedTime = 'SystemField_ApiDataInstruction_LastCommentedTime',
  SystemFieldApiDataInstructionLastPairedTime = 'SystemField_ApiDataInstruction_LastPairedTime',
  SystemFieldApiDataInstructionLastReceivedTime = 'SystemField_ApiDataInstruction_LastReceivedTime',
  SystemFieldApiDataInstructionOrganizationName = 'SystemField_ApiDataInstruction_OrganizationName',
  SystemFieldApiDataInstructionOrgId = 'SystemField_ApiDataInstruction_OrgId',
  SystemFieldApiDataInstructionRoutingInstructionActionId = 'SystemField_ApiDataInstruction_Routing_InstructionActionId',
  SystemFieldApiDataInstructionStatuses = 'SystemField_ApiDataInstruction_Statuses',
  SystemFieldApiDataInstructionUpdateTime = 'SystemField_ApiDataInstruction_UpdateTime',
  SystemFieldApiDataInstructionUsersEmailsRead = 'SystemField_ApiDataInstruction_UsersEmailsRead',
  SystemFieldApiDataRiskAdditionalInformation = 'SystemField_ApiDataRisk_AdditionalInformation',
  SystemFieldApiDataRiskAggregatedRecordId = 'SystemField_ApiDataRisk_AggregatedRecordId',
  SystemFieldApiDataRiskAggregatedRecordsCount = 'SystemField_ApiDataRisk_AggregatedRecordsCount',
  SystemFieldApiDataRiskAssignedBy = 'SystemField_ApiDataRisk_AssignedBy',
  SystemFieldApiDataRiskAssignedToComposite = 'SystemField_ApiDataRisk_AssignedToComposite',
  SystemFieldApiDataRiskAssignmentTime = 'SystemField_ApiDataRisk_AssignmentTime',
  SystemFieldApiDataRiskContentDispatchTime = 'SystemField_ApiDataRisk_ContentDispatchTime',
  SystemFieldApiDataRiskContentId = 'SystemField_ApiDataRisk_ContentId',
  SystemFieldApiDataRiskContentTypeId = 'SystemField_ApiDataRisk_ContentTypeId',
  SystemFieldApiDataRiskContentTypeName = 'SystemField_ApiDataRisk_ContentTypeName',
  SystemFieldApiDataRiskCreatedTime = 'SystemField_ApiDataRisk_CreatedTime',
  SystemFieldApiDataRiskDismissReason = 'SystemField_ApiDataRisk_DismissReason',
  SystemFieldApiDataRiskId = 'SystemField_ApiDataRisk_Id',
  SystemFieldApiDataRiskInsertionTime = 'SystemField_ApiDataRisk_InsertionTime',
  SystemFieldApiDataRiskInstructionCount = 'SystemField_ApiDataRisk_InstructionCount',
  SystemFieldApiDataRiskInstructionEta = 'SystemField_ApiDataRisk_InstructionEta',
  SystemFieldApiDataRiskInstructionsActionId = 'SystemField_ApiDataRisk_InstructionsActionId',
  SystemFieldApiDataRiskInstructionsAdditionalInformation = 'SystemField_ApiDataRisk_InstructionsAdditionalInformation',
  SystemFieldApiDataRiskInstructionsDestinationOrgIds = 'SystemField_ApiDataRisk_InstructionsDestinationOrgIds',
  SystemFieldApiDataRiskInstructionsOrganizationId = 'SystemField_ApiDataRisk_InstructionsOrganizationId',
  SystemFieldApiDataRiskInstructionStatuses = 'SystemField_ApiDataRisk_InstructionStatuses',
  SystemFieldApiDataRiskInstructionsTimestamp = 'SystemField_ApiDataRisk_InstructionsTimestamp',
  SystemFieldApiDataRiskIsAggregated = 'SystemField_ApiDataRisk_IsAggregated',
  SystemFieldApiDataRiskIsAssigned = 'SystemField_ApiDataRisk_IsAssigned',
  SystemFieldApiDataRiskIsCancelled = 'SystemField_ApiDataRisk_IsCancelled',
  SystemFieldApiDataRiskIsDismissed = 'SystemField_ApiDataRisk_IsDismissed',
  SystemFieldApiDataRiskIsInstructed = 'SystemField_ApiDataRisk_IsInstructed',
  SystemFieldApiDataRiskIsPaired = 'SystemField_ApiDataRisk_IsPaired',
  SystemFieldApiDataRiskIsTerminated = 'SystemField_ApiDataRisk_IsTerminated',
  SystemFieldApiDataRiskJourneyId = 'SystemField_ApiDataRisk_JourneyId',
  SystemFieldApiDataRiskLastActionOwnerName = 'SystemField_ApiDataRisk_LastActionOwnerName',
  SystemFieldApiDataRiskLastCommentedTime = 'SystemField_ApiDataRisk_LastCommentedTime',
  SystemFieldApiDataRiskLastInstructedTime = 'SystemField_ApiDataRisk_LastInstructedTime',
  SystemFieldApiDataRiskLastInstructionActionId = 'SystemField_ApiDataRisk_LastInstructionActionId',
  SystemFieldApiDataRiskLastInstructionActionName = 'SystemField_ApiDataRisk_LastInstructionActionName',
  SystemFieldApiDataRiskLastInstructionDestinationOrgIdNames = 'SystemField_ApiDataRisk_LastInstructionDestinationOrgIdNames',
  SystemFieldApiDataRiskLastInstructionDestinationOrgIds = 'SystemField_ApiDataRisk_LastInstructionDestinationOrgIds',
  SystemFieldApiDataRiskLastInstructionStatus = 'SystemField_ApiDataRisk_LastInstructionStatus',
  SystemFieldApiDataRiskLastPairedTime = 'SystemField_ApiDataRisk_LastPairedTime',
  SystemFieldApiDataRiskLastReceivedTime = 'SystemField_ApiDataRisk_LastReceivedTime',
  SystemFieldApiDataRiskLastStatusUpdateTime = 'SystemField_ApiDataRisk_LastStatusUpdateTime',
  SystemFieldApiDataRiskOrganizationName = 'SystemField_ApiDataRisk_OrganizationName',
  SystemFieldApiDataRiskOrgId = 'SystemField_ApiDataRisk_OrgId',
  SystemFieldApiDataRiskPairingInfoLinkedGroupId = 'SystemField_ApiDataRisk_PairingInfoLinkedGroupId',
  SystemFieldApiDataRiskPairingInfoPairedTime = 'SystemField_ApiDataRisk_PairingInfoPairedTime',
  SystemFieldApiDataRiskRiskFactor = 'SystemField_ApiDataRisk_RiskFactor',
  SystemFieldApiDataRiskRiskId = 'SystemField_ApiDataRisk_RiskId',
  SystemFieldApiDataRiskSeverity = 'SystemField_ApiDataRisk_Severity',
  SystemFieldApiDataRiskSnoozeEnd = 'SystemField_ApiDataRisk_SnoozeEnd',
  SystemFieldApiDataRiskSnoozeStart = 'SystemField_ApiDataRisk_SnoozeStart',
  SystemFieldApiDataRiskStatus = 'SystemField_ApiDataRisk_Status',
  SystemFieldApiDataRiskTitle = 'SystemField_ApiDataRisk_Title',
  SystemFieldApiDataRiskUpdateTime = 'SystemField_ApiDataRisk_UpdateTime'
}

export type GQLSystemFieldOperand = GQLFieldOperandInterface & GQLValueOperandInterface & {
  __typename?: 'SystemFieldOperand';
  entity?: Maybe<Scalars['String']>;
  field?: Maybe<GQLBaseField>;
  fieldName: Scalars['String'];
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export enum GQLSystemFieldType {
  Dispatch = 'DISPATCH',
  Metadata = 'METADATA',
  Regular = 'REGULAR',
  Routing = 'ROUTING'
}

export type GQLSystemTriggeredNotification = GQLNotification & {
  __typename?: 'SystemTriggeredNotification';
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  creationTime: Scalars['DateTime'];
  eventType: GQLNotificationEventType;
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  user: GQLUser;
  userId: Scalars['ID'];
};

export type GQLTag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<GQLOrganization>;
  organizationId?: Maybe<Scalars['ID']>;
};

export type GQLTagInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
};

export type GQLTatBucketAnalyticsData = {
  __typename?: 'TatBucketAnalyticsData';
  count?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type GQLTatBucketFactSheet = {
  __typename?: 'TatBucketFactSheet';
  journeyId: Scalars['ID'];
  tatBucketDetails: Array<Maybe<GQLTatBucketAnalyticsData>>;
};

export type GQLTemplateDefinition = {
  __typename?: 'TemplateDefinition';
  contextOrgId?: Maybe<Scalars['String']>;
  direction?: Maybe<GQLTemplateDirectionType>;
  draft?: Maybe<Scalars['Base64File']>;
  id?: Maybe<Scalars['String']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  messages?: Maybe<Array<Maybe<GQLTemplateMessage>>>;
  name?: Maybe<Scalars['String']>;
};

export enum GQLTemplateDirectionType {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export type GQLTemplateExpression = {
  __typename?: 'TemplateExpression';
  isCustom?: Maybe<Scalars['Boolean']>;
  jsonConfig?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GQLTemplateField = {
  __typename?: 'TemplateField';
  active?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Maybe<GQLTemplateField>>>;
  custom?: Maybe<Scalars['Boolean']>;
  customValue?: Maybe<Scalars['String']>;
  dateMask?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jsonExpression?: Maybe<Scalars['Base64File']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  transformValue?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GQLTemplateMessage = {
  __typename?: 'TemplateMessage';
  advanced?: Maybe<Scalars['Boolean']>;
  cData?: Maybe<Scalars['String']>;
  contextUserName?: Maybe<Scalars['String']>;
  customFormat?: Maybe<GQLCustomMappingFormat>;
  dataFilteringStatus?: Maybe<GQLState>;
  direction?: Maybe<GQLTemplateDirectionType>;
  enrichmentStatus?: Maybe<GQLState>;
  enrichmentTexts?: Maybe<Array<Maybe<Scalars['String']>>>;
  executionOrder?: Maybe<Scalars['Int']>;
  fieldMappingStatus?: Maybe<GQLState>;
  fields?: Maybe<Array<Maybe<GQLTemplateField>>>;
  fileUploadStatus?: Maybe<GQLState>;
  generalInfoStatus?: Maybe<GQLState>;
  groupingStatus?: Maybe<GQLState>;
  id?: Maybe<Scalars['String']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  messageGroup?: Maybe<GQLMessageGroupGraph>;
  meta?: Maybe<Array<Maybe<GQLTemplateMetaMessage>>>;
  metaDataStatus?: Maybe<GQLState>;
  name?: Maybe<Scalars['String']>;
  onBehalfOrg?: Maybe<GQLOnBehalfOrganization>;
  onBehalfOrgStatus?: Maybe<GQLState>;
  originalData?: Maybe<Scalars['Base64File']>;
  properties?: Maybe<GQLMessageProperty>;
  reviewStatus?: Maybe<GQLState>;
  sourceData?: Maybe<Scalars['Base64File']>;
  targetData?: Maybe<Scalars['Base64File']>;
  templateFileUpload?: Maybe<GQLFileUploadDetail>;
  templateId?: Maybe<Scalars['String']>;
};

export type GQLTemplateMessageDataFile = {
  __typename?: 'TemplateMessageDataFile';
  dataFile?: Maybe<Scalars['Base64File']>;
  fileName?: Maybe<Scalars['String']>;
};

export type GQLTemplateMetaMessage = {
  __typename?: 'TemplateMetaMessage';
  jsonConfig?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type GQLTemporaryExpression = {
  __typename?: 'TemporaryExpression';
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  expression: Scalars['Json'];
  id: Scalars['ID'];
  timeToLiveInDays: Scalars['Int'];
};

export type GQLTimeFrameBucket = GQLTimeFrameBucketInterface & {
  __typename?: 'TimeFrameBucket';
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type GQLTimeFrameBucketInterface = {
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type GQLTimeFrameConfiguration = GQLTimeFrameBucketInterface & {
  __typename?: 'TimeFrameConfiguration';
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type GQLTimeFrameRecordsReceived = {
  __typename?: 'TimeFrameRecordsReceived';
  recordsReceived: Scalars['Int'];
  timeFrame?: Maybe<GQLTimeFrameBucket>;
};

export type GQLTimeZoneInfo = {
  __typename?: 'TimeZoneInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type GQLTlsSecret = {
  __typename?: 'TlsSecret';
  caCertificateAlias?: Maybe<Scalars['String']>;
  generatedCaCertificateFileName?: Maybe<Scalars['String']>;
  generatedPrivateKeyContent?: Maybe<Scalars['String']>;
  generatedPrivateKeyFileName?: Maybe<Scalars['String']>;
  generatedPublicCertificateContent?: Maybe<Scalars['String']>;
  generatedPublicCertificateFileName?: Maybe<Scalars['String']>;
  generateKeys?: Maybe<Scalars['Boolean']>;
  keyStore?: Maybe<GQLKeyStore>;
  originalCaCertificateFileName?: Maybe<Scalars['String']>;
  originalCertificateFileName?: Maybe<Scalars['String']>;
  originalKeyStoreFileName?: Maybe<Scalars['String']>;
  originalMaskedPassword?: Maybe<Scalars['String']>;
  originalPrivateKeyFileName?: Maybe<Scalars['String']>;
  removeFiles?: Maybe<Scalars['Boolean']>;
  separateTlsFiles?: Maybe<GQLSeparateTlsFiles>;
  serverCertificateAlias?: Maybe<Scalars['String']>;
};

export type GQLTrade = {
  __typename?: 'Trade';
  refNumber?: Maybe<Scalars['String']>;
  tradeDate?: Maybe<Scalars['Date']>;
  settlementDate?: Maybe<Scalars['Date']>;
  tradeType?: Maybe<Scalars['String']>;
  tradeQuantity?: Maybe<Scalars['Int']>;
  tradePrice?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  securityId?: Maybe<Scalars['String']>;
  tradeAmount?: Maybe<Scalars['String']>;
  tradeCurrency?: Maybe<Scalars['String']>;
  depoName?: Maybe<Scalars['String']>;
  depoId?: Maybe<Scalars['String']>;
  firmAcctAltId?: Maybe<Scalars['String']>;
  firmAcctName?: Maybe<Scalars['String']>;
  firmAccountId?: Maybe<Scalars['String']>;
  cptyAcctAltid?: Maybe<Scalars['String']>;
  cptyAccountName?: Maybe<Scalars['String']>;
  cptyAccountId?: Maybe<Scalars['String']>;
  firmCode?: Maybe<Scalars['String']>;
  traderId?: Maybe<Scalars['String']>;
  salespersonId?: Maybe<Scalars['String']>;
  fii?: Maybe<Scalars['String']>;
  smcp?: Maybe<Scalars['String']>;
  cins?: Maybe<Scalars['String']>;
  isin?: Maybe<Scalars['String']>;
  cusip?: Maybe<Scalars['String']>;
};

export type GQLTradeFact = {
  __typename?: 'TradeFact';
  tradeId?: Maybe<Scalars['String']>;
  poolRpb?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  securityDetails?: Maybe<GQLSecurityDetails>;
};

export type GQLTradesFilter = {
  transactionDates: Array<Scalars['Date']>;
};

export type GQLTransactionAuditUniverse = {
  __typename?: 'TransactionAuditUniverse';
  auditEntityConfigurations: Array<GQLAuditEntityConfiguration>;
  changeEntries: GQLCappedChangeEntries;
  changeEntry: GQLChangeEntry;
};


export type GQLTransactionAuditUniverseChangeEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GQLDynamicOrderBy>>;
  searchParameters?: Maybe<GQLSearchParameters>;
};


export type GQLTransactionAuditUniverseChangeEntryArgs = {
  changeEntryId: Scalars['ID'];
};

export enum GQLTransmissionType {
  PostTransformer = 'POST_TRANSFORMER',
  PreTransformer = 'PRE_TRANSFORMER'
}

export enum GQLType {
  Destination = 'DESTINATION',
  Source = 'SOURCE',
  Unknown = 'UNKNOWN'
}


export type GQLUnaryEquationExpressionInterface = {
  isFlat: Scalars['Boolean'];
  value?: Maybe<GQLFieldOperandInterface>;
};

export type GQLUnaryLogicalExpressionInterface = {
  expression?: Maybe<GQLExpressionInterface>;
  isFlat: Scalars['Boolean'];
};

export type GQLUpdateActivitySubscriptionDataInput = {
  dispatchingDetails?: Maybe<Array<GQLDispatchingDetailsInput>>;
  id: Scalars['ID'];
  routingEventFilterExpression?: Maybe<Scalars['Json']>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLUpdateContentRecordInput = {
  clientFieldsValues: Array<GQLContentRecordClientFieldsInput>;
  contentRecordCompositeKey: GQLContentRecordCompositeKeyInput;
};

export enum GQLUpdatedFieldState {
  Added = 'ADDED',
  Removed = 'REMOVED',
  Updated = 'UPDATED'
}

export type GQLUpdateEnrichmentActivityInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLUpdateEnrichmentRuleDefinitionsInput = {
  enrichmentExpression: Scalars['Json'];
  filterExpression?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
  timeBasedExpression?: Maybe<Scalars['Json']>;
};

export type GQLUpdateViewInput = {
  childViewIds?: Maybe<Array<Scalars['ID']>>;
  dataSource?: Maybe<GQLViewDataSource>;
  filterExpression?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  parentViewId?: Maybe<Scalars['ID']>;
  subtitle1?: Maybe<Scalars['String']>;
  subtitle2?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  viewDisplayConfiguration?: Maybe<GQLViewDisplayConfigurationInput>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLUser = {
  __typename?: 'User';
  activeExports: Array<GQLExportStatus>;
  firstName?: Maybe<Scalars['String']>;
  groupIds: Array<Maybe<Scalars['ID']>>;
  groups: Array<Maybe<GQLUsersGroup>>;
  id: Scalars['ID'];
  imageId?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  isChatUser: Scalars['Boolean'];
  jobTitle?: Maybe<Scalars['String']>;
  lastLoginTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Always returns 'null' */
  lastLogoutTime?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  layout: Array<GQLUserLayoutItem>;
  navigationMenuPermissionIds: Array<Maybe<Scalars['ID']>>;
  navigationMenuPermissions: Array<GQLNavigationMenuPermission>;
  notifications: GQLCappedNotification;
  notificationSettings: Array<GQLNotificationSettingsEventData>;
  organizationIds: Array<Maybe<Scalars['ID']>>;
  organizations?: Maybe<Array<Maybe<GQLOrganization>>>;
  /** The list of the notifications subscriptions for the current user paginated */
  paginatedNotificationSubscriptions: GQLCappedNotificationSubscriptions;
  permissionIds: Array<Maybe<Scalars['String']>>;
  permissionsInOrganizations?: Maybe<Array<Maybe<GQLUserPermissionInOrganizations>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  roleIds: Array<Maybe<Scalars['ID']>>;
  rolesInOrganizations?: Maybe<Array<Maybe<GQLUserRoleInOrganizationsGraphType>>>;
  skypeUser?: Maybe<Scalars['String']>;
  ssoIssuer?: Maybe<Scalars['String']>;
  ssoOrganization?: Maybe<GQLOrganization>;
  ssoOrgId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  timeZoneInfo?: Maybe<GQLTimeZoneInfo>;
  timeZoneInfoId?: Maybe<Scalars['ID']>;
  userEntitlements: Array<Maybe<GQLUserEntitlement>>;
  userName: Scalars['String'];
  userNavigationMenuPermissions: Array<GQLUserNavigationMenuPermission>;
  userPermissions: Array<Maybe<GQLUserPermission>>;
  userPreferences?: Maybe<Array<GQLUserPreference>>;
  userRoles: Array<Maybe<GQLUserRole>>;
  name: Scalars['String'];
};


export type GQLUserActiveExportsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLUserLastLoginTimeArgs = {
  considerCurrent?: Maybe<Scalars['Boolean']>;
};


export type GQLUserLayoutArgs = {
  orgId: Scalars['ID'];
};


export type GQLUserNavigationMenuPermissionsArgs = {
  contextOrgId?: Maybe<Scalars['ID']>;
};


export type GQLUserNotificationsArgs = {
  contextOrgId: Scalars['ID'];
  isDismissed?: Maybe<Scalars['Boolean']>;
  isRead?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderDirection?: Maybe<GQLOrderDirection>;
};


export type GQLUserNotificationSettingsArgs = {
  contextOrgId: Scalars['ID'];
};


export type GQLUserPaginatedNotificationSubscriptionsArgs = {
  contextOrgId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type GQLUserUserEntitlementsArgs = {
  entitlementOrgId?: Maybe<Scalars['ID']>;
};


export type GQLUserUserPreferencesArgs = {
  key?: Maybe<Scalars['ID']>;
};

export type GQLUserEntitlement = GQLEntitlement & {
  __typename?: 'UserEntitlement';
  creationTime?: Maybe<Scalars['DateTime']>;
  entitlementRule: GQLEntitlementRule;
  entitlementRuleId: Scalars['ID'];
  instructionActionsPermissions: Array<Scalars['String']>;
  isGlobal: Scalars['Boolean'];
  key: Scalars['ID'];
  lastEditingUser?: Maybe<GQLUser>;
  lastEditingUserId?: Maybe<Scalars['ID']>;
  user: GQLUser;
  userId: Scalars['ID'];
};

export type GQLUserEntitlementInput = {
  entitlementRuleId: Scalars['ID'];
  instructionActionsPermissions?: Maybe<Array<Scalars['String']>>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  key?: Scalars['ID'];
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GQLUserInput = {
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  navigationMenuPermissionIds?: Maybe<Array<Scalars['ID']>>;
  organizationIds?: Maybe<Array<Scalars['ID']>>;
  permissionIds?: Maybe<Array<Scalars['ID']>>;
  phoneNumber?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['ID']>>;
  skypeUser?: Maybe<Scalars['String']>;
  ssoOrgId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  timeZoneInfoId?: Maybe<Scalars['ID']>;
  userName?: Maybe<Scalars['String']>;
};

export type GQLUserLayoutItem = {
  __typename?: 'UserLayoutItem';
  layoutItemType: GQLLayoutItemType;
  order: Scalars['Int'];
  view: GQLView;
  viewId: Scalars['ID'];
};

export type GQLUserNavigationMenuPermission = {
  __typename?: 'UserNavigationMenuPermission';
  navigationMenuPermissionName: GQLNavigationMenuPermission;
  organization?: Maybe<GQLOrganization>;
  organizationId?: Maybe<Scalars['ID']>;
};

export type GQLUserPermission = {
  __typename?: 'UserPermission';
  organization?: Maybe<GQLOrganization>;
  organizationId?: Maybe<Scalars['ID']>;
  permissionId: Scalars['ID'];
};

export type GQLUserPermissionInOrganizations = {
  __typename?: 'UserPermissionInOrganizations';
  organizationIds: Array<Maybe<Scalars['ID']>>;
  organizations: Array<Maybe<GQLOrganization>>;
  permissionName?: Maybe<Scalars['String']>;
  user?: Maybe<GQLUser>;
  userId: Scalars['ID'];
};

export type GQLUserPreference = {
  __typename?: 'UserPreference';
  key: Scalars['ID'];
  user: GQLUser;
  userId: Scalars['ID'];
  value: Scalars['String'];
};

export type GQLUserPreferenceInput = {
  key: Scalars['ID'];
  value: Scalars['String'];
};

export type GQLUserRole = {
  __typename?: 'UserRole';
  organization?: Maybe<GQLOrganization>;
  organizationId?: Maybe<Scalars['ID']>;
  role?: Maybe<GQLRole>;
  roleId: Scalars['ID'];
};

export type GQLUserRoleInOrganizationsGraphType = {
  __typename?: 'UserRoleInOrganizationsGraphType';
  organizationIds: Array<Maybe<Scalars['ID']>>;
  organizations?: Maybe<Array<Maybe<GQLOrganization>>>;
  roleName?: Maybe<Scalars['String']>;
  user?: Maybe<GQLUser>;
  userId: Scalars['ID'];
};

export type GQLUsersGroup = {
  __typename?: 'UsersGroup';
  clientOrganization?: Maybe<GQLOrganization>;
  description?: Maybe<Scalars['String']>;
  groupEntitlements: Array<Maybe<GQLUsersGroupEntitlement>>;
  id: Scalars['ID'];
  isInternalGroup: Scalars['Boolean'];
  name: Scalars['String'];
  orgId: Scalars['ID'];
  userIds: Array<Maybe<Scalars['ID']>>;
  users: Array<Maybe<GQLUser>>;
};

export type GQLUsersGroupEntitlement = GQLEntitlement & {
  __typename?: 'UsersGroupEntitlement';
  creationTime?: Maybe<Scalars['DateTime']>;
  entitlementRule: GQLEntitlementRule;
  entitlementRuleId: Scalars['ID'];
  instructionActionsPermissions: Array<Scalars['String']>;
  isGlobal: Scalars['Boolean'];
  key: Scalars['ID'];
  lastEditingUser?: Maybe<GQLUser>;
  lastEditingUserId?: Maybe<Scalars['ID']>;
  organization: GQLOrganization;
  usersGroup: GQLUsersGroup;
  usersGroupId: Scalars['ID'];
  usersGroupOrgId: Scalars['ID'];
};

export type GQLUsersGroupEntitlementInput = {
  entitlementRuleId: Scalars['ID'];
  instructionActionsPermissions?: Maybe<Array<Scalars['String']>>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  key?: Scalars['ID'];
  usersGroupId: Scalars['ID'];
};

export type GQLUsersGroupInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  isInternalGroup: Scalars['Boolean'];
  name: Scalars['String'];
  orgId: Scalars['ID'];
  userIds?: Maybe<Array<Scalars['ID']>>;
};

export type GQLValidationCombo = {
  comboId?: Maybe<Scalars['ID']>;
  nonStdAmount?: Maybe<Scalars['Float']>;
  items: Array<Maybe<GQLComboItems>>;
};

export type GQLValueOperandInterface = {
  /** Prefer using implementers extended fields */
  leafValue?: Maybe<Scalars['Primitive']>;
};

export type GQLVendor = {
  __typename?: 'Vendor';
  companyLocations: Array<Maybe<Scalars['String']>>;
  customersSupportServiceHours?: Maybe<Scalars['String']>;
  customerSupportEmails: Array<Maybe<Scalars['String']>>;
  customerSupportPhoneNumbers: Array<Maybe<Scalars['String']>>;
  organization: GQLOrganization;
  orgId: Scalars['ID'];
  productLineIds: Array<Maybe<Scalars['ID']>>;
  productLines: Array<Maybe<GQLProductLine>>;
  salesEmail?: Maybe<Scalars['String']>;
  salesPhoneNumber?: Maybe<Scalars['String']>;
};

export type GQLVendorInput = {
  companyLocations?: Maybe<Array<Scalars['String']>>;
  customersSupportServiceHours?: Maybe<Scalars['String']>;
  customerSupportEmails?: Maybe<Array<Scalars['String']>>;
  customerSupportPhoneNumbers?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  legalEntityIdentifiers?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  productLineIds?: Maybe<Array<Scalars['ID']>>;
  salesEmail?: Maybe<Scalars['String']>;
  salesPhoneNumber?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type GQLVideo = {
  __typename?: 'Video';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type GQLView = {
  __typename?: 'View';
  ancestorViewIds: Array<Scalars['ID']>;
  ancestorViews: Array<GQLView>;
  childViewIds: Array<Scalars['ID']>;
  childViews: Array<GQLView>;
  contentRecordViewUniverse: GQLContentRecordViewUniverse;
  dataSource: GQLDataSource;
  descendantViewIds: Array<Scalars['ID']>;
  descendantViews: Array<GQLView>;
  enrichedFilterExpression?: Maybe<GQLEnrichedFilterExpression>;
  filterExpression?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  instructionViewUniverse: GQLInstructionViewUniverse;
  isDefault: Scalars['Boolean'];
  isSystemView: Scalars['Boolean'];
  lastEditingUser?: Maybe<GQLUser>;
  lastEditingUserId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organization?: Maybe<GQLOrganization>;
  organizationId?: Maybe<Scalars['ID']>;
  parentView?: Maybe<GQLView>;
  parentViewId?: Maybe<Scalars['ID']>;
  quickFilters: Array<GQLQuickFilter>;
  riskViewUniverse: GQLRiskViewUniverse;
  rootView?: Maybe<GQLView>;
  /** when root view is null, the root configuration will be the view's configuration */
  rootViewDisplayConfiguration: GQLViewDisplayConfiguration;
  /** view without ancestors will be it's own root */
  rootViewId: Scalars['ID'];
  subtitle1?: Maybe<Scalars['String']>;
  subtitle2?: Maybe<Scalars['String']>;
  /** @deprecated Please use SuggestCompletionV2 */
  suggestCompletion: Array<GQLCompletionSuggestion>;
  suggestCompletionV2: Array<GQLFieldValueCompletionSuggestion>;
  title: Scalars['String'];
  viewDisplayConfiguration: GQLViewDisplayConfiguration;
  viewType: GQLViewType;
};


export type GQLViewSuggestCompletionArgs = {
  contextOrgId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  searchPattern: Scalars['String'];
};


export type GQLViewSuggestCompletionV2Args = {
  contextOrgId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  searchPattern: Scalars['String'];
};

export type GQLViewContentRecordDisplayConfiguration = GQLViewDisplayConfigurationInterface & {
  __typename?: 'ViewContentRecordDisplayConfiguration';
  aggregateBy: Array<GQLAggregationUserPreferencesFieldConfig>;
  defaultFields: Array<GQLFieldDisplayConfiguration>;
  defaultFrequencyFields: Array<GQLFieldDisplayConfiguration>;
  groupBy: Array<GQLGroupingFieldConfig>;
  isPairFilterEnabled: Scalars['Boolean'];
};

export type GQLViewContentRecordDisplayConfigurationInput = {
  aggregateBy?: Maybe<Array<GQLAggregationFieldConfigInput>>;
  defaultFields?: Maybe<Array<GQLFieldDisplayConfigurationInput>>;
  defaultFrequencyFields?: Maybe<Array<GQLFieldDisplayConfigurationInput>>;
  groupBy?: Maybe<Array<GQLGroupingFieldConfigInput>>;
};

export enum GQLViewDataSource {
  ContextAndSharingOrgs = 'CONTEXT_AND_SHARING_ORGS',
  ContextOrg = 'CONTEXT_ORG',
  SharingOrgs = 'SHARING_ORGS'
}

export type GQLViewDisplayConfiguration = {
  __typename?: 'ViewDisplayConfiguration';
  defaultName?: Maybe<Scalars['String']>;
  viewContentRecordDisplayConfiguration: GQLViewContentRecordDisplayConfiguration;
  viewInstructionDisplayConfiguration: GQLViewInstructionDisplayConfiguration;
  viewRiskDisplayConfiguration: GQLViewRiskDisplayConfiguration;
};

export type GQLViewDisplayConfigurationInput = {
  viewContentRecordDisplayConfiguration?: Maybe<GQLViewContentRecordDisplayConfigurationInput>;
  viewId: Scalars['ID'];
  viewInstructionDisplayConfiguration?: Maybe<GQLViewInstructionDisplayConfigurationInput>;
  viewRiskDisplayConfiguration?: Maybe<GQLViewRiskDisplayConfigurationInput>;
};

export type GQLViewDisplayConfigurationInterface = {
  aggregateBy: Array<GQLAggregationUserPreferencesFieldConfig>;
  defaultFields: Array<GQLFieldDisplayConfiguration>;
  defaultFrequencyFields: Array<GQLFieldDisplayConfiguration>;
  groupBy: Array<GQLGroupingFieldConfig>;
};

export type GQLViewInput = {
  childViewIds?: Maybe<Array<Scalars['ID']>>;
  dataSource?: Maybe<GQLViewDataSource>;
  filterExpression?: Maybe<Scalars['Json']>;
  id?: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  parentViewId?: Maybe<Scalars['ID']>;
  subtitle1?: Maybe<Scalars['String']>;
  subtitle2?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  viewDisplayConfiguration?: Maybe<GQLViewDisplayConfigurationInput>;
  whereSystemField?: Maybe<GQLSystemFieldFilter>;
};

export type GQLViewInstructionDisplayConfiguration = GQLViewDisplayConfigurationInterface & {
  __typename?: 'ViewInstructionDisplayConfiguration';
  aggregateBy: Array<GQLAggregationUserPreferencesFieldConfig>;
  defaultFields: Array<GQLFieldDisplayConfiguration>;
  defaultFrequencyFields: Array<GQLFieldDisplayConfiguration>;
  groupBy: Array<GQLGroupingFieldConfig>;
};

export type GQLViewInstructionDisplayConfigurationInput = {
  aggregateBy?: Maybe<Array<GQLAggregationFieldConfigInput>>;
  defaultFields?: Maybe<Array<GQLFieldDisplayConfigurationInput>>;
  defaultFrequencyFields?: Maybe<Array<GQLFieldDisplayConfigurationInput>>;
  groupBy?: Maybe<Array<GQLGroupingFieldConfigInput>>;
};

export type GQLViewNotification = GQLNotification & {
  __typename?: 'ViewNotification';
  contextOrg?: Maybe<GQLOrganization>;
  contextOrgId?: Maybe<Scalars['ID']>;
  creationTime: Scalars['DateTime'];
  eventType: GQLNotificationEventType;
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  user: GQLUser;
  userId: Scalars['ID'];
  viewId: Scalars['ID'];
};

export type GQLViewRiskDisplayConfiguration = GQLViewDisplayConfigurationInterface & {
  __typename?: 'ViewRiskDisplayConfiguration';
  aggregateBy: Array<GQLAggregationUserPreferencesFieldConfig>;
  defaultFields: Array<GQLFieldDisplayConfiguration>;
  defaultFrequencyFields: Array<GQLFieldDisplayConfiguration>;
  groupBy: Array<GQLGroupingFieldConfig>;
  isAnalyticsEnabled: Scalars['Boolean'];
  isAssignFilterEnabled: Scalars['Boolean'];
  isBubblesEnabled: Scalars['Boolean'];
  isContentGridEnabled: Scalars['Boolean'];
  isFrequenciesEnabled: Scalars['Boolean'];
  isRisksFeedEnabled: Scalars['Boolean'];
  isStreamEnabled: Scalars['Boolean'];
};

export type GQLViewRiskDisplayConfigurationInput = {
  aggregateBy?: Maybe<Array<GQLAggregationFieldConfigInput>>;
  defaultFields?: Maybe<Array<GQLFieldDisplayConfigurationInput>>;
  defaultFrequencyFields?: Maybe<Array<GQLFieldDisplayConfigurationInput>>;
  groupBy?: Maybe<Array<GQLGroupingFieldConfigInput>>;
};

export enum GQLViewSearchFilterTypeEnum {
  All = 'ALL',
  Custom = 'CUSTOM',
  DefaultView = 'DEFAULT_VIEW',
  System = 'SYSTEM'
}

export enum GQLViewType {
  All = 'ALL',
  Entitlement = 'ENTITLEMENT',
  Journey = 'JOURNEY',
  PairingCenter = 'PAIRING_CENTER',
  Tag = 'TAG',
  View = 'VIEW'
}

export type GQLViewUniverse = {
  __typename?: 'ViewUniverse';
  view: GQLView;
  views: Array<GQLView>;
};


export type GQLViewUniverseViewArgs = {
  id: Scalars['ID'];
};


export type GQLViewUniverseViewsArgs = {
  filterByViewTypes?: Maybe<Array<GQLViewType>>;
  filterType?: Maybe<GQLViewSearchFilterTypeEnum>;
};

export type GQLWebConnector = GQLBaseConnector & GQLInboundConnector & {
  __typename?: 'WebConnector';
  active: Scalars['Boolean'];
  additionalParams?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectivityStatus?: Maybe<Scalars['String']>;
  connectorParams?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contextOrgId: Scalars['String'];
  contextUserName: Scalars['String'];
  datasourcePassword?: Maybe<Scalars['String']>;
  datasourceUrl?: Maybe<Scalars['String']>;
  datasourceUsername?: Maybe<Scalars['String']>;
  dupCheckEnabled?: Maybe<Scalars['Boolean']>;
  dupCheckHistorySize?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  messageVersion?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parties?: Maybe<Scalars['String']>;
  partition?: Maybe<GQLPartition>;
  password?: Maybe<Scalars['String']>;
  pollInterval?: Maybe<Scalars['Int']>;
  protocol?: Maybe<Scalars['String']>;
  protocolType?: Maybe<GQLProtocolType>;
  setupStatus?: Maybe<GQLSetupStatus>;
  splitter?: Maybe<GQLSplitter>;
  sslProxyHost?: Maybe<Scalars['String']>;
  sslProxyPort?: Maybe<Scalars['Int']>;
  statusNote?: Maybe<Scalars['String']>;
  systemConfiguration?: Maybe<GQLSystemConfiguration>;
  taskStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  webHost?: Maybe<Scalars['String']>;
  webPassword?: Maybe<Scalars['String']>;
  webPort?: Maybe<Scalars['Int']>;
  webUserName?: Maybe<Scalars['String']>;
};

/** Use only one field */
export type GQLWhereFilterExpressionInput = {
  and?: Maybe<GQLFilterExpressionLogicalListInput>;
  between?: Maybe<GQLFilterExpressionEquationBinaryInput>;
  contains?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  ends?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  eq?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  ge?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  gt?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  in?: Maybe<GQLFilterExpressionEquationListInput>;
  isNotNull?: Maybe<GQLFilterExpressionEquationNullaryInput>;
  isNull?: Maybe<GQLFilterExpressionEquationNullaryInput>;
  le?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  lt?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  ne?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  not?: Maybe<GQLFilterExpressionLogicalUnaryInput>;
  notBetween?: Maybe<GQLFilterExpressionEquationBinaryInput>;
  notContains?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  notEnds?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  notIn?: Maybe<GQLFilterExpressionEquationListInput>;
  notStarts?: Maybe<GQLFilterExpressionEquationUnaryInput>;
  or?: Maybe<GQLFilterExpressionLogicalListInput>;
  starts?: Maybe<GQLFilterExpressionEquationUnaryInput>;
};
