// @ts-nocheck

import type { Id } from 'types/Id';
import type { User } from 'types/User';

import { localStorage } from '@af/utils';

import { SINGLE_SIGN_ON_STORAGE_KEY } from './constants';

export const SUPER_USER_TYPES = {
	user: 'user',
	contact: 'contact',
	group: 'group',
};

export function filterUsersByProperty(
	users: Array<User> = [],
	filter: string = '',
	propertiesToSearch: Array<string> = ['userName'],
): Array<User> {
	const lowerCaseFilter = filter.toLowerCase();

	return users.filter(user => {
		const values = propertiesToSearch.map(prop => user[prop]);

		const userMatchesFilter = values.some(
			detail => !!detail && detail.toLowerCase().includes(lowerCaseFilter),
		);

		return userMatchesFilter;
	});
}

type PartialGetNameType = {
	name: string;
	firstName: string;
	lastName: string;
};

export function getName({
	name,
	firstName,
	lastName,
}: PartialGetNameType): string {
	if (name) return name;
	const formattedName = [firstName, lastName].filter(name => !!name).join(' ');
	return formattedName || '';
}

export const DEFAULT_USER: User = {
	id: '_defaultUser_',
	userName: '',
	firstName: '',
	lastName: '',
	name: 'Unknown',
	password: '',
	imageUrl: '',
	jobTitle: '',
	lastLoginTime: new Date(),
	organizationIds: [],
	permissions: [],
	roles: [],
};

export function getUserFromList(
	userId: Id,
	usersList: Array<User>,
): User | undefined | null {
	return usersList.find(({ id }) => id.toString() === userId.toString());
}

export function getUserFromListWithDefault(
	userId: Id,
	usersList: Array<User>,
	defaultUser: User = DEFAULT_USER,
): User {
	return getUserFromList(userId, usersList) || defaultUser;
}

export function setSsoIssuerToLocalStorage(ssoIssuer: string) {
	if (!ssoIssuer) {
		return null;
	}

	localStorage.set(SINGLE_SIGN_ON_STORAGE_KEY, ssoIssuer);
}

export function getSsoIssuerFromLocalStorage(): string | undefined | null {
	const ssoIsuer = localStorage.get(SINGLE_SIGN_ON_STORAGE_KEY);

	if (!ssoIsuer) {
		return null;
	}

	return ssoIsuer;
}
