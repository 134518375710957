/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type { GQLViewNotification } from '@af/api';
import type { NotificationResponse } from 'App/hooks/useGetUserNotificationsQuery';

import React, { useState } from 'react';
import { GQLNotificationEventType } from '@af/api';
import { DownloadIcon, MyActionsIcon, RemoveIcon } from '@af/icons';
import moment from 'moment';

import { ErrorMessage } from 'ds/components/ErrorMessage';
import { LoadingOverlay } from 'ds/components/LoadingOverlay';
import { Tooltip, TooltipContent, TooltipTrigger } from 'ds/components/Tooltip';
import { Typography } from 'ds/components/Typography';
import { MOMENT_DATETIME_FORMAT } from 'utils/dates';
import { Toast, ToastMessage } from 'v2/components/Toast';

import {
	Content,
	Date,
	DownloadButton,
	Footer,
	FooterButton,
	Message,
	StatusButton,
	StatusButtonWrapper,
	Wrapper,
} from './styles';
import { useAddItemToLayoutV2 } from './useAddItemToLayoutV2';

export type Props = {
	notification: NotificationResponse;
	onClick?: () => unknown;
	onToggleRead: (a: { id: string; isRead: boolean }) => unknown;
	onDismissed: (a: { id: string }) => unknown;
};

function Notification({
	notification,
	onClick = () => {},
	onToggleRead = () => {},
	onDismissed = () => {},
}: Props) {
	const date = moment(notification.creationTime);
	const timeTitle = date.format(MOMENT_DATETIME_FORMAT);
	const timeLabel = date.fromNow();
	const [isLoading, setIsLoading] = useState(false);
	const isEntitlementCreatedNotification =
		notification.eventType === GQLNotificationEventType.EntitlementCreated;

	const hasDestination =
		!!notification.temporaryFilterExpressionId || !!notification.viewId;

	return (
		<Wrapper
			isRead={notification.isRead}
			tabIndex="0"
			hasDestination={hasDestination}
			onClick={onClick}
		>
			{isLoading && (
				<LoadingOverlay onClick={event => event.stopPropagation()} />
			)}
			<StatusButtonWrapper>
				<Tooltip>
					<TooltipTrigger>
						<StatusButton
							small
							transparent
							isRead={notification.isRead}
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();

								onToggleRead({
									id: notification.id,
									isRead: !notification.isRead,
								});
							}}
						/>
					</TooltipTrigger>
					<TooltipContent hasDropShadow>
						Mark as {notification.isRead ? 'Unread' : 'Read'}
					</TooltipContent>
				</Tooltip>
			</StatusButtonWrapper>
			<Content>
				<Message>
					{notification.fileUrl ? (
						<>
							<Typography>{notification.message}</Typography>
							<DownloadButton
								href={notification.fileUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								Download
								<DownloadIcon size={20} />
							</DownloadButton>
						</>
					) : (
						<Typography numberOfLines={4}>{notification.message}</Typography>
					)}
				</Message>

				<Footer>
					<Date title={timeTitle}>{timeLabel}</Date>
					<div>
						{isEntitlementCreatedNotification && notification.viewId && (
							<AddToLayout
								viewId={notification.viewId}
								setIsLoading={setIsLoading}
							/>
						)}
						<FooterButton
							small
							transparent
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
								onDismissed({ id: notification.id });
							}}
						>
							<RemoveIcon />
						</FooterButton>
					</div>
				</Footer>
			</Content>
		</Wrapper>
	);
}

type AddToLayoutProps = {
	viewId: GQLViewNotification['viewId'];
	setIsLoading: (a: boolean) => void;
};

const AddToLayout = ({ viewId, setIsLoading }: AddToLayoutProps) => {
	const [updateLayout, { error, data }] = useAddItemToLayoutV2();

	const handleAddToLayout = async event => {
		setIsLoading(true);
		event.stopPropagation();
		await updateLayout(viewId).catch(error => {
			console.error('Promise rejected', error);
		});
		setIsLoading(false);
	};

	return (
		<>
			<FooterButton
				onClick={handleAddToLayout}
				transparent
				title="Add to my dashboard"
			>
				<MyActionsIcon />
			</FooterButton>

			{error && (
				<Toast autoClose={5000}>
					<ToastMessage>
						<ErrorMessage error={error} />
					</ToastMessage>
				</Toast>
			)}
			{data && (
				<Toast autoClose={5000}>
					<ToastMessage>View added to layout</ToastMessage>
				</Toast>
			)}
		</>
	);
};

export { Notification };
