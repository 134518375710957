type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const MyActionsIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 11.5h5v-5h-5v5zm4-4v3h-3v-3h3zm2-1v5h5v-5h-5zm-5 11h-1v-5h5v5h-4zm3-1v-3h-3v3h3zm2 1h5v-5h-5v5zm4-4v3h-3v-3h3z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default MyActionsIcon;
