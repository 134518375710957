type Props = {
	className?: string;
};

export const IndeterminateIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M16.1 10C16.1 13.3689 13.3689 16.1 10 16.1C6.63106 16.1 3.9 13.3689 3.9 10C3.9 6.63106 6.63106 3.9 10 3.9C13.3689 3.9 16.1 6.63106 16.1 10ZM17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM6.99995 10.5L12.9998 10.4999V9.4999H6.99995L6.99995 10.5Z"
	/>
);
