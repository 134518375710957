/* 1.The documentation provides us with a way to define the theme and then add custom props for other components
  	See:  https://styled-components.com/docs/api#typescript
*/

import type {
	TypeAlignmentName,
	TypeSizeName,
	TypeTextTransform,
} from 'ds/styles/typography';

import React from 'react';
import styled, { css } from 'styled-components';

import { Theme } from 'ds/components/core/Theme';

export interface TypographyProps extends React.HTMLAttributes<HTMLSpanElement> {
	size?: TypeSizeName;
	align?: TypeAlignmentName;
	transform?: TypeTextTransform;
	numberOfLines?: number; // mirror React Native Text component https://reactnative.dev/docs/text#numberoflines,
	isBold?: boolean;
	as?: string | React.ReactNode;
}

const Typography: React.ForwardRefRenderFunction<
	HTMLSpanElement,
	TypographyProps
> = ({ size, children, ...props }: TypographyProps, ref) => {
	return (
		<Theme typographySize={size}>
			{/* 1. */}
			{/* @ts-ignore - fixing this will fix everything else */}
			<Text ref={ref} {...props}>
				{children}
			</Text>
		</Theme>
	);
};

const Text = styled.span<TypographyProps>`
	${props => props.theme.typography.size.state.default};

	text-align: ${props =>
		props.align && props.theme.typography.alignment[props.align]};
	text-transform: ${props => props.transform};
	font-weight: ${props => props.isBold && 500};

	/* We will have a different fix for this as we haven't defined the types properly for theme on the styled-components
		See also point 1.
	*/
	${(props: TypographyProps) =>
		!!props.numberOfLines &&
		css`
			-webkit-line-clamp: ${(props: TypographyProps) => props.numberOfLines};
			display: -webkit-box;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
		`}
`;

const TypographyWithRef = React.forwardRef(Typography);

export { TypographyWithRef as Typography };
