// @ts-nocheck

import type { GQLBaseField } from '@af/api';
import type { APIDateType } from 'types/Date';

import moment from 'moment';

import {
	getTimeBasedValueLabel,
	OPERATORS,
} from 'utils/expressionsv2/operator';

export const MOMENT_DATE_FORMAT = 'll';
export const MOMENT_DATETIME_FORMAT = 'lll';
const MOMENT_ELASTIC_FORMAT = 'YYYY-MM-DD';

type OptionsType = {
	format?: string;
	ignoreTime?: boolean;
	ignoreTimeZone?: boolean;
};

type DateTypes = APIDateType | Moment | Date;

export function formatDate(date: DateTypes, options?: OptionsType) {
	const { format, ignoreTime, ignoreTimeZone } = options || {};
	const parsedDate =
		// $FlowFixMe: _isUTC is a private value on momentjs, so this is fine
		ignoreTimeZone && !date._isUTC ? moment.utc(date) : moment(date);
	const finalDate = ignoreTime ? parsedDate.startOf('day') : parsedDate;
	const finalFormat = format || MOMENT_DATE_FORMAT;
	const dateString = finalDate.format(finalFormat);

	return dateString;
}

export function secondsToDays(seconds: number) {
	return seconds / 86400;
}

export function secondsToHours(seconds: number) {
	return seconds / 3600;
}

export function daysToSeconds(days: number) {
	return days * 86400;
}

export function hoursToSeconds(hours: number) {
	return hours * 3600;
}

function minutesToDays(minutes: number) {
	return minutesToHours(minutes) / 24;
}

function minutesToHours(minutes: number) {
	return minutes / 60;
}

export function daysToMinutes(days: number) {
	return days * 24 * 60;
}

export function hoursToMinutes(hours: number) {
	return hours * 60;
}

export function dateAgo(date: DateTypes) {
	return moment(date).fromNow();
}

export function getTimeValue(minutes: number) {
	const days = Math.floor(minutesToDays(minutes));
	const hours = Math.floor(minutesToHours(minutes - daysToMinutes(days)));

	return { days, hours };
}

export function nowISO() {
	return moment.utc().startOf('day').toISOString();
}

export function parseDateBetweenExpression({
	lowerValue,
	upperValue,
}: {
	lowerValue: string | number;
	upperValue: string | number;
}) {
	const betweenValues = {
		lowerValue: moment.utc(lowerValue).startOf('day').toISOString(),
		upperValue: moment.utc(upperValue).endOf('day').toISOString(),
	};

	return betweenValues;
}

type ExpressionField = {
	key: GQLBaseField['key'];
};

type DateExpressionTypes = {
	from: Date;
	to: Date | undefined | null;
	field: ExpressionField;
};

export function createDateRangeExpression({
	from,
	to,
	field,
}: DateExpressionTypes) {
	const doubleValue = {
		lowerValue: getElasticDateFormat(from),
		upperValue: getElasticDateFormat(to || from),
	};

	return {
		left: { key: field.key },
		operator: OPERATORS.between,
		lowerBound: {
			value: doubleValue.lowerValue,
			valueType: 'constant',
		},
		upperBound: {
			value: doubleValue.upperValue,
			valueType: 'constant',
		},
	};
}

export function formatDateTime(date: string) {
	return formatDate(date, { format: MOMENT_DATETIME_FORMAT });
}

export const getElasticDateFormat = (date: Date) => {
	return formatDate(date, { format: MOMENT_ELASTIC_FORMAT });
};

// We ignore time portions of ISO dates when sending up to the API
// these functions can convert those to Date objects
export const getDateBeginningFromElasticFormat = (dateString: string): Date => {
	return new Date(dateString + 'T00:00:00.000Z');
};

export const getDateEndFromElasticFormat = (dateString: string): Date => {
	return new Date(dateString + 'T23:59:59.999Z');
};

// Copied from here: https://github.com/gpbl/react-day-picker/issues/130#issuecomment-501731006
// This function amends a date object and removes the effect of it's time zone
// also reversible, a reversed date can be changed back to original local time zone
export function convertLocalDateToUTC({
	date,
	reverse = false,
}: {
	date: Date;
	reverse?: boolean;
}) {
	const reverseMultiplier = reverse ? -1 : 1;
	// Take the effect of current time zone and reverse it (or re-reverse it if reverse=true)
	const timeZoneChangeInMinutes = date.getTimezoneOffset() * reverseMultiplier;
	const newDateMinutes = date.getMinutes() + timeZoneChangeInMinutes;

	date.setMinutes(newDateMinutes);

	return date;
}

function mathSign(number: number) {
	return number > 0 ? 1 : number === 0 ? 0 : -1;
}

export function formatSeconds(value: number) {
	const sign = mathSign(value);
	const seconds = Math.abs(value);
	const dayValue = Math.floor(secondsToDays(seconds));
	const hourValue = Math.floor(
		secondsToHours(seconds - daysToSeconds(dayValue)),
	);

	const signLabel = getTimeBasedValueLabel(sign);
	const dayText = `${dayValue} ${dayValue === 1 ? 'day' : 'days'}`;
	const hourText = `${hourValue} ${hourValue === 1 ? 'hour' : 'hours'}`;
	const texts = [
		signLabel,
		!!dayValue ? dayText : undefined,
		!!hourValue ? hourText : undefined,
	].filter(v => !!v);

	return texts.join(' ');
}

export function formatByTwoLargestUnits(miliseconds: number) {
	const result = [];
	const seconds = Math.abs(Math.round(miliseconds / 1000));

	const dayValue = Math.floor(secondsToDays(seconds));
	if (Boolean(dayValue)) {
		result.push(`${dayValue}d`);
	}
	const hourValue = Math.floor(secondsToHours(seconds - dayValue * 86400));
	if (Boolean(dayValue) || Boolean(hourValue)) {
		result.push(`${hourValue}hrs`);
		if (result.length > 1) return result;
	}

	// if we got here, there are no days, but may be hours
	const minutesValue = Math.floor((seconds - hourValue * 60 * 60) / 60);
	result.push(`${minutesValue}m`);
	if (result.length > 1) return result;

	// if we got here, there are no days or hours, but may be minutes
	result.push(`${seconds % 60}sec`);
	return result;
}
