import { transparentize } from 'polished';
import { css } from 'styled-components';

import * as color from './color';

export function scrollShadows({
	bgColor = color.white,
	shadowColor = color.mineShaft,
} = {}) {
	return css`
		background-image: linear-gradient(
				to bottom,
				${bgColor},
				${transparentize(1, bgColor)}
			),
			linear-gradient(to bottom, ${transparentize(1, bgColor)}, ${bgColor}),
			linear-gradient(
				to bottom,
				${transparentize(0.92, shadowColor)},
				${transparentize(1, shadowColor)} 9px
			),
			linear-gradient(
				to top,
				${transparentize(0.92, shadowColor)},
				${transparentize(1, shadowColor)} 9px
			);
		background-repeat: no-repeat;
		background-size: 100% 50px, 100% 50px, 100% 10px, 100% 10px;
		background-position: top, bottom, top, bottom;
		background-attachment: local, local, scroll, scroll;
	`;
}
