// @ts-nocheck

import type { ReactNode } from 'react';

import { AttachmentAddIcon } from '@af/icons';

import { AcceptIcon } from './icons/AcceptIcon';
import { ActionIcon } from './icons/ActionIcon';
import { AddIcon } from './icons/AddIcon';
import { AlertIcon } from './icons/AlertIcon';
import { AlertMiniIcon } from './icons/AlertMiniIcon';
import { AllocationIcon } from './icons/AllocationIcon';
import { AmendContextualIcon } from './icons/AmendContextualIcon';
import { AmendIcon } from './icons/AmendIcon';
import { ApplyIcon } from './icons/ApplyIcon';
import { ApprovalIcon } from './icons/ApprovalIcon';
import { ArrowLeftIcon } from './icons/ArrowLeftIcon';
import { ArrowSelectIcon } from './icons/ArrowSelectIcon';
import { AssignIcon } from './icons/AssignIcon';
import { AssignmentIcon } from './icons/AssignmentIcon';
import { BusinessIntelligenceIcon } from './icons/BusinessIntelligenceIcon';
import { BuyInIcon } from './icons/BuyInIcon';
import { BuyInNotification } from './icons/BuyInNotification';
import { CategoryIcon } from './icons/CategoryIcon';
import { ChatContextualIcon } from './icons/ChatContextualIcon';
import { ChatIcon } from './icons/ChatIcon';
import { CheckedIcon } from './icons/CheckedIcon';
import { ChevronDown } from './icons/ChevronDown';
import { ChevronLeft } from './icons/ChevronLeft';
import { ChevronRight } from './icons/ChevronRight';
import { ClaimContextualIcon } from './icons/ClaimContextualIcon';
import { ClaimIcon } from './icons/ClaimIcon';
import { ClearContextualIcon } from './icons/ClearContextualIcon';
import { ClearNavigationIcon } from './icons/ClearNavigationIcon';
import { CloneIcon } from './icons/CloneIcon';
import { CommentContextualIcon } from './icons/CommentContextualIcon';
import { CommentNavigationIcon } from './icons/CommentNavigationIcon';
import { CompareContextualIcon } from './icons/CompareContextualIcon';
import { CompareIcon } from './icons/CompareIcon';
import { DashboardIcon } from './icons/DashboardIcon';
import { DateIcon } from './icons/DateIcon';
import { DeleteContextualIcon } from './icons/DeleteContextualIcon';
import { DeselectIcon } from './icons/DeselectIcon';
import { DestinationIcon } from './icons/DestinationIcon';
import { DismissContextualIcon } from './icons/DismissContextualIcon';
import { DismissIcon } from './icons/DismissIcon';
import { DontKnow } from './icons/DontKnow';
import { DownloadIcon } from './icons/DownloadIcon';
import { DropdownSelectorIcon } from './icons/DropdownSelectorIcon';
import { EditIcon } from './icons/EditIcon';
import { EnvironmentIcon } from './icons/EnvironmentIcon';
import { ExceptionsIcon } from './icons/ExceptionsIcon';
import { ExpandIcon } from './icons/ExpandIcon';
import { FavoriteFullIcon } from './icons/FavoriteFullIcon';
import { FavoriteIcon } from './icons/FavoriteIcon';
import { FilterIcon } from './icons/FilterIcon';
import { FilterV2Icon } from './icons/FilterV2Icon';
import { FocusIcon } from './icons/FocusIcon';
import { FrequencyIcon } from './icons/FrequencyIcon';
import { GenericInstructionIcon } from './icons/GenericInstructionIcon';
import { GlobalIcon } from './icons/GlobalIcon';
import { GoToIcon } from './icons/GoToIcon';
import { HandshakeOffIcon } from './icons/HandshakeOffIcon';
import { HoldReleaseIcon } from './icons/HoldReleaseIcon';
import { IndeterminateIcon } from './icons/IndeterminateIcon';
import { InfiniteIcon } from './icons/InfiniteIcon';
import { InstructAcceptIcon } from './icons/InstructAcceptIcon';
import { InstructCompletedIcon } from './icons/InstructCompletedIcon';
import { InstructIcon } from './icons/InstructIcon';
import { InstructOnHoldIcon } from './icons/InstructOnHoldIcon';
import { InstructRejectIcon } from './icons/InstructRejectIcon';
import { InventoryIcon } from './icons/InventoryIcon';
import { JourneyDetailIcon } from './icons/JourneyDetailIcon';
import { JourneyIcon } from './icons/JourneyIcon';
import { LinkContextualIcon } from './icons/LinkContextualIcon';
import { LinkIcon } from './icons/LinkIcon';
import { ListCollapsedIcon } from './icons/ListCollapsedIcon';
import { ListOpenIcon } from './icons/ListOpenIcon';
import { LockContextualIcon } from './icons/LockContextualIcon';
import { LockNavigationIcon } from './icons/LockNavigationIcon';
import { LogoIcon } from './icons/LogoIcon';
import { LogoTextIcon } from './icons/LogoTextIcon';
import { MarketReliefIcon } from './icons/MarketReliefIcon';
import { MeetContextualIcon } from './icons/MeetContextualIcon';
import { MeetIcon } from './icons/MeetIcon';
import { MergeIcon } from './icons/MergeIcon';
import { MessageIcon } from './icons/MessageIcon';
import { MessagesIcon } from './icons/MessagesIcon';
import { MinimizeIcon } from './icons/MinimizeIcon';
import { MinusIcon } from './icons/MinusIcon';
import { MoreIcon } from './icons/MoreIcon';
import { NavIcon } from './icons/NavIcon';
import { OnboardingIcon } from './icons/OnboardingIcon';
import { OpenCloseIcon } from './icons/OpenCloseIcon';
import { PairedContextualIcon } from './icons/PairedContextualIcon';
import { PairingCenterIcon } from './icons/PairingCenterIcon';
import { PauseIcon } from './icons/PauseIcon';
import { PayIcon } from './icons/PayIcon';
import { PaymentAllocationIcon } from './icons/PaymentAllocationIcon';
import { PendingIcon } from './icons/PendingIcon';
import { PlayIcon } from './icons/PlayIcon';
import { PreferencesIcon } from './icons/PreferencesIcon';
import { RefreshIcon } from './icons/RefreshIcon';
import { RemoveIcon } from './icons/RemoveIcon';
import { ResendIcon } from './icons/ResendIcon';
import { ReshapeIcon } from './icons/ReshapeIcon';
import { ReturnIcon } from './icons/ReturnIcon';
import { RiskIcon } from './icons/RiskIcon';
import { RootIcon } from './icons/RootIcon';
import { RulesIcon } from './icons/RulesIcon';
import { SaveIcon } from './icons/SaveIcon';
import { SearchColumnIcon } from './icons/SearchColumnIcon';
import { SearchIcon } from './icons/SearchIcon';
import { SelectAllIcon } from './icons/SelectAllIcon';
import { SelectQuickIcon } from './icons/SelectQuickIcon';
import { SettingsIcon } from './icons/SettingsIcon';
import { SeverityContextualIcon } from './icons/SeverityContextualIcon';
import { ShareContextualIcon } from './icons/ShareContextualIcon';
import { SnoozeIcon } from './icons/SnoozeIcon';
import { SortIcon } from './icons/SortIcon';
import { SubListIcon } from './icons/SubListIcon';
import { TagContextualIcon } from './icons/TagContextualIcon';
import { TagIcon } from './icons/TagIcon';
import { TaskContextualIcon } from './icons/TaskContextualIcon';
import { TemplateIcon } from './icons/TemplateIcon';
import { TickContextualIcon } from './icons/TickContextualIcon';
import { TickIcon } from './icons/TickIcon';
import { TypeIcon } from './icons/TypeIcon';
import { UndoIcon } from './icons/UndoIcon';
import { UndoMergeIcon } from './icons/UndoMergeIcon';
import { UserExternalIcon } from './icons/UserExternalIcon';
import { UsersIcon } from './icons/UsersIcon';
import { VersionDismissedIcon } from './icons/VersionDismissedIcon';
import { VideoHelpIcon } from './icons/VideoHelpIcon';
import { VisibilityOffIcon } from './icons/VisibilityOffIcon';
import { VisibilityOnIcon } from './icons/VisibilityOnIcon';

export const ICON_TYPE = {
	globalNavigation: 'globalNavigation',
	secondaryNavigation: 'secondaryNavigation',
	contextual: 'contextual',
	mini: 'mini',
	logo: 'logo',
	others: 'others',
};

export type IconName =
	| 'accept'
	| 'actionContextual'
	| 'add'
	| 'addContextual'
	| 'alert'
	| 'alertMini'
	| 'allocation'
	| 'amend'
	| 'amendContextual'
	| 'apply'
	| 'approval'
	| 'arrowLeft'
	| 'arrowSelect'
	| 'assign'
	| 'assignContextual'
	| 'assignment'
	| 'attachmentAdd'
	| 'businessIntelligence'
	| 'buyIn'
	| 'buyInNotification'
	| 'category'
	| 'chat'
	| 'chatContextual'
	| 'checked'
	| 'chevronDown'
	| 'chevronLeft'
	| 'chevronRight'
	| 'claim'
	| 'claimContextual'
	| 'clearContextual'
	| 'clearNavigation'
	| 'clone'
	| 'commentContextual'
	| 'commentNavigation'
	| 'compare'
	| 'compareContextual'
	| 'dashboard'
	| 'date'
	| 'deleteContextual'
	| 'deselect'
	| 'destination'
	| 'dismiss'
	| 'dismissContextual'
	| 'dontKnow'
	| 'download'
	| 'dropdownSelector'
	| 'edit'
	| 'environment'
	| 'exceptions'
	| 'expand'
	| 'favorite'
	| 'favoriteFull'
	| 'filter'
	| 'filterV2'
	| 'focus'
	| 'frequency'
	| 'genericInstruction'
	| 'global'
	| 'goto'
	| 'handshakeOff'
	| 'holdRelease'
	| 'indeterminate'
	| 'infinite'
	| 'instructAccept'
	| 'instruct'
	| 'instructReject'
	| 'instructContextual'
	| 'inventory'
	| 'inventoryContextual'
	| 'journey'
	| 'journeyDetail'
	| 'link'
	| 'linkContextual'
	| 'linkContextual'
	| 'listCollapsed'
	| 'listOpen'
	| 'lockContextual'
	| 'lockNavigation'
	| 'logo'
	| 'logoText'
	| 'marketRelief'
	| 'marketReliefContextual'
	| 'meet'
	| 'meetContextual'
	| 'merge'
	| 'message'
	| 'messages'
	| 'minimize'
	| 'minimizeContextual'
	| 'more'
	| 'nav'
	| 'onboarding'
	| 'openClose'
	| 'pairedContextual'
	| 'pairingCenter'
	| 'pause'
	| 'pay'
	| 'paymentAllocation'
	| 'pending'
	| 'play'
	| 'preferences'
	| 'refresh'
	| 'remove'
	| 'removeContextual'
	| 'resend'
	| 'reshape'
	| 'reshapeContextual'
	| 'return'
	| 'risk'
	| 'root'
	| 'rules'
	| 'save'
	| 'search'
	| 'searchColumn'
	| 'selectAll'
	| 'selectQuick'
	| 'settings'
	| 'severityContextual'
	| 'shareContextual'
	| 'snooze'
	| 'snoozeContextual'
	| 'sort'
	| 'subList'
	| 'tag'
	| 'tagContextualIcon'
	| 'taskContextual'
	| 'template'
	| 'tickContextual'
	| 'tick'
	| 'type'
	| 'undoContextual'
	| 'undoMerge'
	| 'userExternal'
	| 'userExternalContextual'
	| 'users'
	| 'usersContextual'
	| 'versionDismissed'
	| 'videoHelp'
	| 'visibilityOff'
	| 'visibilityOn';

export type IconConfigType = {
	name: IconName;
	children: (props: { className?: string }) => ReactNode;
	type: keyof typeof ICON_TYPE;
	isBadge?: boolean;
};

const iconConfigMap: {
	[k in IconName]: IconConfigType;
} = {
	accept: {
		children: AcceptIcon,
		name: 'accept',
		type: ICON_TYPE.secondaryNavigation,
		isBadge: true,
	},
	actionContextual: {
		children: ActionIcon,
		name: 'actionContextual',
		type: ICON_TYPE.contextual,
	},
	add: {
		children: AddIcon,
		isBadge: true,
		name: 'add',
		type: ICON_TYPE.secondaryNavigation,
	},
	addContextual: {
		children: AddIcon,
		name: 'addContextual',
		type: ICON_TYPE.contextual,
	},
	alert: {
		children: AlertIcon,
		isBadge: true,
		name: 'alert',
		type: ICON_TYPE.secondaryNavigation,
	},
	alertMini: {
		children: AlertMiniIcon,
		isBadge: true,
		name: 'alertMini',
		type: ICON_TYPE.mini,
	},
	allocation: {
		children: AllocationIcon,
		isBadge: true,
		name: 'allocation',
		type: ICON_TYPE.contextual,
	},
	amend: {
		children: AmendIcon,
		isBadge: true,
		name: 'amend',
		type: ICON_TYPE.secondaryNavigation,
	},
	amendContextual: {
		children: AmendContextualIcon,
		name: 'amendContextual',
		type: ICON_TYPE.contextual,
	},
	apply: {
		children: ApplyIcon,
		name: 'apply',
		type: ICON_TYPE.contextual,
	},
	approval: {
		children: ApprovalIcon,
		name: 'approval',
		type: ICON_TYPE.contextual,
	},
	arrowLeft: {
		children: ArrowLeftIcon,
		name: 'arrowLeft',
		type: ICON_TYPE.contextual,
	},
	arrowSelect: {
		children: ArrowSelectIcon,
		name: 'arrowSelect',
		type: ICON_TYPE.contextual,
	},
	assign: {
		children: AssignIcon,
		isBadge: true,
		name: 'assign',
		type: ICON_TYPE.secondaryNavigation,
	},
	assignContextual: {
		children: AssignIcon,
		name: 'assignContextual',
		type: ICON_TYPE.contextual,
	},
	assignment: {
		children: AssignmentIcon,
		name: 'assignment',
		type: ICON_TYPE.contextual,
	},
	attachmentAdd: {
		children: AttachmentAddIcon,
		name: 'attachmentAdd',
		type: ICON_TYPE.contextual,
	},
	businessIntelligence: {
		children: BusinessIntelligenceIcon,
		name: 'businessIntelligence',
		type: ICON_TYPE.globalNavigation,
	},
	buyIn: {
		children: BuyInIcon,
		isBadge: true,
		name: 'buyIn',
		type: ICON_TYPE.secondaryNavigation,
	},
	buyInNotification: {
		children: BuyInNotification,
		isBadge: true,
		name: 'buyInNotification',
		type: ICON_TYPE.secondaryNavigation,
	},
	category: {
		children: CategoryIcon,
		name: 'category',
		type: ICON_TYPE.contextual,
	},
	chat: {
		children: ChatIcon,
		isBadge: true,
		name: 'chat',
		type: ICON_TYPE.secondaryNavigation,
	},
	chatContextual: {
		children: ChatContextualIcon,
		name: 'chatContextual',
		type: ICON_TYPE.contextual,
	},
	checked: {
		children: CheckedIcon,
		name: 'checked',
		type: ICON_TYPE.contextual,
	},
	chevronDown: {
		children: ChevronDown,
		name: 'chevronDown',
		type: ICON_TYPE.contextual,
	},
	chevronLeft: {
		children: ChevronLeft,
		name: 'chevronLeft',
		type: ICON_TYPE.contextual,
	},
	chevronRight: {
		children: ChevronRight,
		name: 'chevronRight',
		type: ICON_TYPE.contextual,
	},
	claim: {
		children: ClaimIcon,
		isBadge: true,
		name: 'claim',
		type: ICON_TYPE.secondaryNavigation,
	},
	claimContextual: {
		children: ClaimContextualIcon,
		name: 'claimContextual',
		type: ICON_TYPE.contextual,
	},
	clearContextual: {
		children: ClearContextualIcon,
		name: 'clearContextual',
		type: ICON_TYPE.contextual,
	},
	clearNavigation: {
		children: ClearNavigationIcon,
		isBadge: true,
		name: 'clearNavigation',
		type: ICON_TYPE.secondaryNavigation,
	},
	clone: {
		children: CloneIcon,
		name: 'clone',
		type: ICON_TYPE.contextual,
	},
	commentContextual: {
		children: CommentContextualIcon,
		name: 'commentContextual',
		type: ICON_TYPE.contextual,
	},
	commentNavigation: {
		children: CommentNavigationIcon,
		isBadge: true,
		name: 'commentNavigation',
		type: ICON_TYPE.secondaryNavigation,
	},
	compare: {
		children: CompareIcon,
		isBadge: true,
		name: 'compare',
		type: ICON_TYPE.secondaryNavigation,
	},
	compareContextual: {
		children: CompareContextualIcon,
		name: 'compareContextual',
		type: ICON_TYPE.contextual,
	},
	dashboard: {
		children: DashboardIcon,
		name: 'dashboard',
		type: ICON_TYPE.globalNavigation,
	},
	date: {
		children: DateIcon,
		name: 'date',
		type: ICON_TYPE.contextual,
	},
	deleteContextual: {
		children: DeleteContextualIcon,
		name: 'deleteContextual',
		type: ICON_TYPE.contextual,
	},
	deselect: {
		children: DeselectIcon,
		name: 'deselect',
		type: ICON_TYPE.contextual,
	},
	destination: {
		children: DestinationIcon,
		name: 'destination',
		type: ICON_TYPE.contextual,
	},
	dismiss: {
		children: DismissIcon,
		isBadge: true,
		name: 'dismiss',
		type: ICON_TYPE.secondaryNavigation,
	},
	dismissContextual: {
		children: DismissContextualIcon,
		name: 'dismissContextual',
		type: ICON_TYPE.contextual,
	},
	dontKnow: {
		children: DontKnow,
		isBadge: true,
		name: 'dontKnow',
		type: ICON_TYPE.secondaryNavigation,
	},
	download: {
		children: DownloadIcon,
		name: 'download',
		type: ICON_TYPE.contextual,
	},
	dropdownSelector: {
		children: DropdownSelectorIcon,
		name: 'dropdownSelector',
		type: ICON_TYPE.contextual,
	},
	edit: {
		children: EditIcon,
		name: 'edit',
		type: ICON_TYPE.contextual,
	},
	environment: {
		children: EnvironmentIcon,
		name: 'environment',
		type: ICON_TYPE.contextual,
	},
	exceptions: {
		children: ExceptionsIcon,
		isBadge: true,
		name: 'exceptions',
		type: ICON_TYPE.secondaryNavigation,
	},
	expand: {
		children: ExpandIcon,
		name: 'expand',
		type: ICON_TYPE.contextual,
	},
	favorite: {
		children: FavoriteIcon,
		name: 'favorite',
		type: ICON_TYPE.contextual,
	},
	favoriteFull: {
		children: FavoriteFullIcon,
		name: 'favoriteFull',
		type: ICON_TYPE.contextual,
	},
	filter: {
		children: FilterIcon,
		name: 'filter',
		type: ICON_TYPE.contextual,
	},
	filterV2: {
		children: FilterV2Icon,
		name: 'filterV2',
		type: ICON_TYPE.contextual,
	},
	focus: {
		children: FocusIcon,
		name: 'focus',
		type: ICON_TYPE.contextual,
	},
	frequency: {
		children: FrequencyIcon,
		name: 'frequency',
		type: ICON_TYPE.contextual,
	},
	genericInstruction: {
		children: GenericInstructionIcon,
		name: 'genericInstruction',
		type: ICON_TYPE.secondaryNavigation,
	},
	global: {
		children: GlobalIcon,
		name: 'global',
		type: ICON_TYPE.contextual,
	},
	goto: {
		children: GoToIcon,
		name: 'goto',
		type: ICON_TYPE.contextual,
	},
	handshakeOff: {
		children: HandshakeOffIcon,
		name: 'handshakeOff',
		type: ICON_TYPE.contextual,
	},
	holdRelease: {
		children: HoldReleaseIcon,
		name: 'holdRelease',
		type: ICON_TYPE.contextual,
		isBadge: true,
	},
	indeterminate: {
		children: IndeterminateIcon,
		name: 'indeterminate',
		type: ICON_TYPE.contextual,
	},
	infinite: {
		children: InfiniteIcon,
		name: 'infinite',
		type: ICON_TYPE.contextual,
	},
	instructAccept: {
		children: InstructAcceptIcon,
		name: 'instructAccept',
		type: ICON_TYPE.contextual,
	},
	instructOnHold: {
		children: InstructOnHoldIcon,
		name: 'instructOnHold',
		type: ICON_TYPE.contextual,
	},
	instructCompleted: {
		children: InstructCompletedIcon,
		name: 'instructCompleted',
		type: ICON_TYPE.contextual,
	},
	instruct: {
		children: InstructIcon,
		isBadge: true,
		name: 'instruct',
		type: ICON_TYPE.secondaryNavigation,
	},
	instructReject: {
		children: InstructRejectIcon,
		name: 'instructReject',
		type: ICON_TYPE.contextual,
	},

	instructContextual: {
		children: InstructIcon,
		name: 'instructContextual',
		type: ICON_TYPE.contextual,
	},
	inventory: {
		children: InventoryIcon,
		isBadge: true,
		name: 'inventory',
		type: ICON_TYPE.secondaryNavigation,
	},
	inventoryContextual: {
		children: InventoryIcon,
		name: 'inventoryContextual',
		type: ICON_TYPE.contextual,
	},
	journey: {
		children: JourneyIcon,
		name: 'journey',
		type: ICON_TYPE.globalNavigation,
	},
	journeyDetail: {
		children: JourneyDetailIcon,
		isBadge: true,
		name: 'journeyDetail',
		type: ICON_TYPE.secondaryNavigation,
	},
	link: {
		children: LinkIcon,
		isBadge: true,
		name: 'link',
		type: ICON_TYPE.secondaryNavigation,
	},
	linkContextual: {
		children: LinkContextualIcon,
		name: 'linkContextual',
		type: ICON_TYPE.contextual,
	},
	listCollapsed: {
		children: ListCollapsedIcon,
		name: 'listCollapsed',
		type: ICON_TYPE.contextual,
	},
	listOpen: {
		children: ListOpenIcon,
		name: 'listOpen',
		type: ICON_TYPE.contextual,
	},
	lockContextual: {
		children: LockContextualIcon,
		name: 'lockContextual',
		type: ICON_TYPE.contextual,
	},
	lockNavigation: {
		children: LockNavigationIcon,
		isBadge: true,
		name: 'lockNavigation',
		type: ICON_TYPE.secondaryNavigation,
	},
	logo: {
		children: LogoIcon,
		name: 'logo',
		type: ICON_TYPE.logo,
	},
	logoText: {
		children: LogoTextIcon,
		name: 'logoText',
		type: ICON_TYPE.logo,
	},
	marketRelief: {
		name: 'marketRelief',
		children: MarketReliefIcon,
		type: ICON_TYPE.secondaryNavigation,
		isBadge: true,
	},
	marketReliefContextual: {
		name: 'marketReliefContextual',
		children: MarketReliefIcon,
		type: ICON_TYPE.contextual,
	},
	meet: {
		children: MeetIcon,
		isBadge: true,
		name: 'meet',
		type: ICON_TYPE.secondaryNavigation,
	},
	meetContextual: {
		children: MeetContextualIcon,
		name: 'meetContextual',
		type: ICON_TYPE.contextual,
	},
	merge: {
		children: MergeIcon,
		isBadge: true,
		name: 'merge',
		type: ICON_TYPE.secondaryNavigation,
	},
	message: {
		children: MessageIcon,
		name: 'message',
		type: ICON_TYPE.contextual,
	},
	messages: {
		children: MessagesIcon,
		name: 'messages',
		type: ICON_TYPE.contextual,
	},
	minimize: {
		children: MinimizeIcon,
		isBadge: true,
		name: 'minimize',
		type: ICON_TYPE.secondaryNavigation,
	},
	minimizeContextual: {
		children: MinimizeIcon,
		name: 'minimizeContextual',
		type: ICON_TYPE.secondaryNavigation,
	},
	more: {
		children: MoreIcon,
		name: 'more',
		type: ICON_TYPE.contextual,
	},
	nav: {
		children: NavIcon,
		name: 'nav',
		type: ICON_TYPE.contextual,
	},
	onboarding: {
		children: OnboardingIcon,
		name: 'onboarding',
		type: ICON_TYPE.globalNavigation,
	},
	openClose: {
		children: OpenCloseIcon,
		isBadge: true,
		name: 'openClose',
		type: ICON_TYPE.secondaryNavigation,
	},
	pairedContextual: {
		children: PairedContextualIcon,
		name: 'pairedContextual',
		type: ICON_TYPE.contextual,
	},
	pairingCenter: {
		children: PairingCenterIcon,
		name: 'pairingCenter',
		type: ICON_TYPE.globalNavigation,
	},
	pause: {
		children: PauseIcon,
		isBadge: true,
		name: 'pause',
		type: ICON_TYPE.secondaryNavigation,
	},
	pay: {
		children: PayIcon,
		name: 'pay',
		type: ICON_TYPE.contextual,
		isBadge: true,
	},
	paymentAllocation: {
		children: PaymentAllocationIcon,
		isBadge: true,
		name: 'paymentAllocation',
		type: ICON_TYPE.secondaryNavigation,
	},
	pending: {
		children: PendingIcon,
		isBadge: true,
		name: 'pending',
		type: ICON_TYPE.contextual,
	},
	play: {
		children: PlayIcon,
		isBadge: true,
		name: 'play',
		type: ICON_TYPE.secondaryNavigation,
	},
	preferences: {
		children: PreferencesIcon,
		name: 'preferences',
		type: ICON_TYPE.globalNavigation,
	},

	refresh: {
		children: RefreshIcon,
		name: 'refresh',
		type: ICON_TYPE.contextual,
	},
	remove: {
		children: RemoveIcon,
		name: 'remove',
		type: ICON_TYPE.contextual,
	},
	removeContextual: {
		children: MinusIcon,
		name: 'removeContextual',
		type: ICON_TYPE.contextual,
	},
	resend: {
		children: ResendIcon,
		isBadge: true,
		name: 'resend',
		type: ICON_TYPE.secondaryNavigation,
	},
	reshape: {
		children: ReshapeIcon,
		isBadge: true,
		name: 'reshape',
		type: ICON_TYPE.secondaryNavigation,
	},
	reshapeContextual: {
		children: ReshapeIcon,
		name: 'reshapeContextual',
		type: ICON_TYPE.contextual,
	},
	return: {
		children: ReturnIcon,
		name: 'return',
		type: ICON_TYPE.contextual,
	},
	risk: {
		children: RiskIcon,
		name: 'risk',
		type: ICON_TYPE.globalNavigation,
	},
	root: {
		children: RootIcon,
		isBadge: true,
		name: 'root',
		type: ICON_TYPE.secondaryNavigation,
	},
	rules: {
		children: RulesIcon,
		name: 'rules',
		type: ICON_TYPE.globalNavigation,
	},
	save: {
		children: SaveIcon,
		name: 'save',
		type: ICON_TYPE.contextual,
	},
	search: {
		children: SearchIcon,
		name: 'search',
		type: ICON_TYPE.globalNavigation,
	},
	searchColumn: {
		children: SearchColumnIcon,
		name: 'searchColumn',
		type: ICON_TYPE.contextual,
	},
	selectAll: {
		children: SelectAllIcon,
		name: 'selectAll',
		type: ICON_TYPE.contextual,
	},
	selectQuick: {
		children: SelectQuickIcon,
		name: 'selectQuick',
		type: ICON_TYPE.contextual,
	},
	settings: {
		children: SettingsIcon,
		name: 'settings',
		type: ICON_TYPE.contextual,
	},
	severityContextual: {
		children: SeverityContextualIcon,
		name: 'severityContextual',
		type: ICON_TYPE.contextual,
	},
	shareContextual: {
		children: ShareContextualIcon,
		name: 'shareContextual',
		type: ICON_TYPE.contextual,
	},
	snooze: {
		children: SnoozeIcon,
		isBadge: true,
		name: 'snooze',
		type: ICON_TYPE.secondaryNavigation,
	},
	snoozeContextual: {
		children: SnoozeIcon,
		name: 'snoozeContextual',
		type: ICON_TYPE.secondaryNavigation,
	},
	sort: {
		children: SortIcon,
		name: 'sort',
		type: ICON_TYPE.contextual,
	},
	subList: {
		children: SubListIcon,
		name: 'subList',
		type: ICON_TYPE.contextual,
	},
	tag: {
		children: TagIcon,
		isBadge: true,
		name: 'tag',
		type: ICON_TYPE.secondaryNavigation,
	},
	tagContextualIcon: {
		children: TagContextualIcon,
		name: 'tagContextualIcon',
		type: ICON_TYPE.contextual,
	},
	taskContextual: {
		children: TaskContextualIcon,
		name: 'taskContextual',
		type: ICON_TYPE.contextual,
	},
	template: {
		children: TemplateIcon,
		name: 'template',
		type: ICON_TYPE.others,
	},
	tickContextual: {
		children: TickContextualIcon,
		name: 'tickContextual',
		type: ICON_TYPE.contextual,
	},
	tick: {
		children: TickIcon,
		isBadge: true,
		name: 'tick',
		type: ICON_TYPE.secondaryNavigation,
	},
	type: {
		children: TypeIcon,
		name: 'type',
		type: ICON_TYPE.contextual,
	},
	undoContextual: {
		children: UndoIcon,
		name: 'undoContextual',
		type: ICON_TYPE.contextual,
	},
	undoMerge: {
		children: UndoMergeIcon,
		name: 'undoMerge',
		type: ICON_TYPE.contextual,
		isBadge: true,
	},
	userExternal: {
		children: UserExternalIcon,
		isBadge: true,
		name: 'userExternal',
		type: ICON_TYPE.secondaryNavigation,
	},
	userExternalContextual: {
		children: UserExternalIcon,
		name: 'userExternalContextual',
		type: ICON_TYPE.contextual,
	},
	users: {
		children: UsersIcon,
		isBadge: true,
		name: 'users',
		type: ICON_TYPE.secondaryNavigation,
	},
	usersContextual: {
		children: UsersIcon,
		name: 'usersContextual',
		type: ICON_TYPE.contextual,
	},
	versionDismissed: {
		children: VersionDismissedIcon,
		name: 'versionDismissed',
		type: ICON_TYPE.contextual,
	},
	videoHelp: {
		children: VideoHelpIcon,
		name: 'videoHelp',
		type: ICON_TYPE.globalNavigation,
	},
	visibilityOff: {
		children: VisibilityOffIcon,
		name: 'visibilityOff',
		type: ICON_TYPE.contextual,
	},
	visibilityOn: {
		children: VisibilityOnIcon,
		name: 'visibilityOn',
		type: ICON_TYPE.contextual,
	},
};

export { iconConfigMap };
