type Props = {
	className?: string;
};

export const SearchColumnIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M2.57975 6H6.75438C6.58127 6.31833 6.43105 6.65252 6.30611 7H2.2256C2.32433 6.65785 2.44282 6.32408 2.57975 6ZM1.99827 8H6.02547C5.95867 8.32545 5.91316 8.65936 5.89056 9H1.88749C1.90603 8.66134 1.9433 8.32767 1.99827 8ZM1.88749 10H5.89056C5.91316 10.3406 5.95867 10.6746 6.02547 11H1.99827C1.9433 10.6723 1.90603 10.3387 1.88749 10ZM2.2256 12H6.30611C6.43105 12.3475 6.58127 12.6817 6.75438 13H2.57975C2.44282 12.6759 2.32433 12.3421 2.2256 12ZM13.1685 4.5C15.9299 4.5 18.1685 6.73858 18.1685 9.5C18.1685 12.2614 15.9299 14.5 13.1685 14.5C11.9881 14.5 10.9032 14.091 10.0479 13.4069L6.58062 16.8731L5.87352 16.166L9.33234 12.7071C8.6058 11.839 8.16846 10.7206 8.16846 9.5C8.16846 6.73858 10.407 4.5 13.1685 4.5ZM13.1685 5.5C10.9593 5.5 9.16846 7.29086 9.16846 9.5C9.16846 11.7091 10.9593 13.5 13.1685 13.5C15.3776 13.5 17.1685 11.7091 17.1685 9.5C17.1685 7.29086 15.3776 5.5 13.1685 5.5Z"
	/>
);
