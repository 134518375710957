type Props = {
	className?: string;
};

export const AssignmentIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(4.5, 4.5)"
		d="M3 1v1.004H.997v7.992H9V7h1v3.5c0 .294-.206.5-.5.5h-9a.478.478 0 0 1-.5-.5V1.7c0-.294.206-.7.5-.7H3zm6.696-1L11 1.304 6.826 5.478 5 6l.522-1.826L9.696 0z"
	/>
);
