type Props = {
	className?: string;
};

export const EditIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(4, 3)"
		d="M6.619 1.369l3.006 3.006.292-.292L6.91 1.077l.896-.896a.582.582 0 0 1 .844 0l2.168 2.168a.582.582 0 0 1 0 .844L4.193 9.819A.547.547 0 0 1 3.77 10H1.602C1.241 10 1 9.759 1 9.398v-2.17c0-.18.06-.3.18-.42L6.62 1.368zM12 13H0v-1h12v1z"
	/>
);
