type Props = {
	className?: string;
};

export const InstructAcceptIcon = ({ className }: Props) => (
	<g>
		<path
			className={className}
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 3C10.7909 3 9 4.79086 9 7C9 9.20914 10.7909 11 13 11C15.2091 11 17 9.20914 17 7C17 4.79086 15.2091 3 13 3ZM15.3969 5.80422L14.6033 5.19578L12.6503 7.74316L11.7534 6.84642L11.0464 7.55358L12.7499 9.25684L15.3969 5.80422Z"
		/>
		<path d="M8.00001 7.01001H4.5V8.01001H8.12862C8.04548 7.69046 8.00086 7.35538 8.00001 7.01001Z" />
		<path d="M7.5 13.01V15.31L3.5 12.51L7.5 9.81001V12.01H13.5V13.01H7.5Z" />
	</g>
);
