type Props = {
	className?: string;
};

export const ResendIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3.5 5)"
		d="M5.502 7.402v1.58l-1-.733V6.285L6.215 4.66l.688.725L5.83 6.403l2.233 1.734 1.266-6.33-6.317 2.527.356.266-.6.8-1.78-1.334L10.671.193l-1.934 9.67-3.235-2.461z"
	/>
);
