type Props = {
	className?: string;
};

export const FilterV2Icon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M5 6l4 5v4h2v-4l4-5H5z"
	/>
);
