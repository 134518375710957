type Props = {
	className?: string;
};

export const CompareIcon = ({ className }: Props) => (
	<g className={className}>
		<path
			transform="translate(1, 1)"
			d="M9 13v1H8v-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h3V4h1v9zm1 0V8l2.998 3.996V8H13V6h-3V5h3a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-3zm-5-1.004h2.998V8l-2.997 3.996z"
		/>
	</g>
);
