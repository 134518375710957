// @ts-nocheck

import type { ReactNode } from 'react';

import React, {
	createContext,
	useCallback,
	useMemo,
	useContext,
	useState,
} from 'react';
import {
	csrfToken,
	getApiUrl,
	getCorrelationId,
	getCookieValue,
	getBusinessEnvironmentName,
	ENVIRONMENT_NAMES,
} from '@af/utils';

const getOktaUrl = () => {
	const ENV_OKTA_URL = process.env.REACT_APP_OKTA_LOGIN_URL;

	if (ENV_OKTA_URL) {
		return ENV_OKTA_URL;
	}

	const envName = getBusinessEnvironmentName();

	return [ENVIRONMENT_NAMES.production, ENVIRONMENT_NAMES.staging].includes(
		envName,
	)
		? 'https://login.accessfintech.com'
		: 'https://prototype.accessfintech.com';
};

function getCsrfCookieKeyByEnv() {
	const envName = getBusinessEnvironmentName();

	switch (envName) {
		case ENVIRONMENT_NAMES.nightly:
			return 'af-nightly-csrf-token';
		case ENVIRONMENT_NAMES.staging:
			return 'af-staging-csrf-token';
		default:
			return 'af-csrf-token';
	}
}

const API_URL = getApiUrl();
const OKTA_URL = getOktaUrl();
const OKTA_FORGOT_PASSWORD_URL = `${OKTA_URL}/signin/forgot-password`;
const REDIRECT_URL = encodeURIComponent(`${document.location.origin}/login`);
const CSRF_COOKIE_KEY = getCsrfCookieKeyByEnv();

type AuthParams = {
	username: string;
	password: string;
};

type AuthLogin = (authParams: AuthParams) => Promise<void>;
type AuthGetUser = () => Promise<any>;
type AuthSso = (ssoIssuer: string) => void;

type ContextType = {
	isAuthenticated: boolean | undefined | null;
	helpLink: string;
	login: AuthLogin;
	logout: () => void;
	getUser: AuthGetUser;
	sso: AuthSso;
};

type Provider = {
	children: ReactNode;
};

const DEFAULT_AUTH_CONTEXT = {
	isAuthenticated: null,
	helpLink: '',
	login: async (authParams: AuthParams) => {},
	logout: () => {},
	getUser: async () => {},
	sso: () => {},
};

const AuthContext = createContext<ContextType>(DEFAULT_AUTH_CONTEXT);

function createAuthError(message, response) {
	const error = new Error(message);

	// $FlowFixMe: I don't know how else to add info to errors.
	error.responseMeta = {
		status: response.status,
		statusText: response.statusText,
	};

	return error;
}

function AuthProvider({ children }: Provider) {
	const [isAuthenticated, setIsAuthenticated] = useState(null);

	function logout() {
		const redirect = `/api/auth/logout?redirectUrl=${REDIRECT_URL}`;
		window.location.replace(redirect);
	}

	const getUser = async () => {
		const { correlationId } = getCorrelationId();
		const token = getCookieValue(document.cookie, CSRF_COOKIE_KEY);

		if (token) {
			csrfToken.set(token);
		}

		const requestOptions: RequestOptions = {
			headers: {
				'x-correlation-id': correlationId,
				...(token ? { 'x-csrf-token': token } : null),
			},
		};
		const response = await fetch(`${API_URL}/users/me`, requestOptions);

		if (response.ok) {
			setIsAuthenticated(true);

			const user = await response.json();
			return user;
		} else {
			setIsAuthenticated(false);

			const error = createAuthError('getUser error', response);
			throw error;
		}
	};

	async function login({ username, password }: AuthParams) {
		// Kill any current active session. This prevents issues where valid
		// users are prevented from logging in, because they previously logged
		// in with an Okta user who doesn't have permission to the application.
		// https://access-fintech.atlassian.net/browse/FIN-6988
		await fetch(`${OKTA_URL}/api/v1/sessions/me`, {
			method: 'DELETE',
			credentials: 'include',
		});

		const tokenResponse = await fetch(`${OKTA_URL}/api/v1/authn`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username,
				password,
			}),
		});
		const { sessionToken } = await tokenResponse.json();

		if (tokenResponse.ok && sessionToken) {
			const redirect = `/api/auth/login?sessionToken=${sessionToken}&redirectUrl=${REDIRECT_URL}`;
			window.location.replace(redirect);
		} else {
			const error = createAuthError('Login error', tokenResponse);
			throw error;
		}
	}

	const sso = useCallback(ssoIssuer => {
		const redirect = `/api/auth/sso?idp=${ssoIssuer}&redirectUrl=${REDIRECT_URL}`;
		window.location.replace(redirect);
	}, []);

	const contextValue = useMemo(
		() => ({
			isAuthenticated,
			helpLink: OKTA_FORGOT_PASSWORD_URL,
			login,
			logout,
			getUser,
			sso,
		}),
		[isAuthenticated, sso],
	);

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
}

function useAuth() {
	return useContext(AuthContext);
}

export { AuthProvider, useAuth, AuthContext };

export type { AuthParams, AuthLogin, AuthGetUser, AuthSso };
