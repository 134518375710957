/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type {
	GQLCappedNotification,
	GQLDownloadNotification,
	GQLNotification,
	GQLSubscriptionNotification,
	GQLUser,
	GQLUserNotificationsArgs,
	GQLViewNotification,
} from '@af/api';
import type { User } from 'types/User';

import { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { UserContext } from 'contexts/UserContext';

const LIMIT = 30;

export const getUserNotificationsQuery = (connectionKey: string) => gql`
	query GetUserNotifications(
		$contextOrgId: ID!
		$offset: Int!
		$limit: Int!
		$isDismissed: Boolean
		$isRead: Boolean
	) {
		userMe {
			id
			notifications(
				contextOrgId: $contextOrgId
				offset: $offset
				limit: $limit
				isDismissed: $isDismissed
				isRead: $isRead
			) {
				totalItemsCount
				notifications @connection(key: ${connectionKey}) {
					id
					isDismissed
					isRead
					creationTime
					eventType
					message
					userId
					user {
						name
					}
					... on DownloadNotification {
						fileUrl
					}
					... on SubscriptionNotification {
						temporaryFilterExpressionId
					}
					... on ViewNotification {
						viewId
					}
				}
			}
		}
	}
`;

export type NotificationResponse = {
	__typename?:
		| 'DownloadNotification'
		| 'SubscriptionNotification'
		| 'ViewNotification';
	id: GQLNotification['id'];
	isDismissed: GQLNotification['isDismissed'];
	isRead: GQLNotification['isRead'];
	creationTime: GQLNotification['creationTime'];
	eventType: GQLNotification['eventType'];
	message: GQLNotification['message'];
	userId: GQLNotification['userId'];
	fileUrl?: GQLDownloadNotification['fileUrl'];
	viewId?: GQLViewNotification['viewId'];
	temporaryFilterExpressionId?: GQLSubscriptionNotification['temporaryFilterExpressionId'];
	user: {
		name: User['name'];
	};
};

export type QueryResponse = {
	userMe: {
		id: GQLUser['id'];
		notifications: {
			totalItemsCount: GQLCappedNotification['totalItemsCount'];
			notifications: Array<NotificationResponse>;
			__typename: 'CappedNotification';
		};
		__typename: 'User';
	};
};

export type QueryVariables = {
	contextOrgId: GQLUserNotificationsArgs['contextOrgId'];
	offset: GQLUserNotificationsArgs['offset'];
	limit: GQLUserNotificationsArgs['limit'];
	isDismissed: GQLUserNotificationsArgs['isDismissed'];
	isRead: GQLUserNotificationsArgs['isRead'];
};

type HookProps = {
	orgId?: QueryVariables['contextOrgId'];
	offset?: QueryVariables['offset'];
	limit?: QueryVariables['limit'];
	isDismissed?: QueryVariables['isDismissed'];
	isRead?: QueryVariables['isRead'];
	// Used to tell Apollo where to cache the entities. Useful when dealing with
	// multiple uses of the same query which need to be cached separately.
	// https://www.apollographql.com/docs/react/v2/caching/cache-interaction/#the-connection-directive
	connectionKey: string;
	pollInterval?: number;
};

function useGetUserNotificationsQuery({
	offset = 0,
	limit = LIMIT,
	isDismissed = false,
	isRead = false,
	orgId = null,
	connectionKey,
	pollInterval,
}: HookProps = {}) {
	const { orgId: userMeOrgId } = useContext(UserContext);

	const response = useQuery<QueryResponse, QueryVariables>(
		getUserNotificationsQuery(connectionKey),
		{
			variables: {
				contextOrgId: orgId || userMeOrgId,
				offset,
				limit,
				isDismissed,
				isRead,
			},
			pollInterval,
			notifyOnNetworkStatusChange: true,
		},
	);

	const notifications = useMemo(() => {
		return response?.data?.userMe?.notifications?.notifications || [];
	}, [response]);

	const count = response?.data?.userMe?.notifications?.totalItemsCount;

	return { ...response, notifications, count };
}

export { useGetUserNotificationsQuery };
