import type { NotificationGQL } from 'types/Notification';
import type { User, UserAPIData } from 'types/User';

import { TYPE } from 'utils/notifications';

function getUserName({
	firstName,
	lastName,
}: {
	firstName: UserAPIData['firstName'];
	lastName: UserAPIData['lastName'];
}): User['name'] {
	return [firstName, lastName].filter(Boolean).join(' ');
}

export const NotificationResolver = {
	Notification: {
		path: ({ data }: NotificationGQL) => {
			if (!data.risk) return null;
			const { journeyId } = data.risk;

			// TODO: Focusing on risk doesn't work
			return `/risks/by-journey/${journeyId}`;
		},
		downloadUri: ({ data }: NotificationGQL): string | undefined | null =>
			data.exportedContentFile || null,
		message: ({ type, data }: NotificationGQL): NotificationGQL['message'] => {
			const { user, risk } = data;
			const author = getUserName(user);

			switch (type.toLowerCase()) {
				// Both type of assignments behave the same way at the moment
				// Separate them in the future if you need to
				case TYPE.ASSIGNMENT:
				case TYPE.GROUP_ASSIGNMENT:
					return `You have been assigned a ${risk.severity} risk exception by ${author}. Click here to open the exception details.`;
				case TYPE.COMMENT:
					return `${author} added a comment`;
				case TYPE.MENTION:
					return `You have been mentioned by ${author}`;
				case TYPE.EXPORT_FILE_READY:
					return `Your download is now ready`;
				default:
					console.warn('Unknown notification type:', type);
					return '-';
			}
		},
	},
};
