type Props = {
	className?: string;
};

export const FocusIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 5)"
		d="M4 4h2v2H4V4zm5 5V6h1v4H6V9h3zM1 9h3v1H0V6h1v3zm8-8H6V0h4v4H9V1zM1 1v3H0V0h4v1H1z"
	/>
);
