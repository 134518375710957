type Props = {
	className?: string;
};

export const MinimizeIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6, 9)"
		d="M9.32.4v1.2H.66V.4z"
	/>
);
