import type { GQLContentType } from '@af/api';

type FriendlyNameDependencies = {
	displayName?: GQLContentType['displayName'];
	name?: GQLContentType['name'];
};

export const ContentTypeResolver = {
	ContentType: {
		friendlyName: ({
			displayName,
			name,
		}: FriendlyNameDependencies): string | undefined | null => {
			return displayName || name;
		},
	},
};
