// @ts-nocheck

import type { ExtractReturn } from '@af/types';
import type { ReactNode } from 'react';
import type { ActionType } from 'v2/constants/actions';

import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

type Action = {
	type: ActionType;
	data?: any;
};

type ContextType = {
	dispatch: (payload: Action) => ExtractReturn<typeof useState>;
	action: Action | undefined | null;
};

const Context = createContext<ContextType>({
	dispatch: () => {},
	action: null,
});

const useActionSubscription = (subscription: (payload: Action) => void) => {
	const prevActionRef = useRef();
	const { action } = useContext(Context);
	const isMountedRef = useRef(false);

	useEffect(() => {
		if (isMountedRef.current && action && action !== prevActionRef.current) {
			subscription(action);
		} else {
			isMountedRef.current = true;
		}
	}, [subscription, action]);

	useEffect(() => {
		prevActionRef.current = action;
	});
};

const useActionDispatcher = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('Missing `ActionDispatchProvider`');
	}

	return context.dispatch;
};

type ActionDispatchProps = {
	children: ReactNode;
};

function ActionDispatchProvider({ children }: ActionDispatchProps) {
	const [action, setAction] = useState<Action | undefined | null>();
	const value = useMemo(
		() => ({
			dispatch: ({ type, data = {} }) => setAction({ type, data }),
			action,
		}),
		[action, setAction],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
}

export { ActionDispatchProvider, useActionDispatcher, useActionSubscription };
