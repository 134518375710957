type Props = {
	className?: string;
};

export const MessagesIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3, 4.5)"
		d="M8.541 2a3.02 3.02 0 0 0-.03.75H.75v6.5h10.5V5.49a3.04 3.04 0 0 0 .75-.031V10H0V2h8.541zM2 7.35v-.7h2.002v.7H2zm0-2v-.7h7.002v.7H2zm9.5-.85a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-.45a1.55 1.55 0 1 0 0-3.1 1.55 1.55 0 0 0 0 3.1zm-.61-1.523l.456.512.86-1.209.163.116-1.002 1.407-.626-.693.149-.133z"
	/>
);
