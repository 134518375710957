type Props = {
	className?: string;
};

export const FavoriteFullIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M16.489 7.894a.228.228 0 0 0-.184-.155l-4.215-.613-1.885-3.82c-.077-.155-.333-.155-.41 0L7.91 7.127l-4.215.613a.228.228 0 0 0-.126.389l3.05 2.973-.72 4.198a.228.228 0 0 0 .331.24L10 13.557l3.77 1.982a.228.228 0 0 0 .331-.24l-.72-4.198 3.05-2.973a.229.229 0 0 0 .058-.234z"
	/>
);
