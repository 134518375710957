export const DAYS_OF_THE_WEEK = {
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	sunday: 'Sunday',
};

export type Day = keyof typeof DAYS_OF_THE_WEEK;
