type Props = {
	className?: string;
};

export const InstructIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M14.5 13h-6v2.3l-4-2.8 4-2.7V12h6v1zm-9-6h6V4.8l4 2.7-4 2.8V8h-6V7z"
	/>
);
