type Props = {
	className?: string;
};

export const InfiniteIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3 7)"
		d="M6.3 3C4.964 1.662 3.967 1 3.4 1c-1.082 0-1.9.757-1.9 2 0 1.243.818 2 1.9 2 .567 0 1.564-.662 2.9-2zm0 0c.111-.11.224-.226.34-.346C8.331.89 9.613 0 10.6 0c1.618 0 2.9 1.187 2.9 3s-1.282 3-2.9 3c-.986 0-2.268-.89-3.96-2.654A24.49 24.49 0 0 0 6.3 3zm1.06.346C5.669 5.11 4.387 6 3.4 6 1.782 6 .5 4.813.5 3s1.282-3 2.9-3c.986 0 2.268.89 3.96 2.654.116.12.229.235.34.346-.111.11-.224.226-.34.346zM10.6 5c1.082 0 1.9-.757 1.9-2 0-1.243-.818-2-1.9-2-.567 0-1.564.662-2.9 2 1.336 1.338 2.333 2 2.9 2z"
	/>
);
