type Props = {
	className?: string;
};

export const BuyInNotification = ({ className }: Props) => (
	<path
		className={className}
		d="M10.9583333,5 L15.8333333,9.58333333 L10.9583333,14.1666667 L10.9583333,11.3020833 L10.020691,11.3012281 C8.13397279,11.313682 6.21890476,11.506369 5,13.59375 C5,8.71558236 8.11535502,7.89267959 10.8141724,7.86530724 L10.9583333,7.86458333 L10.9583333,5 Z M11.7908333,6.92583333 L11.7916667,8.69791667 L10.9583333,8.69791667 C8.50343949,8.69791667 6.91832415,9.33839208 6.229264,11.0827777 L6.1725,11.2316667 L6.22018691,11.2056212 C7.10688649,10.7243385 8.18480641,10.5177938 9.53072501,10.4762663 L9.95693686,10.4682813 L11.7916667,10.46875 L11.7908333,12.24 L14.6166667,9.58333333 L11.7908333,6.92583333 Z"
	/>
);
