/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import { captureException, withScope } from '@sentry/browser';

class ApiError extends Error {
	response: ApiErrorResponse;

	constructor({ responseMeta }: ApiErrorParams) {
		const { status, statusText, method = 'GET', url } = responseMeta;

		const statusMessage = [status, statusText].join(' - ');
		const urlMessage = [method, url].join(' ');
		const message = `${statusMessage} on ${urlMessage}`;

		super(message);

		this.name = 'ApiError';
		this.response = responseMeta;
	}
}

export type UIError = {
	message: string;
	httpStatus?: number;
};
export type ReactErrorInfo = {
	[x: string]: string;
};

export function createError(message: string, httpStatus?: number): UIError {
	return { message, httpStatus };
}

export function getErrorTitleByStatus(httpStatus?: number): string {
	let title = '';
	const statusString = httpStatus ? httpStatus.toString() : '';

	switch (statusString) {
		case '401':
			title = 'Error - Unauthorized';
			break;
		case '403':
			title = 'Error - Forbidden';
			break;
		case '404':
			title = 'Error - Not Found';
			break;
		default:
			title = 'An error occured';
			break;
	}

	return title;
}

export function reportError({
	error,
	errorInfo,
}: {
	error: Error | ApiError;
	errorInfo?: ReactErrorInfo;
}) {
	withScope(scope => {
		if (errorInfo) {
			Object.keys(errorInfo).forEach(key => {
				scope.setExtra(key, errorInfo[key]);
			});
		}

		if (error instanceof ApiError) {
			Object.keys(error.response).forEach(key => {
				scope.setExtra(key, error.response[key]);
			});
		}

		captureException(error);
	});
}

export type ApiErrorResponse = {
	status: number;
	statusText: string;
	url: string;
	method?: string;
	requestBody?: any;
	responseBody?: any;
};
export type ApiErrorParams = {
	responseMeta: ApiErrorResponse;
};
