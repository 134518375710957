// @ts-nocheck

import type { ReactNode } from 'react';
import type { ReactErrorInfo } from 'utils/errors';

import React from 'react';
import styled from 'styled-components';

import { PrimaryButton } from 'ds/components/core/Button';
import { Typography } from 'ds/components/Typography';
import { reportError } from 'utils/errors';

import FullStatus from './FullStatus';

type Props = {
	children: ReactNode;
};

type State = {
	hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
	state = {
		hasError: false,
	};

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ReactErrorInfo) {
		reportError({ error, errorInfo });
	}

	render() {
		if (this.state.hasError) {
			return (
				<Wrapper>
					<FullStatus icon="alert" variant="large" badgeVariant="filled">
						<Typography size="large" as="h1">
							Error
						</Typography>

						<Typography size="normal" as="p">
							There was a problem with the application
						</Typography>

						<PrimaryButton color="brandBlue" onClick={window.location.reload}>
							Reload
						</PrimaryButton>
					</FullStatus>
				</Wrapper>
			);
		}

		return this.props.children;
	}
}

const Wrapper = styled.div`
	height: 100%;
	display: flex;
`;
