type Props = {
	className?: string;
};

export const DismissContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M16.445 10.85l.496.064a7.055 7.055 0 000-1.828l-.496.064-.496.065a6.057 6.057 0 010 1.57l.496.065zm-.438-3.338l.462-.192a6.995 6.995 0 00-.915-1.581l-.397.304-.396.305c.317.413.582.868.784 1.356l.462-.192zm-2.05-2.67l.304-.396a6.993 6.993 0 00-1.581-.915l-.192.462-.191.462c.487.202.942.467 1.355.784l.305-.396zM10.85 3.556l.064-.496a7.065 7.065 0 00-1.828 0l.064.496.065.496a6.064 6.064 0 011.57 0l.065-.496zm-3.338.438l-.192-.462c-.569.236-1.1.545-1.581.915l.304.397.305.396a5.993 5.993 0 011.356-.784l-.192-.462zm-2.67 2.05l-.396-.304a6.993 6.993 0 00-.915 1.581l.462.192.462.192c.202-.488.467-.943.784-1.356l-.396-.305zM3 10c0-.31.02-.615.06-.914l.495.064.496.065a6.064 6.064 0 000 1.57l-.496.065-.496.064C3.02 10.615 3 10.31 3 10zm.993 2.488l-.462.192c.236.569.545 1.1.915 1.582l.397-.305.396-.305a5.994 5.994 0 01-.784-1.355l-.462.191zm2.05 2.67l-.304.396c.481.37 1.012.679 1.581.915l.192-.462.192-.462a5.991 5.991 0 01-1.356-.784l-.305.396zm3.107 1.287l-.064.496a7.054 7.054 0 001.828 0l-.064-.496-.065-.496a6.057 6.057 0 01-1.57 0l-.065.496zm3.338-.438l.192.462c.569-.236 1.1-.545 1.582-.915l-.305-.397-.305-.396a5.992 5.992 0 01-1.355.784l.191.462zm2.67-2.05l.396.304c.37-.481.679-1.012.915-1.581l-.462-.192-.462-.191a5.992 5.992 0 01-.784 1.355l.396.305zM7.5 7.5h5v5h-5v-5zm.8.8h3.4v3.4H8.3V8.3z"
	/>
);
