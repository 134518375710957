import { localStorage } from '@af/utils';

export const getCurrentHostUrl = (): string => {
	const { protocol, host } = window.location;
	const url = `${protocol}//${host}`;

	return url;
};

const preventRedirectUrls = ['/logout', '/login'];
export const navigateToLogout = () => {
	if (
		preventRedirectUrls.some(path => window.location.pathname.includes(path))
	) {
		// Prevent infinite loop
		return;
	}

	const referrer = window.location.pathname + window.location.search;
	if (referrer) {
		localStorage.set('loginRedirect', referrer);
	}

	window.location.href = `/logout`;
};
