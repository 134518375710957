import { configureScope } from '@sentry/browser';
import { guid } from '@af/utils';

export function getCorrelationId() {
	const correlationId = guid();

	configureScope(scope => {
		scope.setTag('correlationId', correlationId);
	});

	return { correlationId };
}
