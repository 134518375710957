type Props = {
	className?: string;
};

export const PairedContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M8.732 11.975v1H6.405a3.008 3.008 0 0 1-2.968-2.981A3.007 3.007 0 0 1 6.41 7.025h2.321v1H6.417a2.003 2.003 0 0 0-1.98 1.969 2.004 2.004 0 0 0 1.974 1.98h2.321zm2.536-3.95v-1h2.327a3.008 3.008 0 0 1 2.968 2.981 3.007 3.007 0 0 1-2.974 2.969h-2.321v-1h2.315a2.003 2.003 0 0 0 1.98-1.969 2.004 2.004 0 0 0-1.974-1.98h-2.321zM6.672 10.5v-1h6.656v1H6.672z"
	/>
);
