// @ts-nocheck

import styled from 'styled-components';

import { color } from 'styles';

export const AvatarImg = styled.img`
	width: 100%;
`;

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${color.abbey};
	color: ${color.iron};
	width: ${props => props.size}px;
	height: ${props => props.size}px;
	flex: 0 0 ${props => props.size}px;
	border-radius: ${props => props.size}px;
	overflow: hidden;
`;
