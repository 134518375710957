type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const ActionCentreOffIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M18.5 5.5h-5v6h5v-6zM18.5 14.5h-5v4h5v-4zM10.5 5.5h-5v4h5v-4zM10.5 12.5h-5v6h5v-6z"
			stroke={color || 'currentColor'}
			strokeOpacity={0.65}
		/>
	</svg>
);

export default ActionCentreOffIcon;
