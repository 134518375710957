type Props = {
	className?: string;
};

export const VisibilityOnIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		d="M10 14.256c2.445 0 4.347-2.09 5.162-3.303.34-.472.34-1.079 0-1.55-.815-1.147-2.717-3.236-5.162-3.236S5.653 8.257 4.838 9.47c-.34.472-.34 1.078 0 1.483.815 1.213 2.717 3.303 5.162 3.303zm0-6.067c1.155 0 2.038.876 2.038 2.022s-.883 2.022-2.038 2.022c-1.155 0-2.038-.876-2.038-2.022S8.845 8.19 10 8.19z"
	/>
);
