type Props = {
	className?: string;
};

export const MergeIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6, 6)"
		d="M1 3v4h4V3H1zM0 2h6v6H0V2zm3-1v4h4V1H3zM2 0h6v6H2V0z"
	/>
);
