import type {
	Alphas,
	BaseColor,
	BaseColors,
	BaseSeverityColors,
	Color,
	Colors,
	IconAlphas,
	Luminosities,
	SeverityColors,
} from './types/colors';
import type { BaseTypography, TypeAlphas, Typography } from './typography';

import { css } from 'styled-components';

import { type Spacings, SPACINGS } from './spacings';
import { alphaToHsla } from './utils';

export type ThemeName = 'v2dark' | 'v2light';

type ThemeType<C, CP, SC, T> = {
	id: ThemeName;
	color: {
		bg: C;
		fg: C;
		primary: C;
		palette: CP;
		severity: SC;
		baseLum: number;
		maxLum: number;
		lum: Luminosities;
		alpha: Alphas;
		iconAlpha: IconAlphas;
		typeAlpha: TypeAlphas;
	};
	spacing: Spacings;
	boxShadow: {
		blurRadius: string;
	};
	typography: T;
};

// Shorthand theme type, gets converted to `Theme` before being used
// Don't use this
export type BaseTheme = ThemeType<
	BaseColor,
	BaseColors,
	BaseSeverityColors,
	BaseTypography
>;

// Main theme type which will represent `props.theme`
export type Theme = ThemeType<Color, Colors, SeverityColors, Typography>;

// All base colors as taken from Sketch
const BASE_COLORS_V2: BaseColors = {
	black: { h: 0, s: 0, l: 0, a: 1, direction: 1 },
	white: { h: 0, s: 0, l: 100, a: 1, direction: -1 },
	brandBlack: { h: 225, s: 14, l: 12, a: 1, direction: 1 },
	brandWhite: { h: 225, s: 14, l: 99, a: 1, direction: -1 },
	// Deprecated - use `positive` instead only for positive feedback
	brandGreen: { h: 164, s: 44, l: 34, a: 1, direction: -1 },
	brandBlue: { h: 210, s: 100, l: 37, a: 1, direction: -1 },
	riskRed: { h: 345, s: 76, l: 41, a: 1, direction: -1 },
	riskPurple: { h: 240, s: 36, l: 51, a: 1, direction: -1 },
	riskGrey: { h: 219, s: 6, l: 42, a: 1, direction: -1 },
	riskGeneric: { h: 214, s: 62, l: 45, a: 1, direction: 1 },
	warning: { h: 360, s: 70, l: 40, a: 1, direction: 1 },
	positive: { h: 160, s: 100, l: 26, a: 1, direction: 1 },
	allocationGreen: { h: 81, s: 44, l: 45, a: 1, direction: 1 },
	allocationBlue: { h: 210, s: 63, l: 63, a: 1, direction: 1 },
	allocationOrange: { h: 5, s: 76, l: 72, a: 1, direction: 1 },
	allocationYellow: { h: 47, s: 100, l: 64, a: 1, direction: 1 },
	instructionUnderReview: { h: 210, s: 56, l: 45, a: 1, direction: 1 },
	instructionAccept: { h: 160, s: 80, l: 24, a: 1, direction: 1 },
	instructionPending: { h: 224, s: 14, l: 38, a: 1, direction: 1 },
	instructionReject: { h: 360, s: 55, l: 40, a: 1, direction: 1 },
	statusReview: { h: 210, s: 56, l: 45, a: 1, direction: 1 },
	statusPending: { h: 224, s: 25, l: 43, a: 1, direction: 1 },
	statusClosed: { h: 224, s: 14, l: 32, a: 1, direction: 1 },
};

const baseV2Theme: BaseTheme = {
	id: 'v2dark',
	color: {
		bg: BASE_COLORS_V2.brandBlack,
		fg: BASE_COLORS_V2.white,
		primary: BASE_COLORS_V2.brandBlue,
		palette: BASE_COLORS_V2,
		severity: {
			low: BASE_COLORS_V2.riskGrey,
			medium: BASE_COLORS_V2.riskPurple,
			high: BASE_COLORS_V2.riskRed,
		},
		baseLum: 0,
		maxLum: 8,
		lum: {
			disabled: 2,
			default: 4,
			active: 6,
			rollover: 8,
		},
		alpha: {
			low: 0.03,
			medium: 0.06,
			high: 0.09,
			extreme: 0.12,
		},
		iconAlpha: {
			disabled: 0.15,
			active: 0.3,
			selected: 0.5,
			rollover: 0.7,
		},
		typeAlpha: { low: 0.5, medium: 0.8, high: 0.9 },
	},
	spacing: SPACINGS,
	boxShadow: {
		blurRadius: `0 0 9px 0`,
	},
	typography: {
		size: {
			large: {
				default: css`
					font-size: 1.7rem;
					font-weight: 400;
					line-height: 2.5rem;
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.8)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 1)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.9)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
				`,
			},
			medium: {
				default: css`
					font-size: 1.3rem;
					font-weight: 400;
					line-height: 1.5rem;
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.8)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 1)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.9)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
				`,
			},
			normal: {
				default: css`
					font-size: 1.2rem;
					font-weight: 400;
					line-height: 1.5rem;
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.8)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 1)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.9)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
				`,
			},
			// Waiting for designs for Hakim to update these values. Currently I've got only the default one from risk summary design
			normalSecondary: {
				default: css`
					font-size: 1.2rem;
					font-weight: 400;
					line-height: 1.5rem;
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.7)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 1)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.9)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
				`,
			},
			// Waiting for designs for Hakim to update these values. Currently I've got only the default one from risk summary desig
			normalTertiary: {
				default: css`
					font-size: 1.2rem;
					font-weight: 400;
					line-height: 1.5rem;
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 1)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.9)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
				`,
			},
			small: {
				default: css`
					font-size: 1.2rem;
					font-weight: 400;
					line-height: 1.5rem;
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.6)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.9)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.7)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
				`,
			},
			tiny: {
				default: css`
					font-size: 1.1rem;
					font-weight: 400;
					line-height: 1.5rem;
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.6)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.8)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.75)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.white, 0.5)};
				`,
			},
			/* The typography sizes will have to change a little bit so I am adding some new stuff
				to finish the work here and we will need to come back and redo the styles
				as soon as Hakim will provide a design for it. I am adding only the default styles for now
			*/
			/* 1. Don't know why on the right the space is smaller than the left side. */
			minuscule: {
				default: css`
					display: inline-flex;
					font-size: 0.8rem;
					font-weight: 600;
					line-height: 0.9rem;
					color: ${alphaToHsla(BASE_COLORS_V2.black, 1)};
				`,
				rollover: css`
					color: ${alphaToHsla(BASE_COLORS_V2.black, 1)};
				`,
				selected: css`
					color: ${alphaToHsla(BASE_COLORS_V2.black, 0.9)};
				`,
				disabled: css`
					color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
				`,
			},
		},
		alignment: {
			left: 'left',
			center: 'center',
			right: 'right',
		},
	},
};

// If you add a new theme, make sure to add a custom ag-grid stylesheet in src/hacks/ag-grid
export const BASE_THEMES: {
	[k in ThemeName]: BaseTheme;
} = {
	v2dark: {
		...baseV2Theme,
		id: 'v2dark',
		color: {
			...baseV2Theme.color,
			bg: BASE_COLORS_V2.brandBlack,
			fg: BASE_COLORS_V2.white,
		},
	},
	v2light: {
		...baseV2Theme,
		id: 'v2light',
		color: {
			...baseV2Theme.color,
			bg: BASE_COLORS_V2.brandWhite,
			fg: BASE_COLORS_V2.black,
			primary: BASE_COLORS_V2.brandBlue,
		},
		typography: {
			size: {
				large: {
					default: css`
						font-size: 1.7rem;
						font-weight: 400;
						line-height: 2.5rem;
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.8)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
				},
				medium: {
					default: css`
						font-size: 1.3rem;
						font-weight: 400;
						line-height: 1.5rem;
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.8)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
				},
				normal: {
					default: css`
						font-size: 1.2rem;
						font-weight: 400;
						line-height: 1.5rem;
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.8)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
				},
				// Waiting for designs for Hakim to update these values. Currently I've got only the default one from risk summary design
				normalSecondary: {
					default: css`
						font-size: 1.2rem;
						font-weight: 400;
						line-height: 1.5rem;
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.7)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 0.9)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
				},
				// Waiting for designs for Hakim to update these values. Currently I've got only the default one from risk summary desig
				normalTertiary: {
					default: css`
						font-size: 1.2rem;
						font-weight: 400;
						line-height: 1.5rem;
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 0.9)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
				},
				small: {
					default: css`
						font-size: 1.2rem;
						font-weight: 400;
						line-height: 1.5rem;
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.6)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 0.8)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 0.8)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
				},
				tiny: {
					default: css`
						font-size: 1.1rem;
						font-weight: 400;
						line-height: 1.5rem;
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.8)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.black, 0.46)};
					`,
				},
				// Waiting for designs for Hakim to update these values. Currently I've got only the default one from risk summary desig
				/* 1. Don't know why on the right the space is smaller than the left side. */
				minuscule: {
					default: css`
						display: inline-flex;
						font-size: 0.8rem;
						font-weight: 400;
						line-height: 0.9rem;
						color: ${alphaToHsla(BASE_COLORS_V2.white, 1)};
					`,
					rollover: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 1)};
					`,
					selected: css`
						color: ${alphaToHsla(BASE_COLORS_V2.brandBlue, 0.9)};
					`,
					disabled: css`
						color: ${alphaToHsla(BASE_COLORS_V2.white, 0.46)};
					`,
				},
			},
			alignment: {
				left: 'left',
				center: 'center',
				right: 'right',
			},
		},
	},
};

export const THEME_LIST = Object.keys(BASE_THEMES) as Array<ThemeName>;
