import type {
	GQLNotificationEventType,
	GQLNotificationEventTypeInput,
	GQLNotificationSettingsEventDataInput,
} from '@af/api';

import { useContext, useEffect, useMemo, useState } from 'react';
import { AlertOutlinedIcon } from '@af/icons';
import { ApolloError } from 'apollo-client';

import { UserContext } from 'contexts/UserContext';
import { Switch } from 'ds/components/core/Switch';
import { ErrorMessage } from 'ds/components/ErrorMessage';
import { LoadingOverlay } from 'ds/components/LoadingOverlay';
import { Typography } from 'ds/components/Typography';
import { Box } from 'v2/components/Box';
import { Theme } from 'v2/components/Theme';
import { Toast, ToastMessage } from 'v2/components/Toast';
import { Message } from 'v2/configuration/components/Message';

import { ICON_SIZE, NOTIFICATION_SETTINGS_LIST } from './constants';
import {
	ActionTitle,
	ApplyButton,
	Body,
	CancelButton,
	EventType,
	EventTypeLabel,
	Footer,
	Header,
	HeaderSubtitle,
	HeaderTitle,
	NotificationAlertMessage,
} from './styles';
import { useGetUserNotificationSettingsQuery } from './useGetUserNotificationSettingsQuery';
import { useUpdateNotificationSettingsMutation } from './useUpdateNotificationSettingsMutation';

type NotificationSettingsType = {
	[key in GQLNotificationEventType]?: {
		isEnabled: boolean;
		isDirty: boolean;
	};
};

type NotificationSettingsProps = {
	onCancel: () => void;
};

const NotificationSettings = ({ onCancel }: NotificationSettingsProps) => {
	const { orgId } = useContext(UserContext);
	const {
		loading: isGetNotificationSettingsLoading,
		error: getNotificationSettingsError,
		notificationSettings,
	} = useGetUserNotificationSettingsQuery();
	const [
		updateNotificationSettings,
		{ loading: isUpdateNotificationSettingsLoading },
	] = useUpdateNotificationSettingsMutation();

	const [localNotificationSettings, setLocalNotificationSettings] =
		useState<NotificationSettingsType | null>(null);
	const [apolloError, setApolloError] = useState<
		ApolloError | undefined | null | unknown
	>(null);
	const [hasSuccess, setHasSuccess] = useState<boolean>(false);

	const isLoading =
		isGetNotificationSettingsLoading || isUpdateNotificationSettingsLoading;

	const hasDirtyFields = useMemo(
		() =>
			localNotificationSettings &&
			Object.entries(localNotificationSettings).filter(
				([, data]) => !!data?.isDirty,
			),
		[localNotificationSettings],
	);

	const updateLocalNotificationSettings = (
		eventType: GQLNotificationEventType[],
	) => {
		if (!eventType) return;

		setLocalNotificationSettings(prevState => {
			if (!prevState) {
				return null;
			}

			let updatedNotificationSettings = {};

			updatedNotificationSettings = eventType.reduce((acc, curr) => {
				return Object.assign(acc, {
					[curr]: {
						isEnabled: !prevState?.[curr]?.isEnabled,
						isDirty: !prevState?.[curr]?.isDirty,
					},
				});
			}, {});

			return {
				...prevState,
				...updatedNotificationSettings,
			};
		});
	};

	const isNotificationSettingEnabled = (
		eventType: GQLNotificationEventType[],
	) => {
		const hasCombinedTruthyValue = eventType.every(
			event => localNotificationSettings?.[event]?.isEnabled,
		);

		return hasCombinedTruthyValue;
	};

	const onApply = async () => {
		if (!hasDirtyFields?.length) {
			return;
		}

		const updatedNotificationSettings = NOTIFICATION_SETTINGS_LIST.reduce<
			Array<GQLNotificationSettingsEventDataInput>
		>((acc, notificationSetting) => {
			if (notificationSetting.isEditable) {
				notificationSetting.eventType.forEach(eventType => {
					acc.push({
						eventType: eventType as unknown as GQLNotificationEventTypeInput,
						isEnabled: !!localNotificationSettings?.[eventType]?.isEnabled,
					});
				});
			}

			return acc;
		}, []);

		try {
			await updateNotificationSettings({
				variables: {
					contextOrgId: orgId,
					updateNotificationSettings: updatedNotificationSettings,
				},
			});
		} catch (error) {
			setApolloError(error);
			setLocalNotificationSettings(notificationSettings);
			return;
		}

		setHasSuccess(true);
	};

	useEffect(() => {
		setLocalNotificationSettings(notificationSettings);
	}, [notificationSettings]);

	return (
		<Content isLoading={isLoading}>
			<Header>
				<HeaderTitle>Configure Notifications</HeaderTitle>
				<HeaderSubtitle>
					Choose which notifications you will receive
				</HeaderSubtitle>
			</Header>
			<Box grow shrink>
				<Body>
					{apolloError && (
						<Toast onDismiss={() => setApolloError(null)} autoClose={3000}>
							<ToastMessage>
								<ErrorMessage error={apolloError} />
							</ToastMessage>
						</Toast>
					)}

					{hasSuccess && (
						<Toast onDismiss={() => setHasSuccess(false)} autoClose={3000}>
							<ToastMessage>
								Notification settings was successfully updated
							</ToastMessage>
						</Toast>
					)}

					<ActionTitle>
						<Typography size="tiny" transform="uppercase">
							Actions
						</Typography>
					</ActionTitle>

					{!isLoading && getNotificationSettingsError && (
						<Message icon="alert">
							<ErrorMessage error={getNotificationSettingsError} />
						</Message>
					)}

					{!!localNotificationSettings &&
						!isLoading &&
						!getNotificationSettingsError && (
							<>
								{NOTIFICATION_SETTINGS_LIST.map((item, index) => {
									const isSettingEnabled = isNotificationSettingEnabled(
										item.eventType,
									);

									return (
										<EventType key={`${item.eventType}-${index}`}>
											<EventTypeLabel isEnabled={isSettingEnabled}>
												{item.label}
											</EventTypeLabel>

											<Switch
												onChange={() =>
													updateLocalNotificationSettings(item.eventType)
												}
												isDisabled={!item.isEditable}
												isChecked={isSettingEnabled}
											/>
										</EventType>
									);
								})}
								<Box stack="horizontal" padding={['medium']}>
									<AlertOutlinedIcon
										size={ICON_SIZE}
										title="Notification removal"
									/>
									<NotificationAlertMessage>
										Notifications will be auto-deleted after 30 days
									</NotificationAlertMessage>
								</Box>
							</>
						)}
				</Body>
				<Footer>
					<Theme baseLum={6}>
						<CancelButton onClick={onCancel}>Cancel</CancelButton>
					</Theme>
					<ApplyButton disabled={!hasDirtyFields?.length} onClick={onApply}>
						Apply
					</ApplyButton>
				</Footer>
			</Box>
		</Content>
	);
};

type PageContentProps = {
	isLoading: boolean;
	children: React.ReactNode;
};

const Content = ({ isLoading, children }: PageContentProps) => (
	<Box as="article" grow>
		{children}
		{isLoading && <LoadingOverlay delay={0} />}
	</Box>
);

export { NotificationSettings };
