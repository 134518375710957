// @ts-nocheck

import type { FullStatusVariant } from 'components/FullStatus';

import React from 'react';

import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import FullStatus from 'components/FullStatus';
import { getErrorTitleByStatus } from 'utils/errors';

import { Message, Title } from './styles';

type Props = {
	message: string;
	debug?: string;
	title?: string;
	nextPath?: string;
	nextLabel?: string;
	className?: string;
	httpStatus?: number;
	onRetry?: () => void;
	variant: FullStatusVariant;
};

const DebugMessage = props =>
	process.env.NODE_ENV === 'production' ? null : (
		<Message variant="small" {...props} />
	);
export function Error({
	message,
	debug,
	title,
	httpStatus,
	nextPath,
	nextLabel,
	className,
	variant,
	onRetry,
	...props
}: Props) {
	const errorTitle =
		title || (httpStatus ? getErrorTitleByStatus(httpStatus) : 'Error');

	return (
		<FullStatus
			icon="alert"
			variant={variant}
			badgeVariant="filled"
			className={className}
			data-test="error-component"
		>
			<Title variant={variant}>{errorTitle}</Title>

			<Message variant={variant}>{message}</Message>

			{debug && <DebugMessage>{debug}</DebugMessage>}

			{nextPath && <ButtonLink to={nextPath}>{nextLabel}</ButtonLink>}

			{onRetry && <Button onClick={onRetry}>Retry</Button>}
		</FullStatus>
	);
}

Error.defaultProps = {
	message: 'An error has occurred',
	variant: 'large',
};

/**
 * @deprecated please use named export from 'components/Error'
 */
export default Error;
