type Props = {
	className?: string;
};

export const MoreIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M14.5,11.5 C15.3284271,11.5 16,10.8284271 16,10 C16,9.17157288 15.3284271,8.5 14.5,8.5 C13.6715729,8.5 13,9.17157288 13,10 C13,10.8284271 13.6715729,11.5 14.5,11.5 Z M10,11.5 C10.8284271,11.5 11.5,10.8284271 11.5,10 C11.5,9.17157288 10.8284271,8.5 10,8.5 C9.17157288,8.5 8.5,9.17157288 8.5,10 C8.5,10.8284271 9.17157288,11.5 10,11.5 Z M5.5,11.5 C6.32842712,11.5 7,10.8284271 7,10 C7,9.17157288 6.32842712,8.5 5.5,8.5 C4.67157288,8.5 4,9.17157288 4,10 C4,10.8284271 4.67157288,11.5 5.5,11.5 Z"
	/>
);
