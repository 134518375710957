// @ts-nocheck

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonContent, getButtonStyles, propTypes } from '../Button';

const BUTTON_PROPS = Object.keys(propTypes);

const ButtonLink = styled(({ children, ...props }) => (
	<StyledButtonLink {...props} as={LinkWithoutCustomProps}>
		<ButtonContent>{children}</ButtonContent>
	</StyledButtonLink>
))``;

const StyledButtonLink = styled.button`
	${getButtonStyles};
`;

const LinkWithoutCustomProps = props => {
	const validProps = Object.keys(props).reduce((linkProps, prop) => {
		return BUTTON_PROPS.includes(prop)
			? linkProps
			: {
					...linkProps,
					[prop]: props[prop],
			  };
	}, {});

	return <Link {...validProps} />;
};

ButtonLink.displayName = 'ButtonLink';
ButtonLink.propTypes = propTypes;
ButtonLink.defaultProps = {
	variant: 'primary',
	size: 'auto',
};

export default ButtonLink;
