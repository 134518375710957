export const OPERATORS = {
	and: 'and',
	or: 'or',
	eq: 'eq',
	ne: 'ne',
	isNotNull: 'isNotNull',
	isNull: 'isNull',
	gt: 'gt',
	ge: 'ge',
	lt: 'lt',
	le: 'le',
	contains: 'contains',
	starts: 'starts',
	ends: 'ends',
	in: 'in',
	notIn: 'notIn',
	between: 'between',
	notContains: 'notContains',
};

export const OPERATOR_LABELS = {
	[OPERATORS.and]: 'and',
	[OPERATORS.or]: 'or',
	[OPERATORS.eq]: 'equals',
	[OPERATORS.ne]: `doesn't equal`,
	[OPERATORS.isNotNull]: 'exists',
	[OPERATORS.isNull]: `doesn't exist`,
	[OPERATORS.gt]: 'greater than',
	[OPERATORS.ge]: 'greater than or equal to',
	[OPERATORS.lt]: 'less than',
	[OPERATORS.le]: 'less than or equal to',
	[OPERATORS.contains]: 'contains',
	[OPERATORS.notContains]: 'not contains',
	[OPERATORS.starts]: 'starts with',
	[OPERATORS.ends]: 'ends with',
	[OPERATORS.in]: 'one of',
	[OPERATORS.notIn]: 'not one of',
	[OPERATORS.between]: 'between',
};

const OPERATOR_PREFIXES = {
	[OPERATORS.ne]: `≠`,
	[OPERATORS.gt]: '>',
	[OPERATORS.ge]: '≥',
	[OPERATORS.lt]: '<',
	[OPERATORS.le]: '≤',
	[OPERATORS.contains]: 'contains:',
	[OPERATORS.notContains]: 'not contains',
	[OPERATORS.starts]: 'starts: ',
	[OPERATORS.ends]: 'ends:',
	[OPERATORS.notIn]: 'not in:',
};

export function getOperatorFriendlyName(operator: string) {
	return OPERATOR_LABELS[operator] || operator;
}

export function getTimeBasedValueLabel(value: number) {
	if (!value && value !== 0) return '';

	let label;

	if (value === 0) label = 'exact';
	else if (value < 0) label = 'minus';
	else label = 'plus';

	return label;
}

export function getOperatorValuePrefix(operator: string) {
	return OPERATOR_PREFIXES[operator];
}

const OPERATOR_SYMBOLS = {
	[OPERATORS.and]: '&',
	[OPERATORS.or]: '∧',
	[OPERATORS.eq]: '=',
	[OPERATORS.ne]: `≠`,
	[OPERATORS.isNotNull]: '∃',
	[OPERATORS.isNull]: `∄`,
	[OPERATORS.gt]: '>',
	[OPERATORS.ge]: '≥',
	[OPERATORS.lt]: '<',
	[OPERATORS.le]: '≤',
	[OPERATORS.contains]: '⊃',
	[OPERATORS.notContains]: '⊅',
	[OPERATORS.starts]: 'a',
	[OPERATORS.ends]: 'z',
	[OPERATORS.in]: '∈',
	[OPERATORS.notIn]: '∉',
	[OPERATORS.between]: '[  ]',
};

export function getOperatorSymbol(operator: string): string {
	return OPERATOR_SYMBOLS[operator] || operator;
}
