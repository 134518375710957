import styled, { css } from 'styled-components';

/**
 * 1. Required for Edge to allow flex items to shrink below their content width.
 */

export const StyledApp = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	color: ${({ theme }) => theme.color.fg.base};
	background-color: ${({ theme }) => theme.color.bg.base};
`;

export const Main = styled.main`
	flex: 1 1 0px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	min-width: 0; /* 1. */
	z-index: 1;
`;

export const GlobalNotificationContainer = styled.div`
	position: absolute;
	z-index: 200;

	${props => css`
		top: ${props.theme.spacing.small}px;
		right: ${props.theme.spacing.small}px;
	`};
`;
