type Props = {
	className?: string;
};

export const AmendIcon = ({ className }: Props) => (
	<path
		transform="translate(6, 5)"
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M4.00004 0.192261H9.15388V1.38457H8.15388V1.19226H7.07696V4.69226H7.26927V5.69226H5.88465V4.69226H6.07696V1.19226H5.00004V1.38457H4.00004V0.192261ZM-0.153809 2.61534H2.76927V3.61534H0.846191V8.84611H6.07696V7.96149H7.07696V9.84611H-0.153809V2.61534Z"
	/>
);
