type Props = {
	className?: string;
};

export const ExceptionsIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(7 7.5)"
		d="M2 4h4v1H2V4zM0 4h1v1H0V4zm2-2h4v1H2V2zM0 2h1v1H0V2zm2-2h4v1H2V0zM0 0h1v1H0V0z"
	/>
);
