import styled from 'styled-components';

import Button from 'components/Button';
import Icon, { ICONS } from 'ds/components/Icon';
import { color, size } from 'styles';

export const Header = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid ${color.shark};
`;
Header.displayName = 'Header';

Header.defaultProps = {
	children: <Icon config={ICONS.alert} size="large" badgeVariant="filled" />,
};

export const Footer = styled.footer`
	align-self: stretch;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: ${size.appNavLinkHeight};
	margin-top: 20px;
	border-top: 1px solid ${color.shark};
`;
Footer.displayName = 'Footer';

export const Content = styled.div`
	color: ${color.gallery};
	word-wrap: break-word;
	word-break: break-word;
	font-size: 16px;
	margin-top: auto;
	background: none;
	outline: none;
	border: none;
`;
Content.displayName = 'Content';

const BaseButton = styled(Button)`
	flex: 1 1 0%;
	margin: 0 6px;
`;

export const AffirmativeButton = styled(BaseButton)``;
AffirmativeButton.displayName = 'AffirmativeButton';

AffirmativeButton.defaultProps = {
	variant: 'darkPrimary',
};

export const DismissButton = styled(BaseButton)``;
DismissButton.displayName = 'DismissButton';

DismissButton.defaultProps = {
	variant: 'neutral',
};
