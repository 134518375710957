import { useEffect } from 'react';
import { useAuth } from '@af/hooks';
import { localStorage } from '@af/utils';
import { configureScope } from '@sentry/browser';
import { Redirect } from 'react-router-dom';

import { Loading } from 'components/Loading';
import { resetStore } from 'utils/apollo-client';

export function Logout() {
	const { logout, isAuthenticated } = useAuth();

	useEffect(() => {
		if (!isAuthenticated) return;

		// Clear apollo store
		const storeReset = resetStore();

		// Clear sentry
		configureScope(scope => scope.clear());

		// Clear local storage
		localStorage.clear();

		Promise.all([storeReset]);

		// Log out from the API
		logout();
	}, [isAuthenticated, logout]);

	return !isAuthenticated ? (
		<Redirect
			to={{
				pathname: '/login',
			}}
		/>
	) : (
		<Loading />
	);
}
