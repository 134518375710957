type Props = {
	className?: string;
};

export const TaskContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M16 4v13H4V4h3.5v2h5V4H16zm-1 1h-1.5v2h-7V5H5v11h10V5zm-3.5 0V3h-3v2h3zM8 12v-1H7v1h1zm0 2v-1H7v1h1zm5-2v-1H9v1h4zm-5-2V9H7v1h1zm5 0V9H9v1h4zm0 4v-1H9v1h4z"
	/>
);
