type Props = {
	className?: string;
};

export const CommentContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M13,13.1 L6,13.1 L6,11.9 L13,11.9 L13,13.1 Z M15,10.1 L6,10.1 L6,8.9 L15,8.9 L15,10.1 Z M14,7.1 L6,7.1 L6,5.9 L14,5.9 L14,7.1 Z"
	/>
);
