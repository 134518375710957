import { position } from 'polished';
import styled from 'styled-components';

import { Loading } from 'components/Loading';
import { fadeIn } from 'styles/animation';

export const LoadingOverlay = styled(Loading).attrs(() => ({
	'data-test': 'loading-spinner',
}))`
	${position('absolute', '0')};
	background-color: ${props =>
		!props.isTransparent && props.theme.color.bg.alpha.custom(0.3)};
	animation: ${fadeIn} 200ms ease-out;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`;
