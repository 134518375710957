// @ts-nocheck

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { getBusinessEnvironmentName } from 'utils/environment';

const envName = getBusinessEnvironmentName();

const getLDProvider = async () => {
	let clientSideID;

	switch (envName) {
		case 'production':
			clientSideID = process.env.REACT_APP_LAUNCHDARKLY_ID_PRODUCTION;
			break;
		case 'staging':
			clientSideID = process.env.REACT_APP_LAUNCHDARKLY_ID_STAGING;
			break;
		case 'nightly':
			clientSideID = process.env.REACT_APP_LAUNCHDARKLY_ID_NIGHTLY;
			break;
		default:
			clientSideID = process.env.REACT_APP_LAUNCHDARKLY_ID_DEV;
			break;
	}

	const LDProvider = await asyncWithLDProvider({
		clientSideID,
		user: {
			key: 'TestUser',
			firstName: 'Test',
			lastName: 'User',
			email: 'testuser@example.com',
		},
		reactOptions: {
			// Unfortunately, we have to reference the flag using bracket notation
			// so that code references pick it up.
			// See https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys
			useCamelCaseFlagKeys: false,
		},
	});

	return LDProvider;
};

export { getLDProvider };
