type Props = {
	className?: string;
};

export const CommentNavigationIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6, 5.5)"
		d="M7 8.1H0V6.9h7v1.2zm2-3H0V3.9h9v1.2zm-1-3H0V.9h8v1.2z"
	/>
);
