import type {
	GQLNotificationSettingsEventData,
	GQLUser,
	GQLUserNotificationsArgs,
} from '@af/api';

import { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { UserContext } from 'contexts/UserContext';

export const GET_USER_NOTIFICATION_SETTINGS_QUERY = gql`
	query GetUserNotificationSettingsQuery($contextOrgId: ID!) {
		userMe {
			id
			notificationSettings(contextOrgId: $contextOrgId) {
				eventType
				isEnabled
			}
		}
	}
`;

export type QueryResponse = {
	userMe: {
		id: GQLUser['id'];
		notificationSettings: Array<GQLNotificationSettingsEventData>;
	};
};

export type QueryVariables = {
	contextOrgId: GQLUserNotificationsArgs['contextOrgId'];
};

function useGetUserNotificationSettingsQuery() {
	const { orgId } = useContext(UserContext);

	const response = useQuery<QueryResponse, QueryVariables>(
		GET_USER_NOTIFICATION_SETTINGS_QUERY,
		{
			variables: {
				contextOrgId: orgId,
			},
			notifyOnNetworkStatusChange: true,
		},
	);

	const notificationSettings = useMemo(() => {
		const indexedNotificationSettings = {
			...response?.data?.userMe?.notificationSettings.reduce(
				(acc, curr) => ({
					...acc,
					[curr.eventType]: {
						isEnabled: curr.isEnabled,
						isDirty: false,
					},
				}),
				{},
			),
		};

		return indexedNotificationSettings || [];
	}, [response]);

	return { ...response, notificationSettings };
}

export { useGetUserNotificationSettingsQuery };
