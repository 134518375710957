// @ts-nocheck

import React from 'react';
import * as PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon, { ICONS } from 'ds/components/Icon';
import { Modal } from 'ds/components/Modal';

import { Footer, Header, Message } from './styles';

export default function ModalAlert({
	header,
	message,
	confirm,
	onConfirm,
	...props
}) {
	const styles = {
		content: {
			alignItems: 'center',
			paddingBottom: 0,
		},
	};

	return (
		<Modal style={styles} {...props}>
			<Header>{header}</Header>
			<Message>{message}</Message>
			<Footer>
				<Button variant="dark" size="180px" onClick={onConfirm}>
					{confirm}
				</Button>
			</Footer>
		</Modal>
	);
}

ModalAlert.propTypes = {
	header: PropTypes.node,
	message: PropTypes.node,
	confirm: PropTypes.string,
	onConfirm: PropTypes.func,
};

ModalAlert.defaultProps = {
	header: <Icon config={ICONS.alert} size="large" badgeVariant="filled" />,
	message: 'Alert!',
	confirm: 'Ok',
};
