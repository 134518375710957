type Props = {
	className?: string;
};

export const GlobalIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M7.5 1.57c-1.559.435-2.86 2.866-2.99 5.93H7.5V1.57zM3.51 7.5c.083-2.149.699-4.066 1.65-5.348A6.503 6.503 0 0 0 1.518 7.5h1.99zM8.5 1.57V7.5h2.99c-.13-3.064-1.431-5.495-2.99-5.93zm3.99 5.93h1.991a6.503 6.503 0 0 0-3.64-5.348c.95 1.282 1.566 3.2 1.65 5.348zm0 1c-.083 2.149-.699 4.066-1.65 5.348A6.503 6.503 0 0 0 14.482 8.5h-1.99zm-1 0H8.5v5.93c1.559-.435 2.86-2.866 2.99-5.93zM7.5 14.43V8.5H4.51c.13 3.064 1.431 5.495 2.99 5.93zM3.51 8.5H1.519a6.503 6.503 0 0 0 3.64 5.348c-.95-1.282-1.566-3.2-1.65-5.348zm4.49 7a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15z"
	/>
);
