type Props = {
	className?: string;
};

export const HandshakeOffIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3, 3)"
		d="M7 13A6 6 0 1 0 7 1a6 6 0 0 0 0 12zm0 1A7 7 0 1 1 7 0a7 7 0 0 1 0 14zm.972-12.921c.38.062.754.16 1.118.295L1.374 9.09a5.994 5.994 0 0 1-.295-1.118l6.893-6.893zm2.903 1.34a6.08 6.08 0 0 1 .707.706l-8.457 8.457a6.08 6.08 0 0 1-.707-.707l8.457-8.457zm2.046 3.61L6.028 12.92a5.994 5.994 0 0 1-1.118-.295l7.716-7.716c.135.364.233.739.295 1.118z"
	/>
);
