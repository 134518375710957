type Props = {
	className?: string;
};

export const InstructCompletedIcon = ({ className }: Props) => (
	<g>
		<path d="M7.5 13.01V15.31L3.5 12.51L7.5 9.81001V12.01H13.5V13.01H7.5Z" />
		<path d="M8.00001 7.01001H4.5V8.01001H8.12862C8.04548 7.69046 8.00086 7.35538 8.00001 7.01001Z" />
		<path
			className={className}
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.75 10.833a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm.75-5a.75.75 0 0 0-1.5 0v2.5a.75.75 0 0 0 1.5 0v-2.5Z"
		/>
	</g>
);
