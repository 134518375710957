type Props = {
	className?: string;
};

export const UsersIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3, 5)"
		d="M2.026 8.632A6.272 6.272 0 0 1 0 7.338c.567-1.462 1.904-2.492 3.2-2.492h1.429c.067.102.14.199.218.29-1.138.484-2.12 1.53-2.611 2.84L2 8.607l.026.026zm2.252-4.498c-.09.013-.184.02-.278.02-1.105 0-2-.93-2-2.077C2 .93 2.895 0 4 0c.521 0 .995.207 1.351.546-.756.557-1.249 1.485-1.249 2.53 0 .372.062.728.176 1.058zM8.648.546C9.006.206 9.48 0 10 0c1.105 0 2 .93 2 2.077 0 1.147-.895 2.077-2 2.077-.094 0-.187-.007-.278-.02.114-.33.176-.686.176-1.057 0-1.046-.493-1.974-1.25-2.531zm.723 4.3H10.8c1.296 0 2.633 1.03 3.2 2.492a6.272 6.272 0 0 1-2.026 1.294L12 8.607l-.236-.63c-.49-1.311-1.473-2.357-2.611-2.84a3.07 3.07 0 0 0 .218-.29zM7.091 11zM7 10c-1.483 0-2.86-.594-4-1.662.567-1.462 1.904-2.492 3.2-2.492h1.6c1.296 0 2.633 1.03 3.2 2.492C9.86 9.406 8.483 10 7 10zm0-4.846c-1.105 0-2-.93-2-2.077C5 1.93 5.895 1 7 1s2 .93 2 2.077c0 1.147-.895 2.077-2 2.077z"
	/>
);
