import { useState, useRef, useEffect } from 'react';

export type OffsetType = {
	top: number;
	left: number;
};

function useScrollOffset() {
	const [offset, setOffset] = useState<OffsetType>({ top: 0, left: 0 });
	const ref = useRef<HTMLElement | undefined | null>();

	useEffect(() => {
		const { current } = ref;
		if (!current) return;

		const handleScroll = () =>
			setOffset({
				top: current.scrollTop || 0,
				left: current.scrollLeft || 0,
			});

		current.addEventListener('scroll', handleScroll);

		return () => {
			current.removeEventListener('scroll', handleScroll);
		};
	});

	return [ref, offset];
}

export { useScrollOffset };
