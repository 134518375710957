type Props = {
	className?: string;
};

export const ApprovalIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(4, 3)"
		d="M12 1v13H0V1h3.5v2h5V1H12zm-1 1H9.5v2h-7V2H1v11h10V2zM7.5 2h-3V0h3v2zm.799 4l.79.614L5.24 11.56l-1.901-1.9.707-.708 1.1 1.1L8.299 6z"
	/>
);
