import type { ReactNode } from 'react';

import { createContext, useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useGetUserNotificationsCountQuery } from '../App/hooks/useGetUserNotificationsCountQuery';

type ContextValueType = {
	notificationsCount: number | null;
};

// The count is not to be display when it is 0
const ContextValueInitial = {
	notificationsCount: null,
};
const NotificationCountContext =
	createContext<ContextValueType>(ContextValueInitial);

const NotificationCountContextProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const flags = useFlags();
	const notificationsPollInterval = flags['notifications-poll-time'];

	const { count } = useGetUserNotificationsCountQuery({
		pollInterval: notificationsPollInterval,
	});

	const value = {
		notificationsCount: count,
	};
	return (
		<NotificationCountContext.Provider value={value}>
			{children}
		</NotificationCountContext.Provider>
	);
};

const useNotificationCountContext = () => {
	const context = useContext(NotificationCountContext);
	if (context === undefined) {
		throw new Error(
			'NotificationCountContext must be used within a NotificationCountContextProvider',
		);
	}
	return context;
};

export { NotificationCountContextProvider, useNotificationCountContext };
