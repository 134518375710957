type Props = {
	className?: string;
};

export const JourneyIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(2, 2)"
		d="M14 9.065A2.017 2.017 0 0 1 15.5 11c0 1.094-.906 2-2 2-1.094 0-2-.906-2-2 0-.922.643-1.71 1.5-1.935v-2.13A2.017 2.017 0 0 1 11.5 5c0-1.094.906-2 2-2 1.094 0 2 .906 2 2 0 .922-.643 1.71-1.5 1.935v2.13zM12.5 11c0 .542.458 1 1 1s1-.458 1-1-.458-1-1-1-1 .458-1 1zm2-6c0-.542-.458-1-1-1s-1 .458-1 1 .458 1 1 1 1-.458 1-1zM8 12.065A2.017 2.017 0 0 1 9.5 14c0 1.094-.906 2-2 2-1.094 0-2-.906-2-2 0-.922.643-1.71 1.5-1.935v-8.13A2.017 2.017 0 0 1 5.5 2c0-1.094.906-2 2-2 1.094 0 2 .906 2 2 0 .922-.643 1.71-1.5 1.935v8.13zM6.5 14c0 .542.458 1 1 1s1-.458 1-1-.458-1-1-1-1 .458-1 1zm0-12c0 .542.458 1 1 1s1-.458 1-1-.458-1-1-1-1 .458-1 1zM0 8c0-1.094.906-2 2-2 1.094 0 2 .906 2 2 0 1.094-.906 2-2 2-1.094 0-2-.906-2-2zm1 0c0 .542.458 1 1 1s1-.458 1-1-.458-1-1-1-1 .458-1 1z"
	/>
);
