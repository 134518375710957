type Props = {
	className?: string;
};

export const ApplyIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 4)"
		d="M3 10H.5c-.294 0-.5-.406-.5-.7V.5C0 .206.206 0 .5 0h8c.294 0 .5.206.5.5V4H8V1.004H.997v7.992H3V10zm8.612-5.312l.781.624-4.352 5.437L5.646 8.35l.708-.707 1.605 1.607 3.653-4.563z"
	/>
);
