// @ts-nocheck

import type { ReactElement } from 'react';

import React from 'react';
import { useMount } from '@af/hooks';
import { Portal } from 'react-portal';

import { Theme } from 'ds/components/core/Theme';
import {
	Toast as RawToast,
	ToastAction,
	ToastActions,
	ToastContent,
	ToastMessage,
} from 'ds/components/Toast';
import { APP_NOTIFICATION_PORTAL_ID } from 'utils/constants';

type Props = {
	autoClose?: number;
	onDismiss?: () => void;
	children: ReactElement<typeof ToastMessage>;
};

function Toast({ autoClose, onDismiss, children }: Props) {
	useMount(() => {
		let timer;

		if (autoClose && onDismiss) {
			timer = setTimeout(onDismiss, autoClose);
		} else if (autoClose) {
			timer = setTimeout(() => undefined, autoClose);
		}

		return () => {
			if (autoClose && timer) {
				clearTimeout(timer);
			}
		};
	});

	return (
		<Portal
			node={document && document.getElementById(APP_NOTIFICATION_PORTAL_ID)}
		>
			<Theme name="v2dark" baseLum={2}>
				<RawToast>
					{children && <ToastContent>{children}</ToastContent>}

					<ToastActions>
						<ToastAction onClick={onDismiss}>Dismiss</ToastAction>
					</ToastActions>
				</RawToast>
			</Theme>
		</Portal>
	);
}

export { Toast, ToastMessage };
