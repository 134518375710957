type Props = {
	className?: string;
};

export const AlertIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(9, 6)"
		d="M.4 6.8h1.2V8H.4V6.8zM.4 0h1.2v5.9H.4V0z"
	/>
);
