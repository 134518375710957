type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const DownloadIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M18 14v3.5H6V14m9.5-4L12 13.5m0 0L8.5 10m3.5 3.5V6"
			stroke={color || 'currentColor'}
			strokeOpacity={0.65}
		/>
	</svg>
);

export default DownloadIcon;
