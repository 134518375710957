type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const DownloadPendingIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5 7.5a4 4 0 118 0 4 4 0 01-8 0zm5.208 1.582l.444-.665-1.322-.882V5.25h-.8v2.714l1.678 1.118z"
			fill={color || 'currentColor'}
		/>
		<path
			d="M11.544 11.5c.294.232.614.431.956.593V13.5h1.707L12 15.707 9.793 13.5H11.5v-2h.044z"
			fill={color || 'currentColor'}
		/>
		<path
			d="M8 6.5h1.754a4.96 4.96 0 01.333-1H7v13h10v-6.52c-.315.17-.65.306-1 .405V17.5H8v-11z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default DownloadPendingIcon;
