// @ts-nocheck
import type { Theme as ThemeType } from 'ds/styles/themes';
import type { ReactNode, RefObject } from 'react';

import React, { useContext, useMemo, useRef, useState } from 'react';
import { useMount } from '@af/hooks';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Bookmark } from 'ds/components/Bookmark';
import { Theme } from 'ds/components/core/Theme';
import { Typography } from 'ds/components/Typography';
import { useTheme } from 'hooks/useTheme';

// eslint-disable-next-line no-use-before-define
type PortalNode = RefObject<typeof Nav>;

type ContextType = {
	portalNode: PortalNode | undefined | null;
	portalTheme: ThemeType;
	setPortalNode: (node?: PortalNode | null) => void;
	setPortalTheme: (theme: ThemeType) => void;
};

const Context = React.createContext<ContextType>({});
const useBreadcrumbContext = () => useContext(Context);

type ProviderProps = {
	children?: ReactNode;
};

function BreadcrumbProvider({ children }: ProviderProps) {
	const theme = useTheme();
	const [portalNode, setPortalNode] = useState<ReactNode | undefined | null>();
	const [portalTheme, setPortalTheme] = useState<ThemeType>(theme);

	const value = useMemo(
		() => ({ portalNode, setPortalNode, portalTheme, setPortalTheme }),
		[portalNode, portalTheme],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
}

type PortalProps = {
	children?: ReactNode;
};

function BreadcrumbPortal({ children }: PortalProps) {
	const theme = useTheme();
	const portalRef = useRef();
	const { setPortalNode, setPortalTheme } = useBreadcrumbContext();

	useMount(() => {
		setPortalNode(portalRef.current);
		setPortalTheme(theme);
	});

	return (
		<NavContainer>
			<Nav aria-label="Breadcrumb">
				<Items ref={portalRef}>{children}</Items>
			</Nav>

			<Bookmark />
		</NavContainer>
	);
}

type BreadcrumbProps = {
	children: ReactNode;
};

const Breadcrumb = ({ children }: BreadcrumbProps) => {
	const { portalNode } = useBreadcrumbContext();
	return portalNode ? ReactDOM.createPortal(children, portalNode) : null;
};

type BreadcrumbItemProps = {
	to?: string;
	children: ReactNode;
};

function BreadcrumbItem({ to, children, ...props }: BreadcrumbItemProps) {
	const { portalTheme } = useContext(Context);

	return (
		<Theme name={portalTheme.id}>
			<Item {...props}>
				<Typography as={to ? Link : 'span'} size="medium" exact={!!to} to={to}>
					{children}
				</Typography>
			</Item>
		</Theme>
	);
}

const NavContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Nav = styled.nav`
	display: flex;
`;

const Items = styled.ol`
	display: flex;
	margin: 0 ${props => props.theme.spacing.small}px;
`;

const Item = styled.li`
	&:not(:first-child)::before {
		content: '>';
		margin: 0 ${props => props.theme.spacing.tiny}px;
	}
`;

const Link = styled(NavLink)`
	&:hover {
		${props => props.theme.typography.size.state.rollover};
	}

	&:focus,
	&:active,
	&.active {
		${props => props.theme.typography.size.state.selected};
	}
`;

export { Breadcrumb, BreadcrumbItem, BreadcrumbPortal, BreadcrumbProvider };
