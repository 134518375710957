type Props = {
	className?: string;
};

export const RulesIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(1, 3)"
		d="M11 12.132L16.132 7 11 1.868 5.868 7 11 12.132zM8.316 3.184L7 1.868 1.868 7 7 12.132l1.316-1.316L4.5 7l3.816-3.816zM9 2.5l2-2L17.5 7 11 13.5l-2-2-2 2L.5 7 7 .5l2 2z"
	/>
);
