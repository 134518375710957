type Props = {
	className?: string;
};

export const ListCollapsedIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 5)"
		d="M0 0h10v11H0V0zm1 5v1h8V5H1z"
	/>
);
