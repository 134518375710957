// @ts-nocheck

import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { Curtain as StyledCurtain, variants } from './styles';

function Curtain({ variant, children, ...props }) {
	return (
		<StyledCurtain variant={variant} {...props}>
			{props.active && children}
		</StyledCurtain>
	);
}

Curtain.displayName = 'Curtain';

Curtain.propTypes = {
	active: PropTypes.bool,
	variant: PropTypes.oneOf(Object.keys(variants)),
};

Curtain.defaultProps = {
	active: false,
	variant: 'dark',
};

const styledCurtain = styled(Curtain)``;

export default styledCurtain;
