import type {
	GQLMutation,
	GQLMutationSetReadNotificationArgs,
	GQLSetReadNotificationInput,
} from '@af/api';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const UPDATE_NOTIFICATION_READ_STATUS = gql`
	mutation ReadNotification(
		$contextOrgId: ID!
		$notificationId: ID!
		$isRead: Boolean!
	) {
		setReadNotification(
			contextOrgId: $contextOrgId
			setReadNotificationParameters: {
				notificationId: $notificationId
				isRead: $isRead
			}
		) {
			isRead
			notificationId
		}
	}
`;

type MutationResponse = {
	setReadNotification: GQLMutation['setReadNotification'];
};

type MutationVariables = {
	contextOrgId: GQLMutationSetReadNotificationArgs['contextOrgId'];
	notificationId: GQLSetReadNotificationInput['notificationId'];
	isRead: GQLSetReadNotificationInput['isRead'];
};

export function useReadNotificationMutation() {
	return useMutation<MutationResponse, MutationVariables>(
		UPDATE_NOTIFICATION_READ_STATUS,
	);
}
