// @ts-nocheck

import React from 'react';
import styled from 'styled-components';

const Lines = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	height: 100%;
	width: 100%;
	background: repeating-linear-gradient(
		to ${props => props.to},
		${props => props.theme.color.palette.riskGrey.alpha.custom(0.1)} 0px,
		${props => props.theme.color.palette.riskGrey.alpha.custom(0.1)} 1px,
		transparent 1px,
		transparent 8px,
		${props => props.theme.color.palette.riskGrey.alpha.custom(0.3)} 8px,
		${props => props.theme.color.palette.riskGrey.alpha.custom(0.3)} 9px,
		transparent 9px,
		transparent 16px
	);
	pointer-events: none;
`;

type Props = {};

type State = {
	isVerticalLinesAvailable: boolean;
	isHorizontalLinesAvailable: boolean;
};

const AFTGH = `6570847172`;
const AFTGV = `6570847186`;

class GridOverlay extends React.Component<Props, State> {
	gridKeysPressed = '';

	state = {
		isVerticalLinesAvailable: false,
		isHorizontalLinesAvailable: false,
	};

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown);
	}

	render() {
		const { isVerticalLinesAvailable, isHorizontalLinesAvailable } = this.state;
		return (
			<React.Fragment>
				{isHorizontalLinesAvailable && <Lines to="right" />}
				{isVerticalLinesAvailable && <Lines to="bottom" />}
			</React.Fragment>
		);
	}

	handleKeyDown = (event: KeyboardEvent) => {
		this.gridKeysPressed += event.keyCode;

		const gridKeysPressedLength = this.gridKeysPressed.length;
		const patternLength = AFTGH.length;

		if (gridKeysPressedLength > patternLength) {
			this.gridKeysPressed = this.gridKeysPressed.substr(
				gridKeysPressedLength - patternLength,
			);
		}

		if (this.gridKeysPressed === AFTGH) {
			this.toggleHorizontalGrid();
		}

		if (this.gridKeysPressed === AFTGV) {
			this.toggleVerticalGrid();
		}
	};

	toggleHorizontalGrid = () =>
		this.setState(state => ({
			isHorizontalLinesAvailable: !state.isHorizontalLinesAvailable,
		}));

	toggleVerticalGrid = () =>
		this.setState(state => ({
			isVerticalLinesAvailable: !state.isVerticalLinesAvailable,
		}));
}

export { GridOverlay };
