import type { BaseTheme, Theme } from './themes';
import type {
	Alphas,
	BaseColor,
	Color,
	Colors,
	IconAlphas,
	Luminosities,
	SeverityColors,
} from './types/colors';
import type { TypeAlphas, TypeSizeName } from './typography';

// Returns a new BaseColor with an altered luminosity
export const adjustLum = (
	color: BaseColor,
	step: number,
	saturationStep: number = 0,
): BaseColor => ({
	...color,
	l: color.l + step,
	s: color.s + saturationStep,
});

// Returns a new BaseColor with an altered alpha
export const adjustAlpha = (color: BaseColor, amount: number): BaseColor => ({
	...color,
	a: amount,
});

// Returns a valid css `hsla()` string
export const hsla = ({ h, s, l, a }: BaseColor): string =>
	`hsla(${h}, ${s}%, ${l}%, ${a})`;

// Shorthand for adjusting luminosity and returning the css `hsla()` string
export const lumToHsla = (
	color: BaseColor,
	lum: number,
	saturation?: number,
): string => hsla(adjustLum(color, lum, saturation));

// Shorthand for adjusting alpha and returning the css `hsla()` string
export const alphaToHsla = (color: BaseColor, value: number): string =>
	hsla(adjustAlpha(color, value));

type ConvertColorParams = {
	color: BaseColor;
	lums: Luminosities;
	alphas: Alphas;
	iconAlphas: IconAlphas;
	typeAlphas: TypeAlphas;
	baseLum?: number;
	maxLum?: number;
};

export function convertBaseColorToColor({
	color,
	lums,
	alphas,
	iconAlphas,
	typeAlphas,
	baseLum = 0,
	maxLum = Infinity,
}: ConvertColorParams): Color {
	const newBaseLum = baseLum * color.direction;
	const base = lumToHsla(color, newBaseLum);
	const invertLum = baseLum > maxLum;
	const lumDirection = color.direction * (invertLum ? -1 : 1);

	const lum = {
		custom: (lum: number, saturation?: number) =>
			lumToHsla(color, lum * color.direction, saturation),
		...Object.keys(lums).reduce(
			(acc, key) => ({
				...acc,
				[key]: lumToHsla(
					color,
					newBaseLum + lums[key as keyof Luminosities] * lumDirection,
				),
			}),
			{},
		),
	} as Color['lum'];

	const alpha = {
		custom: (alpha: number) => alphaToHsla(color, alpha),
		...Object.keys(alphas).reduce(
			(acc, key) => ({
				...acc,
				[key]: alphaToHsla(color, alphas[key as keyof Alphas]),
			}),
			{},
		),
	} as Color['alpha'];

	const iconAlpha = {
		custom: (alpha: number) => alphaToHsla(color, alpha),
		...Object.keys(iconAlphas).reduce(
			(acc, key) => ({
				...acc,
				[key]: alphaToHsla(color, iconAlphas[key as keyof IconAlphas]),
			}),
			{},
		),
	} as Color['iconAlpha'];

	const typeAlpha = {
		custom: (alpha: number) => alphaToHsla(color, alpha),
		...Object.keys(typeAlphas).reduce(
			(acc, key) => ({
				...acc,
				[key]: alphaToHsla(color, typeAlphas[key as keyof TypeAlphas]),
			}),
			{},
		),
	} as Color['typeAlpha'];

	return {
		base,
		lum,
		alpha,
		typeAlpha,
		iconAlpha,
	};
}

// A lazy cache of converted themes and base luminosities
const convertedThemeCache: { [key: string]: Theme } = {};

export function convertBaseThemeToTheme(
	theme: BaseTheme,
	baseLum: number = 0,
	typographySize: TypeSizeName = 'normal',
	convertToColor: (a: ConvertColorParams) => Color = convertBaseColorToColor,
	version: string = 'v1',
): Theme {
	const themeKey = `${version}_${theme.id}__${baseLum}__${typographySize}`;
	const themeFromCache = convertedThemeCache[themeKey];

	if (themeFromCache) {
		return themeFromCache;
	}

	const {
		color: { maxLum, lum, alpha, iconAlpha, typeAlpha },
		color,
	} = theme;

	const base = {
		color: undefined,
		lums: lum,
		alphas: alpha,
		iconAlphas: iconAlpha,
		typeAlphas: typeAlpha,
		baseLum,
		maxLum,
	};

	const fg = convertToColor({
		...base,
		color: color.fg,
	});
	const bg = convertToColor({
		...base,
		color: color.bg,
	});
	const primary = convertToColor({
		...base,
		baseLum: 0,
		color: color.primary,
	});
	const palette = Object.keys(color.palette).reduce(
		(prev, cur) => ({
			...prev,
			[cur]: convertToColor({
				...base,
				baseLum: 0,
				color: color.palette[cur as keyof Colors],
			}),
		}),
		{},
	) as Colors;
	const severity = Object.keys(color.severity).reduce(
		(prev, cur) => ({
			...prev,
			[cur]: convertToColor({
				...base,
				color: color.severity[cur as keyof SeverityColors],
			}),
		}),
		{},
	) as SeverityColors;

	const generatedTheme = {
		id: theme.id,
		color: {
			bg,
			fg,
			primary,
			palette,
			severity,
			baseLum,
			maxLum,
			lum: theme.color.lum,
			alpha: theme.color.alpha,
			iconAlpha: theme.color.iconAlpha,
			typeAlpha: theme.color.typeAlpha,
		},
		spacing: theme.spacing,
		boxShadow: theme.boxShadow,
		typography: {
			size: {
				state: theme.typography.size[typographySize],
				name: typographySize,
			},
			alignment: theme.typography.alignment,
		},
	};

	convertedThemeCache[themeKey] = generatedTheme;

	return generatedTheme;
}

export function convertBaseColorToColorV2({
	color,
	lums,
	alphas,
	iconAlphas,
	typeAlphas,
	baseLum = 0,
	maxLum = Infinity,
}: ConvertColorParams): Color {
	const maxDirection = maxLum * color.direction;
	const baseDirection = baseLum * color.direction;
	const isInverted = Boolean(Math.floor(baseDirection / maxDirection) % 2);
	const baseLumConstrained = baseDirection % maxDirection;
	const newBaseLum = isInverted
		? maxDirection - baseLumConstrained
		: baseLumConstrained;

	const base = lumToHsla(color, newBaseLum);

	const lum = {
		custom: (lum: number, saturation?: number) =>
			lumToHsla(color, lum * color.direction, saturation),
		...Object.keys(lums).reduce(
			(acc, key) => ({
				...acc,
				[key]: lumToHsla(
					color,
					newBaseLum + lums[key as keyof Luminosities] * color.direction,
				),
			}),
			{},
		),
	} as Color['lum'];

	const alpha = {
		custom: (alpha: number) => alphaToHsla(color, alpha),
		...Object.keys(alphas).reduce(
			(acc, key) => ({
				...acc,
				[key]: alphaToHsla(color, alphas[key as keyof Alphas]),
			}),
			{},
		),
	} as Color['alpha'];

	const iconAlpha = {
		custom: (alpha: number) => alphaToHsla(color, alpha),
		...Object.keys(iconAlphas).reduce(
			(acc, key) => ({
				...acc,
				[key]: alphaToHsla(color, iconAlphas[key as keyof IconAlphas]),
			}),
			{},
		),
	} as Color['iconAlpha'];

	const typeAlpha = {
		custom: (alpha: number) => alphaToHsla(color, alpha),
		...Object.keys(typeAlphas).reduce(
			(acc, key) => ({
				...acc,
				[key]: alphaToHsla(color, typeAlphas[key as keyof TypeAlphas]),
			}),
			{},
		),
	} as Color['typeAlpha'];

	return {
		base,
		lum,
		alpha,
		typeAlpha,
		iconAlpha,
	};
}
