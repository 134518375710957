// @ts-nocheck

import type {
	GQLMutationCreateTemporaryExpressionArgs,
	GQLTemporaryExpression,
} from '@af/api';

import gql from 'graphql-tag';

import { getApolloClient } from './apollo-client';

export function getRiskDetailTempExpressionUrl(tempExpressionId: string) {
	return `/external/risks/expressionId/${tempExpressionId}`;
}

export function getActionCenterTempExpressionUrl(tempExpressionId: string) {
	return `/external/actions/expressionId/${tempExpressionId}`;
}

export function getContentDetailTempExpressionUrl(tempExpressionId: string) {
	return `/external/contents/expressionId/${tempExpressionId}`;
}

const CREATE_TEMPORARY_EXPRESSION = gql`
	mutation CreateTemporaryExpression(
		$contextOrgId: ID!
		$whereSystemField: SystemFieldFilter!
	) {
		createTemporaryExpression(
			contextOrgId: $contextOrgId
			timeToLiveInDays: 60
			whereSystemField: $whereSystemField
		) {
			id
		}
	}
`;

export type MutationResponse = {
	createTemporaryExpression: {
		id: GQLTemporaryExpression['id'];
		__typename: GQLTemporaryExpression['__typename'];
	};
};

type MutationVariables = {
	contextOrgId: GQLMutationCreateTemporaryExpressionArgs['contextOrgId'];
	whereSystemField: GQLMutationCreateTemporaryExpressionArgs['whereSystemField'];
};

type Params = {} & MutationVariables;

export async function createTemporaryExpressionSync(params: Params) {
	const apolloClient = await getApolloClient();

	return apolloClient.mutate<MutationResponse>({
		mutation: CREATE_TEMPORARY_EXPRESSION,
		variables: {
			...params,
		},
	});
}
