type Props = {
	className?: string;
};

export const SelectAllIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 4)"
		d="M4 10H.5c-.294 0-.5-.406-.5-.7V.5C0 .206.206 0 .5 0h8c.294 0 .5.206.5.5V5H8V1.004H.997v7.992H4V10zm6.246 2L8.223 9.977l-.956 1.49L6.2 7.2l4.267 1.067-1.49.956L11 11.246l-.754.754z"
	/>
);
