import type { GQLEnrichedJourney } from '@af/api';
import type { AndExpressionType } from 'types/Expression';
import type { FieldValue } from 'utils/expressionsv2/value';

import { convertExpressionToFieldValue } from 'utils/expressionsv2/value';

export const EnrichedJourneyResolver = {
	EnrichedJourney: {
		sourceCriteriaFieldValues: ({
			sourceCriteria,
		}: {
			sourceCriteria: GQLEnrichedJourney['sourceCriteria'];
		}): Array<FieldValue> => {
			const joinExpression: AndExpressionType | undefined | null =
				sourceCriteria;

			return joinExpression
				? joinExpression.expressions.map(convertExpressionToFieldValue)
				: [];
		},
	},
};
