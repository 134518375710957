// @ts-nocheck

export const ENVIRONMENT_NAMES = {
	production: 'production',
	staging: 'staging',
	nightly: 'nightly',
	preview: 'preview',
	localhost: 'localhost',
};

export function getBusinessEnvironmentName():
	| keyof typeof ENVIRONMENT_NAMES
	| 'unknown' {
	const hostname = window.location.hostname;

	if (hostname.includes('staging.app.accessfintech.com'))
		return ENVIRONMENT_NAMES.staging;
	if (
		hostname.includes('nightly.app.accessfintech.com') ||
		hostname.includes('nightly-prototype.app.accessfintech.com')
	)
		return ENVIRONMENT_NAMES.nightly;
	if (hostname.includes('regression.app.accessfintech.com'))
		return ENVIRONMENT_NAMES.nightly;
	if (hostname.includes('app.accessfintech.com'))
		return ENVIRONMENT_NAMES.production;
	if (hostname.includes('localhost')) return ENVIRONMENT_NAMES.localhost;
	if (hostname.includes('netlify.com')) return ENVIRONMENT_NAMES.preview;
	return 'unknown';
}
