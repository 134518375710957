// @ts-nocheck

import type { IconName } from 'ds/components/Icon';
import type { ComponentProps } from 'react';

import React from 'react';
import styled from 'styled-components';

import Icon, { ICONS } from 'ds/components/Icon';
import { Box } from 'v2/components/Box';
import { Text } from 'v2/components/Text';

type Props = {
	icon?: IconName;
	children: ComponentProps<typeof Text>['children'];
} & WrapperProps;

export const Message = ({
	icon = 'messages',
	children,
	opacity,
	...props
}: Props) => (
	<Wrapper
		{...props}
		align={['middle', 'center']}
		padding={['medium']}
		textAlign="center"
		opacity={opacity}
	>
		<Icon config={ICONS[icon]} size="large" />
		<Box as="p" margin={['small', 0, 0]}>
			<Text>{children}</Text>
		</Box>
	</Wrapper>
);

type WrapperProps = {
	opacity?: number;
} & ComponentProps<typeof Box>;

const Wrapper = styled(Box)<WrapperProps>`
	opacity: ${props => props.opacity};
`;
