type Props = {
	className?: string;
};

export const ExpandIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M7.668 6.373l.664-.747L13.252 10l-4.92 4.373-.664-.747L11.748 10l-4.08-3.627z"
	/>
);
