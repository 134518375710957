type FriendlyNameDependencies = {
	fieldFriendlyName?: string | null;
	name?: string | null;
};

export const ClientFieldResolver = {
	ClientField: {
		friendlyName: ({
			fieldFriendlyName,
			name,
		}: FriendlyNameDependencies): string => {
			return fieldFriendlyName || name || 'Unknown';
		},
	},
};
