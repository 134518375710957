/*
 * Custom component to display the progress bar. By using a custom component we are making sure
 * that the component will look the same across all broswers. We also followed the technique descrived here:
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role
 * The decision to build a custom component rather than using the `progress` component was made here:
 * https://github.com/accessfintech/access-fintech-frontend/pull/2954#discussion_r465589134
 * We can use the `progress` component later on once cross-browser CSS support improves
 */

import styled from 'styled-components';

import { Background } from 'ds/components/Background';

type Props = {
	value: number;
	max: number;
	className?: string;
};

function ProgressBar({ value, max, className }: Props) {
	const barWidth = (value / max) * 100 || 0;

	return (
		<Bar
			className={className}
			title={value}
			role="progressbar"
			aria-valuenow={value}
			aria-valuemin={0}
			aria-valuemax={max}
			barWidth={barWidth}
		/>
	);
}

const Bar = styled(Background).attrs(props => ({
	step: props.theme.color.baseLum + 4,
}))<{
	barWidth: number;
}>`
	position: relative;
	height: 4px;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 4px;
		width: ${props => props.barWidth}%;
		max-width: 100%;
		background-color: ${props => props.theme.color.primary.lum.active};
		transition: width 333ms ease;
	}
`;

export { ProgressBar };
