type Props = {
	className?: string;
};

export const GoToIcon = ({ className }: Props) => (
	<>
		<path className={className} d="M14 11V14H6V6H9V5H5V15H15V11H14Z" />
		<path
			className={className}
			d="M11.8179 7.31794L9.5 9.63589L10.3641 10.5L12.6821 8.18206L13.7778 9.88889L15 5L10.1111 6.22222L11.8179 7.31794Z"
		/>
	</>
);
