type Props = {
	className?: string;
};

export const TickContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M13.1128 7.23233L13.8962 7.85384L8.7944 14.2847L6.15796 11.7497L6.85107 11.0288L8.69462 12.8015L13.1128 7.23233Z"
	/>
);
