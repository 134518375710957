// @ts-nocheck

import qs from 'qs';
import type { Location, QueryParams } from 'react-router';

// TODO: Add generic here so consumers can define what comes back from params
// eg. getQueryParams<{param: ?string}>(...)
function getQueryParams(location: Location, options?: QueryParams | null) {
	return qs.parse(location.search, {
		comma: true,
		ignoreQueryPrefix: true,
		// $FlowFixMe - Cannot determine a type for object literal [1]. `QueryParams` [2] cannot be spread because the indexer string [3] may overwrite properties with explicit keys in a way that Flow cannot track. Can you spread `QueryParams` [2] first or remove the indexer?
		...options,
	});
}

function addQueryParams(location: Location, queryParams: QueryParams) {
	const currentQueryParams = getQueryParams(location);
	const queryString = getQueryString({ ...currentQueryParams, ...queryParams });
	return setQueryParams(location, queryString);
}

function setQueryParams(location: Location, queryString: string) {
	return {
		// Flow: Cannot spread `location` because property `toString` is not writable.Flow(InferError)
		// $FlowFixMe
		...location,
		search: `?${queryString}`,
	};
}

function getQueryString(queryParams: QueryParams) {
	return qs.stringify(queryParams, {
		// Flow: Cannot call `qs.stringify` with object literal bound to `opts` because  string [1] is incompatible with  enum [2] in property `arrayFormat`.
		// $FlowFixMe: `comma` is a valid value here: https://github.com/ljharb/qs
		arrayFormat: 'comma',
		strictNullHandling: true,
	});
}

const queryString = {
	getQueryParams,
	addQueryParams,
	setQueryParams,
	getQueryString,
};

export { queryString };
