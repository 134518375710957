// @ts-nocheck

import type {
	DoubleValueOperatorType,
	JoinOperatorType,
	MultipleValueOperatorType,
	NoValueOperatorType,
	SingleValueOperatorType,
} from 'types/Expression';

import { OPERATORS } from 'utils/expressionsv2/operator';

export type DateTypes = 'absolute' | 'relative';

const OPERATORS_SHARED = [
	OPERATORS.eq,
	OPERATORS.ne,
	OPERATORS.isNotNull,
	OPERATORS.isNull,
];
const OPERATORS_QUANTITIVE = [
	OPERATORS.gt,
	OPERATORS.ge,
	OPERATORS.lt,
	OPERATORS.le,
	OPERATORS.between,
];

export const STRING_OPERATORS = [
	...OPERATORS_SHARED,
	OPERATORS.contains,
	OPERATORS.notContains,
	OPERATORS.starts,
	OPERATORS.ends,
	OPERATORS.in,
	OPERATORS.notIn,
];
export const NUMBER_OPERATORS = [...OPERATORS_SHARED, ...OPERATORS_QUANTITIVE];

// Added as part of https://access-fintech.atlassian.net/browse/FIN-6812
const DATETIME_ALLOWED_SHARED_OPERATORS = OPERATORS_SHARED.filter(
	operator => ![OPERATORS.eq, OPERATORS.ne].includes(operator),
);
export const DATETIME_OPERATORS = [
	...DATETIME_ALLOWED_SHARED_OPERATORS,
	...OPERATORS_QUANTITIVE,
];

export const BOOLEAN_OPERATORS = [...OPERATORS_SHARED];

export const NO_VALUE_OPERATORS: Array<NoValueOperatorType> = [
	OPERATORS.isNull,
	OPERATORS.isNotNull,
];

export const SINGLE_VALUE_OPERATORS: Array<SingleValueOperatorType> = [
	OPERATORS.eq,
	OPERATORS.ne,
	OPERATORS.contains,
	OPERATORS.notContains,
	OPERATORS.starts,
	OPERATORS.ends,
	OPERATORS.lt,
	OPERATORS.le,
	OPERATORS.gt,
	OPERATORS.ge,
];

export const DOUBLE_VALUE_OPERATORS: Array<DoubleValueOperatorType> = [
	OPERATORS.between,
];

export const MULTIPLE_VALUE_OPERATORS: Array<MultipleValueOperatorType> = [
	OPERATORS.in,
	OPERATORS.notIn,
];

export const JOIN_OPERATORS: Array<JoinOperatorType> = [
	OPERATORS.and,
	OPERATORS.or,
];

export const DATE_TYPES = {
	absolute: {
		id: 'absolute',
		label: 'Absolute',
		defaultValue: '',
	},
	relative: {
		id: 'relative',
		label: 'Relative',
		defaultValue: 0,
	},
};
export const DATE_TYPE_LIST: Array<DateTypes> = [
	DATE_TYPES.absolute.id,
	DATE_TYPES.relative.id,
];

export const BOOLEAN_VALUES = {
	true: {
		id: 'true',
		label: 'Yes',
	},
	false: {
		id: 'false',
		label: 'No',
	},
};

export const BOOLEAN_VALUE_LIST = [BOOLEAN_VALUES.true, BOOLEAN_VALUES.false];
