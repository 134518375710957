// @ts-nocheck

import type { ReactNode } from 'react';

import React, { useMemo, useRef, useState } from 'react';
import { ActionCentreOffIcon, ActionCentreOnIcon } from '@af/icons';
import { animated, useSpring } from '@react-spring/web';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import { Badge } from 'ds/components/Badge';
import { IconButton, IconButtonLink } from 'ds/components/core/Button';
import { GlobalSearch } from 'hacks/GlobalSearch';
import { HackLogo } from 'hacks/hackLogo';
import { useAllViewId } from 'hooks/useAllViewId';

import { useUserPreferencesBookmarkQuery } from '../../Bookmark/useUserPreferenceBookmarkQuery';
import { Nav } from './Nav';

export type Props = {
	avatarSrc: string;
	isAvatarActive?: boolean;
	badgeCount?: number;
	children?: ReactNode;
	onAvatarClick?: () => void;
};

const HEIGHT = 50;

function NavBar({
	avatarSrc,
	isAvatarActive = false,
	badgeCount,
	children,
	onAvatarClick,
}: Props) {
	const blurTimerRef = useRef();
	const location = useLocation();
	const flags = useFlags();
	const isActionCenterEnabled = flags['action-center'];
	const { data } = useAllViewId();

	const { bookmark } = useUserPreferencesBookmarkQuery();

	const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

	const navBarStyle = useSpring({
		opacity: 1,
		transform: 'translateY(0)',
		from: { opacity: 0, transform: 'translateY(-50px)' },
	});

	const actionCenterUrl = useMemo(() => {
		if (!isActionCenterEnabled) {
			return null;
		}

		const allViewId = data?.allView?.id;

		if (!allViewId) return null;

		return `/view/${allViewId}/actions`;
	}, [data, isActionCenterEnabled]);

	// Used to control the action center navbar button active state
	const isOnActionCenterUrl = actionCenterUrl === location.pathname;

	function handleNavOpen() {
		clearTimeout(blurTimerRef.current);

		setIsNavOpen(true);
	}

	function handleNavClose() {
		blurTimerRef.current = setTimeout(() => setIsNavOpen(false), 300);
	}

	return (
		<Wrapper style={navBarStyle}>
			<LogoNav
				isActive={isNavOpen}
				onFocus={handleNavOpen}
				onBlur={handleNavClose}
				onMouseOver={handleNavOpen}
				onMouseOut={handleNavClose}
				data-test="menu-trigger"
			>
				<LogoLink to={bookmark || '/'}>
					<HackLogo />
				</LogoLink>

				{isNavOpen && (
					<Nav
						label="Jump To"
						data-test="menu"
						onRouteClick={() => setIsNavOpen(false)}
					/>
				)}
			</LogoNav>

			<Main>{children}</Main>

			{actionCenterUrl && (
				<ActionCenterLink title="Action center" to={actionCenterUrl}>
					{isOnActionCenterUrl ? (
						<ActionCentreOnIcon />
					) : (
						<ActionCentreOffIcon />
					)}
				</ActionCenterLink>
			)}

			<StyledGlobalSearch />

			<AvatarButton
				data-test="avatar-button"
				isActive={isAvatarActive}
				onClick={() => onAvatarClick && onAvatarClick()}
			>
				<Badge count={badgeCount}>
					<Avatar src={avatarSrc} size={30} />
				</Badge>
			</AvatarButton>
		</Wrapper>
	);
}

const Wrapper = styled(animated.header)`
	height: ${HEIGHT}px;
	display: flex;
	flex-shrink: 0;
	position: relative;
	align-items: center;
	z-index: 10;
	justify-content: space-between;
	background-color: ${props => props.theme.color.bg.lum.default};
`;

const LogoNav = styled.div`
	position: relative;
	background-color: ${props =>
		props.isActive && props.theme.color.bg.lum.active};
`;

const LogoLink = styled(Link)`
	padding: ${props => props.theme.spacing.small}px;
	display: flex;
`;

const BaseContent = styled.div`
	display: flex;
	align-items: center;
`;

const Main = styled(BaseContent)`
	flex: 1 1 auto;
	height: 100%;
`;

const AvatarButton = styled(IconButton)`
	position: relative;
	padding: ${props => props.theme.spacing.small}px;
	background-color: ${props =>
		props.isActive ? props.theme.color.bg.lum.active : 'transparent'};
	cursor: pointer;

	&:hover {
		background: ${props => props.theme.color.bg.lum.active};
	}
`;

const StyledGlobalSearch = styled(GlobalSearch)`
	margin-right: ${props => props.theme.spacing.small}px;
`;

const ActionCenterLink = styled(IconButtonLink)`
	margin-right: ${props => props.theme.spacing.small}px;
`;

export { NavBar };
