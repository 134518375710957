// @ts-nocheck

import type {
	GQLClientField,
	GQLContentType,
	GQLGenericField,
	GQLProductLine,
	GQLProductLineField,
	GQLProductLineFieldValue,
	GQLQueryOrganizationArgs,
	GQLRiskSystemField,
} from '@af/api';

import gql from 'graphql-tag';

import { getApolloClient } from 'utils/apollo-client';

const CONTENT_TYPE_FRAGMENT = gql`
	fragment ContentTypeFragment on ContentType {
		id
		name
		isEnabled
		displayName
		description
		permitted
	}
`;

const PRODUCT_LINE_FRAGMENT = gql`
	fragment ProductLineFragment on ProductLine {
		id
		name
		description
		url
		vendorOrganizationId
		contentTypeId
		connectionIds
		permitted
	}
`;

const GET_ALL_FIELDS = gql`
	query GetInitialMetadata($orgId: ID!) {
		organization(id: $orgId) {
			id
			client {
				productLines {
					...ProductLineFragment
				}
			}
			vendor {
				productLines {
					...ProductLineFragment
				}
			}
			fieldUniverse {
				clientFields(contextOrgId: $orgId) {
					key
					name
					contentTypeId
					organizationId
					genericFieldId
					fieldFriendlyName
					type
					formatType
					isTimeBased
					titleRank
					frequencyRank
					isRiskFactor
				}
				productLineFields {
					key
					name
					productLineId
					fieldFriendlyName
					type
					formatType
					values {
						value
					}
				}
			}
		}
		riskSystemFields {
			key
			name
			dereferenceValueType
			type
			formatType
			friendlyName
		}
		contentTypes(orgId: $orgId) {
			...ContentTypeFragment
		}
		genericFields {
			type
			formatType
			name
			id
			contentTypeId
		}
	}
	${CONTENT_TYPE_FRAGMENT}
	${PRODUCT_LINE_FRAGMENT}
`;

type ContentType = {
	id: GQLContentType['id'];
	name: GQLContentType['name'];
	isEnabled: GQLContentType['isEnabled'];
	displayName: GQLContentType['displayName'];
	description: GQLContentType['description'];
	permitted: GQLContentType['permitted'];
};

type ProductLine = {
	id: GQLProductLine['id'];
	name: GQLProductLine['name'];
	description: GQLProductLine['description'];
	url: GQLProductLine['url'];
	vendorOrganizationId: GQLProductLine['vendorOrganizationId'];
	contentTypeId: GQLProductLine['contentTypeId'];
	connectionIds: GQLProductLine['connectionIds'];
	permitted: GQLProductLine['permitted'];
};

type ClientField = {
	key: GQLClientField['key'];
	name: GQLClientField['name'];
	contentTypeId: GQLClientField['contentTypeId'];
	organizationId: GQLClientField['organizationId'];
	genericFieldId: GQLClientField['genericFieldId'];
	fieldFriendlyName: GQLClientField['fieldFriendlyName'];
	type: GQLClientField['type'];
	formatType: GQLClientField['formatType'];
	isTimeBased: GQLClientField['isTimeBased'];
	titleRank: GQLClientField['titleRank'];
	frequencyRank: GQLClientField['frequencyRank'];
	isRiskFactor: GQLClientField['isRiskFactor'];
	contentType?: ContentType;
};

type ProductLineField = {
	key: GQLProductLineField['key'];
	name: GQLProductLineField['name'];
	productLineId: GQLProductLineField['productLineId'];
	fieldFriendlyName: GQLProductLineField['fieldFriendlyName'];
	type: GQLProductLineField['type'];
	formatType: GQLProductLineField['formatType'];
	productLine?: ProductLine;
	values: Array<{
		value: GQLProductLineFieldValue['value'];
		__typename: 'ProductLineFieldValue';
	}>;
};

type RiskSystemField = {
	key: GQLRiskSystemField['key'];
	name: GQLRiskSystemField['name'];
	dereferenceValueType: GQLRiskSystemField['dereferenceValueType'];
	type: GQLRiskSystemField['type'];
	formatType: GQLRiskSystemField['formatType'];
	friendlyName: GQLRiskSystemField['friendlyName'];
};

type GenericField = {
	id: GQLGenericField['id'];
	name: GQLGenericField['name'];
	contentTypeId: GQLGenericField['contentTypeId'];
	type: GQLGenericField['type'];
	formatType: GQLGenericField['formatType'];
};

export type GetAllFieldsResponse = {
	organization:
		| {
				id: string;
				client?: {
					productLines: Array<ProductLine>;
				};
				vendor?: {
					productLines: Array<ProductLine>;
				};
				fieldUniverse: {
					clientFields: Array<ClientField>;
					productLineFields: Array<ProductLineField>;
				};
		  }
		| undefined
		| null;
	riskSystemFields: Array<RiskSystemField>;
	contentTypes: Array<ContentType>;
	genericFields: Array<GenericField>;
};

type InitialDataParams = {
	orgId: GQLQueryOrganizationArgs['id'];
};

export async function loadInitialData({ orgId }: InitialDataParams) {
	const apolloClient = await getApolloClient();
	const response = await apolloClient
		.query<GetAllFieldsResponse>({
			query: GET_ALL_FIELDS,
			variables: {
				orgId,
			},
		})
		.catch(error =>
			console.error('There was an error on GetInitialMetadata query'),
		);

	const clientFields: Array<ClientField> =
		response?.data?.organization?.fieldUniverse.clientFields || [];
	const productFields =
		response?.data?.organization?.fieldUniverse.productLineFields || [];
	const systemFields: Array<RiskSystemField> =
		response?.data?.riskSystemFields || [];
	const genericFields: Array<GenericField> =
		response?.data?.genericFields || [];

	const contentTypeList: Array<ContentType> = (
		response?.data?.contentTypes || []
	).sort((a, b) => a.name.localeCompare(b.name));

	const clientProductLines =
		response?.data?.organization?.client?.productLines || [];
	const vendorProductLines =
		response?.data?.organization?.vendor?.productLines || [];
	const productLineList: Array<ProductLine> = clientProductLines
		.concat(vendorProductLines)
		.sort((a, b) => a.name.localeCompare(b.name));

	return {
		clientFields,
		productFields,
		systemFields,
		genericFields,
		contentTypeList,
		productLineList,
	};
}
