import type { GQLUserPreference } from '@af/api';

import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { KEY } from './';

const GET_USER_PREFERENCE_BOOKMARK = gql`
	query GetUserPreferenceBookmark($key: ID!) {
		userMe {
			id
			userPreferences(key: $key) {
				key
				value
			}
		}
	}
`;

type QueryResponse = {
	userMe: {
		userPreferences: [
			{
				key: GQLUserPreference['key'];
				value: GQLUserPreference['value'];
				__typename: 'UserPreference';
			},
		];
		__typename: 'User';
	};
};

type QueryVariables = {
	key: GQLUserPreference['key'];
};

function useUserPreferencesBookmarkQuery() {
	const response = useQuery<QueryResponse, QueryVariables>(
		GET_USER_PREFERENCE_BOOKMARK,
		{
			variables: { key: KEY },
		},
	);

	const bookmark = useMemo(
		() => response.data?.userMe?.userPreferences[0]?.value || '',
		[response.data],
	);

	return { ...response, bookmark };
}

export { useUserPreferencesBookmarkQuery };
