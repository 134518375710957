type Props = {
	className?: string;
};

export const TagIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 5)"
		d="M10.794 5.706a.665.665 0 0 1 0 .963l-4.125 4.125a.624.624 0 0 1-.481.206.624.624 0 0 1-.482-.206l-5.5-5.5A.624.624 0 0 1 0 4.813V.688C0 .275.275 0 .688 0h4.125c.206 0 .343.069.48.206l5.5 5.5zM3 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
	/>
);
