import type { Id } from 'types/Id';

import { localStorage } from '@af/utils';

import store from 'store';

const LOCAL_STORAGE_ORG_ID = 'orgId';

export function getStoreOrgId(): Id {
	return store.getState().app.orgId;
}

export function setLocalStorageOrgId(orgId: Id) {
	localStorage.set(LOCAL_STORAGE_ORG_ID, String(orgId));
}

export function getLocalStorageOrgId() {
	const orgId = localStorage.get(LOCAL_STORAGE_ORG_ID);
	return orgId ? Number(orgId) : null;
}
