// @ts-nocheck

import type { ChangeEvent } from 'react';

import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	isHidden: boolean;
	name: string;
	title: string;
	className?: string;
};

const FileInput = styled.input`
	${props =>
		props.isHidden &&
		css`
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			opacity: 0;
		`};
`;

function RawFileUpload({ isHidden, name, title, className, onChange }: Props) {
	return (
		<form className={className}>
			<FileInput
				isHidden={isHidden}
				type="file"
				title={title}
				name={name}
				onChange={e => {
					e.preventDefault();

					const file = e.target.files[0];

					onChange(file);
				}}
			/>
		</form>
	);
}

const FileUpload = styled(RawFileUpload)``;

FileUpload.defaultProps = {
	isHidden: true,
};

export default FileUpload;
