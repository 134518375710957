// @ts-nocheck

import type {
	AndExpressionType,
	AnyKindOfExpression,
	FieldOperand,
	JoinExpression,
	JoinKeyExpression,
} from 'types/Expression';
import type { Field, FieldResourceType } from 'types/Field';
import type { Id } from 'types/Id';

import { warn } from '@af/utils';

import { RESOURCE_TYPE } from 'utils/field';

import { OPERATORS } from '../expressionsv2/operator';

// We don't need all field properties
type PartialOperandField = {
	id: Field['id'];
	contentTypeId?: Field['contentTypeId'];
	productLineId?: Field['productLineId'];
	fieldId: Field['fieldId'];
	resourceType: Field['resourceType'];
};

export function getFieldOperand(field: PartialOperandField): FieldOperand {
	const { id, contentTypeId, productLineId, fieldId, resourceType } = field;

	if (resourceType === RESOURCE_TYPE.clientField && contentTypeId) {
		return {
			valueType: RESOURCE_TYPE.clientField,
			fieldId,
			contentTypeId,
			compositeKey: id,
		};
	} else if (resourceType === RESOURCE_TYPE.genericField && contentTypeId) {
		return {
			valueType: RESOURCE_TYPE.genericField,
			fieldId,
			contentTypeId,
			compositeKey: id,
		};
	} else if (resourceType === RESOURCE_TYPE.productLineField && productLineId) {
		return {
			valueType: RESOURCE_TYPE.productLineField,
			fieldId,
			productLineId,
			compositeKey: id,
		};
	} else if (resourceType === RESOURCE_TYPE.systemField) {
		return {
			valueType: RESOURCE_TYPE.systemField,
			fieldId,
			compositeKey: id,
		};
	} else if (resourceType === RESOURCE_TYPE.genericField && contentTypeId) {
		return {
			valueType: RESOURCE_TYPE.genericField,
			fieldId,
			contentTypeId,
			compositeKey: id,
		};
	} else if (resourceType === RESOURCE_TYPE.enrichmentField) {
		return {
			valueType: RESOURCE_TYPE.enrichmentField,
			fieldId,
		};
	} else {
		warn('Unknown field resource type', field);

		// $FlowFixMe This should not happen but we are still guarding it.
		return null;
	}
}

export const DEFAULT_SEARCH_EXPRESSION: JoinExpression = {
	operator: OPERATORS.and,
	expressions: [],
};

export const DEFAULT_SEARCH_KEY_EXPRESSION: JoinKeyExpression = {
	operator: OPERATORS.and,
	expressions: [],
};

export function getFieldExpressionIndex(
	field: {
		fieldId: Id;
		resourceType: FieldResourceType;
	},
	expressions: Array<AnyKindOfExpression>,
) {
	if (!field) return;

	return expressions.findIndex(exp => {
		const fieldOperand = exp.left || exp.value;

		return (
			fieldOperand &&
			fieldOperand.fieldId === field.fieldId &&
			fieldOperand.valueType === field.resourceType
		);
	});
}

type UpsertAndExpressionType = {
	currentExpression: AndExpressionType | undefined | null;
	newExpression: AnyKindOfExpression;
	field: Field;
};

export function upsertAndExpression(
	options: UpsertAndExpressionType,
): AndExpressionType {
	const { currentExpression, newExpression, field } = options;

	// Copy to stop mutation
	const copyExpressions = [
		...(currentExpression ? currentExpression.expressions : []),
	];
	// Find usage index of the field in expressions
	const expressionIndex = getFieldExpressionIndex(field, copyExpressions);

	if (typeof expressionIndex === 'number' && expressionIndex > -1) {
		// If field is being used, update it
		copyExpressions[expressionIndex] = newExpression;
	} else {
		// If field is not used, append it
		copyExpressions.push(newExpression);
	}

	// Create a new and expression
	return { operator: OPERATORS.and, expressions: copyExpressions };
}

export function recursiveFlatten(
	initialExpression:
		| JoinExpression
		| Array<AnyKindOfExpression>
		| AnyKindOfExpression,
): Array<AnyKindOfExpression> {
	// Traverses all arrays or join expressions and
	// adds all AnyKindOfExpression to this list
	const flatExpressions: Array<AnyKindOfExpression> = [];

	function recursivelyFlattenExpressions(expression) {
		if (Array.isArray(expression)) {
			expression.forEach(recursivelyFlattenExpressions);
		} else if (
			expression.expressions &&
			Array.isArray(expression.expressions)
		) {
			expression.expressions.forEach(recursivelyFlattenExpressions);
		} else {
			flatExpressions.push(expression as any as AnyKindOfExpression);
		}
	}

	recursivelyFlattenExpressions(initialExpression);

	return flatExpressions;
}
