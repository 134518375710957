type Props = {
	className?: string;
};

export const TagContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M10.706 15.794l-5.5-5.5A.624.624 0 0 1 5 9.812V5.689C5 5.274 5.275 5 5.688 5h4.125c.206 0 .343.069.48.206l5.5 5.5a.624.624 0 0 1 .207.482.624.624 0 0 1-.206.48l-4.125 4.126a.665.665 0 0 1-.963 0zM9 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"
	/>
);
