// @ts-nocheck

import type { TypeTextTransform } from 'ds/styles/typography';
import type { ChildrenArray, ElementType } from 'react';

import { ellipsis } from 'polished';
import styled from 'styled-components';

/**
 * Text only accepts string as children and must be inline because we
 * want it to wrap tightly around the text. This will allow our text to
 * align on the grid properly via the margins.
 */

type Props = {
	transform?: TypeTextTransform;
	// Optional incase we use this to style form elements
	children?: boolean | ChildrenArray<string | number>;
	isTruncated?: boolean;
	hasMarginTop?: boolean;
	hasMarginBottom?: boolean;
	as?: ElementType;
};

export const Text = styled.span.attrs(props => ({
	hasMarginTop: props.hasMarginTop != null ? props.hasMarginTop : true,
	hasMarginBottom: props.hasMarginBottom != null ? props.hasMarginTop : true,
}))<Props>`
	text-transform: ${props => props.transform};
	display: inline-block;
	${props => props.isTruncated && ellipsis()};
	color: inherit;

	margin-top: ${props =>
		props.hasMarginTop &&
		`${props.theme.typography.size.name === 'large' ? 6 : 4}px`};

	margin-bottom: ${props =>
		props.hasMarginBottom &&
		`${props.theme.typography.size.name === 'large' ? 2 : 4}px`};
`;
