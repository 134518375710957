type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const AlertOutlinedIcon = ({
	color,
	size = 24,
	title,
	titleId,
	...props
}: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-1c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
			fill={color || 'currentColor'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4 7v7h1.2V7h-1.2zm0 9v1h1.2v-1h-1.2z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default AlertOutlinedIcon;
