// @ts-nocheck

import type {
	AnyKindOfExpression,
	JoinExpression,
	JoinKeyExpression,
} from 'types/Expression';

import {
	compressToEncodedURIComponent as compress,
	decompressFromEncodedURIComponent as decompress,
} from 'lz-string';

import { JOIN_OPERATORS } from 'utils/expressionsv2/constants';
import { renameKeysDeep, swapKeyValue } from 'utils/object';

const dictionary = {
	expressions: 's',
	expression: 'e',
	operator: 'o',
	left: 'l',
	right: 'r',
	valueType: 't',
	fieldId: 'f',
	value: 'v',
	contentTypeId: 'c',
	compositeKey: 'k',
	lowerBound: 'lb',
	upperBound: 'ub',
	key: 'key',
	entity: 'en',
};
const reversedDictionary = swapKeyValue(dictionary);

/**
 * Serializes a complex expression object into a string which
 * can be used in a URL
 */
export function serializeExpression(
	expression: AnyKindOfExpression | JoinExpression | JoinKeyExpression,
): string {
	if (Array.isArray(expression)) {
		console.warn('Expression is not valid', expression);

		return '';
	}

	const mappedExp = renameKeysDeep(expression, dictionary);
	const mappedExpString = JSON.stringify(mappedExp);
	const compressedExp = compress(mappedExpString);

	return compressedExp;
}

/**
 * Deserializes a URL-encoded string into a valid expression
 * object
 */
export function deserializeExpression(
	serializedExpression: string,
): AnyKindOfExpression | undefined | null | JoinExpression {
	const decompressedExp = decompress(serializedExpression);
	const decompressedExpString = JSON.parse(decompressedExp);
	const unmappedExp = renameKeysDeep(decompressedExpString, reversedDictionary);

	// TODO: Use JoinOperator from type definition, instead of duplicating here
	if (JOIN_OPERATORS.includes(unmappedExp.operator)) {
		return {
			operator: unmappedExp.operator,
			expressions: unmappedExp.expressions,
		};
	}

	// $FlowFixMe: This is difficult.
	return unmappedExp;
}
