function getCookieValue(
	allCookies: string,
	name: string,
): string | undefined | null {
	// Create a dictionary of cookies, using the cookie name as key
	const cookieDictionary: {
		[x: string]: string;
	} = allCookies.split(';').reduce((prev, current) => {
		const [key, value] = current.split('=');
		return { ...prev, [key.trim()]: value };
	}, {});

	return cookieDictionary[name];
}

export { getCookieValue };
