// @ts-nocheck

import type { GQLNavigationMenuPermission } from '@af/api';
import type { ReactNode } from 'react';

import { useUserContext } from 'contexts/UserContext';

type Props = {
	permissionId?: GQLNavigationMenuPermission;
	children: ReactNode;
	fallback?: ReactNode;
};

// For protecting components (nav item, routes) when user doesn't have the correct `NavigationMenuPermission`s
const Protected = ({ permissionId, fallback, children }: Props) => {
	const { user } = useUserContext();

	if (!user) {
		return fallback || null;
	}

	// If there's no specified permission, render
	if (!permissionId) {
		return children;
	}

	// Otherwise, check that the user has this permission in `NavigationMenuPermission`s
	const { navigationMenuPermissionIds } = user;

	const isAllowed = Boolean(
		navigationMenuPermissionIds?.includes(permissionId),
	);

	return isAllowed ? children : fallback || null;
};

export { Protected };
