// @ts-nocheck

/*
	1.  This is used on AMAC as CITI wants to see their errors, but because their API is going through midtier, the extension code that's being returned is `INTERNAL_SERVER_ERROR`
*/

import type { ApolloError } from '@apollo/react-hooks';

import styled from 'styled-components';

import { Box } from 'v2/components/Box';

type Props = {
	customAllowedExtensions?: string[];
	error: ApolloError;
	defaultGQLErrorMessage?: string;
	defaultErrorMessage?: string;
};

const ALLOWED_EXTENSION_CODE = [
	'MISSING_PERMISSIONS',
	'ENTITY_CONFLICT',
	'AGGREGATION_LIMIT_EXCEEDED',
];

function ErrorMessage({
	customAllowedExtensions /* 1. */,
	error,
	defaultGQLErrorMessage = 'Something went wrong. Please try again.',
	defaultErrorMessage = 'There was a network error. Please try again.',
}: Props) {
	const hasGraphQLErrors = Boolean(error?.graphQLErrors?.length);
	const allowedGqlErrorMessages = (error.graphQLErrors || error.errors)
		?.map(gqlError => {
			const extensions = customAllowedExtensions
				? [...customAllowedExtensions, ALLOWED_EXTENSION_CODE]
				: ALLOWED_EXTENSION_CODE;

			const isExtensionCodeAllowed = extensions.includes(
				gqlError.extensions.code,
			);

			if (isExtensionCodeAllowed) {
				return gqlError.message;
			}

			return null;
		})
		.filter(Boolean);

	const hasAllowedErrorMessages = Boolean(allowedGqlErrorMessages?.length);
	const hasNetworkError = Boolean(error.networkError);

	// $FlowFixMe - The `statusCode` exists under `networkError` but for some reason is not added under the "Error" type
	const is504Error = hasNetworkError && error.networkError?.statusCode === 504;

	return hasAllowedErrorMessages
		? allowedGqlErrorMessages.join('. ')
		: hasGraphQLErrors
		? defaultGQLErrorMessage
		: is504Error
		? 'The server took too long respond. Please try again or contact support@accessfintech.com'
		: defaultErrorMessage;
}

const ErrorMessageContainer = styled(Box)`
	align-items: center;
	flex: 1;
	justify-content: center;
`;

export { ErrorMessage, ErrorMessageContainer };
