// @ts-nocheck

import type { ReactNode, RefObject } from 'react';

import React, { useContext, useMemo, useRef, useState } from 'react';
import { useMount } from '@af/hooks';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SecondaryButton } from 'ds/components/core/Button';
import { Typography } from 'ds/components/Typography';

// eslint-disable-next-line no-use-before-define
type PortalNode = RefObject<typeof Nav>;

type ContextType = {
	portalNode: PortalNode | undefined | null;
	setPortalNode: (node?: PortalNode | null) => void;
};

type Props = {
	children?: ReactNode;
};

const Context = React.createContext<ContextType>({});

const useNavButtonsContext = () => useContext(Context);

function NavButtonsProvider({ children }: Props) {
	const [portalNode, setPortalNode] = useState<ReactNode | undefined | null>();

	const value = useMemo(() => ({ portalNode, setPortalNode }), [portalNode]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
}

function NavButtonsPortal({ children }: Props) {
	const portalRef = useRef<PortalNode | undefined | null>();
	const { setPortalNode } = useNavButtonsContext();

	useMount(() => {
		setPortalNode(portalRef.current);
	});

	return (
		<Nav aria-label="Nav" ref={portalRef}>
			<Items>{children}</Items>
		</Nav>
	);
}

type NavButtonsProps = {
	children: ReactNode;
};

const NavButtons = ({ children }: NavButtonsProps) => {
	const { portalNode } = useNavButtonsContext();
	return portalNode ? ReactDOM.createPortal(children, portalNode) : null;
};

type NavButtonProps = {
	children: ReactNode;
	isActive?: boolean;
	to: string;
};

function NavButton({
	children,
	to,
	isActive = false,
	...props
}: NavButtonProps) {
	return (
		<Item {...props}>
			<NavButtonItem as={Link} to={to} isActive={isActive}>
				<Typography size="medium">{children}</Typography>
			</NavButtonItem>
		</Item>
	);
}

const NavButtonItem = styled(SecondaryButton)`
	height: 100%;
	padding: 0 ${props => props.theme.spacing.medium}px;
	background-color: ${props =>
		props.isActive
			? props.theme.color.bg.lum.active
			: props.theme.color.bg.lum.default};
`;

const Nav = styled.nav`
	display: flex;
	height: 100%;
`;

const Items = styled.ul`
	display: flex;
	align-items: center;
`;

const Item = styled.li`
	display: flex;
	flex: 1 1 auto;
	height: 100%;
`;

export { NavButton, NavButtons, NavButtonsPortal, NavButtonsProvider };
