import type { Theme } from 'ds/styles/themes';

import { css } from 'styled-components';

const CUSTOM_SCROLLBARS_ON_MAC =
	process.env.REACT_APP_CUSTOM_SCROLLBARS_ON_MAC?.toLowerCase() === 'true';

export const scrollbars = (theme: Theme) => css`
	${(CUSTOM_SCROLLBARS_ON_MAC ||
		!navigator.platform.toLowerCase().includes('mac')) &&
	css`
		::-webkit-scrollbar {
			width: 12px;
			height: 12px;
			padding: 2px;
			background-color: ${theme.color.bg.lum.default};
			border: 1px solid ${theme.color.bg.lum.custom(2)};
		}

		::-webkit-scrollbar-corner:disabled,
		::-webkit-scrollbar:disabled {
			background: transparent;
			border-color: transparent;
		}

		::-webkit-scrollbar-thumb {
			background: ${theme.color.bg.lum.custom(29, -7)};
			border: 2px solid rgba(0, 0, 0, 0);
			background-clip: padding-box;
			margin: 2px;
			border-radius: 6px;
		}

		::-webkit-scrollbar-thumb:hover,
		::-webkit-scrollbar-thumb:active {
			background: ${theme.color.bg.lum.custom(49, -7)};
			background-clip: padding-box;
		}

		::-webkit-scrollbar-thumb:vertical {
			min-height: 10px;
		}
		::-webkit-scrollbar-thumb:horizontal {
			min-width: 10px;
		}
	`};
`;
