type Props = {
	className?: string;
};

export const LockContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M15 7.063l-3.938 3.875c.126.312.188.624.188.937A3.095 3.095 0 0 1 8.125 15 3.095 3.095 0 0 1 5 11.875 3.095 3.095 0 0 1 8.125 8.75c.313 0 .688.063 1 .188L10 8.124h1.25v-1.25h1.25v-1.25L13.125 5H15v2.063zm-8.125 4.812c0 .667.583 1.25 1.25 1.25s1.25-.583 1.25-1.25-.583-1.25-1.25-1.25-1.25.583-1.25 1.25z"
	/>
);
