type Props = {
	className?: string;
};

export const SeverityContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M3.41683 14.2558C3.23019 14.5891 3.47109 15.0001 3.85308 15.0001H16.147C16.5289 15.0001 16.7699 14.5891 16.5832 14.2558L10.4363 3.27914C10.2454 2.93821 9.75469 2.9382 9.56377 3.27914L3.41683 14.2558ZM10.6 13.1001H9.40002V11.9001H10.6V13.1001ZM10.6 11.0079H9.40002V6.90791H10.6V11.0079Z"
	/>
);
