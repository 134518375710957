type Props = {
	className?: string;
};

export const AmendContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		d="M10.369 6.87l2.709 2.811-4.152 4.154a.41.41 0 01-.21.111l-2.726.546a.41.41 0 01-.482-.482l.546-2.727a.41.41 0 01.111-.209L10.37 6.87zm1.83-1.25l2.181 2.182a.41.41 0 010 .578l-.753.753-2.723-2.799.716-.714a.41.41 0 01.578 0z"
	/>
);
