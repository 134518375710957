/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type { Severity } from 'types/Severity';

import { color } from 'styles';

export const RISK_SEVERITIES = {
	low: {
		id: 'low',
		label: 'Low',
		color: color.severity.low,
		value: '3',
	},
	medium: {
		id: 'medium',
		label: 'Medium',
		color: color.severity.medium,
		value: '2',
	},
	high: {
		id: 'high',
		label: 'High',
		color: color.severity.high,
		value: '1',
	},
};

export const RISK_SEVERITIES_LIST: Array<Severity> = [
	RISK_SEVERITIES.low.id,
	RISK_SEVERITIES.medium.id,
	RISK_SEVERITIES.high.id,
];

export const RISK_SEVERITIES_LIST_DESC: Array<Severity> = [
	...RISK_SEVERITIES_LIST,
].reverse();

export const NUMERAL_FORMAT = '0[.]0a';
export const NUMERAL_EXPRESSION_DOUBLE_VALUE_FORMAT = '0,0.0[00000]';
export const NUMERAL_EXPRESSION_INTEGER_VALUE_FORMAT = '0,0';

export const JOURNEY_CENTER_PATH = '/journey-center';
export const SINGLE_SIGN_ON_PARAM_NAME = 'sso-issuer';
export const SINGLE_SIGN_ON_STORAGE_KEY = 'ssoIssuer';

export const NAVBAR_TITLES = {
	summary: 'Summary',
	riskSummary: 'Risk Summary',
	journeys: 'Journeys',
	journeyCenter: 'Journey Center',
	onboarding: 'Onboarding',
	analytics: 'Analytics',
	fieldConfig: 'Field Config',
	riskDetails: 'Risk Details',
	pairingCenter: 'Pairing Center',
	businessIntelligence: 'Business Intelligence',
	videoHelp: 'Video Help',
	contents: 'Content Details',
	factsheet: 'Factsheet',
	configurationCenter: 'Configuration Center',
	actionCenter: 'Action Center',
};

export const APP_NOTIFICATION_PORTAL_ID = 'app-wide-notifications';

const SINGLE_DATE_OPTIONS = {
	onDate: 'onDate',
	dateTo: 'dateTo',
	dateFrom: 'dateFrom',
	today: 'today',
	beforeToday: 'beforeToday',
	afterToday: 'afterToday',
	last5Days: 'last5Days',
	last10Days: 'last10Days',
	last14Days: 'last14Days',
	last90Days: 'last90Days',
};
export type SingleDateOptionType = keyof typeof SINGLE_DATE_OPTIONS;

export const RANGE_DATE_OPTIONS = {
	dateRange: 'dateRange',
};

export const AGE_DATE_OPTIONS = {
	byNumber: 'byNumber',
	byRange: 'byRange',
};
export type RangeDateOptionType = keyof typeof RANGE_DATE_OPTIONS;

export const DATE_OPTIONS = {
	...SINGLE_DATE_OPTIONS,
	...RANGE_DATE_OPTIONS,
	...AGE_DATE_OPTIONS,
};
export type DateOptionType = keyof typeof DATE_OPTIONS;

// These values are inclusive, so last 5 days needs now-4d etc
export const RELATIVE_DATE_OPTIONS_VALUES = {
	today: 'now/d',
	last5Days: 'now-4d/d',
	last10Days: 'now-9d/d',
	last14Days: 'now-13d/d',
	last90Days: 'now-89d/d',
};
export type RelativeDateOptionType = keyof typeof RELATIVE_DATE_OPTIONS_VALUES;

export const GRID_MODE = {
	RISKS: 'risks',
	CONTENTS: 'contents',
};

export type GridModeType = GRID_MODE.RISKS | gridMode.CONTENTS;
