// @ts-nocheck

import React from 'react';
import { darken, lighten, shade, transparentize } from 'polished';
import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { color } from 'styles';

const height = '40px';

const variants = {
	primary: {
		bgColor: color.brandBlue,
		textColor: color.textLight,
		hoverTextColor: color.textLight,
		hoverColor: shade(0.1, color.brandBlue),
		activeColor: shade(0.15, color.brandBlue),
		disabledColor: transparentize(0.5, color.brandBlue),
	},
	darkPrimary: {
		bgColor: shade(0.1, color.brandBlue),
		textColor: color.textLight,
		hoverTextColor: color.textLight,
		hoverColor: shade(0.2, color.brandBlue),
		activeColor: shade(0.25, color.brandBlue),
		disabledColor: transparentize(0.5, shade(0.1, color.brandBlue)),
	},
	dark: {
		bgColor: color.shark,
		textColor: color.textLight,
		hoverTextColor: color.textLight,
		hoverColor: shade(0.1, color.shark),
		activeColor: shade(0.15, color.shark),
		disabledColor: transparentize(0.5, color.shark),
	},
	neutral: {
		bgColor: color.gray,
		textColor: color.textLight,
		hoverTextColor: color.textLight,
		hoverColor: shade(0.1, color.gray),
		activeColor: shade(0.15, color.gray),
		disabledColor: transparentize(0.5, color.gray),
	},
	light: {
		bgColor: color.white,
		textColor: color.textDark,
		hoverTextColor: color.textDark,
		hoverColor: shade(0.1, color.white),
		activeColor: shade(0.15, color.white),
		disabledColor: transparentize(0.5, color.white),
	},
	transparentLight: {
		bgColor: color.transparent,
		textColor: color.textLight,
		hoverTextColor: darken(0.2, color.textLight),
		hoverColor: transparentize(0.9, color.textLight),
		activeColor: transparentize(0.9, color.textLight),
		disabledColor: color.transparent,
	},
	transparentDark: {
		bgColor: color.transparent,
		textColor: color.textDark,
		hoverTextColor: lighten(0.2, color.textDark),
		hoverColor: transparentize(0.9, color.textDark),
		activeColor: transparentize(0.9, color.textDark),
		disabledColor: color.transparent,
	},
};

export function getButtonStyles(props) {
	const { variant, pill, size, variantStylesOverride = {} } = props;

	const option = { ...variants[variant], ...variantStylesOverride };

	return css`
		display: inline-block;
		min-width: ${height}; /* squared at least */
		height: ${height};
		padding: 8px 16px;
		color: white;
		font-size: 16px;
		user-select: none;
		outline: none;
		border: none;
		transition: background-color 200ms, color 200ms, opacity 200ms;

		width: ${size};
		background-color: ${option.bgColor};
		color: ${option.textColor};

		${pill && `border-radius: ${parseInt(height, 10) / 2}px;`} {
			/*add some comment to prevent prettier wrapping */
		}

		&:hover,
		&:focus {
			background-color: ${option.hoverColor};
			color: ${option.hoverTextColor};
		}

		&:active {
			background-color: ${option.activeColor};
		}

		&:disabled,
		&[disabled] {
			background-color: ${option.disabledColor};
			cursor: not-allowed;
		}
	`;
}

const StyledButton = styled.button`
	${props => getButtonStyles(props)};
`;
StyledButton.displayName = 'Button';

const ButtonAnchor = styled.a`
	${props => getButtonStyles(props)};
`;
ButtonAnchor.displayName = 'ButtonAnchor';

// NOTE: this is need due to <button> flexbox issues (http://stackoverflow.com/a/35466231)
export const ButtonContent = styled.span`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: center;
`;
ButtonContent.displayName = 'ButtonContent';

function RawButton({ children, ...props }) {
	return (
		<StyledButton {...props}>
			<ButtonContent>{children}</ButtonContent>
		</StyledButton>
	);
}

const Button = styled(RawButton)``;

export const propTypes = {
	variant: PropTypes.oneOf(Object.keys(variants)).isRequired,
	variantStylesOverride: PropTypes.object,
	size: PropTypes.string,
	pill: PropTypes.bool,
};

Button.propTypes = propTypes;

Button.defaultProps = {
	type: 'button',
	disabled: false,
	variant: 'primary',
	size: 'auto',
	pill: false,
};

export default Button;
