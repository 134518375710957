// @ts-nocheck

import { useState } from 'react';

function useInsertAtCursor(
	ref: {
		current:
			| HTMLInputElement
			| undefined
			| null
			| HTMLTextAreaElement
			| undefined
			| null;
	},
	defaultValue: string | void,
) {
	const [value, setValue] = useState<string>(defaultValue || '');

	const insert = (insertValue: string) => {
		const insertValueLength = insertValue.length;
		const current = ref.current;

		if (!current) return;

		const startPosition = current.selectionStart;
		const endPosition = current.selectionEnd;
		const finishPosition = endPosition + insertValueLength;

		const newValue =
			value.slice(0, startPosition) + insertValue + value.slice(startPosition);

		setValue(newValue);
		current.value = newValue;
		current.focus();
		current.selectionEnd = finishPosition;
	};

	return { insert, value, setValue };
}

export { useInsertAtCursor };
