export type SpacingName =
	| 'tiny'
	| 'small'
	| 'medium'
	| 'large'
	| 'xlarge'
	| 'xxlarge'
	| 'xxxlarge'
	| 'xxxxlarge';

export type Spacings = {
	[k in SpacingName]: number;
};
// Don't access these directly, use props.theme.spacing instead
export const SPACINGS: Spacings = {
	tiny: 4, // 🐜
	small: 8, // 🐌
	medium: 16, // 🐥
	large: 24, // 🐱
	xlarge: 32, //🐶
	xxlarge: 40, // 🐺
	xxxlarge: 48, // 🦧
	xxxxlarge: 56, // 🦁
};
