import styled from 'styled-components';

export function joinArray(array: Array<string> = [], text: string): string {
	if (!Array.isArray(array)) {
		return '';
	}

	return array.filter(item => !!item).join(text);
}

const invalidRegexChars = /[*°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g;

export type TextHighlight = {
	text: string;
	isHighlighted: boolean;
};

export function getHighlightedTexts(
	text: string,
	searchTerm: string,
): Array<TextHighlight> {
	const validSearchTerm = searchTerm.replace(invalidRegexChars, '');

	if (!validSearchTerm) return [{ text, isHighlighted: false }];

	const search = new RegExp(`(${validSearchTerm})`, 'gi');
	const splitValue = text.split(search);

	return splitValue
		.filter(value => !!value)
		.map((sv, i) => {
			return {
				text: sv,
				isHighlighted: !!sv.match(search),
			};
		});
}

export const HighlightedText = styled.span<{ isHighlighted: boolean }>`
	font-weight: ${props => props.isHighlighted && 700};
`;

export function parseDelimitedText(
	text: string,
	expression = /[\r\n\t,;:]+/g,
): Array<string> | null {
	const parts = text.split(expression).filter(text => text.trim());

	if (parts.length > 1) {
		return parts;
	}

	return null;
}
