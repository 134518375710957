type Props = {
	className?: string;
};

export const VisibilityOffIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		d="M14.78 5.148a.515.515 0 010 .715l-8.864 9.1a.485.485 0 01-.696 0 .515.515 0 010-.715l1.464-1.502a9.736 9.736 0 01-2.036-2.44.517.517 0 010-.5C4.732 9.651 6.78 6.011 10 6.011c.94 0 1.78.315 2.501.762l1.583-1.625a.483.483 0 01.696 0zm-.742 2.906a9.865 9.865 0 011.314 1.75.515.515 0 01-.001.504c-.087.155-2.164 3.792-5.351 3.792a4.382 4.382 0 01-1.56-.298l1.693-1.738c.978-.069 1.757-.868 1.823-1.872zM10 8.034c-1.086 0-1.97.906-1.97 2.022 0 .374.103.722.278 1.022l2.687-2.76A1.894 1.894 0 0010 8.033z"
	/>
);
