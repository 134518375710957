type Props = {
	className?: string;
};

export const DateIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(4, 4)"
		d="M12 1v11H0V1h12zM1.5 10.5h9V4h-9v6.5zM8 1h2V0H8v1zM2 1h2V0H2v1z"
	/>
);
