// @ts-nocheck

import type { Theme as ThemeType } from 'ds/styles/themes';
import type { ChangeEvent, InputHTMLAttributes, RefObject } from 'react';

import React, { useRef } from 'react';
import { useMount } from '@af/hooks';
import autosizeInput from 'autosize-input';
import styled, { css } from 'styled-components';

import { Typography } from 'ds/components/Typography';

export const SIZES = {
	tiny: 2.4,
	small: 3.2,
	normal: 4,
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	width?: number;
	inputSize?: keyof typeof SIZES;
	transparent?: boolean;
}

export const getInputStyles = ({
	theme: { spacing, color },
	width = 145,
	inputSize = 'normal',
	transparent = false,
	textAlign = 'left',
	backgroundColor = color.bg.lum.default,
}: {
	theme: ThemeType;
} & InputProps) => css`
	${props =>
		props.theme.typography.size.state
			.default}; /* Have to put this here so DatePicker will take the correct color and font-size */

	width: ${width}px;
	height: ${props => SIZES[inputSize]}rem;
	text-align: ${props => textAlign};
	background-color: ${transparent
		? 'transparent'
		: backgroundColor
		? backgroundColor
		: color.bg.lum.default};
	padding: 0 ${inputSize === 'tiny' ? spacing.tiny : spacing.small}px;
	border: none;
	transition: 0.3s ease-out;
	transition-property: color, background-color;

	:required {
		box-shadow: none;
	}

	&::placeholder {
		${props =>
			props.theme.typography.size.state
				.disabled}; /* Hakim said I should use the disabled color for placeholder */
	}

	&:focus {
		background-color: ${transparent ? 'transparent' : color.bg.lum.active};
	}

	&:hover {
		background-color: ${transparent ? 'transparent' : color.bg.lum.rollover};
	}

	&:disabled {
		${props => props.theme.typography.size.state.disabled};
		border-color: ${color.bg.lum.disabled};
		cursor: not-allowed;
	}

	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		opacity: 1;
	}
`;

const RawInput = styled.input`
	${getInputStyles};
`;

const Input: React.ForwardRefExoticComponent<
	InputProps & React.RefAttributes<HTMLInputElement>
> = styled(Typography).attrs(props => ({
	forwardedAs: RawInput,
	size: props.size || 'medium',
}))<InputProps>``;

interface NumberInputType extends InputProps {
	type?: 'text' | 'number';
	isAutoSize?: boolean;
	autoSizeProps?: {
		minWidth?: boolean;
	};
	regex?: string;
	onValueChange?: ({
		value,
		event,
	}: {
		value: string;
		event: ChangeEvent<HTMLInputElement>;
	}) => void;
}

// Based on a conversation we had about numbers (https://accessfintech.slack.com/archives/GS33NQRQS/p1599472260007300),
// @penx mentioned this article: https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
// So we've started an implementation of the same thing.
// This component handles this
// Below regex matches all positive, negative, integer and decimal numbers
const REGEX = '^-?[0-9]*\\.?[0-9]*$';
const NumberInput = (
	{
		onValueChange,
		type = 'text',
		isAutoSize = false,
		autoSizeProps = { minWidth: true },
		regex = REGEX,
		...props
	}: NumberInputType,
	ref: any,
) => {
	// Auto size input config

	const inputRef = useRef<RefObject<typeof NumberInput> | undefined | null>();

	useMount(() => {
		if (inputRef.current && isAutoSize) {
			inputRef.current && autosizeInput(inputRef.current, autoSizeProps);
		}
	});

	// inputMode doesn't have full support across browsers so by using this custom function we are making sure
	// to fall back to this in case inputMode or pattern doesn't work
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const isValid = value.match(regex);

		if (isValid) {
			return onValueChange && onValueChange({ value, event });
		}
	};

	return (
		<Input
			{...props}
			ref={reference => {
				if (reference) {
					// $FlowFixMe - Cannot assign `reference` to `inputRef.current` because  AbstractComponent [1] is incompatible with  undefined [2].Flow(InferError)
					inputRef.current = reference;
				}

				// If we still want to take the reference in the usage of `NumberInput` component, return the reference
				ref && ref(reference);
			}}
			type={type}
			inputMode="numeric"
			pattern={regex}
			onChange={handleChange}
		/>
	);
};

const NumberInputWithRef = React.forwardRef<
	NumberInputType,
	RefObject<typeof Input>
>(NumberInput);

export { Input, NumberInputWithRef as NumberInput };
// eslint-disable-next-line import/no-unused-modules
export type { NumberInputType };
