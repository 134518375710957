type Props = {
	className?: string;
};

export const AddIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6 6)"
		d="M4.6 4.6V8H3.4V4.6H0V3.4h3.4V0h1.2v3.4H8v1.2z"
	/>
);
