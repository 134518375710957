// @ts-nocheck
import { VirtualItem } from 'react-virtual';
import styled from 'styled-components';

import { Loading } from 'components/Loading';
import { Background } from 'ds/components/Background';
import { Message as StyledMessage } from 'v2/configuration/components/Message';

export const ListContent = styled.ul`
	padding: 0 ${props => props.theme.spacing.tiny}px;
	position: relative;
`;

type VirtualRowProps = {
	virtualRow: VirtualItem;
};

export const VirtualRow = styled.li<VirtualRowProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: ${props => props.virtualRow.size - props.theme.spacing.tiny}px;
	margin-top: ${props => props.theme.spacing.tiny}px;
	transform: translateY(${props => props.virtualRow.start}px);
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${props => props.theme.color.bg.lum.custom(-1)};
	overflow-y: auto;
`;

export const Message = styled(StyledMessage)`
	margin: auto;
`;

export const Subscription = styled(Background)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding: ${props => props.theme.spacing.medium}px
		${props => props.theme.spacing.small}px;
	transition: background-color 250ms ease-in-out;
	cursor: pointer;

	&:hover {
		background-color: ${props => props.theme.color.bg.lum.custom(6)};
	}
`;

export const NotificationsLoader = styled(Loading)`
	height: 100%;
`;
