type Props = {
	className?: string;
};

export const ReturnIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M10.3086 15.1235C10.5181 15.3034 10.5422 15.619 10.3623 15.8286C10.1825 16.0381 9.8668 16.0622 9.65727 15.8823L7.17433 13.751C7.06367 13.656 7 13.5174 7 13.3716C7 13.2258 7.06367 13.0872 7.17433 12.9922L9.65727 10.8609C9.8668 10.681 10.1825 10.7051 10.3623 10.9146C10.5422 11.1242 10.5181 11.4398 10.3086 11.6197L8.85021 12.8715H16.0008V9H13.949C13.6728 9 13.449 8.77614 13.449 8.5C13.449 8.22386 13.6728 8 13.949 8H16.5005C16.7767 8 17.0008 8.22386 17.0008 8.5V13.3715C17.0008 13.6477 16.777 13.8715 16.5008 13.8715H8.85009L10.3086 15.1235Z"
	/>
);
