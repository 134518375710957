// @ts-nocheck

import {
	GQLLayoutItemType,
	GQLMutationAddLayoutItemsV2Args,
	GQLView,
	GQLViewNotification,
} from '@af/api';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useRiskSummaryContext } from 'contexts/RiskSummaryContext';
import { useCurrentUser } from 'hooks/useCurrentUser';

const ADD_TO_LAYOUT_V2 = gql`
	mutation AddToLayoutV2($orgId: ID!, $userLayoutItems: [LayoutItemV2Input!]!) {
		addLayoutItemsV2(orgId: $orgId, userLayoutItems: $userLayoutItems) {
			viewId
		}
	}
`;

type MutationResponse = {
	id: GQLView['id'];
};

type MutationVariables = {
	orgId: GQLMutationAddLayoutItemsV2Args['orgId'];
	userLayoutItems: GQLMutationAddLayoutItemsV2Args['userLayoutItems'];
};

export function useAddItemToLayoutV2() {
	const { orgId } = useCurrentUser();
	const { setIsInvalidateCache } = useRiskSummaryContext();

	const [update, response] = useMutation<MutationResponse, MutationVariables>(
		ADD_TO_LAYOUT_V2,
	);

	const addItemToLayout = (viewId: GQLViewNotification['viewId']) => {
		const variables = {
			orgId,
			userLayoutItems: [
				{
					id: viewId,
					layoutItemType: GQLLayoutItemType.Risk,
					order: 0,
				},
				{
					id: viewId,
					layoutItemType: GQLLayoutItemType.ContentRecord,
					order: 0,
				},
			],
		};
		const response = update({ variables });

		// Invalidate risk summary layout cache so it requests new data
		setIsInvalidateCache(true);

		return response;
	};

	return [addItemToLayout, response];
}
