type Props = {
	className?: string;
};

export const PreferencesIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(2, 5)"
		d="M10.605.193l.79.614-7.348 9.447-3.4-3.4.707-.708 2.6 2.6L10.604.193zM15 9v1H6.5V9H15zm0-8v1h-2V1h2zm0 4v1H9.75V5H15z"
	/>
);
