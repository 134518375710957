type Props = {
	className?: string;
};

export const InstructOnHoldIcon = ({ className }: Props) => (
	<g>
		<path d="M7.5 13.01V15.31L3.5 12.51L7.5 9.81001V12.01H13.5V13.01H7.5Z" />
		<path d="M8.00001 7.01001H4.5V8.01001H8.12862C8.04548 7.69046 8.00086 7.35538 8.00001 7.01001Z" />
		<path
			className={className}
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.75 10.8334C15.8211 10.8334 17.5 9.15444 17.5 7.08337C17.5 5.01231 15.8211 3.33337 13.75 3.33337C11.6789 3.33337 10 5.01231 10 7.08337C10 9.15444 11.6789 10.8334 13.75 10.8334ZM13.3667 8.33337V5.83337H12.4667V8.33337H13.3667ZM15.0333 8.33337V5.83337H14.1333V8.33337H15.0333Z"
		/>
	</g>
);
