type Props = {
	className?: string;
};

export const RefreshIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M13.8651 13.172C14.5989 12.2778 15 11.1568 15 10H14.0877C14.0877 10.9457 13.7598 11.8622 13.1598 12.5932C12.5599 13.3242 11.725 13.8246 10.7975 14.0091C9.86993 14.1936 8.90712 14.0508 8.07308 13.605C7.23903 13.1592 6.58537 12.438 6.22346 11.5643C5.86156 10.6906 5.8138 9.71839 6.08832 8.81341C6.36285 7.90842 6.94267 7.12662 7.729 6.60121C8.51533 6.0758 9.45951 5.8393 10.4007 5.932C11.2436 6.01502 12.0374 6.3575 12.6743 6.90851L11.6667 7.91667H14.3333V5.24862L13.3206 6.26188C12.5333 5.56251 11.5431 5.12779 10.4901 5.02408C9.33888 4.9107 8.18397 5.19999 7.22215 5.84266C6.26033 6.48533 5.55109 7.44161 5.2153 8.54858C4.87951 9.65554 4.93792 10.8447 5.3806 11.9134C5.82328 12.9821 6.62283 13.8643 7.64302 14.4096C8.6632 14.9549 9.8409 15.1296 10.9755 14.9039C12.11 14.6782 13.1312 14.0662 13.8651 13.172Z"
	/>
);
