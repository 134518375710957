// @ts-nocheck

import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 auto;
`;

const spinner = keyframes`
	from {
		opacity: 1;
	}

	to {
		transform: rotate(360deg);
		opacity: 1;
	}
`;

const scaleIn = keyframes`
	from {
		transform: scale(0.8) rotate(-120deg);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

export const Spinner = styled.span`
	width: ${props => props.size}px;
	height: ${props => props.size}px;
	border-radius: 50%;
	border: ${props =>
		`${props.thickness || props.size / 12}px solid ${transparentize(
			0.666,
			props.color,
		)}`};
	border-top-color: ${props => props.color};
	animation: ${scaleIn} 200ms linear 1, ${spinner} 600ms linear infinite;
`;
