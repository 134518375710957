import type {
	GQLMutation,
	GQLMutationSetDismissNotificationArgs,
	GQLSetDismissNotificationInput,
} from '@af/api';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const DISMISS_NOTIFICATION = gql`
	mutation DismissNotification($contextOrgId: ID!, $notificationId: ID!) {
		setDismissNotification(
			contextOrgId: $contextOrgId
			setDismissNotificationParameters: {
				notificationId: $notificationId
				isDismissed: true
			}
		) {
			isDismissed
			notificationId
		}
	}
`;

type MutationResponse = GQLMutation['setDismissNotification'];

type MutationVariables = {
	contextOrgId: GQLMutationSetDismissNotificationArgs['contextOrgId'];
	notificationId: GQLSetDismissNotificationInput['notificationId'];
};

export function useDismissNotificationMutation() {
	return useMutation<MutationResponse, MutationVariables>(DISMISS_NOTIFICATION);
}
