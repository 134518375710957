// @ts-nocheck

import { combineReducers } from 'redux';

import {
	AUTH_CHECK_COMPLETE,
	RECEIVE_CONTENT_TYPES,
	RECEIVE_FIELDS,
	RECEIVE_PRODUCT_LINES,
	RECEIVE_USER,
	REQUEST_FIELDS,
	UPDATE_BADGE_COUNT,
	UPDATE_ORG_ID,
	USER_AVATAR_CHANGE,
} from './actionTypes';

const initialState = {
	badgeCount: 0,
	fieldsStatus: 'not-loaded',
	fieldList: [],
	clientFieldList: [],
	productFieldList: [],
	contentTypeList: [],
	productLineList: [],
	fieldDictionary: {},
	user: null,
	orgId: null,
	accessToken: null,
	refreshToken: null,
	isAuthCheckComplete: false,
};

const app = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_USER:
			return {
				...state,
				user: action.user,
				orgId: action.orgId,
			};

		case UPDATE_ORG_ID:
			return {
				...state,
				orgId: action.orgId,
			};

		case UPDATE_BADGE_COUNT:
			return {
				...state,
				badgeCount: action.badgeCount,
			};

		case REQUEST_FIELDS:
			return {
				...state,
				fieldsStatus: 'loading',
			};

		case RECEIVE_FIELDS:
			return {
				...state,
				fieldList: action.fieldList,
				fieldDictionary: action.fieldDictionary,
				clientFieldList: action.clientFieldList,
				productFieldList: action.productFieldList,
				genericFieldList: action.genericFieldList,
				fieldsStatus: 'loaded',
			};

		case RECEIVE_CONTENT_TYPES:
			return {
				...state,
				contentTypeList: action.contentTypeList,
			};

		case RECEIVE_PRODUCT_LINES:
			return {
				...state,
				productLineList: action.productLineList,
			};

		case AUTH_CHECK_COMPLETE:
			return {
				...state,
				isAuthCheckComplete: true,
			};

		case USER_AVATAR_CHANGE:
			return {
				...state,
				user: {
					...state.user,
					imageUrl: action.imageUrl,
				},
			};

		default:
			return state;
	}
};

const AFAPP = combineReducers({
	app,
});

export default AFAPP;
