import { css } from 'styled-components';

export function Roboto() {
	return css`
		@font-face {
			font-family: 'Roboto';
			src: url('/fonts/roboto-regular.woff2') format('woff2'),
				url('/fonts/roboto-regular.woff') format('woff');
			font-weight: 400;
			font-style: normal;
		}
		@font-face {
			font-family: 'Roboto';
			src: url('/fonts/roboto-medium.woff2') format('woff2'),
				url('/fonts/roboto-medium.woff') format('woff');
			font-weight: 500;
			font-style: normal;
		}
	`;
}
