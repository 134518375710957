// @ts-nocheck

import type { ReactNode } from 'react';

import React, {
	useMemo,
	createContext,
	useContext,
	useState,
	useRef,
} from 'react';
import { useUserMe } from '@af/hooks';
import { localStorage } from '@af/utils';

const STORE_ORG_ID_KEY = 'orgId';

const OrgIdContext = createContext<[string, any]>(['', () => {}]);

type Props = {
	children: ReactNode;
};

const OrgIdProvider = ({ children }: Props) => {
	const { organizations } = useUserMe();
	const { id } = organizations[0];
	const storedOrgId = useRef(localStorage.get(STORE_ORG_ID_KEY));

	const [orgId, setOrgId] = useState<string>(
		storedOrgId.current ? storedOrgId.current : id,
	);

	const context = useMemo(
		() => [
			orgId,
			orgId => {
				setOrgId(orgId);
				localStorage.set(STORE_ORG_ID_KEY, orgId);
			},
		],
		[orgId],
	);

	return (
		<OrgIdContext.Provider value={context}>{children}</OrgIdContext.Provider>
	);
};

const useOrgId = () => useContext(OrgIdContext);

export { OrgIdProvider, useOrgId };
