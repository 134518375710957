type Props = {
	className?: string;
};

export const MeetContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M5.81999996,7 L11.2425574,7 C11.4192884,7 11.5625573,7.14326886 11.5625573,7.31999996 L11.5625573,12.68 C11.5625573,12.8567311 11.4192884,13 11.2425574,13 L5.81999996,13 C5.64326886,13 5.5,12.8567311 5.5,12.68 L5.5,7.31999996 C5.5,7.14326886 5.64326886,7 5.81999996,7 Z M14.7124675,7.66379186 C14.751236,7.68916308 14.7749719,7.7336667 14.7749719,7.78177497 L14.7749719,12.2182726 C14.7749719,12.2663809 14.751236,12.3108845 14.7124675,12.3362557 C14.6912371,12.3499812 14.6672375,12.3569132 14.6431061,12.3569132 C14.6229306,12.3569132 14.6027551,12.3520608 14.584162,12.3422173 L12.5029744,11.1948281 L12.5029744,8.80521952 L14.584162,7.65769167 C14.6250404,7.63634103 14.6735671,7.63842064 14.7124675,7.66379186 Z"
	/>
);
