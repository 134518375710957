// @ts-nocheck

export function getAppVersion() {
	if (process.env.NODE_ENV === 'development') return 'development';

	const version = process.env.REACT_APP_AF_UI_VERSION;

	return version || 'unknown';
}

export const ENVIRONMENTS = {
	production: 'production',
	staging: 'staging',
	nightly: 'nightly',
	preview: 'preview',
	localhost: 'localhost',
};

// TODO: Replace uses of this with @af/utils version
export function getBusinessEnvironmentName():
	| keyof typeof ENVIRONMENTS
	| 'unknown' {
	const HOSTNAME_ENV_MAP = {
		'app.accessfintech.com': ENVIRONMENTS.production,
		'staging.app.accessfintech.com': ENVIRONMENTS.staging,
		'nightly.app.accessfintech.com': ENVIRONMENTS.nightly,
		localhost: ENVIRONMENTS.localhost,
	};

	const envName =
		// Environment from one of the mapped domains
		HOSTNAME_ENV_MAP[window.location.hostname] ||
		// A dynamic `xxxx.netlify.com` domain or unknown
		(window.location.hostname.includes('netlify.com')
			? ENVIRONMENTS.preview
			: 'unknown');

	return envName;
}
