// @ts-nocheck

import { localStorage } from './localStorage';
import { queryString } from './queryString';

type Token = string | undefined | null;

const CSRF_LOCAL_STORAGE_KEY = 'csrfToken';

// $FlowFixMe: This is safe
const { csrf } = queryString.getQueryParams(window.location);

// Default value for the token is the csrf query param, otherwise localStorage value
if (csrf) {
	localStorage.set(CSRF_LOCAL_STORAGE_KEY, csrf);
}

// The token in localStorage is the primary source of truth. This allows the
// user to open several tabs (with token refreshes), and not get logged out.
// We store an in-memory version of the token also, for when access to
// localStorage is not allowed (Safari private etc).
let token: Token = localStorage.get(CSRF_LOCAL_STORAGE_KEY);

const csrfToken: {
	get: () => Token;
	set: (value: string) => void;
	remove: () => void;
} = {
	get() {
		return localStorage.get(CSRF_LOCAL_STORAGE_KEY) || token;
	},

	set(value) {
		localStorage.set(CSRF_LOCAL_STORAGE_KEY, value);
		token = value;
	},

	remove() {
		localStorage.remove(CSRF_LOCAL_STORAGE_KEY);
		token = null;
	},
};
Object.freeze(csrfToken);

export { csrfToken };
