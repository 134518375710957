type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
	size?: number | string;
	title?: string;
	titleId?: string;
};

const RemoveIcon = ({ color, size = 24, title, titleId, ...props }: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.5 6a1 1 0 00-1 1v1H6v1h12V8h-2.5V7a1 1 0 00-1-1h-5zm5 2h-5V7h5v1zm-7 2.5v6A1.5 1.5 0 009 18h6a1.5 1.5 0 001.5-1.5v-6h-1v6a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-6h-1z"
			fill={color || 'currentColor'}
		/>
	</svg>
);

export default RemoveIcon;
