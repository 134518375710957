// @ts-nocheck
import type { ReactNode } from 'react';

import React from 'react';
import styled from 'styled-components';

import { Theme } from 'ds/components/core/Theme';
import { useTheme } from 'hooks/useTheme';

type Props = {
	step?: number;
	children: ReactNode;
};

const Container = styled.div`
	background-color: ${props => props.theme.color.bg.base};
`;

export const Background = React.forwardRef<Props, Container>(
	({ children, step = 0, ...props }: Props, ref) => {
		const theme = useTheme();

		return (
			<Theme baseLum={theme.color.baseLum + step}>
				<Container {...props} ref={ref}>
					{children}
				</Container>
			</Theme>
		);
	},
);
