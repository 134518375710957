// @ts-nocheck

import React from 'react';

export const InventoryIcon = ({ className }) => (
	<path
		className={className}
		transform="translate(-2 -2)"
		d="M17.9996751,14.491 L17.9996751,15.609 L11.9874955,18.6155395 L5.99967512,15.622 L5.99967512,14.503 L11.9874955,17.4975055 L17.9996751,14.491 Z M17.9996751,11.491 L17.9996751,12.609 L11.9874955,15.6155395 L5.99967512,12.622 L5.99967512,11.503 L11.9874955,14.4975055 L17.9996751,11.491 Z M11.9992848,6.5 L18.000304,9.55444209 L11.9992847,12.622635 L5.99993952,9.55444215 L11.9992848,6.5 Z M11.9993481,7.6221142 L8.20006048,9.55644264 L11.9993482,11.4994792 L15.799696,9.5564427 L11.9993481,7.6221142 Z"
	/>
);
