/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type { SpacingName } from 'ds/styles/spacings';
import type { ReactNode } from 'react';

import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

type Props = {
	isOrdered?: boolean;
	spacing?: SpacingName;
	className?: string;
	'data-test'?: string;
	children: ReactNode;
};

const Context = React.createContext<string | undefined | null>(null);

const List = (
	{ isOrdered = false, spacing, className, children, ...props }: Props,
	ref,
) => (
	<Wrapper isOrdered={isOrdered} className={className} ref={ref} {...props}>
		<Context.Provider value={spacing}>{children}</Context.Provider>
	</Wrapper>
);

const Wrapper = styled.ul.attrs(props => ({
	as: props.isOrdered ? 'ol' : 'ul',
}))`
	margin: 0;
	padding: 0;
	list-style-type: none;
`;

export type ListItemProps = {
	className?: string;
	children: ReactNode;
	'data-test'?: string;
	'data-instruction-id'?: string;
};

const ListItem: React.ForwardRefRenderFunction<HTMLLIElement, ListItemProps> = (
	props: ListItemProps,
	ref,
) => {
	const spacing = useContext(Context);

	return <Item {...props} ref={ref} spacing={spacing} />;
};

const Item = styled.li`
	${props =>
		props.spacing &&
		css`
			&:not(:last-child) {
				margin-bottom: ${props.theme.spacing[props.spacing]}px;
			}
		`}
`;

const ListItemWithRef = React.forwardRef(ListItem);

const ListWithRef = React.forwardRef<Props, Wrapper>(List);

export { ListWithRef as List, ListItemWithRef as ListItem };
