import type { SpacingName } from 'ds/styles/spacings';
import type { ThemeName } from 'ds/styles/themes';
import type { LuminosityName } from 'ds/styles/types/colors';
import type {
	TypeAlignmentName,
	TypeSizeName,
	TypographyState,
} from 'ds/styles/typography';
import type { ElementType, ReactNode } from 'react';

import React from 'react';
import styled, {
	css,
	DefaultTheme,
	FlattenSimpleInterpolation,
	ThemedStyledProps,
} from 'styled-components';

import { Theme } from './Theme';

type BoxState = 'hover' | 'active' | 'disabled';
type Position = 'top' | 'right' | 'bottom' | 'left';
type HorizontalAlign = 'left' | 'center' | 'right' | 'between';
type VerticalAlign = 'top' | 'middle' | 'bottom';
type Stack = 'horizontal' | 'vertical';
type Flex = 'flex-start' | 'center' | 'flex-end' | 'space-between';
type FlexDirection = 'row' | 'column';
type SpacingType = 'margin' | 'padding';
type Alignments = [VerticalAlign] | [VerticalAlign, HorizontalAlign];
type Spacings = Array<0 | SpacingName>;

type States = {
	[k in BoxState]: 'css' | boolean;
};

type Borders = {
	[k in Position]?: number;
};

const boxStateToTypographyState: {
	[k in BoxState]: TypographyState;
} = {
	hover: 'rollover',
	active: 'selected',
	disabled: 'disabled',
};

const boxStateToBackgroundLum: {
	[k in BoxState]: LuminosityName;
} = {
	hover: 'rollover',
	active: 'active',
	disabled: 'disabled',
};

const boxStackToFlexDirection: {
	[k in Stack]: FlexDirection;
} = {
	horizontal: 'row',
	vertical: 'column',
};

const boxAlignToFlex: {
	[k in VerticalAlign | HorizontalAlign]: Flex;
} = {
	top: 'flex-start',
	middle: 'center',
	bottom: 'flex-end',
	left: 'flex-start',
	center: 'center',
	right: 'flex-end',
	between: 'space-between',
};

type SharedProps = {
	width?: number;
	height?: number;
	minWidth?: number;
	minHeight?: number;
	padding?: Spacings;
	margin?: Spacings;
	spacing?: SpacingName;
	border?: Borders;
	align?: Alignments;
	textAlign?: TypeAlignmentName;
	stack?: Stack;
	states?: States;
	grow?: boolean;
	shrink?: boolean;
	inline?: boolean;
	scroll?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
	wrap?: string;
};

type BoxProps = {
	background?: number;
	typography?: TypeSizeName;
	theme?: ThemeName;
	children?: ReactNode;
	as?: ElementType;
} & SharedProps;

const Box: React.ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (
	{ theme, background, typography, children, as, ...props }: BoxProps,
	ref,
) => (
	<Theme name={theme} baseLum={background} typographySize={typography}>
		<Wrapper
			{...props}
			ref={ref}
			forwardedAs={as}
			hasBackground={background != null}
			hasTypography={typography != null}
		>
			{children}
		</Wrapper>
	</Theme>
);

type WrapperProps = {
	hasBackground?: boolean;
	hasTypography?: boolean;
} & SharedProps;

// Strip Box specific props from DOM, this is in case anyone does
// <Box as="span" /> so that these props don't end up on the `span`.
// eslint-disable-next-line no-use-before-define
const DivWithoutBoxProps = React.forwardRef<HTMLDivElement, WrapperProps>(
	(
		{
			width,
			height,
			minWidth,
			minHeight,
			padding,
			margin,
			spacing,
			border,
			align,
			textAlign,
			stack,
			states,
			grow,
			shrink,
			inline,
			hasBackground,
			hasTypography,
			scroll,
			wrap,
			...props
		},
		ref,
	) => <Div {...props} ref={ref} />,
);

// Must use a styled div to utilise styled-components prop whitelist
const Div = styled.div``;

const Wrapper = styled(DivWithoutBoxProps).attrs(
	({
		padding = [],
		margin = [],
		border = {},
		align = [],
		states = {},
		stack = 'vertical',
		hasBackground = false,
		hasTypography = false,
		grow = false,
		shrink = false,
		inline = false,
		scroll = 'visible',
		wrap = 'initial',
	}) => ({
		padding,
		margin,
		border,
		align,
		states,
		stack,
		hasBackground,
		hasTypography,
		grow,
		shrink,
		inline,
		scroll,
		wrap,
	}),
)<WrapperProps>`
	overflow: ${props => props.scroll};
	display: ${props => (props.inline ? 'inline-flex' : 'flex')};
	flex: ${props => Number(props.grow)} ${props => Number(props.shrink)} auto;
	flex-direction: ${props =>
		props.stack && boxStackToFlexDirection[props.stack]};
	flex-wrap: ${props => props.wrap};
	width: ${props => props.width && `${props.width}px`};
	height: ${props => props.height && `${props.height}px`};
	min-height: ${props =>
		props.minHeight ? props.minHeight + 'px' : props.shrink ? 0 : null};
	min-width: ${props =>
		props.minWidth ? props.minWidth + 'px' : props.shrink ? 0 : null};
	font: inherit;
	align-items: stretch;
	position: relative;

	${convertSpacingToCSS('padding')};
	${convertSpacingToCSS('margin')};

	${props => {
		if (props.spacing) {
			const direction = props.stack === 'horizontal' ? 'left' : 'top';
			return css`> * + * {
				margin-${direction}: ${props.theme.spacing[props.spacing]}px;
			}`;
		}
	}}

	text-align: ${props =>
		props.textAlign && props.theme.typography.alignment[props.textAlign]};

	${props =>
		!!props?.border &&
		(Object.keys(props.border) as Array<keyof Borders>).map(position => {
			const width = !!props?.border && props.border[position];
			const color = props.theme.color.bg.lum.custom(
				props.theme.color.baseLum + 10,
			);

			return `border-${position}: ${width}px solid ${color};`;
		})};

	${props => {
		if (props?.align?.length) {
			const [vertical, horizontal] = props.align;

			if (!props.inline) {
				const isHorizontalStack = props.stack === 'horizontal';
				const verticalFlex = boxAlignToFlex[vertical];
				const horizontalFlex = horizontal && boxAlignToFlex[horizontal];
				const align = isHorizontalStack ? verticalFlex : horizontalFlex;
				const justify = isHorizontalStack ? horizontalFlex : verticalFlex;

				return css`
					align-items: ${align};
					justify-content: ${justify};
				`;
			}

			return css`
				vertical-align: ${vertical};
			`;
		}
	}};

	${props => {
		const activeState =
			!!props?.states &&
			(Object.keys(props.states) as Array<keyof States>).find(
				state => !!props?.states && props.states[state] === true,
			);

		if (activeState) {
			const typographyState = boxStateToTypographyState[activeState];
			const backgroundState = boxStateToBackgroundLum[activeState];
			let typography: FlattenSimpleInterpolation | string = ``;

			if (props.hasTypography) {
				typography = css`
					${props.theme.typography.size.state.default};
					${props.theme.typography.size.state[typographyState]};
				`;
			}

			return css`
				${typography};
				background: ${props.hasBackground &&
				props.theme.color.bg.lum[backgroundState]};
			`;
		}

		return css`
			${props.hasTypography && props.theme.typography.size.state.default};
			background: ${props.hasBackground && props.theme.color.bg.base};
		`;
	}};

	${props =>
		props?.state?.hover === 'css' &&
		css`
			&:hover,
			&.focus-visible:focus {
				${props.hasTypography && props.theme.typography.size.state.rollover};
				background: ${props.hasBackground && props.theme.color.bg.lum.rollover};
			}
		`};

	${props =>
		props?.states?.active === 'css' &&
		css`
			&:active {
				${props.hasTypography && props.theme.typography.size.state.selected};
				background: ${props.hasBackground && props.theme.color.bg.lum.active};
			}
		`};

	${props =>
		props?.states?.disabled === 'css' &&
		css`
			&:disabled {
				${props.hasTypography && props.theme.typography.size.state.disabled};
				background: ${props.hasBackground && props.theme.color.bg.lum.disabled};
			}
		`};
`;

function convertSpacingToCSS(type: SpacingType) {
	return (
		props: ThemedStyledProps<
			WrapperProps & React.RefAttributes<HTMLDivElement>,
			DefaultTheme
		>,
	) => {
		const prop = props[type];

		if (prop) {
			return `${type}: ${prop
				.map(spacing => (spacing ? `${props.theme.spacing[spacing]}px` : 0))
				.join(' ')}`;
		}
	};
}

const BoxWithRef = React.forwardRef<HTMLDivElement, BoxProps>(Box);

export { BoxWithRef as Box };
