// @ts-nocheck

import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import AvatarIcon from 'components/icons/AvatarIcon';

import { AvatarImg, Wrapper } from './styles';

function Avatar({ src, size, ...props }) {
	const iconSize = size * 0.6;

	return (
		<Wrapper size={size} {...props}>
			{src ? (
				<AvatarImg src={src} size={size} />
			) : (
				<AvatarIcon width={iconSize} height={iconSize} />
			)}
		</Wrapper>
	);
}

Avatar.propTypes = {
	src: PropTypes.string,
	size: PropTypes.number.isRequired,
};

Avatar.defaultProps = {
	size: 48,
};

const StyledAvatar = styled(Avatar)``;

export default StyledAvatar;
