type Props = {
	className?: string;
};

export const DestinationIcon = ({ className }: Props) => (
	<>
		<path
			className={className}
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.5 6.5h-2v-1h2c.413 0 1.022.12 1.542.494C13.588 6.387 14 7.037 14 8c0 .963-.412 1.613-.958 2.006a2.734 2.734 0 01-1.542.494h-3c-.253 0-.644.08-.958.306C7.254 11.013 7 11.363 7 12c0 .637.254.987.542 1.194.314.226.705.306.958.306h2v1h-2c-.413 0-1.022-.12-1.542-.494C6.412 13.613 6 12.963 6 12c0-.963.412-1.613.958-2.006A2.733 2.733 0 018.5 9.5h3c.253 0 .644-.08.958-.306C12.746 8.987 13 8.637 13 8c0-.637-.254-.987-.542-1.194A1.736 1.736 0 0011.5 6.5z"
		/>
		<path
			className={className}
			d="M13.5 14a1 1 0 11-2 0 1 1 0 012 0zM8.5 6a1 1 0 11-2 0 1 1 0 012 0z"
		/>
	</>
);
