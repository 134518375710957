import type { ReactNode } from 'react';

import styled from 'styled-components';

import { Background } from 'ds/components/Background';
import { SecondaryButton } from 'ds/components/core/Button';
import Icon, { ICONS } from 'ds/components/Icon';
import { Typography } from 'ds/components/Typography';
import { fadeIn } from 'ds/styles/animation';

const DEFAULT_STEP = 2;
const MAX_WIDTH = 330;
const TOAST_DURATION = 5000;

type Props = {
	children: ReactNode;
	className?: string;
};

function ToastContent({ children, className }: Props) {
	// jsx essential for composability
	return (
		<Wrapper step={DEFAULT_STEP + 2} className={className}>
			<Icon config={ICONS.alert} badgeVariant="filled" size="medium" />
			<MessageWrapper>{children}</MessageWrapper>
		</Wrapper>
	);
}

const Toast = styled(Background).attrs(() => ({
	step: DEFAULT_STEP,
}))`
	display: flex;
	width: auto;
	min-height: 60px;
	max-width: ${MAX_WIDTH}px;
	box-shadow: 0px 5px 7px 0
		${props => props.theme.color.palette.black.alpha.custom(0.5)};
	${fadeIn};

	& + & {
		margin-top: ${props => props.theme.spacing.small}px;
	}
`;

const Wrapper = styled(Background)`
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	padding: ${props => props.theme.spacing.small}px;
`;

const MessageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 ${props => props.theme.spacing.small}px;
`;

const ToastMessage = Typography;

const ToastAction = styled(SecondaryButton).attrs(() => ({
	small: true,
}))`
	display: flex;
	width: 100%;
	flex-grow: 1;
	padding: 0 ${props => props.theme.spacing.medium}px;
`;

const ToastActions = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 1px;

	${ToastAction} + ${ToastAction} {
		margin-top: 1px;
	}
`;

export {
	Toast,
	TOAST_DURATION,
	ToastAction,
	ToastActions,
	ToastContent,
	ToastMessage,
};
