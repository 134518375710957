import type { User } from 'types/User';

import { USER_ROLES } from 'utils/roles';

export function hackUserRoles(user: User) {
	// HACK: Add all roles to user and to all organizations
	// Also handle special logic for FieldConfigUI because we actually implemented it with permissions
	const orgIds = user.organizationIds;
	const firstOrgId = orgIds[0];

	const allHACKRoles = Object.keys(USER_ROLES)
		.filter(roleName => {
			// All non FieldConfigUI roles are good.
			if (roleName !== USER_ROLES.FieldConfigUI) return true;

			// Specially calculate "FieldConfigUI" role from permission "FieldUniverseConfigRead"
			const fieldConfigPermission = user.permissions.find(
				({ name }) => name === 'FieldUniverseConfigRead',
			);

			// If permission doesn't exist, don't allow "FieldConfigUI"
			if (!fieldConfigPermission) return false;

			// Allow "FieldConfigUI" if user's organization or global org has permission for it
			if (
				fieldConfigPermission.orgIds === null ||
				fieldConfigPermission.orgIds.includes(firstOrgId)
			) {
				return true;
			}

			return false;
		})
		.map(roleName => ({
			name: roleName,
			orgIds,
		}));

	// Overwrite existing user
	const hackedUser = {
		...user,
		roles: [...user.roles, ...allHACKRoles],
	};

	return hackedUser;
}
