type Props = {
	className?: string;
};

export const AlertMiniIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		d="M9.167 12.5h1.666v1.667H9.167V12.5zm0-6.667h1.666v5.375H9.167V5.833z"
	/>
);
