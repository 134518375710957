type Props = {
	className?: string;
};

export const ListOpenIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 5)"
		d="M0 0h10v11H0V0zm1 1v1h8V1H1zm0 2v1h8V3H1zm0 2v1h8V5H1zm0 2v1h8V7H1zm0 2v1h8V9H1z"
	/>
);
