import type {
	GQLCappedNotification,
	GQLUser,
	GQLUserNotificationsArgs,
} from '@af/api';

import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { UserContext } from 'contexts/UserContext';

const GET_USER_NOTIFICATIONS_COUNT_QUERY = gql`
	query GetUserNotifications(
		$contextOrgId: ID!
		$offset: Int!
		$limit: Int!
		$isDismissed: Boolean
		$isRead: Boolean
	) {
		userMe {
			id
			notifications(
				contextOrgId: $contextOrgId
				offset: $offset
				limit: $limit
				isDismissed: $isDismissed
				isRead: $isRead
			) {
				totalItemsCount
			}
		}
	}
`;

type QueryResponse = {
	userMe: {
		id: GQLUser['id'];
		notifications: {
			totalItemsCount: GQLCappedNotification['totalItemsCount'];
			__typename: 'CappedNotification';
		};
		__typename: 'User';
	};
};

type QueryVariables = {
	contextOrgId: GQLUserNotificationsArgs['contextOrgId'];
	offset: GQLUserNotificationsArgs['offset'];
	limit: GQLUserNotificationsArgs['limit'];
	isDismissed: GQLUserNotificationsArgs['isDismissed'];
	isRead: GQLUserNotificationsArgs['isRead'];
};

type HookProps = {
	orgId?: QueryVariables['contextOrgId'];
	pollInterval?: number;
};

function useGetUserNotificationsCountQuery({
	orgId,
	pollInterval,
}: HookProps = {}) {
	const { orgId: userMeOrgId } = useContext(UserContext);

	// the offset and the limit are there just to satisfy the API requirements
	const response = useQuery<QueryResponse, QueryVariables>(
		GET_USER_NOTIFICATIONS_COUNT_QUERY,
		{
			variables: {
				contextOrgId: orgId || userMeOrgId,
				offset: 0,
				limit: 100,
				isDismissed: false,
				isRead: false,
			},
			pollInterval,
		},
	);

	const count = response?.data?.userMe?.notifications?.totalItemsCount;

	return { ...response, count };
}

export { useGetUserNotificationsCountQuery };
