type Props = {
	className?: string;
};

export const FilterIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(5, 8)"
		d="M6 5H4V4h2v1zm2-2H2V2h6v1zm2-2H0V0h10v1z"
	/>
);
