type Props = {
	className?: string;
};

export const EnvironmentIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M9.5 5H4.5V15H9.5V5ZM15.5 5H10.5V15H15.5V5ZM8 6H6V8H8V6ZM8 9H6V11H8V9ZM12 6H14V8H12V6ZM14 9H12V11H14V9Z"
	/>
);
