import type { JoinKeyExpression } from 'types/Expression';

import { deserializeExpression, serializeExpression } from '../serializer';

export const serializeSearchExpression = (
	searchExpression?: JoinKeyExpression | null,
): string => {
	if (searchExpression) {
		return serializeExpression(searchExpression);
	}

	return '';
};

export const deserializeSearchExpression = (
	searchString: string,
): JoinKeyExpression =>
	deserializeExpression(searchString) as any as JoinKeyExpression;
