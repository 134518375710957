type Props = {
	className?: string;
};

export const AcceptIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(-2, -2)"
		d="M18.499 12.113l-.5-.008.5.008zM11.887 18.5l-.01.5.01-.5zM5.5 11.887l.5.008-.5-.008zM12.113 5.5l.01-.5-.01.5zm-1.76 4.645L10 9.793l-.707.707.353.354.708-.708zM12 12.5l-.354.354.366.366.354-.379L12 12.5zm7.366-7.159l.34-.365-.73-.683-.341.366.73.682zm-1.367 6.764a6 6 0 01-6.104 5.894l-.017 1a7 7 0 007.121-6.877l-1-.017zm-6.104 5.894a6 6 0 01-5.894-6.104l-1-.017a7 7 0 006.877 7.12l.017-.999zm-5.894-6.104a6 6 0 016.104-5.894l.017-1a7 7 0 00-7.12 6.877l.999.017zM17.132 8.89A5.97 5.97 0 0118 12.105l1 .017a6.97 6.97 0 00-1.012-3.75l-.855.518zM12.105 6a5.97 5.97 0 013.182.98l.548-.837a6.97 6.97 0 00-3.713-1.142l-.017 1zm-2.459 4.854l2 2 .708-.708-2-2-.708.708zm2.72 1.987l7-7.5-.732-.682-7 7.5.732.682z"
	/>
);
