type Props = {
	className?: string;
};

export const SearchIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3.5, 3.5)"
		d="M4.492 9.172L.664 13 0 12.336l3.828-3.828c-1.656-2.005-1.52-5.072.383-6.975 2.016-2.016 5.34-2.05 7.323-.067 1.982 1.983 1.949 5.307-.067 7.323-1.903 1.903-4.97 2.04-6.975.383zm.382-6.975c-1.652 1.652-1.68 4.382-.066 5.995 1.613 1.614 4.343 1.586 5.995-.066 1.653-1.653 1.68-4.383.067-5.996C9.257.517 6.527.544 4.874 2.197z"
	/>
);
