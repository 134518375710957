type Props = {
	className?: string;
};

export const VersionDismissedIcon = ({ className }: Props) => (
	<g className={className}>
		<path d="M16.94 10.914l-.992-.13a6.057 6.057 0 000-1.569l.992-.13a7.054 7.054 0 010 1.83zM16.468 7.32l-.924.384a5.991 5.991 0 00-.784-1.356l.793-.61c.37.482.679 1.013.915 1.582zM14.26 4.446l-.609.793a5.994 5.994 0 00-1.355-.784l.383-.924c.569.236 1.1.545 1.582.915zM10.913 3.06l-.129.99a6.064 6.064 0 00-1.57 0l-.13-.99a7.064 7.064 0 011.83 0zM7.32 3.531l.383.924a5.993 5.993 0 00-1.356.784l-.61-.793a6.993 6.993 0 011.583-.915zM4.445 5.739l.793.609a5.993 5.993 0 00-.784 1.356L3.53 7.32c.236-.569.545-1.1.915-1.581zM12.679 16.469l-.383-.924a5.992 5.992 0 001.355-.784l.61.793a6.995 6.995 0 01-1.582.915zM15.553 14.261l-.793-.609c.318-.413.582-.868.784-1.355l.924.383c-.236.569-.545 1.1-.915 1.582z" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 7.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM8.3 10a1.7 1.7 0 103.4 0 1.7 1.7 0 00-3.4 0z"
		/>
		<path d="M11 16a1 1 0 11-2 0 1 1 0 012 0zM6 9l-2 2-2-2h4z" />
	</g>
);
