// @ts-nocheck

import type { GQLGenericField } from '@af/api';
import type { AnyKindOfKeyExpression } from 'types/Expression';

import React, { useMemo, useState } from 'react';
import { DeleteIcon } from '@af/icons';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Loading } from 'components/Loading';
import { IconButton } from 'ds/components/core/Button';
import { ErrorMessage } from 'ds/components/ErrorMessage';
import Icon, { ICONS } from 'ds/components/Icon';
import { Input } from 'ds/components/Input';
import { List, ListItem } from 'ds/components/List';
import { Typography } from 'ds/components/Typography';
import { useAllViewId } from 'hooks/useAllViewId';
import { useDebounce } from 'hooks/useDebounce';
import { serializeSearchExpression } from 'utils/expressionsv2/normalizer';
import { parseDelimitedText } from 'utils/string';

import { useGlobalSearchQuery } from './useGlobalSearchQuery';

// {
//   "key": "SystemField_ApiDataRisk_UpdateTime",
//   "dateOption": "last90Days",
//   "fromDate": "2020-08-02T23:00:00.000Z",
//   "toDate": "2020-01-01T00:00:00.000Z",
//   "expression": {
//     "left": {
//       "key": "SystemField_ApiDataRisk_UpdateTime"
//     },
//     "operator": "ge",
//     "right": {
//       "value": "2020-01-01T00:00:00.000Z",
//       "valueType": "constant"
//     }
//   }
// }

// This shortened string holds the above config object for time period from
// packages/af-app/src/v2/risks/components/ParamsItemTimePeriod.js line 39
const HACK_90_DAYS_TIME_PERIOD_QUERY_STRING =
	'timePeriod=N4Ig1gpgniBcIGUoGcAuEC2AxAlhANgCYD6AggA44AiAhqjQEo7JjECq5hdEAKjhhBAAaEF3QB5cqhwB7AHZwQ+GmgCcABlophIAGYAnGRlrpFAJnUWAtOoAcNszzMBmWOvVv1AOnfqAWjqoMiaC8BbWdjbOTmawAKyq8apeqqkBIhAAHuT6EMjIsgqwoPgQuqhwoJAw8EhomLgEJBTUdIzMrBxivPyCAL4iMuQQ+nQy+ooA5oIi+jiTABYVxSAAbjT4AK6hIOHqNgCMhzzunp4+7ulrG9s8UMOKAMbyaDRyFX2fQA';
const MIN_SEARCH_CHARACTERS = 2;
const DEBOUNCE_TIME = 500;

// These are global fields, and are controlled by us. It's safe to reference
// these for each environment. We will check to see if they are available
// before we query.
export const SEARCH_FIELDS_DICT = {
	CP: 'Counter party',
	ISIN: 'ISIN',
	REF: 'ReferenceID',
	LINK: 'LinkID',
};
const SEARCH_FIELDS = [
	SEARCH_FIELDS_DICT.CP,
	SEARCH_FIELDS_DICT.ISIN,
	SEARCH_FIELDS_DICT.REF,
	SEARCH_FIELDS_DICT.LINK,
];

const GET_GLOBAL_SEARCH_FIELDS = gql`
	query GetGlobalSearchFields {
		# Trade is ContentType 1 on every environment
		genericFields(contentTypeId: "1") {
			key
			name
		}
	}
`;

export type GenericField = {
	key: GQLGenericField['key'];
	name: GQLGenericField['name'];
};

type QueryVariables = {};

type QueryResult = {
	genericFields: Array<GenericField>;
};

const DEFAULT_STATES = {
	searchQuery: '',
	isInputDisplayed: false,
	searchTexts: [],
	resultCount: 0,
};

type Props = {
	className?: string;
};

export function GlobalSearch({ className }: Props) {
	// Get a list of all global fields
	const { data: globalFields, loading: globalFieldsLoading } = useQuery<
		QueryResult,
		QueryVariables
	>(GET_GLOBAL_SEARCH_FIELDS);

	const { data } = useAllViewId();

	// We only want to query for fields that are available, to prevent GQL errors
	const enabledSearchFields = useMemo(() => {
		if (!globalFields) return [];

		return globalFields?.genericFields?.filter(field =>
			SEARCH_FIELDS.includes(field.name),
		);
	}, [globalFields]);

	const [searchQuery, setSearchQuery] = useState<string>(
		DEFAULT_STATES.searchQuery,
	);
	const [isInputDisplayed, setIsInputDisplayed] = useState<boolean>(
		DEFAULT_STATES.isInputDisplayed,
	);
	const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCE_TIME);

	const [searchTexts, setSearchTexts] = useState<Array<string>>(
		DEFAULT_STATES.searchTexts,
	);

	const canSearch =
		debouncedSearchQuery.length > MIN_SEARCH_CHARACTERS ||
		Boolean(searchTexts.length);

	const [resultCount, setResultCount] = useState<number>(
		DEFAULT_STATES.resultCount,
	);
	const [searchExpression, setSearchExpression] =
		useState<AnyKindOfKeyExpression | void>();

	const debouncedSearchTexts = useMemo(
		() => (canSearch ? [debouncedSearchQuery, ...searchTexts] : []),
		[canSearch, debouncedSearchQuery, searchTexts],
	);

	const { searchResults, isLoading, error } = useGlobalSearchQuery({
		searchTexts: debouncedSearchTexts,
		searchFields: enabledSearchFields,
	});

	const contentCenterUrl = useMemo(() => {
		const allViewId = data?.allView?.id;

		if (!allViewId) return null;

		return `/view/${allViewId}/contents`;
	}, [data]);

	const isDisplayNoRecordsLabel =
		(debouncedSearchQuery || searchTexts) &&
		!isLoading &&
		canSearch &&
		!resultCount;

	if (!globalFieldsLoading && !enabledSearchFields.length) {
		return null;
	}

	if (!contentCenterUrl) {
		return null;
	}

	const isResultListVisible =
		isInputDisplayed && (Boolean(searchResults.length) || Boolean(resultCount));

	const redirectionUrl = `${contentCenterUrl}?search=${serializeSearchExpression(
		{
			operator: 'and',
			expressions: searchExpression ? [searchExpression] : [],
		},
	)}&${HACK_90_DAYS_TIME_PERIOD_QUERY_STRING}`;

	function reset() {
		setSearchQuery(DEFAULT_STATES.searchQuery);
		setIsInputDisplayed(DEFAULT_STATES.isInputDisplayed);
		setSearchTexts(DEFAULT_STATES.searchTexts);
		setResultCount(DEFAULT_STATES.resultCount);
	}

	function handlePaste(event) {
		const text = event.clipboardData.getData('Text');
		const values = parseDelimitedText(text);

		if (values) {
			event.preventDefault();

			setSearchTexts(values);
		}
	}

	function removeValueByIndex(removedIndex: number) {
		const newValues = searchTexts.filter((_, index) => index !== removedIndex);

		setSearchTexts(newValues);
	}

	return (
		<Wrapper className={className}>
			<IconButton>
				<Icon
					config={ICONS.search}
					onClick={() => {
						if (isInputDisplayed) {
							reset();
						} else {
							setIsInputDisplayed(true);
						}
					}}
				/>
			</IconButton>

			{isInputDisplayed && (
				<OverlayWrapper>
					{Boolean(searchTexts.length) && (
						<SearchQueriesWrapper>
							{searchTexts.map((searchText, index) => (
								<SearchText key={searchText}>
									{searchText}{' '}
									<IconButton
										title={`Remove ${searchText}`}
										transparent
										onClick={() => removeValueByIndex(index)}
									>
										<DeleteIcon />
									</IconButton>
								</SearchText>
							))}
						</SearchQueriesWrapper>
					)}

					<StyledInput
						placeholder="Search records"
						value={searchQuery}
						onChange={event => setSearchQuery(event.target.value)}
						onPaste={handlePaste}
						autoFocus
					/>

					<ContentWrapper>
						{isLoading ? (
							<LoadingWrapper>
								<Loading />
							</LoadingWrapper>
						) : isResultListVisible ? (
							<>
								<List>
									{searchResults.map(result => (
										<Item
											key={result.label}
											onClick={() => {
												setSearchQuery('');
												setSearchTexts([...searchTexts, searchQuery]);
												setSearchExpression(result.expression);
												setResultCount(result.count);
											}}
										>
											<Typography size="medium">
												{result.count} records on {result.label}
											</Typography>
										</Item>
									))}
								</List>
								<>
									{Boolean(searchTexts.length) && Boolean(resultCount) && (
										<StyledLink
											to={{
												// Uses the hacky /redirect url to navigate the user twice. Check App.js for details
												pathname: '/redirect',
												state: { nextUrl: redirectionUrl },
											}}
											onClick={() => {
												reset();
											}}
										>
											<Typography size="medium">
												Show {resultCount} results
											</Typography>
										</StyledLink>
									)}
								</>
							</>
						) : isDisplayNoRecordsLabel ? (
							<NoResultsWrapper>
								<Typography size="medium">No records found</Typography>
							</NoResultsWrapper>
						) : error ? (
							<ErrorMessage
								error={error}
								defaultGQLErrorMessage="There was a problem while requesting search results"
								defaultErrorMessage="There was a network problem while requesting search results"
							/>
						) : null}
					</ContentWrapper>
				</OverlayWrapper>
			)}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	position: relative;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SearchText = styled(Typography)`
	display: inline-flex;
	height: 24px;
	margin-right: ${props => props.theme.spacing.tiny}px;
	margin-bottom: ${props => props.theme.spacing.tiny}px;
	padding-left: ${props => props.theme.spacing.small}px;
	white-space: nowrap;
	align-items: center;
	background-color: ${({ theme }) => theme.color.bg.lum.custom(12)};
`;

const StyledInput = styled(Input)`
	width: 100%;
`;

const OverlayWrapper = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	width: 350px;
	padding: ${props => props.theme.spacing.small}px;
	background-color: ${props => props.theme.color.bg.base};
	box-shadow: 0 7px 7px rgba(0, 0, 0, 0.3);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
`;

const Item = styled(ListItem)`
	margin-top: 1px;
	padding: ${props => props.theme.spacing.small}px;
	cursor: default;
`;

const NoResultsWrapper = styled.div`
	margin-top: 1px;
	padding: ${props => props.theme.spacing.small}px 0;
	text-align: center;
`;

const StyledLink = styled(Link)`
	display: block;
	padding: ${props => props.theme.spacing.small}px;
`;

const LoadingWrapper = styled.div`
	padding: ${props => props.theme.spacing.small}px;
`;

const SearchQueriesWrapper = styled.div`
	max-height: 150px;
	margin-bottom: ${props => props.theme.spacing.small}px;
	overflow-y: auto;
`;

const ContentWrapper = styled.div`
	margin-top: ${props => props.theme.spacing.small}px;
`;
