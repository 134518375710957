type Props = {
	className?: string;
};

export const CheckedIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M13.108 6.69l.784.62-5.102 6.432-2.637-2.535.694-.721 1.843 1.772z"
	/>
);
