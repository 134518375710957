// @ts-nocheck

import React from 'react';
import styled, { css } from 'styled-components';

import Icon from 'ds/components/Icon';
import { animation, color } from 'styles';

import { variants } from './';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 100%;
	color: ${color.gray};
	animation: ${animation.scaleIn} 0.2s ease-out 1;
`;

export const StyledIcon = styled(props => <Icon {...props} />)`
	width: 30%;
	height: 30%;
	color: ${color.gray};

	${({ size }) => css`
		max-width: ${variants[size].maxWidth};
		max-height: ${variants[size].minWidth};
		margin-bottom: ${variants[size].marginBottom};
	`};
`;
