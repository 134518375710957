import type { FullStatusVariant } from 'components/FullStatus';

import Error from './Error';

type Props = {
	variant: FullStatusVariant;
};

function NotFound({ variant }: Props) {
	return (
		<Error
			message={'404: Page not found'}
			nextPath={'/'}
			nextLabel={'Return to Dashboard'}
			variant={variant}
		/>
	);
}

NotFound.defaultProps = {
	variant: 'large',
};

export default NotFound;
