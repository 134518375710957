import type { GQLClientRule } from '@af/api';
import type { AndExpressionType } from 'types/Expression';
import type { FieldValue } from 'utils/expressionsv2/value';

import { convertExpressionToFieldValue } from 'utils/expressionsv2/value';

export const ClientRuleResolver = {
	ClientRule: {
		fieldValues: ({
			expression,
		}: {
			expression: GQLClientRule['expression'];
		}): Array<FieldValue> | undefined | null => {
			const joinExpression: AndExpressionType | undefined | null = expression;

			return (
				joinExpression &&
				joinExpression.expressions.map(convertExpressionToFieldValue)
			);
		},
	},
};
