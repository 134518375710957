// See https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
export const introspectionQueryResultData = {
	__schema: {
		types: [
			{
				kind: 'UNION',
				name: 'ExpressionMeta',
				possibleTypes: [
					{
						name: 'NoValueExpressionMeta',
					},
					{
						name: 'SingleValueExpressionMeta',
					},
					{
						name: 'DoubleValueExpressionMeta',
					},
					{
						name: 'MultipleValueExpressionMeta',
					},
				],
			},
			{
				kind: 'UNION',
				name: 'ContentRecordEventUnionType',
				possibleTypes: [
					{
						name: 'AttachmentContentRecordEvent',
					},
					{
						name: 'CommentContentRecordEvent',
					},
					{
						name: 'InstructionContentRecordEvent',
					},
					{
						name: 'ContentRecordEvent',
					},
				],
			},
		],
	},
};
