import { ComponentProps } from 'react';
import styled from 'styled-components';

import { PrimaryButton, SecondaryButton } from 'ds/components/core/Button';
import { Typography } from 'ds/components/Typography';
import { Box } from 'v2/components/Box';

export const EventType = styled(Box).attrs(() => ({
	background: 3,
	padding: ['small', 'tiny'],
	align: ['center', 'between'],
	stack: 'horizontal',
}))``;

export const ActionTitle = styled(Box).attrs(() => ({
	padding: ['small', 'tiny'],
	align: ['center', 'between'],
	stack: 'horizontal',
}))``;

export const Header = styled(Box).attrs(() => ({
	align: ['middle', 'middle'],
	padding: ['xxxlarge', 0, 'large', 0],
}))``;

export const HeaderTitle = styled(Typography).attrs(() => ({
	as: 'h2',
	size: 'large',
	isBold: true,
}))`
	color: ${props => props.theme.color.palette.white.base};
`;

export const HeaderSubtitle = styled(Typography)`
	margin-top: ${props => props.theme.spacing.small}px;
`;

export const Body = styled(Box).attrs(() => ({
	background: 0,
	grow: true,
	shrink: true,
}))`
	overflow-y: auto;
`;

export const Footer = styled(Box).attrs(() => ({
	background: 0,
	stack: 'horizontal',
	padding: ['small'],
}))``;

export const CancelButton = styled(SecondaryButton).attrs(() => ({
	flex: true,
}))``;

type ApplyButtonProps = ComponentProps<typeof PrimaryButton> &
	React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ApplyButton = styled(PrimaryButton).attrs(() => ({
	color: 'brandBlue',
	flex: true,
}))<ApplyButtonProps>`
	margin-left: ${props => props.theme.spacing.small}px;
`;

type EventTypeLabelProps = {
	isEnabled: boolean;
};

export const EventTypeLabel = styled(Typography)<EventTypeLabelProps>`
	color: ${props =>
		!!props.isEnabled
			? props.theme.color.palette.white.alpha.custom(0.95)
			: props.theme.color.palette.white.alpha.custom(0.7)};
`;

export const NotificationAlertMessage = styled(Typography)`
	margin-left: ${props => props.theme.spacing.medium}px;
`;
