type Props = {
	className?: string;
};

export const MarketReliefIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M5 9.99983C5 8.24509 5.90353 6.7011 7.27309 5.80813L6.72691 4.97046C5.08626 6.04019 4 7.89302 4 9.99983C4 12.1066 5.08626 13.9595 6.72691 15.0292L7.27309 14.1915C5.90353 13.2986 5 11.7546 5 9.99983ZM12.7269 5.80813C14.0965 6.7011 15 8.24509 15 9.99983C15 11.7546 14.0965 13.2986 12.7269 14.1915L13.2731 15.0292C14.9137 13.9595 16 12.1066 16 9.99983C16 7.89302 14.9137 6.04019 13.2731 4.97046L12.7269 5.80813ZM8 9.99989C8 8.89532 8.89543 7.99989 10 7.99989C11.1046 7.99989 12 8.89532 12 9.99989C12 11.1045 11.1046 11.9999 10 11.9999C8.89543 11.9999 8 11.1045 8 9.99989ZM10 6.99989C8.34315 6.99989 7 8.34304 7 9.99989C7 11.6567 8.34315 12.9999 10 12.9999C11.6569 12.9999 13 11.6567 13 9.99989C13 8.34304 11.6569 6.99989 10 6.99989Z"
	/>
);
