// @ts-nocheck

import { configureScope } from '@sentry/browser';

import { getLocalStorageOrgId } from 'utils/orgId';
import { setSsoIssuerToLocalStorage } from 'utils/users';

import {
	AUTH_CHECK_COMPLETE,
	RECEIVE_USER,
	REQUEST_FIELDS,
	UPDATE_ORG_ID,
	USER_AVATAR_CHANGE,
} from './actionTypes';
import { processInitialData } from './initialAppLoad/appLoadActions';

export const requestFields = { type: REQUEST_FIELDS };

export const receiveUser = user => async dispatch => {
	if (user.ssoIssuer) {
		setSsoIssuerToLocalStorage(user.ssoIssuer);
	}

	// Add userId to sentry tracking
	configureScope(scope => {
		scope.setUser({ id: user.id });
	});

	const prevOrgId = getLocalStorageOrgId();
	const userOrgIds = user.organizationIds || [];
	const defaultOrgId =
		userOrgIds.find(orgId => orgId === prevOrgId) || userOrgIds[0];

	processInitialData(defaultOrgId)(dispatch);

	dispatch({
		type: RECEIVE_USER,
		user,
		orgId: defaultOrgId,
	});
	dispatch(authCheckComplete);
};

export const missingUser = () => async dispatch => {
	dispatch(authCheckComplete);

	return null;
};

export const updateOrganization = orgId => {
	// Attach orgId information for easier filtering on sentry
	configureScope(scope => {
		scope.setExtra('orgId', orgId);
	});

	return {
		type: UPDATE_ORG_ID,
		// TODO: We have to cast this as a number, as REST calls still send ids as numbers
		orgId: Number(orgId),
	};
};

const authCheckComplete = { type: AUTH_CHECK_COMPLETE };

export const onUserAvatarChange = imageUrl => ({
	type: USER_AVATAR_CHANGE,
	imageUrl,
});
