type Props = {
	className?: string;
};

export const ArrowLeftIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M7.368 10.5l2.508 2.882-.754.657-3.519-4.043L9.131 6.05l.746.667L7.387 9.5h6.47v1z"
		fillRule="evenodd"
	/>
);
