// @ts-nocheck

import type {
	FetchMoreProps,
	NotificationSubscriptionType,
	QueryResponse,
} from 'App/hooks/useGetWatchlistQuery';

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { GQLNotificationSubscription } from '@af/api';
import { WatchMeIcon, WatchUnwatchIcon } from '@af/icons';
import { useHistory } from 'react-router';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';

import { useGetWatchlistQuery } from 'App/hooks/useGetWatchlistQuery';
import Curtain from 'components/Curtain';
import { Loading } from 'components/Loading';
import { IconButton, NakedButton } from 'ds/components/core/Button';
import { ErrorMessage } from 'ds/components/ErrorMessage';
import { Typography } from 'ds/components/Typography';
import { useCreateNotificationSubscriptionMutation } from 'hooks/useCreateNotificationSubscriptionMutation';
import { useCreateTemporaryExpressionMutation } from 'hooks/useCreateTemporaryExpressionMutation';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDeleteNotificationSubscriptionMutation } from 'hooks/useDeleteNotificationSubscriptionMutation';
import { generateNotificationSubscriptionPayload } from 'utils/subscriptions';
import { getContentDetailTempExpressionUrl } from 'utils/tempExpression';
import { Toast, ToastMessage } from 'v2/components/Toast';

import {
	ListContent,
	Message,
	Subscription,
	VirtualRow,
	Wrapper,
} from './styles';

const LOADING_SPINNER_SIZE = 16;
const TOAST_DURATION = 3000;

function WatchlistPanel() {
	const { count, subscriptions, loading, error, fetchMore } =
		useGetWatchlistQuery({
			limit: 100,
		});
	const parentRef = useRef();

	const isEmptyList = count === 0;

	const subscriptionsLength = subscriptions.length;

	const estimateSize = useCallback(() => 64, []);
	const subscriptionsVirtualized = useVirtual({
		size: subscriptionsLength,
		parentRef,
		estimateSize,
	});

	const hasMore = useMemo(() => {
		return count && subscriptionsLength < count;
	}, [count, subscriptionsLength]);

	// The `loading` property doesn't give us enough control over when new rows
	// are being fetched. We only want new requests to happen after they have been
	// rendered. So we're hooking into `useVirtual`'s totalSize property - when
	// that changes, we can load more rows.
	const [isFetchingMore, setIsFetchingMore] = useState(false);
	const [currentLoadingItem, setCurrentLoadingItem] = useState<
		GQLNotificationSubscription['id'] | null
	>(null);
	const [unsubscribedItems, setUnsubscribedItems] = useState<
		GQLNotificationSubscription['id'][]
	>([]);
	const [subscriptionError, setSubscriptionError] = useState<string>('');

	const {
		user: { id: userId },
	} = useCurrentUser();
	const [deleteSubscription, { loading: isRemovingSubscription }] =
		useDeleteNotificationSubscriptionMutation();
	const [createSubscription, { loading: isCreatingSubscription }] =
		useCreateNotificationSubscriptionMutation();

	const history = useHistory();

	useEffect(() => {
		setIsFetchingMore(false);
	}, [subscriptionsVirtualized.totalSize]);

	const loadMore = useCallback(() => {
		fetchMore<FetchMoreProps, QueryResponse>({
			variables: { offset: subscriptions.length },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const previousSubscriptions =
					previousResult?.userMe?.paginatedNotificationSubscriptions
						?.notificationSubscriptions || [];
				const newSubscriptions =
					fetchMoreResult?.userMe?.paginatedNotificationSubscriptions
						?.notificationSubscriptions || [];

				return {
					...previousResult,
					userMe: {
						...previousResult.userMe,
						paginatedNotificationSubscriptions: {
							...previousResult.userMe.paginatedNotificationSubscriptions,
							notificationSubscriptions: [
								...previousSubscriptions,
								...newSubscriptions,
							],
						},
					},
				};
			},
		});
	}, [fetchMore, subscriptions.length]);

	const toggleSubscription = useCallback(
		async (subscription: NotificationSubscriptionType) => {
			const {
				id: subscriptionId,
				entity: { serializedCompositeKey },
			} = subscription;

			setCurrentLoadingItem(subscriptionId);

			if (unsubscribedItems.includes(subscriptionId)) {
				await createSubscription(
					generateNotificationSubscriptionPayload(
						userId,
						serializedCompositeKey,
					),
				)
					.then(() =>
						setUnsubscribedItems(
							unsubscribedItems.filter(id => id !== subscriptionId),
						),
					)
					.catch(() => setSubscriptionError('Error creating subscription.'));
			} else {
				await deleteSubscription({
					subscriptionId,
				})
					.then(() =>
						setUnsubscribedItems(unsubscribedItems.concat(subscriptionId)),
					)
					.catch(() => setSubscriptionError('Error removing subscription.'));
			}

			setCurrentLoadingItem(null);
		},
		[userId, unsubscribedItems, createSubscription, deleteSubscription],
	);

	const [getTemporaryExpression] = useCreateTemporaryExpressionMutation();
	const [watchlistPathError, setWatchlistPathError] = useState<string>('');

	const handleWatchlistItemClicked = async subscription => {
		let tempExpressionPath;

		if (!subscription.entity.id) return;

		await getTemporaryExpression(
			{ contentRecordId_eq: subscription?.entity.id },
			1,
		)
			.then(
				res =>
					(tempExpressionPath = getContentDetailTempExpressionUrl(
						res?.data?.createTemporaryExpression.id,
					)),
			)
			.catch(err => setWatchlistPathError(err.message));

		if (tempExpressionPath) {
			history.push(tempExpressionPath);
		}
	};

	useEffect(() => {
		const lastItemIndex =
			subscriptionsVirtualized.virtualItems[
				subscriptionsVirtualized.virtualItems.length - 1
			]?.index;

		if (lastItemIndex == null) {
			return;
		}

		if (
			lastItemIndex === subscriptions.length - 1 &&
			hasMore &&
			!loading &&
			!isFetchingMore
		) {
			setIsFetchingMore(true);
			loadMore();
		}
	}, [
		subscriptionsVirtualized.virtualItems,
		hasMore,
		loading,
		isFetchingMore,
		loadMore,
		subscriptions.length,
	]);

	return (
		<Wrapper ref={parentRef}>
			<Curtain active={isFetchingMore}>
				<Loading />
			</Curtain>

			{error ? (
				<Message>
					<ErrorMessage
						error={error}
						defaultErrorMessage="There was a network error trying to load the items."
						defaultGQLErrorMessage="There was an error loading the items"
					/>
				</Message>
			) : isEmptyList ? (
				<Message>There are no subscriptions</Message>
			) : (
				<>
					{subscriptionError && (
						<Toast
							autoClose={TOAST_DURATION}
							onDismiss={() => setSubscriptionError('')}
						>
							<ToastMessage>{subscriptionError}</ToastMessage>
						</Toast>
					)}

					{watchlistPathError && (
						<Toast
							autoClose={TOAST_DURATION}
							onDismiss={() => setWatchlistPathError('')}
						>
							<ToastMessage>{watchlistPathError}</ToastMessage>
						</Toast>
					)}
					<ListContent
						style={{ height: `${subscriptionsVirtualized.totalSize}px` }}
					>
						{subscriptionsVirtualized.virtualItems.map(virtualRow => {
							const subscription = subscriptions[virtualRow.index];

							if (!subscription) return null;

							return (
								<VirtualRow key={virtualRow.index} virtualRow={virtualRow}>
									<Subscription>
										<NotificationButton
											onClick={() => handleWatchlistItemClicked(subscription)}
										>
											<Typography align="left">
												{subscription.entity
													? subscription.entity.titleV2
													: '-'}
											</Typography>
										</NotificationButton>
										<IconButton
											transparent
											disabled={
												isRemovingSubscription || isCreatingSubscription
											}
											onClick={() => toggleSubscription(subscription)}
										>
											{currentLoadingItem === subscription.id ? (
												<Loading size={LOADING_SPINNER_SIZE} />
											) : unsubscribedItems.includes(subscription.id) ? (
												<WatchUnwatchIcon />
											) : (
												<WatchMeIcon />
											)}
										</IconButton>
									</Subscription>
								</VirtualRow>
							);
						})}
					</ListContent>

					{loading && <Loading />}
				</>
			)}
		</Wrapper>
	);
}

const NotificationButton = styled(NakedButton)`
	cursor: pointer;
`;

export { WatchlistPanel };
