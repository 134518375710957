type Props = {
	className?: string;
};

export const SortIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(3, 6)"
		d="M8 7H6V6h2v1zm0-3H3V3h5v1zm0-3H0V0h8v1zm4 5h1.5l-1.999 1.999L9.502 6H11V0h1v6z"
	/>
);
