// Following field entities can't be aggregated because they are not on ElasticSearch
export const AGGREGATION_DISALLOWED_FIELDS = [
	'ComputableSystemField',
	'ComputableClientField',
];

export const RESOURCE_TYPE = {
	constant: 'constant',
	clientField: 'clientField',
	productLineField: 'productLineField',
	systemField: 'systemField',
	genericField: 'genericField',
	enrichmentField: 'enrichmentField',
};
