// @ts-nocheck

import type { GQLRiskProfileItem } from '@af/api';
import type {
	AndExpressionType,
	AnyKindOfExpression,
	JoinExpression,
	SingleValueExpression,
} from 'types/Expression';
import type { OrExpression } from 'types/Journey';
import type { FieldValue } from 'utils/expressionsv2/value';

import { recursiveFlatten } from 'utils/expressionsv2';
import { getFieldIdFromExpression } from 'utils/expressionsv2/field';
import { OPERATORS } from 'utils/expressionsv2/operator';
import { convertExpressionToFieldValue } from 'utils/expressionsv2/value';

const DEFAULT_PRODUCT_AND_EXPRESSION: AndExpressionType = {
	operator: OPERATORS.and,
	expressions: [],
};

const DEFAULT_PRODUCT_OR_EXPRESSION: OrExpression = {
	operator: OPERATORS.or,
	expressions: [],
};

export const RiskProfileItemResolver = {
	RiskProfileItem: {
		productExpression: productExpressionResolver,
		productFieldValues: productFieldValuesResolver,
		systemExpression: systemExpressionResolver,
		systemFieldValues: systemFieldValuesResolver,
		clientExpression: clientExpressionResolver,
		clientFieldValues: clientFieldValuesResolver,
		timeBasedFieldValue: timeBasedFieldValueResolver,
		metadataExpression: metadataExpressionResolver,
		metadataFieldValues: metadataFieldValuesResolver,
		riskFactorFieldValue: riskFactorFieldValueResolver,
	},
};

function productExpressionResolver({
	productExpression,
}: {
	productExpression: OrExpression | SingleValueExpression | undefined | null;
}): OrExpression {
	if (!productExpression) {
		// `null` expressions are converted to empty list of expressions
		return DEFAULT_PRODUCT_OR_EXPRESSION;
	}

	if (
		productExpression.operator &&
		productExpression.operator === OPERATORS.or
	) {
		// Expression has already been converted to multiple
		return productExpression;
	}

	// Convert singular expression to multiple
	return {
		...DEFAULT_PRODUCT_OR_EXPRESSION,
		expressions: [productExpression],
	};
}

function systemExpressionResolver({
	systemExpression,
}: {
	systemExpression:
		| AndExpressionType
		| SingleValueExpression
		| undefined
		| null;
}): AndExpressionType {
	return expressionResolver(systemExpression);
}

function productFieldValuesResolver({
	productExpression,
}: {
	productExpression: OrExpression | SingleValueExpression | undefined | null;
}) {
	const joinExpression = productExpressionResolver({ productExpression });

	return (
		joinExpression &&
		joinExpression.expressions.map<FieldValue>(convertExpressionToFieldValue)
	);
}

function systemFieldValuesResolver({
	systemExpression,
}: {
	systemExpression: GQLRiskProfileItem['systemExpression'];
}) {
	return fieldValuesResolver(systemExpression);
}

// HACK: Core requires metadata fields to be stored in the `timeBasedExpression`
// So we have to save those fields there, but filter them out when receiving the
// expression back down.
// Ref: https://access-fintech.atlassian.net/browse/FIN-7880
function timeBasedFieldValueResolver({
	timeBasedExpression,
}: {
	timeBasedExpression: JoinExpression | AnyKindOfExpression | undefined | null;
}): FieldValue | undefined | null {
	if (!timeBasedExpression) return null;

	// The timeBasedExpression coming down may be a join, but we only want **one**
	// actual time expression in the UI
	const flattenedExpressions = recursiveFlatten(timeBasedExpression);
	const timeBasedClientFieldExpression = flattenedExpressions.find(
		expression => {
			const fieldId = getFieldIdFromExpression(expression);

			return fieldId && !fieldId.startsWith('Metadata');
		},
	);

	return timeBasedClientFieldExpression
		? convertExpressionToFieldValue(timeBasedClientFieldExpression)
		: null;
}

function riskFactorFieldValueResolver({
	riskFactorExpression,
}: {
	riskFactorExpression: AnyKindOfExpression | undefined | null;
}) {
	return riskFactorExpression
		? convertExpressionToFieldValue(riskFactorExpression)
		: null;
}

function expressionResolver(expression) {
	if (!expression) {
		// `null` expressions are converted to empty list of expressions
		return DEFAULT_PRODUCT_AND_EXPRESSION;
	}

	if (expression.operator && expression.operator === OPERATORS.and) {
		// Expression has already been converted to multiple
		return expression;
	}

	// Convert singular expression to multiple
	return {
		...DEFAULT_PRODUCT_AND_EXPRESSION,
		expressions: [expression],
	};
}

function fieldValuesResolver(
	expression?: AndExpressionType | null,
): Array<FieldValue> | undefined | null {
	const joinExpression: AndExpressionType | undefined | null =
		expressionResolver(expression);

	return (
		joinExpression &&
		joinExpression.expressions.map(convertExpressionToFieldValue)
	);
}

function clientExpressionResolver({
	clientExpression,
}: {
	clientExpression:
		| AndExpressionType
		| SingleValueExpression
		| undefined
		| null;
}): AndExpressionType {
	if (!clientExpression) {
		// `null` expressions are converted to empty list of expressions
		return DEFAULT_PRODUCT_AND_EXPRESSION;
	}

	if (
		clientExpression.operator &&
		clientExpression.operator === OPERATORS.and
	) {
		// Expression has already been converted to multiple
		return clientExpression;
	}

	// Convert singular expression to multiple
	return {
		...DEFAULT_PRODUCT_AND_EXPRESSION,
		expressions: [clientExpression],
	};
}

function clientFieldValuesResolver({
	clientExpression,
}: {
	clientExpression: GQLRiskProfileItem['clientExpression'];
}): Array<FieldValue> | undefined | null {
	const joinExpression: AndExpressionType | undefined | null =
		clientExpressionResolver({
			clientExpression,
		});

	return (
		joinExpression &&
		joinExpression.expressions.map(convertExpressionToFieldValue)
	);
}

// HACK: Core requires metadata fields to be stored in the `timeBasedExpression`
// We are resolving the actual metadata expressions by filtering over the
// time based and pulling out any with a `Metadata` field id
// Ref: https://access-fintech.atlassian.net/browse/FIN-7880
function metadataExpressionResolver({
	timeBasedExpression,
}: {
	timeBasedExpression: JoinExpression | AnyKindOfExpression | undefined | null;
}): AndExpressionType {
	if (!timeBasedExpression) return DEFAULT_PRODUCT_AND_EXPRESSION;

	const flattenedExpressions = recursiveFlatten(timeBasedExpression);
	const timeBasedMetadataExpressions = flattenedExpressions.filter(
		expression => {
			const fieldId = getFieldIdFromExpression(expression);

			return fieldId && fieldId.startsWith('Metadata');
		},
	);
	return {
		...DEFAULT_PRODUCT_AND_EXPRESSION,
		expressions: timeBasedMetadataExpressions,
	};
}

function metadataFieldValuesResolver({
	timeBasedExpression,
}: {
	timeBasedExpression: GQLRiskProfileItem['timeBasedExpression'];
}): Array<FieldValue> | undefined | null {
	const joinExpression: AndExpressionType | undefined | null =
		metadataExpressionResolver({
			timeBasedExpression,
		});

	return joinExpression?.expressions?.map(convertExpressionToFieldValue);
}
