import { normalize } from 'polished';
import { createGlobalStyle, css } from 'styled-components';

import { Roboto } from 'ds/styles/font-face';
import { fontSmoothing } from 'styles';
import reactModal from 'styles/react-modal';

import { scrollbars } from './scrollbars';
import { BASE_TYPOGRAPHY } from './typography';
export const GlobalStyle = createGlobalStyle`
	${props => css`
		${normalize()};
		${Roboto()};
		${reactModal()};

		*,
		*:before,
		*:after {
			box-sizing: border-box;
		}

		html {
			height: 100%;
			${BASE_TYPOGRAPHY({ theme: props.theme })};
			${fontSmoothing.antialiased};
			touch-action: manipulation;
		}

		body {
			height: 100%;
			${fontSmoothing.antialiased};
			background-color: ${props.theme.color.bg.base};
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			min-width: 1024px;
		}

		#root {
			height: 100%;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			/* margin: 1rem 0;
			font-weight: 300; */
			/*
			* Remove this block once the DS is implemented across the app
			*/
			margin: 0;
			font-weight: normal;
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		input,
		select,
		button,
		textarea {
			font: inherit;
			line-height: inherit;
			-webkit-font-smoothing: inherit;
			&:not:focus {
				outline: none;
			}
		}

		ul,
		ol {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		dl,
		dt,
		dd {
			margin: 0;
			padding: 0;
		}

		svg:not(:root) {
			overflow: visible;
		}

		.isBlur {
			filter: blur(3px);
		}

		${scrollbars(props.theme)};
	`};
`;
