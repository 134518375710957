type Props = {
	className?: string;
};

export const DownloadIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(2 5)"
		d="M13 4a3 3 0 0 1 0 6H3a3 3 0 1 1 0-6c0-2.21 2.79-4 5-4s5 1.79 5 4zM8 8.5l3-3H9V2.996H7V5.5H5l3 3z"
	/>
);
