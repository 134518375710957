// @ts-nocheck
import type { ThemeName } from 'ds/styles/themes';
import type { ReactNode } from 'react';
import type { ModalProps } from 'react-modal';

import ReactModal from 'react-modal';
import styled from 'styled-components';

import { PrimaryButton, SecondaryButton } from 'ds/components/core/Button';
import { Typography } from 'ds/components/Typography';

import { AppBlur } from '../AppBlur';
import { Theme } from '../core/Theme';
import { StyledModal } from './styles';

type Props = {
	theme?: ThemeName;
	background?: number;
	children: ReactNode;
} & ModalProps;

function Modal({ theme, background = 0, ...props }: Props) {
	const { isOpen, children } = props;
	return (
		<Theme name={theme ? theme : undefined} baseLum={background}>
			{isOpen ? <AppBlur /> : null}

			<StyledModal id="react-ds-styled-modal" isOpen={isOpen} {...props}>
				{children}
			</StyledModal>
		</Theme>
	);
}

const ModalTitle = styled(Typography).attrs(props => ({
	size: 'large',
	forwardedAs: 'h1',
	hasDivider: props.hasDivider,
}))`
	padding: ${props => props.theme.spacing.medium}px;
	${props =>
		!props.noDivider &&
		`border-bottom: 1px solid ${props.theme.color.bg.lum.active};`}
`;

const ModalContent = styled(Typography).attrs(() => ({
	forwardedAs: 'section',
}))`
	${props => !props.fullbleed && `padding: ${props.theme.spacing.medium}px;`}
	max-height: ${props => `${props.maxHeight || 300}px`};
	overflow: auto;
`;

const ModalActions = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${props => props.theme.spacing.medium}px;
	${props =>
		!props.noDivider &&
		`border-top: 1px solid ${props.theme.color.bg.lum.active};`}
`;

const BUTTON_MAX_WIDTH = 200;

const ModalActionSecondary = styled(SecondaryButton)<{ onClick: () => void }>`
	width: 100%;
	max-width: ${props => !props.flex && `${BUTTON_MAX_WIDTH}px`};
	margin-right: ${props => props.theme.spacing.medium}px;

	&:only-child {
		max-width: none;
		margin: 0;
	}
`;

const ModalActionPrimary = styled(PrimaryButton).attrs(() => ({
	color: 'brandBlue',
}))<{ onClick?: () => void; disabled?: boolean }>`
	width: 100%;
	max-width: ${props => !props.flex && `${BUTTON_MAX_WIDTH}px`};

	&:only-child {
		max-width: none;
	}
`;

Modal.defaultProps = {
	contentLabel: 'Modal',
	closeTimeoutMS: 200,
	shouldReturnFocusAfterClose: false,
	isOpen: false,
};

export {
	Modal,
	ModalActionPrimary,
	ModalActions,
	ModalActionSecondary,
	ModalContent,
	ModalTitle,
};

ReactModal.setAppElement('#root');
