type Props = {
	className?: string;
};

export const PendingIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(-2 -1)"
		d="M15.638 14.607L15 15.5l-3.7-2.61V7h1.097v5.326l3.24 2.281z"
	/>
);
