type Props = {
	className?: string;
};

export const PairingCenterIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(-2, -2)"
		d="M8.47321137,10.5 L9.22046331,11.2473051 L7.2770911,13.1907603 C6.31591703,14.1756496 6.31591703,15.7475417 7.26801214,16.7233513 C8.21001317,17.6428006 9.68904476,17.6827767 10.6737472,16.8472284 L10.8048639,16.7278636 L12.7527481,14.779841 L13.5,15.527146 L11.5475488,17.4796809 C10.152209,18.8416159 7.92523414,18.8416159 6.52081535,17.4706013 C5.20761424,16.1249993 5.1607142,14.0060491 6.38416078,12.6011128 L6.52532725,12.4480226 L8.47321137,10.5 Z M14.7793826,8.5 L15.5,9.22061743 L9.22061743,15.5 L8.5,14.7793826 L14.7793826,8.5 Z M17.4791847,6.52939874 C18.7923858,7.87500067 18.8392858,9.99395087 17.6158392,11.3988872 L17.4746727,11.5519774 L15.5267886,13.5 L14.7795367,12.7526949 L16.7229089,10.8092397 C17.684083,9.82435043 17.684083,8.25245832 16.7319879,7.27664866 C15.7899868,6.3571994 14.3109552,6.31722334 13.3262528,7.1527716 L13.1951361,7.27213644 L11.2472519,9.22015904 L10.5,8.47285396 L12.4524512,6.52031913 C13.847791,5.15838412 16.0747659,5.15838412 17.4791847,6.52939874 Z"
		id="Path-3"
	/>
);
