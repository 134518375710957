type Props = {
	className?: string;
};

export const MeetIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6.5, 7)"
		d="M-.18 0h5.423a.32.32 0 0 1 .32.32v5.36a.32.32 0 0 1-.32.32H-.18a.32.32 0 0 1-.32-.32V.32A.32.32 0 0 1-.18 0zm8.892.664a.14.14 0 0 1 .063.118v4.436a.14.14 0 0 1-.063.118.127.127 0 0 1-.128.006L6.503 4.195v-2.39L8.584.658c.041-.022.09-.02.128.006z"
	/>
);
