/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type { ModalProps } from 'react-modal';

import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { scrollbars } from 'ds/styles/scrollbars';
import { getDatePickerStyles } from '../DatePicker';

export type ReactModalAdapterProps = {
	className: string;
	contentClassName: string;
} & ModalProps;

const ReactModalAdapter = (props: ReactModalAdapterProps) => {
	const { className, contentClassName, overlayClassName } = props;
	return (
		<Modal
			{...props}
			className={contentClassName}
			portalClassName={className}
			overlayClassName={overlayClassName}
		/>
	);
};

export const StyledModal = styled(ReactModalAdapter)`
	.Overlay {
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${props =>
			props.theme.color.palette.brandBlack.alpha.low};
	}

	.Content {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 336px;
		max-height: 90vh;
		overflow: auto;
		outline: none;
		border: none;
		border-radius: 0;
		box-shadow: ${({ theme }) =>
			`1px 1px ${theme.color.palette.black.alpha.custom(0.3)}`};
		background-color: ${({ theme }) => theme.color.bg.base};
		color: ${({ theme }) => theme.color.fg.base};
		* {
			${props => scrollbars(props.theme)};
		}
	}

	${getDatePickerStyles};
`;

StyledModal.defaultProps = {
	overlayClassName: 'Overlay',
	contentClassName: 'Content',
};
