// @ts-nocheck

import React from 'react';

type Props = {};

const appBlurInstances = [];

export class AppBlur extends React.Component<Props> {
	componentDidMount() {
		appBlurInstances.push(new Date());

		const rootDocument = document.getElementById('root');
		rootDocument && rootDocument.classList.add('isBlur');
	}

	componentWillUnmount() {
		appBlurInstances.pop();

		if (!appBlurInstances.length) {
			const rootDocument = document.getElementById('root');
			rootDocument && rootDocument.classList.remove('isBlur');
		}
	}

	render() {
		return null;
	}
}
