// @ts-nocheck

import type { ReactNode } from 'react';

import React from 'react';
import styled from 'styled-components';

import { Dot } from 'ds/components/Dot';
import { Typography } from 'ds/components/Typography';

type Props = {
	children: ReactNode;
	count?: number;
	color?: string;
	className?: string;
	badgePosition?: {
		top?: number;
		right?: number;
	};
	'data-test'?: string;
};

const Badge = ({
	children,
	count = 0,
	color,
	badgePosition,
	className,
	...props
}: Props) => (
	<Container className={className} {...props}>
		{children}
		{count > 0 && (
			<Typography
				as={BadgeDot}
				color={color}
				size="tiny"
				badgePosition={badgePosition}
			>
				{count}
			</Typography>
		)}
	</Container>
);

const Container = styled.span`
	display: inline-block;
	position: relative;
`;

/* 1. Must be white color regardless of theme */
const BadgeDot = styled(Dot).attrs(() => ({
	size: 16,
}))`
	color: ${props => props.theme.color.palette.white.base} !important; /* 1 */
	line-height: 1;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 ${props => props.theme.spacing.tiny}px;
	position: absolute;
	top: ${({ badgePosition, theme }) =>
		badgePosition ? badgePosition.top : -theme.spacing.small}px;
	right: ${({ badgePosition, theme }) =>
		badgePosition ? badgePosition.right : -theme.spacing.small}px;
`;

export { Badge };
