// @ts-nocheck

import React from 'react';
import * as PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { AppBlur } from 'ds/components/AppBlur';
import { useTheme } from 'hooks/useTheme';
import { size } from 'styles';

import {
	AffirmativeButton,
	Content,
	DismissButton,
	Footer,
	Header,
} from './styles';

export default function Modal({
	style: { overlay, content } = {},
	children,
	...props
}) {
	const theme = useTheme();

	const styles = {
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			zIndex: 100,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			...overlay,
		},

		content: {
			position: 'relative',
			top: 'auto',
			right: 'auto',
			bottom: 'auto',
			left: 'auto',
			width: size.panelWidth,
			display: 'flex',
			flexDirection: 'column',
			padding: '20px 16px',
			backgroundColor: theme.color.bg.lum.active,
			borderRadius: 0,
			border: 'none',
			color: theme.color.fg.base,
			...content,
		},
	};

	return (
		<React.Fragment>
			{props.isOpen && <AppBlur />}

			<ReactModal
				style={styles}
				closeTimeoutMS={200}
				shouldReturnFocusAfterClose={false}
				{...props}
			>
				{children}
			</ReactModal>
		</React.Fragment>
	);
}

Modal.propTypes = {
	contentLabel: PropTypes.string,
};

Modal.defaultProps = {
	contentLabel: '',
};

export { AffirmativeButton, Content, DismissButton, Footer, Header };
