type Props = {
	className?: string;
};

export const RootIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6 7)"
		d="M0 0h8L4 8 0 0zm1.5 1L4 6l2.5-5h-5z"
	/>
);
