type Props = {
	className?: string;
};

export const TickIcon = ({ className }: Props) => (
	<path
		className={className}
		transform="translate(6, 6)"
		d="M7.108.69l.784.62L2.79 7.743.153 5.207l.694-.721L2.69 6.258z"
	/>
);
