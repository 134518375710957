import type { Props as NavBarProps } from 'ds/components/Global/NavBar';

import { useEffect, useState } from 'react';

import { BreadcrumbPortal } from 'ds/components/core/Breadcrumb';
import { NavBar } from 'ds/components/Global/NavBar';
import { NavButtonsPortal } from 'ds/components/Global/NavBar/NavButtons';

import { useNotificationCountContext } from '../../contexts/NotificationCountContext';

type Props = {
	onNewNotifications: (notificationsCount: number) => void;
} & NavBarProps;

function AppNavBar({ onNewNotifications, ...props }: Props) {
	const [oldCount, setOldCount] = useState<number | undefined>(undefined);
	const { notificationsCount } = useNotificationCountContext();

	useEffect(() => {
		if (notificationsCount !== null) {
			if (!oldCount) {
				onNewNotifications(notificationsCount);
			}
			setOldCount(notificationsCount);
		}
	}, [onNewNotifications, notificationsCount, oldCount]);

	return (
		<NavBar badgeCount={oldCount} {...props}>
			<NavButtonsPortal />
			<BreadcrumbPortal />
		</NavBar>
	);
}

export { AppNavBar };
