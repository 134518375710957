// @ts-nocheck

import type { GQLRiskProfileItem } from '@af/api';

import { getApiUrl, warn } from '@af/utils';
import { csrfToken, getCorrelationId } from '@af/utils';
import {
	defaultDataIdFromObject,
	InMemoryCache,
	IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RestLink } from 'apollo-link-rest';

import { getAppVersion } from 'utils/environment';
import { reportError } from 'utils/errors';
import { ClientFieldResolver } from 'utils/graphql/ClientFieldResolver';
import { ClientRuleResolver } from 'utils/graphql/ClientRuleResolver';
import { ContentTypeResolver } from 'utils/graphql/ContentTypeResolver';
import { EnrichedJourneyResolver } from 'utils/graphql/EnrichedJourneyResolver';
import { introspectionQueryResultData } from 'utils/graphql/fragmentTypes';
import { NotificationResolver } from 'utils/graphql/NotificationResolver';
import { ProductLineFieldResolver } from 'utils/graphql/ProductLineFieldResolver';
import { RiskProfileItemResolver } from 'utils/graphql/RiskProfileItemResolver';
import { navigateToLogout } from 'utils/urls';

const apiUrl = getApiUrl();

// See https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
});

// Type for every GraphQL entity
type PossibleCacheObjectType = {
	// These can be on various GQL entities
	id?: string | number;
	key?: string;
	serializedCompositeKey?: string;
	orgId?: string | number;
	organizationId?: string | number;
	// This is on risk entity
	clientOrganizationId?: string | number;
	riskProfileItemId?: GQLRiskProfileItem['riskProfileItemId'];
	// This is on ContentRecord event
	eventTime?: string;
	eventType?: string;
	// This is on ExportStatus
	docId?: string;
	// This is on LinkedGroup
	recordId?: string;
	// This is on AggregationParticipant
	aggregatedRecordId?: string;
	// Apollo entity type name
	__typename: string;
};

const cache = new InMemoryCache({
	fragmentMatcher,
	dataIdFromObject: (object: PossibleCacheObjectType) => {
		// Take possible id from any kind of object
		const possibleId =
			object.id ||
			object.serializedCompositeKey ||
			object.riskProfileItemId ||
			object.key ||
			object.docId ||
			object.recordId ||
			object.aggregatedRecordId ||
			// Added as part of FIN-5290 - We have to add a hacky unique id for content record events to support optimistic update on saving/retrieving events
			(object.eventType && object.eventTime
				? `${object.eventType}_${object.eventTime}`
				: null);
		// Take possible orgId from any kind of object
		const possibleOrgId =
			object.orgId || object.organizationId || object.clientOrganizationId;

		if (!possibleId) {
			// fall back to default handling if object doesn't have a main identifier
			return defaultDataIdFromObject(object);
		}

		// Combine all unique parameters of the object
		const cacheKeyItems = [object.__typename, possibleOrgId, possibleId];
		// Create a string key out of existing parameters joined by underscore character
		const cacheKey = cacheKeyItems.filter(Boolean).join('_');

		return cacheKey;
	},
});

// Adds a random id that marks the entire chain of servers and api calls within BE
const correlationMiddleWare = new ApolloLink((operation, forward) => {
	const { correlationId } = getCorrelationId();

	operation.setContext(({ headers }) => ({
		headers: {
			...headers,
			'x-correlation-id': correlationId,
		},
	}));

	return forward(operation);
});

const authMiddleWare = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers }) => ({
		contentType: 'application/json',
		headers: {
			...headers,
			'x-csrf-token': csrfToken.get(),
		},
	}));

	return forward(operation);
});

const customFetch = async function () {
	// Use standard fetch as usual
	const response = await fetch(...arguments);

	const contentTypeHeader = response.headers.get('Content-Type');
	const isJSON =
		!!contentTypeHeader && contentTypeHeader.indexOf('application/json') !== -1;
	const isContentLengthPresent = response.headers.has('Content-Length');
	const isSuccessful = response.status.toString().indexOf('2') === 0;

	// Sometimes our API sends "nothing" for a successful response
	if (isSuccessful && !isJSON && !isContentLengthPresent) {
		// If we get a successful "nothing"
		// convert it to a json empty object
		// So apollo can understand
		// https://github.com/apollographql/apollo-link-rest/issues/107
		return new Response('{}', {
			status: response.status,
			statusText: response.statusText,
		});
	}

	return response;
};

// Strip __typename from variables from every mutation
// https://github.com/apollographql/apollo-client/issues/1913#issuecomment-425281027
const stripTypenameLink = new ApolloLink((operation, forward) => {
	if (operation.variables) {
		const omitTypename = (key, value) =>
			key === '__typename' ? undefined : value;

		operation.variables = JSON.parse(
			JSON.stringify(operation.variables),
			omitTypename,
		);
	}
	return forward(operation);
});

const restLink = new RestLink({
	uri: `${apiUrl}`,
	// Overwrite standard browser fetch
	customFetch: !!window.fetch && customFetch,
	// HACK: FIN-6169
	fieldNameNormalizer: (key: string) =>
		key === 'exported content file' ? 'exportedContentFile' : key,
	fieldNameDenormalizer: (key: string) =>
		key === 'exportedContentFile' ? 'exported content file' : key,
});

const graphqlLink = new HttpLink({
	uri: `${apiUrl}/gql`,
	// Add `?operationName=Example` to every GraphQL request
	// This is useful for debugging, but also needed for stubbing GraphQL
	// requests in our E2E tests.
	fetch: (uri, options) => {
		const { operationName } = JSON.parse(options.body);
		return fetch(`${uri}?operation=${operationName}`, options);
	},
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) => {
			reportError({
				error: new Error(message),
				errorInfo: { locations, path },
			});

			warn(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			);
		});

	if (networkError) {
		// Report all network errors even though they will be a bit noisy
		reportError({
			error: new Error(networkError),
		});

		warn(`[Network error]: ${networkError}`);

		// Log the user out when their auth fails
		if (networkError.statusCode === 401) {
			navigateToLogout();

			return;
		}
	}
});

let apolloClient;

export async function getApolloClient() {
	if (apolloClient) {
		return Promise.resolve(apolloClient);
	}

	return new Promise(async resolve => {
		let mockSchemaLink;

		// $FlowFixMe
		apolloClient = new ApolloClient({
			cache,
			link: ApolloLink.from([
				authMiddleWare,
				correlationMiddleWare,
				stripTypenameLink,
				errorLink,
				restLink,
				mockSchemaLink || graphqlLink,
			]),
			// https://www.apollographql.com/docs/platform/client-awareness.html#Setup
			name: 'AFTWebApp',
			version: getAppVersion(),
			resolvers: {
				...NotificationResolver,
				...ContentTypeResolver,
				...ClientFieldResolver,
				...EnrichedJourneyResolver,
				...ProductLineFieldResolver,
				...ClientRuleResolver,
				...RiskProfileItemResolver,
			},
		});

		resolve(apolloClient);
	});
}

export async function resetStore() {
	const client = await getApolloClient();

	if (client.queryManager) {
		client.stop(); // terminate active client processes so we don't get "Store reset while query was in flight" error
		await client.queryManager.clearStore();
	}
}
