// @ts-nocheck

import styled, { css } from 'styled-components';

// These variants should match the ones in components/FullStatus
const variants = {
	small: {
		titleFontSize: 24,
		messageFontSize: 16,
	},
	medium: {
		titleFontSize: 32,
		messageFontSize: 18,
	},
	large: {
		titleFontSize: 48,
		messageFontSize: 24,
	},
};

const applyCommonStyles = css`
	text-align: center;
	max-width: 500px;
`;

export const Title = styled.h1`
	${applyCommonStyles};

	${props => {
		const config = variants[props.variant];

		return css`
			font-size: ${config.titleFontSize}px;
		`;
	}};
`;

export const Message = styled.p`
	${applyCommonStyles};

	${props => {
		const config = variants[props.variant];

		return css`
			font-size: ${config.messageFontSize}px;
		`;
	}};
`;
