// @ts-nocheck

import React, { useState } from 'react';
import { SettingsIcon } from '@af/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ModalAlert from 'components/ModalAlert';
import { useNotificationCountContext } from 'contexts/NotificationCountContext';
import {
	Tabs,
	TabsBar,
	TabsButton,
	TabsContent,
} from 'ds/components/core/Tabs';

import { Downloads } from './components/Downloads/Downloads';
import { NotificationPanel } from './components/NotificationPanel';
import { NotificationSettings } from './components/NotificationSettings';
import { UserCard } from './components/UserCard';
import { WatchlistPanel } from './components/WatchlistPanel';
import {
	NotificationSettingsButton,
	Panel,
	StyledAppVersion,
	Wrapper,
} from './styles';

type Props = {
	watchlistCount: number | undefined | null;
	onLogout: () => void;
	onClose: () => void;
};

export function UserPanel({ watchlistCount = 0, onLogout, onClose }: Props) {
	const flags = useFlags();
	const isDownloadEnabled = flags['downloads'];
	const [error, setError] = useState<string | undefined | null>();
	const [isNotificationSettingsVisible, setIsNotificationSettingsVisible] =
		useState<boolean>(false);
	const { notificationsCount } = useNotificationCountContext();

	return (
		<Panel>
			{Boolean(error) && (
				<ModalAlert
					isOpen={true}
					message={error}
					onConfirm={event => {
						event.preventDefault();
						event.stopPropagation();
						setError(null);
					}}
				/>
			)}
			<Wrapper
				isNotificationSettingsVisible={isNotificationSettingsVisible}
				data-test="user-panel"
			>
				<StyledAppVersion />
				<NotificationSettingsButton
					onClick={() =>
						setIsNotificationSettingsVisible(prevState => !prevState)
					}
				>
					<SettingsIcon />
				</NotificationSettingsButton>
				{isNotificationSettingsVisible ? (
					<NotificationSettings
						onCancel={() =>
							setIsNotificationSettingsVisible(prevState => !prevState)
						}
					/>
				) : (
					<>
						<UserCard
							onSubtitleClick={onClose}
							onLogout={onLogout}
							onError={setError}
						/>

						<Tabs>
							<TabsBar>
								<TabsButton>
									Notifications ({notificationsCount || 0})
								</TabsButton>
								<TabsButton>Watchlist ({watchlistCount})</TabsButton>
							</TabsBar>

							<TabsContent>
								<NotificationPanel onError={setError} onClose={onClose} />
								{isDownloadEnabled ? <Downloads /> : null}
							</TabsContent>

							<TabsContent>
								<WatchlistPanel />
							</TabsContent>
						</Tabs>
					</>
				)}
			</Wrapper>
		</Panel>
	);
}
