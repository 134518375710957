import { useIdleTimer } from 'react-idle-timer';

import { useOrganizationIdleTime } from './useOrganizationIdleTime';

type Props = {
	onIdle: () => void;
};

export function OrganizationIdleTimer(props: Props) {
	const { idleMinutes } = useOrganizationIdleTime();

	useIdleTimer({
		timeout: 1000 * 60 * idleMinutes,
		onIdle: props.onIdle,
	});

	return null;
}
