type Props = {
	className?: string;
};

export const ClearContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M10.707 10l4.147 4.146-.708.708L10 10.707l-4.146 4.147-.708-.708L9.293 10 5.146 5.854l.708-.708L10 9.293l4.146-4.147.708.708L10.707 10z"
	/>
);
