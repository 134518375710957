// @ts-nocheck

import type {
	GQLCappedNotificationSubscriptions,
	GQLContentRecord,
	GQLNotificationSubscription,
	GQLUser,
	GQLUserPaginatedNotificationSubscriptionsArgs,
} from '@af/api';

import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useCurrentUser } from 'hooks/useCurrentUser';

const GET_PAGINATED_NOTIFICATION_SUBSCRIPTIONS = gql`
	query GetPaginatedNotificationSubscriptions(
		$contextOrgId: ID!
		$offset: Int!
		$limit: Int!
	) {
		userMe {
			id
			paginatedNotificationSubscriptions(
				contextOrgId: $contextOrgId
				limit: $limit
				offset: $offset
			) {
				notificationSubscriptions {
					id
					creationTime
					entity {
						... on ContentRecord {
							id
							serializedCompositeKey
							titleV2
							__typename
						}
					}
				}
				totalItemsCount
			}
		}
	}
`;

type HookParams = {
	limit?: GQLUserPaginatedNotificationSubscriptionsArgs['limit'];
	offset?: GQLUserPaginatedNotificationSubscriptionsArgs['offset'];
	pollInterval?: number;
} | null;

type QueryVariables = {
	contextOrgId: GQLUserPaginatedNotificationSubscriptionsArgs['contextOrgId'];
} & HookParams;

export type NotificationSubscriptionType = {
	id: GQLNotificationSubscription['id'];
	creationTime: GQLNotificationSubscription['creationTime'];
	entity: {
		id: GQLContentRecord['id'];
		serializedCompositeKey: GQLContentRecord['serializedCompositeKey'];
		titleV2: GQLContentRecord['titleV2'];
		__typename: 'ContentRecord';
	} | null;
};

export type QueryResponse = {
	userMe: {
		id: GQLUser['id'];
		paginatedNotificationSubscriptions: {
			notificationSubscriptions: Array<NotificationSubscriptionType>;
			totalItemsCount: GQLCappedNotificationSubscriptions['totalItemsCount'];
		};
	};
};

export type FetchMoreProps = {
	offset?: GQLUserPaginatedNotificationSubscriptionsArgs['offset'];
};

function useGetWatchlistQuery(props: HookParams) {
	const { orgId } = useCurrentUser();

	const { limit = 100, offset = 0, pollInterval = 0 } = props || {};

	const queryResponse = useQuery<QueryResponse, QueryVariables>(
		GET_PAGINATED_NOTIFICATION_SUBSCRIPTIONS,
		{
			variables: { contextOrgId: orgId, limit, offset },
			pollInterval,
		},
	);

	const subscriptions = useMemo(() => {
		const notificationSubscriptions =
			queryResponse?.data?.userMe?.paginatedNotificationSubscriptions
				?.notificationSubscriptions || [];

		const contentsSubscriptions: Array<NotificationSubscriptionType> =
			notificationSubscriptions
				.filter(subscription => !!subscription.entity)
				// Based on requirement we need to display contents subscriptions. Avi said that there are no `Risk` subscriptions and only Content subscriptions so this filter shouldn't be added, but just in case, I am letting it in
				.filter(subscription =>
					Boolean(subscription.entity && subscription.entity.id),
				);

		return contentsSubscriptions;
	}, [queryResponse]);

	const count =
		queryResponse?.data?.userMe?.paginatedNotificationSubscriptions
			?.totalItemsCount || 0;

	return {
		...queryResponse,
		subscriptions,
		count,
	};
}

export { useGetWatchlistQuery };
