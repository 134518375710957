import { ellipsis } from 'polished';
import styled from 'styled-components';
import { css } from 'styled-components';

import { IconButton } from 'ds/components/core/Button';

export const Wrapper = styled.article`
	display: flex;
	flex-direction: column;
	height: auto;
	margin: ${props => props.theme.spacing.tiny}px;
	margin-bottom: 0;
	padding: 0 ${props => props.theme.spacing.tiny}px
	outline: none;
`;

export const ListContent = styled.ul`
	padding: 0 ${props => props.theme.spacing.tiny}px;
	position: relative;
	align-items: center;
	align-content: center;
`;

export const ListRow = styled.li`
	height: auto;
	margin-top: ${props => props.theme.spacing.tiny}px;

	${({ theme: { color, spacing } }) => {
		return css`
			background-color: ${color.bg.lum.custom(6)};
			padding: ${spacing.small}px;

			&:hover {
				background-color: ${color.bg.lum.rollover};
			}
		`;
	}};
`;

export const BadgeAndTitleWrapper = styled.div`
	display: flex;
	margin: ${props => props.theme.spacing.tiny}px;
`;

export const LeftIconButton = styled(IconButton)`
	background-color: ${props => props.theme.color.bg.lum.active};

	&:hover {
		color: ${props => props.theme.color.palette.white.typeAlpha.high};
		background-color: ${props => props.theme.color.bg.lum.default};
	}
`;

export const FileNameWrapper = styled.div`
	padding: ${props => props.theme.spacing.small}px;
	overflow: hidden;
	white-space: nowrap;
	${ellipsis('200px')};
`;

export const ProgressBarWrapper = styled.div`
	margin: ${props => props.theme.spacing.tiny}px;
	padding: ${props => props.theme.spacing.tiny}px;
`;

export const RightSideButton = styled.a`
	z-index: 1;
	height: 40px;
	border-radius: 50%;
	line-height: 0;
	padding: ${props => props.theme.spacing.tiny}px
		${props => props.theme.spacing.small}px;
	align-items: center;

	opacity: 0.8;
	background-color: ${props => props.theme.color.bg.lum.active};
	transition: opacity 0.3s ease-out;
	&:hover {
		color: ${props => props.theme.color.palette.white.typeAlpha.high};
		background-color: ${props => props.theme.color.bg.lum.default};
	}
`;
