/* eslint-disable */
// TODO: Typescript migration

// @ts-nocheck

import type { GQLOrganization, GQLUser } from '@af/api';

import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import FileUpload from 'components/FileUpload';
import { SecondaryButtonLink } from 'ds/components/core/Button';
import { ICONS } from 'ds/components/Icon';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { onUserAvatarChange } from 'store/actions';
import { apiCall } from 'utils/api';
import { uploadAvatarEndpoint } from 'utils/api/hackEndpoints/users';

import {
	AddIcon,
	AvatarWrapper,
	Header,
	JobTitle,
	LogoutWrapper,
	Name,
	StyledAvatar,
	StyledLoading,
} from './UserCard.styles';

const GET_CURRENT_USER = gql`
	query GetUserPanelCurrentUser {
		userMe {
			id
			imageUrl
			name
			jobTitle
			organizations {
				id
				name
			}
		}
	}
`;

type CurrentUserResponse = {
	userMe: {
		id: GQLUser['id'];
		imageUrl: GQLUser['imageUrl'];
		name: GQLUser['name'];
		jobTitle: GQLUser['jobTitle'];
		organizations: Array<{
			id: GQLOrganization['id'];
			name: GQLOrganization['name'];
		}>;
	};
};

const IMAGE_SIZE = 56;
const SUBTITLE_PATH = '/login';

export type Props = {
	onSubtitleClick?: () => void;
	onLogout: () => void;
	onError: (a: string) => void;
};

const UserCard = ({
	onSubtitleClick = () => {},
	onLogout = () => {},
	onError = () => {},
}: Props) => {
	const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
	const { data, refetch } = useQuery<CurrentUserResponse, {}>(GET_CURRENT_USER);
	const { orgId } = useCurrentUser();
	const user = data?.userMe || {};
	const dispatch = useDispatch();
	const userOrganization = user.organizations?.find(
		organization => String(organization.id) === String(orgId),
	);
	const organizationName = userOrganization?.name;
	const jobTitleAndOrganization = [user.jobTitle, organizationName]
		.filter(Boolean)
		.join(' ');

	function handleAvatarChange(response) {
		dispatch(onUserAvatarChange(response));
	}

	const handleLoadingError = () => {
		onError('There was a problem uploading avatar image');
	};
	// TODO: Update to use GraphQL upload
	async function handleImageChange(file) {
		const formData = new FormData();

		formData.append('image', file);
		setIsImageUploading(true);

		try {
			const endpoint = uploadAvatarEndpoint(user.id, formData);
			const response = await apiCall(endpoint);

			if (response.error) {
				handleLoadingError();
			} else {
				handleAvatarChange(response);
				refetch();
			}
		} catch (error) {
			handleLoadingError();
		}

		setIsImageUploading(false);
	}

	return (
		<Header>
			<AvatarWrapper>
				<StyledAvatar src={user.imageUrl} size={IMAGE_SIZE} />

				{!isImageUploading && (
					<AddIcon config={ICONS.addContextual} size="large" />
				)}

				<FileUpload onChange={handleImageChange} title="Upload an avatar" />

				{isImageUploading && <StyledLoading />}
			</AvatarWrapper>

			<Name>{user.name}</Name>

			{(user.jobTitle || organizationName) && (
				<JobTitle>
					<NavLink to={SUBTITLE_PATH} onClick={onSubtitleClick}>
						{jobTitleAndOrganization}
					</NavLink>
				</JobTitle>
			)}
			<LogoutWrapper>
				<SecondaryButtonLink small to="/logout" onClick={onLogout}>
					Log out
				</SecondaryButtonLink>
			</LogoutWrapper>
		</Header>
	);
};

export { UserCard };
