// @ts-nocheck

import { css } from 'styled-components';

export const chevronDown = (width, right) => css`
	position: relative;

	&::after {
		content: '';

		width: 0;
		height: 0;
		border-left: ${width}px solid transparent;
		border-right: ${width}px solid transparent;
		border-top: ${width}px solid currentColor;

		display: inline-block;
		margin-left: 4px;
		position: absolute;
		top: 50%;
		right: ${right};
		transform: translateY(-50%);
	}
`;

export const isDarkVariant = props => props.variant === 'dark';

export const resetButton = css`
	padding: 0;
	background-color: transparent;
	border: none;

	&:disabled {
		cursor: not-allowed;
	}
`;

export const absoluteCenter = css`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const visuallyHidden = css`
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	height: 1px;
	width: 1px;
`;
