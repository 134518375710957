import styled from 'styled-components';

import {
	Content,
	Footer as ParentFooter,
	Header as ParentHeader,
} from 'components/Modal';

export const Header = styled(ParentHeader)`
	border: none;
`;

export const Message = styled(Content)`
	text-align: center;
	padding: 0 20px;
`;
Message.displayName = 'Message';

export const Footer = styled(ParentFooter)`
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
Footer.displayName = 'Footer';
