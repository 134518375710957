// @ts-nocheck

import type { ChildrenArray, ReactElement, ReactNode } from 'react';

import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import styled, { css } from 'styled-components';

import { Background } from 'ds/components/Background';
import { Theme } from 'ds/components/core/Theme';
import { Typography } from 'ds/components/Typography';
import { scrollbars } from 'ds/styles/scrollbars';
import { useTheme } from 'hooks/useTheme';

import { NakedButton } from './Button';

const Context = createContext({});

type TabsProps = {
	baseLum?: number;
	className?: string;
	children: ReactNode;
	defaultActiveIndex?: number;
	onChange?: (a: { activeIndex: number }) => void;
};

function Tabs({
	baseLum = 2,
	className,
	children,
	defaultActiveIndex,
	onChange = () => {},
	...props
}: TabsProps) {
	const state = useState({
		activeIndex: defaultActiveIndex || 0,
		buttonNodes: [],
		contentNodes: [],
	});
	const theme = useTheme();

	const activeIndex = state[0].activeIndex;

	useEffect(() => {
		onChange({ activeIndex });
	}, [onChange, activeIndex]);

	return (
		<Theme baseLum={theme.color.baseLum + baseLum}>
			<TabsWrapper {...props} className={className}>
				<Context.Provider value={state}>{children}</Context.Provider>
			</TabsWrapper>
		</Theme>
	);
}

const TabsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
`;

type TabsBarProps = {
	// eslint-disable-next-line no-use-before-define
	children: ChildrenArray<ReactElement<typeof TabsButton> | boolean>;
	className?: string;
};

function TabsBar({ children, className }: TabsBarProps) {
	const theme = useTheme();
	return (
		<TabsBarWrapper className={className}>
			<Theme baseLum={theme.color.baseLum - 4}>{children}</Theme>
		</TabsBarWrapper>
	);
}

const TabsBarWrapper = styled.div`
	display: flex;
	flex-shrink: 0;
	background-color: ${props => props.theme.color.bg.base};
`;

type TabsButtonType = {
	className?: string;
	'data-test'?: string;
	small?: boolean;
	children: ReactNode;
	disabled?: boolean;
	asLink?: boolean;
};

function TabsButton({
	className,
	disabled,
	children,
	asLink = false,
	small = false,
	...props
}: TabsButtonType) {
	const [context, setContext] = useContext(Context);
	const ref = useRef();
	const index = context.buttonNodes.indexOf(ref.current);
	const isActive = context.activeIndex === index;

	useEffect(() => {
		setContext(prevContext => ({
			...prevContext,
			buttonNodes: [...prevContext.buttonNodes, ref.current],
		}));
	}, [setContext]);

	const TabMenuItem = asLink ? TabLink : TabButton;

	return (
		<TabMenuItem
			type="button"
			ref={ref}
			isActive={isActive}
			className={className}
			disabled={disabled}
			small={small}
			data-is-active={isActive ? 'active-tab-bar-item' : undefined}
			onClick={() =>
				setContext(prevContext => ({ ...prevContext, activeIndex: index }))
			}
			{...props}
		>
			{children}
		</TabMenuItem>
	);
}

export const TAB_BUTTON_HEIGHT = 50;
const TAB_BUTTON_SMALL_HEIGHT = 30;

const RawButton = styled.button`
	flex: 1 1 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: ${props =>
		props.small ? TAB_BUTTON_SMALL_HEIGHT : TAB_BUTTON_HEIGHT}px;
	background-color: ${props => props.theme.color.bg.lum.default};
	padding: 0 ${props => props.theme.spacing.small}px;
	box-shadow: inset 1px -1px 0 ${props => props.theme.color.palette.black.alpha.low};
	transition: 166ms ease-out;
	transition-property: background-color, color;
	border: none;
	max-width: 200px;

	&:hover,
	&:focus {
		background-color: ${props =>
			!props.isActive && !props.disabled && props.theme.color.bg.lum.rollover};

		${props => !props.disabled && props.theme.typography.size.state.rollover};
	}
	$:not:focus {
		outline: none;
	}

	${props =>
		props.isActive &&
		!props.disabled &&
		css`
			${props => props.theme.typography.size.state.selected};

			background-color: ${props => props.theme.color.bg.lum.active};
			box-shadow: inset 0 2px 0 ${props => props.theme.color.primary.base};
		`};

	${props => props.disabled && '&,'} &:disabled {
		cursor: not-allowed;
	}
`;

const TabButton = styled(Typography).attrs(() => ({
	size: 'normal',
	forwardedAs: RawButton,
}))``;

const TabLink = styled(Typography).attrs(() => ({
	size: 'normal',
	forwardedAs: NakedButton,
}))`
	padding: ${props => props.theme.spacing.medium}px;
	margin: 0 auto;
`;

type TabsContentType = {
	className?: string;
	children: ReactNode;
};

const TabsContent = ({ className, children }: TabsContentType) => {
	const ref = useRef();
	const [context, setContext] = useContext(Context);
	const index = context.contentNodes.indexOf(ref.current);
	const isActive = context.activeIndex === index;

	useEffect(() => {
		setContext(prevContext => ({
			...prevContext,
			contentNodes: [...prevContext.contentNodes, ref.current],
		}));
	}, [setContext, ref]);

	return (
		<TabContent ref={ref} className={className} isHidden={!isActive}>
			{isActive && children}
		</TabContent>
	);
};

const RawTabContent = styled(Background).attrs(() => ({ step: 2 }))``;

const TabContent = styled(RawTabContent)`
	display: ${props => (props.isHidden ? 'none' : 'flex')};
	flex-direction: column;
	flex: 1 1 auto;
	overflow-y: auto;
	${props => scrollbars(props.theme)};
	position: relative;
`;

// eslint-disable-next-line import/no-unused-modules
export { TabLink, Tabs, TabsBar, TabsButton, TabsContent };
