type Props = {
	className?: string;
};

export const PaymentAllocationIcon = ({ className }: Props) => (
	<path
		className={className}
		fillRule="evenodd"
		clipRule="evenodd"
		d="M5 5h3v1h4V5h3v3h-1v4h1v3h-3v-1H8v1H5v-3h1V8H5V5zm8 3v4h-1v1H8v-1H7V8h1V7h4v1h1zM6 6h1v1H6V6zm7 0h1v1h-1V6zm-6 7H6v1h1v-1zm6 0h1v1h-1v-1z"
	/>
);
