export const LogoIcon = () => (
	<g>
		<defs>
			<linearGradient id="b" x1="-.039%" x2="99.961%" y1="50.016%" y2="50.016%">
				<stop offset="0%" stopColor="#1F98D5" />
				<stop offset="100%" stopColor="#55BE9D" />
			</linearGradient>
			<circle id="a" cx="10" cy="10" r="10" />
			<linearGradient
				id="f"
				x1="58.841%"
				x2="45.172%"
				y1="51.778%"
				y2="86.018%"
			>
				<stop offset="0%" stopColor="#FFF" />
				<stop offset="100%" stopColor="#D5EEF3" />
			</linearGradient>
			<path
				id="e"
				d="M14.338 2.704c-.479-.281-1.55-1.07-7.38 4.986-1.55 1.606-3.775 4.17-6.028 6.873.338.76 2.084 5.634 8.845 5.634 3.324 0 6.563-1.155 8.169-4-3.324 3.268-5.831-.31-5.127-4.563.338-2.028.901-3.606 1.324-5.183.507-1.972.93-3.324.197-3.747z"
			/>
			<filter
				id="d"
				width="182.3%"
				height="179.2%"
				x="-41.1%"
				y="-28.3%"
				filterUnits="objectBoundingBox"
			>
				<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feGaussianBlur
					in="shadowOffsetOuter1"
					result="shadowBlurOuter1"
					stdDeviation="2"
				/>
				<feColorMatrix
					in="shadowBlurOuter1"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
				/>
			</filter>
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="c" fill="#fff">
				<use xlinkHref="#a" />
			</mask>
			<use fill="url(#b)" xlinkHref="#a" />
			<g mask="url(#c)">
				<use fill="#000" filter="url(#d)" xlinkHref="#e" />
				<use fill="url(#f)" xlinkHref="#e" />
			</g>
		</g>
	</g>
);
