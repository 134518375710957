import type { ExtractReturn } from '@af/types';

import { useEffect } from 'react';

// Think very carefully before using this hook. Usually there is a better
// solution to the problem.
// https://github.com/facebook/create-react-app/issues/6880
export function useMount(effect: () => ExtractReturn<typeof useEffect>) {
	useEffect(effect, []);
}
