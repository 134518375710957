// @ts-nocheck

import type {
	GQLMutationDeleteNotificationSubscriptionsArgs,
	GQLNotificationSubscription,
	GQLUser,
} from '@af/api';
import type { User } from 'types/User';

import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useCurrentUser } from 'hooks/useCurrentUser';

const DELETE_NOTIFICATION_SUBSCRIPTION = gql`
	mutation DeleteNotificationSubscription(
		$contextOrgId: ID!
		$subscriptionId: ID!
	) {
		deleteNotificationSubscriptions(
			contextOrgId: $contextOrgId
			notificationSubscriptionIds: [$subscriptionId]
		) {
			__typename
			id
			identity {
				... on User {
					id
					name
				}
			}
		}
	}
`;

type MutationResponse = {
	deleteNotificationSubscriptions: Array<{
		id: GQLNotificationSubscription['id'];
		identity: {
			id: GQLUser['id'];
			name: User['name'];
		};
	}>;
};

type MutationProps = {
	subscriptionId: GQLNotificationSubscription['id'];
};

type MutationVariables = {
	contextOrgId: GQLMutationDeleteNotificationSubscriptionsArgs['contextOrgId'];
} & MutationProps;

export function useDeleteNotificationSubscriptionMutation() {
	const { orgId: contextOrgId } = useCurrentUser();

	const [mutation, response] = useMutation<MutationResponse, MutationVariables>(
		DELETE_NOTIFICATION_SUBSCRIPTION,
	);

	const deleteNotificationSubscription = useCallback(
		(props: MutationProps) =>
			mutation({ variables: { ...props, contextOrgId } }),
		[mutation, contextOrgId],
	);

	return [deleteNotificationSubscription, response];
}
