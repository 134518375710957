// @ts-nocheck

import { apiRequest } from '@af/utils';
import { startsWith } from 'lodash-es';
import qs from 'qs';

import { reportError } from 'utils/errors';

import { getMergedApiConfigs } from './utils';

const batchEndpoint = (endpoints, config) => {
	// Take out some overwrite configs like orgId because batch doesn't handle those
	// Instead individual endpoints handle orgId etc.
	const batchConfig = {
		withUser: config.withUser,
		headers: config.headers,
	};

	return {
		url: 'batch',
		method: 'POST',
		body: endpoints,
		...batchConfig,
	};
};

export const batch = async (endpoints, overwriteConfig) => {
	const endpointUrls = endpoints
		.map(e => getMergedApiConfigs(e, overwriteConfig))
		// Pre-format the config before sending to the API
		.map((config, index) =>
			endpoints[index].preFormat ? endpoints[index].preFormat(config) : config,
		)
		.map(endpointToUrl);

	let error;

	const rawResponse = await apiRequest(
		batchEndpoint(endpointUrls, overwriteConfig),
	).catch(e => {
		error = e;
	});

	if (!rawResponse || !!error) {
		console.error('Error on batch response:', error);

		reportError(error, {
			errorInfo: {
				response: rawResponse,
				endpoints,
				overwriteConfig,
			},
		});

		return Promise.reject(error);
	}

	const response = rawResponse
		// Handle failed batch requests
		.map((r, index) => {
			// All statuses starting with 2xx are good
			if (startsWith(r.HttpCode.toString(), '2')) return r;

			console.error('Error on batch response', endpoints[index], r);

			reportError(new Error('Error on batch item'), {
				errorInfo: {
					response: r,
					endpoint: endpoints[index],
					overwriteConfig,
				},
			});

			return {
				...r,
				Body: {
					// Inject error values
					...r.Body,
					error: true,
					httpCode: r.HttpCode,
					statusDescription: r.StatusDescription,
				},
			};
		})
		// Replace "Body" parameter with it's contents
		.map(r => r.Body);

	const formattedResponse = formatResponse(endpoints, response);

	return formattedResponse;
};

const endpointToUrl = endpoint => {
	const { url, method = 'GET', params = {}, body } = endpoint;

	const query = qs.stringify(params);

	if (url === undefined) {
		throw new Error('url is undefined');
	}

	let requestUrl = url;

	// Add query strings if they are provided
	if (query.length > 0) {
		requestUrl += `?${query}`;
	}

	return {
		url: requestUrl,
		method,
		body: body ? JSON.stringify(body) : undefined,
	};
};

// Format responses when format function is defined
const formatResponse = (endpoints, response) =>
	endpoints.map((endpoint, index) => {
		const data = response[index];

		// Don't format errored data
		if (data && data.error) {
			return data;
		}

		return formatEndpointResponse(endpoint, data);
	});

const formatEndpointResponse = ({ format }, response) =>
	format ? format(response) : response;
