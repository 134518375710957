import * as PropTypes from 'prop-types';

export const propTypes = {
	color: PropTypes.string,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
};

export const defaultProps = {
	color: 'currentColor',
	width: 24,
	height: 24,
};
