import type { GQLView } from '@af/api';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_ALL_VIEW_ID_QUERY = gql`
	query GetAllViewId {
		allView {
			id
			name # For caching
		}
	}
`;
type QueryVariables = {};

export type QueryResponse = {
	allView: {
		id: GQLView['id'];
		name: GQLView['name'];
		__typename: GQLView['__typename'];
	};
};

export function useAllViewId() {
	const response = useQuery<QueryResponse, QueryVariables>(
		GET_ALL_VIEW_ID_QUERY,
	);

	return response;
}
