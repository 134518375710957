type Props = {
	className?: string;
};

export const DeleteContextualIcon = ({ className }: Props) => (
	<path
		className={className}
		d="M10.707 10l3.324 3.323-.708.708L10 10.707l-3.323 3.324-.708-.708L9.293 10 5.969 6.677l.708-.708L10 9.293l3.323-3.324.708.708z"
		fillRule="evenodd"
	/>
);
