// @ts-nocheck

import type {
	ButtonHTMLAttributes,
	ComponentProps,
	ComponentType,
	MouseEvent,
	ReactNode,
} from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Typography } from 'ds/components/Typography';

const BaseButton = styled(Typography).attrs(({ type = 'button' }) => ({
	type,
	forwardedAs: 'button',
}))`
	padding: 0 ${props => props.theme.spacing.small}px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: none;
	transition: 166ms ease-out;
`;

const BaseAnchor = styled.a.attrs(() => ({
	rel: 'noopener',
	type: undefined,
}))`
	pointer-events: ${props => props.disabled && 'none'};
`;

const BaseLink = styled(BaseAnchor).attrs(() => ({
	as: Link,
	rel: undefined,
}))``;

type PrimaryButtonColor =
	| 'brandGreen'
	| 'brandBlue'
	| 'riskRed'
	| 'riskPurple'
	| 'riskGrey'
	| 'riskGeneric'
	| 'warning'
	| 'positive'
	| 'brandBlack';

export interface PrimaryButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	color: PrimaryButtonColor; // TODO: colorName ?,
	flex?: boolean;
	small?: boolean;
	children: ReactNode;
}

export const PrimaryButton: ComponentType<PrimaryButtonProps> = styled(
	BaseButton,
)`
	flex: ${props => props.flex && 1};
	height: ${props => (props.small ? '3.2rem' : '4rem')};
	background-color: ${props => props.theme.color.palette[props.color].base};
	color: ${props => props.theme.color.palette.white.base};

	&:focus,
	&:hover {
		background-color: ${props =>
			props.theme.color.palette[props.color].lum.default};
	}

	&:active {
		background-color: ${props =>
			props.theme.color.palette[props.color].lum.disabled};
	}

	${props => props.disabled && '&,'} &:disabled {
		background-color: ${props =>
			props.theme.color.palette[props.color].alpha.custom(0.5)};
		color: ${props => props.theme.color.palette.white.typeAlpha.low};
		cursor: not-allowed;
	}
`;

export interface SecondaryButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	flex?: boolean;
	small?: boolean;
	children: ReactNode;
	isActive?: boolean;
}

export const SecondaryButton: ComponentType<SecondaryButtonProps> = styled(
	BaseButton,
)`
	flex: ${props => props.flex && 1};
	height: ${props => (props.small ? '3.2rem' : '4rem')};
	background-color: ${props =>
		props.isActive
			? props.theme.color.primary.base
			: props.theme.color.bg.lum.default};
	color: ${props => props.isActive && props.theme.color.palette.white.base};

	&:focus,
	&:hover {
		background-color: ${props =>
			!props.isActive && props.theme.color.bg.lum.rollover};
		${props => !props.isActive && props.theme.typography.size.state.rollover};
	}

	${props => props.disabled && '&,'} &:disabled {
		background-color: ${props => props.theme.color.bg.lum.disabled};
		${props => props.theme.typography.size.state.disabled};
		cursor: not-allowed;
	}
`;

type IconButtonProps = {
	children: ReactNode;
	isActive?: boolean;
	transparent?: boolean;
	disabled?: boolean;
	onClick?: (a: MouseEvent<HTMLButtonElement>) => any;
	title?: string;
	className?: string;
	'data-test'?: string;
};

export const IconButton: ComponentType<IconButtonProps> = styled(BaseButton)`
	/*
		Old icons are, by default, 20px high.

		New icons are, by default, 24px high.

		We want height under default usage to be 32px.

		We add 4px padding and vertical center, ensuring some padding still exists if a larger icon is used as a child element.
	*/
	padding: ${props => props.theme.spacing.tiny}px;
	min-height: ${props => props.theme.spacing.xlarge}px;
	min-width: ${props => props.theme.spacing.xlarge}px;

	background-color: ${props =>
		props.transparent
			? 'transparent'
			: props.isActive
			? props.theme.color.primary.lum.active
			: props.theme.color.bg.lum.default};

	&.focus-visible:focus,
	&:hover,
	&:active {
		${props => props.theme.typography.size.state.rollover};
		background-color: ${props =>
			!props.transparent &&
			(props.isActive
				? props.theme.color.primary.lum.rollover
				: props.theme.color.bg.lum.rollover)};
	}

	${props => props.isActive && props.theme.typography.size.state.selected};

	&:disabled {
		${props => props.theme.typography.size.state.disabled};
		cursor: not-allowed;
	}
`;

// Omit custom props before passing them to `Link`
const BaseLinkFiltered = ({
	flex,
	isActive,
	small,
	transparent,
	color,
	...props
}) => <BaseLink {...props} />;

export const PrimaryButtonLink: ComponentType<
	PrimaryButtonProps & ComponentProps<typeof Link>
> = styled(PrimaryButton).attrs(() => ({
	forwardedAs: BaseLinkFiltered,
}))``;

export const SecondaryButtonLink: ComponentType<
	SecondaryButtonProps & ComponentProps<typeof Link>
> = styled(SecondaryButton).attrs(() => ({
	forwardedAs: BaseLinkFiltered,
}))``;

export const IconButtonLink: ComponentType<
	IconButtonProps | ComponentProps<typeof Link>
> = styled(IconButton).attrs(() => ({
	forwardedAs: BaseLinkFiltered,
}))``;

export const ExternalPrimaryLink: ComponentType<PrimaryButtonProps> = styled(
	PrimaryButton,
).attrs(() => ({ forwardedAs: BaseAnchor }))``;

export const ExternalSecondaryLink: ComponentType<SecondaryButtonProps> = styled(
	SecondaryButton,
).attrs(() => ({ forwardedAs: BaseAnchor }))``;

interface NakedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isActive?: boolean;
	children: ReactNode;
}

export const NakedButton: ComponentType<NakedButtonProps> = styled(BaseButton)`
	padding: ${props => props.theme.spacing.tiny}px;
	background-color: transparent;

	&.focus-visible:focus,
	&:hover,
	&:active {
		${props => props.theme.typography.size.state.rollover};
	}

	${props => props.isActive && props.theme.typography.size.state.selected};

	&:disabled {
		${props => props.theme.typography.size.state.disabled};
		cursor: not-allowed;
	}
`;
