// @ts-nocheck

import type { ReactElement, ReactNode, RefObject } from 'react';

import React from 'react';
import { triangle } from 'polished';
import PopperTooltipTrigger from 'react-popper-tooltip';
import styled, { css } from 'styled-components';

import { Background } from 'ds/components/Background';
import { Typography } from 'ds/components/Typography';
import { fadeIn } from 'styles/animation';

export const POSITION = {
	top: 'top',
	topStart: 'top-start',
	topEnd: 'top-end',
	right: 'right',
	bottom: 'bottom',
	bottomStart: 'bottom-start',
	bottomEnd: 'bottom-end',
	left: 'left',
};

export const TRIGGER = {
	click: 'click',
	hover: 'hover',
	rightClick: 'right-click',
	none: 'none',
};

const ARROW_SPACING = 'small';

const ARROW_DIRECTIONS = {
	[POSITION.top]: 'bottom',
	[POSITION.topStart]: 'bottom',
	[POSITION.topEnd]: 'bottom',
	[POSITION.right]: 'left',
	[POSITION.bottom]: 'top',
	[POSITION.bottomStart]: 'top',
	[POSITION.bottomEnd]: 'top',
	[POSITION.left]: 'right',
};

const TOPS = [POSITION.top, POSITION.topStart, POSITION.topEnd];
const BOTTOMS = [POSITION.bottom, POSITION.bottomStart, POSITION.bottomEnd];

type TooltipProps = {
	trigger?: typeof TRIGGER[keyof typeof TRIGGER];
	position?: typeof POSITION[keyof typeof POSITION];
	delayShow?: number;
	delayHide?: number;
	followCursor?: boolean;
	hasArrow?: boolean;
	tooltipShown?: boolean;
	// eslint-disable-next-line no-use-before-define
	children: [
		ReactElement<typeof TooltipTrigger>,
		ReactElement<typeof TooltipContent>,
	];
};

const Tooltip = ({
	children,
	delayShow = 100,
	delayHide = 100,
	trigger = TRIGGER.hover,
	position = POSITION.bottom,
	followCursor,
	hasArrow = true,
	tooltipShown,
}: TooltipProps) => {
	const [triggerComponent, contentComponent] = React.Children.toArray(children);

	return (
		<PopperTooltipTrigger
			trigger={trigger}
			placement={position}
			delayShow={delayShow}
			delayHide={delayHide}
			followCursor={followCursor}
			tooltipShown={tooltipShown}
			tooltip={tooltipProps =>
				React.cloneElement(contentComponent, {
					...contentComponent.props,
					...tooltipProps,
					position,
					followCursor,
				})
			}
		>
			{({ getTriggerProps, triggerRef }) =>
				React.cloneElement(triggerComponent, {
					...triggerComponent.props,
					...getTriggerProps(),
					ref: triggerRef,
				})
			}
		</PopperTooltipTrigger>
	);
};

const TooltipTrigger = styled.span`
	display: inline-flex;
	align-items: center;
`;

type TooltipContentProps = {
	children: ReactNode;
	position?: TooltipProps['position'];
	maxWidth?: number;
	className?: string;
	hasArrow?: boolean;
	noPadding?: boolean;
	hasDropShadow?: boolean;
	followCursor?: boolean;
	// eslint-disable-next-line no-use-before-define
	tooltipRef?: RefObject<Content>;
	// eslint-disable-next-line no-use-before-define
	arrowRef?: RefObject<TooltipArrow>;
	step?: number;
	getTooltipProps?: () => {};
	getArrowProps?: () => {};
};

const TooltipContent = ({
	arrowRef,
	tooltipRef,
	position,
	maxWidth,
	children,
	className,
	step,
	hasArrow = true,
	noPadding = false,
	hasDropShadow = false,
	followCursor,
	getArrowProps = () => ({}),
	getTooltipProps = () => ({}),
}: TooltipContentProps) => (
	<Content
		{...getTooltipProps()}
		ref={tooltipRef}
		className={className}
		position={position}
		maxWidth={maxWidth}
		step={step}
		noPadding={noPadding}
		hasDropShadow={hasDropShadow}
		hasPointerEvents={!followCursor}
	>
		{children}
		{hasArrow && (
			<TooltipArrow {...getArrowProps()} ref={arrowRef} position={position} />
		)}
	</Content>
);

const Content = styled(Typography).attrs(({ step = 6 }) => ({
	forwardedAs: Background,
	step,
}))`
	position: absolute;
	padding: ${props => (props.noPadding ? 0 : props.theme.spacing.small)}px;
	animation: ${fadeIn} 200ms ease-out;
	max-width: ${props => props.maxWidth && props.maxWidth + 'px'};
	z-index: 100;

	pointer-events: ${props => !props.hasPointerEvents && 'none'};
	${props => {
		const marginDirection = ARROW_DIRECTIONS[props.position];
		const margin = props.theme.spacing[ARROW_SPACING];

		return css`margin-${marginDirection}: ${margin}px;`;
	}};
	${props =>
		props.hasDropShadow && 'box-shadow: 3px 4px 3px 2px rgba(0, 0, 0, 0.2)'};
`;

const TooltipArrow = styled.span<{
	position: string | undefined | null;
}>`
	position: absolute;

	${props =>
		triangle({
			pointingDirection: ARROW_DIRECTIONS[props.position],
			width: getArrowDimension([POSITION.right, POSITION.left], props),
			height: getArrowDimension([...TOPS, ...BOTTOMS], props),
			foregroundColor: props.theme.color.bg.base,
		}) as {}};

	${props =>
		props.position.includes(POSITION.top) &&
		css`
			top: 100%;
		`};

	${props =>
		props.position.includes(POSITION.bottom) &&
		css`
			bottom: 100%;
		`};

	${props =>
		props.position.includes(POSITION.right) &&
		css`
			right: 100%;
		`};

	${props =>
		props.position.includes(POSITION.left) &&
		css`
			left: 100%;
		`};
`;

const getArrowDimension = (positions, props) =>
	positions.includes(props.position)
		? `${props.theme.spacing[ARROW_SPACING]}px`
		: '15px';

export { Tooltip, TooltipContent, TooltipTrigger };
