// @ts-nocheck

import type { ComponentProps } from 'react';

import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useUserContext } from 'contexts/UserContext';
import { SINGLE_SIGN_ON_PARAM_NAME } from 'utils/constants';
import { getSsoIssuerFromLocalStorage } from 'utils/users';

import NotFound from './Error/404';
import { Protected } from './Protected';

type Props = {
	permissionId?: ComponentProps<typeof Protected>['permissionId'];
} & ComponentProps<typeof Route>;

// A simple wrapper around <Protected> specifically for guarding routes
const ProtectedRoute = ({ permissionId, ...props }: Props) => {
	const { user } = useUserContext();
	const location = useLocation();

	// If there is no user, redirect to login
	if (!user) {
		const ssoIssuer = getSsoIssuerFromLocalStorage();
		// If this code is executing that means auth flow has been completed
		// If there is still no user redirect to Login
		const search = ssoIssuer
			? `?${SINGLE_SIGN_ON_PARAM_NAME}=${ssoIssuer}`
			: '';
		return (
			<Redirect
				to={{
					pathname: '/login',
					search,
					state: { from: location },
				}}
			/>
		);
	}

	return (
		<Protected permissionId={permissionId} fallback={<NotFound />}>
			<Route {...props} />
		</Protected>
	);
};

export { ProtectedRoute };
